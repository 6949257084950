import printJS from "print-js";
import Api from "../../service/api";
import ReportApi from "../../service/reportapi";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./reportReducer";
import { getState } from "../configure/configureStore";
import moment from "moment";

export function getReportListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REPORT_LIST_WITH_FILTER_PENDING,
      payload: {
        getReportListWithFilterPending: false,
        getReportListWithFilterSuccess: false,
        getReportListWithFilerError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `report/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.count ?? 0);
        }

        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_REPORT_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getReportListWithFilterPending: false,
              getReportListWithFilterSuccess: true,
              getReportListWithFilerError: null,
              reportList: response?.data?.rows ?? [],
              filteredReportList: response?.data?.rows ?? [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_REPORT_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getReportListWithFilterPending: false,
              getReportListWithFilterSuccess: true,
              getReportListWithFilerError: null,
              reportList: response?.data?.rows ?? [],
              filteredReportList: response?.data?.rows ?? [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_LIST_WITH_FILTER_ERROR,
          payload: {
            getReportListWithFilterPending: false,
            getReportListWithFilterSuccess: true,
            getReportListWithFilerError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getReportCountByDoctorWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_PENDING,
      payload: {
        getReportCountByDoctorWithFilterPending: false,
        getReportCountByDoctorWithFilterSuccess: false,
        getReportCountByDoctorWithFilerError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `report/countByDoctors?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(
            response?.data?.rows ?? [],
            response?.data?.totalRecords ?? 0
          );
        }

        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_SUCCESS,
            payload: {
              getReportCountByDoctorWithFilterPending: false,
              getReportCountByDoctorWithFilterSuccess: true,
              getReportCountByDoctorWithFilerError: null,
              filteredReportsByDoctor: response?.data?.rows ?? [],
              filteredReportCountsByDoctor: response?.data?.totalRecords ?? 0,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_SUCCESS,
            payload: {
              getReportCountByDoctorWithFilterPending: false,
              getReportCountByDoctorWithFilterSuccess: true,
              getReportCountByDoctorWithFilerError: null,
              filteredReportsByDoctor: response?.data?.rows ?? [],
              filteredReportCountsByDoctor: response?.data?.totalRecords ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_ERROR,
          payload: {
            getReportCountByDoctorWithFilterPending: false,
            getReportCountByDoctorWithFilterSuccess: false,
            getReportCountByDoctorWithFilerError: err,
            filteredReportsByDoctor: null,
            filteredReportCountsByDoctor: 0,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getReportListOfDoctorWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_PENDING,
      payload: {
        getReportListOfDoctorWithFilterPending: false,
        getReportListOfDoctorWithFilterSuccess: false,
        getReportListOfDoctorWithFilerError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `report/list/byDoctor?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.count ?? 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_SUCCESS,
          payload: {
            getReportListOfDoctorWithFilterPending: false,
            getReportListOfDoctorWithFilterSuccess: true,
            getReportListOfDoctorWithFilerError: null,
            reportList: response?.data?.rows ?? [],
            filteredReportList: response?.data?.rows ?? [],
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_ERROR,
          payload: {
            getReportListOfDoctorWithFilterPending: false,
            getReportListOfDoctorWithFilterSuccess: true,
            getReportListOfDoctorWithFilerError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getReportListOfDeviceWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_PENDING,
      payload: {
        getReportListOfDeviceWithFilterPending: false,
        getReportListOfDeviceWithFilterSuccess: false,
        getReportListOfDeviceWithFilerError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `report/list/byDevice?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.count ?? 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_SUCCESS,
          payload: {
            getReportListOfDeviceWithFilterPending: false,
            getReportListOfDeviceWithFilterSuccess: true,
            getReportListOfDeviceWithFilerError: null,
            reportList: response?.data?.rows ?? [],
            filteredReportList: response?.data?.rows ?? [],
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_ERROR,
          payload: {
            getReportListOfDeviceWithFilterPending: false,
            getReportListOfDeviceWithFilterSuccess: true,
            getReportListOfDeviceWithFilerError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getSeverityList(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SEVERITY_LIST_PENDING,
      payload: {
        getSeverityListPending: false,
        getSeverityListSuccess: false,
        getSeverityListError: null,
      },
    });
    dispatch(showLoader());

    Api.get("master/severity/list")
      .then((response) => {
        console.log(response, "severity");
        dispatch(hideLoader());

        if (typeof callback === "function") {
          const severityListData = Array.isArray(response?.data)
            ? response.data.map((item) => {
                return { value: item.id, label: item.name };
              })
            : [];

          callback(severityListData);
        }

        if (!response?.data) {
          dispatch({
            type: actionTypes.GET_SEVERITY_LIST_ERROR,
            payload: {
              getSeverityListPending: false,
              getSeverityListSuccess: false,
              getSeverityListError: "",
              severityList: [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_SEVERITY_LIST_SUCCESS,
            payload: {
              getSeverityListPending: false,
              getSeverityListSuccess: true,
              getSeverityListError: null,
              severityList: response?.data ?? [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_SEVERITY_LIST_ERROR,
          payload: {
            getSeverityListPending: false,
            getSeverityListSuccess: true,
            getSeverityListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function ReportAccepance(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_ACCEPT_PENDING,
      payload: {
        ReportAcceptPending: false,
        ReportAcceptSuccess: false,
        ReportAcceptError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`report/accept/${id}`, data)
      .then((response) => {
        console.log(response);
        if (typeof callback === "function") {
          callback();
        }
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_ACCEPT_SUCCESS,
          payload: {
            ReportAcceptPending: false,
            ReportAcceptSuccess: true,
            ReportAcceptError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_ACCEPT_ERROR,
          payload: {
            ReportAcceptPending: false,
            ReportAcceptSuccess: true,
            ReportAcceptError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function ReportReviewSubmissionBySupervisor(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_PENDING,
      payload: {
        ReportReviewSubmitBySupervisorPending: false,
        ReportReviewSubmitBySupervisorSuccess: false,
        ReportReviewSubmitBySupervisorError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`report/supervisor/submit/observation/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_SUCCESS,
          payload: {
            ReportReviewSubmitBySupervisorPending: false,
            ReportReviewSubmitBySupervisorSuccess: true,
            ReportReviewSubmitBySupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message,
          })
        );

        if (typeof callback === "function") {
          callback(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_ERROR,
          payload: {
            ReportReviewSubmitBySupervisorPending: false,
            ReportReviewSubmitBySupervisorSuccess: true,
            ReportReviewSubmitBySupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );

        if (typeof callback === "function") {
          callback(false);
        }
      });
  };
}

export function ReportSubmission(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_SUBMIT_PENDING,
      payload: {
        ReportSubmitPending: false,
        ReportSubmitSuccess: false,
        ReportSubmitError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`report/submit/observation/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_SUBMIT_SUCCESS,
          payload: {
            ReportSubmitPending: false,
            ReportSubmitSuccess: true,
            ReportSubmitError: null,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message,
          })
        );

        if (typeof callback === "function") {
          callback(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_SUBMIT_ERROR,
          payload: {
            ReportSubmitPending: false,
            ReportSubmitSuccess: true,
            ReportSubmitError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );

        if (typeof callback === "function") {
          callback(false);
        }
      });
  };
}

export function ReportDetails(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_DETAILS_PENDING,
      payload: {
        ReportDetailsPending: false,
        ReportDetailsSuccess: false,
        ReportDetailsError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`report/${id}`)
      .then((response) => {
        if (typeof callback === "function") {
          callback(response.data.report, response.data.ecgData);
        }
        console.log(response);
        dispatch(hideLoader());

        dispatch({
          type: actionTypes.REPORT_DETAILS_SUCCESS,
          payload: {
            ReportDetailsPending: false,
            ReportDetailsSuccess: true,
            ReportDetailsError: null,
            reportDetails: response.data.report,
            ecgDetails: response.data.ecgData,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_DETAILS_ERROR,
          payload: {
            ReportDetailsPending: false,
            ReportDetailsSuccess: true,
            ReportDetailsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function ReportDownload(reportId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_DOWNLOAD_PDF_PENDING,
      payload: {
        ReportDownloadPdfPending: false,
        ReportDownloadPdfSuccess: false,
        ReportDownloadPdfError: null,
      },
    });
    dispatch(showLoader());

    ReportApi.pdfDownload(`report/generate/pdf/${reportId}`)
      .then((response) => {
        if (response.data) {
          console.log("Pdf Download : SUCCESS.... ");

          // Set file name to download file, based on content-type
          const fileNameWithExtension = (contentType) => {
            switch (contentType) {
              case "application/pdf":
                return `${"Report_" + reportId}.pdf`;

              default:
                return `${"Report_" + reportId}.pdf`;
            }
          };

          var blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          var url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            fileNameWithExtension(response.headers["content-type"])
          );

          // Generate pdf link and perform click on it for downloading!
          document.body.appendChild(link);
          link.click();

          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_SUCCESS,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: true,
              ReportDownloadPdfError: null,
            },
          });
          dispatch(hideLoader());
        } else {
          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: false,
              ReportDownloadPdfError: "No data found",
            },
          });
          dispatch(hideLoader());
        }
      })
      .catch((err) => {
        console.log("Pdf Download : ERROR.... ");
        console.log({ err });
        dispatch({
          type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
          payload: {
            ReportDownloadPdfPending: false,
            ReportDownloadPdfSuccess: false,
            ReportDownloadPdfError: err.message,
          },
        });
        dispatch(hideLoader());
      });

    /*
    Api.get(`report/generate/pdf/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        // var blob = new Blob([response], { type: "application/pdf" });
        // var link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // var fileName = id + ".pdf";
        // link.download = fileName;
        // link.click();
        // console.log(link);

        dispatch({
          type: actionTypes.REPORT_DOWNLOAD_PDF_SUCCESS,
          payload: {
            ReportDownloadPdfPending: false,
            ReportDownloadPdfSuccess: true,
            ReportDownloadPdfError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
          payload: {
            ReportDownloadPdfPending: false,
            ReportDownloadPdfSuccess: true,
            ReportDownloadPdfError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });

      */
  };
}

export function ReportDownloadInBulk(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_DOWNLOAD_PDF_PENDING,
      payload: {
        ReportDownloadPdfPending: false,
        ReportDownloadPdfSuccess: false,
        ReportDownloadPdfError: null,
      },
    });
    dispatch(showLoader());

    ReportApi.zipDownload(`report/generate/multiple/pdf`, data)
      .then((response) => {
        console.log("ZIP Download : response.... ");
        console.log(response.statusText);

        // If status failed is returned in response
        if (response.statusText == "Status -Failed") {
          console.log("ZIP Download : ERROR.... ");

          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: false,
              ReportDownloadPdfError: null,
            },
          });

          dispatch(hideLoader());

          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Some error occurred while downloading reports!",
            })
          );
        }

        // Otherwise if response.data is found, then it is success
        else if (response.data) {
          console.log("ZIP Download : SUCCESS.... ");

          // Set file name to download file, based on content-type
          const fileNameWithExtension = (contentType) => {
            switch (contentType) {
              case "application/zip":
                return `${"Reports_" + moment().format("DDMMYYYY")}.zip`;

              default:
                return `${"Reports_" + moment().format("DDMMYYYY")}.zip`;
            }
          };

          var blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          var url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            fileNameWithExtension(response.headers["content-type"])
          );

          // Generate pdf link and perform click on it for downloading!
          document.body.appendChild(link);
          link.click();

          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_SUCCESS,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: true,
              ReportDownloadPdfError: null,
            },
          });
          dispatch(hideLoader());
        }

        // ELSE - If no data is returned in response
        else {
          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: false,
              ReportDownloadPdfError: "No data found",
            },
          });

          dispatch(hideLoader());

          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Some error occurred while downloading reports!",
            })
          );
        }
      })
      .catch((err) => {
        console.log("ZIP Download : ERROR.... ");
        console.log({ err });
        dispatch({
          type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
          payload: {
            ReportDownloadPdfPending: false,
            ReportDownloadPdfSuccess: false,
            ReportDownloadPdfError: err.message,
          },
        });
        dispatch(hideLoader());

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function ReportExportToExcelInBulk(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_EXPORT_TO_EXCEL_PENDING,
      payload: {
        ReportExportToExcelPending: true,
        ReportExportToExcelSuccess: false,
        ReportExportToExcelError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`report/generateReports`, data)
      .then((response) => {
        console.log(response);

        if (response.data) {
          var url = response.data.presignUrl;
          const link = document.createElement("a");
          link.href = url;

          // Generate pdf link and perform click on it for downloading!
          document.body.appendChild(link);
          link.click();

          dispatch({
            type: actionTypes.REPORT_EXPORT_TO_EXCEL_SUCCESS,
            payload: {
              ReportExportToExcelPending: false,
              ReportExportToExcelSuccess: true,
              ReportExportToExcelError: null,
            },
          });

          dispatch(hideLoader());
        } else {
          dispatch({
            type: actionTypes.REPORT_EXPORT_TO_EXCEL_ERROR,
            payload: {
              ReportExportToExcelPending: false,
              ReportExportToExcelSuccess: true,
              ReportExportToExcelError: null,
            },
          });

          dispatch(hideLoader());

          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: "Some error occurred while downloading reports!",
            })
          );
        }
        dispatch(hideLoader());
        //
        // const fileNameWithExtension = (contentType) => {
        //   switch (contentType) {
        //     case "application/json":
        //       return `${"Reports_" + moment().format("DDMMYYYY")}.xlsx`;

        //     default:
        //       return `${"Reports_" + moment().format("DDMMYYYY")}.xlsx`;
        //   }
        // };

        // const apiResponse = response.data; // here is API response (type- array buffer)
        // const workbook = XLSX.read(apiResponse, { type: "binary" });

        // const excelFile = XLSX.write(workbook, {
        //   type: "binary",
        //   bookType: "xlsx",
        // });
        // const blob = new Blob([s2ab(excelFile)], {
        //   type: "application/octet-stream",
        // });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute(
        //   "download",
        //   fileNameWithExtension(response.headers["content-type"])
        // );

        // // Generate excel link and perform click on it for downloading!
        // document.body.appendChild(link);
        // link.click();

        // function s2ab(s) {
        //   const buf = new ArrayBuffer(s.length);
        //   const view = new Uint8Array(buf);
        //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        //   return buf;
        // }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REPORT_EXPORT_TO_EXCEL_ERROR,
          payload: {
            ReportExportToExcelPending: false,
            ReportExportToExcelSuccess: false,
            ReportExportToExcelError: err.message,
          },
        });
        dispatch(hideLoader());

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function ReportPrint(reportId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_DOWNLOAD_PDF_PENDING,
      payload: {
        ReportDownloadPdfPending: false,
        ReportDownloadPdfSuccess: false,
        ReportDownloadPdfError: null,
      },
    });
    dispatch(showLoader());

    ReportApi.pdfDownload(`report/generate/pdf/${reportId}`)
      .then((response) => {
        if (response.data) {
          console.log("Pdf Download : SUCCESS.... ");

          // Set file name to download file, based on content-type
          const fileNameWithExtension = (contentType) => {
            switch (contentType) {
              case "application/pdf":
                return `${"Report_" + reportId}.pdf`;

              default:
                return `${"Report_" + reportId}.pdf`;
            }
          };

          var blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          var url = URL.createObjectURL(blob);

          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute(
          //   "download",
          //   fileNameWithExtension(response.headers["content-type"])
          // );

          // Generate pdf link and perform click on it for downloading!
          // document.body.appendChild(link);

          printJS({
            printable: url,
            type: "pdf",
            showModal: true,
          });

          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_SUCCESS,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: true,
              ReportDownloadPdfError: null,
            },
          });
          dispatch(hideLoader());
        } else {
          dispatch({
            type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
            payload: {
              ReportDownloadPdfPending: false,
              ReportDownloadPdfSuccess: false,
              ReportDownloadPdfError: "No data found",
            },
          });
          dispatch(hideLoader());
        }
      })
      .catch((err) => {
        console.log("Pdf PRINT : ERROR.... ");
        console.log({ err });
        dispatch({
          type: actionTypes.REPORT_DOWNLOAD_PDF_ERROR,
          payload: {
            ReportDownloadPdfPending: false,
            ReportDownloadPdfSuccess: false,
            ReportDownloadPdfError: err.message,
          },
        });
        dispatch(hideLoader());
      });
  };
}

// -------  selected doctor and device handling ----------

export function setSelectedDoctor(selectedDoctorData) {
  console.log("setSelectedDoctor..");
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_DOCTOR,
      payload: {
        selectedDoctorToViewReports: selectedDoctorData,
      },
    });
  };
}

export function getSelectedDoctor() {
  return getState().report.selectedDoctorToViewReports;
}

export function setSelectedDevice(selectedDeviceData) {
  console.log("setSelectedDevice..");
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_DEVICE,
      payload: {
        selectedDeviceToViewReports: selectedDeviceData,
      },
    });
  };
}

export function getSelectedDevice() {
  return getState().report.selectedDeviceToViewReports;
}

// -----------  previous pages state handling -------------

export function setReportScreensState(data) {
  console.log("setReportScreensState.. : ", data);
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_REPORT_SCREENS_STATE,
      payload: {
        reportsScreenStates: data,
      },
    });
  };
}

export function getReportScreensState() {
  return getState().report.reportsScreenStates;
}

// ----------------  reset all data  ----------------------

export function resetReprtsData() {
  console.log("Reports Data reset..");
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_REPORTS_DATA,
      payload: {
        reportList: [],
        filteredReportList: [],
        filteredReportsByDoctor: [],
        filteredReportCountsByDoctor: 0,
        severityList: [],
        reportDetails: "",
        ecgDetails: "",
        //
        selectedDoctorToViewReports: {},
        selectedDeviceToViewReports: {},
        //
        reportsScreenStates: {
          isComingFromDoctorsList: false,
          isComingFromDeviceList: false,
          doctorListScreen: {},
          deviceListScreen: {},
          reportListScreen: {},
        },
      },
    });
  };
}

export function removeAssociationOfReportWithDoctor(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_PENDING,
      payload: {
        removeReportAssociationWithDoctorPending: false,
        removeReportAssociationWithDoctorSuccess: false,
        removeReportAssociationWithDoctorError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`report/cancel/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_SUCCESS,
          payload: {
            removeReportAssociationWithDoctorPending: false,
            removeReportAssociationWithDoctorSuccess: true,
            removeReportAssociationWithDoctorError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_ERROR,
          payload: {
            removeReportAssociationWithDoctorPending: false,
            removeReportAssociationWithDoctorSuccess: true,
            removeReportAssociationWithDoctorError: null,
          },
        });
      });
  };
}
