import React, { useEffect } from "react";
import {
  Input,
  Select,
  TextArea,
  CardView,
  ThemedButton,
  Imageuploader,
  SearchView,
} from "../../../controls";
import PopupDialog from "../Components/Other/PopupDialog";
import { roles } from "../../../../constants/roles";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";

import { globalConstants } from "../../../../constants/globalConstants";
import "./SupervisorStyles.scss";
import SupervisorList from "../Components/Lists/SupervisorList";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";
import { CSSTransition } from "react-transition-group";
import { FiAlertTriangle } from "react-icons/fi";
import { strings } from "../../../../constants/stringConstants";

const SupervisorView = (props) => {
  const selectedSupervisor = `Dr. ${
    props.supervisorInfo?.firstName !== undefined &&
    props.supervisorInfo?.firstName !== null
      ? `${props.supervisorInfo?.firstName
          ?.charAt(0)
          .toUpperCase()}${props.supervisorInfo?.firstName?.slice(1)}`
      : ""
  } ${
    props.supervisorInfo?.lastName !== undefined &&
    props.supervisorInfo?.lastName !== null
      ? `${props.supervisorInfo?.lastName
          ?.charAt(0)
          .toUpperCase()}${props.supervisorInfo?.lastName?.slice(1)}`
      : ""
  }`;

  //-------------- Delete Supervisor popup contents ------------------
  console.log("showEditSupervisorPopup", props.showEditSupervisorPopup);
  const renderDeleteBulkSupervisorPopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      // <div className="delete-modal-content">
      //   <img className="delete-icon" src={logoutIcon} alt="icon" />
      //   {deleteCount > 1 ? (
      //     <text>{`Are you sure you want to Delete ${deleteCount} Administrators ?`}</text>
      //   ) : (
      //     <text>{`Are you sure you want to Delete ${deleteCount} Administrator ?`}</text>
      //   )}
      // </div>s
      <div>
        <h3 className="popup_text title">
          <FiAlertTriangle size={20} style={{ marginRight: "10px" }} />
          Coming soon
        </h3>
        <text className="popup_text">This feature will be updated soon</text>
        <div style={{ height: "30px" }} />
      </div>
    );
  };

  //-------------- Delete Supervisor popup contents ------------------

  const renderDeleteSupervisorPopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Delete Supervisor
          ${selectedSupervisor}
          ?`}
        </text>
      </div>
    );
  };

  const renderUnlockMessage = () => {
    return (
      <div className="unlock-modal-content">
        <img className="unlock-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Unlock {selectedSupervisor}?</text>
      </div>
    );
  };

  const renderResetPasswordContent = () => {
    return (
      <div className="reset-password-supervisor-form">
        <div
          className="fields"
          style={{
            marginBottom:
              props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                ? "15px"
                : "0px",
          }}
        >
          <text className="label-item">
            <span className="required-star">* </span> New Password :
          </text>
          <div className="value-item">
            <Input
              value={props.resetPasswordSupervisorFields.password}
              className="input-wrapper"
              type={"password"}
              placeholder="Enter Password"
              size="sm"
              onChange={props.onResetPasswordSupervisorFieldChange("password")}
              error={props.errors["password"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Confirm Password :
          </text>
          <div className="value-item">
            <Input
              value={props.resetPasswordSupervisorFields.confirmPassword}
              className="input-wrapper"
              placeholder="Enter Confirm Password"
              type={"password"}
              size="sm"
              onChange={props.onResetPasswordSupervisorFieldChange(
                "confirmPassword"
              )}
              error={props.errors["confirmPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>
      </div>
    );
  };

  const renderAddEditSupervisorPopupContents = () => {
    return (
      <div className="new-supervisor-form">
        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span> Supervisor Name :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter First Name"
              value={props.fields.firstName}
              error={props.errors["firstName"]}
              onChange={props.handleChange("firstName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
            <div style={{ width: "20px" }} />
            <Input
              className="input-wrapper"
              placeholder="Enter Last Name"
              value={props.fields.lastName}
              error={props.errors["lastName"]}
              onChange={props.handleChange("lastName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>Qualification :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Qualification"
              value={props.fields.qualification}
              error={props.errors["qualification"]}
              onChange={props.handleChange("qualification")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>MSCI ID :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter MSCI ID"
              value={props.fields.msci_id}
              error={props.errors["msci_id"]}
              onChange={props.handleChange("msci_id")}
              maxLength={10}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>Email ID :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Email ID"
              value={props.fields.email}
              error={props.errors["email"]}
              disabled={props.showEditSupervisorPopup}
              onChange={props.handleChange("email")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>Mobile :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Mobile Number"
              value={props.fields.contactNumber}
              error={props.errors["contactNumber"]}
              onChange={props.handleChange("contactNumber")}
              maxLength={10}
              isvalnum="isNum"
              showMobilePrefix={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        {!props.showEditSupervisorPopup ? (
          <>
            <div
              className="block-level-group"
              style={{
                marginBottom:
                  props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                    ? "15px"
                    : "0px",
              }}
            >
              <label className="form-label">
                <span className="required-star">* </span>Password :
              </label>
              <div className="value-item">
                <Input
                  value={props.fields.password}
                  className="input-wrapper"
                  placeholder="Enter Password"
                  error={props.errors["password"]}
                  onChange={props.handleChange("password")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="block-level-group">
              <label className="form-label">
                <span className="required-star">* </span>Confirm Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Confirm Password"
                  value={props.fields.confirmPassword}
                  error={props.errors["confirmPassword"]}
                  onChange={props.handleChange("confirmPassword")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderAdminsList = () => {
    return (
      <CardView extraStyle="card-container">
        <div className="card-header-wrapper">
          <div className="title">
            Total Supervisors: {props.totalSupervisor}
          </div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Supervisors"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                />

                <ThemedButton
                  title="Add New Supervisors"
                  size={"large"}
                  icon={<FaPlus size={15} color="#fff" />}
                  onClick={() => {
                    props.onAddSupervisorClick();
                  }}
                  styles="primary huge"
                />
              </>
            )}
            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkSupervisorDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <SupervisorList
          data={props.supervisorList}
          totalSupervisor={props.totalSupervisor}
          // onAdminSelected={props.onAdminSelected}
          onEditSupervisorClick={props.onEditSupervisorClick}
          onDeleteSupervisorClick={props.onDeleteSupervisorClick}
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          onResetPasswordSupervisorClick={props.onResetPasswordSupervisorClick}
          onUnlockSupervisorAccountClick={props.onUnlockSupervisorAccountClick}
          //
          onSupervisorListFilterClick={props.onSupervisorListFilterClick}
          onSupervisorListSortClick={props.onSupervisorListSortClick}
        />
      </CardView>
    );
  };

  return (
    <div className="supervisors-container-new">
      {renderAdminsList()}

      <PopupDialog
        show={
          props.showAddSupervisorPopup == true
            ? props.showAddSupervisorPopup
            : props.showEditSupervisorPopup
        }
        title={
          props.showEditSupervisorPopup
            ? "Edit Supervisor"
            : "Add New Supervisor"
        }
        showCloseIcon={true}
        renderBodyContent={renderAddEditSupervisorPopupContents}
        confirmButtonTitle={props.showEditSupervisorPopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditSupervisorPopup
            ? props.onEditSupervisorConfirmClick
            : props.onAddSupervisorConfirmClick
        }
        onclickCancel={
          props.showAddSupervisorPopup
            ? props.onCloseAddSupervisorPopup
            : props.onCloseEditSupervisorPopup
        }
      />
      <PopupDialog
        show={props.showDeleteSupervisorPopup}
        title={"Delete Supervisor"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteSupervisorPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteSupervisorConfirmClick}
        onclickCancel={props.onCloseDeleteSupervisorPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDeleteSupervisorConfirmPopup}
        title={`Delete Supervisors`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteBulkSupervisorPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkSupervisorDeleteConfirmClick}
        onclickCancel={props.onCloseDeleteSupervisorPopup}
        popupSize={"md"}
        hideButtons={true}
      />

      <PopupDialog
        show={props.showResetPasswordSupervisorPopup}
        title={`Reset Password For
        ${selectedSupervisor}
         `}
        showCloseIcon={true}
        renderBodyContent={renderResetPasswordContent}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onResetPasswordSupervisorConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseResetPasswordSupervisorPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showUnlockSupervisorConfirmationPopup}
        title={"Unlock"}
        showCloseIcon={true}
        renderBodyContent={renderUnlockMessage}
        confirmButtonTitle={"Unlock"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onUnlockSupervisorAccountConfirmClick}
        onclickCancel={props.closeUnlockSupervisorPopup}
        hideButtons={false}
        popupSize={"md"}
      />
    </div>
  );
};

export default SupervisorView;
