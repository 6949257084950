import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./hospitalReducer";

/* ------------------- Get Hospital List ----------------------- */

export function getHospitalsListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_PENDING,
      payload: {
        getHospitalsListWithFilterPending: false,
        getHospitalsListWithFilterSuccess: false,
        getHospitalsListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `hospital/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(
            response?.data?.hospital?.rows ?? [],
            response?.data?.hospital?.count ?? 0,
            response?.data?.metaData ?? {},
            response?.data?.hospital?.totalPages ?? 0
          );
        }
        dispatch({
          type: actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_SUCCESS,
          payload: {
            getHospitalsListWithFilterPending: false,
            getHospitalsListWithFilterSuccess: true,
            getHospitalsListWithFilterError: null,
            filteredHospitalList: response?.data?.hospital?.rows ?? [],
            filteredHospitalCount: response?.data?.hospital?.count ?? 0,
          },
        });
        // if (!data || Object.keys(data).length === 0) {
        //   dispatch({
        //     type: actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_SUCCESS,
        //     payload: {
        //       getHospitalsListWithFilterPending: false,
        //       getHospitalsListWithFilterSuccess: true,
        //       getHospitalsListWithFilterError: null,
        //       hospitalList: response.data.rows,
        //       filteredHospitalList: response?.data?.hospital?.rows ?? [],
        //       filteredHospitalCount: response?.data?.hospital?.count ?? 0,
        //       // filteredHospitalList: [],
        //     },
        //   });
        // } else {

        // }
      })
      .catch((err) => {
        console.log({ err });
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0, {});
        }

        dispatch({
          type: actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_ERROR,
          payload: {
            getHospitalsListWithFilterPending: false,
            getHospitalsListWithFilterSuccess: true,
            getHospitalsListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* ------------------- Get Reporting Hospital List ----------------------- */

export function getReportingHospitalsListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  flag
) {
  const newData = {
    text: data?.text,
  };
  if (flag?.reportService) {
    newData["reportService"] = flag?.reportService;
  }

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_PENDING,
      payload: {
        getReportingHospitalsListWithFilterPending: false,
        getReportingHospitalsListWithFilterSuccess: false,
        getReportingHospitalsListWithFilterError: null,
      },
    });
    // dispatch(showLoader());

    Api.post(
      `hospital/reportingHospital/list?page=${page}&limit=${limit}`,
      newData
    )
      .then((response) => {
        // dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? []);
        }
        dispatch({
          type: actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_SUCCESS,
          payload: {
            getReportingHospitalsListWithFilterPending: false,
            getReportingHospitalsListWithFilterSuccess: true,
            getReportingHospitalsListWithFilterError: null,
            filterdReportingHospitalList: response?.data?.hospital?.rows ?? [],
            filteredReportingHospitalCount:
              response?.data?.hospital?.count ?? 0,
          },
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_ERROR,
          payload: {
            getReportingHospitalsListWithFilterPending: false,
            getReportingHospitalsListWithFilterSuccess: true,
            getReportingHospitalsListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------doctors list by hospital ID--------------------------

export function getDoctorsListbyId(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DOCTORS_LIST_BY_ID_PENDING,
      payload: {
        getDoctorsListByIdPending: false,
        getDoctorsListByIdSuccess: false,
        getDoctorsListByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`doctor/doctorListByHospitalId`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? []);
        }
        dispatch({
          type: actionTypes.GET_DOCTORS_LIST_BY_ID_SUCCESS,
          payload: {
            getDoctorsListByIdPending: false,
            getDoctorsListByIdSuccess: true,
            getDoctorsListByIdError: null,
            doctorList: response?.data ?? [],
          },
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_DOCTORS_LIST_BY_ID_ERROR,
          payload: {
            getDoctorsListByIdPending: false,
            getDoctorsListByIdSuccess: true,
            getDoctorsListByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------Get Hospital By Id--------------------

export function getHospitalDetailsById(id, callback) {
  console.log("id", id);
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_HOSPITAL_DETAILS_BY_ID_PENDING,
      payload: {
        getHospitalDetailsByIdPending: false,
        getHospitalDetailsByIdSuccess: false,
        getHospitalDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`hospital/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.GET_HOSPITAL_DETAILS_BY_ID_SUCCESS,
          payload: {
            getHospitalDetailsByIdPending: false,
            getHospitalDetailsByIdSuccess: true,
            getHospitalDetailsByIdError: null,
            HospitalDetails: response?.data ?? {},
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.GET_HOSPITAL_DETAILS_BY_ID_ERROR,
          payload: {
            getHospitalDetailsByIdPending: false,
            getHospitalDetailsByIdSuccess: true,
            getHospitalDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------Reset get hospital details by id-------------//

export function resetGetHospitalDetailsById() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_GET_HOSPITAL_DETAILS_BY_ID,
      payload: {
        HospitalDetails: null,
      },
    });
  };
}

//-------------------------add new hospital--------------------

export function addNewHospital(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_HOSPITALS_PENDING,
      payload: {
        addNewHospitalPending: false,
        addNewHospitalSuccess: false,
        addNewHospitalError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`hospital/addHospital`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_NEW_HOSPITALS_SUCCESS,
          payload: {
            addNewHospitalPending: false,
            addNewHospitalSuccess: true,
            addNewHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.ADD_NEW_HOSPITALS_ERROR,
          payload: {
            addNewHospitalPending: false,
            addNewHospitalSuccess: true,
            addNewHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------edit hospital--------------------

export function editHospital(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_HOSPITAL_PENDING,
      payload: {
        editHospitalPending: false,
        editHospitalSuccess: false,
        editHospitalError: null,
      },
    });
    dispatch(showLoader());

    Api.put(`hospital/update/${id}`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_HOSPITAL_SUCCESS,
          payload: {
            editHospitalPending: false,
            editHospitalSuccess: true,
            editHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.EDIT_HOSPITAL_PENDING,
          payload: {
            editHospitalPending: false,
            editHospitalSuccess: true,
            editHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- delete hospital------------------------------------

export function deleteHospital(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_HOSPITAL_PENDING,
      payload: {
        deleteHospitalPending: false,
        deleteHospitalSuccess: false,
        deleteHospitalError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`hospital/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_HOSPITAL_SUCCESS,
          payload: {
            deleteHospitalPending: false,
            deleteHospitalSuccess: true,
            deleteHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_HOSPITAL_ERROR,
          payload: {
            deleteHospitalPending: false,
            deleteHospitalSuccess: true,
            deleteHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- bulk hospital delete hospital------------------------------------

export function deleteHospitalInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_HOSPITALS_IN_BULK_PENDING,
      payload: {
        deleteHospitalsInBulkPending: false,
        deleteHospitalsInBulkSuccess: false,
        deleteHospitalsInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`hospital/delete/multiple/hospitals`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_HOSPITALS_IN_BULK_SUCCESS,
          payload: {
            deleteHospitalsInBulkPending: false,
            deleteHospitalsInBulkSuccess: true,
            deleteHospitalsInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_HOSPITALS_IN_BULK_ERROR,
          payload: {
            deleteHospitalsInBulkPending: false,
            deleteHospitalsInBulkSuccess: true,
            deleteHospitalsInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function setReportingStatus(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_REPORTING_STATUS_PENDING,
      payload: {
        setReportingStatusPending: false,
        setReportingStatusSuccess: false,
        setReportingStatusError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`hospital/update/service/${id}`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.SET_REPORTING_STATUS_SUCCESS,
          payload: {
            setReportingStatusPending: false,
            setReportingStatusSuccess: true,
            setReportingStatusError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.SET_REPORTING_STATUS_ERROR,
          payload: {
            setReportingStatusPending: false,
            setReportingStatusSuccess: true,
            setReportingStatusError: null,
          },
        });
        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Error",
        //     type: "danger",
        //     msg: err,
        //   })
        // );
      });
  };
}

/* ------------------  Hospital Clicked ------------------------ */

export function setSelectedHospital(hospitalData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_HOSPITAL,
      payload: {
        selectedHospital: hospitalData,
      },
    });
  };
}

export function resetSelectedHospital() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SELECTED_HOSPITAL,
      payload: {
        selectedHospital: null,
      },
    });
  };
}

//-------------------------Update Hospital Info--------------------

export function updateHospitalInfoById(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_PENDING,
      payload: {
        updateHospitalInfoByIdPending: false,
        updateHospitalInfoByIdSuccess: false,
        updateHospitalInfoByIdError: null,
      },
    });
    console.log("pass-data", data);
    const newData = {
      addressData: {
        addressLineOne: data?.addressLine1,
        addressLineTwo: data?.addressLine2,
        landmark: data?.landmark,
        locationName: data?.location,
        latitude:
          data?.latitude?.trim().length > 0 ? data?.latitude?.trim() : null,
        longitude:
          data?.longitude?.trim().length > 0 ? data?.longitude?.trim() : null,

        mobile: data?.contactNumber,
        districtId: data?.district?.value,
        stateId: data?.state?.value,
        pincodeId: data?.pincode?.value,
        areaId: data?.village?.id,
      },
      hubType:
        data?.isHub == true
          ? data?.hubType.name == "Hub A"
            ? "A"
            : "B"
          : null,
      isHub: data?.isHub,
      isSpoke: data?.isSpoke,
      isGovernment: data?.isGovernment,
      // reportingHospitalId: data?.newReportingName?.value
      //   ? data?.newReportingName?.value
      //   : null,
      hospitalName: data?.hospitalName,
    };
    if (data?.reportService == true) {
      newData["reportService"] = true;
    } else {
      newData["reportService"] = false;
      if (data?.reportingName?.value) {
        newData["reportingHospitalId"] = data?.reportingName?.value;
      }
    }

    dispatch(showLoader());

    Api.put(`hospital/update/${id}`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_SUCCESS,
          payload: {
            updateHospitalInfoByIdPending: false,
            updateHospitalInfoByIdSuccess: true,
            updateHospitalInfoByIdError: null,
            updateHospitalData: response?.data ?? {},
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_ERROR,
          payload: {
            updateHospitalInfoByIdPending: false,
            updateHospitalInfoByIdSuccess: true,
            updateHospitalInfoByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// -----------------------Add new center---------------

export function addNewCenter(data, hospitalId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_CENTER_PENDING,
      payload: {
        addNewCenterPending: false,
        addNewCenterSuccess: false,
        addNewCenterError: null,
      },
    });

    const newData = {
      addressData: {
        addressLineOne: data?.addressOne,
        addressLineTwo: data?.address2,

        mobile: data?.contact,
        locationName: data?.location,

        landmark: data?.landmark,

        districtId: data?.district?.value,
        stateId: data?.state?.value,
        pincodeId: data?.pincode?.value,
        areaId: data?.village?.id,
      },
      hospitalId: hospitalId ? hospitalId : "",
      centerName: data?.centerName,
    };

    dispatch(showLoader());

    Api.post(`center/add`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.ADD_NEW_CENTER_SUCCESS,
          payload: {
            addNewCenterPending: false,
            addNewCenterSuccess: true,
            addNewCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
        // if (data?.CenterAdminFlag == true) {
        //   dispatch(addCenterAdmin(data, response?.data?.id, () => {}));
        // }

        if (response.message && hospitalId) {
          dispatch(getHospitalDetailsById(hospitalId, () => {}));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.ADD_NEW_CENTER_ERROR,
          payload: {
            addNewCenterPending: false,
            addNewCenterSuccess: true,
            addNewCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------edit center hospital--------------------

export function editCenterHospital(id, data, hospitalId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_HOSPITAL_CENTER_PENDING,
      payload: {
        editHospitalCenterPending: false,
        editHospitalCenterSuccess: false,
        editHospitalCenterError: null,
      },
    });
    dispatch(showLoader());

    const newData = {
      centerName: data?.centerName,
      // centerNo: 123,
      // hospitalId: 36,
      addressData: {
        addressLineOne: data?.addressOne,
        addressLineTwo: data?.address2,
        locationName: data?.location,
        // districtId: 447,
        mobile: data?.contact,
        landmark: data?.landmark,

        districtId: data?.district?.value,
        stateId: data?.state?.value,
        pincodeId: data?.pincode?.value,
        areaId: data?.village?.id,
        // stateId: 15,
      },
      centerAdminData: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        contactNumber: data?.CenterAdmincontact,
      },
    };

    // if(data)
    if (data?.isDeletedArchievedFlag && data?.email) {
      newData.centerAdminData["email"] = data?.email;
    }

    Api.put(`center/update/${id}`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.EDIT_HOSPITAL_CENTER_SUCCESS,
          payload: {
            editHospitalCenterPending: false,
            editHospitalCenterSuccess: true,
            editHospitalCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
        if (hospitalId) {
          dispatch(getHospitalDetailsById(hospitalId, () => {}));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.EDIT_HOSPITAL_CENTER_ERROR,
          payload: {
            editHospitalCenterPending: false,
            editHospitalCenterSuccess: true,
            editHospitalCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- delete hospital Center------------------------------------

export function deleteHospitalCenter(id, hospitalId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_HOSPITAL_CENTER_PENDING,
      payload: {
        deleteHospitalCenterPending: false,
        deleteHospitalCenterSuccess: false,
        deleteHospitalCenterError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`center/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_HOSPITAL_CENTER_SUCCESS,
          payload: {
            deleteHospitalCenterPending: false,
            deleteHospitalCenterSuccess: true,
            deleteHospitalCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
        dispatch(getHospitalDetailsById(hospitalId, () => {}));
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_HOSPITAL_CENTER_ERROR,
          payload: {
            deleteHospitalCenterPending: false,
            deleteHospitalCenterSuccess: true,
            deleteHospitalCenterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// -----------------------Add center Admin---------------

export function addCenterAdmin(data, centerId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_CENTER_ADMIN_PENDING,
      payload: {
        addCenterAdminPending: false,
        addCenterAdminSuccess: false,
        addCenterAdminError: null,
      },
    });

    const newData = {
      email: data?.email ? data?.email : "",
      password: data?.password ? data?.password : "",
      firstName: data?.firstName ? data?.firstName : "",
      lastName: data?.lastName ? data?.lastName : "",
      contactNumber: data?.CenterAdmincontact ? data?.CenterAdmincontact : "",
      centerId: centerId,
    };

    dispatch(showLoader());

    Api.post(`centerAdmin/add`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.ADD_CENTER_ADMIN_SUCCESS,
          payload: {
            addCenterAdminPending: false,
            addCenterAdminSuccess: true,
            addCenterAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        if (typeof callback === "function") {
          callback(err);
        }

        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.ADD_CENTER_ADMIN_ERROR,
          payload: {
            addCenterAdminPending: false,
            addCenterAdminSuccess: true,
            addCenterAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// ----------------------- get center by id-----------------

export function getCenterById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CENTER_BY_ID_PENDING,
      payload: {
        getCenterByIdPending: false,
        getCenteByIdSuccess: false,
        getCenteByIdError: null,
      },
    });

    dispatch(showLoader());

    Api.get(`center/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.GET_CENTER_BY_ID_SUCCESS,
          payload: {
            getCenterByIdPending: false,
            getCenteByIdSuccess: true,
            getCenteByIdError: null,
            getCenterData: response?.data ?? {},
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.GET_CENTER_BY_ID_ERROR,
          payload: {
            getCenterByIdPending: false,
            getCenteByIdSuccess: true,
            getCenteByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- delete hospital Center Admin------------------------------------

export function deleteHospitalCenterAdmin(id, hospitalId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_PENDING,
      payload: {
        deleteHospitalCenterAdminPending: false,
        deleteHospitalCenterAdminSuccess: false,
        deleteHospitalCenterAdminError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`centerAdmin/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_SUCCESS,
          payload: {
            deleteHospitalCenterAdminPending: false,
            deleteHospitalCenterAdminSuccess: true,
            deleteHospitalCenterAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
        dispatch(getHospitalDetailsById(hospitalId, () => {}));
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_ERROR,
          payload: {
            deleteHospitalCenterAdminPending: false,
            deleteHospitalCenterAdminSuccess: true,
            deleteHospitalCenterAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* ------------------- Get Hospital List ----------------------- */

export function getHospitalsNearAnotherHospitals(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_HOSPITALS_NEAR_ANOTHER_PENDING,
      payload: {
        getHospitalsNearAnotherPending: true,
        getHospitalsNearAnotherSuccess: false,
        getHospitalsNearAnotherError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`hospital/nearestHospital/list`, data)
      .then((response) => {
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(response?.data?.hospitals ?? [], response?.data?.count ?? 0);
        }
        dispatch({
          type: actionTypes.GET_HOSPITALS_NEAR_ANOTHER_SUCCESS,
          payload: {
            getHospitalsNearAnotherPending: false,
            getHospitalsNearAnotherSuccess: true,
            getHospitalsNearAnotherError: null,
            hospitalNearByList: response?.data?.hospital?.rows ?? [],
          },
        });
      })
      .catch((err) => {
        console.log({ err });
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_HOSPITALS_NEAR_ANOTHER_ERROR,
          payload: {
            getHospitalsNearAnotherPending: false,
            getHospitalsNearAnotherSuccess: true,
            getHospitalsNearAnotherError: null,
            hospitalNearByList: [],
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
