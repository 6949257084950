import React from "react";
import CardView from "../../../../controls/Card/CardView";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import devices_card from "../../../../../assets/images/icons/devices_card.png";
import "./CardStyles.scss";

const COLORS = ["#061178", "#2F54EB", "#D6E4FF"];

const DevicesCard = (props) => {
  return (
    <div className="super-admin-card-wrapper">
      <CardView
        extraStyle="device-card-control"
        bodyStyle={{ height: "180px" }}
      >
        <div className="card-title">
          <img className="card-icon" src={devices_card} alt="icon" />
          <span>Devices</span>
        </div>

        <div className="info-wrapper">
          <ResponsiveContainer width="100%" height={100}>
            <PieChart>
              <Pie
                data={props.devicesData}
                cx="20%"
                cy="50%"
                innerRadius={35}
                outerRadius={50}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
                legendType="circle"
              >
                {props.devicesData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Label content={<CustomLabel total={props.totalDevices} />} />
              </Pie>
              <Legend
                content={<CustomLegend data={props.devicesData} />}
                width={"55%"}
                layout="vetical"
                verticalAlign="middle"
                align="right"
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardView>
    </div>
  );
};

const CustomLegend = (data) => {
  var legendsData = Array.isArray(data.data) ? data.data : [];
  // console.log("legendsData : ", JSON.stringify(legendsData));

  //---------------------------------------------------------
  // to calculate percentage
  var totalValue = 0;
  for (var i = 0; i < legendsData.length; i++) {
    totalValue += parseInt(legendsData[i].value);
  }
  //---------------------------------------------------------

  // legendsData.map((entry, index) => {
  //   var percent = (entry.value * 100) / totalValue;
  //   console.log("PROPS : legend : entry.value :", entry.value);
  //   console.log("PROPS : legend : percent  :", percent);
  // });

  return (
    <ul style={{ listStyle: "none" }} className="legends-list">
      {legendsData.map((entry, index) => (
        <li key={`legend-${index}`} className="legends-list-item">
          <span
            className="legend-dot"
            style={{ backgroundColor: COLORS[index] }}
          ></span>
          <span className="legend-title">{entry.name}</span>
          <div className="legend-separator" />
          <span className="legend-value">
            {/* {parseInt(entry.value) === 0 && parseInt(totalValue) === 0
              ? "0.00%"
              : ((parseInt(entry.value) / parseInt(totalValue)) * 100).toFixed(
                  2
                ) + "%"} */}
            {parseInt(entry.value)}
          </span>
        </li>
      ))}
    </ul>
  );
};

const CustomLabel = ({ viewBox, total }) => {
  const { cx, cy } = viewBox;

  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      fill="#333"
      fontSize={16}
      fontWeight="bold"
    >
      <tspan
        x={cx}
        dy="-1em"
        style={{ fill: "#00000085", fontSize: "12px", fontWeight: "300" }}
      >
        Total
      </tspan>
      <tspan
        x={cx}
        dy="1.2em"
        style={{ fill: "#00000085", fontSize: "22px", fontWeight: "400" }}
      >
        {total}
      </tspan>
    </text>
  );
};

export default DevicesCard;
