import { combineReducers } from "redux";
import alert from "../alert/alertReducer";
import loader from "../loader/loaderReducer";
import sidebar from "../sidebar/sidebarReducer";
import auth from "../auth/authReducer";
import otpVerification from "../otpverification/otpVerificationReducer";

import master from "../master/masterReducer";
import administrators from "../administrators/adminReducer";
import hospital from "../hospital/hospitalReducer";
import center from "../center/centerReducer";
import doctor from "../doctor/doctorReducer";
import technician from "../technician/technicianReducer";
import device from "../device/deviceReducer";
import stemiCase from "../case/caseReducer";
import report from "../report/reportReducer";
import dashboard from "../dashboard/dashboardReducer";
import errorLogs from "../errorLogs/errorLogsReducer";
import ambulance from "../ambulance/ambulanceReducer";
import mqtt from "../mqtt/mqttReducer";
import patient from "../patient/patientReducer";

import { routerReducer } from "react-router-redux";

export const rootReducer = combineReducers({
  // router: connectRouter(history),
  router: routerReducer,
  alert,
  auth,
  loader,
  sidebar,
  otpVerification,
  master,
  administrators,
  hospital,
  center,
  doctor,
  technician,
  device,
  report,
  dashboard,
  errorLogs,
  stemiCase,
  ambulance,
  mqtt,
  patient,
});
