import React from "react";
import {
  CardView,
  Input,
  SearchView,
  ThemedButton,
  FormSearchView,
} from "../../../controls";
import { AdministratorsList, PopupDialog } from "../Components";
import { FaPlus } from "react-icons/fa6";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import { CSSTransition } from "react-transition-group";
import { AiOutlineDelete } from "react-icons/ai";
import "./AdministratorsStyles.scss";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { strings } from "../../../../constants/stringConstants";

const AdministratorsView = (props) => {
  console.log("props", props.totalAdmins);
  // const chipData = props.hospitals ?? [];
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.4),
  }));

  const selectedAdmin = props.hospitalAdminInfo;

  const selectedAdminName = `${
    (selectedAdmin?.firstName !== null && selectedAdmin?.firstName !== undefined
      ? `${selectedAdmin?.firstName
          .charAt(0)
          .toUpperCase()}${selectedAdmin?.firstName.slice(1)}`
      : "") +
    " " +
    (selectedAdmin?.lastName !== null && selectedAdmin?.lastName !== undefined
      ? `${selectedAdmin?.lastName
          .charAt(0)
          .toUpperCase()}${selectedAdmin?.lastName.slice(1)}`
      : "")
  }`;

  //-------------- Add Admin popup contents ------------------

  const renderAddAdminPopupContents = () => {
    return (
      <div className="new-administrator-form">
        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span> Administrator Name :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter First Name"
              value={props.addAdminFields.firstName}
              error={props.errors["firstName"]}
              onChange={props.onAddAdminFieldChange("firstName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
            <div style={{ width: "20px" }} />
            <Input
              className="input-wrapper"
              placeholder="Enter Last Name"
              value={props.addAdminFields.lastName}
              error={props.errors["lastName"]}
              onChange={props.onAddAdminFieldChange("lastName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>Email ID :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Email ID"
              value={props.addAdminFields.email}
              error={props.errors["email"]}
              disabled={props.showEditAdminPopup}
              onChange={props.onAddAdminFieldChange("email")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required-star">* </span>Mobile :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Mobile Number"
              value={props.addAdminFields.contactNumber}
              error={props.errors["contactNumber"]}
              onChange={props.onAddAdminFieldChange("contactNumber")}
              maxLength={10}
              isvalnum="isNum"
              showMobilePrefix={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        {!props.showEditAdminPopup ? (
          <>
            <div
              className="block-level-group"
              style={{
                marginBottom:
                  props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                    ? "15px"
                    : "0px",
              }}
            >
              <label className="form-label">
                <span className="required-star">* </span>Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Password"
                  value={props.addAdminFields.password}
                  error={props.errors["password"]}
                  onChange={props.onAddAdminFieldChange("password")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="block-level-group">
              <label className="form-label">
                <span className="required-star">* </span>Confirm Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Confirm Password"
                  value={props.addAdminFields.confirmPassword}
                  error={props.errors["confirmPassword"]}
                  onChange={props.onAddAdminFieldChange("confirmPassword")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
          </>
        ) : null}
        <div
          className={`block-level-group ${
            props.hospitals.length > 0 ? "align-center" : ""
          }`}
        >
          <label className="form-label">Hospital :</label>

          <div className="value-item extra">
            {props.hospitals.length > 0 ? (
              <>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    boxShadow: "none",
                    borderRadius: "2px",
                    border: "none",
                    width: "100%",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.4,
                    m: 0,
                  }}
                  component="ul"
                >
                  {props.hospitals.map((data) => {
                    return (
                      <ListItem key={data.value}>
                        <Chip
                          style={{ background: "#c0e5dc" }}
                          label={data.label}
                          onDelete={
                            props.handleDeleteHospital !== null
                              ? () => props.handleDeleteHospital(data)
                              : () => {}
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Paper>
                <div style={{ height: "8px" }}></div>
              </>
            ) : null}
            <FormSearchView
              Options={props.searchHospitalList}
              previousArrayValue={props.hospitals}
              placeholder="Search Hospital Name"
              onInputChange={props.onHospitalSearchChange}
              onChange={props.onAssignHospitalChange}
              onScroll={props.onScrollHospitalListDropdown}
              searchText={props.searchHospitalText}
              multiSelect={true}
              lazyLoading={true}
              customInputSearch={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
      </div>
    );
  };

  //-------------- Delete Admin popup contents ------------------

  const renderDeleteAdminPopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Delete Administrator
          ${selectedAdminName}
          ?`}
        </text>
      </div>
    );
  };

  const renderUnlockMessage = () => {
    return (
      <div className="unlock-modal-content">
        <img className="unlock-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Unlock {selectedAdminName}?</text>
      </div>
    );
  };

  const renderDeleteBulkTechnicianPopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        {deleteCount > 1 ? (
          <text>{`Are you sure you want to Delete ${deleteCount} Administrators ?`}</text>
        ) : (
          <text>{`Are you sure you want to Delete ${deleteCount} Administrator ?`}</text>
        )}
      </div>
    );
  };

  //-------------- Admins List View ------------------

  const renderAdminsList = () => {
    return (
      <CardView extraStyle="card-container">
        <div className="card-header-wrapper">
          <div className="title">Total Administrators: {props.totalAdmins}</div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Administrators"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                />

                <ThemedButton
                  title="Add New Administrator"
                  size={"large"}
                  icon={<FaPlus size={15} color="#fff" />}
                  onClick={() => {
                    props.onAddAdministratorClick();
                  }}
                  styles="primary huge"
                />
              </>
            )}
            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkAdministratorDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <AdministratorsList
          data={props.adminsList}
          totalAdmins={props.totalAdmins}
          onAdminSelected={props.onAdminSelected}
          onEditHospitalAdminClick={props.onEditHospitalAdminClick}
          onDeleteHospitalAdminClick={props.onDeleteHospitalAdminClick}
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          onResetPasswordHospitalAdminClick={
            props.onResetPasswordHospitalAdminClick
          }
          onUnlockHospitalAdminAccountClick={
            props.onUnlockHospitalAdminAccountClick
          }
          //
          onHospitalAdminListSortClick={props.onHospitalAdminListSortClick}
        />
      </CardView>
    );
  };

  const renderResetPasswordContent = () => {
    return (
      <div className="reset-password-hospital-admin-form">
        <div
          className="fields"
          style={{
            marginBottom:
              props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                ? "10px"
                : "0px",
          }}
        >
          <text className="label-item">
            <span className="required-star">* </span> New Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Password"
              type={"password"}
              size="sm"
              value={props.resetPasswordHospitalAdminFields.password}
              onChange={props.onResetPasswordHospitalAdminFieldChange(
                "password"
              )}
              error={props.errors["password"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Confirm Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Confirm Password"
              type={"password"}
              size="sm"
              value={props.resetPasswordHospitalAdminFields.confirmPassword}
              onChange={props.onResetPasswordHospitalAdminFieldChange(
                "confirmPassword"
              )}
              error={props.errors["confirmPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>
      </div>
    );
  };

  //---------------------------------------------------------------

  return (
    <div className="administrators-container-new">
      {renderAdminsList()}

      <PopupDialog
        show={props.showAddAdminPopup}
        title={
          props.showEditAdminPopup
            ? "Edit Administrator"
            : "Add New Administrator"
        }
        showCloseIcon={true}
        renderBodyContent={renderAddAdminPopupContents}
        confirmButtonTitle={props.showEditAdminPopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditAdminPopup
            ? props.onEditAdminConfirmClick
            : props.onAddAdministratorConfirmClick
        }
        onclickCancel={
          props.showEditAdminPopup
            ? props.onCloseEditAdminPopup
            : props.onCloseAddAdministratorPopup
        }
        // extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />
      <PopupDialog
        show={props.showDeleteAdminPopup}
        title={"Delete Administrator"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteAdminPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteAdminConfirmClick}
        onclickCancel={props.onCloseDeleteAdminPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDeleteAdminConfirmPopup}
        title={`Delete Administrators`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteBulkTechnicianPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkAdministratorDeleteConfirmClick}
        onclickCancel={props.onCloseDeleteAdminPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showResetPasswordHospitalAdminPopup}
        title={`Reset Password For ${selectedAdminName}`}
        showCloseIcon={true}
        renderBodyContent={renderResetPasswordContent}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onResetPasswordHospitalAdminConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseResetPasswordHospitalAdminPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showUnlockAdministratorConfirmationPopup}
        title={"Unlock"}
        showCloseIcon={true}
        renderBodyContent={renderUnlockMessage}
        confirmButtonTitle={"Unlock"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onUnlockHospitalAdminAccountConfirmClick}
        onclickCancel={props.closeUnlockHospitalAdminPopup}
        hideButtons={false}
        popupSize={"md"}
      />
    </div>
  );
};

export default AdministratorsView;
