import React, { useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
// import "react-calendar/dist/Calendar.css";
import { SlCalender } from "react-icons/sl";
import { IoClose } from "react-icons/io5";
import "./NewDateRangePickerStyles.scss";

export default function NewDateRangePicker() {
  const [value, onChange] = useState(null);
  return null;
  // <DateRangePicker
  //   className="new-date-range-picker"
  //   onChange={(dates) => {
  //     onChange(dates);
  //   }}
  //   clearIcon={
  //     !(value == null) ? (
  //       <IoClose
  //         className="close-icon"
  //         size={20}
  //         style={{ color: "#82888a" }}
  //       />
  //     ) : null
  //   }
  //   autoFocus={false}
  //   calendarIcon={<SlCalender size={18} style={{ color: "#82888a" }} />}
  //   value={value}
  //   shouldOpenCalendar={({ reason }) => reason !== "focus"}
  // />
}
