import React from "react";
import {
  CardView,
  DateRangeSelector,
  Input,
  SearchView,
  TextArea,
  ThemedButton,
  TogglebuttonGroup,
} from "../../../controls";
import ErrorLogsList from "../Components/Lists/ErrorLogsList";
import { AiOutlineDownload } from "react-icons/ai";
import "./ErrorLogsStyles.scss";
import { FaAngleLeft } from "react-icons/fa6";
import { roles } from "../../../../constants/roles";
import PopupDialog from "../Components/Other/PopupDialog";

const ErrorLogsView = (props) => {
  // console.log("viewErrorLogData", props.viewUserRemark);

  const renderViewErrorLogPopupContent = () => {
    // console.log("viewErrorLogData : ", props.viewErrorLogData);
    // console.log("toggleData : ", props.toggleData);

    var errorlLogEntryStatus = "";
    props.toggleData.map((item) => {
      if (item.id == props.viewErrorLogData?.status ?? null) {
        errorlLogEntryStatus = item.name;
      }
    });
    //
    const location = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo
      ?.center?.addressInfo?.locationName
      ? props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .locationName
      : "-";

    const district = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo
      ?.center?.addressInfo?.districtInfo?.name
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center?.addressInfo
          .districtInfo.name
      : props.viewErrorLogData?.deviceInfo?.centerDeviceInfo?.center
          ?.addressInfo?.districtInfo?.nam
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .districtInfo.nam
      : "";

    const state = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo?.center
      ?.addressInfo?.stateInfo?.name
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .stateInfo.name
      : "";
    const addressDetails = `${location}${district}${state}`;

    return (
      <div className="error_logs_view">
        <div className="form-info">
          <div className="info-1">
            <text className="label">Device ID</text>
            <text className="value">
              {props.viewErrorLogData.deviceInfo?.serialNo ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Device Type</text>
            <text className="value">
              {props.viewErrorLogData.deviceInfo?.deviceType?.type ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-2">
            <text className="label">Device Location</text>
            <text className="value">{addressDetails}</text>
          </div>
        </div>
        <div className="form-info">
          <div className="info-2">
            <text className="label">Hospital</text>
            <text className="value">
              {props.viewErrorLogData?.deviceInfo?.hospital?.hospitalInfo
                ?.hospitalName ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Status</text>
            <text className="value">
              {errorlLogEntryStatus}
              {/* {props.viewErrorLogData?.deviceInfo?.status == 1
                ? "Online"
                : props.viewErrorLogData?.deviceInfo?.status == 2
                ? "Offline"
                : props.viewErrorLogData?.deviceInfo?.status == 3
                ? "Error"
                : "-"} */}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Severity</text>
            <text className="value">
              {props.viewErrorLogData.severity == 1
                ? "Low"
                : props.viewErrorLogData.severity == 2
                ? "Medium"
                : props.viewErrorLogData.severity == 3
                ? "High"
                : "-"}
            </text>
          </div>
        </div>
        <div className="form-info">
          <div className="info-2">
            <text className="label">Error Description</text>
            <text className="value">
              {props.viewErrorLogData?.description ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-2">
            <text className="label">Email Sent To</text>
            <text className="value">{props.viewUserRemark?.to ?? "-"}</text>
          </div>
        </div>
        <div className="form-info">
          <div className="info-3">
            <div className="label">Remarks</div>
            <div className="value">
              {Object.keys(props.viewUserRemark).length === 0
                ? "-"
                : JSON.stringify(props.viewUserRemark.remark).length <= 2 // remark is not required field so handled it with -
                ? "-"
                : props.viewUserRemark?.remark ?? "-"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderUpdateErrorLogPopupContent = () => {
    var errorlLogEntryStatus = "";
    props.toggleData.map((item) => {
      if (item.id == props.viewErrorLogData?.status ?? null) {
        errorlLogEntryStatus = item.name;
      }
    });

    const location = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo
      ?.center?.addressInfo?.locationName
      ? props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .locationName
      : "-";

    const district = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo
      ?.center?.addressInfo?.districtInfo?.name
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center?.addressInfo
          .districtInfo.name
      : props.viewErrorLogData?.deviceInfo?.centerDeviceInfo?.center
          ?.addressInfo?.districtInfo?.nam
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .districtInfo.nam
      : "";

    const state = props.viewErrorLogData?.deviceInfo?.centerDeviceInfo?.center
      ?.addressInfo?.stateInfo?.name
      ? ", " +
        props.viewErrorLogData.deviceInfo.centerDeviceInfo.center.addressInfo
          .stateInfo.name
      : "";
    const addressDetails = `${location}${district}${state}`;
    //

    return (
      <div className="error_logs_view">
        <div className="form-contents">
          <div className="fields">
            <text className="text">
              <span className="required-star">* </span> To :
            </text>
            <div className="field-item">
              <Input
                className="select-option"
                placeholder={"Enter email"}
                type="email"
                size="sm"
                onChange={(e) => {
                  props.onMailToChange(e.target.value, e.target.name);
                }}
                value={props.fields["email"]}
                name={"email"}
                error={props.emailError}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="fields upper">
            <text className="text">Remarks :</text>
            <div className="field-item">
              <TextArea
                label={null}
                className="wrapper-option"
                placeholder={"Please enter your remarks if any"}
                value={props.remark}
                onChange={(e) => {
                  props.onRemarksChange(e.target.value);
                }}
                error={props.remarksError}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
        <div className="form-info">
          <div className="info-1">
            <text className="label">Device ID</text>
            <text className="value">
              {props.viewErrorLogData?.deviceInfo?.serialNo ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Device Type</text>
            <text className="value">
              {props.viewErrorLogData?.deviceInfo?.deviceType?.type ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-2">
            <text className="label">Device Location</text>
            <text className="value">{addressDetails}</text>
          </div>
        </div>
        <div className="form-info">
          <div className="info-2">
            <text className="label">Hospital</text>
            <text className="value">
              {props.viewErrorLogData?.deviceInfo?.hospital?.hospitalInfo
                ?.hospitalName ?? "-"}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Status</text>
            <text className="value">
              {errorlLogEntryStatus}
              {/* {props.viewErrorLogData?.deviceInfo?.status == 1
                ? "Online"
                : props.viewErrorLogData?.deviceInfo?.status == 2
                ? "Offline"
                : props.viewErrorLogData?.deviceInfo?.status == 3
                ? "Error"
                : "-"} */}
            </text>
          </div>
          <div className="separator" />
          <div className="info-1">
            <text className="label">Severity</text>
            <text className="value">
              {props.viewErrorLogData?.severity == 1
                ? "Low"
                : props.viewErrorLogData?.severity == 2
                ? "Medium"
                : props.viewErrorLogData?.severity == 3
                ? "High"
                : "-" ?? "-"}
            </text>
          </div>
        </div>
        <div className="form-info">
          <div className="info-3">
            <div className="label">Error Description</div>
            <div className="value">
              {props.viewErrorLogData?.description ?? "-"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="error-logs-container-new">
      <CardView extraStyle="card-container">
        <div className="card-header-wraper">
          <div className="title">
            <text>
              Total {props.toggleHeader === "All" ? "" : props.toggleHeader}{" "}
              Errors: {props.totalErrors}
            </text>
          </div>

          <div className="search-and-buttons">
            <TogglebuttonGroup
              customToggleData={props.toggleData}
              selectedItemId={props.toggleValue}
              onItemSelected={props.onToggleChange}
            />

            <SearchView
              placeholder={"Search Error Logs"}
              onSearchClick={props.onSearchClick}
              onSearchTextCleared={props.onSearchTextCleared}
            />

            {/* <ThemedButton
              title="Download"
              size={"medium"}
              icon={<AiOutlineDownload size={15} color="#fff" />}
              onClick={() => {}}
              styles="primary medium"
            /> */}
          </div>
        </div>

        <div className="ps-2 pe-2">
          <ErrorLogsList
            page={props.page}
            limit={props.limit}
            totalErrors={props.totalErrors}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            userRole={props.userRole}
            errorLogList={props.errorLogList}
            //
            onViewClick={props.onViewClick}
            onEscalateActionClick={props.onEscalateActionClick}
            onInvestigateActionClick={props.onInvestigateActionClick}
            //
            toggleHeader={props.toggleHeader}
            toggleData={props.toggleData}
            //
            onErrorLogListFilterClick={props.onErrorLogListFilterClick}
            onErrorLogListSortClick={props.onErrorLogListSortClick}
          />
        </div>
      </CardView>

      <PopupDialog
        show={props.showViewErrorLogPopup}
        title={`${
          props.viewErrorLogData.deviceInfo?.serialNo ?? "-"
        } Error Log`}
        showCloseIcon={true}
        renderBodyContent={renderViewErrorLogPopupContent}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.closeAllPopups}
        hideConfirmButton={true}
      />

      <PopupDialog
        show={props.showUpdateErrorLogPopup}
        title={`${
          props.userRole === roles.superAdmin ? "Escalate" : "Investigate"
        } Error`}
        showCloseIcon={true}
        renderBodyContent={renderUpdateErrorLogPopupContent}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.onCancelButtonClick}
        confirmButtonTitle={"Send Email"}
        onclickConfirm={props.onSendEmailButtonClick}
        extraButtonTitle={"Send Email & Close Error"}
        onClickExtra={props.onSendEmailAndCloseButtonClick}
        showExtraButton={props.userRole === roles.superAdmin ? false : true}
        extraButtonStyle="primary-outline"
      />
    </div>
  );
};

export default ErrorLogsView;
