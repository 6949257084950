import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";
import {
  getDashboardData,
  getTopPerformers,
} from "../../../../../store/dashboard/dashboardActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import HospitalAdminView from "./HospitalAdminView";
import moment from "moment";

export class HospitalAdminDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",

      totalHospitals: 0,
      totalCenters: 0,
      totalDevices: 0,
      totalDoctors: 0,

      topDoctors: [],
      topCenters: [],

      hospitalAdminDropdownOptions: [
        { value: "year", label: "This Year" },
        { value: "month", label: "This Month" },
        { value: "week", label: "This Week" },
        { value: "today", label: "Today" },
      ],

      hospitalAdminTopDoctorsFilterSelected: {
        value: "month",
        label: "This Month",
      },

      hospitalAdminEcgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },

      devicesData: [
        { label: "Label A", countOne: 40, countTwo: 20, countThree: 15 },
        { label: "Label B", countOne: 25, countTwo: 50, countThree: 35 },
        { label: "Label C", countOne: 10, countTwo: 35, countThree: 15 },
      ],

      errorLogsData: [
        { label: "Label A", countOne: 40, countTwo: 20, countThree: 15 },
        { label: "Label B", countOne: 25, countTwo: 50, countThree: 35 },
        { label: "Label C", countOne: 10, countTwo: 35, countThree: 15 },
      ],
    };

    this.populateHospitalAdminData = this.populateHospitalAdminData.bind(this);

    this.onHospitalAdminTopDoctorsFilterChange =
      this.onHospitalAdminTopDoctorsFilterChange.bind(this);
    this.onHospitalAdminEcgReportsFilterChange =
      this.onHospitalAdminEcgReportsFilterChange.bind(this);
  }

  componentDidMount() {
    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
    });

    // Fetch Dashboard data for all users...
    if (loginUserRole) {
      this.props.showLoader();
      this.props.getDashboardData();

      setTimeout(() => {
        this.props.hideLoader();
        this.populateHospitalAdminData(this.props.dashboardData);
      }, 1500);
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.lastUpdatedTimestamp !== this.props.lastUpdatedTimestamp) {
      this.populateHospitalAdminData(newProps.dashboardData);
    }
  }

  // ---------------- Dashbaord Data Parsing -----------------------

  populateHospitalAdminData = (data) => {
    var dashboardData = data; //this.props.dashboardData; // Redux data

    // Create a new object to store the aggregated data from multiple hospitals
    const aggregatedData = {
      reports: {
        arrived: 0,
        assigned: 0,
        today: 0,
        yesterday: 0,
        day_wise: {},
        month_wise: {},
      },
      centers: {
        total: 0,
      },

      devices: {
        total: 0,
      },

      doctors: {
        active: 0,
        total: 0,
      },
    };

    if (dashboardData) {
      const allKeys = Object.keys(dashboardData);

      for (const k of allKeys) {
        const entry = dashboardData[k];

        console.log("ENTRY --->", JSON.stringify(entry));

        // Check if entry has reports
        if (entry.reports) {
          // Sum the "arrived," "assigned,", "today", and "yesterday" values
          aggregatedData.reports.arrived +=
            parseInt(entry.reports.arrived) || 0;
          aggregatedData.reports.assigned +=
            parseInt(entry.reports.assigned) || 0;
          aggregatedData.reports.today += parseInt(entry.reports.today) || 0;
          aggregatedData.reports.yesterday +=
            parseInt(entry.reports.yesterday) || 0;

          // Check if entry has day_wise property
          if (entry.reports.day_wise) {
            for (const date in entry.reports.day_wise) {
              const value = entry.reports.day_wise[date] || 0;
              if (aggregatedData.reports.day_wise.hasOwnProperty(date)) {
                aggregatedData.reports.day_wise[date] += parseInt(value);
              } else {
                aggregatedData.reports.day_wise[date] = parseInt(value);
              }
            }
          }
        }

        // Check if entry has centers and total property
        if (entry.centers && entry.centers.total) {
          // Sum the total centers
          aggregatedData.centers.total += parseInt(entry.centers.total) || 0;
        }

        // Check if entry has devices and total property
        if (entry.devices && entry.devices.total) {
          // Sum the total devices
          aggregatedData.devices.total += parseInt(entry.devices.total) || 0;
        }

        // Check if entry has doctors and active property
        if (entry.doctors && entry.doctors.active) {
          // Sum the total and active doctors
          aggregatedData.doctors.active += parseInt(entry.doctors.active) || 0;
          aggregatedData.doctors.total += parseInt(entry.doctors.total) || 0;
        }
      }
    }

    console.log("aggregatedData", JSON.stringify(aggregatedData));

    /*------------------------------------------

    // Logic to identify current month, and no. of days in it
    const allDayWise = dashboardData[0]?.reports?.day_wise;

    // First key is to identify month. so that we can extract no. of days to show in graph.
    var firstKey = "";
    if (allDayWise && Object.keys(allDayWise).length > 0) {
      firstKey = Object.keys(allDayWise)[0];
    } else {
      firstKey = moment().format("DD-MM-YYYY");
    }
    console.log("firstKey", firstKey);

    // Extract day, month and year from this key
    const [day, month, year] = firstKey.split("-").map(Number);
    const firstDate = new Date(year, month - 1, day);
    const lastDay = new Date(year, month, 0).getDate();
    console.log("firstDate", firstDate);
    console.log("lastDay", lastDay);

    //------------------------------------------

    // Now we need a array of all days in month in format of { day, report_count}
    const fullDaysInMonth = [];

    // We also need to show totalReports generated today, we will calculate it simultaneously
    var currentDay = moment().format("DD");
    currentDay = `${String(parseInt(currentDay))}-${month}-${year}`;

    var todaysReportsCount = 0;

    for (let i = 1; i <= lastDay; i++) {
      const formattedDay = `${i}`;
      const formattedMonth = `${month}`;
      const date = `${formattedDay}-${formattedMonth}-${year}`;
      fullDaysInMonth.push(date);
    }
    console.log("fullDaysInMonth", JSON.stringify(fullDaysInMonth));

    // use fullDaysInMonth array to create a new array for reports chart
    var barChartData = [];
    fullDaysInMonth.map((day) => {
      // A day is like : "1-11-2023", "10-11-2023", etc.
      if (String(day) === String(currentDay)) {
        todaysReportsCount = aggregatedData?.reports?.day_wise?.[day] ?? 0;
      }

      // For label in bar chart, we don't want "1-11-2023", "10-11-2023", instead we just want "1", "2", "10", etc.
      var dayLabel = day.substring(0, 2);
      if (dayLabel.charAt(1) === "-") {
        dayLabel = dayLabel.substring(0, 1);
      }

      barChartData.push({
        label: dayLabel,
        count: aggregatedData?.reports?.day_wise?.[day] ?? 0,
      });
    });

    console.log("barChartData", JSON.stringify(barChartData));
    console.log("currentDay : ", currentDay, "-->", todaysReportsCount);

    //------------------------------------------ 

    // We need to show totalReports of this month in Card.
    var dayWise = aggregatedData.reports.day_wise;
    let totalReportsOfMonth = 0;

    // Loop through the 'day_wise' object and sum up the values
    for (const date in dayWise) {
      if (dayWise.hasOwnProperty(date)) {
        totalReportsOfMonth += parseInt(dayWise[date]);
      }
    }

    var currentMonth = moment().format("MMMM YYYY");
    console.log("currentMonth : ", currentMonth, "-->", totalReportsOfMonth);

    // We also need to show totalDevices in Card.
    var totalDevices = parseInt(aggregatedData?.devices?.total) ?? 0;



    */

    // -------------- Calculate top centers and top doctors data  ---------------

    var topDoctorsData = dashboardData?.topDoctors ?? [];
    var topCentersData = dashboardData?.topCenters ?? [];

    var topDoctorsNew = [];
    var topCentersNew = [];

    topDoctorsData?.map((item) => {
      topDoctorsNew.push({
        label: item.name,
        count: parseInt(item.total ?? 0),
      });
    });

    topCentersData?.map((item) => {
      topCentersNew.push({
        label: item.name,
        count: parseInt(item.total ?? 0),
      });
    });

    // -------------- Update state variables for all cards data  ---------------

    var totalHospitals = dashboardData?.hospital?.total
      ? parseInt(dashboardData.hospital.total)
      : 0;

    this.setState({
      // Statistics Card
      totalHospitals: totalHospitals,
      totalCenters: aggregatedData.centers.total ?? 0,
      totalDevices: aggregatedData.devices.total ?? 0,
      totalDoctors: aggregatedData.doctors.total ?? 0,
      // Top Centers and Doctors
      topCenters: topCentersNew,
      topDoctors: topDoctorsNew,
      //Top Centers and Doctors selected filter reset
      hospitalAdminTopDoctorsFilterSelected: {
        value: "month",
        label: "This Month",
      },
      hospitalAdminEcgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },
    });
  };

  // ------------------------- Hospital Admin -------------------------

  onHospitalAdminTopDoctorsFilterChange = (selectItem) => {
    console.log("onHospitalAdminEcgReportsFilterChange : ", selectItem);
    this.setState({ hospitalAdminTopDoctorsFilterSelected: selectItem });

    var toggleVal = selectItem.value;
    var startDate = null;
    var endDate = null;

    if (toggleVal == "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }
    if (toggleVal == "week") {
      startDate = moment().startOf("week").unix();
      endDate = moment().endOf("week").unix();
    }
    if (toggleVal == "month") {
      startDate = moment().startOf("month").unix();
      endDate = moment().endOf("month").unix();
    }
    if (toggleVal == "year") {
      startDate = moment().startOf("year").unix();
      endDate = moment().endOf("year").unix();
    }

    var apiData = {
      entity: "doctor",
      duration: toggleVal === "today" ? "custom" : toggleVal,
      startTime: startDate,
      endTime: endDate,
      limit: 5,
    };

    console.log("ON SELECT : ", JSON.stringify(apiData));

    // Call API and Update top hospitals list

    this.props.getTopPerformers(apiData, (responseData) => {
      var topDoctors = responseData.topDoctors;
      console.log("ON SELECT : getTopPerformers: ", JSON.stringify(topDoctors));

      if (topDoctors.length > 0) {
        // Sort the array based on 'total' property in descending order
        topDoctors.sort((a, b) => parseInt(b.total) - parseInt(a.total));
      }

      var topDoctorsForBarChart = [];
      topDoctors.map((item) => {
        topDoctorsForBarChart.push({
          label: item.name,
          count: item.total,
        });
      });
      this.setState({ topDoctors: topDoctorsForBarChart });
    });
  };

  onHospitalAdminEcgReportsFilterChange = (selectItem) => {
    console.log("onHospitalAdminEcgReportsFilterChange : ", selectItem);
    this.setState({ hospitalAdminEcgReportsFilterSelected: selectItem });

    var toggleVal = selectItem.value;
    var startDate = null;
    var endDate = null;

    if (toggleVal == "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }
    if (toggleVal == "week") {
      startDate = moment().startOf("week").unix();
      endDate = moment().endOf("week").unix();
    }
    if (toggleVal == "month") {
      startDate = moment().startOf("month").unix();
      endDate = moment().endOf("month").unix();
    }
    if (toggleVal == "year") {
      startDate = moment().startOf("year").unix();
      endDate = moment().endOf("year").unix();
    }

    var apiData = {
      entity: "center",
      duration: toggleVal === "today" ? "custom" : toggleVal,
      startTime: startDate,
      endTime: endDate,
      limit: 5,
    };

    console.log("ON SELECT : ", JSON.stringify(apiData));

    // Call API and Update top hospitals list

    this.props.getTopPerformers(apiData, (responseData) => {
      console.log("responseData", responseData);
      var topCenters = responseData.topCenters;
      console.log("ON SELECT : getTopPerformers: ", JSON.stringify(topCenters));

      if (topCenters?.length > 0) {
        // Sort the array based on 'total' property in descending order
        topCenters.sort((a, b) => parseInt(b.total) - parseInt(a.total));
      }

      var topCentersForBarChart = [];
      topCenters?.map((item) => {
        topCentersForBarChart.push({
          label: item.name,
          count: item.total,
        });
      });
      console.log("topCentersForBarChart", topCentersForBarChart);
      this.setState({ topCenters: topCentersForBarChart });
    });
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (
      this.state.userRole === roles.hospitalAdmin ||
      this.state.userRole === roles.reportingHospitalAdmin
    ) {
      return (
        <HospitalAdminView
          loginUser={this.props.loginUser}
          userCreatedAt={this.state.userCreatedAt}
          //
          totalHospitals={this.state.totalHospitals}
          totalCenters={this.state.totalCenters}
          totalDevices={this.state.totalDevices}
          totalDoctors={this.state.totalDoctors}
          //
          topCenters={this.state.topCenters}
          topDoctors={this.state.topDoctors}
          //
          hospitalAdminDropdownOptions={this.state.hospitalAdminDropdownOptions}
          hospitalAdminTopDoctorsFilterSelected={
            this.state.hospitalAdminTopDoctorsFilterSelected
          }
          onHospitalAdminTopDoctorsFilterChange={
            this.onHospitalAdminTopDoctorsFilterChange
          }
          hospitalAdminEcgReportsFilterSelected={
            this.state.hospitalAdminEcgReportsFilterSelected
          }
          onHospitalAdminEcgReportsFilterChange={
            this.onHospitalAdminEcgReportsFilterChange
          }
          //
          devicesData={this.state.devicesData}
          errorLogsData={this.state.errorLogsData}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    dashboardData: state.dashboard.dashboardData,
    lastUpdatedTimestamp: state.dashboard.lastUpdatedTimestamp,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
  getTopPerformers,
})(HospitalAdminDashboardContainer);
