import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";
import {
  createMQTTConnection,
  subscribeToTopic,
  unSubscribeToTopic,
} from "../../../../../service/mqttApi";
import { history } from "../../../../../store/configure/configureStore";
import {
  getDashboardData,
  getTopPerformers,
} from "../../../../../store/dashboard/dashboardActions";
import {
  getDeviceListWithFilter,
  checkInDevice,
} from "../../../../../store/device/deviceActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import {
  getReportListWithFilter,
  ReportAccepance,
  ReportDownload,
} from "../../../../../store/report/reportActions";
import {
  saveNotification,
  refreshHomeScreen,
  resetRefreshHomeScreen,
} from "../../../../../store/mqtt/mqttActions";
import DoctorsView from "./DoctorsView";
import moment from "moment";

export class DoctorDashboardContainer extends Component {
  // List of report Ids from MQTT notifications array :
  // These are to be shown with 'NEW' tag.
  newRecords = [];

  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",
      doctorId: null,
      page: 1,
      limit: 10,
      totalReports: 0,
      reportsList: [],

      // Statistics Card
      previousMonthsData: [
        { name: "-", value: 0 },
        { name: "-", value: 0 },
        { name: "-", value: 0 },
      ],
      currentMonth: "",

      // MQTT Data recieved
      mqttData: "",

      // Variables Not used anymore :
      // newReportIds: {},
      // showNewReportPopup: false,
      // newReportDetails: {
      //   id: "",
      //   patientName: "",
      //   age: "",
      //   location: "",
      // },

      filters: { text: "" },
      searchStringParam: "",
    };

    this.populateDoctorsData = this.populateDoctorsData.bind(this);

    this.callGetReportsApi = this.callGetReportsApi.bind(this);
    this.doctorEnvSetupAndMqttSubscribe =
      this.doctorEnvSetupAndMqttSubscribe.bind(this);

    this.onReportsRefreshClick = this.onReportsRefreshClick.bind(this);
    this.onReportAcceptClick = this.onReportAcceptClick.bind(this);
    this.onReportRejectClick = this.onReportRejectClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);

    this.onECGReportDoctorDashboardListSortClick =
      this.onECGReportDoctorDashboardListSortClick.bind(this);

    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
  }

  componentDidMount() {
    var previousState = this.props.location?.state ?? {};

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;
    var prevFilter = previousState?.filters ?? { text: "" };
    // Connect to MQTT
    createMQTTConnection(() => {
      console.log("DOCTOR DASHBOARD : MQTT Connected!!!");
    });

    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
    });

    // For Doctor user : Save doctor Id, get reports list and subscribe to mqtt
    if (loginUserRole && loginUserRole === roles.doctor) {
      this.setState(
        {
          page: prevPage,
          limit: prevLimit,
          filters: prevFilter,
        },
        () => {
          window.history.replaceState({}, "");
          this.doctorEnvSetupAndMqttSubscribe();
          this.props.resetRefreshHomeScreen();

          this.props.showLoader();
          this.props.getDashboardData();
          setTimeout(() => {
            this.props.hideLoader();
            this.populateDoctorsData(this.props.dashboardData);
          }, 1500);
        }
      );
    }
  }

  componentWillUnmount() {
    // clearInterval(this.clearNewReportIdsForDoctor);
    // this.clearNewReportIdsForDoctor = null;
  }

  componentWillReceiveProps(newProps) {
    this.populateDoctorsData(newProps.dashboardData);

    console.log("-----------------------------------------------------");
    console.log("homeScreenRefreshed :  ", newProps.homeScreenRefreshed);
    console.log(
      "notificationDeletedTimestamp :  ",
      this.props.notificationDeletedTimestamp,
      " --> ",
      newProps.notificationDeletedTimestamp
    );

    //-----------------------------------------------------------------
    // Set to true when a new notification is added (New MQTT recieved)
    if (newProps.homeScreenRefreshed == true) {
      this.page = 1;
      this.lastPage = 0;
      this.setState({ totalReports: 0, reportsList: [] });
      this.callGetReportsApi();
      this.props.resetRefreshHomeScreen();
    }

    //-----------------------------------------------------------------
    // Updated when a notification is deleted (Post 1 min of adding)
    if (
      newProps.notificationDeletedTimestamp !==
      this.props.notificationDeletedTimestamp
    ) {
      this.newRecords = this.props.notifications
        .filter(
          (item) =>
            item.status === "new_report" &&
            (new Date() - new Date(item.timestamp)) / 60000 <= 1
        )
        .map((item) => item.id);
    }
  }

  // ----------------------- saerch content ----------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        {
          page: 1,
          filters: { ...this.state.filters, text },
          searchStringParam: null,
        },
        () => {
          this.callGetReportsApi();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      {
        page: 1,
        filters: { ...this.state.filters, text: "" },
        searchStringParam: null,
      },
      () => {
        this.callGetReportsApi();
      }
    );
  };

  // ------------------- Initialization ------------------------

  doctorEnvSetupAndMqttSubscribe = () => {
    // Set doctor Id in state variable
    this.setState({
      doctorId: this.props.loginUser?.userProfile?.id ?? null,
    });

    // Ask for browser notifications permission
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    // Get Pending reports list from API
    this.callGetReportsApi();

    // Subscribe to MQTT topic for recieving browser notifications and dynamic list updating
    var hospitalId =
      this.props.loginUser?.userProfile?.doctorHospitalInfo?.hospitalId ?? null;

    subscribeToTopic(`device/reportRequest/reportData/${hospitalId}`, (msg) => {
      const data = JSON.parse(msg);

      // console.log("mqtt msg : ");
      // console.log({ msg });

      console.log("mqtt data : ");
      console.log(JSON.stringify(data));

      new Notification("New Report Request", {
        body: "New report request received, kindly accept and proceed for report analysis.",
      });

      this.props.saveNotification(
        data.reportDetails.id,
        "new_report",
        new Date().getTime()
      );

      this.props.refreshHomeScreen();
    });

    /* --------- Old Code before adding 'mqtt' redux module  ------------

    var newReportIdsObject = this.state.newReportIds;
    newReportIdsObject[data.reportDetails.id] = {
      timestamp: moment().format("DD-MM-YYYY, HH:mm:ss"),
    };
    console.log("newReportIds  ::: ", JSON.stringify(newReportIdsObject));
    this.setState({ newReportIds: newReportIdsObject });

    // Fetch updated report list on recieveing new MQTT report data
    this.props.getReportListWithFilter(
      { isRecSentToSp: false },
      (list, count) => {
        for (let index = 0; index < list.length; index++) {
          const reportItem = list[index];
          // console.log("reportItem  ::: ", reportItem.id);

          if (newReportIdsObject.hasOwnProperty(reportItem.id)) {
            reportItem["isNew"] = true; // To show 'NEW' tag in Dynamic List
          }
        }

        this.setState({ totalReports: count, reportsList: list });
      }
    );

    // Clear interval first then re-start it
    clearInterval(this.clearNewReportIdsForDoctor);

    // Setup interval to clear these new ids after 1 minute
    this.clearNewReportIdsForDoctor = setInterval(() => {
      var newReportIdsInState = this.state.newReportIds;
      var allNewReportIds = Object.entries(newReportIdsInState);
      console.log("clearNewReportIdsForDoctor Interval Called ........");

      allNewReportIds.map((reportItem) => {
        var reportId = reportItem[0];
        var reportTimestamp = reportItem[1].timestamp;
        var reportItemExpiry = moment(reportTimestamp, "DD-MM-YYYY, HH:mm:ss")
          .add(1, "minute")
          .format("DD-MM-YYYY, HH:mm:ss");
        console.log();
        var currentTime = moment().format("DD-MM-YYYY, HH:mm:ss");

        console.log(
          "report : ",
          reportId,
          ", reportItemExpiry : ",
          reportItemExpiry,
          ", currentTime : ",
          currentTime
        );

        if (
          moment(reportItemExpiry, "DD-MM-YYYY, HH:mm:ss").isSameOrBefore(
            moment(currentTime, "DD-MM-YYYY, HH:mm:ss")
          )
        ) {
          console.log("EXPIRED!!!");

          var currentReportList = this.state.reportsList;
          for (let index = 0; index < currentReportList.length; index++) {
            const reportListItem = currentReportList[index];
            // console.log("reportItem  ::: ", reportItem.id);

            if (reportListItem.id === reportId) {
              reportListItem["isNew"] = false; // Remove 'NEW' tag in Dynamic List item
              delete newReportIdsInState[reportId]; // Remove this reportId from state variable of newReportIds
            }
          }

          this.setState({
            reportsList: currentReportList,
            newReportIds: newReportIdsInState,
          });
        } else {
          console.log("NOT EXPIRED....");
        }
      });
    }, 60000);

    //  ------------ Old Code to Notification -----------------
    //  navigator.serviceWorker.ready
    //   .then(registration => {
    //     registration.showNotification("New Report Request", {
    //       body: "New report request received, kindly accept and proceed for report analysis.",
    //       actions: [{ action: "VIEW", title: "View" }],
    //       data: {
    //         id: data.reportDetails.id,
    //         patientName: data.reportDetails.patientInfo.firstName,
    //         age: data.reportDetails.patientInfo.age,
    //         location: data.reportDetails.patientInfo.addressInfo.locationName,
    //       },
    //     });
    //   })
    //   .catch(error => {
    //     console.error(error.message);
    //   });

    // ------------ Old Code to Display Popup -----------------
    // this.setState({
    //   showNewReportPopup: true,
    //   mqttData: data,
    //   newReportDetails: {
    //     id: data.reportDetails.id,
    //     patientName: data.reportDetails.patientInfo.firstName,
    //     age: data.reportDetails.patientInfo.age,
    //     location: data.reportDetails.patientInfo.addressInfo.locationName,
    //   },
    // });

    */
  };

  callGetReportsApi = () => {
    // To view  pending / unaccepted reports, send isRecSentToSp = false.
    // For accepted reports submitted by current doctor, no flag is required.

    this.props.getReportListWithFilter(
      { isRecSentToSp: false, text: this.state.filters.text },
      (list, count) => {
        // Update newRecords array to show newly arrived report with 'NEW' tag
        this.newRecords = this.props.notifications
          .filter(
            (item) =>
              item.status === "new_report" &&
              (new Date() - new Date(item.timestamp)) / 60000 <= 1
          )
          .map((item) => item.id);

        // Then update Report list
        this.setState({ totalReports: count, reportsList: list });
      },
      this.state.page,
      this.state.limit
    );
  };

  // ---------------- Dashbaord Data Parsing -----------------------

  populateDoctorsData = (data) => {
    var dashboardData = data; //this.props.dashboardData; // Redux data
    // console.log("DASHBOARD DATA Doctor : ", JSON.stringify(dashboardData));

    // -------------- Reports Card calculation   ---------------

    /*  
    
    // Logic to identify current month, and no. of days in it
    const allDayWise = dashboardData?.reports?.day_wise;

    // First key is to identify month. so that we can extract no. of days to show in graph.
    var firstKey = "";
    if (allDayWise && Object.keys(allDayWise).length > 0) {
      firstKey = Object.keys(allDayWise)[0];
    } else {
      firstKey = moment().format("DD-MM-YYYY");
    }
    console.log("firstKey", firstKey);

    // Extract day, month and year from this key
    const [day, month, year] = firstKey
      ? firstKey.split("-").map(Number)
      : [1, 1, 2023];

    const firstDate = new Date(year, month - 1, day);
    const lastDay = new Date(year, month, 0).getDate();
    console.log("firstDate", firstDate);
    console.log("lastDay", lastDay);

    */

    //----------------------------------------------------------

    // Logic to identify current month, last three months data
    const allMonthWise = dashboardData?.reports?.month_wise;
    // console.log("allMonthWise : ", JSON.stringify(allMonthWise));

    var currentMonth = moment().format("MM-YYYY");
    // console.log("currentMonth : ", currentMonth);

    if (allMonthWise && Object.keys(allMonthWise).length > 0) {
      var previousMonthsData = [];

      for (let index = 2; index >= 0; index--) {
        var previousMonthLabel = moment(currentMonth, "MM-YYYY")
          .subtract(parseInt(index), "months")
          .format("M-YYYY");

        // console.log("previousMonthLabel : ", previousMonthLabel);

        if (allMonthWise.hasOwnProperty(previousMonthLabel)) {
          var previousMonthCount = allMonthWise[previousMonthLabel];

          previousMonthsData.push({
            name: moment(previousMonthLabel, "MM-YYYY").format("MMM"),
            value: previousMonthCount,
          });
        } else {
          previousMonthsData.push({
            name: moment(previousMonthLabel, "MM-YYYY").format("MMM"),
            value: 0,
          });
        }
      }

      // console.log("previousMonthsData : ", JSON.stringify(previousMonthsData));

      // Statistics Card Data
      this.setState({ previousMonthsData: previousMonthsData });
    }

    //------------------------------------------

    /*

    // Now we need a array of all days in month in format of {day, report_count}
    const fullDaysInMonth = [];

    // We also need to show totalReports generated today, we will calculate it simultaneously
    var currentDay = moment().format("DD");
    currentDay = `${String(parseInt(currentDay))}-${month}-${year}`;

    var todaysReportsCount = 0;

    for (let i = 1; i <= lastDay; i++) {
      const formattedDay = `${i}`;
      const formattedMonth = `${month}`;
      const date = `${formattedDay}-${formattedMonth}-${year}`;
      fullDaysInMonth.push(date);
    }

    console.log("fullDaysInMonth", JSON.stringify(fullDaysInMonth));

    // use fullDaysInMonth array to create a new array for reports chart
    var barChartData = [];

    fullDaysInMonth.map((day) => {
      // A day is like : "1-11-2023", "10-11-2023", etc.
      if (String(day) === String(currentDay)) {
        todaysReportsCount = parseInt(
          dashboardData?.reports?.day_wise?.[day] ?? 0
        );
      }

      // For label in bar chart, we don't want "1-11-2023", "10-11-2023", instead we just want "1", "2", "10", etc.
      var dayLabel = day.substring(0, 2);
      if (dayLabel.charAt(1) === "-") {
        dayLabel = dayLabel.substring(0, 1);
      }

      barChartData.push({
        label: dayLabel,
        count: parseInt(dashboardData?.reports?.day_wise?.[day] ?? 0),
      });
    });

    // -------------- Update state variables for all cards data  ---------------

    this.setState({
      // Reports Bar Chart
      reportsData: barChartData,
      currentMonth: currentMonth,
    });

    */
  };

  // -------------------------- Doctor ---------------------------

  onReportsRefreshClick = () => {
    console.log("refresh clicked....");
    this.setState({ page: 1, limit: 10 });
    this.handlePageChange(1);
  };

  onReportAcceptClick = (reportId) => {
    this.props.ReportAccepance(
      reportId,
      { doctorId: this.state.doctorId },
      () => {
        var hospitalId =
          this.props.loginUser?.userProfile?.doctorHospitalInfo?.hospitalId ??
          null;
        unSubscribeToTopic(`device/reportRequest/reportData/${hospitalId}`);
        // history.push(`ecg_report_details/${reportId}`);
        history.push(`ecg_report_details?id=${reportId}`);
        // history.push({
        //   pathname: `ecg_report_details?id=${reportId}`,
        //   state: {
        //     page: this.state.page,
        //     limit: this.state.limit,
        //     filters: this.state.filters,
        //   },
        // });
      }
    );
  };

  onReportRejectClick = () => {
    // Do nothing..
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalReports;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
      },
      () => {
        this.callGetReportsApi();
      }
    );
  };

  handlePageChange = (page) => {
    console.log("handlePageChange");
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetReportsApi();
      }
    );
  };

  //sorting filters

  onECGReportDoctorDashboardListSortClick = (columnName) => {
    console.log(" sorting column name :", columnName);
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.doctor) {
      return (
        <DoctorsView
          loginUser={this.props.loginUser}
          userCreatedAt={this.state.userCreatedAt}
          //
          previousMonthsData={this.state.previousMonthsData}
          //
          reportList={this.state.reportsList}
          totalReports={this.state.totalReports}
          newRecords={this.newRecords}
          onReportAcceptClick={this.onReportAcceptClick}
          onReportRejectClick={this.onReportRejectClick}
          //
          page={this.state.page}
          limit={this.state.limit}
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          onReportsRefreshClick={this.onReportsRefreshClick}
          onECGReportDoctorDashboardListSortClick={
            this.onECGReportDoctorDashboardListSortClick
          }
          //
          onSearchTextCleared={this.onSearchTextCleared}
          onSearchClick={this.onSearchClick}
          searchStringParam={this.state.searchStringParam}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    profilePicture: state.auth.profilePicture,
    dashboardData: state.dashboard.dashboardData,
    filteredReportList: state.report.filteredReportList,
    filteredDeviceList: state.device.filteredDeviceList,
    selectedCheckInDevice: state.device.selectedCheckInDevice,
    lastUpdatedTimestamp: state.dashboard.lastUpdatedTimestamp,
    //
    notifications: state.mqtt.newNotifications,
    notificationDeletedTimestamp: state.mqtt.notificationDeletedTimestamp,
    homeScreenRefreshed: state.mqtt.homeScreenRefreshed,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
  getTopPerformers,
  getReportListWithFilter,
  getDeviceListWithFilter,
  checkInDevice,
  ReportAccepance,
  ReportDownload,
  saveNotification,
  refreshHomeScreen,
  resetRefreshHomeScreen,
})(DoctorDashboardContainer);
