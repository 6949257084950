import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { globalConstants } from "../constants/globalConstants";

export const getWelcomeGreetings = () => {
  const hours = moment().startOf("day").fromNow(); // Get current hour (24-hour format)
  const currentHour = hours.slice(0, 2);
  let greetMessage = "";

  if (currentHour < 5) {
    greetMessage = "Welcome";
  } else if (currentHour < 12) {
    greetMessage = "Good morning";
  } else if (currentHour < 18) {
    greetMessage = "Good afternoon";
  } else if (currentHour < 21) {
    greetMessage = "Good evening";
  } else {
    greetMessage = "Welcome";
  }
  return greetMessage;
};

//----------------------------------------------------------------------

// Save data in Secure Local Storage in encrypted format!
export const saveLoginDetailsInSecureStorage = (loginData) => {
  var CryptoJS = require("crypto-js");

  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(loginData),
    globalConstants.CRYPTO_JS_KEY
  ).toString();

  console.log("LOGIN DATA encrypted", ciphertext);

  // Save data in Secure Local Storage
  secureLocalStorage.setItem(globalConstants.REMEMBER_ME_KEY, true);
  secureLocalStorage.setItem(globalConstants.LOGIN_DATA_KEY, ciphertext);
};

// Fetch data in Secure Local Storage and decrypt it!
export const extractLoginDetailsFromSecureStorage = () => {
  var CryptoJS = require("crypto-js");

  var rememberMeSelected = secureLocalStorage.getItem(
    globalConstants.REMEMBER_ME_KEY
  );
  var loginDataSaved = secureLocalStorage.getItem(
    globalConstants.LOGIN_DATA_KEY
  );

  var decryptedData = {};
  if (rememberMeSelected) {
    console.log("LOGIN: REMEMBER ME was selecetd! ");

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      loginDataSaved,
      globalConstants.CRYPTO_JS_KEY
    );
    decryptedData = JSON.parse(
      bytes !== null ? bytes.toString(CryptoJS.enc.Utf8) : {}
    );
    console.log("LOGIN DATA decrypted", decryptedData);
  }
  return decryptedData;
};

// Update data items in Secure Local Storage
export const removeLoginDetailsFromSecureStorage = () => {
  secureLocalStorage.setItem(globalConstants.REMEMBER_ME_KEY, false);
  secureLocalStorage.setItem(globalConstants.LOGIN_DATA_KEY, {});
};

//----------------------------------------------------------------------

export const trimFormData = (data) => {
  const newData = {};
  for (let field in data) {
    if (typeof data[field] === "string") {
      newData[field] = data[field].trim();
    } else {
      newData[field] = data[field];
    }
  }
  return newData;
};

//----------------------------------------------------------------------

export const phoneMask = (val) => {
  let arr = String(val).trim().split("");
  const num = Number(arr[arr.length - 1]);
  if (!Number.isInteger(+num)) {
    arr.pop();
  }
  arr = arr.filter((item) => Number.isInteger(+item) && item !== " ");
  arr.length && arr.splice(0, 0, "(");
  arr.length > 4 && arr.splice(4, 0, ") ");
  arr.length > 8 && arr.splice(8, 0, "-");
  return arr.join("").slice(0, 14);
};

export const getAllNum = (string) => {
  if (!string) return "";
  return string.toString().replace(/\D+/g, "");
};

export const formatter = (number, params) => {
  if (!number && number !== 0) return false;
  let maxDigit = 2;
  let letter = "";

  if (params?.style !== "percent") {
    if (Math.abs(number) < 5) maxDigit = 4;
    if (Math.abs(number) < 0.01) maxDigit = 4;
    // if (Math.abs(number) < 0.001) maxDigit = 4;
    if (Math.abs(number) < 0.0001) maxDigit = 5;
    if (Math.abs(number) < 0.00001) maxDigit = 6;
    if (Math.abs(number) < 0.000001) maxDigit = 7;
    if (Math.abs(number) < 0.0000001) maxDigit = 8;
  }

  if (params?.showLetter) {
    if (number > 999999 && number < 1000000000) {
      letter = "M";
      number = number / 1000000;
      maxDigit = 3;
    }
    if (number > 999999999) {
      letter = "B";
      number = number / 1000000000;
    }
  }

  const options = {
    style: "decimal",
    currency: "USD",
    maximumFractionDigits: maxDigit,
    ...params,
  };

  const formatter = new Intl.NumberFormat("en-US", options);

  if (params?.showLetter) return formatter.format(number) + letter;

  return formatter.format(number);
};

export const formatPrice = (stringNumber) => {
  let numbers = String(stringNumber).replace(/\D+/g, "");

  const options = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat("en-US", options);

  return formatter.format(+numbers / 100);
};

export const reFormatPrice = (stringPrice) => {
  // => 1232324.88
  return +stringPrice.toString().replace(/,|\$/g, "");
};

export const ignoreOrderArrayComparison = (a, b) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

// cal stemi-case status for Hospital admin

export const evaluateCaseSourceDestination = (
  transferDataArray = [],
  HospitalIds = []
) => {
  let isSource = false;
  let isDestination = false;
  let sourceId = null;
  let destinationId = null;
  let isTansferDetailsConfirmed = null;
  let transferId = null;
  let status = null;

  // First sort transfer Entries Array based on timestamp or transfer id (we need them sequentially!)
  const caseTransferData = transferDataArray.sort(
    (a, b) => parseInt(a.id) - parseInt(b.id)
  );

  // console.log("caseTransferData : ", caseTransferData);

  if (caseTransferData.length > 0) {
    for (let i = 0; i < caseTransferData.length; i++) {
      const transferEntry = caseTransferData[i];
      // console.log("TRANSFER RECORD : ", caseTransferData[i]);

      let sId = transferEntry.transferredFromHospitalId;
      let dId = transferEntry.transferredToHospitalId;

      isSource = false;
      isDestination = false;

      for (let j = 0; j < HospitalIds.length; j++) {
        if (sId == HospitalIds[j]) {
          // console.log("is source: true");
          isSource = true;
          sourceId = sId;
          isTansferDetailsConfirmed = false;
          transferId = transferEntry?.id;
          status = transferEntry?.status;
        }
        if (dId == HospitalIds[j]) {
          // console.log("is dest: true");
          isDestination = true;
          destinationId = dId;
          isTansferDetailsConfirmed = transferEntry.isTansferDetailsConfirmed;
          transferId = transferEntry?.id;
          status = transferEntry?.status;
        }
      }
    }
    return {
      isSource:
        isSource && !isDestination
          ? true
          : isSource && isDestination
          ? false
          : false,
      sourceId,
      destinationId,
      isTansferDetailsConfirmed,
      transferId,
      status,
    };
  } else {
    return null;
  }
};

export const getOngoingOutwardTransferId = (transferArray, hospitalIds) => {
  if (transferArray.length > 0) {
    let outgoingTransferId = null;

    for (let i = 0; i < transferArray.length; i++) {
      const transterElemet = transferArray[i];

      for (let j = 0; j < hospitalIds.length; j++) {
        if (
          transterElemet.transferredFromHospitalId == hospitalIds[j] && // If transfer's source belongs to this admin,
          transterElemet.isTansferDetailsConfirmed == false // And this transfer is not yet completed
        ) {
          outgoingTransferId = transterElemet.id;
        }
      }
    }
    return outgoingTransferId;
  } else {
    return null;
  }
};
