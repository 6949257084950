import React from "react";
import { ThemedButton } from "../../../../controls";
import "../HospitalCenter/HospitalCenterStyles.scss";

const CenterCard = ({
  name,
  onHandleEditCenterOpenModal,
  onDeleteCenterCardClick,
  addressLine1,
  mobile,
  location,
}) => {
  return (
    <div className="center_card_container">
      <div className="card_header">
        <div className="card_title_list">{` ${name ? name : "NA"}`}</div>
        <div className="card_operation">
          <ThemedButton
            styles={"link"}
            onClick={() => {
              onHandleEditCenterOpenModal();
            }}
            title={"Edit"}
          />

          <div className="sep" />

          <ThemedButton
            styles={"link"}
            onClick={() => {
              onDeleteCenterCardClick();
            }}
            title={"Delete"}
          />
        </div>
      </div>
      <div className="separator" />

      <div className="card_body_main">
        <div className="card_body_title">
          {/* <div className="hospital_center_body_label">
                        <span style={{ color: "#c3c3c3e6", fontSize: "14px" }}>
                          Name
                        </span>
                        :{" "}
                        {` ${
                          item?.center?.centerName
                            ? item?.center?.centerName
                            : "NA"
                        }`}
                      </div> */}

          <div className="card_body_label">
            <text className="title">Address :</text>
            <text>
              {" "}
              {`${addressLine1 && addressLine1}, ${location && location}`}
            </text>
          </div>

          <div className="card_body_label">
            <text className=" title">Contact :</text>
            <text> {` ${mobile ? mobile : "NA"}`}</text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterCard;
