import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { globals } from "../../store/globals";

import RestrictedRoute from "../restricted/index";
import LoginContainer from "./Login/LoginContainer";
import ForgotPasswordContainer from "./ForgotPassword/ForgotPasswordContainer";
import TestScreenContainer from "./NewUIPages/TestScreen/TestScreenContainer";

import NewDashboardContainer from "./NewUIPages/Dashboards/DashboardContainer";
import AdministratorsContainer from "./NewUIPages/Administrators/AdministratorsContainer";
import AdminsHospitalsContainer from "./NewUIPages/Administrators/AdminsHospitals/AdminsHospitalsContainer";
import HospitalsContainer from "./NewUIPages/Hospitals/HospitalsContainer";
import CentersContainer from "./NewUIPages/Hospitals/HospitalsCenters/CentersContainer";
import DevicesContainer from "./NewUIPages/Devices/DevicesContainer";
import DoctorsContainer from "./NewUIPages/Doctors/DoctorsContainer";
import EcgReportsContainer from "./NewUIPages/EcgReports/EcgReportsContainer";
import EcgReportsByDoctorContainer from "./NewUIPages/EcgReportsByDoctor/EcgReportsByDoctorContainer";
import ECGReportDetailsContainer from "./NewUIPages/ECGReportDetails/ECGReportDetailsContainer";
import ErrorLogsContainer from "./NewUIPages/ErrorLogs/ErrorLogsContainer";
import TechniciansContainer from "./NewUIPages/Technicians/TechniciansContainer";
import MyProfileContainer from "./NewUIPages/MyProfile/MyProfileContainer";
import HospitalInfoContainer from "./NewUIPages/HospitalInfo/HospitalInfoContainer";
import AmbulancesContainer from "./NewUIPages/Ambulances/AmbulancesContainer";

import { createMQTTConnection } from "../../service/mqttApi";
import { BigLoader } from "../common";
import SupervisorContainer from "./NewUIPages/Supervisor/SupervisorContainer";
import CasesContainer from "./NewUIPages/Cases/CasesContainer";
import CaseDetailsContainer from "./NewUIPages/Cases/CaseDetails/CaseDetailsContainer";

function App({ match }) {
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   createMQTTConnection(() => {
  //     setIsLoading(false);
  //   });
  // }, []);

  if (isLoading) {
    return <BigLoader />;
  }

  var defaultRedirection = (
    <Redirect exact from={`${match.url}/`} to={`${match.url}dashboard_new`} />
  );

  var routes = [
    {
      component: NewDashboardContainer,
      link: "home",
    },
    {
      component: TestScreenContainer,
      link: "test_screen",
    },

    // ------------ NEW UI PAGES START HERE --------------
    {
      component: NewDashboardContainer,
      link: "dashboard_new",
    },
    {
      component: AdministratorsContainer,
      link: "administrators",
    },
    {
      component: AdminsHospitalsContainer,
      link: "administrators/hospitals",
    },
    {
      component: HospitalsContainer,
      link: "hospitals",
    },
    {
      component: CentersContainer,
      link: "hospitals/centers",
    },

    {
      component: DevicesContainer,
      link: "devices",
    },
    {
      component: DoctorsContainer,
      link: "doctors",
    },
    {
      component: TechniciansContainer,
      link: "technicians",
    },
    {
      component: EcgReportsContainer,
      link: "ecg_reports",
    },
    {
      component: EcgReportsByDoctorContainer,
      link: "ecg_reports_by_doctor",
    },
    {
      component: ECGReportDetailsContainer,
      link: "ecg_report_details",
    },
    {
      component: ErrorLogsContainer,
      link: "error_logs",
    },
    {
      component: MyProfileContainer,
      link: "my_profile",
    },

    {
      component: HospitalInfoContainer,
      link: "hospital_info",
    },
    {
      component: AmbulancesContainer,
      link: "ambulances",
    },
    {
      component: SupervisorContainer,
      link: "supervisors",
    },
    {
      component: CasesContainer,
      link: "cases",
    },
    {
      component: CaseDetailsContainer,
      link: "case_details",
    },
  ];

  var login_routes = [
    {
      component: LoginContainer,
      link: "/login",
    },
    {
      component: ForgotPasswordContainer,
      link: "/forgot_password",
    },
  ];

  return (
    <Switch>
      {defaultRedirection}

      {routes.map((element) => (
        <RestrictedRoute
          exact // Important!!!
          path={`${match.url}${element.link}`}
          component={element.component}
        />
      ))}

      {login_routes.map((element) => (
        <Route exact path={element.link} component={element.component} />
      ))}
    </Switch>
  );
}

export default App;
