import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { ThemedButton } from "../../../../controls";

const PopupDialog = ({
  show,
  title,
  showCloseIcon,
  renderBodyContent,
  confirmButtonTitle,
  cancelButtonTitle,
  onclickConfirm,
  onclickCancel,
  extraStyle,
  popupSize = "lg", // Can be lg, md, sm (Width of popup)
  hideButtons = false, // By default buttons are shown
  hideConfirmButton,
  onClickExtra,
  extraButtonTitle = "",
  extraButtonStyle = "",
  showExtraButton = false,
  hideCancelButton = false,
  disabledExtraButton = false,
  disabledConfirmButton = false,
  disabledCancelButton = false,
  overrideFunction,
  overrideFunctionEnable = false,
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          onclickCancel();
        }}
        size={popupSize}
        backdrop="static" // to disable closing popup on backdrop click
        centered
      >
        <Modal.Header closeButton={showCloseIcon}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={extraStyle}>{renderBodyContent()}</Modal.Body>

        {hideButtons ? null : (
          <Modal.Footer>
            {hideCancelButton ? null : (
              <ThemedButton
                title={cancelButtonTitle}
                size={"small"}
                styles="grey-outline"
                disabled={disabledCancelButton}
                onClick={() => {
                  overrideFunctionEnable ? overrideFunction() : onclickCancel();
                }}
              />
            )}
            {showExtraButton ? (
              <ThemedButton
                title={extraButtonTitle}
                size={"small"}
                styles={`${extraButtonStyle}`}
                disabled={disabledExtraButton}
                onClick={() => {
                  onClickExtra();
                }}
              />
            ) : null}
            {hideConfirmButton ? null : (
              <ThemedButton
                title={confirmButtonTitle}
                size={"small"}
                disabled={disabledConfirmButton}
                onClick={() => {
                  onclickConfirm();
                }}
              />
            )}
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

PopupDialog.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  renderBodyContent: PropTypes.func,
  confirmButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  onclickConfirm: PropTypes.func,
  onclickCancel: PropTypes.func,
};

PopupDialog.defaultProps = {
  show: false,
  title: "",
  showCloseIcon: true,
  confirmButtonTitle: "Confirm",
  cancelButtonTitle: "Cancel",
};

export default PopupDialog;
