import React, { useEffect } from "react";
import { DataTable } from "../../../../common";
import { FaSort } from "react-icons/fa6";
import {
  evaluateCaseSourceDestination,
  getOngoingOutwardTransferId,
} from "../../../../../utils/common";
import { ThemedButton, IconMenuOption } from "../../../../controls";
import { AiFillFilter } from "react-icons/ai";
import "./ListStyles.scss";
import { globalConstants } from "../../../../../constants/globalConstants";

const StemiCasesList = (props) => {
  // passing props to data variable
  const data = props.data;

  useEffect(() => {
    console.log("CASE LIST LIMIT: ", props.limit);
  }, [props.limit]);

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onCaseListSortClick !== null
                  ? () => props.onCaseListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const onlineColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const inTransitColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#1890FF",
    };
    const closedColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const Transferred = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: " #FF7A45",
    };

    const Options = [
      { label: "Active", icon: <div style={onlineColor} /> },
      { label: "Closed", icon: <div style={closedColor} /> },
    ];

    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              showSelectedItem={true}
              columnName={label}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onCaseListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderViewAcceptRejectAction = (row) => {
    var displayStatus = row.displayStatus;
    var caseAccepted = row?.acceptedStatus ?? false;
    let showAllThreeButtons = true;

    if (
      displayStatus === "Closed" ||
      displayStatus === "Admit" ||
      displayStatus === "Transferred"
    ) {
      showAllThreeButtons = false;
    }

    if (
      displayStatus === "In Transit" ||
      displayStatus === "In Transit (Transfer)"
    ) {
      if (caseAccepted) {
        showAllThreeButtons = false;
      } else {
        showAllThreeButtons = true;
      }
    }

    // var UIType = row.caseUIType;
    // Logic to show all 3 buttons or just View Button - Case coming from technician
    // if (
    //   UIType === globalConstants.CASE_UI_FOR_CLOSED ||
    //   UIType === globalConstants.CASE_UI_FOR_ADMIT ||
    //   UIType === globalConstants.CASE_UI_FOR_TRANSFERRED
    // ) {
    //   showAllThreeButtons = false;
    // }

    // if (
    //   UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ||
    //   UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT ||
    //   UIType === globalConstants.CASE_UI_FOR_ARRIVED
    // ) {
    //   if (caseAccepted) {
    //     showAllThreeButtons = false;
    //   } else {
    //     showAllThreeButtons = true;
    //   }
    // }

    return (
      <div className="action-wrapper">
        {showAllThreeButtons ? (
          <>
            <ThemedButton
              title="View"
              styles="link"
              onClick={
                props.onViewCaseClick !== null
                  ? () => props.onViewCaseClick(row)
                  : () => {}
              }
            />
            <div className="partition-block" />
            <ThemedButton
              title="Accept"
              styles="link"
              onClick={
                props.onAcceptCaseClick !== null
                  ? () => props.onAcceptCaseClick(row, false)
                  : () => {}
              }
            />
            <div className="partition-block" />
            <ThemedButton
              title="Reject"
              styles="danger-link"
              onClick={
                props.onRejectCaseClick !== null
                  ? () => props.onRejectCaseClick(row, false)
                  : () => {}
              }
            />
          </>
        ) : (
          <ThemedButton
            title="View"
            styles="link"
            onClick={
              props.navigateToCaseDetails !== null
                ? () => props.navigateToCaseDetails(row.id)
                : () => {}
            }
          />
        )}
      </div>
    );
  };

  const renderStatus = (row) => {
    // var UIType = row.caseUIType;

    var status = row?.displayStatus ?? "";
    var statusStyle = null;

    const Closed = { backgroundColor: "#D9D9D9" };
    const Admit = { backgroundColor: "#52C41A" };
    const Transferred = { backgroundColor: "rgba(255, 122, 69, 1)" };
    const InTransit = { backgroundColor: "#1890FF" };
    const Arrived = { backgroundColor: "#87D068" };

    if (status === "Closed") {
      statusStyle = Closed;
    }

    if (status === "Admit") {
      statusStyle = Admit;
    }

    if (status === "Transferred") {
      statusStyle = Transferred;
    }

    if (status === "In Transit") {
      statusStyle = InTransit;
    }

    if (status === "In Transit (Transfer)") {
      statusStyle = InTransit;
    }

    if (status === "Arrived") {
      statusStyle = Arrived;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text className="to-stemi-case">{status}</text>
      </div>
    );
  };

  const renderTechnicianName = (row) => {
    const technicianName = row?.reportInfo?.technicianInfo;
    return technicianName !== null && technicianName !== undefined
      ? `${
          (technicianName?.firstName !== null &&
          technicianName?.firstName !== undefined
            ? `${technicianName?.firstName
                .charAt(0)
                .toUpperCase()}${technicianName?.firstName.slice(1)}`
            : "") +
          " " +
          (technicianName?.lastName !== null &&
          technicianName?.lastName !== undefined
            ? `${technicianName?.lastName
                .charAt(0)
                .toUpperCase()}${technicianName?.lastName.slice(1)}`
            : "")
        }`
      : "-";
  };

  const renderDeviceLocation = (row) => {
    const address =
      row?.reportInfo?.deviceInfo?.centerDeviceInfo?.center?.addressInfo;

    const location = address?.areaInfo?.name
      ? address.areaInfo.name + ", "
      : "";

    const district = address?.districtInfo?.name
      ? address.districtInfo.name + ", "
      : "";

    const state = address?.stateInfo?.name ? address.stateInfo.name : "";
    return `${location}${district}${state}`;
  };

  const renderCaseNumber = (label) => {
    return (
      <div className="case_number-wrapper">
        <text className="me-3">{label}</text>

        {props.newRecords.some((recordId) => recordId == label) ? (
          <div className="new-tag">New</div>
        ) : null}
      </div>
    );
  };

  // Columns info with row value to use (row : single object in data array)
  const columns = [
    {
      name: renderHeader("Case ID", false, true),
      id: "case_id",
      center: false,
      sortable: false,
      selector: (row) => renderCaseNumber(row?.id ?? "-"),
    },
    {
      name: renderHeader("Patient Name", false, true),
      id: "patient_name",
      center: false,
      sortable: false,
      selector: (row) =>
        row !== null && row !== undefined
          ? `${
              (row?.firstName !== null && row?.firstName !== undefined
                ? `${row?.firstName
                    .charAt(0)
                    .toUpperCase()}${row?.firstName.slice(1)}`
                : "") +
              " " +
              (row?.lastName !== null && row?.lastName !== undefined
                ? `${row?.lastName
                    .charAt(0)
                    .toUpperCase()}${row?.lastName.slice(1)}`
                : "")
            }`
          : "-",
    },
    {
      name: renderHeader("Technician Name", false, true),
      id: "technician_name",
      center: false,
      sortable: false,
      selector: (row) => renderTechnicianName(row),
    },
    {
      name: renderHeader("Device ID", false, true),
      id: "device_id",
      center: false,
      sortable: false,
      selector: (row) => row?.reportInfo?.deviceInfo?.serialNo ?? "-",
    },
    {
      name: renderHeader("Device Location", false, false),
      id: "device_location",
      center: false,
      sortable: false,
      selector: (row) => renderDeviceLocation(row),
    },
    {
      name: renderStatusHeader("Status"),
      id: "status",
      center: false,
      sortable: false,
      selector: (row) => renderStatus(row),
    },
    {
      name: renderActionHeader("Action"),
      id: "action",
      center: true,
      width: "240px",
      fixed: "right",
      // allowOverflow: true,
      selector: (row) => renderViewAcceptRejectAction(row),
    },
  ];

  return (
    <div className="stemi-case-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          selectableRows={false}
          // onSelectedRowsChange={(state) =>
          //   props.onSelectedRowsChange(state.selectedRows)
          // }
          // clearSelectedRows={props.clearSelectedRows}
          // selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          persistTableHead={true}
          pagination
          // paginationComponent={PaginationNew} // Not needed anymore!
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalCases}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default StemiCasesList;
