import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const BarChartView = ({ dataSet, yaxisLabel, xaxisLabel, theme = "blue" }) => {
  const data = dataSet;

  //----------------- Custom x-axis Tick Label------------------

  const customTickLables = (value) => {
    console.log("value", value);
    // Split label by space for one word per line
    const words = value.split(" ");
    // console.log("sliced", words.slice(0, 2));
    return words.map((word, index) => (
      <tspan x={0} dy={index > 0 ? "1.2em" : 0} key={`word-${index}`}>
        {word}
      </tspan>
    ));
  };

  const CustomTickLabels = ({ x, y, payload, fontSize }) => {
    var payloadData = payload.value;
    var words = payloadData ? payloadData.split(" ") : [];

    if (words.length > 2) {
      return (
        <g>
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {words[0]}
          </text>
          <text
            x={x}
            y={y}
            dy={27}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {words[1] + "..."}
          </text>
        </g>
      );
    } else if (words.length === 2) {
      return (
        <g>
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {words[0]}
          </text>
          <text
            x={x}
            y={y}
            dy={27}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {words[1]}
          </text>
        </g>
      );
    } else if (words.length === 1) {
      return (
        <g>
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {words[0]}
          </text>
        </g>
      );
    } else if (words.length === 0) {
      return (
        <g>
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill="#666"
            fontSize={fontSize}
          >
            {""}
          </text>
        </g>
      );
    }
  };

  //--------------------fill bar color ------------------------------------
  const fillColors = {
    blue: ["#1890FF"],
    Purple: ["#722ED1"],
  };

  var fillColorBar;

  if (theme === "blue") {
    fillColorBar = fillColors.blue[0];
  }
  if (theme === "Purple") {
    fillColorBar = fillColors.Purple[0];
  }

  //--------------------------------------------------------------------------------

  return (
    <div>
      <ResponsiveContainer width="100%" height={270}>
        <BarChart data={data} margin={{ left: 10, bottom: 45 }}>
          <CartesianGrid
            vertical={false}
            stroke="rgba(0,0,0,0.1)"
            strokeDasharray="1 1"
            // strokeDasharray="0"
          />
          <XAxis
            dataKey="label"
            label={{ value: xaxisLabel, offset: -40, position: "insideBottom" }}
            interval={0}
            // tick={{
            //   width: 70,
            //   fontSize: 10,
            //   content: (props) => customTickLables(props.payload.value),
            // }}
            tick={<CustomTickLabels fontSize={10} />}
          />
          <YAxis
            label={{
              value: yaxisLabel,
              angle: -90,
              offset: 0,
              position: "insideLeft",
              style: { textAnchor: "middle" },
            }}
            tick={{ fontSize: 10 }}
          />
          <Tooltip cursor={false} active={false} />
          <Bar
            isAnimationActive={false}
            dataKey="count"
            fill={fillColorBar}
            barSize={25}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartView;
