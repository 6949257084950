import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./adminReducer";

/* ------------------- Get Hospital Admins List ----------------------- */

export function getHospitalAdminsList(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_HOSPITAL_ADMINS_LIST_PENDING,
      payload: {
        getHospitalAdminsListPending: false,
        getHospitalAdminsListSuccess: false,
        getHospitalAdminsListError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `master/hospitalAdmin/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(
            response?.data?.hospitalAdmin?.rows ?? [],
            response?.data?.hospitalAdmin?.count ?? 0,
            response?.data?.metaData ?? {},
            response?.data?.hospitalAdmin?.totalPages ?? 0
          );
        }

        dispatch({
          type: actionTypes.GET_HOSPITAL_ADMINS_LIST_SUCCES,
          payload: {
            getHospitalAdminsListPending: false,
            getHospitalAdminsListSuccess: true,
            getHospitalAdminsListError: null,
            hospitalAdminsList: response?.data?.hospitalAdmin?.rows ?? [],
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0, {});
        }

        dispatch({
          type: actionTypes.GET_HOSPITAL_ADMINS_LIST_ERROR,
          payload: {
            getHospitalAdminsListPending: false,
            getHospitalAdminsListSuccess: true,
            getHospitalAdminsListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* ------------------- Get Hospital Admins Data from Id ----------------------- */

export function getHospitalAdminsDataFromId(id, callback) {
  console.log("getHospitalAdminsDataFromId ----->", id);

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ADMIN_INFO_BY_ID_PENDING,
      payload: {
        getAdminsInfoByIdPending: false,
        getAdminsInfoByIdSuccess: false,
        getAdminsInfoByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`master/hospitalAdmin/${id}`)
      .then((response) => {
        console.log("response1", response);
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(response.data);
        }
        const hospitalIds = response.data?.hospitalList.map((item) => {
          return item.hospitalId;
        });
        dispatch({
          type: actionTypes.GET_ADMIN_INFO_BY_ID_SUCCES,
          payload: {
            getAdminsInfoByIdPending: false,
            getAdminsInfoByIdSuccess: true,
            getAdminsInfoByIdError: null,
            selectedHospitalAdmin: response.data,
            hospitalAdminsHospitalIds: hospitalIds,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_ADMIN_INFO_BY_ID_ERROR,
          payload: {
            getAdminsInfoByIdPending: false,
            getAdminsInfoByIdSuccess: true,
            getAdminsInfoByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------Add New Hospital Admin -----------------------------*/

export function addNewHospitalAdmin(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_HOSPITAL_ADMIN_PENDING,
      payload: {
        addHospitalAdminPending: false,
        addHospitalAdminSuccess: false,
        addHospitalAdminError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`hospitalAdmin/add`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_HOSPITAL_ADMIN_SUCCESS,
          payload: {
            addHospitalAdminPending: false,
            addHospitalAdminSuccess: true,
            addHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.ADD_HOSPITAL_ADMIN_ERROR,
          payload: {
            addHospitalAdminPending: false,
            addHospitalAdminSuccess: true,
            addHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------Edit Hospital Admin -----------------------------*/

export function editHospitalAdmin(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_HOSPITAL_ADMIN_PENDING,
      payload: {
        editHospitalAdminPending: false,
        editHospitalAdminSuccess: false,
        editHospitalAdminError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`hospitalAdmin/update/${id}`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_HOSPITAL_ADMIN_SUCCESS,
          payload: {
            editHospitalAdminPending: false,
            editHospitalAdminSuccess: true,
            editHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.EDIT_HOSPITAL_ADMIN_ERROR,
          payload: {
            editHospitalAdminPending: false,
            editHospitalAdminSuccess: true,
            editHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------Delete Hospital Admin -----------------------------*/

export function deleteHospitalAdmin(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_HOSPITAL_ADMIN_PENDING,
      payload: {
        deleteHospitalAdminPending: false,
        deleteHospitalAdminSuccess: false,
        deleteHospitalAdminError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`hospitalAdmin/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_HOSPITAL_ADMIN_SUCCESS,
          payload: {
            deleteHospitalAdminPending: false,
            deleteHospitalAdminSuccess: true,
            deleteHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_HOSPITAL_ADMIN_ERROR,
          payload: {
            deleteHospitalAdminPending: false,
            deleteHospitalAdminSuccess: true,
            deleteHospitalAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- bulk hospital delete hospital------------------------------------

export function deleteAdministratorInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ADMINISTRATORS_IN_BULK_PENDING,
      payload: {
        deleteAdministratorInBulkPending: false,
        deleteAdministratorInBulkSuccess: false,
        deleteAdministratorInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`hospitalAdmin/delete/multiple/hospitalAdmin`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_ADMINISTRATORS_IN_BULK_SUCCESS,
          payload: {
            deleteAdministratorInBulkPending: false,
            deleteAdministratorInBulkSuccess: true,
            deleteAdministratorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_ADMINISTRATORS_IN_BULK_ERROR,
          payload: {
            deleteAdministratorInBulkPending: false,
            deleteAdministratorInBulkSuccess: true,
            deleteAdministratorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* -------------------  Hospital Admin Clicked -------------------- */

export function setSelectedHospitalAdmin(adminData) {
  // console.log("setSelectedHospitalAdmin ----->", adminData.id);

  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_HOSPITAL_ADMIN,
      payload: {
        selectedHospitalAdmin: adminData,
      },
    });
  };
}

export function resetSelectedHospitalAdmin() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SELECTED_HOSPITAL_ADMIN,
      payload: {
        selectedHospitalAdmin: null,
      },
    });
  };
}
