import { actionTypes } from "./mqttReducer";
import { getState } from "../configure/configureStore";
import moment from "moment";

// --------------- Show 'NEW' tags for MQTT requests -------------

export function saveNotification(reportIdOrCaseId, status, timestamp) {
  console.log("saveNotification :: ", reportIdOrCaseId);
  console.log("notifications :: ", getState().mqtt.newNotifications);

  var notificationData = {
    id: reportIdOrCaseId,
    status: status,
    timestamp: timestamp,
  };

  var notificationList = getState().mqtt.newNotifications;
  var newList = [...notificationList, notificationData];

  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_NOTIFICATION,
      payload: {
        newNotifications: newList,
      },
    });

    // Calculate the time difference in milliseconds
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;

    // Set timeout to delete the notification after 1 minute if it hasn't been deleted already
    if (timeDifference < 60000) {
      setTimeout(() => {
        dispatch(deleteNotification(reportIdOrCaseId));
      }, 60000 - timeDifference); // Adjust timeout based on the remaining time
    }
  };
}

// --------------- Remove 'NEW' tags after 1 minute -------------

export function deleteNotification(reportIdOrCaseId) {
  console.log("deleteNotification :: ", reportIdOrCaseId);

  var notificationList = getState().mqtt.newNotifications;
  var updatedNotificationsList = notificationList.filter(
    (notification) => notification.id !== reportIdOrCaseId
  );

  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_NOTIFICATION,
      payload: {
        newNotifications: updatedNotificationsList,
        notificationDeletedTimestamp: moment().toString(),
      },
    });
  };
}

// ------------ Home screen refresh related functions ------------

export function refreshHomeScreen() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REFRESH_HOME_SCREEN,
      payload: {
        homeScreenRefreshed: true,
      },
    });
  };
}

export function resetRefreshHomeScreen() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_REFRESH_HOME_SCREEN,
      payload: {
        homeScreenRefreshed: false,
      },
    });
  };
}
