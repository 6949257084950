import React from "react";
import metsl_logo_color from "../../../assets/images/metsl_logo_color.png";
import { Input, ThemedButton } from "../../controls";
import "./ForgotPasswordStyles.scss";

const ForgotPasswordView = (props) => {
  const renderEmailForm = () => {
    return (
      <>
        <text className="login-subtitle">Enter your email to get the OTP</text>
        <div className="login-card">
          <div style={{ marginTop: "15px" }}>
            <Input
              value={props?.fields?.email}
              name={"email"}
              label={"Email"}
              placeholder="Enter Email"
              isrequired={true}
              size="lg"
              onChange={props.handleChange("email")}
              error={props?.errors["email"]}
              style={{ backgroundColor: "white" }}
            />
          </div>

          <div style={{ marginTop: "25px" }}>
            <ThemedButton
              title="Get OTP"
              styles={"primary"}
              fullWidth
              loginButton
              onClick={() => {
                props.onGetOtpClick();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderNewPasswordForm = () => {
    return (
      <>
        <text className="login-subtitle"> Reset your password</text>
        <div className="reset-subheading">
          Enter a new password below to reset your password
        </div>
        <div className="login-card">
          <div style={{ marginTop: "25px" }}>
            <Input
              value={props?.fields?.emailOtp}
              name={"emailOtp"}
              label="OTP"
              placeholder="Enter OTP"
              isrequired={true}
              type="text"
              size="lg"
              onChange={props.handleChange("emailOtp")}
              error={props?.errors["emailOtp"]}
              style={{ backgroundColor: "white" }}
            />
          </div>
          <div style={{ marginTop: "25px" }}>
            <Input
              value={props?.fields?.password}
              name={"password"}
              label="New Password"
              placeholder="Enter New Password"
              isrequired={true}
              type="password"
              size="lg"
              onChange={props.handleChange("password")}
              error={props?.errors["password"]}
              style={{ backgroundColor: "white" }}
            />
          </div>

          <div style={{ marginTop: "25px" }}>
            <Input
              value={props?.fields?.confirmPassword}
              name={"confirmPassword"}
              label="Confirm Password"
              placeholder="Confirm Password"
              isrequired={true}
              type="password"
              size="lg"
              onChange={props.handleChange("confirmPassword")}
              error={props?.errors["confirmPassword"]}
              style={{ backgroundColor: "white" }}
            />
          </div>

          <div className="resend-otp-container">
            <text className="resend-otp" onClick={props.onResendOtpClick}>
              Resend OTP
            </text>
          </div>

          <div style={{ marginTop: "25px" }}>
            <ThemedButton
              title="Reset Password"
              styles={"primary"}
              fullWidth
              loginButton
              onClick={() => {
                props.onResetPasswordClick();
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="reset-password-container ">
        <div className="reset-password-container-main">
          <div className="header_title" onClick={props.onLogoClick}>
            <h2 className="title">
              Rhythms{" "}
              <span style={{ color: "#00b48a", fontSize: "30px" }}>24x7</span>{" "}
              <span style={{ fontSize: "20px" }}> by</span>
            </h2>
            <img className="logo-style" src={metsl_logo_color} alt="logo" />
          </div>

          {props?.setPasswordFlag ? renderNewPasswordForm() : renderEmailForm()}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
