export const actionTypes = {
  GET_ERROR_LOGS_LIST_WITH_FILTER_PENDING:
    "GET_ERROR_LOGS_LIST_WITH_FILTER_PENDING",
  GET_ERROR_LOGS_LIST_WITH_FILTER_SUCCESS:
    "GET_ERROR_LOGS_LIST_WITH_FILTER_SUCCESS",
  GET_ERROR_LOGS_LIST_WITH_FILTER_ERROR:
    "GET_ERROR_LOGS_LIST_WITH_FILTER_ERROR",

  VIEW_ERROR_LOG_PENDING: "VIEW_ERROR_LOG_PENDING",
  VIEW_ERROR_LOG_SUCCESS: "VIEW_ERROR_LOG_SUCCESS",
  VIEW_ERROR_LOG_ERROR: "VIEW_ERROR_LOG_ERROR",

  UPDATE_ERROR_LOG_PENDING: "UPDATE_ERROR_LOG_PENDING",
  UPDATE_ERROR_LOG_SUCCESS: "UPDATE_ERROR_LOG_SUCCESS",
  UPDATE_ERROR_LOG_ERROR: "UPDATE_ERROR_LOG_ERROR",

  GET_TOGGLE_DATA_FOR_ERROR_LOGS_PENDING:
    "GET_TOGGLE_DATA_FOR_ERROR_LOGS_PENDING",
  GET_TOGGLE_DATA_FOR_ERROR_LOGS_SUCCESS:
    "GET_TOGGLE_DATA_FOR_ERROR_LOGS_SUCCESS",
  GET_TOGGLE_DATA_FOR_ERROR_LOGS_ERROR: "GET_TOGGLE_DATA_FOR_ERROR_LOGS_ERROR",
};
const initialState = {
  getErrorLogsListWithFilterPending: false,
  getErrorLogsListWithFilterSuccess: false,
  getErrorLogsListWithFilterError: null,

  viewErrorLogPending: false,
  viewErrorLogSuccess: false,
  viewErrorLogError: null,

  updateErrorLogPending: false,
  updateErrorLogSuccess: false,
  updateErrorLogError: null,

  getToggleDataForErrorLogsPending: false,
  getToggleDataForErrorLogsSuccess: false,
  getToggleDataForErrorLogsError: null,

  errorlogList: [],
  filteredErrorLogsList: [],
  viewErrorLogData: {},
  filteredErrorLogsCount: 0,
  //
  toggleDataforErrorLogs: [],
};

export default function errorLogs(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_PENDING:
    case actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_SUCCESS:
    case actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.VIEW_ERROR_LOG_PENDING:
    case actionTypes.VIEW_ERROR_LOG_SUCCESS:
    case actionTypes.VIEW_ERROR_LOG_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_ERROR_LOG_PENDING:
    case actionTypes.UPDATE_ERROR_LOG_SUCCESS:
    case actionTypes.UPDATE_ERROR_LOG_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
