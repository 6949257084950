import React from "react";
import HospitalList from "../../../pages/NewUIPages/Components/Lists/HospitalList";
import {
  CardView,
  SearchView,
  ThemedButton,
  Input,
  Checkbox,
  FormSearchView,
  Select,
  TogglebuttonGroup,
} from "../../../controls";
import { CSSTransition } from "react-transition-group";
import { AiOutlineDelete } from "react-icons/ai";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import { AccordionList, PopupDialog } from "../Components";
import { FaPlus, FaAngleLeft } from "react-icons/fa6";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./HospitalsStyles.scss";
import RadioButtonGroup from "../../../controls/RadioButtonGroup/RadioButtonGroup";

const HospitalsView = (props) => {
  // console.log("HospitalsView : ", JSON.stringify(props.hospitalList));
  console.log(props.districtList);
  //-------------- Add Hospital popup contents ------------------
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.4),
  }));

  const toggleData = [
    {
      id: "centers",
      name: "Centers",
    },
    {
      id: "doctors",
      name: "Doctors",
    },
  ];

  const renderAddHospitalPopupContents = () => {
    return (
      <div className="add-new-hospital-form">
        <div className="block-level-group align-center">
          <label className="form-label">
            <span className="required">* </span> Hospital Name :
          </label>
          <div className="value-item extra">
            <Input
              className="input-wrapper"
              placeholder="Enter Hospital Name"
              value={props.addHospitalFields.name}
              error={props.errors["name"]}
              onChange={props.onAddHospitalFieldChange("name")}
            />

            <div className="check-selectors">
              <div className="checkbox">
                <Checkbox
                  onChange={props.onCheckBoxChange}
                  checked={props.reportService}
                />
                <label className="boxLabel">Reporting</label>
              </div>
              <div className="checkbox">
                <Checkbox
                  onChange={props.onSpokeCheckBoxChange}
                  checked={props.isSpoke}
                />
                <label className="boxLabel">Spoke</label>
              </div>
              <div className="checkbox">
                <Checkbox
                  onChange={props.onHubCheckBoxChange}
                  checked={props.isHub}
                />
                <label className="boxLabel">Hub</label>
              </div>
              {props.showHospitalHubTypeToggle ? (
                <div className="hospital-type-toggle">
                  <TogglebuttonGroup
                    smallSize={true}
                    customToggleData={props.HospitalHubTypeToggleData}
                    onItemSelected={props.onChangeHubHospitalType}
                    selectedItemId={props.hubType?.id}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group vertical-middle">
          <label className="form-label"></label>
          <div className="value-item extra">
            <RadioButtonGroup
              name="hospital-category-group"
              value={props.hospitalType}
              onItemSelected={props.onGovermentOrPrivateChange}
              data={props.hospitalTypeData}
              size="lg"
              // error={props.errors["hospitalType"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Reporting Hospital :
          </label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.reportingHospitalList}
              placeholder="Search Reporting Hospital Name"
              previousValue={props.addHospitalFields.reportingName}
              onInputChange={props.onReportingHospitalSearchChange}
              onChange={props.onReportingHospitalChange}
              onScroll={props.onScrollReportingHospitalList}
              searchText={props.searchReportHospitalText}
              error={props.errors["reportingName"]}
              disable={props.reportService}
              lazyLoading={true}
              customInputSearch={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Contact :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Contact"
              value={props.addHospitalFields.contact}
              error={props.errors["contact"]}
              onChange={props.onAddHospitalFieldChange("contact")}
              maxLength={10}
              isvalnum="isNum"
              showMobilePrefix={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Address :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Address Line 1"
              value={props.addHospitalFields.addressLine1}
              error={props.errors["addressLine1"]}
              disabled={props.showEditAdminPopup}
              onChange={props.onAddHospitalFieldChange("addressLine1")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>State :
          </label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.stateList}
              placeholder="Search State"
              onChange={props.onStateChange}
              error={props.errors["state"]}
              previousValue={props.addHospitalFields.state}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>District :
          </label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.districtList}
              disable={!props.districtList.length > 0}
              placeholder="Search District"
              onChange={props.onDistrictChange}
              error={props.errors["district"]}
              previousValue={props.addHospitalFields.district}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Pincode :
          </label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.pincodeList}
              disable={!props.pincodeList.length > 0}
              placeholder="Search Pincode"
              onChange={props.onPincodeChange}
              error={props.errors["pincode"]}
              previousValue={props.addHospitalFields.pincode}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">Area/Village :</label>
          <div className="value-item">
            <Input
              extraStyle={"display-text-view"}
              className="input-wrapper"
              placeholder="Area/Village Name"
              readOnly={true}
              value={props.addHospitalFields.village}
              onChange={props.onAddHospitalFieldChange("village")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">Landmark :</label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Landmark"
              value={props.addHospitalFields.landmark}
              onChange={props.onAddHospitalFieldChange("landmark")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">Latitude :</label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Latitude"
              value={props.addHospitalFields.latitude}
              error={props.errors["latitude"]}
              onChange={props.onAddHospitalFieldChange("latitude")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">Longitude :</label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Longitude"
              value={props.addHospitalFields.longitude}
              error={props.errors["longitude"]}
              onChange={props.onAddHospitalFieldChange("longitude")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>

        <div className="block-level-group">
          <label className="form-label">Administrator :</label>
          <div className="value-item extra">
            {props.administrator.length > 0 ? (
              <>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    boxShadow: "none",
                    borderRadius: "2px",
                    border: "none",
                    width: "100%",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.4,
                    m: 0,
                  }}
                  component="ul"
                >
                  {props.administrator.map((data) => {
                    return (
                      <ListItem key={data.value}>
                        <Chip
                          style={{ background: "#c0e5dc" }}
                          label={data.label}
                          onDelete={
                            props.handleHospitalAdminChips !== null
                              ? () => props.handleHospitalAdminChips(data)
                              : () => {}
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Paper>
                <div style={{ height: "8px" }}></div>
              </>
            ) : null}
            <FormSearchView
              Options={props.administratorList}
              placeholder="Search Admin Name"
              previousArrayValue={props.administrator}
              onInputChange={props.onAdministratorSearchChange}
              onChange={props.onAdministratorChange}
              onScroll={props.onScrollAdministratorList}
              searchText={props.searchAdministratorText}
              previousValue={null}
              // previousValue={props.addHospitalFields.administrator}
              lazyLoading={true}
              multiSelect={true}
              customInputSearch={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
      </div>
    );
  };

  const renderDeleteHospitalPopupContents = () => {
    const details = props.HospitalInfo;
    const hospitalName = `${
      details?.hospitalName !== null && details?.hospitalName !== undefined
        ? `${details?.hospitalName
            .charAt(0)
            .toUpperCase()}${details?.hospitalName.slice(1)}`
        : ""
    }`;
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>{`Are you sure you want to Delete ${hospitalName} ?`}</text>
      </div>
    );
  };

  const renderDeletebulkHospitalPopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        {deleteCount > 1 ? (
          <text>{`Are you sure you want to Delete ${deleteCount} Hospitals ?`}</text>
        ) : (
          <text>{`Are you sure you want to Delete ${deleteCount} Hospital ?`}</text>
        )}
      </div>
    );
  };

  //-------------- Hospitals List View ------------------

  const renderHospitalList = () => {
    return (
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wrapper">
          <div className="title">Total Hospitals: {props.totalHospitals}</div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Hospitals"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                  updatedCurrentValue={props.searchStringParam}
                />

                <ThemedButton
                  title="Add New Hospital"
                  size={"large"}
                  icon={<FaPlus size={15} color="#fff" />}
                  onClick={() => {
                    props.onAddHospitalClick();
                  }}
                  styles="primary medium"
                />
              </>
            )}
            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkHospitalDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <HospitalList
          page={props.page}
          limit={props.limit}
          totalHospitals={props.totalHospitals}
          hospitalList={props.hospitalList}
          onHospitalSelected={props.onHospitalSelected}
          onEditHospitalClicked={props.onEditHospitalClick}
          onDeleteHospitalClicked={props.onDeleteHospitalAction}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          onHospitalListSortClick={props.onHospitalListSortClick}
        />
      </CardView>
    );
  };

  //---------------------------------------------------------------

  return (
    <div className="hospitals-container-new">
      {renderHospitalList()}

      <PopupDialog
        show={
          props.showEditHospitalPopup
            ? props.showEditHospitalPopup
            : props.showAddHospitalPopup
        }
        title={
          props.showEditHospitalPopup ? "Edit Hospital" : "Add New Hospital"
        }
        showCloseIcon={true}
        renderBodyContent={renderAddHospitalPopupContents}
        confirmButtonTitle={props.showEditHospitalPopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditHospitalPopup
            ? props.onEditHospitalConfirmClick
            : props.onAddHospitalConfirmClick
        }
        onclickCancel={
          props.showEditHospitalPopup
            ? props.onCloseEditPopup
            : props.onCloseAddHospitalPopup
        }
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />
      <PopupDialog
        show={props.showDeleteHospitalPopup}
        title={"Delete Hospital"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteHospitalPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteAdminConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseDeletePopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDeleteHospitalConfirmPopup}
        title={"Delete Hospitals"}
        showCloseIcon={true}
        renderBodyContent={renderDeletebulkHospitalPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkHospitalDeleteConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseDeletePopup}
        popupSize={"md"}
      />
    </div>
  );
};

export default HospitalsView;
