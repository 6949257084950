import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "./SelectStyles.scss";
import { propTypes } from "react-bootstrap/esm/Image";

const SelectView = ({
  className = "",
  isrequired = false,
  error,
  label,
  options,
  isSearchable,
  isClearable,
  menuPlacement,
  menuPositionFixed,
  compact,
  ...props
}) => {
  return (
    <div className={`select_wrapper ${compact && "compact"}`}>
      {label ? (
        <span className={`select__label text-muted`}>
          {label} {isrequired && <span className="text-danger">*</span>}
        </span>
      ) : null}

      <Select
        options={options}
        isClearable={!!isClearable}
        isSearchable={!!isSearchable}
        className={`select ${className} ${compact && "compact"}  ${
          error ? "error" : ""
        }`}
        classNamePrefix="select"
        placeholder={""}
        menuPosition={menuPositionFixed ? "fixed" : ""}
        {...props}
      />
      <div className={`errorText`}>{error ? error : ""}</div>
    </div>
  );
};

SelectView.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  menuPositionFixed: PropTypes.bool,
  options: PropTypes.array,
  menuPlacement: PropTypes.string,
};

export default SelectView;
