import React, { useEffect, useState } from "react";
import {
  CardView,
  ThemedButton,
  TextArea,
  Select,
  TogglebuttonGroup,
  NumberInputBasic,
} from "../../../controls";
import { FiAlertTriangle, FiArrowLeft } from "react-icons/fi";
import { REPORT_EXPIRY_TIME } from "../../../../constants/env";
import { roles } from "../../../../constants/roles";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PopupDialog from "../Components/Other/PopupDialog";
import { SlClose } from "react-icons/sl";
import { BiPhone } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "./ECGReportDetailsStyles.scss";
import RadioButtonGroup from "../../../controls/RadioButtonGroup/RadioButtonGroup";

const ECGReportDetailsView2 = (props) => {
  console.log("is case view", props.isCaseView);
  // console.log("reportDetails : ", JSON.stringify(props.reportDetails));

  // const gainFinalVal = {
  //   0.5: 24 / window.devicePixelRatio,
  //   1: 12 / window.devicePixelRatio,
  //   1.5: 8 / window.devicePixelRatio,
  //   2: 6 / window.devicePixelRatio,
  // };

  const gainFinalVal = {
    0.5: 24,
    1: 12,
    1.5: 8,
    2: 6,
  };

  const [sweepSpeed, setSweepSpeed] = useState(1);
  const [gain, setGain] = useState(1);
  const [ecgIndex, setEcgIndex] = useState(0);
  const [availableSpace, setAvailableSpace] = useState(
    parseInt((window.screen.width - 200) / 2)
  );

  console.log("window width", window.innerWidth);

  useEffect(() => {
    if (props?.ecgDetails?.ecG_Array?.length) {
      // alert("Plotting");
      plotECGData(ecgIndex);
    }
  }, [ecgIndex]);

  useEffect(() => {
    if (props?.ecgDetails?.ecG_Array?.length) {
      plotECGData(0);
      setEcgIndex(0);
    }
  }, [sweepSpeed]);

  useEffect(() => {
    if (props?.ecgDetails?.ecG_Array?.length) {
      plotECGData(ecgIndex);
    }
  }, [gain]);

  useEffect(() => {
    if (props.ecgDetails) {
      setTimeout(() => {
        try {
          // var ECGPlotter = require("./ECGPlotter");
          // ECGPlotter.loadLayout(sweepSpeed, gain);

          // const newData = props.ecgDetails.ecG_Array;
          // var skip = 0;
          // // if (newData.length) {
          // //   const ecgData = newData[ecgIndex];

          // //   ECGPlotter.Plot12LeadECG(ecgData);
          // // }
          // // alert(parseInt(window.screen.width - 200) / 2);
          // for (var i = 0; i < parseInt((window.screen.width - 200) / 2); i++) {
          //   console.log("ECG I Value: ", i);
          //   const ecgData = newData[i];
          //   ECGPlotter.Plot12LeadECG(ecgData);
          // }
          // const interval = setInterval(() => {
          //   console.log(ecgIndex + availableSpace + 1 < props.ecgDetails.ecG_Array.length);
          //   if (ecgIndex + availableSpace + 1 < props.ecgDetails.ecG_Array.length) {
          //     setEcgIndex(ecgIndex + 1);
          //     console.log(ecgIndex);
          //     // plotECGData(ecgIndex + 1);
          //   } else {
          //     clearInterval(interval);
          //   }
          // }, 300);
          plotECGData(ecgIndex);
        } catch (err) {
          console.log("ECG Plotter Error : ", err);
        }
      }, 1000);
    }
  }, [props.ecgDetails]);

  // ------------------------------------------

  const renderLeftPanel = () => {
    return (
      <div className="left-panel">
        <div className="card-header-wraper">
          <div className="title">
            Record Id: {props.reportDetails?.id ?? ""}
          </div>
        </div>
        <div className="form">{renderTextBlocks()}</div>
      </div>
    );
  };

  const renderTextBlocks = () => {
    const text = "\t";
    const patientId = props.reportDetails?.patientInfo?.ECGPatientId
      ? props.reportDetails.patientInfo.ECGPatientId.replace(
          /\\([ntrbfe"\\])/g,
          ""
        ).length > 0
        ? props.reportDetails.patientInfo.ECGPatientId.replace(
            /\\([ntrbfe"\\])/g,
            ""
          )
        : "-"
      : "-";

    return (
      <div className="horizontal-container">
        <div className="text-block first">
          <text className="title">Patient ID</text>
          <text className="value">{patientId}</text>
        </div>

        <div className="separator" />

        <div className="text-block ">
          <text className="title">Patient Name</text>
          <text className="value">
            {props.reportDetails.patientInfo !== null &&
            props.reportDetails.patientInfo !== undefined
              ? (props.reportDetails.patientInfo?.firstName !== undefined &&
                props.reportDetails.patientInfo?.firstName !== null
                  ? `${props.reportDetails.patientInfo.firstName
                      .charAt(0)
                      .toUpperCase()}${props.reportDetails.patientInfo.firstName.slice(
                      1
                    )}`
                  : "") +
                " " +
                (props.reportDetails.patientInfo?.lastName !== undefined &&
                props.reportDetails.patientInfo?.lastName !== null
                  ? `${props.reportDetails.patientInfo.lastName
                      .charAt(0)
                      .toUpperCase()}${props.reportDetails.patientInfo.lastName.slice(
                      1
                    )}`
                  : "")
              : "-"}
          </text>
        </div>

        <div className="separator" />

        <div className="text-block">
          <text className="title">Age</text>
          <text className="value">
            {props.reportDetails?.patientInfo?.age ?? "-"}
          </text>
        </div>

        <div className="separator" />

        <div className="text-block">
          <text className="title">Gender</text>
          <text className="value">
            {props.reportDetails?.patientInfo?.gender === 1
              ? "Male"
              : props.reportDetails?.patientInfo?.gender === 2
              ? "Female"
              : "-"}
          </text>
        </div>

        <div className="separator" />

        <div className="text-block">
          <text className="title">Location</text>
          <text className="value">
            {props.reportDetails?.patientInfo?.addressInfo?.locationName
              ? props.reportDetails?.patientInfo?.addressInfo?.locationName +
                (props.patientDistrict ? `, ${props.patientDistrict}` : "") +
                (props.patientState ? `, ${props.patientState}` : "")
              : "-"}
          </text>
        </div>

        {/* {props.userRole === roles.supervisor && (
          <>
            <div className="separator" />
            <div className="text-block">
              <text className="title">Severity</text>
              <text className="value" style={{ color: "#B40000" }}>
                {props.reportDetails.severity == 1
                  ? "Normal"
                  : props.reportDetails.severity == 2
                  ? "Mild"
                  : props.reportDetails.severity == 3
                  ? "Critical"
                  : "-"}
              </text>
            </div>
          </>
        )} */}
      </div>
    );
  };

  // ------------------------------------------

  const renderResponseTimeCountdown = () => {
    const { ecgDetails } = props;

    if (ecgDetails) {
      return (
        <div className="response-time-section">
          <div className="section-title">
            <text>Response Time</text>
          </div>
          <div className="section-countdown">
            <div className="countdown-timer">
              <CountdownCircleTimer
                size={75}
                strokeWidth={4}
                isPlaying
                duration={REPORT_EXPIRY_TIME}
                isSmoothColorTransition={false}
                colorsTime={[
                  parseInt(REPORT_EXPIRY_TIME),
                  parseInt(REPORT_EXPIRY_TIME * 0.75),
                  parseInt(REPORT_EXPIRY_TIME * 0.5),
                  parseInt(REPORT_EXPIRY_TIME * 0.25),
                  0,
                ]}
                colors={["#00b48a", "#e2b604", "#ff8e05", "#e53939"]}
                onComplete={props.onTimerExpire}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
            <div className="section-info">
              <div className="reset-info">
                <div className="icon-info">
                  <AiOutlineInfoCircle
                    size={16}
                    style={{
                      background: "rgba(0, 180, 138, 1)",
                      color: "white",
                      borderRadius: "50%",
                      border: "none",
                    }}
                  />
                  <text>Click Reset</text>
                </div>
                <text className="icon-msg">To reset the timer</text>
              </div>
              <ThemedButton
                title="Reset"
                styles="primary small"
                onClick={props.onResetTimerClick}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderRightPanel = () => {
    const { ecgDetails } = props;
    if (ecgDetails) {
      return (
        <div className="right-panel">
          {props.isPreviewOnly ? null : props.userRole ===
            roles.supervisor ? null : (
            <CountdownCircleTimer
              size={100}
              strokeWidth={8}
              isPlaying
              duration={REPORT_EXPIRY_TIME}
              isSmoothColorTransition={false}
              colorsTime={[
                parseInt(REPORT_EXPIRY_TIME),
                parseInt(REPORT_EXPIRY_TIME * 0.75),
                parseInt(REPORT_EXPIRY_TIME * 0.5),
                parseInt(REPORT_EXPIRY_TIME * 0.25),
                0,
              ]}
              colors={["#00b48a", "#e2b604", "#ff8e05", "#e53939"]}
              onComplete={props.onTimerExpire}
            >
              {renderTime}
            </CountdownCircleTimer>
          )}

          {renderSettingPanel()}

          <div className="measure-container">
            <table>
              <tr colspan={3}>
                <td>
                  <div className="title">
                    <b>HR:</b> {ecgDetails.heart_Rate ?? ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>AXIS (in deg)</b>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>Interval (in msec)</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>P:</b> {ecgDetails.p_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>PR:</b> {ecgDetails.pR_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>ST:</b> {ecgDetails.sT_Segment.toFixed(4) ?? ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>R:</b> {ecgDetails.r_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>QRS:</b> {ecgDetails.qrS_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>QT:</b> {ecgDetails.qT_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>T:</b> {ecgDetails.t_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>QTc:</b> {ecgDetails.qTc.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>R-R:</b> {ecgDetails.r2R_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderTopPanel = () => {
    const patientId = props.reportDetails?.patientInfo?.ECGPatientId
      ? props.reportDetails.patientInfo.ECGPatientId.replace(
          /\\([ntrbfe"\\])/g,
          ""
        ).length > 0
        ? props.reportDetails.patientInfo.ECGPatientId.replace(
            /\\([ntrbfe"\\])/g,
            ""
          )
        : "-"
      : "-";

    const patientName =
      props.reportDetails.patientInfo !== null &&
      props.reportDetails.patientInfo !== undefined
        ? (props.reportDetails.patientInfo?.firstName !== undefined &&
          props.reportDetails.patientInfo?.firstName !== null
            ? `${props.reportDetails.patientInfo.firstName
                .charAt(0)
                .toUpperCase()}${props.reportDetails.patientInfo.firstName.slice(
                1
              )}`
            : "") +
          " " +
          (props.reportDetails.patientInfo?.lastName !== undefined &&
          props.reportDetails.patientInfo?.lastName !== null
            ? `${props.reportDetails.patientInfo.lastName
                .charAt(0)
                .toUpperCase()}${props.reportDetails.patientInfo.lastName.slice(
                1
              )}`
            : "")
        : "-";
    const { ecgDetails } = props;
    console.log("ecgDetails", ecgDetails);
    if (ecgDetails) {
      return (
        <>
          {props.isCaseView ? null : (
            <div className="measure-container first">
              <table>
                <tr>
                  <th colspan={2}>
                    <div>Record ID : {props.reportDetails?.id ?? ""}</div>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div className="title">
                      <b>Patient ID: </b> {patientId}
                    </div>
                  </td>
                  <td className="left-border">
                    <div className="title">
                      <b>Device ID: </b>
                      {props.reportDetails?.deviceInfo?.serialNo ?? "-"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="title">
                      <b>Name:</b> {patientName}
                    </div>
                  </td>
                  <td className="left-border">
                    <div className="title">
                      <b>Age:</b> {props.reportDetails?.patientInfo?.age ?? "-"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="title">
                      <b>Gender:</b>{" "}
                      {props.reportDetails?.patientInfo?.gender === 1
                        ? "Male"
                        : props.reportDetails?.patientInfo?.gender === 2
                        ? "Female"
                        : "-"}
                    </div>
                  </td>
                  <td className="left-border">
                    <div className="title">
                      <b>Location: </b>
                      {props.patientDistrict ? ` ${props.patientDistrict}` : ""}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          )}
          <div className="measure-container second">
            <table>
              <tr>
                <th>
                  <div>Axis (in deg)</div>
                </th>
                <th colSpan={2} className="left-border">
                  <div>Interval (in msec)</div>
                </th>
                <th className="left-border">
                  <div>HR: {ecgDetails.heart_Rate ?? ""} Bpm</div>
                </th>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>P :</b> {ecgDetails.p_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td className="left-border">
                  <div className="title">
                    <b>PR :</b> {ecgDetails.pR_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>ST :</b> {ecgDetails.sT_Segment.toFixed(4) ?? ""}
                  </div>
                </td>
                <td rowSpan={3} className="left-border">
                  No comments
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>R :</b> {ecgDetails.r_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td className="left-border">
                  <div className="title">
                    <b>QRS :</b> {ecgDetails.qrS_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>P :</b> {ecgDetails.qT_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="title">
                    <b>T :</b> {ecgDetails.t_Axis.toFixed(4) ?? ""}
                  </div>
                </td>
                <td className="left-border">
                  <div className="title">
                    <b>QTc :</b> {ecgDetails.qTc.toFixed(4) ?? ""}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <b>R-R :</b> {ecgDetails.r2R_Interval.toFixed(4) ?? ""}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderSettingPanel = () => {
    const sweepSpeedToggleData = [
      {
        id: 0.5,
        name: "12.5",
      },
      {
        id: 1,
        name: "25",
      },
      {
        id: 2,
        name: "50",
      },
    ];
    const sweepSpeedToggleValue = sweepSpeedToggleData[1].id;
    return (
      <div className="settings-container-section">
        <div className="section-header">
          <text>Setting</text>
        </div>
        <div className="section-controls">
          <div className="toggle-speeds">
            <text className="title">Sweep Speed (mmps) :</text>
            <div className="toggles">
              <TogglebuttonGroup
                smallSize={true}
                selectedItemId={sweepSpeedToggleValue}
                onItemSelected={(item) => setSweepSpeed(item)}
                customToggleData={sweepSpeedToggleData}
              />
            </div>
          </div>
          <div className="hz-seperator" />
          <div className="range-section-title">
            <div className="range-settings">
              <text className="title">Gain</text>
              <input
                className="range"
                type="range"
                min={0.5}
                max={2}
                step={0.5}
                value={gain}
                onChange={(event) => {
                  var val = event.target.valueAsNumber;
                  setGain(val);
                }}
              />
              <div className="incrementer">
                <NumberInputBasic
                  min={0.5}
                  max={2}
                  defaultValue={gain}
                  stepsIncrement={0.5}
                  setGain={setGain}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }

    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{remainingTime}</div>
        <div className="text">secs left</div>
      </div>
    );
  };

  // ------------------------------------------

  const plotECGData = (index = 0) => {
    console.log("ECG I Value: ", index);
    var ECGPlotter = require("./ECGPlotter");
    // alert(gainFinalVal[gain]);
    ECGPlotter.loadLayout(sweepSpeed, gainFinalVal[gain]);
    // ECGPlotter.loadLayout(sweepSpeed / window.devicePixelRatio, gainFinalVal[gain]);

    const newData = props.ecgDetails.ecG_Array;
    var skip = 0;
    const length =
      index + availableSpace + 1 < newData.length
        ? index + availableSpace + 1
        : newData.length;
    var dropPointCounter = 0;
    for (var i = index; i < newData.length; i++) {
      if (dropPointCounter == 0) {
        const ecgData = newData[i];
        const canTakeNext = ECGPlotter.Plot12LeadECG(ecgData);
        if (!canTakeNext) {
          break;
        }
      }
      // dropPointCounter++;
      // if (dropPointCounter >= 2) {
      //   dropPointCounter = 0;
      // }
    }
  };

  // ------------------------------------------

  const renderRequiredSecondOpinionContents = () => {
    return (
      <div>
        <h3 className="popup_text title">
          <FiAlertTriangle size={20} style={{ marginRight: "10px" }} />
          Coming soon
        </h3>
        <text className="popup_text">This feature will be updated soon</text>
        <div style={{ height: "30px" }} />
      </div>
    );
  };

  const renderTechnicianContactDetailsPopupContent = () => {
    const technicianInfo = props.reportDetails?.technicianInfo;
    const TechnicianName = `${
      (technicianInfo?.firstName !== null &&
      technicianInfo?.firstName !== undefined
        ? `${technicianInfo?.firstName
            .charAt(0)
            .toUpperCase()}${technicianInfo?.firstName.slice(1)}`
        : "") +
      " " +
      (technicianInfo?.lastName !== null &&
      technicianInfo?.lastName !== undefined
        ? `${technicianInfo?.lastName
            .charAt(0)
            .toUpperCase()}${technicianInfo?.lastName.slice(1)}`
        : "")
    }`;
    return (
      <div className="p-4">
        <h5>
          {TechnicianName} : {technicianInfo?.contactNumber ?? ""}{" "}
        </h5>
      </div>
    );
  };

  const renderInputBlocks = () => {
    return (
      <div className="horizontal-container ">
        <div className="input-block px-2">
          <text className="title">Analysis</text>
          <TextArea
            label={""}
            value={props.analysis}
            disabled={props.isPreviewOnly}
            placeholder={"Enter your analysis"}
            onChange={(e) => {
              props.onAnalysisChange(e.target.value);
            }}
            error={props.analysisError}
          />
        </div>

        <div className="input-block px-2">
          <text className="title">Interpretation</text>
          <TextArea
            label={""}
            disabled={props.isPreviewOnly}
            value={props.interpretation}
            placeholder={"Enter your interpretation"}
            onChange={(e) => {
              props.onInterpretationChange(e.target.value);
            }}
            error={props.interpretationError}
          />
        </div>

        <div className="input-block px-2">
          <text className="title">Remarks</text>
          <TextArea
            label={""}
            value={props.remarks}
            disabled={props.isPreviewOnly}
            placeholder={"Enter your remarks"}
            onChange={(e) => {
              props.onRemarksChange(e.target.value);
            }}
            error={props.remarksError}
          />
        </div>
      </div>
    );
  };

  const renderReadOnlyBlocks = () => {
    return (
      <div className="horizontal-container px-2">
        <div className="input-block first">
          <text className="title">Analysis</text>
          <text className="value">{props.analysis}</text>
        </div>

        <div className="input-block ">
          <text className="title">Interpretation</text>
          <text className="value">{props.interpretation}</text>
        </div>

        <div className="input-block">
          <text className="title">Remarks</text>
          <text className="value">
            {!props.remarks || props.remarks === "" ? "-" : props.remarks}
          </text>
        </div>
      </div>
    );
  };

  const renderFormFooter = () => {
    console.log("props.severity :", props.severity);
    return (
      <div className="horizontal-container ">
        <div className="footer-child-container">
          <text className="title me-3">
            Severity
            {props.isPreviewOnly ? (
              <b>
                {props.severity?.label ? " : " + props.severity?.label : ""}
              </b>
            ) : (
              ""
            )}
          </text>

          <div className="option-item">
            {props.isPreviewOnly ? null : (
              // <Select
              //   label={null}
              //   placeholder="Select Severity"
              //   disabled={props.isPreviewOnly}
              //   isSearchable={
              //     Object.keys(props.severityData).length > 0 ? true : false
              //   }
              //   value={props.severity}
              //   options={props.severityData}
              // onChange={(val) => {
              //   console.log("SELECT : ", val);
              //   props.onSeverityChange(val);
              // }}
              //   error={props.severityError}
              //   menuPlacement="top"
              //   menuPositionFixed={true} // opean menu at top
              //   // compact // TODO - Make border invisible here...
              // />
              <RadioButtonGroup
                name="patient-condition-group"
                value={props.severity}
                onItemSelected={props.onSeverityChange}
                data={props.severityData}
                error={props.severityError}
              />
            )}
          </div>

          <div className="option-item">
            {props.isPreviewOnly ? (
              <div className="">
                <ThemedButton
                  title="Go back"
                  styles="primary medium"
                  icon={<FiArrowLeft color={"fff"} size={"12px"} />}
                  onClick={props.onGoBackButtonClick}
                />
              </div>
            ) : (
              <div className="option-item less-space">
                <ThemedButton
                  title="Resend"
                  styles="grey-outline small"
                  onClick={props.onResendButtonClick}
                />

                <ThemedButton
                  title="Second Opinion"
                  styles="primary-outline small"
                  onClick={props.onRequireSecondButtonClick}
                />

                <ThemedButton
                  title="Submit"
                  styles="primary medium"
                  onClick={props.onSubmitButtonClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderContactTechnician = () => {
    return (
      <div className="feedback-section">
        <text className="text">Feedback</text>
        <div onClick={props.onContactTechnicianClick} className="phone-icon">
          <BiPhone />
          <text>Contact Technician</text>
        </div>
      </div>
    );
  };

  const renderFormFooterForSupervisor = () => {
    return (
      <div className="horizontal-container px-2">
        <div className="input-block comments_section ">
          <text className="title">Comments</text>
          <TextArea
            label={""}
            value={props.comments}
            placeholder={"Enter your comments"}
            onChange={(e) => {
              props.onCommentsChange(e.target.value);
            }}
            error={props.commentsError}
          />
        </div>

        <div className="footer-child-container">
          <text className="title me-3">
            Severity
            {props.isPreviewOnly ? (
              <b>
                {props.severity?.label ? " : " + props.severity?.label : ""}
              </b>
            ) : (
              ""
            )}
          </text>

          <div className="option-item">
            {props.isPreviewOnly ? null : (
              // <Select
              //   label={null}
              //   placeholder="Select Severity"
              //   disabled={props.isPreviewOnly}
              //   isSearchable={
              //     Object.keys(props.severityData).length > 0 ? true : false
              //   }
              //   value={props.reviewedSeverity}
              //   options={props.severityData}
              //   error={props.reviewedSeverityError}
              //   onChange={(val) => {
              //     console.log("SELECT : ", val);
              //     props.onSeverityChange(val);
              //   }}
              //   menuPlacement="top"
              //   menuPositionFixed={true} // opean menu at top
              //   // compact // TODO - Make border invisible here...
              // />
              <RadioButtonGroup
                name="patient-status-group"
                value={props.reviewedSeverity}
                onItemSelected={props.onSeverityChange}
                data={props.severityData}
                error={props.reviewedSeverityError}
              />
            )}
          </div>
        </div>

        <div className="option-item less-space mt-2">
          <ThemedButton
            title="Submit"
            styles="primary medium"
            onClick={props.onSubmitButtonClickOfSupervisor}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="report-details-container-new2">
      <CardView extraStyle="card-container card-title">
        <div className="main-vertical-container">
          <div className="header-splitter">
            {renderTopPanel()}
            {renderSettingPanel()}
            {props.isPreviewOnly
              ? null
              : props.userRole === roles.supervisor || props.isCaseView
              ? null
              : renderResponseTimeCountdown()}
          </div>

          <div className="ecg-graph-from">
            <div
              className="ecg-graph"
              style={props.isCaseView == true ? { width: "100%" } : null}
            >
              <div style={{ position: "relative" }}>
                <canvas
                  id="gridcanvas"
                  width={
                    props.isCaseView == true
                      ? window.innerWidth - 80
                      : window.innerWidth -
                        Math.ceil(window.innerWidth * (30 / 100))
                  }
                  height={550}
                  style={{ position: "relative", top: "0px", left: "0px" }}
                ></canvas>
                <canvas
                  id="dataplottercanvas"
                  width={
                    props.isCaseView == true
                      ? window.innerWidth - 80
                      : window.innerWidth -
                        Math.ceil(window.innerWidth * (30 / 100))
                  }
                  height={550}
                  style={{ position: "absolute", top: "0px", left: "0px" }}
                ></canvas>
              </div>
              <div>
                <input
                  type="range"
                  min={0}
                  // max={props?.ecgDetails?.ecG_Array?.length ? props?.ecgDetails?.ecG_Array?.length - availableSpace : availableSpace}
                  max={
                    sweepSpeed == 0.5
                      ? parseInt(props?.ecgDetails?.ecG_Array?.length / 16)
                      : parseInt(props?.ecgDetails?.ecG_Array?.length / 4)
                  }
                  step={1}
                  value={ecgIndex}
                  onChange={(event) => {
                    setEcgIndex(event.target.valueAsNumber);
                  }}
                />
              </div>
            </div>
            {props.isCaseView ? null : (
              <div className="form">
                {renderContactTechnician()}
                {props.userRole === roles.supervisor
                  ? renderReadOnlyBlocks()
                  : renderInputBlocks()}
                {props.userRole === roles.supervisor
                  ? renderFormFooterForSupervisor()
                  : renderFormFooter()}
              </div>
            )}
          </div>
        </div>
        <SlClose
          size={20}
          onClick={
            props.userRole === roles.supervisor || props.isPreviewOnly
              ? props.onGoBackButtonClick
              : props.isCaseView == true
              ? props.onCloseReportByHospitalAdminClick
              : props.onAcceptedReportCancelButtonClick
          }
          style={{
            position: "absolute",
            top: "-10px",
            right: "-10px",
            zIndexndex: "1",
            color: "rgba(0, 0, 0, 0.30)",
            background: "white",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </CardView>

      <PopupDialog
        show={props.showContactTechnicianPopup}
        title={"Contact Details"}
        showCloseIcon={true}
        renderBodyContent={renderTechnicianContactDetailsPopupContent}
        confirmButtonTitle={"OK"}
        onclickConfirm={props.closeAllPopups}
        onclickCancel={props.closeAllPopups}
        hideCancelButton={true}
        popupSize="md"
      />

      <PopupDialog
        show={
          props.showResetTimerPopup ||
          props.showResendPopup ||
          props.showRequiredSecondOpinionPopup
        }
        title={
          props.showResetTimerPopup === true
            ? "Reset Timer"
            : props.showResendPopup === true
            ? "Resend Report"
            : props.showRequiredSecondOpinionPopup === true
            ? "Require Second Opinion"
            : "Coming Soon"
        }
        showCloseIcon={true}
        renderBodyContent={renderRequiredSecondOpinionContents}
        hideButtons={true}
        onclickConfirm={props.closeAllPopups}
        onclickCancel={props.closeAllPopups}
        popupSize={"md"}
      />
    </div>
  );
};

export default ECGReportDetailsView2;
