import React, { useState } from "react";
import CardView from "../../../../controls/Card/CardView";
import BarChartView from "../Other/BarChartView";
import { FaAngleDown } from "react-icons/fa6";
import "./CardStyles.scss";

const TopDevicesCardCenterAdmin = (props) => {
  const chartData =
    props.data.length > 0 ? props.data : [{ label: "", count: 0 }];

  return (
    <div className="center-admin-half-width-cards-wrapper right-card">
      <CardView
        extraStyle="top-performance-card-control"
        bodyStyle={{ height: "320px" }}
      >
        <div className="card-header">
          <div className="title">Device Performance</div>
        </div>

        <div className="info-wrapper">
          <div className="bar-chart-wrapper">
            <BarChartView dataSet={chartData} theme="blue" />
          </div>
        </div>
      </CardView>
    </div>
  );
};

export default TopDevicesCardCenterAdmin;
