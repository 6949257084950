export const actionTypes = {
  GET_HOSPITALS_PENDING: "GET_HOSPITALS_PENDING",
  GET_HOSPITALS_SUCCESS: "GET_HOSPITALS_SUCCESS",
  GET_HOSPITALS_ERROR: "GET_HOSPITALS_ERROR",

  GET_HOSPITALS_LIST_WITH_FILTER_PENDING:
    "GET_HOSPITALS_LIST_WITH_FILTER_PENDING",
  GET_HOSPITALS_LIST_WITH_FILTER_SUCCESS:
    "GET_HOSPITALS_LIST_WITH_FILTER_SUCCESS",
  GET_HOSPITALS_LIST_WITH_FILTER_ERROR: "GET_HOSPITALS_LIST_WITH_FILTER_ERROR",

  GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_PENDING:
    "GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_PENDING",
  GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_SUCCESS:
    "GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_SUCCESS",
  GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_ERROR:
    "GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_ERROR",

  SET_REPORTING_STATUS_PENDING: "SET_REPORTING_STATUS_PENDING",
  SET_REPORTING_STATUS_SUCCESS: "SET_REPORTING_STATUS_SUCCESS",
  SET_REPORTING_STATUS_ERROR: "SET_REPORTING_STATUS_ERROR",

  GET_HOSPITAL_DETAILS_BY_ID_PENDING: "GET_HOSPITAL_DETAILS_BY_ID_PENDING",
  GET_HOSPITAL_DETAILS_BY_ID_SUCCESS: "GET_HOSPITAL_DETAILS_BY_ID_SUCCESS",
  GET_HOSPITAL_DETAILS_BY_ID_ERROR: "GET_HOSPITAL_DETAILS_BY_ID_ERROR",

  GET_DOCTORS_LIST_BY_ID_PENDING: "GET_DOCTORS_LIST_BY_ID_PENDING",
  GET_DOCTORS_LIST_BY_ID_SUCCESS: "GET_DOCTORS_LIST_BY_ID_SUCCESS",
  GET_DOCTORS_LIST_BY_ID_ERROR: "GET_DOCTORS_LIST_BY_ID_ERROR",

  DELETE_HOSPITAL_PENDING: "DELETE_HOSPITAL_PENDING",
  DELETE_HOSPITAL_SUCCESS: "DELETE_HOSPITAL_SUCCESS",
  DELETE_HOSPITAL_ERROR: "DELETE_HOSPITAL_ERROR",

  DELETE_HOSPITALS_IN_BULK_PENDING: "DELETE_HOSPITALS_IN_BULK_PENDING",
  DELETE_HOSPITALS_IN_BULK_SUCCESS: "DELETE_HOSPITALS_IN_BULK_SUCCESS",
  DELETE_HOSPITALS_IN_BULK_ERROR: "DELETE_HOSPITALS_IN_BULK_ERROR",

  ADD_NEW_HOSPITALS_PENDING: "ADD_NEW_HOSPITALS_PENDING",
  ADD_NEW_HOSPITALS_SUCCESS: "ADD_NEW_HOSPITALS_SUCCESS",
  ADD_NEW_HOSPITALS_ERROR: "ADD_NEW_HOSPITALS_ERROR",

  EDIT_HOSPITAL_PENDING: "EDIT_HOSPITAL_PENDING",
  EDIT_HOSPITAL_SUCCESS: "EDIT_HOSPITAL_SUCCESS",
  EDIT_HOSPITAL_PENDING: "EDIT_HOSPITAL_PENDING",

  SET_SELECTED_HOSPITAL: "SET_SELECTED_HOSPITAL",
  RESET_SELECTED_HOSPITAL: "RESET_SELECTED_HOSPITAL",

  UPDATE_HOSPITAL_INFO_BY_ID_PENDING: "UPDATE_HOSPITAL_INFO_BY_ID_PENDING",
  UPDATE_HOSPITAL_INFO_BY_ID_SUCCESS: "UPDATE_HOSPITAL_INFO_BY_ID_SUCCESS",
  UPDATE_HOSPITAL_INFO_BY_ID_ERROR: "UPDATE_HOSPITAL_INFO_BY_ID_ERROR",

  ADD_NEW_CENTER_PENDING: "ADD_NEW_CENTER_PENDING",
  ADD_NEW_CENTER_SUCCESS: "ADD_NEW_CENTER_SUCCESS",
  ADD_NEW_CENTER_ERROR: "ADD_NEW_CENTER_ERROR",

  EDIT_HOSPITAL_CENTER_PENDING: "EDIT_HOSPITAL_CENTER_PENDING",
  EDIT_HOSPITAL_CENTER_SUCCESS: "EDIT_HOSPITAL_CENTER_SUCCESS",
  EDIT_HOSPITAL_CENTER_ERROR: "EDIT_HOSPITAL_CENTER_ERROR",

  DELETE_HOSPITAL_CENTER_PENDING: "DELETE_HOSPITAL_CENTER_PENDING",
  DELETE_HOSPITAL_CENTER_SUCCESS: "DELETE_HOSPITAL_CENTER_SUCCESS",
  DELETE_HOSPITAL_CENTER_ERROR: "DELETE_HOSPITAL_CENTER_ERROR",

  ADD_CENTER_ADMIN_PENDING: "ADD_CENTER_ADMIN_PENDING",
  ADD_CENTER_ADMIN_SUCCESS: "ADD_CENTER_ADMIN_SUCCESS",
  ADD_CENTER_ADMIN_ERROR: "ADD_CENTER_ADMIN_ERROR",

  GET_CENTER_BY_ID_PENDING: "GET_CENTER_BY_ID_PENDING",
  GET_CENTER_BY_ID_SUCCESS: "GET_CENTER_BY_ID_SUCCESS",
  GET_CENTER_BY_ID_ERROR: "GET_CENTER_BY_ID_ERROR",

  DELETE_HOSPITAL_CENTER_ADMIN_PENDING: "DELETE_HOSPITAL_CENTER_ADMIN_PENDING",
  DELETE_HOSPITAL_CENTER_ADMIN_SUCCESS: "DELETE_HOSPITAL_CENTER_ADMIN_SUCCESS",
  DELETE_HOSPITAL_CENTER_ADMIN_ERROR: "DELETE_HOSPITAL_CENTER_ADMIN_ERROR",

  RESET_GET_HOSPITAL_DETAILS_BY_ID: "RESET_GET_HOSPITAL_DETAILS_BY_ID",

  GET_HOSPITALS_NEAR_ANOTHER_PENDING: "GET_HOSPITALS_NEAR_ANOTHER_PENDING",
  GET_HOSPITALS_NEAR_ANOTHER_SUCCESS: "GET_HOSPITALS_NEAR_ANOTHER_SUCCESS",
  GET_HOSPITALS_NEAR_ANOTHER_ERROR: "GET_HOSPITALS_NEAR_ANOTHER_ERROR",
};

const initialState = {
  getHospitalsPending: false,
  getHospitalsSuccess: false,
  getHospitalsError: null,

  getHospitalsListWithFilterPending: false,
  getHospitalsListWithFilterSuccess: false,
  getHospitalsListWithFilterError: null,

  getHospitalDetailsByIdPending: false,
  getHospitalDetailsByIdSuccess: false,
  getHospitalDetailsByIdError: null,

  getDoctorsListByIdPending: false,
  getDoctorsListByIdSuccess: false,
  getDoctorsListByIdError: null,

  setReportingStatusPending: false,
  setReportingStatusSuccess: false,
  setReportingStatusError: null,

  addNewHospitalPending: false,
  addNewHospitalSuccess: false,
  addNewHospitalError: null,

  editHospitalCenterPending: false,
  editHospitalCenterSuccess: false,
  editHospitalCenterError: null,

  deleteHospitalCenterAdminPending: false,
  deleteHospitalCenterAdminSuccess: false,
  deleteHospitalCenterAdminError: null,

  deleteHospitalPending: false,
  deleteHospitalSuccess: false,
  deleteHospitalError: null,

  deleteHospitalsInBulkPending: false,
  deleteHospitalsInBulkSuccess: false,
  deleteHospitalsInBulkError: null,

  getReportingHospitalsListWithFilterPending: false,
  getReportingHospitalsListWithFilterSuccess: false,
  getReportingHospitalsListWithFilterError: null,

  updateHospitalInfoByIdPending: false,
  updateHospitalInfoByIdSuccess: false,
  updateHospitalInfoByIdError: null,

  editCenterHospitalPending: false,
  editCenterHospitalSuccess: false,
  editCenterHospitalError: null,

  addNewCenterPending: false,
  addNewCenterSuccess: false,
  addNewCenterError: null,

  deleteHospitalCenterPending: false,
  deleteHospitalCenterSuccess: false,
  deleteHospitalCenterError: null,

  addCenterAdminPending: false,
  addCenterAdminSuccess: false,
  addCenterAdminError: null,

  getCenterByIdPending: false,
  getCenteByIdSuccess: false,
  getCenteByIdError: null,
  getCenterData: null,

  getHospitalsNearAnotherPending: false,
  getHospitalsNearAnotherSuccess: false,
  getHospitalsNearAnotherError: null,

  filterdHospitalList: [],
  filteredHospitalCount: 0,
  filterdReportingHospitalList: [],
  filteredReportingHospitalCount: 0,
  selectedHospital: null,
  doctorList: [],
  HospitalDetails: null,
  updateHospitalData: null,
  hospitalNearByList: [],
};

export default function hospital(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_HOSPITALS_PENDING:
    case actionTypes.GET_HOSPITALS_SUCCESS:
    case actionTypes.GET_HOSPITALS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_HOSPITALS_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DOCTORS_LIST_BY_ID_PENDING:
    case actionTypes.GET_DOCTORS_LIST_BY_ID_SUCCESS:
    case actionTypes.GET_DOCTORS_LIST_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_REPORTING_HOSPITALS_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_HOSPITAL_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_HOSPITAL_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_HOSPITAL_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_REPORTING_STATUS_PENDING:
    case actionTypes.SET_REPORTING_STATUS_SUCCESS:
    case actionTypes.SET_REPORTING_STATUS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_NEW_HOSPITALS_PENDING:
    case actionTypes.ADD_NEW_HOSPITALS_SUCCESS:
    case actionTypes.ADD_NEW_HOSPITALS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_HOSPITAL_PENDING:
    case actionTypes.DELETE_HOSPITAL_SUCCESS:
    case actionTypes.DELETE_HOSPITAL_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_HOSPITALS_IN_BULK_PENDING:
    case actionTypes.DELETE_HOSPITALS_IN_BULK_SUCCESS:
    case actionTypes.DELETE_HOSPITALS_IN_BULK_SUCCESS:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_PENDING:
    case actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_SUCCESS:
    case actionTypes.UPDATE_HOSPITAL_INFO_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_NEW_CENTER_PENDING:
    case actionTypes.ADD_NEW_CENTER_SUCCESS:
    case actionTypes.ADD_NEW_CENTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_HOSPITAL_CENTER_PENDING:
    case actionTypes.EDIT_HOSPITAL_CENTER_SUCCESS:
    case actionTypes.EDIT_HOSPITAL_CENTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_HOSPITAL_CENTER_PENDING:
    case actionTypes.DELETE_HOSPITAL_CENTER_SUCCESS:
    case actionTypes.DELETE_HOSPITAL_CENTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_CENTER_ADMIN_PENDING:
    case actionTypes.ADD_CENTER_ADMIN_SUCCESS:
    case actionTypes.ADD_CENTER_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_CENTER_BY_ID_PENDING:
    case actionTypes.GET_CENTER_BY_ID_SUCCESS:
    case actionTypes.GET_CENTER_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_PENDING:
    case actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_SUCCESS:
    case actionTypes.DELETE_HOSPITAL_CENTER_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_HOSPITAL:
    case actionTypes.RESET_SELECTED_HOSPITAL:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_GET_HOSPITAL_DETAILS_BY_ID:
      return Object.assign({}, state, payload);

    case actionTypes.GET_HOSPITALS_NEAR_ANOTHER_PENDING:
    case actionTypes.GET_HOSPITALS_NEAR_ANOTHER_SUCCESS:
    case actionTypes.GET_HOSPITALS_NEAR_ANOTHER_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
