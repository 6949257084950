// app
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const CHANGE_PASSWORD = "/change_password";
export const OTPVERIFICATION = "/otpverification";

// OLD UI Screens -----------------------------

export const HOME = "/home";
export const HOSPITALS_LIST = "/hospital_list";
export const DOCTORS_LIST = "/doctors_list";
export const TECHNICIANS_LIST = "/technician_list";
export const DEVICES_LIST = "/device_list";
export const REPORTS_LIST = "/reports_list";
export const REPORT_DETAIL = "/report_detail/:id";
export const CENTER_LIST = "/center_list";
export const CENTER_LIST_BY_HOSPITAL = "/hospitals/center_list";

// NEW UI Screens -----------------------------

export const DASHBOARD_NEW = "/dashboard_new";
export const ADMINISTRATORS = "/administrators";
export const ADMINISTRATORS_HOSPITALS = "/administrators/hospitals";
export const HOSPITALS = "/hospitals";
export const HOSPITALS_CENTERS = "/hospitals/centers";
export const DEVICES = "/devices";
export const TECHNICIANS = "/technicians";
export const DOCTORS = "/doctors";
export const ECG_REPORTS = "/ecg_reports";
export const ECG_REPORTS_BY_DOCTOR = "/ecg_reports_by_doctor";
export const ECG_REPORT_DETAILS = "/ecg_report_details";
export const ERROR_LOGS = "/error_logs";
export const MY_PROFILE = "/my_profile";
export const TEST_SCREEN = "/test_screen";
export const AMBULANCES = "/ambulances";

export const HOSPITAL_INFO = "/hospital_info";
export const FORGOTPASSWORD = "/forgot_password";
export const SUPERVISOR = "/supervisors";
export const CASES = "/cases";
export const CASES_DETAILS = "/case_details";
