export const actionTypes = {
  GET_TECHNICIAN_LIST_WITH_FILTER_PENDING:
    "GET_TECHNICIAN_LIST_WITH_FILTER_PENDING",
  GET_TECHNICIAN_LIST_WITH_FILTER_SUCCESS:
    "GET_TECHNICIAN_LIST_WITH_FILTER_SUCCESS",
  GET_TECHNICIAN_LIST_WITH_FILTER_ERROR:
    "GET_TECHNICIAN_LIST_WITH_FILTER_ERROR",

  GET_ASSIGNED_DEVICE_OF_TECHNICIAN_PENDING:
    "GET_ASSIGNED_DEVICE_OF_TECHNICIAN_PENDING",
  GET_ASSIGNED_DEVICE_OF_TECHNICIAN_SUCCESS:
    "GET_ASSIGNED_DEVICE_OF_TECHNICIAN_SUCCESS",
  GET_ASSIGNED_DEVICE_OF_TECHNICIAN_ERROR:
    "GET_ASSIGNED_DEVICE_OF_TECHNICIAN_ERROR",

  GET_TECHNICIAN_DETAILS_BY_ID_PENDING: "GET_TECHNICIAN_DETAILS_BY_ID_PENDING",
  GET_TECHNICIAN_DETAILS_BY_ID_SUCCESS: "GET_TECHNICIAN_DETAILS_BY_ID_SUCCESS",
  GET_TECHNICIAN_DETAILS_BY_ID_ERROR: "GET_TECHNICIAN_DETAILS_BY_ID_ERROR",

  ADD_NEW_TECHNICIAN_PENDING: "ADD_NEW_TECHNICIAN_PENDING",
  ADD_NEW_TECHNICIAN_SUCCESS: "ADD_NEW_TECHNICIAN_SUCCESS",
  ADD_NEW_TECHNICIAN_ERROR: "ADD_NEW_TECHNICIAN_ERROR",

  EDIT_TECHNICIAN_PENDING: "EDIT_TECHNICIAN_PENDING",
  EDIT_TECHNICIAN_SUCCESS: "EDIT_TECHNICIAN_SUCCESS",
  EDIT_TECHNICIAN_ERROR: "EDIT_TECHNICIAN_ERROR",

  DELETE_TECHNICIAN_PENDING: "DELETE_TECHNICIAN_PENDING",
  DELETE_TECHNICIAN_SUCCESS: "DELETE_TECHNICIAN_SUCCESS",
  DELETE_TECHNICIAN_ERROR: "DELETE_TECHNICIAN_ERROR",

  DELETE_TECHNICIAN_IN_BULK_PENDING: "DELETE_TECHNICIAN_IN_BULK_PENDING",
  DELETE_TECHNICIAN_IN_BULK_SUCCESS: "DELETE_TECHNICIAN_IN_BULK_SUCCESS",
  DELETE_TECHNICIAN_IN_BULK_ERROR: "DELETE_TECHNICIAN_IN_BULK_ERROR",
};

const initialState = {
  getTechnicianListWithFilterPending: false,
  getTechnicianListWithFilterSuccess: false,
  getTechnicianListWithFilterError: null,

  getAssignedDeviceOfTechnicianPending: false,
  getAssignedDeviceOfTechnicianSuccess: false,
  getAssignedDeviceOfTechnicianError: null,

  getTechnicianDetailsByIdPending: false,
  getTechnicianDetailsByIdSuccess: false,
  getTechnicianDetailsByIdError: null,

  addNewTechnicianPending: false,
  addNewTechnicianSuccess: false,
  addNewTechnicianError: null,

  editTechnicianPending: false,
  editTechnicianSuccess: false,
  editTechnicianError: null,

  deleteTechnicianPending: false,
  deleteTechnicianSuccess: false,
  deleteTechnicianError: null,

  deleteTechnicianInBulkPending: false,
  deleteTechnicianInBulkSuccess: false,
  deleteTechnicianInBulkError: null,

  technicianList: [],
  filteredTechnicianList: [],
  technicianDetails: {},
  filteredTechnicianCount: 0,
  assignedDevice: null,
};

export default function technician(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_PENDING:
    case actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_SUCCESS:
    case actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_NEW_TECHNICIAN_PENDING:
    case actionTypes.ADD_NEW_TECHNICIAN_SUCCESS:
    case actionTypes.ADD_NEW_TECHNICIAN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_TECHNICIAN_PENDING:
    case actionTypes.EDIT_TECHNICIAN_SUCCESS:
    case actionTypes.EDIT_TECHNICIAN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_TECHNICIAN_PENDING:
    case actionTypes.DELETE_TECHNICIAN_SUCCESS:
    case actionTypes.DELETE_TECHNICIAN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_TECHNICIAN_IN_BULK_PENDING:
    case actionTypes.DELETE_TECHNICIAN_IN_BULK_SUCCESS:
    case actionTypes.DELETE_TECHNICIAN_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
