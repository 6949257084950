import React, { Component, createRef, useEffect, useRef } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getAmbulanceListWithFilter,
  getAmbulanceDetailsById,
  deleteAmbulanceInBulk,
  addAmbulanceInBulk,
  deleteAmbulance,
  editAmbulance,
  addAmbulance,
} from "../../../../store/ambulance/ambulanceActions";
import {
  uploadImage,
  deleteImage,
} from "../../../../store/master/masterActions";
import { unlockUser } from "../../../../store/master/masterActions";
import { getReportScreensState } from "../../../../store/report/reportActions";
import { getHospitalsListWithFilter } from "../../../../store/hospital/hospitalActions";
import { history } from "../../../../store/configure/configureStore";
import * as validator from "../../../../utils/validator";
import { trimFormData } from "../../../../utils/common";
import { roles } from "../../../../constants/roles";
import AmbulanceView from "./AmbulancesView";
import { globalConstants } from "../../../../constants/globalConstants";
import { strings } from "../../../../constants/stringConstants";

class AmbulancesContainer extends Component {
  validatorFieldsForAmbulance = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForAmbulance["required"] = [
      "ambulanceName",
      "ambulanceNo",
      "ambulanceType",
      "assignHospital",
      "email",
      "password",
      "confirmPassword",
    ];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: null,
      order: null,
      ambulanceList: [],
      totalAmbulances: 0,
      //
      showAddBulkAmbulancePopup: false,
      showAddNewAmbulancePopup: false,
      showEditAmbulancePopup: false,
      showDeleteAmbulancePopup: false,
      showBulkDeleteAmbulanceConfirmPopup: false,
      showAmbulanceLogoPopup: false,
      disableSingleDeleteButton: false,
      //
      showUnlockAmbulanceConfirmationPopup: false,
      // checkOnEditImagePresent: false,
      // Search filters
      filters: {
        text: "",
        ambulanceStatus: null,
      },
      searchStringParam: null,
      noScrollHospital: false,
      ambulanceInfo: {},

      //error logs error
      isValidationsOn: false,

      //add new device
      addNewAmbulancefields: {
        ambulanceName: "",
        ambulanceNo: "",
        ambulanceType: "",
        ambulanceLogo: "",
        assignHospital: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      mainAambulanceLogo: null,
      errors: {},
      //
      ambulanceTypeData: [
        { value: 1, label: "Cardiac" },
        { value: 2, label: "ICU" },
        { value: 3, label: "General" },
      ],

      AmbulanceCategoryData: [
        { value: 1, label: "Privately Owned" },
        { value: 2, label: "Government" },
      ],
      isGovernment: false,
      ambCategoryType: "",
      //Bulk Delete
      selectedIdsToDelete: [],
      clearSelectedRows: false,
      searchHospitalText: "",
      hospitalList: [],
      detailhospitalList: [],
      hospitalListPage: 1,
      pageCountHospital: 2,
      //stepper
      file: [],
      fileName: "",
      assignAmbulanceInBulk: "",
      detailsAssignAmbulanceInBulk: {},
      disableNextButton: true,
      pregressPer: 0,
      showProgress: false,
      fileInputRef: React.createRef(null),
      activeStep: 0,
    };

    this.callGetAmbulanceListAPI = this.callGetAmbulanceListAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.onAddBulkAmbulancesClick = this.onAddBulkAmbulancesClick.bind(this);
    this.onAddNewAmbulancesClick = this.onAddNewAmbulancesClick.bind(this);
    this.closeAllPopups = this.closeAllPopups.bind(this);

    this.onAddNewAmbulanceConfirmClick =
      this.onAddNewAmbulanceConfirmClick.bind(this);
    this.onAddAmbulanceFieldChange = this.onAddAmbulanceFieldChange.bind(this);
    this.onCloseAddAmbulancePopup = this.onCloseAddAmbulancePopup.bind(this);

    this.handleFileInputClick = this.handleFileInputClick.bind(this);
    this.addBulkAmbulancesConfirmClick =
      this.addBulkAmbulancesConfirmClick.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.onCloseAddBulkAmbulancePopup =
      this.onCloseAddBulkAmbulancePopup.bind(this);

    this.onHospitalSearchChange = this.onHospitalSearchChange.bind(this);

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkAmbulanceDeleteClick =
      this.onBulkAmbulanceDeleteClick.bind(this);
    this.onBulkAmbulanceDeleteConfirmClick =
      this.onBulkAmbulanceDeleteConfirmClick.bind(this);

    this.onEditAmbulanceConfirmClick =
      this.onEditAmbulanceConfirmClick.bind(this);
    this.onCloseEditAmbulancePopup = this.onCloseEditAmbulancePopup.bind(this);

    this.onDeleteAmbulanceConfirmClick =
      this.onDeleteAmbulanceConfirmClick.bind(this);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onEditAmbulanceClick = this.onEditAmbulanceClick.bind(this);
    this.onDeleteAmbulanceClick = this.onDeleteAmbulanceClick.bind(this);
    //
    this.onAddBulkAmbulanceFieldChange =
      this.onAddBulkAmbulanceFieldChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);

    this.handleFileDropClick = this.handleFileDropClick.bind(this);
    this.viewAmbulanceLogoClicked = this.viewAmbulanceLogoClicked.bind(this);
    this.onHandleUploadAmbulanceImage =
      this.onHandleUploadAmbulanceImage.bind(this);
    this.deleteAmbulanceLogoClicked =
      this.deleteAmbulanceLogoClicked.bind(this);

    this.onAmbulanceListSortClick = this.onAmbulanceListSortClick.bind(this);
    this.onAmbulanceListFilterClick =
      this.onAmbulanceListFilterClick.bind(this);

    this.onAmbulanceGovtOrPvtChange =
      this.onAmbulanceGovtOrPvtChange.bind(this);
    this.closeViewAmbulanceLogo = this.closeViewAmbulanceLogo.bind(this);

    this.onUnlockAmbulanceAccountClick =
      this.onUnlockAmbulanceAccountClick.bind(this);
    this.onUnlockAmbulanceAccountConfirmClick =
      this.onUnlockAmbulanceAccountConfirmClick.bind(this);
    this.closeUnlockAmbulancePopup = this.closeUnlockAmbulancePopup.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({
      userRole: loginUserRole,
      ambCategoryType: this.state.AmbulanceCategoryData[0],
    });

    this.callGetHospitalListAPI();
    this.callGetAmbulanceListAPI();
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {}

  handleFileInputClick = () => {
    this.state.fileInputRef.current.click();
  };

  handleFileDropClick = (e) => {
    const temp = document.getElementById("border-active");
    temp.style.border = "";
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    console.log(droppedFile);
    if (droppedFile === undefined) {
      this.state.errors["file"] = "Field can not be empty";
    } else if (droppedFile.type !== "text/csv") {
      this.setState({ file: [], disableNextButton: true });
      this.state.errors["file"] = "Only .CSV files are accepted.";
    } else {
      const maxSize = 6 * 1024 * 1024;
      const fileName =
        droppedFile.name.length > 28
          ? `${droppedFile.name.split(".")[0].substring(0, 28)}... .${
              droppedFile.name.split(".")[1]
            }`
          : droppedFile.name;
      if (droppedFile.size > maxSize) {
        this.state.errors["file"] = "file size exceeds 6 MB limit";
        this.setState({
          fileName: "",
          file: [],
          disableNextButton: true,
        });
      } else {
        this.setState({
          fileName: fileName,
          file: e.dataTransfer.files,
          disableNextButton: false,
        });
        this.state.errors["file"] = "";
      }
    }
  };

  onAddBulkAmbulanceFieldChange = (field) => (e) => {
    if (field === "file") {
      //
      console.log(e.target.files);
      const file = e.target.files[0];
      if (file === undefined) {
        this.state.errors[field] = "Field can not be empty";
      } else if (file.type !== "text/csv") {
        this.setState({ file: [], disableNextButton: true });
        this.state.errors[field] = "Only .CSV files are accepted.";
      } else {
        const maxSize = 6 * 1024 * 1024;
        const fileName =
          file.name.length > 28
            ? `${file.name.split(".")[0].substring(0, 28)}... .${
                file.name.split(".")[1]
              }`
            : file.name;
        if (file.size > maxSize) {
          this.state.errors[field] = "file size exceeds 6 MB limit";
          this.setState({
            fileName: "",
            file: [],
            disableNextButton: true,
          });
        } else {
          this.setState({
            fileName: fileName,
            file: e.target.files,
            disableNextButton: false,
          });
          this.state.errors[field] = "";
        }
      }
    } else if (field === "assignAmbulanceInBulk") {
      //
      if (e === null || e.value === "" || e === "") {
        this.setState({
          assignAmbulanceInBulk: "",
          disableNextButton: true,
          searchHospitalText: "",
          noScrollHospital: false,
          pageCountHospital: 2,
          detailsAssignAmbulanceInBulk: {},
        });
      } else {
        const detailsOfAssignHospitalInBulk =
          this.state.detailhospitalList.find((item) => item.id === e.value);
        console.log(
          "detailsOfAssignHospitalInBulk",
          detailsOfAssignHospitalInBulk
        );
        this.setState({
          assignAmbulanceInBulk: e,
          disableNextButton: false,
          searchHospitalText: "",
          noScrollHospital: false,
          pageCountHospital: 2,
          detailsAssignAmbulanceInBulk: detailsOfAssignHospitalInBulk,
        });
      }
    }
  };

  addBulkAmbulancesConfirmClick = () => {
    this.props.addAmbulanceInBulk(
      this.state.file[0],
      this.state.assignAmbulanceInBulk.value,
      (response) => {
        if (response !== null) {
          this.setState({
            activeStep: 0,
            showAddBulkAmbulancePopup: false,
            file: [],
            fileName: "",
            assignAmbulanceInBulk: "",
            disableNextButton: true,
            pregressPer: 0,
            searchHospitalText: "",
            errors: {},
            noScrollHospital: false,
          });
          this.callGetAmbulanceListAPI();
        }
      }
    );
  };

  onCloseAddBulkAmbulancePopup = () => {
    this.setState(
      {
        activeStep: 0,
        showAddBulkAmbulancePopup: false,
        file: [],
        fileName: "",
        assignAmbulanceInBulk: "",
        disableNextButton: true,
        pregressPer: 0,
        errors: {},
        noScrollHospital: false,
        detailsAssignAmbulanceInBulk: {},
        searchHospitalText: "",
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  handleDeleteFile = () => {
    this.setState({ file: [], disableNextButton: true });
    this.state.fileInputRef.current.value = null;
    console.log("referance", this.state.fileInputRef);
  };

  //--------------------------- Get Devices List API Call ------------------------

  callGetAmbulanceListAPI = () => {
    this.props.getAmbulanceListWithFilter(
      this.state.filters,
      (listData, totalPages, count) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(listData, count);
          this.forceUpdate();
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  callGetHospitalListAPI = () => {
    this.props.getHospitalsListWithFilter(
      {
        text: this.state.searchHospitalText,
      },
      (list, count, metaData) => {
        const newList = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({ hospitalList: newList, detailhospitalList: list });
      },
      this.state.hospitalListPage,
      25
    );
  };

  resetAmbulanceFormField = () => {
    this.setState({
      showAddNewAmbulancePopup: false,
      showEditAmbulancePopup: false,
      isValidationsOn: false,
      noScrollHospital: false,
      // checkOnEditImagePresent: true,
      isGovernment: false,
      errors: {},
      pageCounttechnician: 2,
      pageCountHospital: 2,
      ambCategoryType: this.state.AmbulanceCategoryData[0],
      mainAambulanceLogo: null,
      addNewAmbulancefields: {
        ...this.state.addNewAmbulancefields,
        ambulanceName: "",
        ambulanceNo: "",
        ambulanceType: "",
        ambulanceLogo: null,
        assignHospital: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    });
  };

  //------------------------------ Add New Device popup field control ----------------------------------------

  onAssignTechnicianChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        addNewAmbulancefields: {
          ...this.state.addNewAmbulancefields,
          assignTechnician: value,
        },
        searchTechnicianText: "",
        noScrollTechnician: false,
        pageCounttechnician: 2,
      });
    } else {
      this.setState({
        addNewAmbulancefields: {
          ...this.state.addNewAmbulancefields,
          assignTechnician: "",
        },
        noScrollTechnician: false,
        pageCounttechnician: 2,
      });
    }
  };

  onScrollHospitalList = () => {
    console.log("noScrollHospital", this.state.noScrollHospital);
    if (!this.state.noScrollHospital) {
      this.props.getHospitalsListWithFilter(
        {
          text: this.state.searchHospitalText,
        },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              if (this.state.pushedHospitalId !== item.id) {
                return { label: item.hospitalName, value: item.id };
              }
            });
            const newListData = listData.filter(function (item) {
              return item !== undefined;
            });
            this.setState({
              hospitalList: [...this.state.hospitalList, ...newListData],
              detailhospitalList: [...this.state.detailhospitalList, ...list],
            });
            ++this.state.pageCountHospital;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCountHospital, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onAddAmbulanceFieldChange = (field) => (e) => {
    let value;
    const { addNewAmbulancefields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;
    console.log(field, "&", e);

    var isRequired = this.validatorFieldsForAmbulance.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    } else if (field === "ambulanceType" || field === "assignHospital") {
      if (e === null || e.value === "" || e === "") {
        this.state.errors[field] = "Field can not be empty";
      } else {
        this.state.errors[field] = "";
        value = e;
      }
    }
    this.setState({
      ...this.state,
      addNewAmbulancefields: { ...addNewAmbulancefields, [field]: value },
    });
  };

  onHandleUploadAmbulanceImage = (data) => {
    const { addNewAmbulancefields } = this.state;
    const localUrl = URL.createObjectURL(data);
    //
    if (this.state.showEditAmbulancePopup) {
      this.props.uploadImage(
        data,
        globalConstants.AMBULANCE_IMAGE,
        (imageUrl) => {
          if (imageUrl !== null) {
            this.setState({
              ...this.state,
              addNewAmbulancefields: {
                ...addNewAmbulancefields,
                ambulanceLogo: imageUrl,
              },
            });
          }
        },
        this.state.ambulanceInfo?.id,
        true
      );
    } else {
      this.setState({
        ...this.state,
        addNewAmbulancefields: {
          ...addNewAmbulancefields,
          ambulanceLogo: localUrl,
        },
        mainAambulanceLogo: data,
        // checkOnEditImagePresent: false,
      });
    }
  };

  deleteAmbulanceLogoClicked = (data) => {
    const { addNewAmbulancefields } = this.state;
    if (
      this.state.showAddNewAmbulancePopup
      // (this.state.checkOnEditImagePresent && this.state.showEditAmbulancePopup)
    ) {
      this.setState({
        ...this.state,
        addNewAmbulancefields: {
          ...addNewAmbulancefields,
          ambulanceLogo: null,
        },
        mainAambulanceLogo: null,
      });
    } else {
      this.props.deleteImage(
        this.state.ambulanceInfo.id,
        globalConstants.AMBULANCE_IMAGE,
        (isSuccess) => {
          if (isSuccess) {
            this.setState({
              ...this.state,
              addNewAmbulancefields: {
                ...addNewAmbulancefields,
                ambulanceLogo: null,
              },
              // checkOnEditImagePresent: true,
              mainAambulanceLogo: null,
            });
          }
        }
      );
    }
  };

  onAmbulanceGovtOrPvtChange = (value) => {
    this.setState({ isGovernment: value.label === "Government" });
  };

  //---------------------report device pop up fields control------------------------------

  onHospitalSearchChange = (text) => {
    console.log("onHospitalSearchChange", text);
    this.setState(
      {
        searchHospitalText: text,
        pageCountHospital: 2,
        noScrollHospital: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  //--------------------- Pop up dialog controls ---------------------

  //-------------------------- ADD Device ------------------------------------
  onAddBulkAmbulancesClick = () => {
    this.setState({ showAddBulkAmbulancePopup: true });
  };

  onAddNewAmbulancesClick = () => {
    this.setState({ showAddNewAmbulancePopup: true });
  };
  onAddNewAmbulanceConfirmClick = () => {
    const custom = [
      validator.email(["email"]),
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
    ];
    this.validatorFieldsForAmbulance["custom"] = custom;
    const { addNewAmbulancefields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForAmbulance,
      trimFormData(addNewAmbulancefields)
    );
    if (
      addNewAmbulancefields.confirmPassword.length > 0 &&
      addNewAmbulancefields.password !== addNewAmbulancefields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }
    console.log("errors", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      console.log("addNewAmbulancefields", addNewAmbulancefields);
      const data = {
        email: addNewAmbulancefields.email?.trim() ?? null,
        password: addNewAmbulancefields.password,
        ambulanceName: addNewAmbulancefields.ambulanceName?.trim() ?? null,
        hospitalId: addNewAmbulancefields.assignHospital?.value ?? null,
        ambulanceType: addNewAmbulancefields.ambulanceType?.value ?? null,
        ambulanceRegistrationNumber:
          addNewAmbulancefields.ambulanceNo?.trim() ?? null,
        isGovernment: this.state.isGovernment,
      };
      this.props.addAmbulance(data, (response) => {
        if (response) {
          if (this.state.mainAambulanceLogo && response !== null) {
            this.props.uploadImage(
              this.state.mainAambulanceLogo,
              globalConstants.AMBULANCE_IMAGE,
              (imageUrl) => {
                if (imageUrl !== null) {
                  this.resetAmbulanceFormField();
                  this.callGetAmbulanceListAPI();
                  this.callGetHospitalListAPI();
                }
              },
              response?.id,
              false
            );
          } else {
            this.resetAmbulanceFormField();
            this.callGetAmbulanceListAPI();
            this.callGetHospitalListAPI();
          }
        }
      });
    }
  };

  onCloseAddAmbulancePopup = () => {
    this.resetAmbulanceFormField();
    this.callGetHospitalListAPI();
  };

  closeViewAmbulanceLogo = () => {
    this.setState({
      ...this.state,
      showAmbulanceLogoPopup: false,
    });
  };
  viewAmbulanceLogoClicked = () => {
    this.setState({
      ...this.state,
      showAmbulanceLogoPopup: true,
    });
  };

  //------------------------ unlock ambulance -----------------------

  onUnlockAmbulanceAccountClick = (rowData) => {
    this.setState({
      ambulanceInfo: rowData,
      showUnlockAmbulanceConfirmationPopup: true,
    });
  };

  onUnlockAmbulanceAccountConfirmClick = () => {
    this.props.unlockUser(this.state.ambulanceInfo.userId, () => {
      this.setState(
        {
          ambulanceInfo: {},
          showUnlockAmbulanceConfirmationPopup: false,
        },
        () => {
          this.callGetAmbulanceListAPI();
        }
      );
    });
  };

  closeUnlockAmbulancePopup = () => {
    this.setState({
      ambulanceInfo: {},
      showUnlockAmbulanceConfirmationPopup: false,
    });
  };

  //-------------------------------- Update device -------------------------------------------------

  onEditAmbulanceConfirmClick = () => {
    const custom = [];
    this.validatorFieldsForAmbulance["custom"] = custom;
    const { addNewAmbulancefields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForAmbulance,
      trimFormData(addNewAmbulancefields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    console.log("err", errors);
    if (!Object.keys(errors).length) {
      const data = {
        email: addNewAmbulancefields.email?.trim() ?? null,
        ambulanceName: addNewAmbulancefields.ambulanceName?.trim() ?? null,
        hospitalId: addNewAmbulancefields.assignHospital?.value ?? null,
        ambulanceType: addNewAmbulancefields.ambulanceType?.value ?? null,
        ambulanceRegistrationNumber:
          addNewAmbulancefields.ambulanceNo?.trim() ?? null,
        isGovernment: this.state.isGovernment,
      };
      this.props.editAmbulance(
        this.state.ambulanceInfo.id,
        data,
        (response) => {
          this.resetAmbulanceFormField();
          this.callGetAmbulanceListAPI();
          this.callGetHospitalListAPI();

          this.validatorFieldsForAmbulance["required"].push("password");
          this.validatorFieldsForAmbulance["required"].push("confirmPassword");
        }
      );
    }
  };

  //---------------------------------- Deelete Device --------------------------------------

  onDeleteAmbulanceConfirmClick = () => {
    this.props.deleteAmbulance(this.state.ambulanceInfo.id, () => {
      this.setState({ showDeleteAmbulancePopup: false });
      this.callGetAmbulanceListAPI();
    });
  };

  onCloseEditAmbulancePopup = () => {
    this.validatorFieldsForAmbulance["required"].push("password");
    this.validatorFieldsForAmbulance["required"].push("confirmPassword");
    this.resetAmbulanceFormField();
    this.callGetHospitalListAPI();
  };

  closeAllPopups = () => {
    this.setState({
      showAddBulkAmbulancePopup: false,
      showAddNewAmbulancePopup: false,
      showDeleteAmbulancePopup: false,
      showBulkDeleteAmbulanceConfirmPopup: false,
      errors: {},
    });
  };

  //--------------------- List Action Button Clicks --------------------

  onEditAmbulanceClick = (row) => {
    this.validatorFieldsForAmbulance["required"] =
      this.validatorFieldsForAmbulance["required"].filter(function (item) {
        return item !== "password" && item !== "confirmPassword";
      });
    this.props.getAmbulanceDetailsById(row.id, (details) => {
      console.log("device details", details);
      const hospitaldetails = details?.assignHospitals ?? null;
      var hospitalObject;

      const ambTypeObject = this.state.ambulanceTypeData.find(
        (item) => item.value === details?.ambulanceTypeInfo?.id ?? ""
      );
      const hospitalIndex = this.state.hospitalList.findIndex(
        (item) => item.value === hospitaldetails?.hospitalId
      );
      if (hospitaldetails !== null) {
        if (hospitalIndex !== -1) {
          hospitalObject = this.state.hospitalList[hospitalIndex]; // if hospital is not present in first 25 then we manually create object and push
        } else {
          hospitalObject = {
            value: hospitaldetails?.id,
            label: hospitaldetails?.hospitalName,
          };
          this.state.hospitalList.push(hospitalObject);
        }
      }
      console.log(
        "details?.ambulanceImage",
        details?.ambulanceImage == null || details?.ambulanceImage == undefined
          ? true
          : false
      );
      this.setState({
        showEditAmbulancePopup: true,
        isValidationsOn: true,
        ambulanceInfo: row,
        pushedHospitalId: hospitalObject?.value,
        addNewAmbulancefields: {
          ...this.state.addNewAmbulancefields,
          ambulanceName: details?.ambulanceName ?? "",
          ambulanceNo: details?.ambulanceRegistrationNumber ?? "",
          ambulanceType: ambTypeObject ? ambTypeObject : "",
          ambulanceLogo: details?.ambulanceImage ?? null,
          assignHospital: hospitalObject,
          email: details?.user?.userName ?? "",
        },
        isGovernment: details.isGovernment,
        ambCategoryType:
          details.isGovernment === true
            ? this.state.AmbulanceCategoryData[1]
            : this.state.AmbulanceCategoryData[0],
        mainAambulanceLogo: details?.ambulanceImage ?? null,
        // checkOnEditImagePresent:
        //   details?.ambulanceImage == null ||
        //   details?.ambulanceImage == undefined
        //     ? true
        //     : false,
        searchHospitalText: "",
      });
    });
  };

  onDeleteAmbulanceClick = (row) => {
    this.setState({ ambulanceInfo: row, showDeleteAmbulancePopup: true });
  };

  //--------------------------------------------- delete bulk devices ------------------------------

  handleSelectedRowsChange = (selectedRowsArray) => {
    var devicesIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({
      selectedIdsToDelete: devicesIds,
      disableSingleDeleteButton: devicesIds?.length > 0 ? true : false,
    });
  };

  onBulkAmbulanceDeleteClick = () => {
    this.setState({
      showBulkDeleteAmbulanceConfirmPopup: true,
      disableSingleDeleteButton: false,
    });
  };

  onBulkAmbulanceDeleteConfirmClick = () => {
    this.props.deleteAmbulanceInBulk(
      { ambulanceId: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showBulkDeleteAmbulanceConfirmPopup: false,
          disableSingleDeleteButton: false,
        });
        this.callGetAmbulanceListAPI();
      }
    );
  };

  //--------------------------------------------- Stepper View functionality ------------------------------

  handleNext = () => {
    if (
      this.state.activeStep === 0 &&
      !(JSON.stringify(this.state.assignAmbulanceInBulk).length > 2)
    ) {
      this.setState({
        disableNextButton: true,
        activeStep: this.state.activeStep + 1,
      });
    } else {
      this.setState({
        disableNextButton: false,
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handleBack = () => {
    if (
      this.state.activeStep === 2 &&
      !(JSON.stringify(this.state.assignAmbulanceInBulk).length > 2)
    ) {
      this.setState({
        disableNextButton: true,
        activeStep: this.state.activeStep - 1,
      });
    } else {
      this.setState({
        disableNextButton: false,
        activeStep: this.state.activeStep - 1,
      });
    }
  };
  //-------------------------- Data and Pagination ---------------------------------

  populateListData = (listData, count) => {
    this.setState({
      ambulanceList: listData,
      totalAmbulances: count,
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalAmbulances;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetAmbulanceListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetAmbulanceListAPI();
      }
    );
  };

  //------------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetAmbulanceListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetAmbulanceListAPI();
      }
    );
  };

  //---------------- filter and sorting --------------------

  onAmbulanceListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    let filter =
      filterOption === "Active" ? 1 : filterOption === "Inactive" ? 2 : null;
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          ambulanceStatus: parseInt(filter),
        },
      },
      () => {
        this.props.getAmbulanceListWithFilter(
          this.state.filters,
          (listData, totalPages, count) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData, count);
              this.forceUpdate();
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onAmbulanceListSortClick = (column) => {
    let columnName = "";
    if (column === "Ambulance Name") {
      columnName = "ambulanceName";
    } else if (column === "Ambulance Number") {
      columnName = "ambulanceRegistrationNumber";
    } else if (column === "Ambulance Type") {
      columnName = "ambulanceType";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getAmbulanceListWithFilter(
          this.state.filters,
          (listData, totalPages, count) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData, count);
              this.forceUpdate();
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <AmbulanceView
        // For showing Report List Columns as per user role
        userRole={this.state.userRole}
        //
        hospitalList={this.state.hospitalList}
        ambulanceList={this.state.ambulanceList}
        totalAmbulances={this.props.filteredAmbulanceCount}
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        //
        ambulanceLogo={this.state.addNewAmbulancefields.ambulanceLogo}
        viewAmbulanceLogoClicked={this.viewAmbulanceLogoClicked}
        onHandleUploadAmbulanceImage={this.onHandleUploadAmbulanceImage}
        deleteAmbulanceLogoClicked={this.deleteAmbulanceLogoClicked}
        showAmbulanceLogoPopup={this.state.showAmbulanceLogoPopup}
        closeViewAmbulanceLogo={this.closeViewAmbulanceLogo}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        searchStringParam={this.state.searchStringParam}
        //
        showAddBulkAmbulancePopup={this.state.showAddBulkAmbulancePopup}
        onAddBulkAmbulancesClick={this.onAddBulkAmbulancesClick}
        //
        showAddNewAmbulancePopup={this.state.showAddNewAmbulancePopup}
        showEditAmbulancePopup={this.state.showEditAmbulancePopup}
        showDeleteAmbulancePopup={this.state.showDeleteAmbulancePopup}
        onAddNewAmbulancesClick={this.onAddNewAmbulancesClick}
        //
        onBulkAmbulanceDeleteClick={this.onBulkAmbulanceDeleteClick}
        onBulkAmbulanceDeleteConfirmClick={
          this.onBulkAmbulanceDeleteConfirmClick
        }
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        showBulkDeleteAmbulanceConfirmPopup={
          this.state.showBulkDeleteAmbulanceConfirmPopup
        }
        //
        onAddNewAmbulanceConfirmClick={this.onAddNewAmbulanceConfirmClick}
        onAddAmbulanceFieldChange={this.onAddAmbulanceFieldChange}
        onAssignTechnicianChange={this.onAssignTechnicianChange}
        onScrollHospitalList={this.onScrollHospitalList}
        //
        onHospitalSearchChange={this.onHospitalSearchChange}
        onTechnicianSearchChange={this.onTechnicianSearchChange}
        //
        addNewAmbulancefields={this.state.addNewAmbulancefields}
        errors={this.state.errors}
        ambulanceTypeData={this.state.ambulanceTypeData}
        //
        onCloseAddAmbulancePopup={this.onCloseAddAmbulancePopup}
        //
        onReportDeviceClick={this.onReportDeviceClick}
        //
        onEditAmbulanceClick={this.onEditAmbulanceClick}
        onDeleteAmbulanceClick={this.onDeleteAmbulanceClick}
        //
        onDeleteAmbulanceConfirmClick={this.onDeleteAmbulanceConfirmClick}
        //
        showDisableDevicePopup={this.state.showDisableDevicePopup}
        onDisableDeviceClick={this.onDisableDeviceClick}
        //
        searchHospitalText={this.state.searchHospitalText}
        onEditAmbulanceConfirmClick={this.onEditAmbulanceConfirmClick}
        onCloseEditAmbulancePopup={this.onCloseEditAmbulancePopup}
        //
        closeAllPopups={this.closeAllPopups}
        //
        ambulanceInfo={this.state.ambulanceInfo}
        //
        activeStep={this.state.activeStep}
        fileInputRef={this.state.fileInputRef}
        pregressPer={this.state.pregressPer}
        disableNextButton={this.state.disableNextButton}
        fileName={this.state.fileName}
        file={this.state.file}
        assignAmbulanceInBulk={this.state.assignAmbulanceInBulk}
        detailsAssignAmbulanceInBulk={this.state.detailsAssignAmbulanceInBulk}
        //
        onCloseAddBulkAmbulancePopup={this.onCloseAddBulkAmbulancePopup}
        handleFileInputClick={this.handleFileInputClick}
        onAddBulkAmbulanceFieldChange={this.onAddBulkAmbulanceFieldChange}
        addBulkAmbulancesConfirmClick={this.addBulkAmbulancesConfirmClick}
        handleDeleteFile={this.handleDeleteFile}
        //
        handleBack={this.handleBack}
        handleNext={this.handleNext}
        handleFileDropClick={this.handleFileDropClick}
        //
        onUnlockAmbulanceAccountClick={this.onUnlockAmbulanceAccountClick}
        onUnlockAmbulanceAccountConfirmClick={
          this.onUnlockAmbulanceAccountConfirmClick
        }
        showUnlockAmbulanceConfirmationPopup={
          this.state.showUnlockAmbulanceConfirmationPopup
        }
        closeUnlockAmbulancePopup={this.closeUnlockAmbulancePopup}
        //
        AmbulanceCategoryData={this.state.AmbulanceCategoryData}
        ambCategoryType={this.state.ambCategoryType}
        onAmbulanceGovtOrPvtChange={this.onAmbulanceGovtOrPvtChange}
        disableSingleDeleteButton={this.state.disableSingleDeleteButton}
        onAmbulanceListSortClick={this.onAmbulanceListSortClick}
        onAmbulanceListFilterClick={this.onAmbulanceListFilterClick}
      />
    );
  }
}

AmbulancesContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    filteredAmbulanceList: state.ambulance.filteredAmbulanceList,
    filteredAmbulanceCount: state.ambulance.filteredAmbulanceCount,
    filteredHospitalList: state.hospital.filteredHospitalList,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  getHospitalsListWithFilter,
  getAmbulanceListWithFilter,
  getAmbulanceDetailsById,
  deleteAmbulanceInBulk,
  addAmbulanceInBulk,
  deleteAmbulance,
  editAmbulance,
  addAmbulance,
  uploadImage,
  deleteImage,
  unlockUser,
})(AmbulancesContainer);
