import React, { useState } from "react";
import { CardView } from "../../../../controls";
import {
  EcgReportsCardCenterAdmin,
  StatisticsCardCenterAdmin,
  TopDevicesCardCenterAdmin,
  TopTechniciansCardCenterAdmin,
} from "../../Components";
import "../DashboardStyles.scss";

const CenterAdminView = (props) => {
  // console.log("CENTER ADMIN VIEW : ", JSON.stringify(props.totalDevices));

  return (
    <div className="new-dashboard-container">
      <div className="dashboard-info-cards">
        <StatisticsCardCenterAdmin
          loginUser={props.loginUser}
          userCreatedAt={props.userCreatedAt}
          centerName={props.centerName}
          technicians={props.totalTechnicians}
          devices={props.totalDevices}
          sentReports={props.totalReports}
          pendingReports={props.pendingReports}
        />
      </div>

      <div className="dashboard-info-cards">
        <TopTechniciansCardCenterAdmin
          data={props.topTechnicians}
          filtersData={props.topTechniciansFilters}
          filterOptionSelected={props.topTechniciansFilterSelected}
          onFilterOptionChange={props.onTopTechniciansFilterChange}
        />
        <TopDevicesCardCenterAdmin data={props.devicesData} />
      </div>

      <div className="dashboard-info-cards">
        <EcgReportsCardCenterAdmin
          data={props.ecgBarChartData}
          filtersData={props.ecgReportsFilters}
          filterOptionSelected={props.ecgReportsFilterSelected}
          onFilterOptionChange={props.onEcgReportsFilterChange}
          // areaChartLabel={props.areaChartLabel}
        />
      </div>
    </div>
  );
};

export default CenterAdminView;
