import React from "react";
import { CardView } from "../../../../controls";
import { getWelcomeGreetings } from "../../../../../utils/common";
import { getState } from "../../../../../store/configure/configureStore";
import userPlaceholder from "../../../../../assets/images/icons/user-placeholder.png";
import "./CardStyle.scss";

const StatisticsCardDoctor = (props) => {
  var firstName = getState().auth.loginUser?.userProfile?.firstName ?? "";
  var lastName = getState().auth.loginUser?.userProfile?.lastName ?? "";

  firstName = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
  lastName = `${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;

  var userName = firstName + " " + lastName;
  userName = userName.trim() === "" ? "user, " : `Dr. ${userName}, `;

  var firstNameInitial = firstName.charAt(0).toUpperCase();
  var lastNameInitial = lastName.charAt(0).toUpperCase();

  var profilePicUrl = getState().auth.loginUser?.data?.profilePicture ?? null;

  return (
    <CardView
      extraStyle="doctor-statistics-card"
      bodyStyle={{ minHeight: "110px" }}
    >
      <div className="card-container">
        <div className="card-icon-header">
          {profilePicUrl !== null ? (
            <div className="card-image">
              <img
                src={profilePicUrl}
                alt={`${firstNameInitial}${lastNameInitial}`} // When URL is broken / not working
                className="img_preview"
              />
            </div>
          ) : (
            <div className="card-image">
              {`${firstNameInitial}${lastNameInitial}`}
            </div>
          )}

          <div className="card-head">
            <div className="title">
              {getWelcomeGreetings()} {userName} have a great day!
            </div>
            <div className="subtitle">
              Role: Consultant/Doctor | Assigned Since : {props.userCreatedAt}
            </div>
          </div>
        </div>
        <div className="card-info">
          <div className="info">
            <text className="text">
              {props.previousMonthsData[0].name} Reports
            </text>
            <text className="number">{props.previousMonthsData[0].value}</text>
          </div>

          <div className="vertical"></div>

          <div className="info">
            <text className="text">
              {props.previousMonthsData[1].name} Reports
            </text>
            <text className="number">{props.previousMonthsData[1].value}</text>
          </div>

          <div className="vertical"></div>

          <div className="info">
            <text className="text">
              {props.previousMonthsData[2].name} Reports
            </text>
            <text className="number">{props.previousMonthsData[2].value}</text>
          </div>
        </div>
      </div>
    </CardView>
  );
};
export default StatisticsCardDoctor;
