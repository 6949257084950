import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  getHospitalAdminsList,
  getHospitalAdminsDataFromId,
  setSelectedHospitalAdmin,
  resetSelectedHospitalAdmin,
} from "../../../../../store/administrators/adminActions";
import { history } from "../../../../../store/configure/configureStore";
import AdminsHospitalsView from "./AdminsHospitalsView";

class AdminsHospitalsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Accordian Data
      selectedAdmin: this.props.selectedHospitalAdmin,
      hospitalList: [],

      // previous page's state
      prevState: {},
    };

    this.onAdminNameBackClick = this.onAdminNameBackClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    // console.log("ADMIN HOSPITALS : ", JSON.stringify(this.props.selectedHospitalAdmin));
    var selectedAdmin = this.props.selectedHospitalAdmin ?? null;
    var previousState = this.props.location?.state ?? {};
    console.log("Hopsitals previousState ::: ", JSON.stringify(previousState));

    this.setState({ selectedAdmin: selectedAdmin, prevState: previousState });

    // Fetch hospitals list and save it in state variable
    this.props.getHospitalAdminsDataFromId(selectedAdmin?.id, (data) => {
      this.setState({ hospitalList: data.hospitalList });
    });
  }

  componentWillUnmount() {}

  onAdminNameBackClick = () => {
    // Reset selected Hospital in state vaiable and redux
    this.props.resetSelectedHospitalAdmin();

    // Send previous page's state for updating pagination on that screen
    history.push({
      pathname: "/administrators",
      state: this.state.prevState,
    });
  };

  render() {
    return (
      <AdminsHospitalsView
        //------------------ Accordian -----------------
        selectedAdmin={this.state.selectedAdmin}
        hospitalList={this.state.hospitalList}
        //------------------ Admin click --------------
        onAdminNameBackClick={this.onAdminNameBackClick}
      />
    );
  }
}

AdminsHospitalsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    selectedHospitalAdmin: state.administrators.selectedHospitalAdmin,
  };
};

export default connect(mapStateToProps, {
  getHospitalAdminsList,
  getHospitalAdminsDataFromId,
  setSelectedHospitalAdmin,
  resetSelectedHospitalAdmin,
})(AdminsHospitalsContainer);
