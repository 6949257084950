import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./RadioButtonGroupStyles.scss";
import RadioButton from "../Radio/RadioButton";

const RadioButtonGroup = ({
  data,
  value = null,
  name = "",
  onItemSelected,
  size,
  color,
  error,
  disabled = false,
  ...props
}) => {
  useEffect(() => {
    setselectValue(value);
  }, [value]);

  const [selectValue, setselectValue] = useState(value);

  const onSelected = (e) => {
    const selectedItem = data.filter((item) => item.label === e.target.value);
    onItemSelected(selectedItem[0]);
    setselectValue(selectedItem[0]);
  };

  return (
    <div className="radio-button-container">
      {data.map((item) => {
        return (
          <RadioButton
            size={size}
            disabled={disabled}
            color={color}
            name={name}
            checked={selectValue?.label === item.label}
            onChange={onSelected}
            text={item.label}
          />
        );
      })}
      <div className={`button-group-error`}>{error ? error : ""}</div>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  onItemSelected: PropTypes.func,
};

export default RadioButtonGroup;
