import React, { useEffect, useRef, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { showAlert } from "../../../store/alert/alertActions";
import { getStore } from "../../../store/configure/configureStore";
import "./FileUploaderStyles.scss";
import { AiOutlineDelete } from "react-icons/ai";

const FileUploader = ({
  uploadFilesHandle,
  disabled = false,
  documents = [],
}) => {
  const fileInputRef = useRef(null);
  const [files, setFile] = useState(documents);

  useEffect(() => {
    if (files?.length > 0) {
      uploadFilesHandle(files);
    }
  }, [files]);

  const handleImageChange = (e) => {
    // new files
    const newFiles = Array.from(e.target.files);
    // extract pre-uploaded files name
    const existingFileNames = files.map((file) => file.name);
    // remove dublicate files
    const uniqueFiles = newFiles.filter(
      (newFile) => !existingFileNames.includes(newFile.name)
    );
    console.log("uniqueFiles", uniqueFiles);
    // for store valid files only
    const validFiles = [];
    let invalidFileCount = 0;
    const maxSize = 5 * 1024 * 1024;
    for (let i = 0; i < uniqueFiles?.length; i++) {
      const filetag = uniqueFiles[i]?.type.split("/");
      if (
        filetag?.length > 0 &&
        (filetag[1] == "jpg" ||
          filetag[1] == "jpeg" ||
          filetag[1] == "png" ||
          filetag[1] == "doc" ||
          filetag[1] == "docx" ||
          filetag[1] == "pdf" ||
          filetag[1] == "xls" ||
          filetag[1] == "xlsx")
      ) {
        if (uniqueFiles[i]?.size < maxSize) {
          validFiles.push(uniqueFiles[i]);
        } else {
          invalidFileCount++;
        }
      } else {
        invalidFileCount++;
      }
    }
    if (invalidFileCount > 0) {
      getStore().dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: `There was an error uploading ${invalidFileCount} files. Please check that the file format is supported and that each file is within the size limit.`,
          showError: true,
        })
      );
    }
    setFile([...files, ...validFiles]);
  };

  const handleDeleteFile = (index) => {
    const newSelectedFiles = [...files];
    newSelectedFiles.splice(index, 1);
    setFile(newSelectedFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="file_uploader_outer_container">
      <div className="file_uploader_view">
        <div className="file_uploader" onClick={handleButtonClick}>
          <input
            type="file"
            disabled={disabled}
            multiple
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <div
            className={`add_view ${disabled ? "disable-bg" : ""}`}
            onChange={handleImageChange}
          >
            {<BsPlusLg size={15} color="#222" />}
            <text className="mt-2">Upload</text>
          </div>
        </div>
        <div className="docs-info-view">
          <text>
            <b>File Upload Guidelines : </b>
          </text>
          <text>
            Only JPEG, JPG, PNG, DOC, PDF, and XLS file formats allowed.
          </text>
          <text>Maximum 5MB size allowed.</text>
        </div>
      </div>
      <div className="uploeded-file-list">
        {files.length > 0 ? (
          <ul className="files">
            {files.map((item, i) => {
              const fileName =
                item.name.length > 28
                  ? `${item.name.split(".")[0].substring(0, 28)}... .${
                      item.name.split(".")[1]
                    }`
                  : item.name;
              return (
                <div className="list-file">
                  <li>{fileName}</li>
                  <div id="delete-icon">
                    <AiOutlineDelete
                      size={20}
                      cursor={"pointer"}
                      color="
                      rgba(255, 77, 79, 0.7)"
                      onClick={() => handleDeleteFile(i)}
                    />
                  </div>
                </div>
              );
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

FileUploader.propTypes = {};

export default FileUploader;
