import React from "react";
import { bindActionCreators } from "redux";
import ReactLoading from "react-loading";
import connect from "react-redux/es/connect/connect";
import "./BigLoaderStyles.scss";

const BigLoader = ({ isOpen }) => {
  return (
    <>
      {isOpen ? (
        <div className="big_loader">
          <ReactLoading type="spin" color="#00b48a" height={60} width={60} />
          <text className="loading_text ">Loading...</text>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.loader.isOpen,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BigLoader);
