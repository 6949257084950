export const actionTypes = {
  GET_DOCTORS_PENDING: "GET_DOCTORS_PENDING",
  GET_DOCTORS_SUCCESS: "GET_DOCTORS_SUCCESS",
  GET_DOCTORS_ERROR: "GET_DOCTORS_ERROR",

  GET_DOCTORS_LIST_WITH_FILTER_PENDING: "GET_DOCTORS_LIST_WITH_FILTER_PENDING",
  GET_DOCTORS_LIST_WITH_FILTER_SUCCESS: "GET_DOCTORS_LIST_WITH_FILTER_SUCCESS",
  GET_DOCTORS_LIST_WITH_FILTER_ERROR: "GET_DOCTORS_LIST_WITH_FILTER_ERROR",

  ADD_NEW_DOCTOR_PENDING: "ADD_NEW_DOCTOR_PENDING",
  ADD_NEW_DOCTOR_SUCCESS: "ADD_NEW_DOCTOR_SUCCESS",
  ADD_NEW_DOCTOR_ERROR: "ADD_NEW_DOCTOR_ERROR",

  GET_DOCTOR_ID_PENDING: "GET_DOCTOR_ID_PENDING",
  GET_DOCTOR_BY_ID_SUCCESS: "GET_DOCTOR_BY_ID_SUCCESS",
  GET_DOCTOR_BY_ID_ERROR: "GET_DOCTOR_BY_ID_ERROR",

  EDIT_DOCTOR_PENDING: "EDIT_DOCTOR_PENDING",
  EDIT_DOCTOR_SUCCESS: "EDIT_DOCTOR_SUCCESS",
  EDIT_DOCTOR_ERROR: "EDIT_DOCTOR_ERROR",

  DELETE_DOCTOR_PENDING: "DELETE_DOCTOR_PENDING",
  DELETE_DOCTOR_SUCCESS: "DELETE_DOCTOR_SUCCESS",
  DELETE_DOCTOR_ERROR: "DELETE_DOCTOR_ERROR",

  DELETE_DOCTOR_IN_BULK_PENDING: "DELETE_DOCTOR_IN_BULK_PENDING",
  DELETE_DOCTOR_IN_BULK_SUCCESS: "DELETE_DOCTOR_IN_BULK_SUCCESS",
  DELETE_DOCTOR_IN_BULK_ERROR: "DELETE_DOCTOR_IN_BULK_ERROR",

  RESET_GET_DOCTOR_ID_PENDING: "RESET_GET_DOCTOR_ID_PENDING",
};

const initialState = {
  getHospitalsPending: false,
  getHospitalsSuccess: false,
  getHospitalsError: null,

  getdoctorsListWithFilterPending: false,
  getdoctorsListWithFilterSuccess: false,
  getdoctorsListWithFilterError: null,

  addNewDoctorPending: false,
  addNewDoctorSuccess: false,
  addNewDoctorError: null,

  getDoctorByIdPending: false,
  getDoctorByIdSuccess: false,
  getDoctorByIdError: null,

  editDoctorPending: false,
  editDoctorSuccess: false,
  editDoctorError: null,

  deleteDoctorPending: false,
  deleteDoctorSuccess: false,
  deleteDoctorError: null,

  deleteDoctorInBulkPending: false,
  deleteDoctorInBulkSuccess: false,
  deleteDoctorInBulkError: null,

  doctorDataById: null,

  doctorsList: [],
  filteredDoctorsList: [],
  filteredDoctorcount: 0,
};

export default function doctor(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_DOCTORS_PENDING:
    case actionTypes.GET_DOCTORS_SUCCESS:
    case actionTypes.GET_DOCTORS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DOCTORS_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_DOCTORS_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_DOCTORS_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_NEW_DOCTOR_PENDING:
    case actionTypes.ADD_NEW_DOCTOR_SUCCESS:
    case actionTypes.ADD_NEW_DOCTOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DOCTOR_ID_PENDING:
    case actionTypes.GET_DOCTOR_BY_ID_SUCCESS:
    case actionTypes.GET_DOCTOR_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_DOCTOR_PENDING:
    case actionTypes.EDIT_DOCTOR_SUCCESS:
    case actionTypes.EDIT_DOCTOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_DOCTOR_PENDING:
    case actionTypes.DELETE_DOCTOR_SUCCESS:
    case actionTypes.DELETE_DOCTOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_DOCTOR_IN_BULK_PENDING:
    case actionTypes.DELETE_DOCTOR_IN_BULK_SUCCESS:
    case actionTypes.DELETE_DOCTOR_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_GET_DOCTOR_ID_PENDING:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
