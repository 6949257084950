import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import { useEffect } from "react";

export default function NumberInputBasic({
  inputWidth = "50px",
  minLimit = 0.5,
  maxLimit = 2,
  defaultValue = 1,
  stepsIncrement = 0.5,
  setGain,
}) {
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInputElement,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        min={minLimit}
        max={maxLimit}
        step={stepsIncrement}
        value={value}
        slotProps={{
          incrementButton: {
            children: "▴",
          },
          decrementButton: {
            children: "▾",
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });

  return (
    <NumberInput
      aria-label="Demo number input"
      value={value}
      onChange={(event, val) => {
        if (val) {
          setValue(val);
          setGain(val);
        }
      }}
    />
  );
}

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  height:32px;
  font-family: 'Roboto','IBM Plex Sans', sans-serif;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 4px;

  &.${numberInputClasses.focused} {
    border-color: #33c3a1;
    box-shadow: 0 0 2px 4px  rgba(0, 144, 110, 0.1);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  width:48px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  background: inherit;
  border: none;
  border-radius: none;
  padding: 6px 10px;
  outline: 0;
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  font-family: Roboto ,system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 0;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    // background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    // border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    // cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
    }

  border-color:rgba(217, 217, 217, 1);
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border: 1px solid;
    &:hover {
      cursor: pointer;
    }

  border-color:rgba(217, 217, 217, 1);
  }
  & .arrow {
    transform: translateY(-1px);
  }
`
);
