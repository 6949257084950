import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { getState } from "../configure/configureStore";
import { globals } from "../globals";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./doctorReducer";

export function getDoctorsListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = "",
  order = ""
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DOCTORS_LIST_WITH_FILTER_PENDING,
      payload: {
        getdoctorsListWithFilterPending: false,
        getdoctorsListWithFilterSuccess: false,
        getdoctorsListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `doctor/doctorList?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.totalPages ?? 0);
        }

        dispatch(hideLoader());
        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_DOCTORS_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getdoctorsListWithFilterPending: false,
              getdoctorsListWithFilterSuccess: true,
              getdoctorsListWithFilterError: null,
              doctorsList: response?.data?.rows ?? [],
              filteredDoctorsList: response?.data?.rows ?? [],
              filteredDoctorCount: response?.data?.count ?? 0,
              // filterdDoctorsList: [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_DOCTORS_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getdoctorsListWithFilterPending: false,
              getdoctorsListWithFilterSuccess: true,
              getdoctorsListWithFilterError: null,
              filteredDoctorsList: response?.data?.rows ?? [],
              filteredDoctorCount: response?.data?.count ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_DOCTORS_LIST_WITH_FILTER_ERROR,
          payload: {
            getdoctorsListWithFilterPending: false,
            getdoctorsListWithFilterSuccess: true,
            getdoctorsListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// -----------------------Add new doctor---------------

export function addNewDoctor(data, callback) {
  const data1 = getState().auth.loginUser;
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_DOCTOR_PENDING,
      payload: {
        addNewDoctorPending: false,
        addNewDoctorSuccess: false,
        addNewDoctorError: null,
      },
    });

    const newData = {
      email: data?.email,
      password: data?.password,
      firstName: data?.firstName,
      contactNumber: data?.mobile,
      lastName: data?.lastName,
      qualification: data?.qualification,

      registrationId: data?.msci_id,
      // status: 1,
      hospitalId: data?.hospitals?.value,
    };

    dispatch(showLoader());

    Api.post(`doctor/add`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.ADD_NEW_DOCTOR_SUCCESS,
          payload: {
            addNewDoctorPending: false,
            addNewDoctorSuccess: true,
            addNewDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })

      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(err ?? {});
        }
        dispatch({
          type: actionTypes.ADD_NEW_DOCTOR_ERROR,
          payload: {
            addNewDoctorPending: false,
            addNewDoctorSuccess: true,
            addNewDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// ----------------------- get doctor by id-----------------

export function getDoctorById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DOCTOR_ID_PENDING,
      payload: {
        getDoctorByIdPending: false,
        getDoctorByIdSuccess: false,
        getDoctorByIdError: null,
      },
    });

    dispatch(showLoader());

    Api.get(`doctor/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.GET_DOCTOR_BY_ID_SUCCESS,
          payload: {
            getDoctorByIdPending: false,
            getDoctorByIdSuccess: true,
            getDoctorByIdError: null,
            doctorDataById: response?.data ?? {},
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.GET_DOCTOR_BY_ID_ERROR,
          payload: {
            getDoctorByIdPending: false,
            getDoctorByIdSuccess: true,
            getDoctorByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------------edit Doctor --------------------

export function editDoctor(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_DOCTOR_PENDING,
      payload: {
        editDoctorPending: false,
        editDoctorSuccess: false,
        editDoctorError: null,
      },
    });
    dispatch(showLoader());

    const newData = {
      email: data?.email,
      password: data?.password,
      firstName: data?.firstName,
      contactNumber: data?.mobile,
      lastName: data?.lastName,
      qualification: data?.qualification,

      registrationId: data?.msci_id,
      // status: 1,
      hospitalId: data?.hospitals?.value,
    };

    Api.post(`doctor/update/${id}`, newData)
      .then((response) => {
        dispatch(hideLoader());
        console.log("response", response);
        if (typeof callback === "function") {
          callback(response ?? {});
        }

        dispatch({
          type: actionTypes.EDIT_DOCTOR_SUCCESS,
          payload: {
            editDoctorPending: false,
            editDoctorSuccess: true,
            editDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.EDIT_DOCTOR_ERROR,
          payload: {
            editDoctorPending: false,
            editDoctorSuccess: true,
            editDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function deleteDoctor(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DOCTOR_PENDING,
      payload: {
        deleteDoctorPending: false,
        deleteDoctorSuccess: false,
        deleteDoctorError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`doctor/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DOCTOR_SUCCESS,
          payload: {
            deleteDoctorPending: false,
            deleteDoctorSuccess: true,
            deleteDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_DOCTOR_ERROR,
          payload: {
            deleteDoctorPending: false,
            deleteDoctorSuccess: true,
            deleteDoctorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- bulk doctor delete ------------------------------------

export function deleteDoctorInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DOCTOR_IN_BULK_PENDING,
      payload: {
        deleteDoctorInBulkPending: false,
        deleteDoctorInBulkSuccess: false,
        deleteDoctorInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`doctor/delete/multiple/doctors`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DOCTOR_IN_BULK_SUCCESS,
          payload: {
            deleteDoctorInBulkPending: false,
            deleteDoctorInBulkSuccess: true,
            deleteDoctorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_DOCTOR_IN_BULK_ERROR,
          payload: {
            deleteDoctorInBulkPending: false,
            deleteDoctorInBulkSuccess: true,
            deleteDoctorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// ----------------------- reset get doctor by id-----------------

export function resetGetDoctorById() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_GET_DOCTOR_ID_PENDING,
      payload: {
        // resetGetDoctorByIdPending: false,
        // resetGetDoctorByIdSuccess: false,
        // resetGetDoctorByIdError: null,
        doctorDataById: null,
      },
    });
  };
}
