import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./supervisorReducer";

/* ------------------- Get Supervisor List ----------------------- */

export function getSupervisorList(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SUPERVISOR_LIST_PENDING,
      payload: {
        getSupervisorListPending: true,
        getSupervisorListSuccess: false,
        getSupervisorListError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `supervisor/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(
            response?.data?.rows ?? [],
            response?.data?.count ?? 0,
            response?.data?.totalPages ?? 0
          );
        }

        dispatch({
          type: actionTypes.GET_SUPERVISOR_LIST_SUCCESS,
          payload: {
            getSupervisorListPending: false,
            getSupervisorListSuccess: true,
            getSupervisorListError: null,
            supervisorsList: response?.data?.rows ?? [],
            filteredSupervisorCount: response?.data?.count,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_SUPERVISOR_LIST_ERROR,
          payload: {
            getSupervisorListPending: false,
            getSupervisorListSuccess: true,
            getSupervisorListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* ------------------- Get Supervisor Data from Id ----------------------- */

export function getSupervisorDataById(id, callback) {
  console.log("getHospitalAdminsDataFromId ----->", id);

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SUPERVISOR_INFO_BY_ID_PENDING,
      payload: {
        getSupervisorInfoByIdPending: false,
        getSupervisorInfoByIdSuccess: false,
        getSupervisorInfoByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`supervisor/${id}`)
      .then((response) => {
        console.log("response1", response);
        dispatch(hideLoader());

        // hospitals list and metadata is sent back
        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_SUPERVISOR_INFO_BY_ID_SUCCESS,
          payload: {
            getSupervisorInfoByIdPending: false,
            getSupervisorInfoByIdSuccess: true,
            getSupervisorInfoByIdError: null,
            selectedHospitalAdmin: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.GET_SUPERVISOR_INFO_BY_ID_ERROR,
          payload: {
            getSupervisorInfoByIdPending: false,
            getSupervisorInfoByIdSuccess: true,
            getSupervisorInfoByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------    Add New Supervisor   ----------------------------*/

export function addNewSupervisor(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_SUPERVISOR_PENDING,
      payload: {
        addSupervisorPending: true,
        addSupervisorSuccess: false,
        addSupervisorError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`supervisor/add`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.ADD_SUPERVISOR_SUCCESS,
          payload: {
            addSupervisorPending: false,
            addSupervisorSuccess: true,
            addSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.ADD_SUPERVISOR_ERROR,
          payload: {
            addSupervisorPending: false,
            addSupervisorSuccess: true,
            addSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------     Edit Supervisor --------------------------------*/

export function editSupervisor(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_SUPERVISOR_PENDING,
      payload: {
        editSupervisorPending: true,
        editSupervisorSuccess: false,
        editSupervisorError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`supervisor/update/${id}`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_SUPERVISOR_SUCCESS,
          payload: {
            editSupervisorPending: false,
            editSupervisorSuccess: true,
            editSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch({
          type: actionTypes.EDIT_SUPERVISOR_ERROR,
          payload: {
            editSupervisorPending: false,
            editSupervisorSuccess: true,
            editSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* --------------------Delete Supervisor -----------------------------*/

export function deleteSupervisor(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SUPERVISOR_PENDING,
      payload: {
        deleteSupervisorPending: true,
        deletesSupervisorSuccess: false,
        deleteSupervisorError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`supervisor/delete/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_SUPERVISOR_SUCCESS,
          payload: {
            deleteSupervisorPending: false,
            deletesSupervisorSuccess: true,
            deleteSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_SUPERVISOR_ERROR,
          payload: {
            deleteSupervisorPending: false,
            deletesSupervisorSuccess: true,
            deleteSupervisorError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- bulk Supervisor delete ----------------------------/

export function deleteSupervisorInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SUPERVISOR_IN_BULK_PENDING,
      payload: {
        deleteSupervisorInBulkPending: false,
        deleteSupervisorInBulkSuccess: false,
        deleteSupervisorInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`hospitalAdmin/delete/multiple/hospitalAdmin`, data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_SUPERVISOR_IN_BULK_SUCCESS,
          payload: {
            deleteSupervisorInBulkPending: false,
            deleteSupervisorInBulkSuccess: true,
            deleteSupervisorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.DELETE_SUPERVISOR_IN_BULK_ERROR,
          payload: {
            deleteSupervisorInBulkPending: false,
            deleteSupervisorInBulkSuccess: true,
            deleteSupervisorInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

/* -------------------  Supervisor Clicked -------------------- */

export function setSelectedSupervisor(supervisorData) {
  // console.log("setSelectedHospitalAdmin ----->", adminData.id);

  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_SUPERVISOR,
      payload: {
        selectedSupervisor: supervisorData,
      },
    });
  };
}

export function resetSelectedSupervisor() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SELECTED_SUPERVISOR,
      payload: {
        selectedSupervisor: null,
      },
    });
  };
}
