export const globalConstants = {
  USER_PROFILE_IMAGE: 1,
  DOCTOR_SIGNATURE_IMAGE: 2,
  HOSPITAL_LOGO_IMAGE: 3,
  HOSPITAL_FRANCHISE_IMAGE: 4,
  VEHICLE_IMAGE: 5,
  AMBULANCE_IMAGE: 6,
  INSURANCE_CARD_IMAGE: 7,

  // STEMI Case Status
  CASE_STATUS_CREATED: 1,
  CASE_STATUS_PATIENT_DATA_ADDED: 2,
  CASE_STATUS_HOSPITAL_ASSIGNED: 3,
  CASE_STATUS_AMBULANCE_ASSIGNED: 4,
  CASE_STATUS_FORWARDED: 5,
  CASE_STATUS_ADMITTED: 6,
  CASE_STATUS_CLOSED: 7,
  CASE_STATUS_AWAITING_TRANSFER: 8,

  // Ride Status
  RIDE_NEW: 1,
  RIDE_ACCEPTED: 2,
  RIDE_REJECTED: 3,
  RIDE_STARTED: 4,
  RIDE_ENDED: 5,

  // TRANSFER ENTRY STATUS
  TRANSFER_INITIATED: 1,
  TRANSFER_PATIENT_DATA_ADDED: 2,
  TRANSFER_HOSP_ASSIGNED: 3,
  TRANSFER_AMB_ASSIGNED: 4,
  TRANSFER_DETAILS_CONFIRM: 5,
  TRANSFER_ACCEPTED_BY_DEST: 6,
  TRANSFER_REJECTED_BY_DEST: 7,

  // Hospital admin case view
  CASE_UI_FOR_IN_TRANSIT: 1,
  CASE_UI_FOR_IN_TRANSIT_TRANSFER: 2,
  CASE_UI_FOR_ADMIT: 3,
  CASE_UI_FOR_CLOSED: 4,
  CASE_UI_FOR_TRANSFERRED: 5,
  CASE_UI_FOR_ARRIVED: 6,

  // hospital activity status
  HOSP_ACTIVITY_STATUS_ASSIGNED: 1,
  HOSP_ACTIVITY_STATUS_ACCEPTED: 2,
  HOSP_ACTIVITY_STATUS_REJECTED: 3,
  HOSP_ACTIVITY_STATUS_ADMITTED: 4,
  HOSP_ACTIVITY_STATUS_TRANSFERRED: 5,
  HOSP_ACTIVITY_STATUS_CLOSED: 6,

  // case notes type
  GENERIC_NOTE: 1,
  ADMISSION_NOTE: 2,
  TRANSFER_NOTE: 3,
  CLOSING_NOTE: 4,

  CRYPTO_JS_KEY: "metsl@123",
  REMEMBER_ME_KEY: "REMEMBER_ME",
  LOGIN_DATA_KEY: "LOGIN_DATA",
};
