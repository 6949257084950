import React from "react";
import { DataTable } from "../../../../common";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import "./ListStyles.scss";
import { FaSort } from "react-icons/fa6";
import moment from "moment";
import {
  AiFillFilter,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineRedo,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

const DoctorsList = (props) => {
  var unLockFlag = true;

  const data = props.doctorsList;

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onDoctorListSortClick !== null
                  ? () => props.onDoctorListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const Active = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const Inactive = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const Occupied = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#1890FF",
    };
    const Options = [
      { label: "Active", icon: <div style={Active} /> },
      { label: "Inactive", icon: <div style={Inactive} /> },
      { label: "Occupied", icon: <div style={Occupied} /> },
    ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              columnName={label}
              showSelectedItem={true}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onDoctorListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true, center = false) => {
    return (
      <div className={`custom-column-header ${center ? "header-text" : ""}`}>
        <text>{label}</text>
        <div className="sortIcon">
          {/*  No sort icon  */}
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderEditDeleteResetPasswordAction = (row) => {
    const Options = [
      { label: "Edit", icon: <AiOutlineEdit size={18} /> },
      { label: "Delete", icon: <AiOutlineDelete size={18} /> },
      { label: "Reset Password", icon: <AiOutlineRedo size={18} /> },
    ];
    return (
      <IconMenuOption
        row={row}
        options={Options}
        menuIcon={<BsThreeDotsVertical size={22} />}
        onclickEdit={props.onEditDoctorClick}
        onclickDelete={props.onDeleteDoctorClick}
        onclickResetPassword={props.onResetPasswordDoctorClick}
      />
    );
  };

  const renderUnlockButton = (row) => {
    return (
      <div>
        <ThemedButton
          title="Unlock"
          styles="link "
          onClick={
            props.onUnlockDoctorAccountClick !== null
              ? () => props.onUnlockDoctorAccountClick(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const renderDoctorStatus = (status) => {
    const Active = { backgroundColor: "#52C41A" };
    const Inactive = { backgroundColor: "#D9D9D9" };
    const Occupied = { backgroundColor: "#1890FF" };
    const Locked = { backgroundColor: "#FF4D4F" };

    let statusStyle;
    if (status === "Active") {
      statusStyle = Active;
    }
    if (status === "Offline") {
      status = "Inactive";
      statusStyle = Inactive;
    }
    if (status === "Occupied") {
      status = "Occupied";
      statusStyle = Occupied;
    }
    if (status === "Locked") {
      statusStyle = Locked;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text>{status}</text>
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  //-----------------------------All Column objects-----------------

  const columns = [
    {
      name: renderHeader("Doctor Name", false, true),
      id: "Doctor_Name",
      center: false,
      sortable: false,
      selector: (row) =>
        row !== null && row !== undefined
          ? `Dr. ${
              (row?.firstName !== null && row?.firstName !== undefined
                ? `${row?.firstName
                    .charAt(0)
                    .toUpperCase()}${row?.firstName.slice(1)}`
                : "") +
              " " +
              (row?.lastName !== null && row?.lastName !== undefined
                ? `${row?.lastName
                    .charAt(0)
                    .toUpperCase()}${row?.lastName.slice(1)}`
                : "")
            }`
          : "-",
    },
    {
      name: renderHeader("Qualification", false, false),
      id: "Qualification",
      center: false,
      sortable: false,
      selector: (row) => (row.qualification ? row.qualification : "-"),
    },
    {
      name: renderHeader("MSCI ID", false, true),
      id: "MSCI_ID",
      center: false,
      sortable: false,
      selector: (row) => (row.registrationId ? row.registrationId : "-"),
    },
    {
      name: renderHeader("Added On", false, true),
      id: "Added_On",
      center: false,
      sortable: false,
      selector: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
          : "-",
    },
    {
      name: renderStatusHeader("Status"),
      id: "Status",
      center: false,
      sortable: false,
      selector: (row) =>
        renderDoctorStatus(
          row?.user?.isLocked ? "Locked" : row.statusInfo.status
        ),
    },
    {
      name: renderActionHeader("Action", true, true),
      id: "Action",
      center: true,
      sortable: false,
      selector: (row) =>
        row?.user?.isLocked
          ? renderUnlockButton(row)
          : renderEditDeleteResetPasswordAction(row),
    },
  ];

  return (
    <div className="doctors-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          className="data-table"
          columns={columns}
          data={data}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          clearSelectedRows={props.clearSelectedRows}
          paginationRowsPerPageOptions={[10, 20]}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows)
          }
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalDoctors}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default DoctorsList;
