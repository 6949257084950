import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import Nav from "react-bootstrap/Nav";

import "./OtherComponentStyles.scss";

const AccordionList = ({ userRole, dataList, type, onListItemSelected }) => {
  //

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);

  // console.log("ACCORDIAN VIEW : dataList : ", JSON.stringify(dataList));

  //--------------------- Accordian Customization --------------------------------------

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      // console.log("totally custom!")
    });

    return (
      <button
        type="button"
        className="custom-accordion-button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleAccordionSelect = (index) => {
    // onListItemSelected(index); //Not handling anything on parent screen now!
    setActiveAccordionKey(index);
  };

  //---------------------- Accordion render -------------------------------------

  const renderAccordion = () => {
    return dataList.map((item, index) => (
      <Accordion.Item
        className="accordion-item"
        eventKey={index}
        onClick={() => {
          console.log(item.id);
        }}
      >
        {renderAccordionHeader(item, index)}
        <Accordion.Body>
          {renderDeviceListHeader()}
          {renderDeviceList(item, index)}
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  const renderAccordionForDoctor = () => {
    const isExpanded = activeAccordionKey === 0;
    const Icon = isExpanded ? FaAngleDown : FaAngleRight;
    return (
      <Accordion.Item eventKey={0} className="accordion-item">
        <h2 className="accordion-header">
          <CustomToggle eventKey={0}>
            <div className="title-section">
              <span className="arrow-icon">{<Icon />}</span>
              <text className="title">Doctors</text>
            </div>
            <div className="count-section">
              <text claðssName="text-count">
                Total Doctors: {dataList.length}
              </text>
            </div>
          </CustomToggle>
        </h2>
        <Accordion.Body>{renderDoctorList()}</Accordion.Body>
      </Accordion.Item>
    );
  };

  //--------------------- Header & Child render --------------------------------

  const renderAccordionHeader = (item, index) => {
    console.log("index", index);
    const isExpanded = activeAccordionKey === index;
    const Icon = isExpanded ? FaAngleDown : FaAngleRight;

    return (
      <h2 className="accordion-header">
        <CustomToggle eventKey={index}>
          <div className="title-section">
            <span className="arrow-icon">
              <Icon />
            </span>
            <text className="title">{item.title}</text>
          </div>
          <div className="count-section">
            <text claðssName="text-count">
              Total Devices: {item.deviceCount}
            </text>
          </div>
        </CustomToggle>
      </h2>
    );
  };

  const renderDeviceListHeader = () => {
    return (
      <div className="list-header">
        <text>Devices</text>
      </div>
    );
  };

  const renderDeviceList = (item, itemIndex) => {
    // console.log("Devices List :::::: ", JSON.stringify(item.devicesList));

    var deviceList = item.devicesList ?? [];

    const nameLabel = deviceList.map((item, index) => (
      <Col key={item.id} xs={3} sm={3} md={3} lg={3} className="border">
        <div className="list-wrapper">
          <div className="list-index"> {parseInt(index) + 1}</div>
          <div className="list-item"> {item.dI?.serialNo ?? "-"}</div>
        </div>
      </Col>
    ));

    if (deviceList.length > 0) {
      return (
        <div className="name-details-card-wrapper">
          <Container>
            <Row>{nameLabel}</Row>
          </Container>
        </div>
      );
    } else
      return <text className="no-data-placeholder">No devices available.</text>;
  };
  const renderDoctorList = () => {
    // console.log("Devices List :::::: ", JSON.stringify(item.devicesList));

    const doctorData = dataList;

    const nameLabel = doctorData.map((item, index) => (
      <Col key={item.id} xs={3} sm={3} md={3} lg={3} className="border">
        <div className="list-wrapper">
          <div className="list-index"> {parseInt(index) + 1}</div>
          <div className="list-item">
            {`Dr. ${
              (item?.firstName !== null && item?.firstName !== undefined
                ? `${item?.firstName
                    .charAt(0)
                    .toUpperCase()}${item?.firstName.slice(1)}`
                : "") +
              " " +
              (item?.lastName !== null && item?.lastName !== undefined
                ? `${item?.lastName
                    .charAt(0)
                    .toUpperCase()}${item?.lastName.slice(1)}`
                : "")
            }`}
          </div>
        </div>
      </Col>
    ));

    if (doctorData.length > 0) {
      return (
        <div className="name-details-card-wrapper">
          <Container>
            <Row>{nameLabel}</Row>
          </Container>
        </div>
      );
    } else
      return <text className="no-data-placeholder">No devices available.</text>;
  };

  return (
    <div className="accordion-list">
      <Accordion
        defaultActiveKey="0"
        activeKey={activeAccordionKey}
        onSelect={handleAccordionSelect}
      >
        {type === "device" ? renderAccordion() : renderAccordionForDoctor()}
      </Accordion>
    </div>
  );
};

export default AccordionList;
