import React from "react";
import { CardView, SearchView, ThemedButton } from "../../../controls";
import StemiCasesList from "../Components/Lists/StemiCaseList";
import { PopupDialog } from "../Components";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { globalConstants } from "../../../../constants/globalConstants";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import "./CasesStyles.scss";

const CasesView = (props) => {
  const renderStatus = () => {
    const row = props.caseInfo ?? {};
    var UIType = row.caseUIType;

    var status = "-";
    var statusStyle = null;

    const Closed = { backgroundColor: "#ff4d4f" };
    const Admit = { backgroundColor: "#52C41A" };
    const Transferred = { backgroundColor: "rgba(255, 122, 69, 1)" };
    const InTransit = { backgroundColor: "#1890FF" };
    const Arrived = { backgroundColor: "#87D068" };

    if (UIType === globalConstants.CASE_UI_FOR_CLOSED) {
      status = "Closed";
      statusStyle = Closed;
    }
    if (UIType === globalConstants.CASE_UI_FOR_ADMIT) {
      status = "Admit";
      statusStyle = Admit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_TRANSFERRED) {
      status = "Transferred";
      statusStyle = Transferred;
    }
    if (UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT) {
      status = "In Transit";
      statusStyle = InTransit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER) {
      status = "In Transit (Transfer)";
      statusStyle = InTransit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_ARRIVED) {
      status = "Arrived";
      statusStyle = Arrived;
    }

    return (
      <div className="status-wrapper" style={statusStyle}>
        <text>{status}</text>
      </div>
    );
  };

  const renderViewCasePopupContents = () => {
    const patientName =
      props.caseInfo !== null && props.caseInfo !== undefined
        ? `${
            (props.caseInfo?.firstName !== null &&
            props.caseInfo?.firstName !== undefined
              ? `${props.caseInfo?.firstName
                  .charAt(0)
                  .toUpperCase()}${props.caseInfo?.firstName.slice(1)}`
              : "") +
            " " +
            (props.caseInfo?.lastName !== null &&
            props.caseInfo?.lastName !== undefined
              ? `${props.caseInfo?.lastName
                  .charAt(0)
                  .toUpperCase()}${props.caseInfo?.lastName.slice(1)}`
              : "")
          }`
        : "-";

    const isTransferCase = props?.caseInfo?.isTransferredCase ? true : false;

    const inTransitWithManualVehicle = props?.caseInfo?.isTransferredCase
      ? props?.trasnferCaseInfo?.assignedVehicle
        ? true
        : false
      : props?.caseInfo?.assignedVehicle
      ? true
      : false;

    console.log("inTransitWithManualVehicle", inTransitWithManualVehicle);

    const gender =
      props.caseInfo?.gender == 1
        ? "Male"
        : props.caseInfo?.gender == 2
        ? "Female"
        : "-";

    let address =
      props.caseInfo?.deviceInfo?.centerDeviceInfo?.center?.addressInfo;
    const deviceLocation =
      address !== null && address !== undefined
        ? `${address?.areaInfo?.name ? address.areaInfo.name + ", " : ""} ${
            address?.districtInfo?.name ? address.districtInfo.name : ""
          } `
        : "-";

    let technician = props.caseInfo?.reportInfo?.technicianInfo;
    const technicianName =
      technician !== null && technician !== undefined
        ? `${
            (technician?.firstName !== null &&
            technician?.firstName !== undefined
              ? `${technician?.firstName
                  .charAt(0)
                  .toUpperCase()}${technician?.firstName.slice(1)}`
              : "") +
            " " +
            (technician?.lastName !== null && technician?.lastName !== undefined
              ? `${technician?.lastName
                  .charAt(0)
                  .toUpperCase()}${technician?.lastName.slice(1)}`
              : "")
          }`
        : "-";

    const vitalsArray =
      props.caseInfo?.caseVitals?.length > 0 ? props.caseInfo?.caseVitals : [];
    let patientVitals = vitalsArray?.length ? vitalsArray[0] : null;
    if (isTransferCase) {
      let latestVital = vitalsArray?.find(
        (item) => item.transferCaseId == props.transferId
      );
      patientVitals = latestVital;
    }

    const ambDetails = isTransferCase
      ? inTransitWithManualVehicle
        ? props.trasnferCaseInfo?.assignedVehicle
        : props.trasnferCaseInfo?.assignAmbulance
      : inTransitWithManualVehicle
      ? props.caseInfo?.assignVehicle
      : props.caseInfo?.assignAmbulance;

    const ambAttendantName =
      ambDetails !== null && ambDetails !== undefined
        ? `${
            (ambDetails?.attendantFirstName !== null &&
            ambDetails?.attendantFirstName !== undefined
              ? `${ambDetails?.attendantFirstName
                  .charAt(0)
                  .toUpperCase()}${ambDetails?.attendantFirstName.slice(1)}`
              : "") +
            " " +
            (ambDetails?.attendantLastName !== null &&
            ambDetails?.attendantLastName !== undefined
              ? `${ambDetails?.attendantLastName
                  .charAt(0)
                  .toUpperCase()}${ambDetails?.attendantLastName.slice(1)}`
              : "")
          }`
        : "-";

    return (
      <div className="view-stemi-case-details">
        <table>
          <tr>
            <th>
              Demographics
              <div className="header-seperater" />
            </th>

            <th>Description</th>
          </tr>
          <tr>
            <td>Patient Name</td>
            <td>{patientName}</td>
          </tr>
          <tr>
            <td>Age</td>
            <td>{props.caseInfo?.age ?? "-"}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>{gender}</td>
          </tr>
          <tr>
            <td>Mobile Number</td>
            <td>
              {props.caseInfo?.mobileNumber
                ? "+91 " + props.caseInfo.mobileNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {props.caseInfo?.patientAddressInfo?.address?.length > 0
                ? props.caseInfo.patientAddressInfo.address
                : "-"}
            </td>
          </tr>
          {isTransferCase ? (
            <>
              <tr>
                <td>Hospital</td>
                <td>
                  {props.trasnferCaseInfo?.transferredFromHospital
                    ?.hospitalName ?? "-"}
                </td>
              </tr>
              <tr>
                <td>
                  {inTransitWithManualVehicle
                    ? `Vehicle Name`
                    : `Ambulance Name`}
                </td>
                <td>
                  {inTransitWithManualVehicle
                    ? props.trasnferCaseInfo?.assignedVehicle?.vehicleName
                    : props.trasnferCaseInfo?.assignAmbulance?.ambulanceName ??
                      "-"}
                </td>
              </tr>
              <tr>
                <td>
                  {inTransitWithManualVehicle
                    ? `Vehicle Number`
                    : `Ambulance Number`}
                </td>
                <td>
                  {inTransitWithManualVehicle
                    ? props.trasnferCaseInfo?.assignedVehicle
                        ?.vehicleRegistrationNumber ?? "-"
                    : props.trasnferCaseInfo?.assignAmbulance
                        ?.ambulanceRegistrationNumber ?? "-"}
                </td>
              </tr>
              <tr>
                <td>Attendant Name</td>
                <td>{ambAttendantName}</td>
              </tr>
              <tr>
                <td>Attendant Number</td>
                <td>
                  {inTransitWithManualVehicle
                    ? props.trasnferCaseInfo?.assignedVehicle
                        ?.attendantContactNumber
                      ? "+91 " +
                        props.trasnferCaseInfo?.assignedVehicle
                          .attendantContactNumber
                      : "-"
                    : props.trasnferCaseInfo?.assignAmbulance
                        ?.attendantContactNumber
                    ? "+91 " +
                      props.trasnferCaseInfo?.assignAmbulance
                        .attendantContactNumber
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>Case Assigned To</td>
                <td>
                  {props.trasnferCaseInfo?.transferredToHospital
                    ?.hospitalName ?? "-"}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>ECG Record ID</td>
                <td> {props.caseInfo?.reportInfo?.id ?? "-"}</td>
              </tr>
              <tr>
                <td>Device ID</td>
                <td>
                  {props.caseInfo?.reportInfo?.deviceInfo?.serialNo ?? "-"}
                </td>
              </tr>
              <tr>
                <td>Device Location</td>
                <td>{deviceLocation}</td>
              </tr>
              <tr>
                <td>Technician</td>
                <td>{technicianName}</td>
              </tr>
              <tr>
                <td>
                  {props.caseInfo?.assignAmbulance != null
                    ? "Ambulance"
                    : props.caseInfo?.assignVehicle != null
                    ? "Vehicle"
                    : "Ambulance"}
                </td>
                <td>
                  {props.caseInfo?.assignVehicle == null &&
                  props.caseInfo?.assignAmbulance == null
                    ? "-"
                    : props.caseInfo?.assignAmbulance != null
                    ? props.caseInfo?.assignAmbulance?.ambulanceName
                    : props.caseInfo?.assignVehicle != null
                    ? props.caseInfo?.assignVehicle?.vehicleRegistrationNumber
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>Case Assigned To</td>
                <td>{props.caseInfo?.assignHospital?.hospitalName ?? "-"}</td>
              </tr>
            </>
          )}
        </table>
        {/*--------------------------- 2nd vitals Table -----------------------*/}
        <table>
          <tr>
            <th>
              Vitals
              <div className="header-seperater" />
            </th>
            <th>Description</th>
          </tr>
          <tr>
            <td>Pulse</td>
            <td>{`${
              patientVitals?.pulse !== null &&
              patientVitals?.pulse !== undefined
                ? patientVitals.pulse + " bpm"
                : "-"
            } `}</td>
          </tr>
          <tr>
            <td>RR</td>
            <td>{`${
              patientVitals?.rr !== null && patientVitals?.rr !== undefined
                ? patientVitals.rr + " bpm"
                : "-"
            } `}</td>
          </tr>
          <tr>
            <td>BP</td>
            <td>{`${
              patientVitals?.bp ? patientVitals.bp + " mm/Hg" : "-"
            } `}</td>
          </tr>
          <tr>
            <td>SpO2</td>
            <td>
              {`${
                patientVitals?.spo2 !== null &&
                patientVitals?.spo2 !== undefined
                  ? patientVitals.spo2 + " %"
                  : "-"
              } `}
            </td>
          </tr>
          <tr>
            <td>Weight</td>
            <td>
              {`${
                patientVitals?.weight !== null &&
                patientVitals?.weight !== undefined
                  ? patientVitals.weight + " kgs"
                  : "-"
              } `}
            </td>
          </tr>
          <tr>
            <td>Blood Sugar</td>
            <td>
              {`${
                patientVitals?.bloodSugar !== null &&
                patientVitals?.bloodSugar !== undefined
                  ? patientVitals.bloodSugar + " mg/dL"
                  : "-"
              } `}
            </td>
          </tr>
          <tr>
            <td>Patient History</td>
            <td>
              {props.caseInfo?.patientHistory?.length > 2 ? (
                <IoCheckmarkCircle color="rgba(0, 180, 138, 1)" size={20} />
              ) : (
                <IoIosCloseCircle color="rgba(255, 77, 79, 1)" size={20} />
              )}
            </td>
          </tr>
          <tr>
            <td>Patient Documents</td>
            <td>
              {props.caseInfo?.caseDocuments?.length > 0 ? (
                <IoCheckmarkCircle color="rgba(0, 180, 138, 1)" size={20} />
              ) : (
                <IoIosCloseCircle color="rgba(255, 77, 79, 1)" size={20} />
              )}
            </td>
          </tr>
          {props.caseInfo.isTransferredCase ? (
            <tr>
              <td>Insurance</td>
              <td>
                {props.caseInfo?.insuranceId ? (
                  <IoCheckmarkCircle color="rgba(0, 180, 138, 1)" size={20} />
                ) : (
                  <IoIosCloseCircle color="rgba(255, 77, 79, 1)" size={20} />
                )}
              </td>
            </tr>
          ) : (
            <tr>
              <td>ECG Record</td>
              <td>
                <IoCheckmarkCircle color="rgba(0, 180, 138, 1)" size={20} />
              </td>
            </tr>
          )}
        </table>
      </div>
    );
  };

  const renderAdminsList = () => {
    return (
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wrapper">
          <div className="title">Total Cases: {props.totalCases}</div>

          <div className="search-and-buttons">
            <SearchView
              placeholder="Search Cases"
              onSearchClick={props.onSearchClick}
              onSearchTextCleared={props.onSearchTextCleared}
              updatedCurrentValue={props.filters.text}
            />
          </div>
        </div>

        <StemiCasesList
          data={props.casesList}
          totalAdmins={props.totalAdmins}
          //
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          onSelectedRowsChange={props.onSelectedRowsChange}
          //
          onViewCaseClick={props.onViewCaseClick}
          onAcceptCaseClick={(row, fromModal) =>
            props.onAcceptCaseClick(row, fromModal)
          }
          onRejectCaseClick={(row, fromModal) =>
            props.onRejectCaseClick(row, fromModal)
          }
          totalCases={props.totalCases}
          navigateToCaseDetails={props.navigateToCaseDetails}
          //
          newRecords={props.newRecords}
          //
          onCaseListFilterClick={props.onCaseListFilterClick}
          onCaseListSortClick={props.onCaseListSortClick}
        />
      </CardView>
    );
  };

  const renderRejectCasePopupContents = () => {
    const patientName =
      props.caseInfo !== null && props.caseInfo !== undefined
        ? `${
            (props.caseInfo?.firstName !== null &&
            props.caseInfo?.firstName !== undefined
              ? `${props.caseInfo?.firstName
                  .charAt(0)
                  .toUpperCase()}${props.caseInfo?.firstName.slice(1)}`
              : "") +
            " " +
            (props.caseInfo?.lastName !== null &&
            props.caseInfo?.lastName !== undefined
              ? `${props.caseInfo?.lastName
                  .charAt(0)
                  .toUpperCase()}${props.caseInfo?.lastName.slice(1)}`
              : "")
          }`
        : "-";

    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Reject case of
          ${patientName}
          ?`}
        </text>
      </div>
    );
  };

  return (
    <div className="cases-container-new">
      {renderAdminsList()}

      <PopupDialog
        show={props.showViewCasePopup}
        title={
          <div className="case-view-title">
            {`Case ID ${props.caseInfo.id}`}
            {renderStatus()}
          </div>
        }
        showCloseIcon={true}
        renderBodyContent={renderViewCasePopupContents}
        extraButtonTitle="Reject"
        onClickExtra={() => props.onRejectCaseClick(props.caseInfo, true)}
        showExtraButton={true}
        extraButtonStyle="danger-outline"
        confirmButtonTitle={"Accept"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={() => props.onAcceptCaseClick(props.caseInfo, true)}
        onclickCancel={props.onCloseViewCasePopup}
        popupSize={"md"}
        extraStyle={{ maxHeight: "65vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showRejectCasePopup}
        title={"Reject Case"}
        showCloseIcon={true}
        renderBodyContent={renderRejectCasePopupContents}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.callRejectCaseAPI}
        onclickCancel={props.onCloseRejectPopup}
        popupSize={"md"}
      />
    </div>
  );
};

export default CasesView;
