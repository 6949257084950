export const actionTypes = {
    // GET_REPORT_PENDING: "GET_REPORT_PENDING",
    // GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
    // GET_REPORT_ERROR: "GET_REPORT_ERROR",

    GET_CENTER_LIST_WITH_FILTER_PENDING: "GET_CENTER_LIST_WITH_FILTER_PENDING",
    GET_CENTER_LIST_WITH_FILTER_SUCCESS: "GET_CENTER_LIST_WITH_FILTER_SUCCESS",
    GET_CENTER_LIST_WITH_FILTER_ERROR: "GET_CENTER_LIST_WITH_FILTER_ERROR",
};

const initialState = {
    getCenterPending: false,
    getCenterSuccess: false,
    getCenterError: null,

    getCenterListWithFilterPending: false,
    getCenterListWithFilterSuccess: false,
    getCenterListWithFilerError: null,

    centerList: [],
    filteredCenterList: []
};

export default function center(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        // case actionTypes.GET_CENTER_PENDING:
        // case actionTypes.GET_CENTER_SUCCESS:
        // case actionTypes.GET_CENTER_ERROR:
        //     return Object.assign({}, state, payload);

        case actionTypes.GET_CENTER_LIST_WITH_FILTER_PENDING:
        case actionTypes.GET_CENTER_LIST_WITH_FILTER_SUCCESS:
        case actionTypes.GET_CENTER_LIST_WITH_FILTER_ERROR:
            return Object.assign({}, state, payload);

        default:
            return state;
    }
}