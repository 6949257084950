import React, { useEffect } from "react";
import "./DoctorsStyles.scss";
import {
  CardView,
  FormSearchView,
  Input,
  SearchView,
  ThemedButton,
} from "../../../controls";
import { FaPlus } from "react-icons/fa6";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import DoctorsList from "../Components/Lists/DoctorsList";
import PopupDialog from "../Components/Other/PopupDialog";
import { CSSTransition } from "react-transition-group";
import { AiOutlineDelete } from "react-icons/ai";
import { strings } from "../../../../constants/stringConstants";

const DoctorsView = (props) => {
  // console.log("props.doctorDataById", props.doctorDataById);
  // useEffect(() => {
  //   if (props.doctorDataById) {
  //     props.onEditPopulateData(props.doctorDataById);
  //   }
  // }, [props.doctorDataById]);

  const selectedDoctorName = `${
    (props.doctorInfo?.firstName !== null &&
    props.doctorInfo?.firstName !== undefined
      ? `${props.doctorInfo?.firstName
          .charAt(0)
          .toUpperCase()}${props.doctorInfo?.firstName.slice(1)}`
      : "") +
    " " +
    (props.doctorInfo?.lastName !== null &&
    props.doctorInfo?.lastName !== undefined
      ? `${props.doctorInfo?.lastName
          .charAt(0)
          .toUpperCase()}${props.doctorInfo?.lastName.slice(1)}`
      : "")
  }`;
  //

  var isFlag = false;
  if (props.showReportingHospitalFlag && props.searchHospitalList.length == 0) {
    isFlag = true;
  } else if (props.searchHospitalList.length == 0) {
    isFlag = true;
  } else {
    isFlag = false;
  }

  const renderAddNewDoctor = () => {
    return (
      <div>
        {isFlag ? (
          <div className="form-wrapper-container">
            <div>
              You are not authorised to add new doctors as you do not have any
              reporting hospitals assigned.
            </div>
          </div>
        ) : (
          <div className="form-wrapper-container">
            <div className="block-level-group">
              <label className="form-label">
                <span className="required-star">* </span> Doctor Name :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter First Name"
                  value={props?.fields?.firstName}
                  // disabled={fields.CenterAdminFlag == false ? true : false}
                  error={props.errors["firstName"]}
                  onChange={props.handleChange("firstName")}
                  halfsize={true}
                  charLimit={50}
                  showCharLimit={true}
                />
                <div style={{ width: "20px" }} />
                <Input
                  className="input-wrapper"
                  placeholder="Enter Last Name"
                  value={props?.fields?.lastName}
                  // disabled={fields.CenterAdminFlag == false ? true : false}
                  error={props.errors["lastName"]}
                  onChange={props.handleChange("lastName")}
                  halfsize={true}
                  charLimit={50}
                  showCharLimit={true}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <label className="form-label">
                <span className="required-star">* </span>Hospital :
              </label>
              <div className="value-item extra">
                <FormSearchView
                  Options={props.searchHospitalList}
                  previousValue={props.fields.hospitals}
                  placeholder="Search Hospital Name"
                  onInputChange={props.onHospitalSearchChange}
                  onChange={props.onAssignHospitalChange}
                  onScroll={props.onScrollHospitalListDropdown}
                  searchText={props.searchHospitalText}
                  error={props.errors["hospitals"]}
                  // multiSelect={true}
                  lazyLoading={true}
                  customInputSearch={true}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <text className="form-label">
                <span className="required-star">* </span>Qualification :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Qualification"
                  size="sm"
                  onChange={props.handleChange("qualification")}
                  value={props?.fields.qualification}
                  error={props.errors["qualification"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <text className="form-label">
                <span className="required-star">*</span>MSCI ID
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter MSCI ID"
                  size="sm"
                  onChange={props.handleChange("msci_id")}
                  value={props?.fields?.msci_id}
                  error={props.errors["msci_id"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <text className="form-label">
                <span className="required-star">*</span>Mobile :
              </text>
              <div className="value-item">
                {/* <text className="mobile-code">+91</text> */}
                <Input
                  type="tel"
                  isvalnum="isNum"
                  className="input-wrapper"
                  placeholder="Enter Mobile Number"
                  size="sm"
                  value={props?.fields?.mobile}
                  onChange={props.handleChange("mobile")}
                  maxLength={10}
                  showMobilePrefix={true}
                  error={props.errors["mobile"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group ">
              <text className="form-label">
                <span className="required-star">*</span>
                Email :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  value={props?.fields?.email}
                  name={"email"}
                  disabled={props.onEditFlag == true ? true : false}
                  placeholder="Enter email"
                  onChange={props.handleChange("email")}
                  error={props.errors["email"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            {props.onEditFlag == false && (
              <div
                className="block-level-group"
                style={{
                  marginBottom:
                    props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                      ? "15px"
                      : "0px",
                }}
              >
                <text className="form-label">
                  <span className="required-star">*</span>Password :
                </text>
                <div className="value-item">
                  <Input
                    value={props?.fields?.password}
                    className="input-wrapper"
                    // name={"password"}
                    placeholder="Enter Password"
                    onChange={props.handleChange("password")}
                    error={props.errors["password"]}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
            )}

            {props.onEditFlag == false && (
              <div className="block-level-group  mb-3">
                <text className="form-label">
                  <span className="required-star">*</span>Confirm Password :
                </text>
                <div className="value-item">
                  <Input
                    value={props?.fields?.confirmPassword}
                    className="input-wrapper"
                    // name={"confrimPassword1"}
                    placeholder="Enter Confirm Password"
                    onChange={props.handleChange("confrimPassword1")}
                    error={props.errors["confrimPassword1"]}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderDeleteDoctorPopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to delete Dr. ${selectedDoctorName} ?`}
        </text>
      </div>
    );
  };

  const renderDeleteBulkDoctorPopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Delete ${
            props.selectedIdsToDelete.length
          } ${props.selectedIdsToDelete.length > 1 ? "Doctors" : "Doctor"}
  
          ?`}
        </text>
      </div>
    );
  };
  const renderUnlockMessage = () => {
    return (
      <div className="unlock-modal-content">
        <img className="unlock-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Unlock {selectedDoctorName}?</text>
      </div>
    );
  };

  const renderResetPasswordContent = () => {
    return (
      <div className="reset-password-Doctor-form">
        <div
          className="fields"
          style={{
            marginBottom:
              props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                ? "15px"
                : "0px",
          }}
        >
          <text className="label-item">
            <span className="required-star">* </span> New Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Password"
              type={"password"}
              size="sm"
              value={props.resetPasswordDoctorFields.password}
              onChange={props.onResetPasswordDoctorFieldChange("password")}
              error={props.errors["password"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Confirm Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Confirm Password"
              type={"password"}
              size="sm"
              value={props.resetPasswordDoctorFields.confirmPassword}
              onChange={props.onResetPasswordDoctorFieldChange(
                "confirmPassword"
              )}
              error={props.errors["confirmPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="doctors-container-new">
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wraper">
          <div className="title">Total Doctors : {props.totalDoctors}</div>
          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder={"Search Doctors"}
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                />

                <ThemedButton
                  title="Add New Doctor"
                  size={"large"}
                  icon={<FaPlus size={15} color="#fff" />}
                  onClick={() => {
                    props.onAddNewDoctorClick();
                  }}
                  styles="primary medium"
                />
              </>
            )}

            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onDeleteBulkDoctorClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>
        <DoctorsList
          page={props.page}
          limit={props.limit}
          totalDoctors={props.totalDoctors}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          doctorsList={props.doctorsList}
          //
          onEditDoctorClick={props.onEditDoctorClick}
          onDeleteDoctorClick={props.onDeleteDoctorClick}
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          onResetPasswordDoctorClick={props.onResetPasswordDoctorClick}
          //
          onUnlockDoctorAccountClick={props.onUnlockDoctorAccountClick}
          //
          onDoctorListFilterClick={props.onDoctorListFilterClick}
          onDoctorListSortClick={props.onDoctorListSortClick}
        />
      </CardView>

      <PopupDialog
        show={props.onEditFlag ? props.onEditFlag : props.showAddNewDoctorPopup}
        title={props.onEditFlag ? "Edit Doctor" : "Add New Doctor"}
        showCloseIcon={true}
        renderBodyContent={renderAddNewDoctor}
        confirmButtonTitle={props.onEditFlag ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickCancel={
          props.onEditFlag ? props.onCancelEditButton : props.onCancelAddButton
        }
        onclickConfirm={
          props.onEditFlag ? props?.onEditSaveButton : props.onAddSaveButton
        }
        hideButtons={false}
        hideConfirmButton={props?.searchHospitalList.length == 0 && true}
      />
      <PopupDialog
        show={props.showDeleteDoctorPopup}
        title={"Delete Doctor"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteDoctorPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteDoctorConfirmClick}
        onclickCancel={props.onCloseDeleteDoctorPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDeleteBulkDoctorPopup}
        title={"Delete Bulk Doctor"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteBulkDoctorPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkDoctorDeleteClick}
        onclickCancel={props.onCloseDeleteBulkDoctorPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showResetPasswordDoctorPopup}
        title={`Reset Password For Dr. ${selectedDoctorName}`}
        showCloseIcon={true}
        renderBodyContent={renderResetPasswordContent}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onResetPasswordDoctorConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseResetPasswordDoctorPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showUnlockDoctorConfirmationPopup}
        title={"Unlock"}
        showCloseIcon={true}
        renderBodyContent={renderUnlockMessage}
        confirmButtonTitle={"Unlock"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onUnlockDoctorAccountConfirmClick}
        onclickCancel={props.closeUnlockDoctorPopup}
        hideButtons={false}
        popupSize={"md"}
      />
    </div>
  );
};

export default DoctorsView;
