import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getStatesList,
  getDistrict,
} from "../../../../store/master/masterActions";
import {
  getReportListWithFilter,
  ReportSubmission,
  ReportDetails,
  getSeverityList,
  removeAssociationOfReportWithDoctor,
  ReportReviewSubmissionBySupervisor,
} from "../../../../store/report/reportActions";
import ECGReportDetailsView from "./ECGReportDetailsView";
import ECGReportDetailsView2 from "./ECGReportDetailsView2";
import { history } from "../../../../store/configure/configureStore";
import {
  subscribeToTopic,
  unSubscribeToTopic,
} from "../../../../service/mqttApi";
import { roles } from "../../../../constants/roles";

class ECGReportDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      reportId: null,
      isPreviewOnly: false,
      //
      reportDetails: "",
      ecgDetails: "",
      //
      patientState: "",
      patientDistrict: "",
      //
      severityData: [],
      //
      prevState: {},
      //
      showRequiredSecondOpinionPopup: false,
      showContactTechnicianPopup: false,
      showResetTimerPopup: false,
      showResendPopup: false,
      isCaseView: false,
      caseId: "",
      //
      analysis: "",
      analysisError: "",
      interpretation: "",
      interpretationError: "",
      remarks: "",
      remarksError: "",
      severity: "",
      severityError: "",
      //
      comments: "",
      commentsError: "",
      reviewedSeverity: "",
      reviewedSeverityError: "",
    };

    this.onResetTimerClick = this.onResetTimerClick.bind(this);
    this.onContactTechnicianClick = this.onContactTechnicianClick.bind(this);
    this.closeAllPopups = this.closeAllPopups.bind(this);

    this.onAnalysisChange = this.onAnalysisChange.bind(this);
    this.onInterpretationChange = this.onInterpretationChange.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.onSeverityChange = this.onSeverityChange.bind(this);

    this.onGoBackButtonClick = this.onGoBackButtonClick.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.onAcceptedReportCancelButtonClick =
      this.onAcceptedReportCancelButtonClick.bind(this);
    this.onResendButtonClick = this.onResendButtonClick.bind(this);
    this.onRequireSecondButtonClick =
      this.onRequireSecondButtonClick.bind(this);

    this.validateData = this.validateData.bind(this);
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
    this.validateDataForSupervisor = this.validateDataForSupervisor.bind(this);
    this.onSubmitButtonClickOfSupervisor =
      this.onSubmitButtonClickOfSupervisor.bind(this);

    this.onCloseReportByHospitalAdminClick =
      this.onCloseReportByHospitalAdminClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    const pathname = window.location.pathname;
    var previousState = this.props.location?.state ?? {};
    console.log("previousState", previousState);

    // var reportDetails = pathname.substr(20, pathname.length);
    // var id = 0;

    // if (reportDetails.includes("preview/")) {
    //   //Disable all inputs and button clicks
    //   id = reportDetails.substr(8, reportDetails.length);
    //   this.setState({ isPreviewOnly: true, reportId: id });
    // } else {
    //   id = reportDetails;
    //   this.setState({ isPreviewOnly: false, reportId: id });
    // }

    // Parse reportId and isPreviewOnly values from Url params
    const urlParams = new URLSearchParams(this.props.location?.search);
    var preview = urlParams.get("preview")
      ? urlParams.get("preview") === "true"
        ? true
        : urlParams.get("preview") === "false"
        ? false
        : false
      : false;

    //when hospital admin wants to view ECG
    var caseView = urlParams.get("case_view")
      ? urlParams.get("case_view") === "true"
        ? true
        : urlParams.get("case_view") === "false"
        ? false
        : false
      : false;

    var id = urlParams.get("id");
    console.log(id, "_id");
    this.setState({
      isPreviewOnly: preview,
      isCaseView: caseView,
      reportId: id,
      prevState: previousState,
    });

    var caseId = urlParams.get("case_id");
    console.log(id, "case_id");

    this.setState({
      isPreviewOnly: preview,
      isCaseView: caseView,
      reportId: id,
      caseId: caseId,
    });

    console.log("PATH :  ", pathname, ", id :  ", id, ", preview :  ", preview);

    this.props.getSeverityList((severityList) => {
      this.setState({ severityData: severityList });

      this.props.ReportDetails(parseInt(id), (reportDetails, ecgDetails) => {
        if (this.state.userRole === roles.doctor && !this.state.isPreviewOnly) {
          subscribeToTopic(
            `device/expiredReportRequest/reportData/${this.state.reportId}`,
            (msg) => {
              unSubscribeToTopic(
                `device/expiredReportRequest/reportData/${this.state.reportId}`
              );
              this.onTimerExpire();
            }
          );
        }
        this.populateReportData(reportDetails, ecgDetails, severityList);
      });
    });
  }

  componentWillUnmount() {
    if (this.state.userRole === roles.doctor) {
      unSubscribeToTopic(
        `device/expiredReportRequest/reportData/${this.state.reportId}`
      );
    }
  }

  componentWillReceiveProps(newProps) {
    //TODO
  }

  // ------------------------------------

  populateReportData = (reportDetails, ecgDetails = {}, severityList) => {
    var severity = reportDetails?.severity; // 'critical'
    var severityArray = severityList; //[...data objects]
    console.log("severity : ", severity);

    // Find index in 'severityArray', where value matches above variable 'severity'.
    var index = severityArray.findIndex((data) => data.value == severity);
    console.log("severity index : ", index);

    // Find patients state and district names
    var patientDistrictId =
      reportDetails?.patientInfo?.addressInfo?.districtId ?? null;
    var patientStateId =
      reportDetails?.patientInfo?.addressInfo?.stateId ?? null;
    console.log("district, state:", patientDistrictId, ", ", patientStateId);

    this.props.getStatesList((statesList) => {
      if (Array.isArray(statesList) && statesList.length > 0) {
        statesList.map((item) => {
          if (item.id == patientStateId) {
            console.log("found state : ", item.name);
            this.setState({ patientState: item.name });
          }
        });
      }
    });

    this.props.getDistrict({ stateId: patientStateId }, (districtList) => {
      if (Array.isArray(districtList) && districtList.length > 0) {
        districtList.map((item) => {
          if (item.id == patientDistrictId) {
            console.log("found district : ", item.name);
            this.setState({ patientDistrict: item.name });
          }
        });
      }
    });

    this.setState({
      reportDetails: reportDetails,
      ecgDetails: ecgDetails,
      // doctor
      analysis: reportDetails?.analysis ?? "",
      interpretation: reportDetails?.autointerpritation ?? "",
      remarks: reportDetails?.observations ?? "",
      severity: index !== -1 ? this.state.severityData[index] : "",
      // supervisor
      comments: reportDetails?.comments ?? "",
    });

    if (this.state.userRole === roles.supervisor) {
      var reviewedSeverity = reportDetails?.reviewedSeverity ?? "";
      // reportDetails.reviewedSeverity ?? ""; // 'critical'

      // Find index in 'severityArray', where value matches above variable 'severity'.
      var index = severityArray.findIndex(
        (data) => data.value == reviewedSeverity
      );
      console.log("severity index : ", index);

      this.setState({
        analysis:
          reportDetails?.reviewedAnalysis ?? reportDetails?.analysis ?? "",
        interpretation:
          reportDetails?.reviewedAutointerpritation ??
          reportDetails?.autointerpritation ??
          "",
        remarks:
          reportDetails?.reviewedObservations ??
          reportDetails?.observations ??
          "",
        reviewedSeverity: index !== -1 ? this.state.severityData[index] : "",
      });
      console.log(this.state.reviewedSeverity, "reviewedSeverity");
    }
  };

  // ----------------------

  onResetTimerClick = () => {
    this.setState({ showResetTimerPopup: true });
  };

  onContactTechnicianClick = () => {
    this.setState({ showContactTechnicianPopup: true });
  };

  closeAllPopups = () => {
    this.setState({
      showContactTechnicianPopup: false,
      showRequiredSecondOpinionPopup: false,
      showResetTimerPopup: false,
      showResendPopup: false,
    });
  };

  // ------------------------------------

  onAnalysisChange = (value) => {
    if (value.length > 120) {
      this.setState({
        analysis: value.substr(0, 120),
        analysisError: "Maximum 120 characters allowed",
      });
    } else {
      this.setState({ analysis: value, analysisError: "" });
    }
  };

  onInterpretationChange = (value) => {
    if (value.length > 120) {
      this.setState({
        interpretation: value.substr(0, 120),
        interpretationError: "Maximum 120 characters allowed",
      });
    } else {
      this.setState({ interpretation: value, interpretationError: "" });
    }
  };

  onRemarksChange = (value) => {
    if (value.length > 120) {
      this.setState({
        remarks: value.substr(0, 120),
        remarksError: "Maximum 120 characters allowed",
      });
    } else {
      this.setState({ remarks: value, remarksError: "" });
    }
  };

  onCommentsChange = (value) => {
    if (value.length > 500) {
      this.setState({
        comments: value.substr(0, 500),
        commentsError: "Maximum 500 characters allowed",
      });
    } else {
      this.setState({ comments: value, commentsError: "" });
    }
  };

  onSeverityChange = (value) => {
    if (this.state.userRole === roles.supervisor) {
      this.setState({ reviewedSeverity: value, reviewedSeverityError: "" });
      console.log("reviewedSeverity : ", value);
    } else {
      this.setState({ severity: value, severityError: "" });
      console.log("severity : ", value);
    }
  };

  // ------------------------------------

  onGoBackButtonClick = () => {
    this.navigateBack();
  };

  navigateBack = () => {
    if (this.state.isPreviewOnly) {
      //Coming from Reviewed Reprts screen of (View Report Click)
      history.push("/ecg_reports");
    } else {
      //Coming from Dashboard screen of Doctor (Accept Click)
      history.push("/dashboard_new");
    }
  };

  onAcceptedReportCancelButtonClick = () => {
    this.props.removeAssociationOfReportWithDoctor(
      this.props.reportDetails.id,
      () => {
        this.navigateBack();
      }
    );
  };

  onCloseReportByHospitalAdminClick = () => {
    if (this.state.caseId) {
      history.push({
        pathname: "/case_details",
        state: {
          caseId: this.state.caseId,
        },
      });
    }
  };

  onResendButtonClick = () => {
    this.setState({ showResendPopup: true });
  };

  onRequireSecondButtonClick = () => {
    this.setState({ showRequiredSecondOpinionPopup: true });
  };

  onTimerExpire = () => {
    this.props.showAlert({
      isOpen: true,
      title: "Session Timeout",
      type: "danger",
      msg: "You have exhausted time limit for completing this report",
      showError: true,
    });
    this.navigateBack();
  };

  // ------------------------------------

  onSubmitButtonClickOfSupervisor = () => {
    console.log("onSubmitButtonClickOfSupervisor ", this.validateData());
    if (this.validateDataForSupervisor()) {
      const data = {
        reviewedAnalysis: this.state.analysis ?? "",
        reviewedAutointerpritation: this.state.interpretation ?? "",
        reviewedObservations: this.state.remarks ?? "",
        comments: this.state.comments,
        reviewedSeverity: this.state.reviewedSeverity.value,
      };

      console.log(data, "sending data");
      // API CALL for Submit Report
      this.props.ReportReviewSubmissionBySupervisor(
        this.props.reportDetails.id,
        data,
        (isSuccess) => {
          if (isSuccess) {
            this.navigateBack();
          }
        }
      );
    }
  };

  validateDataForSupervisor = () => {
    var isErrorInData = false;

    //
    if (this.state.comments === null || this.state.comments.trim() === "") {
      this.setState({
        commentsError: "Please add comments for the report",
      });
      isErrorInData = true;
    }

    //
    if (
      this.state.reviewedSeverity === null ||
      this.state.reviewedSeverity === "" ||
      this.state.reviewedSeverity.value === ""
    ) {
      this.setState({
        reviewedSeverityError: "Please select a severity for the report.",
      });
      isErrorInData = true;
    }

    if (isErrorInData) {
      return false;
    } else {
      return true;
    }
  };

  // ------------------------------------

  onSubmitButtonClick = () => {
    if (this.validateData()) {
      const data = {
        analysis: this.state.analysis,
        autointerpritation: this.state.interpretation,
        observations: this.state.remarks,
        severity: this.state.severity.value,
      };

      // API CALL for Submit Report
      this.props.ReportSubmission(
        this.props.reportDetails.id,
        data,
        (isSuccess) => {
          if (isSuccess) {
            this.navigateBack();
          }
        }
      );
    }
  };

  validateData = () => {
    var isErrorInData = false;

    if (this.state.analysis === null || this.state.analysis.trim() === "") {
      this.setState({ analysisError: "Please add analysis for the report" });
      isErrorInData = true;
    }

    //
    if (this.state.analysis.length > 120) {
      this.setState({ analysisError: "Maximum 120 characters allowed" });
      isErrorInData = true;
    }

    //
    if (
      this.state.interpretation === null ||
      this.state.interpretation.trim() === ""
    ) {
      this.setState({
        interpretationError: "Please add interpretation for the report",
      });
      isErrorInData = true;
    }

    //
    if (this.state.interpretation.length > 120) {
      this.setState({ interpretationError: "Maximum 120 characters allowed" });
      isErrorInData = true;
    }

    //
    if (this.state.remarks.length > 120) {
      this.setState({ remarksError: "Maximum 120 characters allowed" });
      isErrorInData = true;
    }

    //
    if (
      this.state.severity === null ||
      this.state.severity === "" ||
      this.state.severity.value === ""
    ) {
      this.setState({
        severityError: "Please select a severity for the report.",
      });
      isErrorInData = true;
    }

    if (isErrorInData) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <ECGReportDetailsView2
        userRole={this.state.userRole}
        reportDetails={this.state.reportDetails}
        ecgDetails={this.state.ecgDetails}
        patientState={this.state.patientState}
        patientDistrict={this.state.patientDistrict}
        //
        severityData={this.state.severityData}
        isPreviewOnly={this.state.isPreviewOnly}
        onTimerExpire={this.onTimerExpire}
        //
        showResetTimerPopup={this.state.showResetTimerPopup}
        onResetTimerClick={this.onResetTimerClick}
        showContactTechnicianPopup={this.state.showContactTechnicianPopup}
        onContactTechnicianClick={this.onContactTechnicianClick}
        closeAllPopups={this.closeAllPopups}
        //
        analysis={this.state.analysis}
        analysisError={this.state.analysisError}
        onAnalysisChange={this.onAnalysisChange}
        //
        interpretation={this.state.interpretation}
        interpretationError={this.state.interpretationError}
        onInterpretationChange={this.onInterpretationChange}
        //
        remarks={this.state.remarks}
        remarksError={this.state.remarksError}
        onRemarksChange={this.onRemarksChange}
        //
        comments={this.state.comments}
        commentsError={this.state.commentsError}
        onCommentsChange={this.onCommentsChange}
        //
        severity={this.state.severity}
        severityError={this.state.severityError}
        reviewedSeverity={this.state.reviewedSeverity}
        reviewedSeverityError={this.state.reviewedSeverityError}
        onSeverityChange={this.onSeverityChange}
        //
        onGoBackButtonClick={this.onGoBackButtonClick} // Doctor : Preview Old Reports Clicked Go Back or close icon
        onAcceptedReportCancelButtonClick={
          this.onAcceptedReportCancelButtonClick // Doctor : Clicked Close icon while submitting Report
        }
        showResendPopup={this.state.showResendPopup}
        onResendButtonClick={this.onResendButtonClick}
        showRequiredSecondOpinionPopup={
          this.state.showRequiredSecondOpinionPopup
        }
        onRequireSecondButtonClick={this.onRequireSecondButtonClick}
        //
        onSubmitButtonClick={this.onSubmitButtonClick}
        onSubmitButtonClickOfSupervisor={this.onSubmitButtonClickOfSupervisor}
        //
        isCaseView={this.state.isCaseView}
        //
        onCloseReportByHospitalAdminClick={
          this.onCloseReportByHospitalAdminClick
        }
      />
    );
  }
}

ECGReportDetailsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    filteredReportList: state.report.filteredReportList,
    reportDetails: state.report.reportDetails,
    ecgDetails: state.report.ecgDetails,
    severityList: state.report.severityList,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  getStatesList,
  getDistrict,
  getReportListWithFilter,
  ReportSubmission,
  ReportDetails,
  getSeverityList,
  ReportReviewSubmissionBySupervisor,
  removeAssociationOfReportWithDoctor,
})(ECGReportDetailsContainer);
