import axios from "axios";
import {
  PATH_API_URI,
  REPORT_DOMAIN_API_URI,
  DOMAIN_API_URI,
} from "./apiConstants";
import { getState, getStore } from "../store/configure/configureStore";
import { updateToken, resetUserLoginDetails } from "../store/auth/authActions";

const AUTH = "auth/";

export default class Api {
  static actionsStack = [];

  static methods = {
    GET: "get",
    POST: "post",
    PATCH: "patch",
    PUT: "put",
    DELETE: "delete",
  };

  static get initialStatus() {
    return {
      loading: false,
      loaded: false,
      fail: false,
    };
  }

  static get requestStatus() {
    return {
      loading: true,
      loaded: false,
      fail: false,
    };
  }

  static get successStatus() {
    return {
      loading: false,
      loaded: true,
      fail: false,
    };
  }

  static get failStatus() {
    return {
      loading: false,
      loaded: false,
      fail: true,
    };
  }

  static composeRouteUrl(route) {
    if (route.startsWith("http")) {
      return route;
    }
    return `${REPORT_DOMAIN_API_URI}${PATH_API_URI}${route}`;
  }

  static composeRouteUrlForPdfDownload(route) {
    if (route.startsWith("http")) {
      return route;
    }
    return `${DOMAIN_API_URI}${PATH_API_URI}${route}`;
  }

  static get(route, params) {
    return Api.request(route, params, undefined, Api.methods.GET);
  }

  static put(route, data, params) {
    return Api.request(route, params, data, Api.methods.PUT);
  }

  static patch(route, params, data) {
    return Api.request(route, params, data, Api.methods.PATCH);
  }

  static post(route, data, appendHeaders, handleFormError = true) {
    return Api.request(
      route,
      undefined,
      data,
      Api.methods.POST,
      appendHeaders,
      handleFormError
    );
  }

  static delete(route, data, params) {
    return Api.request(route, params, data, Api.methods.DELETE);
  }

  static pdfDownload(url, params, data) {
    return new Promise(async (resolve, reject) => {
      const urlNew = Api.composeRouteUrlForPdfDownload(url);

      let headers = {
        "Content-Type": "application/pdf",
      };
      const token = "Bearer " + getState().auth.accessToken;
      if (getState().auth.accessToken) {
        headers["Authorization"] = token;
      }

      return axios({
        method: "GET",
        url: urlNew,
        headers,
        params,
        responseType: "arraybuffer",
        data,
      })
        .then((resp) => {
          return resolve(resp);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }

  static zipDownload(url, data) {
    return new Promise(async (resolve, reject) => {
      const urlNew = Api.composeRouteUrlForPdfDownload(url);

      let headers = {
        "Content-Type": "application/json",
      };
      const token = "Bearer " + getState().auth.accessToken;
      if (getState().auth.accessToken) {
        headers["Authorization"] = token;
      }

      return axios({
        method: "POST",
        url: urlNew,
        headers,
        params: null,
        responseType: "arraybuffer",
        data,
      })
        .then((resp) => {
          console.log("then resp ::: ", resp);
          return resolve(resp);
        })
        .catch((err) => {
          console.log("err resp ::: ", err);
          reject(err.message);
        });
    });
  }

  static request(
    route,
    params,
    data,
    method,
    appendHeaders,
    shallRetry = true
  ) {
    return new Promise(async (resolve, reject) => {
      const url = Api.composeRouteUrl(route, params);
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
      };
      const token = "Bearer " + getState().auth.accessToken;
      const refreshToken = "Bearer " + getState().auth.refreshToken;

      // const token = Auth.getTokenFromLocalStorage();
      // console.log(getState().auth.accessToken);

      if (getState().auth.accessToken) {
        headers["Authorization"] = token;
      }
      if (appendHeaders) {
        headers = { ...headers, ...appendHeaders };
      }

      axios({
        method,
        url,
        headers,
        params,
        data,
      })
        .then((resp) => {
          if (resp.data.msg) {
            Api.alert(resp.data.msg);
          }

          if (resp.data.rid === "e-auth-14" || resp.data.rid === "e-auth-2") {
            // alert("Token Expired");
            getStore().dispatch(resetUserLoginDetails());
            reject(resp.data.message);
          } else if (resp.data.rid.startsWith("e-")) {
            reject(resp.data.message);
          } else if (resp.data.rid.startsWith("s-")) {
            return resolve(resp.data);
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  }
}
