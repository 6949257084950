import React from "react";
import { Card } from "react-bootstrap";
import "./CardStyles.scss";
const CardView = ({
  children,
  title,
  image,
  customStyle = {},
  extraStyle = "",
  cardstyle = "",
  bodyStyle = {},
}) => {
  return (
    <div className={`card-control ${extraStyle}`}>
      <Card className={`card ${cardstyle}`}>
        {image && <Card.Img variant="top" src={image} style={customStyle} />}
        <Card.Body style={bodyStyle}>
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardView;
