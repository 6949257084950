import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  getErrorLogsListWithFilter,
  viewErrorLogs,
  updateErrorLogs,
  toggleDataForErrorLogs,
} from "../../../../store/errorLogs/errorLogsActions";
import * as validator from "../../../../utils/validator";
import { trimFormData } from "../../../../utils/common";
import { showAlert } from "../../../../store/alert/alertActions";
import ErrorLogsView from "./ErrorLogsView";

class ErrorLogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      errorLogList: [],
      totalerrorLogs: 0,
      toggleValue: "4",
      toggleData: [],
      toggleHeader: "All",
      // super center and hospital admin
      fields: {
        email: "",
      },
      emailError: "",
      remark: "",
      remarksError: "",
      isValidationsOn: false,
      //
      showViewErrorLogPopup: false,
      showUpdateErrorLogPopup: false,
      viewErrorLogData: {},
      viewUserRemark: {},

      // Search filters
      filters: {
        text: "",
        severity: "",
      },
    };
    this.populateListData = this.populateListData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);
    this.onMailToChange = this.onMailToChange.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.onSendEmailButtonClick = this.onSendEmailButtonClick.bind(this);
    this.onSendEmailAndCloseButtonClick =
      this.onSendEmailAndCloseButtonClick.bind(this);
    //
    this.validateData = this.validateData.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
    this.userViewAction = this.userViewAction.bind(this);
    this.onEscalateActionClick = this.onEscalateActionClick.bind(this);
    this.onInvestigateActionClick = this.onInvestigateActionClick.bind(this);
    this.closeAllPopups = this.closeAllPopups.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);

    this.onErrorLogListFilterClick = this.onErrorLogListFilterClick.bind(this);
    this.onErrorLogListSortClick = this.onErrorLogListSortClick;
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    this.props.toggleDataForErrorLogs((toggleListData) => {
      this.setState(
        {
          toggleData: toggleListData,
          toggleValue: toggleListData[0]?.id ?? "4",
          toggleHeader: toggleListData[0]?.name ?? "All",
          filters: {
            ...this.state.filters,
            status: toggleListData[0]?.id == 4 ? "" : toggleListData[0]?.id,
          },
        },
        () => {
          this.callGetErrorLogsListApi();
        }
      );
    });
  }
  componentWillUnmount() {}

  callGetErrorLogsListApi = () => {
    this.props.getErrorLogsListWithFilter(
      this.state.filters,
      (listData, count) => {
        this.populateListData(listData, count);
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  onToggleChangeErrorLogForTechnician = (toggleVal) => {
    const toggleListData = this.state.toggleData;
    var index = toggleListData.findIndex((data) => data.id == toggleVal);

    this.setState(
      {
        page: 1,
        toggleHeader: toggleListData[index].name,
        filters: {
          ...this.state.filters,
          status: toggleListData[index].id == 4 ? "" : toggleListData[index].id, // status id
        },
      },
      () => {
        this.callGetErrorLogsListApi();
      }
    );
  };

  onMailToChange = (value, name) => {
    const field = name;
    if (JSON.stringify(value).length <= 2 && this.state.isValidationsOn) {
      this.setState({
        fields: { ...this.state.fields, [field]: value },
        emailError: "Field can not be empty",
      });
    } else {
      this.setState({
        fields: { ...this.state.fields, [field]: value },
        emailError: "",
      });
    }
  };

  onRemarksChange = (value) => {
    console.log("VAL : ", value);

    if (value.length > 500) {
      var croppedValue = value.substring(0, 500);
      console.log("VAL : ", croppedValue);

      this.setState({
        remark: croppedValue,
        remarksError: "Maximum 500 characters allowed",
      });
    } else {
      this.setState({ remark: value, remarksError: "" });
    }
  };

  userViewAction = (data) => {
    const index = data.findIndex(
      (data) => data.createdBy === this.props.loginUser?.data?.id
    );
    return index !== -1 ? data[index] : {};
  };

  onViewClick = (id) => {
    this.props.viewErrorLogs(id, (viewedData) => {
      const reamrkObject = this.userViewAction(
        viewedData?.errorLogActivities ?? []
      );
      this.setState({
        viewErrorLogData: viewedData,
        viewUserRemark: reamrkObject,
        showViewErrorLogPopup: true,
      });
    });
  };

  onEscalateActionClick = (rowId) => {
    console.log("onEscalateActionClick");
    this.props.viewErrorLogs(rowId, (viewedData) => {
      const reamrkObject = this.userViewAction(
        viewedData?.errorLogActivities ?? []
      );
      this.setState({
        viewErrorLogData: viewedData,
        viewUserRemark: reamrkObject,
        showUpdateErrorLogPopup: true,
      });
    });
  };

  onInvestigateActionClick = (rowId) => {
    this.props.viewErrorLogs(rowId, (viewedData) => {
      const reamrkObject = this.userViewAction(
        viewedData?.errorLogActivities ?? []
      );
      this.setState({
        viewErrorLogData: viewedData,
        viewUserRemark: reamrkObject,
        showUpdateErrorLogPopup: true,
      });
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isValidationsOn: false,
      showUpdateErrorLogPopup: false,
      emailError: "",
      remarksError: "",
      remark: "",
      fields: { ...this.state.fields, email: "" },
    });
  };

  closeAllPopups = () => {
    this.setState({
      showViewErrorLogPopup: false,
    });
  };

  onSearchClick = (text) => {
    console.log("onSearchClick.....");

    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetErrorLogsListApi();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetErrorLogsListApi();
      }
    );
  };

  onSendEmailButtonClick = () => {
    this.setState({ isValidationsOn: true }); // on change validation on only when user click on confirm button
    if (this.validateData()) {
      const rowId = this.state.viewErrorLogData.id;
      const data = {
        to: this.state.fields.email, // "to" variable as per api body
        remark: this.state.remark,
        severity: this.state.viewErrorLogData.severity,
        closeErrorLog: false,
      };
      this.props.updateErrorLogs(rowId, data, () => {
        this.callGetErrorLogsListApi();
        this.setState({
          showUpdateErrorLogPopup: false,
          fields: { ...this.state.fields, email: "" },
          remark: "",
        });
      });
    }
  };

  onSendEmailAndCloseButtonClick = () => {
    this.setState({ isValidationsOn: true }); // on change validation on only when user click on confirm button
    if (this.validateData()) {
      const rowId = this.state.viewErrorLogData.id;
      const data = {
        to: this.state.fields.email, // "to" variable as per api body
        remark: this.state.remark,
        severity: this.state.viewErrorLogData.severity,
        closeErrorLog: true,
      };
      this.props.updateErrorLogs(rowId, data, () => {
        this.callGetErrorLogsListApi();
        this.setState({
          showUpdateErrorLogPopup: false,
          fields: { ...this.state.fields, email: "" },
          remark: "",
        });
      });
    }
  };

  validateData = () => {
    var isErrorInData = false;

    var custom = [validator.email(["email"])];
    var fields = this.state.fields;
    const validatorFields = {
      required: ["email"],
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );
    console.log("errors", errors);
    if (!Object.keys(errors).length) {
      this.setState({ emailError: "" });
      isErrorInData = false;
    } else {
      this.setState({ emailError: errors.email });
      isErrorInData = true;
    }

    if (isErrorInData) {
      return false;
    } else {
      return true;
    }
  };

  //-------------------------- Data and Pagination ---------------------------------

  populateListData = (listData, count, toggleListData) => {
    this.setState({
      errorLogList: listData,
      totalerrorLogs: count,
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.props.filteredErrorLogsCount;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
      },
      () => {
        this.callGetErrorLogsListApi();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetErrorLogsListApi();
      }
    );
  };
  //sorting filters

  onErrorLogListFilterClick = (column, filterOption) => {
    // const filterOption = value?.props?.children ?? "";
    console.log("columnName", column, "filterOption", filterOption);

    let severityFilter =
      filterOption === "Low"
        ? 1
        : filterOption === "Medium"
        ? 2
        : filterOption === "High"
        ? 3
        : "";

    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          severity: parseInt(severityFilter),
        },
      },
      () => {
        this.props.getErrorLogsListWithFilter(
          this.state.filters,
          (listData, count) => {
            console.log("updated varifying", !listData?.length > 0);
            this.populateListData(listData, count);
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onErrorLogListSortClick = (column) => {
    let columnName = "";
    if (column === "Error Log ID") {
      columnName = "errorLogId";
    } else if (column === "Device ID") {
      columnName = "deviceId";
    } else if (column === "Device Type") {
      columnName = "deviceType";
    } else if (column === "Hospital") {
      columnName = "hospital";
    } else if (column === "Center") {
      columnName = "center";
    } else if (column === "Reported On") {
      columnName = "reportedOn";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getErrorLogsListWithFilter(
          this.state.filters,
          (listData, count) => {
            this.populateListData(listData, count);
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <ErrorLogsView
        userRole={this.state.userRole}
        page={this.state.page}
        limit={this.state.limit}
        totalErrors={this.props.filteredErrorLogsCount}
        viewErrorLogData={this.state.viewErrorLogData}
        showViewErrorLogPopup={this.state.showViewErrorLogPopup}
        errorLogList={this.state.errorLogList}
        viewUserRemark={this.state.viewUserRemark}
        //
        onRemarksChange={this.onRemarksChange}
        onMailToChange={this.onMailToChange}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        onSendEmailAndCloseButtonClick={this.onSendEmailAndCloseButtonClick}
        onSendEmailButtonClick={this.onSendEmailButtonClick}
        //
        toggleData={this.state.toggleData}
        toggleValue={this.state.toggleValue}
        onToggleChange={this.onToggleChangeErrorLogForTechnician}
        toggleHeader={this.state.toggleHeader}
        //
        onEscalateActionClick={this.onEscalateActionClick}
        onInvestigateActionClick={this.onInvestigateActionClick}
        to={this.state.to}
        emailError={this.state.emailError}
        fields={this.state.fields}
        remark={this.state.remark}
        remarksError={this.state.remarksError}
        showUpdateErrorLogPopup={this.state.showUpdateErrorLogPopup}
        onCancelButtonClick={this.onCancelButtonClick}
        //
        closeAllPopups={this.closeAllPopups}
        onViewClick={this.onViewClick}
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        //
        onErrorLogListFilterClick={this.onErrorLogListFilterClick}
        onErrorLogListSortClick={this.onErrorLogListSortClick}
      />
    );
  }
}

ErrorLogsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    filteredErrorLogsCount: state.errorLogs.filteredErrorLogsCount,
    filteredErrorLogsList: state.errorLogs.filteredErrorLogsList,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  getErrorLogsListWithFilter,
  viewErrorLogs,
  toggleDataForErrorLogs,
  updateErrorLogs,
})(ErrorLogsContainer);
