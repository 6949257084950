import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getDoctorsListWithFilter,
  addNewDoctor,
  getDoctorById,
  editDoctor,
  deleteDoctor,
  deleteDoctorInBulk,
  resetGetDoctorById,
} from "../../../../store/doctor/doctorActions";
import { unlockUser } from "../../../../store/master/masterActions";
import DoctorsView from "./DoctorsView";
import { trimFormData } from "../../../../utils/common";
import * as validator from "../../../../utils/validator";
import { resetUserPasswordByAdmin } from "../../../../store/auth/authActions";
import {
  getHospitalsListWithFilter,
  getReportingHospitalsListWithFilter,
} from "../../../../store/hospital/hospitalActions";
import { strings } from "../../../../constants/stringConstants";

class DoctorsContainer extends Component {
  validatorFieldsForDoctor = {};
  validatorFieldsForResetPassword = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForDoctor["required"] = [
      "firstName",
      "lastName",
      "qualification",
      "msci_id",
      "mobile",
      "email",
      "password",
      "confrimPassword1",
      "hospitals",
    ];

    this.validatorFieldsForResetPassword["required"] = [
      "password",
      "confirmPassword",
    ];

    this.state = {
      hospitalListPage: 1,

      pageCount: 2,
      searchHospitalText: "",
      hospitals: null,

      fields: {
        firstName: "",
        lastName: "",
        qualification: "",
        msci_id: "",
        mobile: "",
        email: "",
        password: "",
        confrimPassword1: "",
      },
      //reset password
      resetPasswordDoctorFields: {
        password: "",
        confirmPassword: "",
      },

      doctorInfo: null,
      searchReportHospitalText: "",
      onEditFlag: false,
      isValidationsOn: false,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      doctorList: [],
      searchHospitalList: [],
      totalDoctors: 0,
      doctorInfo: {},
      // Search
      filters: {
        text: "",
        doctorStatus: "",
      },
      errors: {},
      // Popup
      noScrollHospital: false,
      clearSelectedRows: false,
      showAddNewDoctorPopup: false,
      showDeleteDoctorPopup: false,
      showDeleteBulkDoctorPopup: false,
      selectedIdsToDelete: [],
      showReportingHospitalFlag: false,
      showResetPasswordDoctorPopup: false,
      showUnlockDoctorConfirmationPopup: false,
    };

    this.callGetDoctorsAPI = this.callGetDoctorsAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onDeleteDoctorClick = this.onDeleteDoctorClick.bind(this);
    this.onDeleteDoctorConfirmClick =
      this.onDeleteDoctorConfirmClick.bind(this);
    this.onCloseDeleteDoctorPopup = this.onCloseDeleteDoctorPopup.bind(this);
    this.onEditDoctorClick = this.onEditDoctorClick.bind(this);
    this.onAddNewDoctorClick = this.onAddNewDoctorClick.bind(this);
    this.resetFields = this.resetFields.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.onAddSaveButton = this.onAddSaveButton.bind(this);
    this.onEditSaveButton = this.onEditSaveButton.bind(this);
    this.onHospitalSearchChange = this.onHospitalSearchChange.bind(this);
    this.onScrollHospitalListDropdown =
      this.onScrollHospitalListDropdown.bind(this);
    this.onAssignHospitalChange = this.onAssignHospitalChange.bind(this);
    this.onEditPopulateData = this.onEditPopulateData.bind(this);
    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkDoctorDeleteClick = this.onBulkDoctorDeleteClick.bind(this);
    this.onDeleteBulkDoctorClick = this.onDeleteBulkDoctorClick.bind(this);
    this.onCloseDeleteBulkDoctorPopup =
      this.onCloseDeleteBulkDoctorPopup.bind(this);
    this.onCancelAddButton = this.onCancelAddButton.bind(this);
    this.onCancelEditButton = this.onCancelEditButton.bind(this);

    this.onResetPasswordDoctorFieldChange =
      this.onResetPasswordDoctorFieldChange.bind(this);
    this.onResetPasswordDoctorClick =
      this.onResetPasswordDoctorClick.bind(this);
    this.onCloseResetPasswordDoctorPopup =
      this.onCloseResetPasswordDoctorPopup.bind(this);
    this.onResetPasswordDoctorConfirmClick =
      this.onResetPasswordDoctorConfirmClick.bind(this);

    this.onUnlockDoctorAccountClick =
      this.onUnlockDoctorAccountClick.bind(this);
    this.closeUnlockDoctorPopup = this.closeUnlockDoctorPopup.bind(this);
    this.onUnlockDoctorAccountConfirmClick =
      this.onUnlockDoctorAccountConfirmClick.bind(this);

    this.onDoctorListFilterClick = this.onDoctorListFilterClick.bind(this);
    this.onDoctorListSortClick = this.onDoctorListSortClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.callGetDoctorsAPI();

    var previousState = this.props.location?.state ?? {};

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;

    this.setState({ page: prevPage, limit: prevLimit }, () => {
      window.history.replaceState({}, "");

      this.callGetHospitalListAPI();
    });
  }

  componentWillUnmount() {
    this.props.resetGetDoctorById();
  }

  componentWillReceiveProps(newProps) {
    this.populateListData(newProps.filteredDoctorsList);
  }

  //--------------------------pop up dialog controls--------------------

  onAddNewDoctorClick = () => {
    this.setState({ showAddNewDoctorPopup: true });
  };

  resetFields = () => {
    this.setState({
      ...this.state,
      fields: {
        firstName: "",
        lastName: "",
        qualification: "",
        msci_id: "",
        mobile: "",
        email: "",
        password: "",
        confrimPassword1: "",
      },
      // onEditFlag: false,
      searchHospitalList: [],
      searchReportHospitalText: "",
      // filters: {
      //   text: "",
      // },
      doctorInfo: null,
      onEditFlag: false,

      doctorList: [],
      noScrollHospital: false,

      pageCount: 2,

      errors: {},

      clearSelectedRows: false,
      // showAddNewDoctorPopup: false,
      showDeleteDoctorPopup: false,
      selectedIdsToDelete: [],
      showReportingHospitalFlag: false,
    });
  };

  onCancelAddButton = () => {
    this.setState(
      { showAddNewDoctorPopup: false, searchReportHospitalText: "" },
      () => {
        this.resetFields();
        this.callGetDoctorsAPI();
        this.callGetHospitalListAPI();
      }
    );
  };
  onCancelEditButton = () => {
    this.setState({ onEditFlag: false, searchReportHospitalText: "" }, () => {
      this.resetFields();
      this.callGetDoctorsAPI();
      this.callGetHospitalListAPI();
    });
  };

  onEditDoctorClick = (row) => {
    this.setState({ onEditFlag: true });

    this.props.getDoctorById(row.id, (data) => {
      this.onEditPopulateData(data);
    });
  };

  onDeleteDoctorClick = (row) => {
    if (row) {
      // this.props.deleteDoctor(Did, () => {});
      this.setState({ doctorInfo: row, showDeleteDoctorPopup: true });
    }
  };

  onDeleteBulkDoctorClick = () => {
    this.setState({ showDeleteBulkDoctorPopup: true });
  };

  onDeleteDoctorConfirmClick = () => {
    this.props.deleteDoctor(this.state.doctorInfo?.id, (data) => {
      this.setState({ showDeleteDoctorPopup: false }, () => {
        this.callGetDoctorsAPI();
      });
    });
  };

  onCloseDeleteDoctorPopup = () => {
    this.setState({ showDeleteDoctorPopup: false });
  };

  onCloseDeleteBulkDoctorPopup = () => {
    this.setState({ showDeleteBulkDoctorPopup: false });
  };

  //--------------------------Data and pagination----------------------

  callGetDoctorsAPI = () => {
    this.props.getDoctorsListWithFilter(
      this.state.filters,
      (listData, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(listData);
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  populateListData = (List) => {
    var doctorListData = List; // from redux store

    var noOfDoctorAvailable =
      doctorListData && doctorListData.length ? doctorListData.length : 0;
    this.setState({
      doctorList: doctorListData,
      totalDoctors: noOfDoctorAvailable,
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalDoctors;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetDoctorsAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetDoctorsAPI();
      }
    );
  };

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetDoctorsAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetDoctorsAPI();
      }
    );
  };

  //-------------------------- Add Edit Delete Operations----------------------

  callGetHospitalListAPI = () => {
    this.props.getReportingHospitalsListWithFilter(
      {
        text: this.state.searchReportHospitalText,
      },
      (list, count, metaData) => {
        const listData = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });

        if (
          (this.state.onEditFlag || this.state.showAddNewDoctorPopup) &&
          listData?.length == 0
        ) {
          this.setState({
            showReportingHospitalFlag: true,
          });
        } else {
          this.setState({
            pageCount: 2,
            searchHospitalList: listData,
            // showReportingHospitalFlag: listData?.length > 0 ? false : true,
          });
        }
      },
      this.state.hospitalListPage,
      25,
      { reportService: true }
    );
  };

  onScrollHospitalListDropdown = () => {
    if (!this.state.noScrollHospital) {
      this.props.getReportingHospitalsListWithFilter(
        {
          text: this.state.searchReportHospitalText,
        },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              return { label: item.hospitalName, value: item.id };
            });
            this.setState({
              searchHospitalList: [
                ...this.state.searchHospitalList,
                ...listData,
              ],
            });
            ++this.state.pageCount;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCount, // // direct call from 2nd page because 1st page alredy added
        // this.state.limit
        25,
        { reportService: true }
      );
    }
  };

  onHospitalSearchChange = (text) => {
    this.state.searchHospitalText = text;

    this.setState(
      {
        ...this.state,
        // filters: { ...this.state.filters, text: this.state.searchHospitalText },
        searchHospitalText: text,
        noScrollHospital: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  // onAssignHospitalChange = (Object) => {

  //   if (Object !== null && Object !== undefined) {
  //     this.state.hospitals.push(Object);
  //     this.setState(
  //       {
  //         filters: { ...this.state.filters, text: "" },
  //         searchHospitalText: "",
  //       },
  //       () => {
  //         this.callGetHospitalListAPI();
  //       }
  //     );
  //   }
  // };

  onAssignHospitalChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            hospitals: value ?? "",
          },

          searchReportHospitalText: "",
          // noScrollHospital: false,
        },
        () => {
          this.callGetHospitalListAPI();
        }
      );
      this.validatorFieldsForDoctor["required"] = this.validatorFieldsForDoctor[
        "required"
      ].filter(function (item) {
        return item !== "hospitals";
      });
      this.state.errors["hospitals"] = "";
    } else {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            hospitals: "",
          },

          searchReportHospitalText: "",
          onloadReportingHospitalDataFlag: false,
          // noScrollHospital: false,
        },
        () => {
          this.callGetHospitalListAPI();
        }
      );
      this.validatorFieldsForDoctor["required"].push("hospitals");
    }
  };

  onResetPasswordDoctorFieldChange = (field) => (e) => {
    let value;
    const { resetPasswordDoctorFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired =
      this.validatorFieldsForResetPassword.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      resetPasswordDoctorFields: {
        ...resetPasswordDoctorFields,
        [field]: value,
      },
    });
  };

  onResetPasswordDoctorConfirmClick = () => {
    var custom = [
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
    ];

    this.validatorFieldsForResetPassword["custom"] = custom;
    const { resetPasswordDoctorFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForResetPassword,
      trimFormData(resetPasswordDoctorFields)
    );
    if (
      resetPasswordDoctorFields.confirmPassword.length > 0 &&
      resetPasswordDoctorFields.password !==
        resetPasswordDoctorFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }

    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      console.log("doctorInfo", this.state.doctorInfo);
      const data = {
        userId: this.state.doctorInfo?.userId,
        newPassword: resetPasswordDoctorFields.password.trim(),
      };
      this.props.resetUserPasswordByAdmin(data, () => {
        this.setState({
          showResetPasswordDoctorPopup: false,
          resetPasswordDoctorFields: {
            ...this.state.resetPasswordDoctorFields,
            password: "",
            confirmPassword: "",
          },
          isValidationsOn: false,
          errors: {},
        });
      });
    }
  };

  onCloseResetPasswordDoctorPopup = () => {
    this.setState({
      showResetPasswordDoctorPopup: false,
      resetPasswordDoctorFields: {
        ...this.state.resetPasswordDoctorFields,
        password: "",
        confirmPassword: "",
      },
      isValidationsOn: false,
      errors: {},
    });
  };

  onResetPasswordDoctorClick = (row) => {
    this.setState({ doctorInfo: row, showResetPasswordDoctorPopup: true });
  };

  onHospitalSearchChange = (text) => {
    this.state.searchHospitalText = text;
    this.setState(
      {
        ...this.state,
        searchReportHospitalText: text,
        noScrollHospital: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  handleChange = (field) => (e) => {
    let value;
    const { fields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired = this.validatorFieldsForDoctor.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  onEditSaveButton = () => {
    const { fields } = this.state;

    var custom = [];
    var required = [];

    required = [
      "firstName",
      "lastName",
      "qualification",
      "msci_id",
      "mobile",

      "hospitals",
    ];
    if (fields.mobile) custom.push(validator.phone(["mobile"]));

    const validatorFieldsForDoctor = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFieldsForDoctor,
      trimFormData(fields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      this.props.editDoctor(this.props.doctorDataById?.id, fields, (data) => {
        // this.callGetDoctorsAPI();

        if (data?.message == "Doctor details updated successfully") {
          this.onCancelEditButton();
        }
      });
    }
  };

  onEditPopulateData = (data) => {
    this.setState({
      ...this.state,
      fields: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        qualification: data?.qualification,
        msci_id: data?.registrationId,
        mobile: data?.contactNumber,
        email: data?.user?.userName,

        hospitals: this.state.searchHospitalList.find((item) => {
          return item.value == data?.linkHospitalToDoctor[0]?.hospitalId;
        }),
      },
    });
  };

  onAddSaveButton = () => {
    const { fields } = this.state;

    var custom = [];
    var required = [];

    required = [
      "firstName",
      "lastName",
      "qualification",
      "msci_id",
      "mobile",
      "email",
      "password",
      "confrimPassword1",
      "hospitals",
    ];

    if (fields.email) custom.push(validator.email(["email"]));
    if (fields.password) {
      custom.push(validator.StrongPassword(["password"]));
      custom.push(validator.PasswordWithoutSpaces(["password"]));
      custom.push(validator.password(["password"]));
    }
    // if (fields.confrimPassword1)
    //   custom.push(validator.password(["confrimPassword1"]));
    if (fields.mobile) custom.push(validator.phone(["mobile"]));
    console.log("custom", custom);
    const validatorFieldsForDoctor = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFieldsForDoctor,
      trimFormData(fields)
    );
    if (
      fields?.confrimPassword1?.length >= 0 &&
      fields.password !== fields.confrimPassword1
    ) {
      errors["confrimPassword1"] = strings.PASSWORD_MATCH_ERROR;
    }

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      this.props.addNewDoctor(fields, (data) => {
        if (data?.id) {
          this.onCancelAddButton();
        }
      });
    }
  };
  //
  onUnlockDoctorAccountClick = (row) => {
    this.setState({ doctorInfo: row, showUnlockDoctorConfirmationPopup: true });
  };

  onUnlockDoctorAccountConfirmClick = () => {
    this.props.unlockUser(this.state.doctorInfo.userId, () => {
      this.setState(
        {
          doctorInfo: {},
          showUnlockDoctorConfirmationPopup: false,
        },
        () => {
          this.callGetDoctorsAPI();
        }
      );
    });
  };

  closeUnlockDoctorPopup = () => {
    this.setState({
      doctorInfo: {},
      showUnlockDoctorConfirmationPopup: false,
    });
  };

  //-------------------- Delete Bulk Administrators ---------------------------

  handleSelectedRowsChange = (selectedRowsArray, state) => {
    var doctorIds = selectedRowsArray.map((item) => {
      return item.id;
    });

    this.setState({ selectedIdsToDelete: doctorIds });
  };

  onBulkDoctorDeleteClick = () => {
    this.props.deleteDoctorInBulk(
      { doctorIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          showDeleteBulkDoctorPopup: false,
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
        });
        this.callGetDoctorsAPI();
      }
    );
  };

  //sorting filters

  onDoctorListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    let filter =
      filterOption === "Active"
        ? 1
        : filterOption === "Occupied"
        ? 2
        : filterOption === "Inactive"
        ? 3
        : "";
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          doctorStatus: parseInt(filter),
        },
      },
      () => {
        this.props.getDoctorsListWithFilter(
          this.state.filters,
          (listData, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onDoctorListSortClick = (column) => {
    let columnName = "";
    if (column === "Doctor Name") {
      columnName = "firstName";
    } else if (column === "MSCI ID") {
      columnName = "msci";
    } else if (column === "Added On") {
      columnName = "addedOn";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getDoctorsListWithFilter(
          this.state.filters,
          (listData, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <DoctorsView
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        totalDoctors={this.props.filteredDoctorCount}
        doctorsList={this.state.doctorList}
        //
        onEditDoctorClick={this.onEditDoctorClick}
        onDeleteDoctorClick={this.onDeleteDoctorClick}
        //
        showAddNewDoctorPopup={this.state.showAddNewDoctorPopup}
        onAddNewDoctorClick={this.onAddNewDoctorClick}
        //
        resetFields={this.resetFields}
        //
        resetPasswordDoctorFields={this.state.resetPasswordDoctorFields}
        showResetPasswordDoctorPopup={this.state.showResetPasswordDoctorPopup}
        //
        onResetPasswordDoctorClick={this.onResetPasswordDoctorClick}
        onResetPasswordDoctorConfirmClick={
          this.onResetPasswordDoctorConfirmClick
        }
        onCloseResetPasswordDoctorPopup={this.onCloseResetPasswordDoctorPopup}
        onResetPasswordDoctorFieldChange={this.onResetPasswordDoctorFieldChange}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        //
        fields={this.state.fields}
        handleChange={this.handleChange}
        onAddSaveButton={this.onAddSaveButton}
        errors={this.state.errors}
        onHospitalSearchChange={this.onHospitalSearchChange}
        onScrollHospitalListDropdown={this.onScrollHospitalListDropdown}
        onAssignHospitalChange={this.onAssignHospitalChange}
        searchHospitalText={this.state.searchHospitalText}
        // hospitals={this.state.hospitals}
        searchHospitalList={this.state.searchHospitalList}
        showReportingHospitalFlag={this.state.showReportingHospitalFlag}
        onEditFlag={this.state.onEditFlag}
        onEditSaveButton={this.onEditSaveButton}
        doctorDataById={this.props.doctorDataById}
        onEditPopulateData={this.onEditPopulateData}
        clearSelectedRows={this.state.clearSelectedRows}
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onBulkDoctorDeleteClick={this.onBulkDoctorDeleteClick}
        onDeleteDoctorConfirmClick={this.onDeleteDoctorConfirmClick}
        onCloseDeleteDoctorPopup={this.onCloseDeleteDoctorPopup}
        showDeleteDoctorPopup={this.state.showDeleteDoctorPopup}
        doctorInfo={this.state.doctorInfo}
        showDeleteBulkDoctorPopup={this.state.showDeleteBulkDoctorPopup}
        onCloseDeleteBulkDoctorPopup={this.onCloseDeleteBulkDoctorPopup}
        onDeleteBulkDoctorClick={this.onDeleteBulkDoctorClick}
        onCancelAddButton={this.onCancelAddButton}
        onCancelEditButton={this.onCancelEditButton}
        //
        showUnlockDoctorConfirmationPopup={
          this.state.showUnlockDoctorConfirmationPopup
        }
        onUnlockDoctorAccountConfirmClick={
          this.onUnlockDoctorAccountConfirmClick
        }
        closeUnlockDoctorPopup={this.closeUnlockDoctorPopup}
        onUnlockDoctorAccountClick={this.onUnlockDoctorAccountClick}
        onDoctorListFilterClick={this.onDoctorListFilterClick}
        //
        onDoctorListSortClick={this.onDoctorListSortClick}
      />
    );
  }
}

DoctorsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    filteredDoctorsList: state.doctor.filteredDoctorsList,
    filteredDoctorCount: state.doctor.filteredDoctorCount,
    doctorDataById: state.doctor.doctorDataById,
  };
};

export default connect(mapStateToProps, {
  getDoctorsListWithFilter,
  showAlert,
  addNewDoctor,
  getHospitalsListWithFilter,
  getDoctorById,
  editDoctor,
  deleteDoctor,
  deleteDoctorInBulk,
  getReportingHospitalsListWithFilter,
  resetGetDoctorById,
  resetUserPasswordByAdmin,
  unlockUser,
})(DoctorsContainer);
