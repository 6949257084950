import React, { useEffect } from "react";
import { CardView } from "../../../../controls";
import userPlaceholder from "../../../../../assets/images/icons/user-placeholder.png";
import { getWelcomeGreetings } from "../../../../../utils/common";
import { getState } from "../../../../../store/configure/configureStore";
import "./CardStyles.scss";

const StatisticsCardCenterAdmin = (props) => {
  // useEffect(() => {
  //   console.log("STATISTICS CARD CA: ", props);
  // }, [props.lastUpdatedTimestamp]);

  var firstName = getState().auth.loginUser?.userProfile?.firstName ?? "";
  var lastName = getState().auth.loginUser?.userProfile?.lastName ?? "";

  firstName = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
  lastName = `${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;

  var userName = firstName + " " + lastName;
  userName = userName.trim() === "" ? "user, " : `${userName}, `;

  var firstNameInitial = firstName.charAt(0).toUpperCase();
  var lastNameInitial = lastName.charAt(0).toUpperCase();

  var profilePicUrl = getState().auth.loginUser?.data?.profilePicture ?? null;

  return (
    <CardView
      extraStyle="center-admin-statistics-card"
      bodyStyle={{ minHeight: "110px" }}
    >
      <div className="card-container">
        <div className="card-icon-header">
          {profilePicUrl !== null ? (
            <div className="card-image">
              <img
                src={profilePicUrl}
                alt={`${firstNameInitial}${lastNameInitial}`} // When URL is broken / not working
                className="img_preview"
              />
            </div>
          ) : (
            <div className="card-image">
              {`${firstNameInitial}${lastNameInitial}`}
            </div>
          )}

          <div className="card-head">
            <div className="title">
              {getWelcomeGreetings()} {userName} have a great day!
            </div>
            <div className="subtitle">
              Role: Center Admin | Assigned Since : {props.userCreatedAt} |
              Center: {props.centerName}
            </div>
          </div>
        </div>

        <div className="card-info">
          <div className="info">
            <text className="text">Active Technicians</text>
            <text className="number">{props.technicians}</text>
          </div>

          <div className="vertical"></div>

          <div className="info">
            <text className="text">Devices</text>
            <text className="number">{props.devices}</text>
          </div>

          <div className="vertical"></div>

          <div className="info">
            <text className="text">Reports Sent</text>
            <text className="number">{props.sentReports}</text>
          </div>

          <div className="vertical"></div>

          <div className="info">
            <text className="text">Reports Pending Response</text>
            <text className="number">{props.pendingReports}</text>
          </div>
        </div>
      </div>
    </CardView>
  );
};

export default StatisticsCardCenterAdmin;
