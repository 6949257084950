import React, { useCallback, useEffect } from "react";
// import PropTypes from "prop-types";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import PropTypes from "prop-types";
import "./PaginationNew.scss";
const PaginationNew = ({
  rowCount,
  rowsPerPage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
  currentPage,
  onChangePage,
  displayDescription = true,
  displayRowsPerPageDropdown = true,
  extraStyle = undefined,
}) => {
  const handlePrevious = useCallback(
    () => onChangePage(currentPage - 1),
    [currentPage, onChangePage]
  );

  const handleNext = useCallback(
    () => onChangePage(currentPage + 1),
    [currentPage, onChangePage]
  );

  const handleFirst = useCallback(() => onChangePage(1), [onChangePage]);

  const handleRowsPerPage = useCallback(
    ({ target }) => onChangeRowsPerPage(Number(target.value), currentPage),
    [currentPage, onChangeRowsPerPage]
  );

  const numberOfPages = Math.ceil(rowCount / rowsPerPage);

  const handleLast = useCallback(
    () => onChangePage(numberOfPages),
    [onChangePage, numberOfPages]
  );

  useEffect(() => {
    // console.log("ROWS PER PAGE , CURRENT PAGE : ", rowsPerPage, currentPage);
  }, [rowsPerPage]);

  //-----------------------page options ------------------
  const pageOptions = paginationRowsPerPageOptions.map((item) => {
    return { label: item, target: { value: item } };
  });

  return (
    <div className={`page ${numberOfPages === 1 ? "" : ""}`} style={extraStyle}>
      {displayRowsPerPageDropdown && (
        <p className="pagTextdropdown">
          Rows per page{" "}
          <select
            className="rows-per-page-selector"
            onChange={(e) => handleRowsPerPage(e)}
            value={rowsPerPage}
          >
            {pageOptions.map((option, index) => (
              <option className="page-option" key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </p>
      )}

      <div style={{ width: "15px" }} />

      {displayDescription && (
        <p className="pagText">
          {currentPage === 1
            ? `1 - ${Math.min(rowsPerPage, rowCount)} of ${rowCount}`
            : `${(currentPage - 1) * rowsPerPage + 1} - ${Math.min(
                currentPage * rowsPerPage,
                rowCount
              )} of ${rowCount}`}
        </p>
      )}

      <div style={{ width: "15px" }} />

      <button
        className={`btn`}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        <FaChevronLeft className="pagIcon" />
      </button>

      <button
        className={`btn`}
        onClick={handleNext}
        disabled={currentPage === numberOfPages}
      >
        <FaChevronRight className="pagIcon" />
      </button>
    </div>
  );
};

PaginationNew.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PaginationNew;
