import React, { useState } from "react";
import { CardView, Select, ThemedButton } from "../../../../controls";
import {
  PopupDialog,
  EcgReportsList,
  StatisticsCardTechnician,
  EcgReportsCardTechnician,
} from "../../Components";
import "../DashboardStyles.scss";

const TechnicianView = (props) => {
  /*

  const deviceList = props.filteredDeviceList.map((item) => {
    return { value: item.id, label: item.serialNo };
  });

  function renderDeviceCheckInPopup() {
    return (
      <div className="report-popup-container">
        <Select
          label={"Device Serial Number : "}
          onChange={(val) => {
            props.onDeviceSelected(val);
            console.log(val, "seleted");
          }}
          options={deviceList}
        />
      </div>
    );
  }

  const renderCheckInWarningMessage = () => {
    return (
      <CardView extraStyle="dashboard-bar-chart" bodyStyle={{ padding: "0px" }}>
        <div className="warning-for-device-checkin">
          <text className="me-2">Please Check-In with a Device! </text>
          <ThemedButton
            title={"Check In"}
            styles="primary medium"
            onClick={props.onCheckInButtonClick}
          />
        </div>
      </CardView>
    );
  };

  */

  return (
    <div className="new-dashboard-container">
      {/* {!props.isCheckedInOnDevice && !props.showDeviceCheckInPopup
        ? renderCheckInWarningMessage()
        : null} */}

      {/* {props.isCheckedInOnDevice ? (
        <> */}

      <CardView extraStyle="dashboard-bar-chart" bodyStyle={{ padding: "0px" }}>
        <StatisticsCardTechnician
          loginUser={props.loginUser}
          userCreatedAt={props.userCreatedAt}
          serialNo={props.serialNo}
          totalReports={props.totalReports}
          pendingReports={props.pendingReports}
        />
      </CardView>

      <CardView extraStyle="dashboard-bar-chart" bodyStyle={{ padding: "0px" }}>
        <EcgReportsCardTechnician
          data={props.ecgBarChartData}
          filtersData={props.ecgReportsFilters}
          filterOptionSelected={props.ecgReportsFilterSelected}
          onFilterOptionChange={props.onEcgReportsFilterChange}
          // areaChartLabel={props.areaChartLabel}
        />
      </CardView>
      {/* </>
      ) : null} */}

      {/* <PopupDialog
        show={props.showDeviceCheckInPopup}
        title={"Select Device"}
        showCloseIcon={true}
        renderBodyContent={renderDeviceCheckInPopup}
        confirmButtonTitle={"Check In"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeviceCheckInCofirmClick}
        onclickCancel={props.onDeviceCheckInCancelClick}
        popupSize={"md"}
      /> */}
    </div>
  );
};

export default TechnicianView;
