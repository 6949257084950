import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";

import { roles } from "../../../../constants/roles";
import { history } from "../../../../store/configure/configureStore";

import * as validator from "../../../../utils/validator";
import HospitalInfoView from "./HospitalInfoView";
import { globalConstants } from "../../../../constants/globalConstants";
import {
  getHospitalAdminsDataFromId,
  getHospitalAdminsList,
} from "../../../../store/administrators/adminActions";
import {
  getHospitalDetailsById,
  getReportingHospitalsListWithFilter,
  updateHospitalInfoById,
  resetGetHospitalDetailsById,
} from "../../../../store/hospital/hospitalActions";
import {
  getState,
  getDistrict,
  getPincode,
  getCity,
  uploadImage,
  deleteImage,
} from "../../../../store/master/masterActions";
import { trimFormData } from "../../../../utils/common";
const Initdata = {
  reportingName: "",
};
class HospitalInfoContainer extends Component {
  validatorFields = {};
  constructor(props) {
    super(props);

    this.validatorFields["required"] = [
      "hospitalName",
      "addressLine1",
      "state",
      "district",
      "pincode",
      "contactNumber",
    ];

    this.state = {
      fields: {
        hospitalName: "",
        contactNumber: "",
        addressLine1: "",
        location: "",
        state: "",
        district: "",
        hospitalLogo: "",
        pincode: "",
        village: "",
        landmark: "",
        latitude: "",
        longitude: "",
        // newReportingName: "",
        reportService: false,
        ...Initdata,
      },

      isValidationsOn: false,

      filters: {
        text: "",
      },
      hospitalLogoFlag: false,
      pushedReportingHospitalId: "",
      pageCountReportingHospital: 2,
      reportingHospitalListPage: 1,
      noScrollHospital: false,
      searchReportHospitalText: "",
      TabData: null,
      hospitalList: [],
      stateList: [],
      districtList: [],
      reportingHospitalList: [],
      pincodeList: [],
      arearData: null,
      errors: {},
      //
      hospitalTypeData: [
        { value: 1, label: "Privately Owned" },
        { value: 2, label: "Government" },
      ],
      HospitalHubTypeToggleData: [
        { id: 1, name: "Hub A" },
        { id: 2, name: "Hub B" },
      ],
      hospitalType: "",
      hubType: "",
      isHub: false,
      isSpoke: false,
      isGovernment: false,
      showHospitalHubTypeToggle: false,
    };
    if (this.props.loginUser?.userProfile?.id) {
      this.props.getHospitalAdminsDataFromId(
        this.props.loginUser?.userProfile?.id,
        (data) => {
          this.setState({
            hospitalList: data.hospitalList,
          });
        }
      );
    }

    // this.props.getHospitalDetailsById(props.hospitalList[0]?.hospitalId, () => {});
    // this.callGetHospitalListAPI = this.callGetHospitalListAPI.bind(this);
    this.handleTabData = this.handleTabData.bind(this);

    this.onReportingHospitalSearchChange =
      this.onReportingHospitalSearchChange.bind(this);
    this.onReportingHospitalChange = this.onReportingHospitalChange.bind(this);
    this.onScrollReportingHospitalList =
      this.onScrollReportingHospitalList.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onDistrictChange = this.onDistrictChange.bind(this);
    this.onPincodeChange = this.onPincodeChange.bind(this);
    this.callGetDistrictListAPI = this.callGetDistrictListAPI.bind(this);

    this.onGovermentOrPrivateChange =
      this.onGovermentOrPrivateChange.bind(this);
    this.onSpokeCheckBoxChange = this.onSpokeCheckBoxChange.bind(this);
    this.onChangeHubHospitalType = this.onChangeHubHospitalType.bind(this);
    this.onHubCheckBoxChange = this.onHubCheckBoxChange.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.props.getState((list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });

      this.setState({ stateList: newList });
    });
    this.callGetOnlyReportingHospitalListAPI();
  }

  componentWillUnmount() {
    this.props.resetGetHospitalDetailsById();
  }
  handleTabData = (data) => {
    this.setState({
      ...this.state,
      fields: {
        hospitalName: "",
        contactNumber: "",
        addressLine1: "",
        latitude: "",
        longitude: "",
        location: "",
        state: "",
        district: "",
        hospitalLogo: "",
        pincode: "",
        village: "",
        landmark: "",
        reportingName: "",
        reportService: false,
      },
      hospitalType: "",
      hubType: "",
      isHub: false,
      isSpoke: false,
      isGovernment: false,
      showHospitalHubTypeToggle: false,

      errors: {},
      TabData: data,
      // onloadReportingHospitalDataFlag: true,
    });
  };

  callGetDistrictListAPI = (stateId) => {
    this.props.getDistrict({ stateId: stateId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });
      this.setState({ districtList: newList });
    });
  };

  callGetPincodeListAPI = (districtId) => {
    this.props.getPincode({ districtId: districtId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: `${item.pincode}` };
      });
      this.setState({ pincodeList: newList });
    });
  };

  onScrollReportingHospitalList = () => {
    if (!this.state.noScrollHospital) {
      this.props.getReportingHospitalsListWithFilter(
        this.state.filters,
        (list) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              if (this.state.pushedReportingHospitalId !== item.id) {
                return { label: item.hospitalName, value: item.id };
              }
            });
            const newListData = listData.filter(function (item) {
              return item !== undefined;
            });
            this.setState({
              reportingHospitalList: [
                ...this.state.reportingHospitalList,
                ...newListData,
              ],
            });
            ++this.state.pageCountReportingHospital;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCountReportingHospital, // direct call from 2nd page bcoz 1st page alredy added
        25
      );
    }
  };

  callGetOnlyReportingHospitalListAPI = () => {
    this.props.getReportingHospitalsListWithFilter(
      {
        text: this.state.searchReportHospitalText,
      },
      (list) => {
        const newList = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({
          reportingHospitalList: newList,
        });
      },
      this.state.reportingHospitalListPage,
      25
    );
  };

  onReportingHospitalSearchChange = (text) => {
    this.state.searchReportHospitalText = text;
    this.setState(
      {
        searchReportHospitalText: text,
        noScrollHospital: false,
      },
      () => {
        this.callGetOnlyReportingHospitalListAPI();
      }
    );
  };

  //------------------------------------
  onHubCheckBoxChange = (e) => {
    const target = e.target;
    if (target.checked) {
      this.setState({ isHub: true, showHospitalHubTypeToggle: true });
    } else {
      this.setState({
        isHub: false,
        showHospitalHubTypeToggle: false,
      });
    }
  };

  onChangeHubHospitalType = (index) => {
    console.log("onChangeHubHospitalType", index);
    this.setState({
      hubType: this.state.HospitalHubTypeToggleData.find(
        (item) => item.id === index
      ),
    });
  };

  onSpokeCheckBoxChange = (e) => {
    const target = e.target;
    if (target.checked) {
      this.setState({ isSpoke: true });
    } else {
      this.setState({ isSpoke: false });
    }
  };

  onGovermentOrPrivateChange = (value) => {
    this.setState({ isGovernment: value.label === "Government" });
  };
  //--------------------------------------

  onHandlePopulateHospitalByIdData = (TabData, hospitalList) => {
    const { fields } = this.state;

    if (TabData?.hospitalId || hospitalList[0]?.hospitalId) {
      this.props.getHospitalDetailsById(
        TabData ? TabData?.hospitalId : hospitalList[0]?.hospitalId,
        (data) => {
          console.log("dataaaaaa", data?.hospital?.isSpoke);
          const stateData = this.state.stateList.find((item) => {
            return item?.value == data?.hospital?.addressInfo?.stateId;
          });

          this.props.getDistrict(
            { stateId: stateData?.value },
            (destrictList) => {
              const newDestrictList = destrictList.map((item) => {
                return { value: item.id, label: item.name };
              });
              const districtData = newDestrictList.find((item) => {
                return item?.value == data?.hospital?.addressInfo?.districtId;
              });

              this.props.getPincode(
                { districtId: districtData?.value },
                (pincodeList) => {
                  const newPincodeList = pincodeList.map((item) => {
                    return { value: item.id, label: `${item.pincode}` };
                  });

                  const pincodeData = newPincodeList.find((item) => {
                    return item.value == data?.hospital?.addressInfo?.pincodeId;
                  });

                  const newReportingName1 =
                    this.state.reportingHospitalList?.find((item) => {
                      return data?.hospital?.reportingHospitalId == item?.value;
                    });
                  const hubHospitalType =
                    this.state.HospitalHubTypeToggleData.find(
                      (item) =>
                        item.name ==
                        (data?.hospital?.hubType == "A"
                          ? "Hub A"
                          : data?.hospital?.hubType == "B"
                          ? "Hub B"
                          : "Hub A")
                    );
                  this.setState({
                    ...this.state,
                    pincodeList: newPincodeList,
                    districtList: newDestrictList,
                    fields: {
                      ...fields,
                      hospitalName: data?.hospital?.hospitalName
                        ? data?.hospital?.hospitalName
                        : "",

                      contactNumber: data?.hospital?.addressInfo?.mobile
                        ? data?.hospital?.addressInfo?.mobile
                        : "",
                      addressLine1: data?.hospital?.addressInfo?.addressLineOne,
                      addressLine2: data?.hospital?.addressInfo?.addressLineTwo
                        ? data?.hospital?.addressInfo?.addressLineTwo
                        : "",
                      location: data?.hospital?.addressInfo?.locationName
                        ? data?.hospital?.addressInfo?.locationName
                        : "",
                      village: data?.hospital?.addressInfo?.areaInfo,

                      landmark: data?.hospital?.addressInfo?.landmark
                        ? data?.hospital?.addressInfo?.landmark
                        : "",
                      hospitalLogo: data?.hospital?.logo,
                      reportService: data?.hospital?.reportService,
                      state: stateData,
                      district: districtData,
                      pincode: pincodeData,
                      reportingName: newReportingName1,
                      latitude:
                        data?.hospital?.addressInfo?.latitude?.toString() ?? "",
                      longitude:
                        data?.hospital?.addressInfo?.longitude?.toString() ??
                        "",
                    },
                    isHub: data?.hospital?.isHub,
                    showHospitalHubTypeToggle:
                      data?.hospital?.isHub == true ? true : false,
                    hubType: hubHospitalType,
                    isSpoke: data?.hospital?.isSpoke,
                    isGovernment: data?.hospital?.isGovernment,
                    hospitalType:
                      data?.hospital?.isGovernment == true
                        ? this.state.hospitalTypeData[1]
                        : this.state.hospitalTypeData[0],
                    errors: {},
                  });
                }
              );
            }
          );
        }
      );
    }
  };

  getDistrictList = (stateId) => {
    this.props.getDistrict({ stateId: stateId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });
      this.setState({ districtList: newList });
    });
  };

  onStateChange = (value) => {
    const { fields } = this.state;
    if (value !== null && value !== undefined) {
      this.props.getDistrict({ stateId: value?.value }, (districtList) => {
        const newDestrictList = districtList.map((item) => {
          return { value: item.id, label: item.name };
        });

        this.setState({
          ...this.state,

          districtList: newDestrictList,
          fields: {
            ...fields,
            state: value,
            district: "",
            pincode: "",
            village: "",
            // reportService: false,
          },
          pincodeList: [],
          // districtList: [],
        });
      });
      this.state.errors["state"] = "";
      // this.callGetDistrictListAPI(value.value);
    } else {
      this.setState({
        fields: {
          ...fields,
          state: "",
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
        districtList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["state"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("state");
    }
  };

  onDistrictChange = (value) => {
    const { fields } = this.state;
    if (value !== null && value !== undefined) {
      this.props.getPincode({ districtId: value?.value }, (pincodeList) => {
        const newPincodeList = pincodeList.map((item) => {
          return { value: item?.id, label: `${item?.pincode}` };
        });

        this.setState({
          ...this.state,

          pincodeList: newPincodeList,
          fields: { ...fields, district: value, pincode: "", village: "" },
        });
      });
      this.state.errors["district"] = "";
    } else {
      this.setState({
        fields: {
          ...fields,
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["district"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("district");
    }
  };

  onPincodeChange = (value) => {
    const { fields } = this.state;

    if (value !== null && value !== undefined) {
      this.props.getCity({ pincodeId: value.value }, (areaData) => {
        this.setState({
          ...this.state,
          fields: {
            // pincode: value,
            // village: areaData,
            ...fields,
            pincode: value,
            village: areaData,
          },
        });
      });
      this.state.errors["pincode"] = "";
    } else {
      this.setState({
        fields: {
          ...fields,
          pincode: "",
          village: "",
        },
        selectedVillage: {},
      });
      if (this.state.isValidationsOn) {
        this.state.errors["pincode"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("pincode");
    }
  };

  handleChange = (field) => (e) => {
    let value;
    const { fields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired = this.validatorFields.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  onCheckChange = (field) => (e) => {
    let value;
    const { fields, errors } = this.state;
    value = e.target.checked;

    if (field == "reportService" && value == false) {
      this.setState({
        ...this.state,
        fields: {
          ...fields,
          reportingName: "",
        },
      });
    }

    if (e) {
      this.setState((prevState) => ({
        ...prevState,
        fields: { ...prevState.fields, [field]: value },
      }));
    }
  };

  onReportingHospitalChange = (value) => {
    var hospitalIdCompare = this.state.TabData?.hospitalId
      ? parseInt(this.state.TabData?.hospitalId)
      : parseInt(this.state.hospitalList[0]?.hospitalId);
    const { fields } = this.state;

    if (value !== null && value !== undefined) {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            reportingName: value ?? "",
            reportService: parseInt(value?.value) === hospitalIdCompare && true,
          },
          filters: { ...this.state.filters, text: "" },
          searchReportHospitalText: "",
          noScrollHospital: false,
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );

      this.validatorFields["required"] = this.validatorFields[
        "required"
      ].filter(function (item) {
        return item !== "reportingName";
      });
      this.state.errors["reportingName"] = "";
    } else {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            reportingName: "",
          },
          filters: { ...this.state.filters, text: "" },
          searchReportHospitalText: "",
          noScrollHospital: false,
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );
      this.validatorFields["required"].push("reportingName");
    }
  };

  onCancelClick = () => {
    history.push("/dashboard_new");
  };

  onSave = () => {
    const { fields } = this.state;

    var custom = [];
    var required = [];
    required = [
      "hospitalName",
      "addressLine1",
      "state",
      "district",
      "pincode",
      "contactNumber",
    ];

    if (fields.contactNumber) custom.push(validator.phone(["contactNumber"]));

    if (
      fields?.reportService == false &&
      (fields?.reportingName == "" || fields?.reportingName == undefined)
    ) {
      required = [...required, "reportingName"];
    }

    const validatorFields = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      fields["isHub"] = this.state.isHub;
      fields["isGovernment"] = this.state.isGovernment;
      fields["isSpoke"] = this.state.isSpoke;
      fields["hubType"] = this.state.hubType;
      this.props.updateHospitalInfoById(
        this.state.TabData?.hospitalId
          ? this.state.TabData?.hospitalId
          : this.state.hospitalList[0]?.hospitalId,
        fields,
        () => {}
      );
    }
  };

  onHandleReset = () => {
    const { hospitalList, TabData } = this.state;
    // if (TabData) {
    //   this.props.getHospitalDetailsById(TabData?.hospitalId, () => {});
    // } else {
    //   if (hospitalList[0]?.hospitalId) {
    //     this.props.getHospitalDetailsById(
    //       hospitalList[0]?.hospitalId,
    //       () => {}
    //     );
    //   }
    // }
    if (TabData || hospitalList) {
      this.onHandlePopulateHospitalByIdData(TabData, hospitalList);
    }
  };

  closeViewHospitalLogo = () => {
    this.setState({
      ...this.state,
      hospitalLogoFlag: false,
    });
  };
  viewHospitalLogoClicked = () => {
    this.setState({
      ...this.state,
      hospitalLogoFlag: true,
    });
  };

  onHandleUploadHospitalLogoImage = (data) => {
    const { fields } = this.state;
    this.props.uploadImage(
      data,
      globalConstants.HOSPITAL_LOGO_IMAGE,
      (imageUrl) => {
        if (imageUrl !== null) {
          this.setState({
            ...this.state,
            fields: {
              ...fields,
              hospitalLogo: imageUrl,
            },
          });
        }
      },
      this.state.TabData?.hospitalId
        ? this.state.TabData?.hospitalId
        : this.state.hospitalList[0]?.hospitalId,
      true
    );
  };

  deleteHospitalLogoClicked = (data) => {
    const { fields } = this.state;
    this.props.deleteImage(
      this.state.TabData?.hospitalId
        ? this.state.TabData?.hospitalId
        : this.state.hospitalList[0]?.hospitalId,
      globalConstants.HOSPITAL_LOGO_IMAGE,
      (isSuccess) => {
        if (isSuccess) {
          this.setState({
            ...this.state,
            fields: {
              ...fields,
              hospitalLogo: null,
            },
          });
        }
      }
    );
  };

  render() {
    return (
      <HospitalInfoView
        errors={this.state.errors}
        hospitalName={this.state.fields.hospitalName}
        contactNumber={this.state.fields.contactNumber}
        addressLine1={this.state.fields.addressLine1}
        addressLine2={this.state.fields.addressLine2}
        reportingName={this.state.fields.reportingName}
        location={this.state.fields.location}
        state={this.state.fields.state}
        district={this.state.fields.district}
        hospitalLogo={this.state.fields.hospitalLogo}
        pincode={this.state.fields.pincode}
        village={this.state.fields.village}
        landmark={this.state.fields.landmark}
        latitude={this.state.fields.latitude}
        longitude={this.state.fields.longitude}
        reportService={this.state.fields.reportService}
        handleTabData={this.handleTabData}
        hospitalList={this.state.hospitalList}
        TabData={this.state.TabData}
        HospitalDetails={this.props.HospitalDetails}
        onHandlePopulateHospitalByIdData={this.onHandlePopulateHospitalByIdData}
        closeViewHospitalLogo={this.closeViewHospitalLogo}
        viewHospitalLogoClicked={this.viewHospitalLogoClicked}
        stateList={this.state.stateList}
        // handleSelectChange={this.handleSelectChange}
        districtList={this.state.districtList}
        pincodeList={this.state.pincodeList}
        handleChange={this.handleChange}
        onCheckChange={this.onCheckChange}
        onCancelClick={this.onCancelClick}
        onHandleReset={this.onHandleReset}
        onSave={this.onSave}
        reportingHospitalList={this.state.reportingHospitalList}
        onReportingHospitalChange={this.onReportingHospitalChange}
        onReportingHospitalSearchChange={this.onReportingHospitalSearchChange}
        searchReportHospitalText={this.state.searchReportHospitalText}
        onScrollReportingHospitalList={this.onScrollReportingHospitalList}
        hospitalLogoFlag={this.state.hospitalLogoFlag}
        onHandleUploadHospitalLogoImage={this.onHandleUploadHospitalLogoImage}
        deleteHospitalLogoClicked={this.deleteHospitalLogoClicked}
        onStateChange={this.onStateChange}
        onDistrictChange={this.onDistrictChange}
        onPincodeChange={this.onPincodeChange}
        //
        onGovermentOrPrivateChange={this.onGovermentOrPrivateChange}
        onSpokeCheckBoxChange={this.onSpokeCheckBoxChange}
        onChangeHubHospitalType={this.onChangeHubHospitalType}
        onHubCheckBoxChange={this.onHubCheckBoxChange}
        //
        hospitalTypeData={this.state.hospitalTypeData}
        HospitalHubTypeToggleData={this.state.HospitalHubTypeToggleData}
        hospitalType={this.state.hospitalType}
        hubType={this.state.hubType}
        isHub={this.state.isHub}
        isSpoke={this.state.isSpoke}
        isGovernment={this.state.isGovernment}
        showHospitalHubTypeToggle={this.state.showHospitalHubTypeToggle}
      />
    );
  }
}

HospitalInfoContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    HospitalDetails: state.hospital.HospitalDetails,
    // filteredHospitalList: state.hospital.filteredHospitalList,
    // filteredHospitalCount: state.hospital.filteredHospitalCount,
    // selectedHospital: state.hospital.selectedHospital,
  };
};

export default connect(mapStateToProps, {
  //   showLoader,
  getHospitalAdminsDataFromId,
  getHospitalDetailsById,
  getState,
  getDistrict,
  getPincode,
  getCity,
  getReportingHospitalsListWithFilter,
  updateHospitalInfoById,
  uploadImage,
  deleteImage,
  resetGetHospitalDetailsById,
  //   hideLoader,
})(HospitalInfoContainer);
