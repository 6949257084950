import React from "react";
import { DataTable } from "../../../../common";
import { PaginationNew, ThemedButton } from "../../../../controls";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { roles } from "../../../../../constants/roles";
import { FaCircleCheck, FaDownload, FaSort } from "react-icons/fa6";
import moment from "moment";
import "./ListStyles.scss";

const EcgReportsDynamicList = (props) => {
  const data = props.reportList;
  const userRole = props.userRole;

  // console.log("ECG Report Dynamic list : ", JSON.stringify(data));

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onECGReportDoctorDashboardListSortClick !== null
                  ? () => props.onECGReportDoctorDashboardListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderReportNumber = (label, isNew) => {
    return (
      <div className="report_number-wrapper">
        <text className="me-3">{label}</text>
        {/* {isNew ? <div className="new-tag">New</div> : null} */}

        {props.newRecords.some((recordId) => recordId == label) ? (
          <div className="new-tag">New</div>
        ) : null}
      </div>
    );
  };

  const renderAction = (reportId) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="Accept"
          styles="link"
          onClick={
            props.onReportAcceptClick !== null
              ? () => props.onReportAcceptClick(reportId)
              : () => {}
          }
        />

        {/* <div className="partition-block"/>
          <ThemedButton
            title="Reject"
            styles="link"
            onClick={
              props.onReportRejectClick !== null
                ? () => props.onReportRejectClick(reportId)
                : () => {}
            }
          />
        */}
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // ---------------------  All Columns ------------------------

  var columns = [
    {
      name: renderHeader("Record ID", false, false),
      id: "report_id",
      center: false,
      sortable: false,
      selector: (row) =>
        renderReportNumber(row?.id ?? "-", row.isNew ? true : false),
    },
    {
      name: renderHeader("Device ID", false, false),
      id: "report_id",
      center: false,
      sortable: false,
      selector: (row) => row?.deviceInfo?.serialNo ?? "-",
    },
    {
      name: renderHeader("Data Recorded Date", false, false),
      id: "data_recorded_date",
      center: false,
      sortable: false,
      selector: (row) =>
        moment(row?.dtRecReceivedOnServer).format("YYYY-MM-DD") ?? "-",
    },
    {
      name: renderHeader("Data Recorded Time", false, false),
      id: "data_recorded_time",
      center: false,
      sortable: false,
      selector: (row) =>
        moment(row?.dtRecReceivedOnServer).format("h:mm A") ?? "-",
    },
    {
      name: renderActionHeader("Action", true),
      id: "accept_action",
      center: false,
      sortable: false,
      selector: (row) => renderAction(row.id),
    },
  ];

  return (
    <div className="ecg-reports-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          selectableRows={false}
          selectableRowsComponent={BootyCheckbox}
          onSelectedRowsChange={(state) => {}}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalReports}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default EcgReportsDynamicList;
