import React from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "../../../../common";
import { FaSlash, FaSort } from "react-icons/fa6";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineRedo } from "react-icons/ai";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import "./ListStyles.scss";
import { BsThreeDotsVertical } from "react-icons/bs";

const AdmintratorsList = (props) => {
  // passing props to data variable
  var unLockFlag = true;
  const data = props.data;
  console.log("data", data);

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onHospitalAdminListSortClick !== null
                  ? () => props.onHospitalAdminListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true, center = false) => {
    console.log("renderActionHeader", label);
    return (
      <div className={`custom-column-header ${center ? "header-text" : ""}`}>
        <text>{label}</text>
        <div className="sortIcon">
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderEditDeleteResetPasswordAction = (row) => {
    const Options = [
      { label: "Edit", icon: <AiOutlineEdit size={18} /> },
      { label: "Delete", icon: <AiOutlineDelete size={18} /> },
      { label: "Reset Password", icon: <AiOutlineRedo size={18} /> },
    ];

    return (
      <IconMenuOption
        row={row}
        options={Options}
        menuIcon={<BsThreeDotsVertical size={22} />}
        onclickEdit={props.onEditHospitalAdminClick}
        onclickDelete={props.onDeleteHospitalAdminClick}
        onclickResetPassword={props.onResetPasswordHospitalAdminClick}
      />
    );
  };

  const renderUnlockButton = (row) => {
    return (
      <div>
        <ThemedButton
          title="Unlock"
          styles="link "
          onClick={
            props.onUnlockHospitalAdminAccountClick !== null
              ? () => props.onUnlockHospitalAdminAccountClick(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // Columns info with row value to use (row : single object in data array)
  const columns = [
    {
      name: renderHeader("Administrator Name", false, true),
      id: "adminstrator_name",
      center: false,
      sortable: false,
      selector: (row) => (
        <text
          style={{
            color: "#00B48A",
            fontSize: "14px",
            fontWeight: "400",
            cursor: "pointer",
          }}
          onClick={() => {
            props.onAdminSelected(row);
          }}
        >
          {(row?.firstName !== undefined && row?.firstName !== null
            ? `${row.firstName.charAt(0).toUpperCase()}${row.firstName.slice(
                1
              )}`
            : "") +
            " " +
            (row?.lastName !== undefined && row?.lastName !== null
              ? `${row.lastName.charAt(0).toUpperCase()}${row.lastName.slice(
                  1
                )}`
              : "")}
        </text>
      ),
      style: {
        color: "#00B48A",
        fontSize: "14px",
        fontWeight: "400",
        cursor: "pointer",
      },
    },
    {
      name: renderHeader("No. of Hospitals"),
      id: "no_of_hospitals",
      center: false,
      sortable: false,
      selector: (row) => row?.currentAdminsMetaData?.hospitalsCount ?? "0",
    },
    {
      name: renderHeader("No. of Centres"),
      id: "no_of_centres",
      center: false,
      sortable: false,
      selector: (row) => row?.currentAdminsMetaData?.centersCount ?? "0",
    },
    {
      name: renderHeader("No. of Devices"),
      id: "no_of_devices",
      center: false,
      sortable: false,
      selector: (row) => row?.currentAdminsMetaData?.devicesCount ?? "0",
    },
    {
      name: renderActionHeader("Action", true, true),
      id: "action",
      center: true,
      selector: (row) =>
        row?.user?.is_locked
          ? renderUnlockButton(row)
          : renderEditDeleteResetPasswordAction(row),
    },
  ];

  return (
    <div className="adminstrator-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows, state)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalAdmins}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default AdmintratorsList;
