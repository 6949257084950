import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import logo from "../../../assets/images/Logo.png";
import { AiOutlineMenu } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import Sidebar from "../SideBar/Sidebar";
import { HOME, LOGIN } from "../../../navigation/routes";
import { NavLink, Link } from "react-router-dom";
import { logout } from "../../../store/auth/authActions";
import { FaSignOutAlt } from "react-icons/fa";
import "./TopNavBarStyles.scss";

const TopNavBar = ({ logout, loginUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onLogOut = () => {
    logout();
  };

  const userName = loginUser?.data.userName;
  const userRole =
    loginUser?.data?.id == "363"
      ? "Reporting Hospital Admin"
      : loginUser?.data?.userRoles[0].name; //363
  return (
    <>
      <Navbar
        //  className="top-navbar p-0"
        className={
          loginUser !== null &&
          loginUser.data.userRoles[0].name !== "Technician" &&
          loginUser.data.userRoles[0].name !== "Doctor"
            ? "top-navbar p-0"
            : "top-navbar-full-width"
        }
      >
        <Container>
          <Navbar.Brand href="#home">
            {loginUser !== null &&
            loginUser.data.userRoles[0].name !== "Technician" &&
            loginUser.data.userRoles[0].name !== "Doctor" ? null : (
              <div>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    height: "50px",
                    width: "100%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginLeft: "20px",
                    // marginTop: "10px",
                    // marginBottom: "10px",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </Navbar.Brand>
          <Nav className="me-auto">
            <div className="user_info">
              <div className="d-flex flex-column">
                <span>
                  <BiSolidUser className="me-1" /> {userName}
                </span>
                <span className="text-end" style={{ fontSize: "12px" }}>
                  {`( ${userRole} )`}
                </span>
              </div>

              {(loginUser !== null &&
                loginUser.data.userRoles[0].name === "Technician") ||
              (loginUser !== null &&
                loginUser.data.userRoles[0].name === "Doctor") ? (
                <div
                  className="ms-3"
                  onClick={() => {
                    onLogOut();
                  }}
                >
                  <FaSignOutAlt color="red" />
                  <span
                    className="ms-1"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    Logout
                  </span>
                </div>
              ) : null}
            </div>
          </Nav>
        </Container>
        <div className="menu-icon">
          <AiOutlineMenu
            size={25}
            color="#FFFF"
            onClick={() => {
              // console.log("test");
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </Navbar>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default TopNavBar;
