import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  getTechnicianListWithFilter,
  deleteTechnicianInBulk,
  addNewTechnician,
  editTechnician,
  deleteTechnician,
  getTechnicianDetailsById,
} from "../../../../store/technician/technicianActions";
import { unlockUser } from "../../../../store/master/masterActions";
import { resetUserPasswordByAdmin } from "../../../../store/auth/authActions";
import * as validator from "../../../../utils/validator";
import { trimFormData } from "../../../../utils/common";
import { showAlert } from "../../../../store/alert/alertActions";
import TechniciansView from "./TechniciansView";
import { strings } from "../../../../constants/stringConstants";

class TechniciansContainer extends Component {
  validatorFieldsForTechnician = {};
  validatorFieldsForResetPassword = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForTechnician["required"] = [
      "firstName",
      "lastName",
      "phoneNo",
      "email",
      "password",
      "confirmPassword",
    ];

    this.validatorFieldsForResetPassword["required"] = [
      "password",
      "confirmPassword",
    ];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      technicianList: [],
      totalTechnicians: 0,
      //
      showAddNewTechniciansPopup: false,
      showDeleteTechnicianPopup: false,
      showEditTechnicianPopup: false,
      showBulkDeleteTechnicianPopup: false,
      showResetPasswordTechnicianPopup: false,
      showUnlockTechnicianConfirmationPopup: false,

      //add technician
      addTechnicianFields: {
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      //reset password
      resetPasswordTechnicianFields: {
        password: "",
        confirmPassword: "",
      },
      adminCenterId: "",
      isValidationsOn: false,
      technicianInfo: {},

      selectedIdsToDelete: [],
      clearSelectedRows: false,

      // Search filters
      filters: {
        text: "",
        checkIn: null,
      },
      //
      errors: {},
    };

    this.callGetTechnicianListAPI = this.callGetTechnicianListAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.onAddNewTechniciansClick = this.onAddNewTechniciansClick.bind(this);
    this.closeAllPopups = this.closeAllPopups.bind(this);
    //
    this.onAddTechnicianFieldChange =
      this.onAddTechnicianFieldChange.bind(this);
    this.onAddNewTechnicianConfirmClick =
      this.onAddNewTechnicianConfirmClick.bind(this);
    this.onCloseAddTechnicianPopup = this.onCloseAddTechnicianPopup.bind(this);

    this.onEditTechnicianClick = this.onEditTechnicianClick.bind(this);
    this.onEditTechnicianConfirmClick =
      this.onEditTechnicianConfirmClick.bind(this);
    this.onCloseEditTechnicianPopup =
      this.onCloseEditTechnicianPopup.bind(this);

    this.onResetPasswordTechnicianFieldChange =
      this.onResetPasswordTechnicianFieldChange.bind(this);
    this.onResetPasswordTechnicianClick =
      this.onResetPasswordTechnicianClick.bind(this);
    this.onCloseResetPasswordTechnicianPopup =
      this.onCloseResetPasswordTechnicianPopup.bind(this);
    this.onResetPasswordTechnicianConfirmClick =
      this.onResetPasswordTechnicianConfirmClick.bind(this);

    this.onDeleteTechnicianClick = this.onDeleteTechnicianClick.bind(this);
    this.onDeleteTechnicianConfirmClick =
      this.onDeleteTechnicianConfirmClick.bind(this);
    this.onCloseDeleteTechnicianPopup =
      this.onCloseDeleteTechnicianPopup.bind(this);

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkTechnicianDeleteClick =
      this.onBulkTechnicianDeleteClick.bind(this);
    this.onBulkTechnicianDeleteConfirmClick =
      this.onBulkTechnicianDeleteConfirmClick.bind(this);

    this.onTechnicianListFilterClick =
      this.onTechnicianListFilterClick.bind(this);
    this.onTechnicianListSortClick = this.onTechnicianListSortClick.bind(this);

    this.resetAddTechnicianFields = this.resetAddTechnicianFields.bind(this);

    this.onUnlockTechnicianAccountClick =
      this.onUnlockTechnicianAccountClick.bind(this);
    this.closeUnlockTechnicianPopup =
      this.closeUnlockTechnicianPopup.bind(this);
    this.onUnlockTechnicianAccountConfirmClick =
      this.onUnlockTechnicianAccountConfirmClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    const centerID =
      this.props.loginUser?.userProfile?.centerList[0]?.centerId ?? "";
    this.setState({ userRole: loginUserRole, adminCenterId: centerID });
    this.callGetTechnicianListAPI();
    console.log("userRole", this.props.loginUser);
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    //TODO
  }

  //--------------------------- Get Devices List API Call ------------------------

  callGetTechnicianListAPI = () => {
    this.props.getTechnicianListWithFilter(
      this.state.filters,
      (list, count, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(list, count);
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  populateListData = (listData, count) => {
    console.log("technicianListData : ", JSON.stringify(listData));

    this.setState({
      technicianList: listData,
      totalTechnicians: count,
    });
  };

  resetAddTechnicianFields = () => {
    this.setState({
      addTechnicianFields: {
        ...this.state.addTechnicianFields,
        firstName: "",
        lastName: "",
        phoneNo: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
      showEditTechnicianPopup: false,
      showAddNewTechniciansPopup: false,
      isValidationsOn: false,
    });
  };

  onAddTechnicianFieldChange = (field) => (e) => {
    let value;
    const { addTechnicianFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired = this.validatorFieldsForTechnician.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      addTechnicianFields: { ...addTechnicianFields, [field]: value },
    });
  };

  onResetPasswordTechnicianFieldChange = (field) => (e) => {
    let value;
    const { resetPasswordTechnicianFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired =
      this.validatorFieldsForResetPassword.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      resetPasswordTechnicianFields: {
        ...resetPasswordTechnicianFields,
        [field]: value,
      },
    });
  };

  //------------------Pop up dialog controls-------------
  onAddNewTechniciansClick = () => {
    this.setState({ showAddNewTechniciansPopup: true });
  };

  onAddNewTechnicianConfirmClick = () => {
    var custom = [
      validator.email(["email"]),
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
      validator.phone(["phoneNo"]),
    ];

    this.validatorFieldsForTechnician["custom"] = custom;
    const { addTechnicianFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForTechnician,
      trimFormData(addTechnicianFields)
    );
    console.log("confirm", addTechnicianFields.confirmPassword);
    if (
      addTechnicianFields.confirmPassword.length > 0 &&
      addTechnicianFields.password !== addTechnicianFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }
    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        email: addTechnicianFields.email,
        password: addTechnicianFields.password,
        firstName: addTechnicianFields.firstName,
        lastName: addTechnicianFields.lastName,
        contactNumber: addTechnicianFields.phoneNo,
        centerId: this.state.adminCenterId,
      };
      console.log("add tech data", data);
      this.props.addNewTechnician(data, (response) => {
        if (response !== null) {
          this.resetAddTechnicianFields();
          this.callGetTechnicianListAPI();
        }
      });
    }
  };

  onCloseAddTechnicianPopup = () => {
    this.setState({
      showAddNewTechniciansPopup: false,
      errors: {},
      isValidationsOn: false,
    });
    this.resetAddTechnicianFields();
  };

  closeAllPopups = () => {
    this.setState({
      showAddNewTechniciansPopup: false,
    });
  };

  //--------------------- List Action Button Clicks --------------------

  onEditTechnicianClick = (row) => {
    this.validatorFieldsForTechnician["required"] =
      this.validatorFieldsForTechnician["required"].filter(function (item) {
        return item !== "password" && item !== "confirmPassword";
      });
    console.log("before req", this.validatorFieldsForTechnician["required"]);
    this.props.getTechnicianDetailsById(row.id, (details) => {
      console.log("details", details);
      this.setState({
        showEditTechnicianPopup: true,
        isValidationsOn: true,
        technicianInfo: row,
        addTechnicianFields: {
          ...this.state.addTechnicianFields,
          firstName: details?.firstName ?? "",
          lastName: details?.lastName ?? "",
          phoneNo: details?.contactNumber ?? "",
          email: details?.user?.userName ?? "",
        },
      });
    });
  };

  onEditTechnicianConfirmClick = () => {
    var custom = [validator.phone(["phoneNo"])];

    this.validatorFieldsForTechnician["custom"] = custom;
    const { addTechnicianFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForTechnician,
      trimFormData(addTechnicianFields)
    );
    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        firstName: addTechnicianFields.firstName,
        lastName: addTechnicianFields.lastName,
        contactNumber: addTechnicianFields.phoneNo,
        centerId: this.state.adminCenterId,
      };
      console.log("add tech data", data);
      this.props.editTechnician(
        this.state.technicianInfo.id,
        data,
        (response) => {
          if (response !== null) {
            this.resetAddTechnicianFields();
            this.callGetTechnicianListAPI();
            this.validatorFieldsForTechnician["required"].push("password");
            this.validatorFieldsForTechnician["required"].push(
              "confirmPassword"
            );
            console.log(
              "after req",
              this.validatorFieldsForTechnician["required"]
            );
          }
        }
      );
    }
  };

  onCloseEditTechnicianPopup = () => {
    this.setState({ showEditTechnicianPopup: false });
    this.validatorFieldsForTechnician["required"].push("password");
    this.validatorFieldsForTechnician["required"].push("confirmPassword");

    this.resetAddTechnicianFields();
  };

  onDeleteTechnicianClick = (row) => {
    console.log("row details", row);
    this.setState({ technicianInfo: row, showDeleteTechnicianPopup: true });
  };

  onResetPasswordTechnicianClick = (row) => {
    this.setState({
      technicianInfo: row,
      showResetPasswordTechnicianPopup: true,
    });
  };

  onResetPasswordTechnicianConfirmClick = () => {
    var custom = [
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
    ];

    this.validatorFieldsForResetPassword["custom"] = custom;
    const { resetPasswordTechnicianFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForResetPassword,
      trimFormData(resetPasswordTechnicianFields)
    );
    if (
      resetPasswordTechnicianFields.confirmPassword.length > 0 &&
      resetPasswordTechnicianFields.password !==
        resetPasswordTechnicianFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }

    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        userId: this.state.technicianInfo?.userId,
        newPassword: resetPasswordTechnicianFields.password.trim(),
      };
      this.props.resetUserPasswordByAdmin(data, () => {
        this.setState({
          resetPasswordTechnicianFields: {
            ...this.state.resetPasswordTechnicianFields,
            password: "",
            confirmPassword: "",
          },
          showResetPasswordTechnicianPopup: false,
          isValidationsOn: false,
          errors: {},
        });
      });
    }
  };

  onCloseResetPasswordTechnicianPopup = () => {
    this.setState({
      showResetPasswordTechnicianPopup: false,
      resetPasswordTechnicianFields: {
        ...this.state.resetPasswordTechnicianFields,
        password: "",
        confirmPassword: "",
      },
      errors: {},
    });
  };

  onDeleteTechnicianConfirmClick = () => {
    this.props.deleteTechnician(this.state.technicianInfo.id, () => {
      this.setState({ showDeleteTechnicianPopup: false });
      this.callGetTechnicianListAPI();
    });
  };

  onCloseDeleteTechnicianPopup = () => {
    this.setState({
      showDeleteTechnicianPopup: false,
      showBulkDeleteTechnicianPopup: false,
    });
  };

  handleSelectedRowsChange = (selectedRowsArray) => {
    var technicianIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({ selectedIdsToDelete: technicianIds });
  };

  onBulkTechnicianDeleteClick = () => {
    this.setState({ showBulkDeleteTechnicianPopup: true });
  };

  onBulkTechnicianDeleteConfirmClick = () => {
    this.props.deleteTechnicianInBulk(
      { technicianIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showBulkDeleteTechnicianPopup: false,
        });
        this.callGetTechnicianListAPI();
      }
    );
  };

  //

  onUnlockTechnicianAccountClick = (row) => {
    this.setState({
      technicianInfo: row,
      showUnlockTechnicianConfirmationPopup: true,
    });
  };

  onUnlockTechnicianAccountConfirmClick = () => {
    this.props.unlockUser(this.state.technicianInfo.userId, () => {
      this.setState(
        {
          technicianInfo: {},
          showUnlockTechnicianConfirmationPopup: false,
        },
        () => {
          this.callGetTechnicianListAPI();
        }
      );
    });
  };

  closeUnlockTechnicianPopup = () => {
    this.setState({
      TechnicianInfo: {},
      showUnlockTechnicianConfirmationPopup: false,
    });
  };
  //

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalTechnicians;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
      },
      () => {
        this.callGetTechnicianListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetTechnicianListAPI();
      }
    );
  };

  //------------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetTechnicianListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetTechnicianListAPI();
      }
    );
  };

  //sorting filters

  onTechnicianListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    let filter =
      filterOption === "Active"
        ? true
        : filterOption === "Inactive"
        ? false
        : null;
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          checkIn: filter,
        },
      },
      () => {
        this.props.getTechnicianListWithFilter(
          this.state.filters,
          (list, count, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(list, count);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onTechnicianListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Technician Name") {
      columnName = "name";
    } else if (column === "Assigned Device") {
      columnName = "assignedDevice";
    } else if (column === "Added On") {
      columnName = "addedOn";
    } else if (column === "Device Type") {
      columnName = "deviceType";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getTechnicianListWithFilter(
          this.state.filters,
          (list, count, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(list, count);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <TechniciansView
        userRole={this.state.userRole}
        //
        technicianList={this.state.technicianList}
        totalTechnicians={this.props.filteredTechnicianCount}
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        //
        showAddNewTechniciansPopup={this.state.showAddNewTechniciansPopup}
        onAddNewTechniciansClick={this.onAddNewTechniciansClick}
        //
        onAddTechnicianFieldChange={this.onAddTechnicianFieldChange}
        onAddNewTechnicianConfirmClick={this.onAddNewTechnicianConfirmClick}
        onCloseAddTechnicianPopup={this.onCloseAddTechnicianPopup}
        //
        onResetPasswordTechnicianFieldChange={
          this.onResetPasswordTechnicianFieldChange
        }
        resetPasswordTechnicianFields={this.state.resetPasswordTechnicianFields}
        //
        addTechnicianFields={this.state.addTechnicianFields}
        technicianInfo={this.state.technicianInfo}
        errors={this.state.errors}
        //
        showEditTechnicianPopup={this.state.showEditTechnicianPopup}
        onEditTechnicianClick={this.onEditTechnicianClick}
        onEditTechnicianConfirmClick={this.onEditTechnicianConfirmClick}
        onCloseEditTechnicianPopup={this.onCloseEditTechnicianPopup}
        //
        showDeleteTechnicianPopup={this.state.showDeleteTechnicianPopup}
        showResetPasswordTechnicianPopup={
          this.state.showResetPasswordTechnicianPopup
        }
        onCloseDeleteTechnicianPopup={this.onCloseDeleteTechnicianPopup}
        onDeleteTechnicianConfirmClick={this.onDeleteTechnicianConfirmClick}
        onDeleteTechnicianClick={this.onDeleteTechnicianClick}
        onResetPasswordTechnicianClick={this.onResetPasswordTechnicianClick}
        onResetPasswordTechnicianConfirmClick={
          this.onResetPasswordTechnicianConfirmClick
        }
        onCloseResetPasswordTechnicianPopup={
          this.onCloseResetPasswordTechnicianPopup
        }
        //
        onBulkTechnicianDeleteClick={this.onBulkTechnicianDeleteClick}
        onBulkTechnicianDeleteConfirmClick={
          this.onBulkTechnicianDeleteConfirmClick
        }
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        showBulkDeleteTechnicianPopup={this.state.showBulkDeleteTechnicianPopup}
        //
        onTechnicianListFilterClick={this.onTechnicianListFilterClick}
        onTechnicianListSortClick={this.onTechnicianListSortClick}
        //
        closeAllPopups={this.closeAllPopups}
        //
        showUnlockTechnicianConfirmationPopup={
          this.state.showUnlockTechnicianConfirmationPopup
        }
        onUnlockTechnicianAccountClick={this.onUnlockTechnicianAccountClick}
        closeUnlockTechnicianPopup={this.closeUnlockTechnicianPopup}
        onUnlockTechnicianAccountConfirmClick={
          this.onUnlockTechnicianAccountConfirmClick
        }
      />
    );
  }
}

TechniciansContainer.propTypes = {};
const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    filteredTechnicianList: state.technician.filteredTechnicianList,
    filteredTechnicianCount: state.technician.filteredTechnicianCount,
  };
};

export default connect(mapStateToProps, {
  getTechnicianListWithFilter,
  getTechnicianDetailsById,
  deleteTechnicianInBulk,
  addNewTechnician,
  showAlert,
  editTechnician,
  deleteTechnician,
  resetUserPasswordByAdmin,
  unlockUser,
})(TechniciansContainer);
