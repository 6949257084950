import React from "react";
import CardView from "../../../../controls/Card/CardView";
import hospital_card from "../../../../../assets/images/icons/hospital_card.png";
import "./CardStyles.scss";

const HospitalCard = (props) => {
  return (
    <div className="super-admin-card-wrapper">
      <CardView
        extraStyle="hospital-card-control"
        bodyStyle={{ height: "180px" }}
      >
        <div className="card-title">
          <img className="card-icon" src={hospital_card} alt="icon" />
          <span>Hospitals</span>
        </div>

        <div className="info-wrapper">
          <div className="total-hospital-info">
            <span>Total Hospitals</span>
            <text className="highlight">{props.totalHospitals}</text>
          </div>
          <div className="total-hospital-info">
            <span>Total Centers</span>
            <text className="highlight">{props.totalCenters}</text>
          </div>
        </div>

        <div className="footer-text">
          Newly Added Hospitals
          <text className="px-2">{props.newlyAddedHospitals}</text>
        </div>
      </CardView>
    </div>
  );
};

export default HospitalCard;
