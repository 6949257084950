import React, { useState } from "react";
import { CardView, SelectOption } from "../../../../controls";
import BarChartView from "../Other/BarChartView";
import "./CardStyles.scss";

const TopPerformingDoctorsCard = (props) => {
  const chartData =
    props.data.length > 0 ? props.data : [{ label: "", count: 0 }];

  return (
    <div className="hospital-admin-cards-wrapper">
      <CardView
        extraStyle="top-doctors-by-ecg-report-card-control"
        bodyStyle={{ height: "320px" }}
      >
        <div className="card-header">
          <div className="title">Top 5 Doctors by ECG Reports</div>
          <div className="dropdown-option-wrapper">
            <SelectOption
              options={props.hospitalAdminDropdownOptions}
              placeholder={"Select"}
              isSearchable={false}
              value={props.hospitalAdminTopDoctorsFilterSelected}
              onChange={(val) => {
                console.log("SELECT : ", val);
                props.onHospitalAdminTopDoctorsFilterChange(val);
              }}
            />
          </div>
        </div>

        <div className="info-wrapper">
          <div className="bar-chart-wrapper">
            <BarChartView dataSet={chartData} theme="Purple" />
          </div>
        </div>
      </CardView>
    </div>
  );
};

export default TopPerformingDoctorsCard;
