export const actionTypes = {
  // GET_REPORT_PENDING: "GET_REPORT_PENDING",
  // GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  // GET_REPORT_ERROR: "GET_REPORT_ERROR",

  GET_REPORT_LIST_WITH_FILTER_PENDING: "GET_REPORT_LIST_WITH_FILTER_PENDING",
  GET_REPORT_LIST_WITH_FILTER_SUCCESS: "GET_REPORT_LIST_WITH_FILTER_SUCCESS",
  GET_REPORT_LIST_WITH_FILTER_ERROR: "GET_REPORT_LIST_WITH_FILTER_ERROR",

  GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_PENDING:
    "GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_PENDING",
  GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_SUCCESS:
    "GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_SUCCESS",
  GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_ERROR:
    "GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_ERROR",

  GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_PENDING:
    "GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_PENDING",
  GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_SUCCESS:
    "GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_SUCCESS",
  GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_ERROR:
    "GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_ERROR",

  GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_PENDING:
    "GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_PENDING",
  GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_SUCCESS:
    "GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_SUCCESS",
  GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_ERROR:
    "GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_ERROR",

  GET_SEVERITY_LIST_PENDING: "GET_SEVERITY_LIST_PENDING",
  GET_SEVERITY_LIST_SUCCESS: "GET_SEVERITY_LIST_SUCCESS",
  GET_SEVERITY_LIST_ERROR: "GET_SEVERITY_LIST_ERROR",

  REPORT_ACCEPT_PENDING: "REPORT_ACCEPT_PENDING",
  REPORT_ACCEPT_SUCCESS: "REPORT_ACCEPT_SUCCESS",
  REPORT_ACCEPT_ERROR: "REPORT_ACCEPT_ERROR",

  REPORT_SUBMIT_PENDING: "REPORT_SUBMIT_PENDING",
  REPORT_SUBMIT_SUCCESS: "REPORT_SUBMIT_SUCCESS",
  REPORT_SUBMIT_ERROR: "REPORT_SUBMIT_ERROR",

  REPORT_DETAILS_PENDING: "REPORT_DETAILS_PENDING",
  REPORT_DETAILS_SUCCESS: "REPORT_DETAILS_SUCCESS",
  REPORT_DETAILS_ERROR: "REPORT_DETAILS_ERROR",

  REPORT_DOWNLOAD_PDF_PENDING: "REPORT_DOWNLOAD_PDF_PENDING",
  REPORT_DOWNLOAD_PDF_SUCCESS: "REPORT_DOWNLOAD_PDF_SUCCESS",
  REPORT_DOWNLOAD_PDF_ERROR: "REPORT_DOWNLOAD_PDF_ERROR",

  REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_PENDING:
    "REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_PENDING",
  REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_SUCCESS:
    "REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_SUCCESS",
  REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_ERROR:
    "REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_ERROR",

  RESET_REPORTS_DATA: "RESET_REPORTS_DATA",

  SET_SELECTED_DOCTOR: "SET_SELECTED_DOCTOR",
  SET_SELECTED_DEVICE: "SET_SELECTED_DEVICE",
  SET_REPORT_SCREENS_STATE: "SET_REPORT_SCREENS_STATE",

  REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_PENDING:
    "REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_PENDING",
  REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_SUCCESS:
    "REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_SUCCESS",
  REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_ERROR:
    "REPORT_REVIEW_SUBMIT_BY_SUPERVISOR_ERROR",

  REPORT_EXPORT_TO_EXCEL_PENDING: "REPORT_EXPORT_TO_EXCEL_PENDING",
  REPORT_EXPORT_TO_EXCEL_SUCCESS: "REPORT_EXPORT_TO_EXCEL_SUCCESS",
  REPORT_EXPORT_TO_EXCEL_ERROR: "REPORT_EXPORT_TO_EXCEL_ERROR",
};

const initialState = {
  ReportReviewSubmitBySupervisorPending: false,
  ReportReviewSubmitBySupervisorSuccess: false,
  ReportReviewSubmitBySupervisorError: null,

  getReportPending: false,
  getReportSuccess: false,
  getReportError: null,

  getReportListWithFilterPending: false,
  getReportListWithFilterSuccess: false,
  getReportListWithFilerError: null,

  getReportCountByDoctorWithFilterPending: false,
  getReportCountByDoctorWithFilterSuccess: false,
  getReportCountByDoctorWithFilerError: null,

  getReportListOfDoctorWithFilterPending: false,
  getReportListOfDoctorWithFilterSuccess: false,
  getReportListOfDoctorWithFilerError: null,

  getReportListOfDeviceWithFilterPending: false,
  getReportListOfDeviceWithFilterSuccess: false,
  getReportListOfDeviceWithFilerError: null,

  getSeverityListPending: false,
  getSeverityListSuccess: false,
  getSeverityListError: null,

  ReportDetailsPending: false,
  ReportDetailsSuccess: false,
  ReportDetailsError: null,

  ReportAcceptPending: false,
  ReportAcceptSuccess: false,
  ReportAcceptError: null,

  ReportSubmitPending: false,
  ReportSubmitSuccess: false,
  ReportSubmitError: null,

  ReportDownloadPdfPending: false,
  ReportDownloadPdfSuccess: false,
  ReportDownloadPdfError: null,

  ReportExportToExcelPending: false,
  ReportExportToExcelSuccess: false,
  ReportExportToExcelError: null,

  removeReportAssociationWithDoctorPending: false,
  removeReportAssociationWithDoctorSuccess: false,
  removeReportAssociationWithDoctorError: null,

  reportList: [],
  filteredReportList: [],
  filteredReportsByDoctor: [],
  filteredReportCountsByDoctor: 0,
  severityList: [],
  reportDetails: "",
  ecgDetails: "",

  // Selected Doctor or Device for which 'View Reports' is clicked
  selectedDoctorToViewReports: {},
  selectedDeviceToViewReports: {},

  // States Objects to keep page, limit, etc pagination data preserved while doing to and fro navigation
  reportsScreenStates: {
    isComingFromDoctorsList: false,
    isComingFromDeviceList: false,
    doctorListScreen: {},
    deviceListScreen: {},
    reportListScreen: {},
  },
};

export default function report(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // case actionTypes.GET_REPORT_PENDING:
    // case actionTypes.GET_REPORT_SUCCESS:
    // case actionTypes.GET_REPORT_ERROR:
    //     return Object.assign({}, state, payload);

    case actionTypes.GET_REPORT_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_REPORT_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_REPORT_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_PENDING:
    case actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_SUCCESS:
    case actionTypes.GET_REPORT_COUNT_BY_DOCTOR_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_PENDING:
    case actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_SUCCESS:
    case actionTypes.GET_REPORT_LIST_OF_DOCTOR_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_PENDING:
    case actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_SUCCESS:
    case actionTypes.GET_REPORT_LIST_OF_DEVICE_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_SEVERITY_LIST_PENDING:
    case actionTypes.GET_SEVERITY_LIST_SUCCESS:
    case actionTypes.GET_SEVERITY_LIST_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REPORT_ACCEPT_PENDING:
    case actionTypes.REPORT_ACCEPT_SUCCESS:
    case actionTypes.REPORT_ACCEPT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REPORT_SUBMIT_PENDING:
    case actionTypes.REPORT_SUBMIT_SUCCESS:
    case actionTypes.REPORT_SUBMIT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REPORT_DETAILS_PENDING:
    case actionTypes.REPORT_DETAILS_SUCCESS:
    case actionTypes.REPORT_DETAILS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REPORT_DOWNLOAD_PDF_PENDING:
    case actionTypes.REPORT_DOWNLOAD_PDF_SUCCESS:
    case actionTypes.REPORT_DOWNLOAD_PDF_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REPORT_EXPORT_TO_EXCEL_PENDING:
    case actionTypes.REPORT_EXPORT_TO_EXCEL_SUCCESS:
    case actionTypes.REPORT_EXPORT_TO_EXCEL_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_PENDING:
    case actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_SUCCESS:
    case actionTypes.REMOVE_REPORT_ASSOCIATION_WITH_DOCTOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_REPORTS_DATA:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_DOCTOR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_DEVICE:
      return Object.assign({}, state, payload);

    case actionTypes.SET_REPORT_SCREENS_STATE:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
