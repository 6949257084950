import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IoIosArrowRoundUp } from "react-icons/io";
import DataTable from "react-data-table-component";
import { PaginationNew } from "../../controls";
import "./DataTableStyles.scss";

const DataTableView = ({
  title,
  paginationServer = true,
  pagination = true,
  ...props
}) => {
  // alert(props.paginationDefaultPage);

  useEffect(() => {
    // console.log("DATATABLE LIMIT: ", props.paginationPerPage);
  }, [props.paginationPerPage]);

  return (
    <div className="data_table">
      <DataTable
        title={<h3 className={`H2 ${title ? "mb-4" : "mb-0"}`}>{title}</h3>}
        className={"dataTable"}
        sortIcon={<IoIosArrowRoundUp />}
        pagination={false} //{pagination} // We will render pagination component separately now, at the bottom of DataTable
        paginationServer={paginationServer}
        persistTableHead={true}
        // paginationComponent={PaginationNew} // No need to pass pagination component here, as we are rendering pagination component separately now
        {...props}
      />

      {props.paginationTotalRows && parseInt(props.paginationTotalRows) > 0 ? (
        <PaginationNew
          rowCount={props.paginationTotalRows}
          rowsPerPage={props.paginationPerPage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          paginationRowsPerPageOptions={props.paginationRowsPerPageOptions}
          currentPage={props.paginationDefaultPage}
          onChangePage={props.onChangePage}
          {...props}
        />
      ) : null}
    </div>
  );
};

DataTableView.propTypes = {
  title: PropTypes.string,
  paginationServer: PropTypes.bool,
  pagination: PropTypes.bool,
};

export default DataTableView;
