import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import metsl_logo_white from "../../../assets/images/metsl_logo_white.png";
import notifications from "../../../assets/images/icons/notifications.png";
import chats from "../../../assets/images/icons/chats.png";
import { FaUser, FaAngleDown, FaAngleUp } from "react-icons/fa6";
import DropdownCard from "./Components/DropdownCard";

import "./HeaderStyles.scss";
import { globals } from "../../../store/globals";

const Header = ({
  loginUser,
  profilePicture, // We are not using this variable, BUT it is refreshed upon proifile pic change, whereas loginUser object does not refresh
  franchiseLogo, // We are not using this variable, BUT it is refreshed upon proifile pic change, whereas loginUser object does not refresh
  onLogoClick,
  showDropdownMenu,
  updateDropdownMenuVisibility,
  onMyProfileClick,
  onHospitalInfoClicked,
  onLogoutClick,
}) => {
  // console.log("Header: Hosp Franchise Logo ", loginUser?.userProfile?.franchiseLogo ?? "NONE");
  // console.log("Header: Prof Pic:" + loginUser?.data?.profilePicture ?? "NONE");

  const userRole = loginUser?.data?.userRoles[0].name;
  const userName = loginUser?.data?.userName;
  // const data = globals.store.getState().auth;

  const firstNameInitial = loginUser?.userProfile?.firstName
    ? loginUser?.userProfile?.firstName.charAt(0).toUpperCase()
    : "";
  const lastNameInitial = loginUser?.userProfile?.lastName
    ? loginUser?.userProfile?.lastName.charAt(0).toUpperCase()
    : "";

  //--------------- handle animating dropdown icon ----------------------
  const [shouldRender, setShouldRender] = useState(showDropdownMenu);

  useEffect(() => {
    if (!showDropdownMenu) {
      setTimeout(() => {
        setShouldRender(false);
      }, 250);
    } else {
      setShouldRender(true);
    }
  }, [showDropdownMenu]);

  //--------------- render dropdown icon ----------------------
  const renderDropdownIcon = showDropdownMenu ? (
    <FaAngleUp size={20} onClick={updateDropdownMenuVisibility} />
  ) : (
    <FaAngleDown size={20} onClick={updateDropdownMenuVisibility} />
  );

  return (
    <div className="header fixed-top">
      <div className="header_title">
        <img
          className={`logo-style ${
            loginUser?.userProfile?.franchiseLogo ? "custom" : ""
          }`}
          src={loginUser?.userProfile?.franchiseLogo ?? metsl_logo_white}
          alt="logo"
          onClick={onLogoClick}
        />

        {loginUser?.userProfile?.franchiseLogo ? null : (
          <h2 className="title" onClick={onLogoClick}>
            Rhythms 24x7
          </h2>
        )}
      </div>

      <div className="user-info-wrapper ">
        <img className="icon-style" src={notifications} alt="icon" />
        <img className="icon-style" src={chats} alt="icon" />

        <div style={{ width: "20px" }} />

        {loginUser?.data?.profilePicture !== null &&
        loginUser?.data?.profilePicture !== undefined ? (
          <div className="user-profile-image">
            <img
              src={loginUser?.data?.profilePicture ?? null}
              alt={`${firstNameInitial}${lastNameInitial}`}
              className="img_preview"
            />
          </div>
        ) : (
          <div className="user-profile-placeholder">
            {`${firstNameInitial}${lastNameInitial}`}
          </div>
        )}

        <div style={{ width: "20px" }} />

        <div className="user-profile-info-wrapper">
          <div className="user-role-title">{userRole}</div>
          <div className="user-mail">{userName}</div>
        </div>

        <div style={{ width: "10px" }} />

        <span className="option-dropdown-icon">
          {renderDropdownIcon}
          <div
            className={`dropdown-wrapper ${showDropdownMenu ? "show" : "hide"}`}
          >
            {shouldRender && (
              <DropdownCard
                loginUser={loginUser}
                onMyProfileClick={onMyProfileClick}
                // onHospitalInfoClick={onHospitalInfoClick}
                onHospitalInfoClicked={onHospitalInfoClicked}
                onLogoutClick={onLogoutClick}
              />
            )}
          </div>
        </span>
      </div>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {
  btnData: [],
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
