import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

import TabContext from "@mui/lab/TabContext";

import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
// import hospital_filled from "../../../assets/images/icons/hospital_filled.png";
// import hospital_line from "../../../assets/images/icons/hospital_line.png";

import hospital_filled from "../../../assets/images/icons/hospital_filled.png";
import hospital_line from "../../../assets/images/icons/hospital_line.png";

function Tabview({ TabListFunc, handleDataSet }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="hospital_main">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <div>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              className="hospital_tablist"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#00b48a",
                },
              }}
              value={value}
            >
              {TabListFunc?.map((item, index) => {
                return (
                  <Tab
                    key={item?.id}
                    sx={{
                      "&.Mui-selected": {
                        color: "#00b48a",
                      },
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          textTransform: "capitalize",
                        }}
                      >
                        <Avatar
                          src={value == index ? hospital_filled : hospital_line}
                          sx={{
                            width: 16,
                            height: 16,
                          }}
                        />
                        <Typography>
                          {item?.hospitalInfo?.hospitalName}
                        </Typography>
                      </Box>
                    }
                    value={index}
                    iconPosition="start"
                    onClick={() => {
                      index !== value && handleDataSet(item);
                    }}
                  ></Tab>
                );
              })}
            </TabList>
          </div>
        </Box>
        {/* 
      {TabListFunc?.map((item, index) => {
        return (
          <>
            <TabPanel value={index}>
              <HospitalCard
                tabData={
                  // item.hospitalId == HospitalDetails?.hospital?.id &&
                  HospitalDetails
                }
                fields={fields}
              />
            </TabPanel>
          </>
        );
      })} */}
      </TabContext>
    </Box>
  );
}

export default Tabview;
