import React from "react";
import { Input, Checkbox, ThemedButton } from "../../controls";
import LOGO from "../../../assets/images/metsl_logo_color.png";
import LOGIN_BG from "../../../assets/images/login_bg.png";
import "./LoginStyles.scss";

const LoginViewNew = ({
  isLoader,
  fields,
  errors,
  onFieldsChange,
  onKeyDown,
  onLogin,
  onForgotPasswordClick,
  onRememberMeCheckBoxChange,
  rememberMeSelected,
}) => {
  return (
    <div
      className="login-container-new "
      tabIndex={0}
      onKeyDown={(e) => onKeyDown(e)}
    >
      <div className="half-container-left">
        <img src={LOGIN_BG} alt="Background" className="left-bg" />

        <div className="botom-text">
          <h3 className="welcome-text">Welcome to Rhythms 24x7</h3>
          <p className="info-text">
            Telemedicine at Maestros Vision To bridge the gap between “patient
            care and expert” using digital devices Benefits of using
            telemedicine. Device Comprehensive 12 Lead 500 Samples/Sec Portable
            ECG equipped with Touch Screen TFT and GSM 5G Modem and local
            storage/printing. Telemedicine services can provide healthcare to
            geographically hard-to-serve areas thus improving hospital
            stability. We have our manufacturing works at Navi Mumbai
            (Maharashtra).
          </p>
        </div>
      </div>

      <div className="half-container-right">
        <img
          src={LOGO}
          alt="Logo"
          style={{
            height: "100px",
            width: "300px",
            marginBottom: "20px",
            objectFit: "contain",
          }}
        />
        <div className="login-card">
          <div style={{ marginTop: "15px" }}>
            <Input
              value={fields["email"]}
              name={"email"}
              label={"Username"}
              placeholder="Enter your username"
              type="text"
              size="lg"
              onChange={onFieldsChange}
              error={errors["email"]}
              style={{ backgroundColor: "white" }}
            />
          </div>

          <div style={{ marginTop: "25px" }}>
            <Input
              value={fields["password"]}
              name={"password"}
              label={"Password"}
              placeholder="Enter your password"
              type="password"
              size="lg"
              onChange={onFieldsChange}
              error={errors["password"]}
              style={{ backgroundColor: "white" }}
            />
          </div>

          <div className="login-options">
            {/* <span className="remember-me">
              <input type="checkbox" />{" "}
              <span className="text">Remember Me</span>
            </span> */}

            <div className="checkbox">
              <Checkbox
                onChange={onRememberMeCheckBoxChange}
                checked={rememberMeSelected}
              />
              <label className="boxLabel">Remember Me</label>
            </div>

            <span className="forgot-password" onClick={onForgotPasswordClick}>
              Forgot Password?
            </span>
          </div>

          {!isLoader && (
            <div style={{ marginTop: "15px" }}>
              <ThemedButton
                title="Login"
                styles={"primary"}
                fullWidth
                loginButton
                onClick={onLogin}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginViewNew;
