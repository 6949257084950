var drawingdataCanvas = document.getElementById("dataplottercanvas"),
  canvasWidth = drawingdataCanvas.width,
  drawingdataContext = drawingdataCanvas.getContext("2d"),
  canvasHeight = drawingdataCanvas.height,
  GRID_LINE_COLOR = "orange",
  drawinggridCanvas = document.getElementById("gridcanvas"),
  drawinggridContext = drawinggridCanvas.getContext("2d"); //'rgb(200,0, 0)';
var sampling = 100;

var cur_x, prev_x; // , cur_y;//, prev_y, y_offset;
var p_ecg_y = new Array(13);

// KJ Edit
var _Machineoffset;
var _ECGSaveDataFlag = false;

var offsetArray = new Array(14);
var xValRight = 0;
var xValLeft;
var prexValRight;
var prexValLeft;
var xValResp = 0;
var prexValResp;
var xValSpo = 0;
var prexValSpo;
var sweepspeed = 1;
var canvasWidth, canvasHeight;
// var Gain = 6; // OLD
var Gain = 1; // TEST
xValRight = drawingdataContext.canvas.width / 2;
xValLeft = 0;
xValSpo = 0;
xValResp = xValRight;
_Machineoffset = 3000;
var savebutton = document.getElementById("save");
var streambutton = ""; // document.getElementById("streamingButton");

var machinetypecombo = document.getElementById("machinetypeSelect");

//ECGDataService.GetPatientInfo(otvisitid, OnComplete, OnError, OnTimeOut);

console.log("offsetArray: ", offsetArray.length);

// Grid..........................................................

function drawGrid(context, color, stepx, stepy) {
  canvasWidth = context.canvas.width;
  canvasHeight = context.canvas.height;
  context.clearRect(0, 0, canvasWidth, canvasHeight);

  offsetArray[0] = canvasHeight / 7;
  offsetArray[7] = offsetArray[0];
  for (var i = 1; i < offsetArray.length / 2; i++) {
    offsetArray[i] = offsetArray[i - 1] + canvasHeight / 7;
    offsetArray[i + 7] = offsetArray[i];
  }

  context.save();

  context.strokeStyle = color;
  context.fillStyle = "#ffffff";
  context.lineWidth = 0.5;
  context.fillRect(0, 0, context.canvas.width, context.canvas.height);
  context.globalAlpha = 0.15;

  //Horizontal Lines
  context.beginPath();
  var cnt = 0;
  for (var i = stepx + 0.5; i < context.canvas.width; i += stepx) {
    cnt += 1;
    if (cnt == 5) {
      cnt = 0;
      context.stroke();
      context.strokeStyle = "red";
      context.beginPath();
      context.moveTo(i, 0);
      context.lineTo(i, context.canvas.height);
      context.stroke();
    } else {
      context.stroke();
      context.strokeStyle = color;
      context.beginPath();
      context.moveTo(i, 0);
      context.lineTo(i, context.canvas.height);
      context.stroke();
    }
  }

  //Vertical Lines
  context.beginPath();
  cnt = 0;
  for (var i = stepy + 0.5; i < context.canvas.height; i += stepy) {
    cnt += 1;
    if (cnt == 5) {
      cnt = 0;
      context.stroke();
      context.strokeStyle = "red";
      context.beginPath();
      context.moveTo(0, i);
      context.lineTo(context.canvas.width, i);
      context.stroke();
    } else {
      context.stroke();
      context.strokeStyle = color;
      context.beginPath();
      context.moveTo(0, i);
      context.lineTo(context.canvas.width, i);
      context.stroke();
    }
  }
  context.stroke();

  context.restore();

  //Draw Lead Text and Pulse for each case
  //Draw the Lead text
  context.save();
  context.fillStyle = "blue";
  context.font = "bold 14px Arial";

  context.fillText("L1", xValLeft + 2, offsetArray[0] + 14);

  context.fillText("L2", xValLeft + 2, offsetArray[1] + 14);

  context.fillText("L3", xValLeft + 2, offsetArray[2] + 14);

  context.fillText("AVR", xValLeft + 2, offsetArray[3] + 14);

  context.fillText("AVL", xValLeft + 2, offsetArray[4] + 14);

  context.fillText("AVF", xValLeft + 2, offsetArray[5] + 14);

  context.fillText("V1", xValRight + 2, offsetArray[7] + 14);

  context.fillText("V2", xValRight + 2, offsetArray[8] + 14);

  context.fillText("V3", xValRight + 2, offsetArray[9] + 14);

  context.fillText("V4", xValRight + 2, offsetArray[10] + 14);

  context.fillText("V5", xValRight + 2, offsetArray[11] + 14);

  context.fillText("V6", xValRight + 2, offsetArray[12] + 14);

  context.font = "bold 10px Arial";
  // context.fillText("SPO2", xValSpo + 2, offsetArray[7] - 14);
  // context.fillText("Resp", xValResp + 2, offsetArray[15] - 14);
  context.restore();
}

//Pulse.................................................................................
function drawPulse(context) {
  //    context.strokeStyle = 'blue';
  //    for (i = 0; i < 6; i++) {
  //        xValLeft = 2;
  //        context.beginPath();
  //        context.moveTo(xValLeft, offsetArray[i]);
  //        context.lineTo(xValLeft + 5, offsetArray[i]);
  //        context.lineTo(xValLeft + 5, offsetArray[i] - (300 / Gain));
  //        xValLeft = xValLeft + 5;
  //        context.lineTo(xValLeft + 5, offsetArray[i] - (300 / Gain));
  //        context.lineTo(xValLeft + 5, offsetArray[i]);
  //        xValLeft = xValLeft + 5;
  //        context.lineTo(xValLeft + 5, offsetArray[i]);
  //        context.stroke();
  //        context.beginPath();

  //        xValRight = (canvasWidth / 2) + 2;
  //        context.beginPath();
  //        context.moveTo(xValRight, offsetArray[i]);
  //        context.lineTo(xValRight + 5, offsetArray[i]);
  //        context.lineTo(xValRight + 5, offsetArray[i] - (300 / Gain));
  //        xValRight = xValRight + 5;
  //        context.lineTo(xValRight + 5, offsetArray[i] - (300 / Gain));
  //        context.lineTo(xValRight + 5, offsetArray[i]);
  //        xValRight = xValRight + 5;
  //        context.lineTo(xValRight + 5, offsetArray[i]);
  //        context.stroke();
  //        context.beginPath();

  //    }
  //    context.restore();
  drawingdataContext.clearRect(0, 0, 25, canvasHeight);
  drawingdataContext.strokeStyle = "black";
  drawingdataContext.fillStyle = "black";
  drawingdataContext.lineWidth = 1;

  var ofs = offsetArray[1] - 7;
  drawingdataContext.beginPath();
  drawingdataContext.moveTo(xValLeft, ofs);
  drawingdataContext.lineTo(xValLeft + 5, ofs);
  drawingdataContext.lineTo(xValLeft + 5, ofs - 600 / Gain);
  xValLeft = xValLeft + 5;
  drawingdataContext.lineTo(xValLeft + 5, ofs - 600 / Gain);
  drawingdataContext.lineTo(xValLeft + 5, ofs);
  xValLeft = xValLeft + 5;
  drawingdataContext.lineTo(xValLeft + 5, ofs);
  drawingdataContext.stroke();
  drawingdataContext.beginPath();
  drawingdataContext.restore();
  xValLeft += 25;
  xValRight += 25;
  xValResp += 25;
  xValSpo += 25;
}

export function Plot12LeadECG(ECGArray) {
  // alert(ECGArray.length);
  drawingdataContext.clearRect(xValLeft - sweepspeed, 0, 5 * sweepspeed, offsetArray[6]);

  drawingdataContext.clearRect(xValRight - sweepspeed, 0, 5 * sweepspeed, offsetArray[13]);
  if (_ECGSaveDataFlag == false) {
    drawingdataContext.strokeStyle = "#050505";
    drawingdataContext.fillStyle = "#050505";
  } else {
    drawingdataContext.strokeStyle = "#009900";
    drawingdataContext.fillStyle = "#009900";
  }
  drawingdataContext.lineWidth = 1;

  //graph 6x2

  for (var i = 0; i < 6; i++) {
    //draw l1-aVL
    drawingdataContext.beginPath();
    xValLeft = xValLeft - sweepspeed;
    drawingdataContext.moveTo(xValLeft, p_ecg_y[i]);
    xValLeft = xValLeft + sweepspeed;

    var data = offsetArray[i] - ECGArray[i] / Gain;
    drawingdataContext.lineTo(xValLeft, data);
    p_ecg_y[i] = data;
    drawingdataContext.stroke();
    //draw v1-v6
    drawingdataContext.beginPath();
    xValRight = xValRight - sweepspeed;
    drawingdataContext.moveTo(xValRight, p_ecg_y[i + 6]);
    xValRight = xValRight + sweepspeed;

    var data = offsetArray[i] - ECGArray[i + 6] / Gain;
    drawingdataContext.lineTo(xValRight, data);
    p_ecg_y[i + 6] = data;
    drawingdataContext.stroke();
  }
  //draw respiration wave
  // drawingdataContext.clearRect(xValResp, offsetArray[14], 5 * sweepspeed, canvasHeight);
  // drawingdataContext.beginPath();
  // xValResp = xValResp - sweepspeed;
  // drawingdataContext.moveTo(xValResp, p_ecg_y[12]);
  // xValResp = xValResp + sweepspeed;
  // drawingdataContext.lineTo(xValResp, offsetArray[15] - ECGArray[12] / 2);
  // p_ecg_y[12] = offsetArray[15] - ECGArray[12] / 2;
  // drawingdataContext.stroke();

  xValLeft = xValLeft + sweepspeed;
  xValRight = xValRight + sweepspeed;
  // xValResp = xValResp + sweepspeed;

  if (xValLeft > canvasWidth / 2 - 15) {
    // xValLeft = 25;
    // drawingdataContext.stroke();
    // drawingdataContext.beginPath();
    return false;
  }
  if (xValRight > canvasWidth - 20) {
    // xValRight = canvasWidth / 2 + 25;
    // drawingdataContext.stroke();
    // drawingdataContext.beginPath();
    return false;
  }
  // if (xValResp > canvasWidth - 20) {
  //   // xValResp = canvasWidth / 2 + 25;
  //   // //  p_ecg_y[12] = xValResp;
  //   // drawingdataContext.stroke();
  //   // drawingdataContext.beginPath();
  //   return false;
  // }
  return true;
}

function fnchange() {
  //gain change
  var p = 2;
  drawingdataContext.clearRect(0, 0, 25, canvasHeight);
  var val = document.getElementById("gainselect").value;
  switch (val) {
    case "0": //0.5
      Gain = 24;
      break;
    case "1": //1
      Gain = 12;
      break;
    case "2": //1.5
      Gain = 8;
      break;
    case "3": //2
      Gain = 6;
      break;
  }
  //draw the pulse again
  drawingdataContext.lineWidth = 1;

  drawingdataContext.strokeStyle = "black";
  drawingdataContext.fillStyle = "black";
  drawingdataContext.beginPath();
  drawingdataContext.moveTo(p, offsetArray[1]);
  drawingdataContext.lineTo(p + 5, offsetArray[1]);
  drawingdataContext.lineTo(p + 5, offsetArray[1] - 300 / Gain);
  p = p + 5;
  drawingdataContext.lineTo(p + 5, offsetArray[1] - 300 / Gain);
  drawingdataContext.lineTo(p + 5, offsetArray[1]);
  p = p + 5;
  drawingdataContext.lineTo(p + 5, offsetArray[1]);
  drawingdataContext.stroke();
  drawingdataContext.beginPath();
  drawingdataContext.restore();
}

//if (IsRealtime == "True") {
//    document.getElementById('startnibp').style.visibility = 'hidden'; // hide
//    document.getElementById('serveriplabel').style.visibility = 'visible'; // show
//    document.getElementById('serverIP').style.visibility = 'visible'; // show
//    document.getElementById('streamingButton').style.visibility = 'visible'; // show

//}

export function loadLayout(sweep = 1, gainVal = 1) {
  drawingdataCanvas = document.getElementById("dataplottercanvas");
  canvasWidth = drawingdataCanvas.width;
  drawingdataContext = drawingdataCanvas.getContext("2d");
  canvasHeight = drawingdataCanvas.height;
  GRID_LINE_COLOR = "orange";
  drawinggridCanvas = document.getElementById("gridcanvas");
  drawinggridContext = drawinggridCanvas.getContext("2d"); //'rgb(200,0, 0)';
  sampling = 100;

  cur_x = null;
  prev_x = null; // , cur_y;//, prev_y, y_offset;
  p_ecg_y = new Array(13);

  // KJ Edit
  _Machineoffset = null;
  _ECGSaveDataFlag = false;

  offsetArray = new Array(14);
  xValRight = 0;
  xValLeft = null;
  prexValRight = null;
  prexValLeft = null;
  xValResp = 0;
  prexValResp = null;
  xValSpo = 0;
  prexValSpo = null;
  sweepspeed = sweep;
  canvasWidth = null;
  canvasHeight = null;
  // var Gain = 6; // OLD
  Gain = gainVal; // TEST
  xValRight = drawingdataContext.canvas.width / 2;
  xValLeft = 0;
  xValSpo = 0;
  xValResp = xValRight;
  _Machineoffset = 3000;
  savebutton = document.getElementById("save");
  streambutton = ""; // document.getElementById("streamingButton");

  machinetypecombo = document.getElementById("machinetypeSelect");

  drawingdataContext.clearRect(0, 0, drawingdataContext.canvas.width, drawingdataContext.canvas.height);

  drawGrid(drawinggridContext, GRID_LINE_COLOR, 5, 5);
  drawPulse(drawingdataContext);
}
