export const actionTypes = {
  AUTH_PENDING: "AUTH_PENDING",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",

  LOGOUT_PENDING: "LOGOUT_PENDING",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",

  UPDATE_TOKEN_SUCCESS: "UPDATE_TOKEN_SUCCESS",

  RESET_USER_LOGIN_DETAILS: "RESET_USER_LOGIN_DETAILS",

  CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",

  GET_MY_PROFILE_PENDING: "GET_MY_PROFILE_PENDING",
  GET_MY_PROFILE_SUCCESS: "GET_MY_PROFILE_SUCCESS",
  GET_MY_PROFILE_ERROR: "GET_MY_PROFILE_ERROR",

  UPDATE_MY_PROFILE_PENDING: "UPDATE_MY_PROFILE_PENDING",
  UPDATE_MY_PROFILE_SUCCESS: "UPDATE_MY_PROFILE_SUCCESS",
  UPDATE_MY_PROFILE_ERROR: "UPDATE_MY_PROFILE_ERROR",

  UPDATE_USER_PROFILE_IMAGE: "UPDATE_USER_PROFILE_IMAGE",
  UPDATE_HOSPITAL_FRANHISE_LOGO: "UPDATE_HOSPITAL_FRANHISE_LOGO",
  UPDATE_USER_NAMES_DATA: "UPDATE_USER_NAMES_DATA",

  VERIFY_EMAIL_FOR_OTP_PENDING: "VERIFY_EMAIL_FOR_OTP_PENDING",
  VERIFY_EMAIL_FOR_OTP_SUCCESS: "VERIFY_EMAIL_FOR_OTP_SUCCESS",
  VERIFY_EMAIL_FOR_OTP_ERROR: "VERIFY_EMAIL_FOR_OTP_ERROR",

  RESEND_OTP_PENDING: "RESEND_OTP_PENDING",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR: "RESEND_OTP_ERROR",

  RESET_PASSWORD_BY_OTP_PENDING: "RESET_PASSWORD_BY_OTP_PENDING",
  RESET_PASSWORD_BY_OTP_SUCCESS: "RESET_PASSWORD_BY_OTP_SUCCESS",
  RESET_PASSWORD_BY_OTP_ERROR: "RESET_PASSWORD_BY_OTP_ERROR",

  RESET_USER_PASSWORD_BY_ADMIN_PENDING: "RESET_USER_PASSWORD_BY_ADMIN_PENDING",
  RESET_USER_PASSWORD_BY_ADMIN_SUCCESS: "RESET_USER_PASSWORD_BY_ADMIN_SUCCESS",
  RESET_USER_PASSWORD_BY_ADMIN_ERROR: "RESET_USER_PASSWORD_BY_ADMIN_ERROR",
};

const initialState = {
  authPending: false,
  authSuccess: false,
  authError: null,

  logoutPending: false,
  logoutSuccess: false,
  logoutError: null,

  changePasswordPending: false,
  changePasswordSuccess: false,
  changePasswordError: null,

  getMyProfilePending: false,
  getMyProfileSuccess: false,
  getMyProfileError: null,

  updateMyProfilePending: false,
  updateMyProfileSuccess: false,
  updateMyProfileError: null,

  verifyEmailForOtpPending: false,
  verifyEmailForOtpSuccess: false,
  verifyEmailForOtpError: false,

  resendOtpPending: false,
  resendOtpSuccess: false,
  resendOtpError: false,

  resetPasswordByOTPPending: false,
  resetPasswordByOTPSuccess: true,
  resetPasswordByOTPError: false,

  resetUserPasswordByAdminPending: false,
  resetUserPasswordByAdminSuccess: false,
  resetUserPasswordByAdminError: null,

  accessToken: null,
  refreshToken: null,
  loginUser: null,
  myProfileData: null,
  profilePicture: null,
  franchiseLogo: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.AUTH_PENDING:
    case actionTypes.AUTH_ERROR:
    case actionTypes.AUTH_SUCCESS:
      return Object.assign({}, state, payload);

    case actionTypes.LOGOUT_PENDING:
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.LOGOUT_SUCCESS:
      console.log("Logout Payload:", payload);
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_TOKEN_SUCCESS:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_USER_LOGIN_DETAILS:
      return Object.assign({}, state, payload);

    case actionTypes.CHANGE_PASSWORD_PENDING:
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_MY_PROFILE_PENDING:
    case actionTypes.GET_MY_PROFILE_ERROR:
    case actionTypes.GET_MY_PROFILE_SUCCESS:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_MY_PROFILE_PENDING:
    case actionTypes.UPDATE_MY_PROFILE_ERROR:
    case actionTypes.UPDATE_MY_PROFILE_SUCCESS:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_USER_PROFILE_IMAGE:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_HOSPITAL_FRANHISE_LOGO:
      return Object.assign({}, state, payload);

    case actionTypes.UPDATE_USER_NAMES_DATA:
      return Object.assign({}, state, payload);

    case actionTypes.VERIFY_EMAIL_FOR_OTP_PENDING:
    case actionTypes.VERIFY_EMAIL_FOR_OTP_SUCCESS:
    case actionTypes.VERIFY_EMAIL_FOR_OTP_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESEND_OTP_PENDING:
    case actionTypes.RESEND_OTP_SUCCESS:
    case actionTypes.RESEND_OTP_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_PASSWORD_BY_OTP_PENDING:
    case actionTypes.RESET_PASSWORD_BY_OTP_SUCCESS:
    case actionTypes.RESET_PASSWORD_BY_OTP_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_USER_PASSWORD_BY_ADMIN_PENDING:
    case actionTypes.RESET_USER_PASSWORD_BY_ADMIN_SUCCESS:
    case actionTypes.RESET_USER_PASSWORD_BY_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
