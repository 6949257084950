import React, { Component } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import "./libStyles.scss";
import "./DateRangeSelectorStyles.scss";

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  componentWillReceiveProps(newProps) {
    // console.log("DateRangeSelector ::  NEW PROPS  :: ", JSON.stringify(newProps));

    // Keep checking if for startDate and endTime null is passed.
    // This is passed when some other filter is in use, in which case both dates should be cleared.
    if (newProps.startDate === null) {
      this.setState({ startDate: null });
    }
    if (newProps.endDate === null) {
      this.setState({ endDate: null });
    }

    // Also, Inherently we handle selcted dates in this component, but if they are passed from parent, then they should take preference.
    if (newProps.startDate !== this.state.startDate) {
      this.setState({ startDate: newProps.startDate });
    }
    if (newProps.endDate !== this.state.endDate) {
      this.setState({ endDate: newProps.endDate });
    }
  }

  render() {
    return (
      <div className="date-range-selector">
        <DateRangePicker
          startDateId="startDate"
          endDateId="endDate"
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDatesChange={({ startDate, endDate }) => {
            console.log("DateRangePicker ::: ", startDate, " , ", endDate);
            this.props.onDatesChange(startDate, endDate);
            this.setState({ startDate, endDate });
          }}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => {
            this.setState({ focusedInput });
          }}
          small
          noBorder
          verticalHeight={"25px"}
          displayFormat={"DD/MM/YYYY"}
          isOutsideRange={() => false} // To allow previous dates selection
        />
        <CiCalendar size={25} color="#d9d9d9" />
      </div>
    );
  }
}

export default DateRangeSelector;
