import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./patientReducer";

export function getPatientInsuranceDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PATIENT_INSURANCE_BY_ID_PENDING,
      payload: {
        getPatientInsuranceDetailsByIdPending: true,
        getPatientInsuranceDetailsByIdSuccess: false,
        getPatientInsuranceDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`insurance/by/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.insuranceDetails ?? null);
        }

        dispatch({
          type: actionTypes.GET_PATIENT_INSURANCE_BY_ID_SUCCESS,
          payload: {
            getPatientInsuranceDetailsByIdPending: false,
            getPatientInsuranceDetailsByIdSuccess: true,
            getPatientInsuranceDetailsByIdError: null,
            patientInsuranceDetails: response?.data?.insuranceDetails ?? {},
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.GET_PATIENT_INSURANCE_BY_ID_ERROR,
          payload: {
            getPatientInsuranceDetailsByIdPending: false,
            getPatientInsuranceDetailsByIdSuccess: true,
            getPatientInsuranceDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- admit patient --------------

export function admitPatient(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADMIT_NEW_PATIENT_PENDING,
      payload: {
        admitPatientPending: true,
        admitPatientSuccess: false,
        admitPatientError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/admit/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADMIT_NEW_PATIENT_SUCCESS,
          payload: {
            admitPatientPending: false,
            admitPatientSuccess: true,
            admitPatientError: null,
            patientDetails: response?.data ?? {},
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADMIT_NEW_PATIENT_ERROR,
          payload: {
            admitPatientPending: false,
            admitPatientSuccess: true,
            admitPatientError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- add patientInsurance --------------

export function addPatientInsurance(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PATIENT_INSURANCE_PENDING,
      payload: {
        addPatientInsurancePending: true,
        addPatientInsuranceSuccess: false,
        addPatientInsuranceError: null,
      },
    });
    dispatch(showLoader());

    Api.post("insurance/add", data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_PATIENT_INSURANCE_SUCCESS,
          payload: {
            addPatientInsurancePending: false,
            addPatientInsuranceSuccess: true,
            addPatientInsuranceError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_PATIENT_INSURANCE_ERROR,
          payload: {
            addPatientInsurancePending: false,
            addPatientInsuranceSuccess: true,
            addPatientInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- Edit patient ------------

export function editPatient(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_PATIENT_PENDING,
      payload: {
        editPatientPending: true,
        editPatientSuccess: false,
        editPatientError: null,
      },
    });
    dispatch(showLoader());

    Api.put(`technician/update/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_PATIENT_SUCCESS,
          payload: {
            editPatientPending: false,
            editPatientSuccess: true,
            editPatientError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.EDIT_PATIENT_ERROR,
          payload: {
            editPatientPending: false,
            editPatientSuccess: true,
            editPatientError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- Edit patient Insurance ------------

export function editPatientInsurance(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_PATIENT_INSURANCE_PENDING,
      payload: {
        editPatientInsurancePending: true,
        editPatientInsuranceSuccess: false,
        editPatientInsuranceError: null,
      },
    });
    dispatch(showLoader());

    Api.put(`technician/update/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_PATIENT_INSURANCE_SUCCESS,
          payload: {
            editPatientInsurancePending: false,
            editPatientInsuranceSuccess: true,
            editPatientInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.EDIT_PATIENT_INSURANCE_ERROR,
          payload: {
            editPatientInsurancePending: false,
            editPatientInsuranceSuccess: true,
            editPatientInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//--------------------------------- delete PAtient Insurance ---------

export function deletePatientInsurance(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PATIENT_INSURANCE_PENDING,
      payload: {
        deletePatientInsurancePending: true,
        deletePatientInsuranceSuccess: false,
        deletePatientInsuranceError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`insurance/delete/by/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.DELETE_PATIENT_INSURANCE_SUCCESS,
          payload: {
            deletePatientInsurancePending: false,
            deletePatientInsuranceSuccess: true,
            deletePatientInsuranceError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.DELETE_PATIENT_INSURANCE_ERROR,
          payload: {
            deletePatientInsurancePending: false,
            deletePatientInsuranceSuccess: true,
            deletePatientInsuranceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//

export function initiateTransferProcess(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.INITIATE_TRANSFER_PROCESS_PENDING,
      payload: {
        initiateTransferProcessPending: true,
        initiateTransferProcessSuccess: false,
        initiateTransferProcessError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/initiate/transfer/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.INITIATE_TRANSFER_PROCESS_SUCCESS,
          payload: {
            initiateTransferProcessPending: false,
            initiateTransferProcessSuccess: true,
            initiateTransferProcessError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.INITIATE_TRANSFER_PROCESS_ERROR,
          payload: {
            initiateTransferProcessPending: false,
            initiateTransferProcessSuccess: true,
            initiateTransferProcessError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- add Patient vitals  --------------------------

export function transferPatientUpdateVitals(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_PENDING,
      payload: {
        transferPatientUpdateVitalsPending: true,
        transferPatientUpdateVitalsSuccess: false,
        transferPatientUpdateVitalsError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/update/patient/details/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_SUCCESS,
          payload: {
            transferPatientUpdateVitalsPending: false,
            transferPatientUpdateVitalsSuccess: true,
            transferPatientUpdateVitalsError: null,
          },
        });
        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "Success",
        //     msg: response.message,
        //   })
        // );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_ERROR,
          payload: {
            transferPatientUpdateVitalsPending: false,
            transferPatientUpdateVitalsSuccess: true,
            transferPatientUpdateVitalsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- assign hospital --------------------------

export function transferPatientAssignHospital(transferId, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_PENDING,
      payload: {
        transferPatientAssignedHospitalPending: true,
        transferPatientAssignedHospitalSuccess: false,
        transferPatientAssignedHospitalError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/transfer/assign/hospital/${transferId}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_SUCCESS,
          payload: {
            transferPatientAssignedHospitalPending: false,
            transferPatientAssignedHospitalSuccess: true,
            transferPatientAssignedHospitalError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_ERROR,
          payload: {
            transferPatientAssignedHospitalPending: false,
            transferPatientAssignedHospitalSuccess: true,
            transferPatientAssignedHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- assign Ambulance* --------------------------

export function transferPatientAssignAmbulance(transferId, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_PENDING,
      payload: {
        transferPatientAssignedAmbulancePending: true,
        transferPatientAssignedAmbulanceSuccess: false,
        transferPatientAssignedAmbulanceError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/transfer/assign/ambulance/${transferId}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_SUCCESS,
          payload: {
            transferPatientAssignedAmbulancePending: false,
            transferPatientAssignedAmbulanceSuccess: true,
            transferPatientAssignedAmbulanceError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_ERROR,
          payload: {
            transferPatientAssignedAmbulancePending: false,
            transferPatientAssignedAmbulanceSuccess: true,
            transferPatientAssignedAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- add vehicle manually --------------------------

export function transferPatientAddVehicle(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_PENDING,
      payload: {
        transferPatientAddVehiclePending: true,
        transferPatientAddVehicleSuccess: false,
        transferPatientAddVehicleError: null,
      },
    });
    dispatch(showLoader());

    Api.post("vehicle/add", data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_SUCCESS,
          payload: {
            transferPatientAddVehiclePending: false,
            transferPatientAddVehicleSuccess: true,
            transferPatientAddVehicleError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_ERROR,
          payload: {
            transferPatientAddVehiclePending: false,
            transferPatientAddVehicleSuccess: true,
            transferPatientAddVehicleError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// --------------------------------- transfer confirm --------------------------

export function transferPatientConfirmDetails(transferId, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TRANSFER_PATIENT_DETAILS_PENDING,
      payload: {
        transferPatientPending: true,
        transferPatientSuccess: false,
        transferPatientError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/transfer/add/note/${transferId}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_DETAILS_SUCCESS,
          payload: {
            transferPatientPending: false,
            transferPatientSuccess: true,
            transferPatientError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.TRANSFER_PATIENT_DETAILS_ERROR,
          payload: {
            transferPatientPending: false,
            transferPatientSuccess: true,
            transferPatientError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
