import React, { useEffect } from "react";
import {
  Input,
  CardView,
  ThemedButton,
  Imageuploader,
  FormSearchView,
  Checkbox,
  TogglebuttonGroup,
} from "../../../controls";
import Tabview from "../../../controls/Tab/Tabview";
import HospitalCenterContainer from "./HospitalCenter/HospitalCenterContainer";
import { PopupDialog } from "../Components";
import { globalConstants } from "../../../../constants/globalConstants";
import "./HospitalInfoStyles.scss";
import RadioButtonGroup from "../../../controls/RadioButtonGroup/RadioButtonGroup";

const HospitalInfoView = (props) => {
  console.log("hubType    *", props.hubType);
  useEffect(() => {
    if (props.TabData || props.hospitalList) {
      props.onHandlePopulateHospitalByIdData(props.TabData, props.hospitalList);
    }
  }, [props.TabData, props.hospitalList]);

  const renderViewHospitalLogoContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.hospitalLogo}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  return (
    <div className="my_hospital_container_main">
      <div className="my_hospital_container">
        <CardView extraStyle="hospital_card">
          <div
            style={{ paddingBottom: "50px" }}
            className="hospital_card_header"
          >
            {props?.hospitalList.length > 0 ? (
              <Tabview
                TabListFunc={props.hospitalList}
                handleDataSet={props.handleTabData}
              />
            ) : (
              <div>
                <text className="hospital_title">Hospitals</text>
                <div className="separator" />
              </div>
            )}

            {props?.hospitalList.length > 0 ? (
              <div>
                <div className="form-wrapper-container-hospital-info">
                  <div className="block-level-group ">
                    <text className="form-label">
                      <span className="asterisk">*</span>Hospital Name :
                    </text>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Hospital Name"
                        size="sm"
                        onChange={props.handleChange("hospitalName")}
                        value={props?.hospitalName}
                        error={props.errors["hospitalName"]}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group ">
                    <text className="form-label">
                      {/* <span>Hospital Name :</span> */}
                    </text>
                    <div className="value-item">
                      <div className="check-selectors">
                        <div className="checkbox">
                          <Checkbox
                            onChange={props.onCheckChange("reportService")}
                            checked={props.reportService}
                          />
                          <label className="boxLabel">Reporting</label>
                        </div>
                        <div className="checkbox">
                          <Checkbox
                            onChange={props.onSpokeCheckBoxChange}
                            checked={props.isSpoke}
                          />
                          <label className="boxLabel">Spoke</label>
                        </div>
                        <div className="checkbox">
                          <Checkbox
                            onChange={props.onHubCheckBoxChange}
                            checked={props.isHub}
                          />
                          <label className="boxLabel">Hub</label>
                        </div>
                        {props.showHospitalHubTypeToggle ? (
                          <div className="hospital-type-toggle">
                            <TogglebuttonGroup
                              smallSize={true}
                              customToggleData={props.HospitalHubTypeToggleData}
                              onItemSelected={props.onChangeHubHospitalType}
                              selectedItemId={props.hubType?.id}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group ">
                    <text className="form-label"></text>
                    <div className="value-item">
                      <RadioButtonGroup
                        name="hospital-category-type-group"
                        value={props.hospitalType}
                        onItemSelected={props.onGovermentOrPrivateChange}
                        data={props.hospitalTypeData}
                        size="lg"
                        // error={props.errors["hospitalType"]}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>Reporting Hospital :
                    </label>
                    <div className="value-item extra">
                      <FormSearchView
                        Options={props.reportingHospitalList}
                        placeholder="Search Reporting Hospital Name"
                        previousValue={props.reportingName}
                        onInputChange={props.onReportingHospitalSearchChange}
                        onChange={props.onReportingHospitalChange}
                        onScroll={props.onScrollReportingHospitalList}
                        searchText={props.searchReportHospitalText}
                        error={props.errors["reportingName"]}
                        disable={props.reportService}
                        lazyLoading={true}
                        customInputSearch={true}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group ">
                    <text className="form-label">
                      {" "}
                      <span className="required">* </span>Contact :
                    </text>
                    <div className="value-item">
                      {/* <text className="mobile-code">+91</text> */}
                      <Input
                        type="tel"
                        isvalnum="isNum"
                        className="input-wrapper"
                        placeholder="Enter your Contact Number"
                        size="sm"
                        value={props?.contactNumber}
                        onChange={props.handleChange("contactNumber")}
                        maxLength={10}
                        showMobilePrefix={true}
                        error={props.errors["contactNumber"]}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>Address :
                    </label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Address Line1"
                        value={props?.addressLine1}
                        error={props.errors["addressLine1"]}
                        // disabled={props.showEditAdminPopup}
                        onChange={props.handleChange("addressLine1")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  {/* <div className="block-level-group">
                    <label className="form-label">Address Line2 :</label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Address Line2"
                        value={props?.addressLine2}
                        // error={props.errors["addressLine2"]}
                        onChange={props.handleChange("addressLine2")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div> */}

                  {/* <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>Location :
                    </label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Location"
                        value={props?.location}
                        error={props.errors["location"]}
                        onChange={props.handleChange("location")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div> */}

                  <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>State :
                    </label>
                    <div className="value-item extra">
                      <FormSearchView
                        Options={props?.stateList}
                        placeholder="Search State"
                        onChange={props.onStateChange}
                        error={props.errors["state"]}
                        // lazyLoading={false}
                        previousValue={props?.state}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>District :
                    </label>
                    <div className="value-item extra">
                      <FormSearchView
                        Options={props?.districtList}
                        disable={props?.state?.value ? false : true}
                        placeholder="Search District"
                        onChange={props.onDistrictChange}
                        error={props.errors["district"]}
                        // lazyLoading={false}
                        // multiSelect={false}
                        previousValue={props?.district}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">
                      <span className="required">* </span>Pincode :
                    </label>
                    <div className="value-item extra">
                      <FormSearchView
                        Options={props?.pincodeList}
                        disable={props?.district?.value ? false : true}
                        placeholder="Search Pincode"
                        onChange={props.onPincodeChange}
                        error={props.errors["pincode"]}
                        // lazyLoading={false}
                        // multiSelect={false}
                        previousValue={props?.pincode}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">Area/Village :</label>
                    <div className="value-item">
                      <Input
                        extraStyle={"display-text-view"}
                        className="input-wrapper"
                        placeholder="Area/Village"
                        readOnly={true}
                        value={
                          props?.village == null || props?.village == ""
                            ? ""
                            : props?.village?.name
                        }
                        // disable={props.pincodeList.length > 0?false:true}
                        // onChange={handleChange("village")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">Landmark :</label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Landmark"
                        value={props?.landmark}
                        onChange={props.handleChange("landmark")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">Latitude :</label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Latitude"
                        value={props?.latitude}
                        onChange={props.handleChange("latitude")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group">
                    <label className="form-label">Longitude :</label>
                    <div className="value-item">
                      <Input
                        className="input-wrapper"
                        placeholder="Enter Longitude"
                        value={props?.longitude}
                        onChange={props.handleChange("longitude")}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group top-placement">
                    <text className="form-label top-placement">
                      Hospital logo :{" "}
                    </text>
                    <div className="value-item">
                      <Imageuploader
                        imageUrl={props.hospitalLogo}
                        uploadFileHandle={props.onHandleUploadHospitalLogoImage}
                        viewIconHandle={props.viewHospitalLogoClicked}
                        deleteIconHandle={props.deleteHospitalLogoClicked}
                        type={globalConstants.HOSPITAL_LOGO_IMAGE}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>

                  <div className="block-level-group ">
                    <div className="form-label" />
                    <div className="value-item">
                      <ThemedButton
                        title={"Cancel"}
                        size={"small"}
                        styles="grey-outline"
                        onClick={() => {
                          props.onCancelClick();
                        }}
                      />

                      <ThemedButton
                        title={"Reset"}
                        size={"small"}
                        styles="primary-outline"
                        onClick={props.onHandleReset}
                      />

                      <ThemedButton
                        title={"Save"}
                        size={"small"}
                        onClick={() => {
                          props.onSave();
                        }}
                      />
                    </div>
                    <div className="last-dummy-item" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="plainText">No hospitals found.</div>
            )}
          </div>
        </CardView>
      </div>

      <div className="my_hospital_container" style={{ paddingTop: "0px" }}>
        <CardView extraStyle="hospital_card">
          <div style={{ paddingBottom: "50px" }}>
            <div style={{ paddingBottom: "50px" }}>
              <HospitalCenterContainer
                tabData={props.HospitalDetails?.hospital}
                hospitalList={props.hospitalList}
              />
            </div>
          </div>
        </CardView>
      </div>

      <PopupDialog
        show={props.hospitalLogoFlag}
        title={"View Hospital Logo"}
        showCloseIcon={true}
        renderBodyContent={renderViewHospitalLogoContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewHospitalLogo}
        popupSize={"md"}
      />
    </div>
  );
};

export default HospitalInfoView;
