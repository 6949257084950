import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../../store/alert/alertActions";

import { roles } from "../../../../../constants/roles";
import {
  getStore,
  history,
} from "../../../../../store/configure/configureStore";
import { validateMobile } from "../../../../../utils/validator";
import * as validator from "../../../../../utils/validator";
import { globalConstants } from "../../../../../constants/globalConstants";
import HospitalCenterView from "./HospitalCenterView";
import { getHospitalAdminsList } from "../../../../../store/administrators/adminActions";
import {
  getCenterById,
  deleteHospitalCenter,
  editCenterHospital,
  addNewCenter,
  deleteHospitalCenterAdmin,
  addCenterAdmin,
} from "../../../../../store/hospital/hospitalActions";
import {
  getState,
  getDistrict,
  getPincode,
  getCity,
} from "../../../../../store/master/masterActions";
import { trimFormData } from "../../../../../utils/common";
import { strings } from "../../../../../constants/stringConstants";

const centerAdminInitdata = {
  CenterAdmincontact: "",

  firstName: "",
  lastName: "",

  email: "",
  password: "",
  confirmPassword: "",
};
class HospitalCenterContainer extends Component {
  validatorFields = {};
  state = {
    fields: {
      centerName: "",
      addressOne: "",
      address2: "",
      contact: "",
      location: "",
      CenterAdminFlag: false,

      state: "",
      district: "",

      pincode: "",
      village: "",
      landmark: "",

      ...centerAdminInitdata,
      isDeletedArchievedFlag: null,
      passwordShowFlag: false,
    },
    // isDeletedFlag: null,

    isValidationsOn: false,
    deleteAdminFlag: false,
    errors: {},
    centerInfo: null,
    showDeleteCenterPopup: false,
    addEditModalFlag: false,
    editModalFlag: false,
    getCenterData: null,
    centerAdminId: null,
    isEdiFormAdminAvailableFlag: false,
    stateList: [],
    districtList: [],

    pincodeList: [],
    arearData: null,
  };
  constructor(props) {
    super(props);
    this.validatorFields["required"] = [
      "centerName",
      "addressOne",
      "contact",
      "location",
      "CenterAdmincontact",
      "firstName",
      "lastName",
      "password",
      "confirmPassword",
      "email",

      "state",
      "district",
      "pincode",
      "village",
    ];
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.props.getState((list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });

      this.setState({ stateList: newList });
    });
  }

  componentWillUnmount() {}

  onHandleAddNewCenterOpenModal = () => {
    if (this.props.hospitalList.length > 0) {
      this.setState({
        addEditModalFlag: true,
      });
    } else {
      getStore().dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: "You cannot add a center as you are not yet assigned to any hospital",
        })
      );
    }
  };

  onHandleEditCenterOpenModal = (data) => {
    const { fields } = this.state;
    this.props.getCenterById(data?.centerId, (data) => {
      const stateData = this.state.stateList.find((item) => {
        return item?.value == data?.center?.addressInfo?.stateId;
      });

      this.props.getDistrict({ stateId: stateData?.value }, (destrictList) => {
        const newDestrictList = destrictList.map((item) => {
          return { value: item.id, label: item.name };
        });
        const districtData = newDestrictList.find((item) => {
          return item?.value == data?.center?.addressInfo?.districtId;
        });

        this.props.getPincode(
          { districtId: districtData?.value },
          (pincodeList) => {
            const newPincodeList = pincodeList.map((item) => {
              return { value: item.id, label: `${item.pincode}` };
            });

            const pincodeData = newPincodeList.find((item) => {
              return item.value == data?.center?.addressInfo?.pincodeId;
            });

            this.setState({
              ...this.state,
              pincodeList: newPincodeList,
              districtList: newDestrictList,
              getCenterData: data,
              editModalFlag: true,
              centerAdminId: data?.centerAdminDetails?.centerAdminId
                ? data?.centerAdminDetails?.centerAdminId
                : null,
              isEdiFormAdminAvailableFlag:
                data?.centerAdminDetails == null ? true : false,
              fields: {
                ...fields,
                CenterAdminFlag:
                  data?.centerAdminDetails?.centerAdminInfo?.contactNumber ||
                  data?.centerAdminDetails?.centerAdminInfo?.user?.userName ||
                  data?.centerAdminDetails?.centerAdminInfo?.firstName ||
                  data?.centerAdminDetails?.centerAdminInfo?.lastName
                    ? true
                    : false,
                CenterAdmincontact:
                  data?.centerAdminDetails?.centerAdminInfo?.contactNumber,
                email:
                  data?.centerAdminDetails?.centerAdminInfo?.user?.userName,

                address2: data?.center?.addressInfo?.addressLineTwo,
                addressOne: data?.center?.addressInfo?.addressLineOne,
                centerName: data?.center?.centerName,

                contact: data?.center?.addressInfo?.mobile,

                firstName: data?.centerAdminDetails?.centerAdminInfo?.firstName,
                lastName: data?.centerAdminDetails?.centerAdminInfo?.lastName,

                location: data?.center?.addressInfo?.locationName,

                password: data?.centerAdminDetails?.centerAdminInfo?.user
                  ?.userName
                  ? "********"
                  : "",
                confirmPassword: data?.centerAdminDetails?.centerAdminInfo?.user
                  ?.userName
                  ? "********"
                  : "",
                passwordShowFlag: data?.centerAdminDetails?.centerAdminInfo
                  ?.user?.userName
                  ? true
                  : false,
                village: data?.center?.addressInfo?.areaInfo,

                landmark: data?.center?.addressInfo?.landmark
                  ? data?.center?.addressInfo?.landmark
                  : "",

                state: stateData,
                district: districtData,
                pincode: pincodeData,
              },
            });
          }
        );
      });
    });
  };

  onDeleteCenterCardClick = (row) => {
    if (row) {
      // this.props.deleteDoctor(Did, () => {});
      this.setState({ centerInfo: row, showDeleteCenterPopup: true });
    }
  };

  onHandleAddCenterCloseModal = () => {
    this.setState(
      {
        addEditModalFlag: false,
        // validationFlag:true
      },
      () => {
        this.closeModalinitData();
      }
    );
  };
  onSaveDeleteAdminBtn = () => {
    this.props.deleteHospitalCenterAdmin(
      this.state.centerAdminId,
      this.props.tabData?.id,
      () => {
        this.setState({
          deleteAdminFlag: false,
          centerAdminId: null,
          isEdiFormAdminAvailableFlag: true,
          fields: {
            ...this.state.fields,
            CenterAdminFlag: false,
            passwordShowFlag: false,
            ...centerAdminInitdata,
          },

          // validationFlag:true
        });
        // this.onHandleEditCenterOpenModal();
      }
    );
  };

  onHandleDeleteAdminCloseModal = () => {
    this.setState({
      deleteAdminFlag: false,

      // validationFlag:true
    });
  };
  onHandleDeleteAdminOpenModal = () => {
    this.setState({
      deleteAdminFlag: true,
      // validationFlag:true
    });
  };
  onHandleEditCenterCloseModal = () => {
    const { fields } = this.state;
    this.setState(
      {
        editModalFlag: false,
        centerAdminId: null,

        // validationFlag:true
      },
      () => {
        this.closeModalinitData();
      }
    );
  };

  onHandleDeleteCenter = () => {
    this.props.deleteHospitalCenter(
      this.state.centerInfo?.centerId,
      this.props.tabData?.id,
      () => {}
    );
    this.setState({ centerInfo: null, showDeleteCenterPopup: false });
  };

  onHandlePopulatedData = (getCenterData) => {
    const { fields } = this.state;

    if (getCenterData) {
      this.setState({
        ...this.state,

        fields: {
          ...fields,
          CenterAdminFlag:
            getCenterData?.centerAdminDetails?.centerAdminInfo?.contactNumber ||
            getCenterData?.centerAdminDetails?.centerAdminInfo?.user
              ?.userName ||
            getCenterData?.centerAdminDetails?.centerAdminInfo?.firstName ||
            getCenterData?.centerAdminDetails?.centerAdminInfo?.lastName
              ? true
              : false,
          CenterAdmincontact:
            getCenterData?.centerAdminDetails?.centerAdminInfo?.contactNumber,
          email:
            getCenterData?.centerAdminDetails?.centerAdminInfo?.user?.userName,

          address2: getCenterData?.center?.addressInfo?.addressLineTwo,
          addressOne: getCenterData?.center?.addressInfo?.addressLineOne,
          centerName: getCenterData?.center?.centerName,

          contact: getCenterData?.center?.addressInfo?.mobile,

          firstName:
            getCenterData?.centerAdminDetails?.centerAdminInfo?.firstName,
          lastName:
            getCenterData?.centerAdminDetails?.centerAdminInfo?.lastName,

          location: getCenterData?.center?.addressInfo?.locationName,

          // password:,
          // confirmPassword:,
        },
      });
    }
  };

  getDistrictList = (stateId) => {
    this.props.getDistrict({ stateId: stateId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });
      this.setState({ districtList: newList });
    });
  };

  onStateChange = (value) => {
    const { fields } = this.state;
    if (value !== null && value !== undefined) {
      this.props.getDistrict({ stateId: value?.value }, (districtList) => {
        const newDestrictList = districtList.map((item) => {
          return { value: item.id, label: item.name };
        });

        this.setState({
          ...this.state,

          districtList: newDestrictList,
          fields: {
            ...fields,
            state: value,
            district: "",
            pincode: "",
            village: "",
            // reportService: false,
          },
          pincodeList: [],
          // districtList: [],
        });
      });
      this.state.errors["state"] = "";
    } else {
      this.setState({
        fields: {
          ...fields,
          state: "",
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
        districtList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["state"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("state");
    }
  };

  onDistrictChange = (value) => {
    const { fields } = this.state;
    if (value !== null && value !== undefined) {
      this.props.getPincode({ districtId: value?.value }, (pincodeList) => {
        const newPincodeList = pincodeList.map((item) => {
          return { value: item?.id, label: `${item?.pincode}` };
        });

        this.setState({
          ...this.state,

          pincodeList: newPincodeList,
          fields: { ...fields, district: value, pincode: "", village: "" },
        });
      });
      this.state.errors["district"] = "";
    } else {
      this.setState({
        fields: {
          ...fields,
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["district"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("district");
    }
  };
  onPincodeChange = (value) => {
    const { fields } = this.state;

    if (value !== null && value !== undefined) {
      this.props.getCity({ pincodeId: value.value }, (areaData) => {
        this.setState({
          ...this.state,
          fields: {
            // pincode: value,
            // village: areaData,
            ...fields,
            pincode: value,
            village: areaData,
          },
        });
      });
      this.state.errors["pincode"] = "";
    } else {
      this.setState({
        fields: {
          ...fields,
          pincode: "",
          village: "",
        },
        selectedVillage: {},
      });
      if (this.state.isValidationsOn) {
        this.state.errors["pincode"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("pincode");
    }
  };

  callGetHospitalAdminListAPI = () => {
    this.props.getHospitalAdminsList(
      this.state.filters,
      (listData, count, metadata) => {
        const newList = listData.map((item) => {
          return {
            label: `${
              (item?.firstName !== null && item?.firstName !== undefined
                ? `${item?.firstName
                    .charAt(0)
                    .toUpperCase()}${item?.firstName.slice(1)}`
                : "") +
              " " +
              (item?.lastName !== null && item?.lastName !== undefined
                ? `${item?.lastName
                    .charAt(0)
                    .toUpperCase()}${item?.lastName.slice(1)}`
                : "")
            }`,
            value: item.id,
          };
        });
        this.setState({ administratorList: newList, pageCount: 2 });
      },
      this.state.adminListPage,
      25
    );
  };

  onReportingHospitalChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            reportingName: value ?? "",
          },
          filters: { ...this.state.filters, text: "" },
          searchReportHospitalText: "",
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );
    } else {
      this.setState(
        {
          fields: {
            ...this.state.fields,
            reportingName: "",
          },
          filters: { ...this.state.filters, text: "" },
          searchReportHospitalText: "",
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );
    }
  };

  onReportingHospitalSearchChange = (text) => {
    this.state.searchReportHospitalText = text;
    this.setState(
      {
        filters: { ...this.state.filters, text: text },
      },
      () => {
        this.callGetOnlyReportingHospitalListAPI();
      }
    );
  };

  onScrollReportingHospitalList = () => {
    this.props.getReportingHospitalsListWithFilter(
      this.state.filters,
      (list) => {
        if (list.length > 0) {
          const listData = list.map((item) => {
            return { label: item.hospitalName, value: item.id };
          });
          this.setState({
            reportingHospitalList: [
              ...this.state.reportingHospitalList,
              ...listData,
            ],
          });
          ++this.state.pageCount;
        }
      },
      this.state.pageCount, // direct call from 2nd page bcoz 1st page alredy added
      25
    );
  };

  handleChange = (field) => (e) => {
    let value;
    const { fields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired = this.validatorFields.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  onCheckChange = (field) => (e) => {
    let value;
    const { fields, errors } = this.state;
    value = e.target.checked;

    if (e) {
      this.setState(
        { ...this.state, fields: { ...fields, [field]: value } },
        () => {
          if (field == "CenterAdminFlag" && value == false) {
            this.clearDataOnChecked();
          }
        }
      );
    }
  };

  clearDataOnChecked = () => {
    const { fields, errors } = this.state;
    const newError = { ...errors };

    for (let key in centerAdminInitdata) {
      delete newError[key];
    }

    this.setState({
      ...this.state,
      fields: {
        ...fields,
        ...centerAdminInitdata,
      },
      errors: newError,
    });
  };

  closeModalinitData = () => {
    this.setState({
      ...this.state,
      fields: {
        centerName: "",
        addressOne: "",
        address2: "",
        contact: "",
        location: "",
        state: "",
        district: "",

        pincode: "",
        village: "",
        landmark: "",
        ...centerAdminInitdata,

        CenterAdminFlag: false,
        passwordShowFlag: false,
      },
      showDeleteCenterPopup: false,
      isEdiFormAdminAvailableFlag: false,
      centerInfo: null,
      errors: {},
    });
  };

  onSave = () => {
    const { fields } = this.state;

    var custom = [];
    var required = [];
    required = [
      "centerName",
      "addressOne",
      "contact",
      "state",
      "district",
      "pincode",
    ];

    if (fields.contact) custom.push(validator.phone(["contact"]));
    if (fields.CenterAdmincontact)
      custom.push(validator.phone(["CenterAdmincontact"]));
    if (fields.CenterAdminFlag) custom.push(validator.email(["email"]));
    // if (fields.password && this.state.addEditModalFlag)
    //   custom.push(validator.password(["password"]));
    // if (fields.confirmPassword && this.state.addEditModalFlag)
    //   custom.push(validator.password(["confirmPassword"]));

    if (fields.password) {
      custom.push(validator.StrongPassword(["password"]));
      custom.push(validator.PasswordWithoutSpaces(["password"]));
      custom.push(validator.password(["password"]));
    }
    // if (fields.confirmPassword)
    //   custom.push(validator.password(["confirmPassword"]));

    if (fields?.CenterAdminFlag) {
      required = [
        ...required,
        "CenterAdmincontact",
        "firstName",
        "lastName",
        "password",
        "confirmPassword",
        "email",
      ];
    }
    // if (this.state.addEditModalFlag && fields?.CenterAdminFlag) {
    //   required = [...required, "confirmPassword", "password"];
    // }

    const validatorFields = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );
    if (
      fields.confirmPassword.length >= 0 &&
      fields.password !== fields.confirmPassword
    ) {
      errors["confirmPassword"] = strings.PASSWORD_MATCH_ERROR;
    }

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      if (this.state.addEditModalFlag) {
        this.props.addNewCenter(fields, this.props.tabData?.id, (response) => {
          if (this.state.fields.CenterAdminFlag) {
            this.props.addCenterAdmin(
              fields,
              response?.data?.id,
              (response1) => {
                this.onHandleAddCenterCloseModal();
              }
            );
          } else {
            if (response?.data?.id) {
              this.onHandleAddCenterCloseModal();
            }
          }
        });
      } else {
        if (this.state.isEdiFormAdminAvailableFlag && fields?.CenterAdminFlag) {
          this.props.addCenterAdmin(
            fields,
            this.state.getCenterData?.center?.id,
            (response) => {}
          );
          this.props.editCenterHospital(
            this.state.getCenterData?.center?.id,
            fields,
            this.props.tabData?.id,
            (response) => {}
          );
          this.onHandleEditCenterCloseModal();
        } else {
          this.props.editCenterHospital(
            this.state.getCenterData?.center?.id,
            fields,
            this.props.tabData?.id,
            (response) => {
              if (response?.data?.id) {
                this.onHandleEditCenterCloseModal();
              }
            }
          );
        }
      }

      // this.onHandleAddEditCenterCloseModal();
    }
  };

  render() {
    const { tabData, getCenterData, hospitalList } = this.props;
    const { addEditModalFlag, editModalFlag, fields, errors } = this.state;

    return (
      <HospitalCenterView
        userRole={this.state.userRole}
        addEditModalFlag={addEditModalFlag}
        onHandleAddEditCenterCloseModal={this.onHandleAddEditCenterCloseModal}
        onHandleAddNewCenterOpenModal={this.onHandleAddNewCenterOpenModal}
        tabData={tabData}
        onHandleEditCenterOpenModal={this.onHandleEditCenterOpenModal}
        editModalFlag={editModalFlag}
        getCenterData={this.state.getCenterData}
        onHandleDeleteCenter={this.onHandleDeleteCenter}
        onCheckChange={this.onCheckChange}
        handleChange={this.handleChange}
        fields={fields}
        onHandlePopulatedData={this.onHandlePopulatedData}
        errors={errors}
        onSave={this.onSave}
        onHandleAddCenterCloseModal={this.onHandleAddCenterCloseModal}
        onHandleDeleteAdminCloseModal={this.onHandleDeleteAdminCloseModal}
        onHandleEditCenterCloseModal={this.onHandleEditCenterCloseModal}
        onDeleteCenterCardClick={this.onDeleteCenterCardClick}
        closeModalinitData={this.closeModalinitData}
        showDeleteCenterPopup={this.state.showDeleteCenterPopup}
        centerInfo={this.state.centerInfo}
        deleteAdminFlag={this.state.deleteAdminFlag}
        onHandleDeleteAdminOpenModal={this.onHandleDeleteAdminOpenModal}
        onSaveDeleteAdminBtn={this.onSaveDeleteAdminBtn}
        centerAdminId={this.state.centerAdminId}
        onStateChange={this.onStateChange}
        onDistrictChange={this.onDistrictChange}
        onPincodeChange={this.onPincodeChange}
        stateList={this.state.stateList}
        districtList={this.state.districtList}
        pincodeList={this.state.pincodeList}
      />
    );
  }
}

HospitalCenterContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    securityQuestions: state.master.securityQuestions,
    // getCenterData: state.hospital.getCenterData,
  };
};

export default connect(mapStateToProps, {
  getHospitalAdminsList,
  getCenterById,
  deleteHospitalCenter,
  addNewCenter,
  editCenterHospital,
  deleteHospitalCenterAdmin,
  addCenterAdmin,
  getState,
  getDistrict,
  getPincode,
  getCity,
  //   getMyProfile,
  //   updateMyProfile,
})(HospitalCenterContainer);
