import React, { Component } from "react";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { logout } from "../../../../store/auth/authActions";

import TestScreenView from "./TestScreenView";

class TestScreenContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { loginUser, logout } = this.props;

    return <TestScreenView loginUser={loginUser} logout={logout} />;
  }
}

TestScreenContainer.propTypes = {};

const mapStateToProps = (state) => ({ loginUser: state.auth.loginUser });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout: logout }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestScreenContainer);
