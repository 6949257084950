import React from "react";
import {
  CardView,
  SearchView,
  ThemedButton,
  TogglebuttonGroup,
} from "../../../../controls";
import { AccordionList } from "../../Components";
import { FaAngleLeft } from "react-icons/fa6";

import "../HospitalsStyles.scss";

const CentersView = (props) => {
  // console.log("CentersView : ", JSON.stringify(props.hospitalList));

  //-------------- Centers > Devices/Doctors View ------------------
  console.log("change toggle", props.toggleValue);
  const renderCentersAccordian = () => {
    return props.centersList && props.centersList.length > 0 ? (
      <div style={{ paddingTop: "20px" }}>
        <AccordionList dataList={props.centersList} type="device" />
      </div>
    ) : (
      <div className="no-centers-placeholder">
        <text>No centers available</text>
      </div>
    );
  };

  const renderCentersAccordianForDoctors = () => {
    return props.doctorsList && props.doctorsList.length > 0 ? (
      <div style={{ paddingTop: "20px" }}>
        <AccordionList dataList={props.doctorsList} type="doctor" />
      </div>
    ) : (
      <div className="no-centers-placeholder">
        <text>No doctors available</text>
      </div>
    );
  };

  //---------------------------------------------------------------

  return (
    <div className="hospitals-container-new">
      <CardView
        extraStyle="card-container accordian-bg "
        cardstyle="card-style"
      >
        <div className="card-header-wrapper">
          <div className="title">
            <ThemedButton
              icon={<FaAngleLeft size={15} color="rgba(0,0,0,0.45)" />}
              onClick={() => {
                props.onHospitalNameBackClick();
              }}
              styles="link "
            />

            {props.selectedHospital?.hospitalName ?? "-"}
          </div>

          <div className="search-and-buttons">
            <TogglebuttonGroup
              selectedItemId={props.toggleValue}
              onItemSelected={props.onToggleChange}
              customToggleData={props.toggleData}
            />
            {/* <SearchView placeholder="input search text" /> */}
          </div>
        </div>
        {props.toggleValue === "centers"
          ? renderCentersAccordian()
          : renderCentersAccordianForDoctors()}
      </CardView>
    </div>
  );
};

export default CentersView;
