import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  getReportCountByDoctorWithFilter,
  ReportDownload,
  ReportPrint,
  setSelectedDoctor,
  setReportScreensState,
  getReportScreensState,
} from "../../../../store/report/reportActions";
import { roles } from "../../../../constants/roles";
import { history } from "../../../../store/configure/configureStore";
import { showAlert } from "../../../../store/alert/alertActions";
import EcgReportsView from "./EcgReportsView";
import moment from "moment";

class EcgReportsByDoctorContainer extends Component {
  startDayForLastSevenDaysToggle = moment()
    .subtract(6, "days")
    .format("DD-MM-YYYY");

  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      reportList: [],
      totalReports: 0,
      //
      toggleValue: "lastSevenDays",
      toggleData: [
        {
          id: "today",
          name: "Today",
        },
        {
          id: "lastSevenDays",
          name: "Last 7 Days",
        },
      ],
      dateRangeStartDate: null,
      dateRangeEndDate: null,
      filters: {
        startTime: moment(
          this.startDayForLastSevenDaysToggle,
          "DD-MM-YYYY"
        ).unix(), //moment().startOf("day").unix(),
        endTime: moment().endOf("day").unix(),
        text: "",
      },

      // This is needed only when we are coming back from View Reports page (sending a search string as param to searchView). BY DEFAULT THIS SHOULD BE 'null'.
      searchStringParam: null,
    };

    this.saveThisScreensStateInRedux =
      this.saveThisScreensStateInRedux.bind(this);
    this.callGetReportCountsAPI = this.callGetReportCountsAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);

    this.onToggleChange = this.onToggleChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onViewDoctorsReportsClick = this.onViewDoctorsReportsClick.bind(this);
    this.onECGReportByDoctorListSortClick =
      this.onECGReportByDoctorListSortClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    var previousState = getReportScreensState()?.doctorListScreen ?? {}; // = this.props.location?.state ?? {};
    console.log("ECG RepByDoc prevState ::: ", JSON.stringify(previousState));

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;
    var prevToggle =
      previousState?.dateRangeStartDate && previousState?.dateRangeStartDate
        ? null
        : previousState?.toggleValue
        ? previousState?.toggleValue
        : "lastSevenDays";
    var prevDateRangeStart = previousState?.dateRangeStartDate ?? null;
    var prevDateRangeEnd = previousState?.dateRangeEndDate ?? null;
    var prevFilters = previousState?.filters ?? {
      startTime: moment(
        this.startDayForLastSevenDaysToggle,
        "DD-MM-YYYY"
      ).unix(), //moment().startOf("day").unix(),
      endTime: moment().endOf("day").unix(),
      text: "",
    };
    var searchString = prevFilters?.text !== "" ? prevFilters.text : null;

    this.setState(
      {
        page: prevPage,
        limit: prevLimit,
        toggleValue: prevToggle,
        dateRangeStartDate:
          prevDateRangeStart !== null ? moment(prevDateRangeStart) : null,
        dateRangeEndDate:
          prevDateRangeEnd !== null ? moment(prevDateRangeEnd) : null,
        filters: prevFilters,
        searchStringParam: searchString,
      },
      () => {
        window.history.replaceState({}, "");
        this.callGetReportCountsAPI();
      }
    );
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {}

  // -------------- List parsing  ---------------

  saveThisScreensStateInRedux = () => {
    var savedPageStates = getReportScreensState();
    savedPageStates.doctorListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);
  };

  callGetReportCountsAPI = () => {
    this.props.getReportCountByDoctorWithFilter(
      this.state.filters,
      (listData, count) => {
        this.populateListData(listData, count);
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  populateListData = (listData, count) => {
    var reportListData = listData; // Redux data
    var reportListByDoctors = [];

    for (let index = 0; index < reportListData.length; index++) {
      const reportElement = reportListData[index];

      reportListByDoctors.push({
        doctorId: reportElement.doctorId
          ? reportElement.doctorId
          : reportElement.doctorInfo?.id
          ? reportElement.doctorInfo.id
          : null,
        doctorInfo: reportElement.doctorInfo,
        hospitalName: reportElement.hospitalInfo?.hospitalName ?? "-",
        hospitalId: reportElement.hospitalInfo?.id ?? null,
        reportCount: parseInt(reportElement.reportCount ?? 0),
      });
    }

    // console.log("ECG reportListByDoctors : ", JSON.stringify(reportListByDoctors));

    // Set data in state variables
    this.setState({
      reportList: reportListByDoctors,
      totalReports: count,
    });

    this.forceUpdate();
  };

  // -------------- Pagination  ---------------

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalReports;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportCountsAPI();
      }
    );
  };

  handlePageChange = (page) => {
    console.log("handlePageChange");
    this.setState(
      {
        page: page,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportCountsAPI();
      }
    );
  };

  // -------------- Column Button clicks ---------------

  onViewDoctorsReportsClick = (doctorName, doctorId, hospitalId) => {
    // console.log("View Reports for doctor --> ", doctorName, ",", doctorId);
    // console.log("View Reports for doctor --> ", hospitalId);

    this.props.setSelectedDoctor({
      name: doctorName,
      id: doctorId,
      hospitalId: hospitalId,
      //date-time filters also need to be same on next page
      toggleValue: this.state.toggleValue,
      dateRangeStartDate: this.state.dateRangeStartDate,
      dateRangeEndDate: this.state.dateRangeEndDate,
      filters: this.state.filters,
    });

    var savedPageStates = getReportScreensState();
    savedPageStates.isComingFromDoctorsList = true;
    savedPageStates.isComingFromDeviceList = false;
    savedPageStates.doctorListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);

    setTimeout(() => {
      history.push({
        pathname: "/ecg_reports",
        // state: {
        //   page: this.state.page,
        //   limit: this.state.limit,
        //   toggleValue: this.state.toggleValue,
        //   dateRangeStartDate: this.state.dateRangeStartDate,
        //   dateRangeEndDate: this.state.dateRangeEndDate,
        //   filters: this.state.filters,
        // },
      });
    }, 500);
  };

  // -------------- Filters changed ---------------

  onToggleChange = (toggleVal) => {
    var startDate = null;
    var endDate = null;

    if (toggleVal === "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }

    if (toggleVal === "lastSevenDays") {
      var startDay = moment().subtract(6, "days").format("DD-MM-YYYY");
      startDate = moment(startDay, "DD-MM-YYYY").unix();
      endDate = moment().endOf("day").unix();

      console.log("before 7 days: ", startDay, " , ", startDate);
    }

    // if (toggleVal === "month") {
    //   startDate = moment().startOf("month").unix();
    //   endDate = moment().endOf("month").unix();
    // }
    // if (toggleVal === "year") {
    //   startDate = moment().startOf("year").unix();
    //   endDate = moment().endOf("year").unix();
    // }

    this.setState(
      {
        toggleValue: toggleVal,
        dateRangeStartDate: null,
        dateRangeEndDate: null,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportCountsAPI();
      }
    );
  };

  onDatesChange = (start, end) => {
    console.log("onDatesChange, ", start, ", ", end);

    var startDate = moment(start).startOf("day").unix();
    var endDate = moment(end).endOf("day").unix();

    this.setState(
      {
        toggleValue: null,
        dateRangeStartDate: start,
        dateRangeEndDate: end,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportCountsAPI();
      }
    );
  };

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        {
          page: 1,
          filters: { ...this.state.filters, text },
          searchStringParam: null,
        },
        () => {
          this.saveThisScreensStateInRedux();
          this.callGetReportCountsAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      {
        page: 1,
        filters: { ...this.state.filters, text: "" },
        searchStringParam: null,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportCountsAPI();
      }
    );
  };

  //sorting filters

  onECGReportByDoctorListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Doctor Name") {
      columnName = "doctorName";
    } else if (column === "Hospital") {
      columnName = "hospitalName";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getReportCountByDoctorWithFilter(
          this.state.filters,
          (listData, count) => {
            this.populateListData(listData, count);
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <EcgReportsView
        // For showing Report List Columns as per user role
        userRole={this.state.userRole}
        //
        reportList={this.state.reportList}
        totalReports={this.state.totalReports}
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        //
        onViewDoctorsReportsClick={this.onViewDoctorsReportsClick}
        //
        toggleValue={this.state.toggleValue}
        toggleData={this.state.toggleData}
        onToggleChange={this.onToggleChange}
        //
        dateRangeStartDate={this.state.dateRangeStartDate}
        dateRangeEndDate={this.state.dateRangeEndDate}
        onDatesChange={this.onDatesChange}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        searchStringParam={this.state.searchStringParam}
        //
        onECGReportByDoctorListSortClick={this.onECGReportByDoctorListSortClick}
      />
    );
  }
}

EcgReportsByDoctorContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  getReportCountByDoctorWithFilter,
  ReportDownload,
  ReportPrint,
  setSelectedDoctor,
  setReportScreensState,
})(EcgReportsByDoctorContainer);
