import React from "react";
import CardView from "../../../../controls/Card/CardView";
import ecg_reports_card from "../../../../../assets/images/icons/ecg_reports_card.png";
import "./CardStyles.scss";

const ECGReportsCard = (props) => {
  return (
    <div className="super-admin-card-wrapper">
      <CardView
        extraStyle="ecg-report-card-control-new"
        bodyStyle={{ height: "180px" }}
      >
        <div className="card-title">
          <img className="card-icon" src={ecg_reports_card} alt="icon" />
          <span>ECG Reports (Today)</span>
        </div>

        <div className="info-wrapper">
          <div className="total-hospital-info">
            <span>Received Reports</span>
            <text className="highlight">{props.reportsGeneratedToday}</text>
          </div>
          <div className="total-hospital-info">
            <span>Pending Reports</span>
            <text className="highlight">{props.reportsPendingForToday}</text>
          </div>
        </div>

        <div className="footer-text">
          Total Reports Generated This Year
          <text className="px-2">
            {Intl.NumberFormat("en-US").format(parseInt(props.totalReports))}
          </text>
        </div>
      </CardView>
    </div>
  );
};

export default ECGReportsCard;
