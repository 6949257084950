import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";

import {
  getMyProfile,
  updateMyProfile,
  changePassword,
} from "../../../../store/auth/authActions";
import { resetUserPasswordByAdmin } from "../../../../store/auth/authActions";
import {
  getSupervisorList,
  getSupervisorDataById,
  addNewSupervisor,
  editSupervisor,
  deleteSupervisor,
  deleteSupervisorInBulk,
} from "../../../../store/supervisors/supervisorActions";
import { unlockUser } from "../../../../store/master/masterActions";
import { roles } from "../../../../constants/roles";
import { history } from "../../../../store/configure/configureStore";

import { globalConstants } from "../../../../constants/globalConstants";
import { trimFormData } from "../../../../utils/common";
import * as validator from "../../../../utils/validator";
import { strings } from "../../../../constants/stringConstants";
import SupervisorView from "./SupervisorView";
class SupervisorContainer extends Component {
  validatorFieldsForSupervisor = {};
  validatorFieldsForResetPassword = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForSupervisor["required"] = [
      "firstName",
      "lastName",
      "msci_id",
      "email",
      "contactNumber",
      "password",
      "confirmPassword",
      "qualification",
    ];

    this.validatorFieldsForResetPassword["required"] = [
      "password",
      "confirmPassword",
    ];

    this.state = {
      fields: {
        firstName: "",
        lastName: "",
        qualification: "",
        msci_id: "",
        contactNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      totalSupervisor: 0,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      filters: {
        text: "",
        status: "",
      },
      //
      supervisorList: [],
      //reset password
      resetPasswordSupervisorFields: {
        password: "",
        confirmPassword: "",
      },
      isValidationsOn: false,
      supervisorInfo: null,

      showDeleteSupervisorConfirmPopup: false,
      showDeleteSupervisorPopup: false,
      showResetPasswordSupervisorPopup: false,
      showEditSupervisorPopup: false,
      showAddSupervisorPopup: false,
      showUnlockSupervisorConfirmationPopup: false,

      // Bulk delete
      clearSelectedRows: false,
      selectedIdsToDelete: [],
      errors: {},
    };

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkSupervisorDeleteClick =
      this.onBulkSupervisorDeleteClick.bind(this);
    this.onBulkSupervisorDeleteConfirmClick =
      this.onBulkSupervisorDeleteConfirmClick.bind(this);
    this.onCloseDeleteSupervisorPopup =
      this.onCloseDeleteSupervisorPopup.bind(this);
    this.onDeleteSupervisorClick = this.onDeleteSupervisorClick.bind(this);
    this.onDeleteSupervisorConfirmClick =
      this.onDeleteSupervisorConfirmClick.bind(this);
    this.onResetPasswordSupervisorClick =
      this.onResetPasswordSupervisorClick.bind(this);
    this.onResetPasswordSupervisorConfirmClick =
      this.onResetPasswordSupervisorConfirmClick.bind(this);
    this.onCloseResetPasswordSupervisorPopup =
      this.onCloseResetPasswordSupervisorPopup.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onAddSupervisorClick = this.onAddSupervisorClick.bind(this);
    this.onAddSupervisorConfirmClick =
      this.onAddSupervisorConfirmClick.bind(this);

    this.onCloseAddSupervisorPopup = this.onCloseAddSupervisorPopup.bind(this);
    this.onCloseEditSupervisorPopup =
      this.onCloseEditSupervisorPopup.bind(this);
    this.onEditSupervisorClick = this.onEditSupervisorClick.bind(this);
    this.onEditSupervisorConfirmClick =
      this.onEditSupervisorConfirmClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);
    this.callGetSupervisorsListAPI = this.callGetSupervisorsListAPI.bind(this);

    this.onUnlockSupervisorAccountClick =
      this.onUnlockSupervisorAccountClick.bind(this);
    this.onUnlockSupervisorAccountConfirmClick =
      this.onUnlockSupervisorAccountConfirmClick.bind(this);
    this.closeUnlockSupervisorPopup =
      this.closeUnlockSupervisorPopup.bind(this);

    this.onResetPasswordSupervisorFieldChange =
      this.onResetPasswordSupervisorFieldChange.bind(this);

    this.onSupervisorListFilterClick =
      this.onSupervisorListFilterClick.bind(this);
    this.onSupervisorListSortClick = this.onSupervisorListSortClick.bind(this);

    this.resetSupervisorFormFields = this.resetSupervisorFormFields.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    var previousState = this.props.location?.state ?? {};
    console.log("Supervisor previousState ::: ", JSON.stringify(previousState));

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;

    this.setState({ page: prevPage, limit: prevLimit }, () => {
      window.history.replaceState({}, "");
      this.callGetSupervisorsListAPI();
    });

    // this.getMyProfileDetails();
  }

  componentWillUnmount() {}

  //-------------------------  Populate List -------------------------------

  populateListData = (listData, count) => {
    this.setState({
      supervisorList: listData,
      totalSupervisor: count,
    });
  };

  callGetSupervisorsListAPI = () => {
    this.props.getSupervisorList(
      this.state.filters,
      (listData, count, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(listData, count);
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  resetSupervisorFormFields = () => {
    this.setState({
      ...this.state,
      showAddSupervisorPopup: false,
      showEditSupervisorPopup: false,
      isValidationsOn: false,
      fields: {
        ...this.state.fields,
        firstName: "",
        lastName: "",
        qualification: "",
        msci_id: "",
        contactNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
    });
  };

  //-------------------- Delete Bulk Supervisors ---------------------------

  handleSelectedRowsChange = (selectedRowsArray, state) => {
    console.log("state", state);
    console.log("selectedRowsArray", selectedRowsArray);
    var supervisorIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({ selectedIdsToDelete: supervisorIds });
  };

  onBulkSupervisorDeleteClick = () => {
    this.setState({ showDeleteSupervisorConfirmPopup: true });
  };

  onBulkSupervisorDeleteConfirmClick = () => {
    this.props.deleteAdministratorInBulk(
      { supervisorIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showDeleteSupervisorConfirmPopup: false,
        });
        // this.callGetSupervisorsListAPI();
      }
    );
  };

  onCloseDeleteSupervisorPopup = () => {
    this.setState({
      showDeleteSupervisorPopup: false,
      showDeleteSupervisorConfirmPopup: false,
    });
  };

  //----------------------- Delete Supervisor  ---------------------------

  onDeleteSupervisorClick = (row) => {
    this.setState({ supervisorInfo: row, showDeleteSupervisorPopup: true });
  };

  onDeleteSupervisorConfirmClick = () => {
    this.props.deleteSupervisor(this.state.supervisorInfo.id, () => {
      this.setState({ showDeleteSupervisorPopup: false }, () => {
        this.callGetSupervisorsListAPI(); // refresh supervisor list
      });
    });
  };

  // reset password

  onResetPasswordSupervisorClick = (row) => {
    this.setState({
      supervisorInfo: row,
      showResetPasswordSupervisorPopup: true,
    });
  };

  onResetPasswordSupervisorConfirmClick = () => {
    const { fields, resetPasswordSupervisorFields } = this.state;

    var custom = [];
    var required = [];
    required = ["password", "confirmPassword"];

    if (resetPasswordSupervisorFields.password) {
      custom.push(validator.StrongPassword(["password"]));
      custom.push(validator.PasswordWithoutSpaces(["password"]));
      custom.push(validator.password(["password"]));
    }

    const validatorFields = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(resetPasswordSupervisorFields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    console.log(
      "*resetPasswordSupervisorFields",
      resetPasswordSupervisorFields
    );
    if (
      resetPasswordSupervisorFields.confirmPassword.length > 0 &&
      resetPasswordSupervisorFields.password !==
        resetPasswordSupervisorFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }
    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      const data = {
        userId: this.state.supervisorInfo?.userId,
        newPassword: this.state.resetPasswordSupervisorFields.password?.trim(),
      };
      this.props.resetUserPasswordByAdmin(data, () => {
        this.setState({
          resetPasswordSupervisorFields: {
            ...this.state.resetPasswordSupervisorFields,
            password: "",
            confirmPassword: "",
          },
          showResetPasswordSupervisorPopup: false,
          isValidationsOn: false,
        });
      });
    }
  };

  onCloseResetPasswordSupervisorPopup = () => {
    this.setState({
      showResetPasswordSupervisorPopup: false,
      resetPasswordSupervisorFields: {
        ...this.state.resetPasswordSupervisorFields,
        password: "",
        confirmPassword: "",
      },
      isValidationsOn: false,
      errors: {},
    });
  };

  handleChange = (field) => (e) => {
    let value;
    const { fields, resetPasswordSupervisorFields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired =
      this.validatorFieldsForResetPassword.required.includes(field);
    var isRequiredAddEditSupervisorFields =
      this.validatorFieldsForSupervisor.required.includes(field);

    if (isNull) {
      if (
        (this.state.isValidationsOn && isRequired) ||
        (this.state.isValidationsOn && isRequiredAddEditSupervisorFields)
      )
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (
        (this.state.isValidationsOn && isRequired) ||
        (this.state.isValidationsOn && isRequiredAddEditSupervisorFields)
      ) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  onResetPasswordSupervisorFieldChange = (field) => (e) => {
    let value;
    const { resetPasswordSupervisorFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired =
      this.validatorFieldsForResetPassword.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      resetPasswordSupervisorFields: {
        ...resetPasswordSupervisorFields,
        [field]: value,
      },
    });
  };

  //--------------------- Add / Edit Supervisor Popup Data Handling ------------------------

  onAddSupervisorClick = () => {
    this.setState({
      showAddSupervisorPopup: true,
      showEditSupervisorPopup: false,
    });
  };

  onEditSupervisorClick = (row) => {
    this.validatorFieldsForSupervisor["required"] =
      this.validatorFieldsForSupervisor["required"].filter(function (item) {
        return item !== "password" && item !== "confirmPassword";
      });

    this.props.getSupervisorDataById(row.id, (details) => {
      if (details) {
        this.setState({
          showAddSupervisorPopup: false,
          showEditSupervisorPopup: true,
          supervisorInfo: details,
          isValidationsOn: true,
          fields: {
            ...this.state.fields,
            firstName: details?.firstName ?? "",
            lastName: details?.lastName ?? "",
            qualification: details?.qualification ?? "",
            msci_id: details?.registrationId ?? "",
            contactNumber: details?.contactNumber ?? "",
            email: details?.userInfo?.userName ?? "",
          },
        });
      }
    });
  };

  onAddSupervisorConfirmClick = () => {
    const { fields, resetPasswordSupervisorFields } = this.state;
    console.log("fields", fields);
    var custom = [
      validator.email(["email"]),
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
      validator.phone(["contactNumber"]),
    ];

    this.validatorFieldsForSupervisor["custom"] = custom;

    const { errors } = validator.validate(
      this.validatorFieldsForSupervisor,
      trimFormData(fields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (
      resetPasswordSupervisorFields.confirmPassword.length > 0 &&
      resetPasswordSupervisorFields.password !==
        resetPasswordSupervisorFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }
    console.log("error", errors);

    if (!Object.keys(errors).length) {
      const data = {
        email: fields.email,
        password: fields.password,
        firstName: fields.firstName,
        lastName: fields.lastName,
        contactNumber: fields.contactNumber,
        qualification: fields.qualification,
        registrationId: fields.msci_id,
      };
      this.props.addNewSupervisor(data, () => {
        this.resetSupervisorFormFields();
        this.callGetSupervisorsListAPI();
      });
    }
  };

  onEditSupervisorConfirmClick = () => {
    const { fields } = this.state;

    var custom = [validator.phone(["contactNumber"])];
    this.validatorFieldsForSupervisor["custom"] = custom;

    const { errors } = validator.validate(
      this.validatorFieldsForSupervisor,
      trimFormData(fields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });

    console.log("error", errors);
    if (!Object.keys(errors).length) {
      const data = {
        firstName: fields.firstName,
        lastName: fields.lastName,
        contactNumber: fields.contactNumber,
        qualification: fields.qualification,
        registrationId: fields.msci_id,
      };
      this.props.editSupervisor(this.state.supervisorInfo.id, data, () => {
        this.resetSupervisorFormFields();
        this.callGetSupervisorsListAPI();
        this.validatorFieldsForSupervisor["required"].push("password");
        this.validatorFieldsForSupervisor["required"].push("confirmPassword");
      });
    }
  };

  onCloseEditSupervisorPopup = () => {
    this.resetSupervisorFormFields();
  };

  onCloseAddSupervisorPopup = () => {
    this.resetSupervisorFormFields();
  };

  //---------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    console.log("text", text);
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetSupervisorsListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetSupervisorsListAPI();
      }
    );
  };

  //

  onUnlockSupervisorAccountClick = (row) => {
    this.setState({
      supervisorInfo: row,
      showUnlockSupervisorConfirmationPopup: true,
    });
  };

  onUnlockSupervisorAccountConfirmClick = () => {
    this.props.unlockUser(this.state.supervisorInfo.userId, () => {
      this.setState(
        {
          supervisorInfo: {},
          showUnlockSupervisorConfirmationPopup: false,
        },
        () => {
          this.callGetSupervisorsListAPI();
        }
      );
    });
  };

  closeUnlockSupervisorPopup = () => {
    this.setState({
      supervisorInfo: {},
      showUnlockSupervisorConfirmationPopup: false,
    });
  };

  //-----------------------------   pagination---------------------------------

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalSupervisor;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetSupervisorsListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetSupervisorsListAPI();
      }
    );
  };

  // sortings and filters

  onSupervisorListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    let filter =
      filterOption === "Active"
        ? 1
        : filterOption === "Occupied"
        ? 2
        : filterOption === "Inactive"
        ? 3
        : "";
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          status: parseInt(filter),
        },
      },
      () => {
        this.props.getSupervisorList(
          this.state.filters,
          (listData, count, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData, count);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onSupervisorListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Supervisor Name") {
      columnName = "firstName";
    }
    if (column === "MSCI ID") {
      columnName = "msci";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getSupervisorList(
          this.state.filters,
          (listData, count, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData, count);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <SupervisorView
        fields={this.state.fields}
        errors={this.state.errors}
        page={this.state.page}
        limit={this.state.limit}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        onBulkSupervisorDeleteClick={this.onBulkSupervisorDeleteClick}
        onBulkSupervisorDeleteConfirmClick={
          this.onBulkSupervisorDeleteConfirmClick
        }
        showDeleteSupervisorConfirmPopup={
          this.state.showDeleteSupervisorConfirmPopup
        }
        //
        supervisorInfo={this.state.supervisorInfo}
        supervisorList={this.state.supervisorList}
        //
        onCloseDeleteSupervisorPopup={this.onCloseDeleteSupervisorPopup}
        onDeleteSupervisorClick={this.onDeleteSupervisorClick}
        onDeleteSupervisorConfirmClick={this.onDeleteSupervisorConfirmClick}
        showDeleteSupervisorPopup={this.state.showDeleteSupervisorPopup}
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onResetPasswordSupervisorClick={this.onResetPasswordSupervisorClick}
        showResetPasswordSupervisorPopup={
          this.state.showResetPasswordSupervisorPopup
        }
        onResetPasswordSupervisorConfirmClick={
          this.onResetPasswordSupervisorConfirmClick
        }
        onCloseResetPasswordSupervisorPopup={
          this.onCloseResetPasswordSupervisorPopup
        }
        handleChange={this.handleChange}
        resetPasswordSupervisorFields={this.state.resetPasswordSupervisorFields}
        clearSelectedRows={this.state.clearSelectedRows}
        showAddSupervisorPopup={this.state.showAddSupervisorPopup}
        onAddSupervisorClick={this.onAddSupervisorClick}
        onAddSupervisorConfirmClick={this.onAddSupervisorConfirmClick}
        onCloseAddSupervisorPopup={this.onCloseAddSupervisorPopup}
        onCloseEditSupervisorPopup={this.onCloseEditSupervisorPopup}
        onEditSupervisorClick={this.onEditSupervisorClick}
        showEditSupervisorPopup={this.state.showEditSupervisorPopup}
        onEditSupervisorConfirmClick={this.onEditSupervisorConfirmClick}
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        totalSupervisor={this.state.totalSupervisor}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        // userRole={this.state.userRole}
        //
        onUnlockSupervisorAccountConfirmClick={
          this.onUnlockSupervisorAccountConfirmClick
        }
        onUnlockSupervisorAccountClick={this.onUnlockSupervisorAccountClick}
        closeUnlockSupervisorPopup={this.closeUnlockSupervisorPopup}
        showUnlockSupervisorConfirmationPopup={
          this.state.showUnlockSupervisorConfirmationPopup
        }
        onResetPasswordSupervisorFieldChange={
          this.onResetPasswordSupervisorFieldChange
        }
        //
        onSupervisorListFilterClick={this.onSupervisorListFilterClick}
        onSupervisorListSortClick={this.onSupervisorListSortClick}
      />
    );
  }
}

SupervisorContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  unlockUser,
  getSupervisorList,
  getSupervisorDataById,
  addNewSupervisor,
  editSupervisor,
  deleteSupervisor,
  deleteSupervisorInBulk,
  resetUserPasswordByAdmin,
})(SupervisorContainer);
