import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./StepperStyles.scss";
import { AiOutlineInbox } from "react-icons/ai";
import { CiCircleCheck } from "react-icons/ci";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Button,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const customstyles = {
  ".MuiStepConnector-root": {
    top: 0,
  },
  ".MuiSvgIcon-root": {
    color: "rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.25)",
  },
  ".MuiSvgIcon-root:not(.Mui-completed)": {
    color: "white",
  },
  ".MuiStepLabel-root": {
    gap: "4px",
  },
  ".MuiStepLabel-label": {
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: "400",
    fontSize: "14px",
  },
  // middle line
  "& .MuiStepConnector-line": {
    borderColor: "rgba(240, 240, 240, 1)",
    marginRight: "4px",
  },
  "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
    borderColor: "rgba(0, 180, 138, 1)",
  },
  "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
    borderColor: "rgba(0, 180, 138, 1)",
  },
  // middle icon and text
  ".MuiStepIcon-text": {
    fill: "rgba(0, 0, 0, 0.25)",
    fontWeight: "400",
    fontSize: "16px",
  },
  ".MuiSvgIcon-root": {
    color: "#1976d2",
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    transform: "scale(1.6)",
  },
  ".MuiSvgIcon-root.Mui-completed": {
    color: "rgba(0, 180, 138, 1)",
    borderRadius: "50%",
    border: "none",
    transform: "scale(1.6)",
  },
  ".MuiSvgIcon-root.Mui-active": {
    color: "rgba(0, 180, 138, 1)",
    borderRadius: "50%",
    border: "none",
    transform: "scale(1.6)",
  },
  ".Mui-active .MuiStepIcon-text": {
    fill: "white",
  },
};
const StepperView = ({ steps, parentStep = null, bodySteps, ...props }) => {
  useEffect(() => {
    if (parentStep !== null) {
      setActiveStep(parentStep);
    }
  }, [parentStep]);
  const [activeStep, setActiveStep] = React.useState(parentStep);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {};

  const maxSteps = steps.length;

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} sx={customstyles}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {bodySteps[activeStep]}
        </Box>
      </React.Fragment>
    </Box>
    // <Box sx={{ width: "100%" }}>
    //   <Stepper activeStep={activeStep}>
    // {steps.map((label, index) => {
    //       const stepProps = {};
    //       const labelProps = {};
    //       if (isStepOptional(index)) {
    //         labelProps.optional = (
    //           <Typography variant="caption">Optional</Typography>
    //         );
    //       }
    //       if (isStepSkipped(index)) {
    //         stepProps.completed = false;
    //       }
    //       return (
    //         <Step key={label} {...stepProps}>
    //           <StepLabel {...labelProps}>{label}</StepLabel>
    //         </Step>
    //       );
    //     })}
    //   </Stepper>
    //   {activeStep === steps.length ? (
    //     <React.Fragment>
    //       <Typography sx={{ mt: 2, mb: 1 }}>
    //         All steps completed - you're finished
    //       </Typography>
    //       <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
    //         <Box sx={{ flex: "1 1 auto" }} />
    //         <Button onClick={handleReset}>Reset</Button>
    //       </Box>
    //     </React.Fragment>
    //   ) : activeStep === 0 ? (
    //     <React.Fragment>
    //       <Typography sx={{ mt: 2, mb: 1 }}>hello</Typography>
    //       <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
    //         <Button
    //           color="inherit"
    //           disabled={activeStep === 0}
    //           onClick={handleBack}
    //           sx={{ mr: 1 }}
    //         >
    //           Back
    //         </Button>
    //         <Box sx={{ flex: "1 1 auto" }} />
    //         {isStepOptional(activeStep) && (
    //           <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
    //             Skip
    //           </Button>
    //         )}

    //         <Button onClick={handleNext}>
    //           {activeStep === steps.length - 1 ? "Finish" : "Next"}
    //         </Button>
    //       </Box>
    //     </React.Fragment>
    //   ) : (
    //     <React.Fragment>
    //       <Typography sx={{ mt: 2, mb: 1 }}>Hey</Typography>
    //       <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
    //         <Button
    //           color="inherit"
    //           disabled={activeStep === 0}
    //           onClick={handleBack}
    //           sx={{ mr: 1 }}
    //         >
    //           Back
    //         </Button>
    //         <Box sx={{ flex: "1 1 auto" }} />
    //         {isStepOptional(activeStep) && (
    //           <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
    //             Skip
    //           </Button>
    //         )}

    //         <Button onClick={handleNext}>
    //           {activeStep === steps.length - 1 ? "Finish" : "Next"}
    //         </Button>
    //       </Box>
    //     </React.Fragment>
    //   )}
    // </Box>
  );
};

StepperView.propTypes = {};

export default StepperView;
