import React from "react";
import CardView from "../../../controls/Card/CardView";
import {
  SearchView,
  ThemedButton,
  Input,
  FormSearchView,
  RadioButtonGroup,
  Imageuploader,
} from "../../../controls";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import { FaPlus } from "react-icons/fa6";
import {
  AiOutlineInbox,
  AiOutlineFileExcel,
  AiOutlineDelete,
} from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import PopupDialog from "../Components/Other/PopupDialog";
import "./AmbulancesStyles.scss";
import StepperView from "../../../controls/Stepper/StepperView";
import { roles } from "../../../../constants/roles";
import AmbulanceList from "../Components/Lists/AmbulanceList";
import { globalConstants } from "../../../../constants/globalConstants";
import { strings } from "../../../../constants/stringConstants";

const AmbulanceView = (props) => {
  //--------------render popup content ------------------
  const selectedAmbulanceName = props.ambulanceInfo?.ambulanceName ?? "";
  const renderAddBulkAmbulanceContents = () => {
    const selectHospital = () => {
      return (
        <div className="select-hospital-tab">
          <div className="block-level-group">
            <label className="form-label">Assign Hospital :</label>
            <div className="value-item extra">
              <FormSearchView
                Options={props.hospitalList}
                placeholder="Search Hospital Name"
                onChange={props.onAddBulkAmbulanceFieldChange(
                  "assignAmbulanceInBulk"
                )}
                lazyLoading={true}
                onInputChange={props.onHospitalSearchChange}
                customInputSearch={true}
                previousValue={props.assignAmbulanceInBulk}
                onScroll={props.onScrollHospitalList}
                error={props.errors["assignAmbulanceInBulk"]}
              />
            </div>
            <div style={{ width: "20%" }} />
          </div>
        </div>
      );
    };
    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const temp = document.getElementById("border-active");
      temp.style.border = "1px dashed #00B48A";
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const temp = document.getElementById("border-active");
      temp.style.border = "";
    };

    const uploadBox = () => {
      return (
        <div className="upload-box-file-details">
          <div className="upload-box">
            <form
              style={
                props.file.length > 0 ? { border: "1px solid #D9D9D9" } : {}
              }
              onClick={props.handleFileInputClick}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={props.handleFileDropClick}
              id="border-active"
            >
              <input
                className="file-input"
                type="file"
                name="file"
                ref={props.fileInputRef}
                hidden
                onChange={props.onAddBulkAmbulanceFieldChange("file")}
              />
              <AiOutlineInbox size={40} color="#00B48A" />
              <div className="text-head-sub">
                <text className="text-head">
                  <span>Click</span> or drag file to this area to upload
                </text>
                <text className="text-sub">only CSV (max 6MB)</text>
              </div>
            </form>
            <div className="file-error">{props.errors["file"]}</div>
          </div>
          {props.file.length > 0 ? (
            <div className="file-details">
              <div className="details">
                <div className="file-icon">
                  <AiOutlineFileExcel size={28} color="#00B48A" />
                </div>
                <div className="file-name">{props.fileName}</div>
                <div className="delete-icon">
                  <AiOutlineDelete
                    size={20}
                    cursor={"pointer"}
                    color="rgba(0, 0, 0, 0.45)"
                    onClick={props.handleDeleteFile}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    };
    const alert = () => {
      return (
        <div className="alert-modal-loading-area">
          <div className="alert-modal-content">
            <img className="delete-icon" src={logoutIcon} alt="icon" />
            <div className="alert-head-sub-text">
              <text className="alert-head">{`Are you sure you want to add Ambulances?`}</text>
              <text className="alert-sub">
                Assigned Hospital :
                {props.assignAmbulanceInBulk?.label
                  ? ` 
                  ${
                    props.assignAmbulanceInBulk?.label ?? "No Hospital Selected"
                  }, ${
                      props.detailsAssignAmbulanceInBulk?.addressInfo
                        ?.locationName ?? ""
                    }, ${
                      props.detailsAssignAmbulanceInBulk?.addressInfo
                        ?.districtInfo?.name ?? ""
                    }, ${
                      props.detailsAssignAmbulanceInBulk?.addressInfo?.stateInfo
                        ?.name ?? ""
                    }`
                  : " No Hospital Selected"}
              </text>
            </div>
          </div>
          <section className="loading-area">
            {/* <div className="loading-bar">
              <div
                className="loading"
                style={{ width: `${props.pregressPer}%` }}
              ></div>
            </div> */}
          </section>
        </div>
      );
    };
    const steps = ["Select File", "Assign Hospital", "Add Ambulances"];
    const multiSteps = [uploadBox(), selectHospital(), alert()];
    return (
      <div style={{ paddingTop: "10px" }}>
        <StepperView
          steps={steps}
          bodySteps={multiSteps}
          parentStep={props.activeStep}
        />
      </div>
    );
  };

  const renderDeleteAmbulancePopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>{`Are you sure you want to Delete Ambulance ${selectedAmbulanceName} ?`}</text>
      </div>
    );
  };

  const renderDeleteBulkAmbulancePopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        {deleteCount > 1 ? (
          <text>{`Are you sure you want to Delete ${deleteCount} Ambulances ?`}</text>
        ) : (
          <text>{`Are you sure you want to Delete ${deleteCount} Ambulance ?`}</text>
        )}
      </div>
    );
  };

  const renderAddNewAmbulanceContents = () => {
    return (
      <div className="add-new-ambulance-form">
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Ambulance Name :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Ambulance Name"
              value={props.addNewAmbulancefields.ambulanceName}
              onChange={props.onAddAmbulanceFieldChange("ambulanceName")}
              error={props.errors["ambulanceName"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Ambulance Number :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Ambulance Number"
              value={props.addNewAmbulancefields.ambulanceNo}
              onChange={props.onAddAmbulanceFieldChange("ambulanceNo")}
              error={props.errors["ambulanceNo"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group vertical-middle to-error">
          <label className="form-label">
            <span className="required">* </span> Ambulance Type :
          </label>
          <div className="value-item ">
            <RadioButtonGroup
              name="ambulance-types-group"
              data={props.ambulanceTypeData}
              error={props.errors["ambulanceType"]}
              size="lg"
              value={props.addNewAmbulancefields.ambulanceType}
              onItemSelected={props.onAddAmbulanceFieldChange("ambulanceType")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group vertical-middle to-error">
          <label className="form-label"></label>
          <div className="value-item ">
            <RadioButtonGroup
              name="ambulance-category-group"
              data={props.AmbulanceCategoryData}
              size="lg"
              value={props.ambCategoryType}
              onItemSelected={props.onAmbulanceGovtOrPvtChange}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group vertical-middle">
          <label className="form-label">Ambulance Image :</label>
          <div className="value-item">
            <Imageuploader
              imageUrl={props.ambulanceLogo}
              uploadFileHandle={props.onHandleUploadAmbulanceImage}
              viewIconHandle={props.viewAmbulanceLogoClicked}
              deleteIconHandle={props.deleteAmbulanceLogoClicked}
              type={globalConstants.AMBULANCE_IMAGE}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Assign Hospital :
          </label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.hospitalList}
              placeholder="Search Hospital Name"
              onChange={props.onAddAmbulanceFieldChange("assignHospital")}
              lazyLoading={true}
              previousValue={props.addNewAmbulancefields.assignHospital}
              onInputChange={props.onHospitalSearchChange}
              customInputSearch={true}
              searchText={props.searchHospitalText}
              onScroll={props.onScrollHospitalList}
              error={props.errors["assignHospital"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span>Email ID :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Email ID"
              value={props.addNewAmbulancefields.email}
              error={props.errors["email"]}
              disabled={props.showEditAmbulancePopup}
              onChange={props.onAddAmbulanceFieldChange("email")}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        {!props.showEditAmbulancePopup ? (
          <>
            {" "}
            <div
              className="block-level-group"
              style={{
                marginBottom:
                  props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                    ? "15px"
                    : "0px",
              }}
            >
              <label className="form-label">
                <span className="required">* </span>Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Password"
                  value={props.addNewAmbulancefields.password}
                  error={props.errors["password"]}
                  onChange={props.onAddAmbulanceFieldChange("password")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="block-level-group">
              <label className="form-label">
                <span className="required">* </span>Confirm Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Confirm Password"
                  value={props.addNewAmbulancefields.confirmPassword}
                  error={props.errors["confirmPassword"]}
                  onChange={props.onAddAmbulanceFieldChange("confirmPassword")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderViewAmbulanceLogoContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.ambulanceLogo}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  const renderUnlockMessage = () => {
    return (
      <div className="unlock-modal-content">
        <img className="unlock-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Unlock {selectedAmbulanceName}?</text>
      </div>
    );
  };

  //---------------------------------------------------------------

  return (
    <div className="ambulances-container-new">
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wraper">
          <div className="title">
            Total Ambulances : {props.totalAmbulances}
          </div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Ambulances"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                  updatedCurrentValue={props.searchStringParam}
                />

                {props.userRole === roles.hospitalAdmin ? (
                  <>
                    <ThemedButton
                      title="Add Bulk Ambulances"
                      size={"large"}
                      icon={<FaPlus size={15} color="#000" />}
                      onClick={() => {
                        props.onAddBulkAmbulancesClick();
                      }}
                      styles="grey-outline huge"
                    />

                    <ThemedButton
                      title="Add New Ambulance"
                      size={"large"}
                      icon={<FaPlus size={15} color="#fff" />}
                      onClick={() => {
                        props.onAddNewAmbulancesClick();
                      }}
                      styles="primary huge"
                    />
                  </>
                ) : // props.userRole === roles.hospitalAdmin ? (
                //   <ThemedButton
                //     title="Download"
                //     size={"medium"}
                //     icon={<AiOutlineDownload size={15} color="#fff" />}
                //     onClick={() => {}}
                //     styles="primary medium"
                //   />
                null}
              </>
            )}

            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkAmbulanceDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <AmbulanceList
          userRole={props.userRole}
          //
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          totalAmbulances={props.totalAmbulances}
          ambulanceList={props.ambulanceList}
          //
          onReportDeviceClick={props.onReportDeviceClick}
          onEditAmbulanceClick={props.onEditAmbulanceClick}
          onDeleteAmbulanceClick={props.onDeleteAmbulanceClick}
          onAssignTechnicianClick={props.onAssignTechnicianClick}
          //
          onRowClick={props.onRowClick}
          //
          disableSingleDeleteButton={props.disableSingleDeleteButton}
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          onUnlockAmbulanceAccountClick={props.onUnlockAmbulanceAccountClick}
          onAmbulanceListSortClick={props.onAmbulanceListSortClick}
          onAmbulanceListFilterClick={props.onAmbulanceListFilterClick}
        />
      </CardView>

      <PopupDialog
        show={props.showAddBulkAmbulancePopup}
        title={"Add Bulk Ambulances"}
        showCloseIcon={true}
        renderBodyContent={renderAddBulkAmbulanceContents}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.onCloseAddBulkAmbulancePopup}
        hideCancelButton={props.activeStep !== 0}
        showExtraButton={props.activeStep !== 0}
        extraButtonStyle="primary-outline"
        extraButtonTitle={"Back"}
        onClickExtra={props.handleBack}
        onclickConfirm={
          props.activeStep === 2
            ? props.addBulkAmbulancesConfirmClick
            : props.handleNext
        }
        confirmButtonTitle={
          props.activeStep === 1
            ? "Assign"
            : props.activeStep === 2
            ? "Add"
            : "Next"
        }
        // disabledExtraButton={!props.disableNextButton}
        disabledConfirmButton={
          props.activeStep === 2 ? false : props.disableNextButton
        }
        popupSize={"md"}
      />

      <PopupDialog
        show={
          props.showEditAmbulancePopup
            ? props.showEditAmbulancePopup
            : props.showAddNewAmbulancePopup
        }
        title={
          props.showEditAmbulancePopup ? "Edit Ambulance" : "Add New Ambulance"
        }
        showCloseIcon={true}
        renderBodyContent={renderAddNewAmbulanceContents}
        confirmButtonTitle={props.showEditAmbulancePopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditAmbulancePopup
            ? props.onEditAmbulanceConfirmClick
            : props.onAddNewAmbulanceConfirmClick
        }
        onclickCancel={
          props.showEditAmbulancePopup
            ? props.onCloseEditAmbulancePopup
            : props.onCloseAddAmbulancePopup
        }
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showDeleteAmbulancePopup}
        title={`Delete Device`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteAmbulancePopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteAmbulanceConfirmClick}
        onclickCancel={props.closeAllPopups}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showBulkDeleteAmbulanceConfirmPopup}
        title={`Delete Ambulances`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteBulkAmbulancePopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkAmbulanceDeleteConfirmClick}
        onclickCancel={props.closeAllPopups}
        popupSize={"md"}
      />
      <PopupDialog
        show={props.showAmbulanceLogoPopup}
        title={"View Ambulance Image"}
        showCloseIcon={true}
        renderBodyContent={renderViewAmbulanceLogoContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewAmbulanceLogo}
        popupSize={"md"}
      />
      <PopupDialog
        show={props.showUnlockAmbulanceConfirmationPopup}
        title={"Unlock"}
        showCloseIcon={true}
        renderBodyContent={renderUnlockMessage}
        confirmButtonTitle={"Unlock"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onUnlockAmbulanceAccountConfirmClick}
        onclickCancel={props.closeUnlockAmbulancePopup}
        hideButtons={false}
        popupSize={"md"}
      />
    </div>
  );
};

export default AmbulanceView;
