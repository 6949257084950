import React from "react";
import {
  CardView,
  DateRangeSelector,
  SearchView,
  ThemedButton,
  TogglebuttonGroup,
} from "../../../controls";
import EcgReportsList from "../Components/Lists/EcgReportsList";
import { CSSTransition } from "react-transition-group";
import { AiOutlineDownload } from "react-icons/ai";
import { FaAngleLeft } from "react-icons/fa6";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import { RiFileExcel2Line } from "react-icons/ri";
import { roles } from "../../../../constants/roles";
import "./EcgReportsStyles.scss";

const EcgReportsView = (props) => {
  return (
    <div
      className={`ecg-reports-container-new ${
        props.userRole === roles.supervisor ? "remove-nav-area" : ""
      }`}
    >
      <CardView extraStyle="card-container">
        <div className="card-header-wraper">
          {props.isComingFromDoctorsList ? (
            <div className="title">
              <ThemedButton
                icon={<FaAngleLeft size={15} color="rgba(0,0,0,0.45)" />}
                onClick={() => {
                  props.onDoctorNameBackClick();
                }}
                styles="link "
              />
              <text>
                {props.doctorName} : {props.totalReports}
              </text>
            </div>
          ) : props.isComingFromDeviceList ? (
            <div className="title">
              <ThemedButton
                icon={<FaAngleLeft size={15} color="rgba(0,0,0,0.45)" />}
                onClick={() => {
                  props.onDeviceNameBackClick();
                }}
                styles="link "
              />
              <text>
                {props.deviceSerialNumber} Reports : {props.totalReports}
              </text>
            </div>
          ) : (
            <div className="title">
              <text> Total Reports : {props.totalReports}</text>
            </div>
          )}

          <div className="search-and-buttons">
            <CSSTransition
              in={props.reportIdsToDownload.length === 0}
              timeout={500}
              classNames="zoom"
            >
              <>
                {props.reportIdsToDownload.length === 0 ? (
                  <TogglebuttonGroup
                    selectedItemId={props.toggleValue}
                    onItemSelected={props.onToggleChange}
                    customToggleData={props.toggleData}
                  />
                ) : null}
              </>
            </CSSTransition>

            <CSSTransition
              in={props.reportIdsToDownload.length === 0}
              timeout={500}
              classNames="zoom"
            >
              <>
                {props.reportIdsToDownload.length === 0 ? (
                  <DateRangeSelector
                    startDate={props.dateRangeStartDate}
                    endDate={props.dateRangeEndDate}
                    onDatesChange={props.onDatesChange}
                  />
                ) : null}
              </>
            </CSSTransition>

            <CSSTransition
              in={props.reportIdsToDownload.length === 0}
              timeout={500}
              classNames="zoom"
            >
              <>
                {props.reportIdsToDownload.length === 0 ? (
                  <SearchView
                    placeholder={"Search ECG Reports"}
                    onSearchClick={props.onSearchClick}
                    onSearchTextCleared={props.onSearchTextCleared}
                    updatedCurrentValue={props.searchStringParam}
                  />
                ) : null}
              </>
            </CSSTransition>

            <CSSTransition
              in={
                props.reportIdsToDownload &&
                props.reportIdsToDownload.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <>
                {props.reportIdsToDownload &&
                props.reportIdsToDownload.length > 0 ? (
                  <text className="me-3">
                    Total {props.reportIdsToDownload.length}{" "}
                    {props.reportIdsToDownload.length === 1
                      ? "record"
                      : "records"}{" "}
                    selected
                  </text>
                ) : null}
              </>
            </CSSTransition>

            <ThemedButton
              title="Download" // TODO : No title if - Viewport is less or equal to 700 pixels wide
              size={"medium"}
              icon={<AiOutlineDownload size={15} color="#fff" />}
              onClick={props.onBulkReportsDownloadClick}
              styles="primary medium"
            />

            <ThemedButton
              title="Export" // TODO : No title if - Viewport is less or equal to 700 pixels wide
              size={"medium"}
              icon={<RiFileExcel2Line size={15} color="#fff" />}
              onClick={props.onExcelExportClick}
              styles="primary medium"
            />
          </div>
        </div>

        <EcgReportsList
          userRole={props.userRole}
          isReportListByDevice={props.isComingFromDeviceList}
          //
          page={props.page}
          limit={props.limit}
          totalReports={props.totalReports}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          reportList={props.reportList}
          onViewReportClick={props.onViewReportClick}
          onSupervisorViewReportClick={props.onSupervisorViewReportClick}
          onPrintReportClick={props.onPrintReportClick}
          onDownloadReportClick={props.onDownloadReportClick}
          onViewDoctorsReportsClick={null}
          //
          onECGReportListFilterClick={props.onECGReportListFilterClick}
          onECGReportListSortClick={props.onECGReportListSortClick}
        />
      </CardView>
    </div>
  );
};

export default EcgReportsView;
