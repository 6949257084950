import React, { useState } from "react";
import {
  HospitalCard,
  DevicesCard,
  ECGReportsCard,
  ECGReportsCardNew,
  ErrorManagementCard,
  TopPerformingHospitals,
} from "../../Components";
import "../DashboardStyles.scss";

const SuperAdminView = (props) => {
  // console.log("SUPER ADMIN VIEW : ", JSON.stringify(props));

  return (
    <div className="new-dashboard-container">
      <div className="dashboard-info-cards">
        <HospitalCard
          totalHospitals={props.totalHospitals}
          totalCenters={props.totalCenters}
          newlyAddedHospitals={props.newlyAddedHospitals}
        />

        {/* <ECGReportsCard
          totalReports={props.totalReportsThisYear}
          reportsGeneratedToday={props.reportsGeneratedToday}
          reportsData={props.reportsData}
        /> */}

        <ECGReportsCardNew
          totalReports={props.totalReportsThisYear}
          reportsGeneratedToday={props.reportsGeneratedToday}
          reportsCompletedToday={props.reportsCompletedToday}
          reportsPendingForToday={props.reportsPendingForToday}
          reportsData={props.reportsData}
        />

        <DevicesCard
          totalDevices={props.totalDevices}
          devicesData={props.devicesData}
        />

        <ErrorManagementCard
          totalErrorLogs={props.totalErrorLogs}
          errorManagementData={props.errorManagementData}
        />
      </div>

      <div className="dashboard-bar-chart">
        <TopPerformingHospitals
          topHospitalsData={props.topHospitalsData}
          //
          toggleValue={props.toggleValue}
          onToggleChange={props.onToggleChange}
          //
          dateRangeStartDate={props.dateRangeStartDate}
          dateRangeEndDate={props.dateRangeEndDate}
          onDatesChange={props.onDatesChange}
        />
      </div>
    </div>
  );
};

export default SuperAdminView;
