import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, ThemedButton } from "../../controls";
import { IoIosSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import "./SearchViewStyles.scss";

const SearchView = ({
  placeholder,
  onSearchClick,
  onSearchTextCleared,
  updatedCurrentValue = null, //Only needed when we have to force a search string in searchView from params, otherwise this string is handled internally.
}) => {
  // Search Input text - handle it in internal state
  const [currentValue, setCurrentValue] = useState("");

  // State Update function
  const onChange = ({ target }) => {
    setCurrentValue(target.value);

    if (target.value === "" || target.value === null) {
      onSearchTextCleared();
    }
  };

  const onClickEnterButton = (e) => {
    console.log("enter", e);
    if (e.keyCode === 13) {
      onSearchClick(currentValue);
    }
  };

  // If a value is sent in updatedCurrentValue from param, means we have to override it in searchview's currentValue
  useEffect(() => {
    console.log("Use effect for updatedcurrentvalue : ", updatedCurrentValue);
    // updatedCurrentValue = null means to ignore this param, else use this param for currentValue
    if (updatedCurrentValue !== null) {
      setCurrentValue(updatedCurrentValue);
    }
  }, [updatedCurrentValue]);

  console.log("currentValue", currentValue.length);

  return (
    <div className="card-search-wrapper">
      <div className="search-input">
        <Input
          placeholder={placeholder}
          extraStyle="input-style"
          value={currentValue}
          onChange={onChange}
          onKeyDown={onClickEnterButton}
        />
      </div>
      <div className="search-icon">
        {!currentValue?.length > 0 ? (
          <ThemedButton
            icon={<IoIosSearch size={20} color="rgba(0,0,0,0.45)" />}
            onClick={() => onSearchClick(currentValue)}
            styles="link small"
          />
        ) : (
          <ThemedButton
            icon={<IoClose size={20} color="rgba(0,0,0,0.45)" />}
            onClick={() => {
              onSearchTextCleared();
              setCurrentValue("");
            }}
            styles="link small"
          />
        )}
      </div>
    </div>
  );
};

SearchView.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchView;
