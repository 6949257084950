import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "./SelectOptionStyles.scss";

const SelectOptionView = ({
  onChange,
  error,
  value,
  options,
  className = "",
  placeholder,
  isSearchable = false,
}) => {
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      fontSize: "14px",
      lineHeight: "15px",
      backgroundColor: "transparent",
      height: "25px",
      padding: "0px 0px",
      minHeight: "25px",
      overflow: "none",
      textOverflow: "none",
      border: "none", // Remove default border
      boxShadow: "none", // Remove default box shadow
      "&:hover": {
        border: "none", // Remove hover border
      },
      // marginTop: 0,
    }),
    option: (base, state) => ({
      ...base,
      border: `none`,
      fontSize: "14px",
      fontWeight: "400",
      background: state.isSelected ? "#c0e5dc" : "white", // add active background color
      color: "rgba(0,0,0,0.60)",

      "&:hover": {
        backgroundColor: state.isSelected ? "#c0e5dc" : "rgb(242, 242, 242)",
        color: "rgba(0,0,0)",
      },
    }),

    singleValue: (styles, state) => ({
      ...styles,
      color: "#00B48A",
      fontWeight: "400",
      marginTop: "-5px", // Reduce top margin from label
    }),

    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: "#00B48A",
      "&:hover": {
        color: "#00B48A",
      },
      marginTop: "-5px", // Reduce top margin from dropdown arrow
    }),

    indicatorSeparator: (styles, state) => ({
      ...styles,
      display: "none",
    }),

    placeholder: (styles, state) => ({
      ...styles,
      color: "#00B48A",
      marginTop: 0,
    }),
  };

  return (
    <Select
      options={options}
      value={value}
      isSearchable={isSearchable}
      styles={customStyles}
      onChange={onChange}
      className={`select_option_wrapper ${className}`}
      placeholder={placeholder}
    />
  );
};
SelectOptionView.prototype = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
};
export default SelectOptionView;
