import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import auth from "../../restricted/auth";
import {
  verifyEmailForForgotPassword,
  resendOtp,
  resetUserPasswordUsingOTP,
} from "../../../store/auth/authActions";
import ForgotPasswordView from "./ForgotPasswordView";
import { HOME, LOGIN } from "../../../navigation/routes";
import { history } from "../../../store/configure/configureStore";
import { trimFormData } from "../../../utils/common";
import * as validator from "../../../utils/validator";
import { strings } from "../../../constants/stringConstants";

export class ForgotPasswordContainer extends Component {
  validatorFields = {};
  state = {
    fields: {
      email: "",
      password: "",
      confirmPassword: "",
      emailOtp: "",
    },

    setPasswordFlag: false,
    isValidationsOn: false,
    errors: {},
  };
  constructor(props) {
    super(props);

    this.validatorFields["required"] = [
      "password",
      "confirmPassword",
      "email",
      "emailOtp",
    ];

    this.onLogoClick = this.onLogoClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onGetOtpClick = this.onGetOtpClick.bind(this);
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
    this.onResendOtpClick = this.onResendOtpClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillUnmount() {}

  onLogoClick = () => {
    history.push(LOGIN);
  };

  handleChange = (field) => (e) => {
    let value;
    const { fields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired = this.validatorFields.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  onGetOtpClick = () => {
    const { fields } = this.state;

    var custom = [];
    var required = ["email"];

    if (fields.email) custom.push(validator.email(["email"]));

    const validatorFields = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      this.props.verifyEmailForForgotPassword(fields, (response) => {
        if (response?.data?.userId) {
          this.setState({
            ...this.state,
            setPasswordFlag: true,
            // fields: {
            //   ...fields,
            //   emailOtp: response?.data?.otp,
            // },
          });
        }
      });
      // }
    }
  };

  onResetPasswordClick = () => {
    const { fields } = this.state;

    var custom = [];
    var required = ["password", "confirmPassword", "emailOtp"];

    if (fields.email) custom.push(validator.email(["email"]));

    if (fields.password) {
      custom.push(validator.StrongPassword(["password"]));
      custom.push(validator.PasswordWithoutSpaces(["password"]));
      custom.push(validator.password(["password"]));
    }
    // if (fields.confirmPassword) {
    //   custom.push(validator.password(["confirmPassword"]));
    // custom.push(validator.PasswordWithoutSpaces(["confirmPassword"]));
    // }
    const validatorFields = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );
    if (
      fields.confirmPassword.length >= 0 &&
      fields.password !== fields.confirmPassword
    ) {
      errors["confirmPassword"] = strings.PASSWORD_MATCH_ERROR;
    }

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      if (this.state.setPasswordFlag) {
        this.props.resetUserPasswordUsingOTP(fields, (response) => {
          // if (response?.data?.userId) {
          //   this.setState({
          //     ...this.state,
          //   });
          // }
        });
      }
    }
  };

  handleKeyDown = (e) => {
    // Prevent the default action if the space key is pressed
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  onResendOtpClick = () => {
    const { fields } = this.state;
    this.props.resendOtp(fields, (response) => {
      this.setState({
        ...this.state,
        fields: {
          ...fields,

          emailOtp: "",
        },
      });
      // if (response?.data?.userId) {
      //   this.setState({
      //     ...this.state,
      //     setPasswordFlag: true,
      //   });
      // }
    });
  };

  render() {
    if (auth.isAuthenticated()) {
      // if (this.props.authSuccess == true) {
      history.push(HOME);
    }

    return (
      <>
        <ForgotPasswordView
          onLogoClick={this.onLogoClick}
          fields={this.state.fields}
          errors={this.state.errors}
          handleChange={this.handleChange}
          onGetOtpClick={this.onGetOtpClick}
          setPasswordFlag={this.state.setPasswordFlag}
          onResendOtpClick={this.onResendOtpClick}
          onResetPasswordClick={this.onResetPasswordClick}
        />
      </>
    );
  }
}

ForgotPasswordContainer.propTypes = {
  ForgotPwd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authSuccess: state.auth.authSuccess,
  authData: state.auth,
});

export default connect(mapStateToProps, {
  verifyEmailForForgotPassword,
  resendOtp,
  resetUserPasswordUsingOTP,
})(ForgotPasswordContainer);
