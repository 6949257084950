import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "../CaseDetails/CaseDetailsStyles.scss";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#efefef",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#efefef",
    color: "rgba(0, 0, 0, 0.85)",
    boxShadow: "red",
    fontSize: 11,
  },
}));

export default function InfoTextInline({
  label = "",
  value = "-",
  style,
  labelTextSize = null,
  valueTextSize = null,
}) {
  const data = value !== null && value !== undefined ? value : "-";
  return (
    <div style={style} className="info-text-inline-row-container">
      <text
        style={labelTextSize ? { textSize: labelTextSize } : null}
        className="r-label"
      >
        {label + ": "}
      </text>
      <LightTooltip title={value && value != "-" ? data : null}>
        <text style={{ textSize: valueTextSize }} className="r-value">
          {data}
        </text>
      </LightTooltip>
    </div>
  );
}
