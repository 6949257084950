import moment from "moment";

export const actionTypes = {
  GET_DASHBOARD_DATA_PENDING: "GET_DASHBOARD_DATA_PENDING",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR",

  GET_TOP_PERFORMERS_PENDING: "GET_TOP_PERFORMERS_PENDING",
  GET_TOP_PERFORMERS_SUCCESS: "GET_TOP_PERFORMERS_SUCCESS",
  GET_TOP_PERFORMERS_ERROR: "GET_TOP_PERFORMERS_ERROR",

  RESET_DASHBOARD_DATA: "RESET_DASHBOARD_DATA",
};

const initialState = {
  getDashboardDataPending: false,
  getDashboardDataSuccess: false,
  getDashboardDataError: null,

  getTopPerformersPending: false,
  getTopPerformersSuccess: false,
  getTopPerformersError: null,

  dashboardData: {},
  topPerformersData: {},
  lastUpdated: moment().calendar(), // Text to be shown in TopNavBar, contains only HH:MM string
  lastUpdatedTimestamp: moment().toString(), // Timestamp with seconds info too, so as to refresh dashboard UI, even if data fetched is in same minute.
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_DASHBOARD_DATA_PENDING:
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
    case actionTypes.GET_DASHBOARD_DATA_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_TOP_PERFORMERS_PENDING:
    case actionTypes.GET_TOP_PERFORMERS_SUCCESS:
    case actionTypes.GET_TOP_PERFORMERS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_DASHBOARD_DATA:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
