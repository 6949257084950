import React, { useState } from "react";
import { CardView } from "../../../../controls";
import {
  StatisticsCardHospitalAdmin,
  TopPerformingDoctorsCard,
  EcgReportsCardHospitalAdmin,
  DevicesCardHospitalAdmin,
  ErrorLogsCardHospitalAdmin,
} from "../../Components";
import "../DashboardStyles.scss";

const HospitalAdminView = (props) => {
  // console.log("HOSPITAL ADMIN VIEW : ", JSON.stringify(props));

  return (
    <div className="new-dashboard-container">
      <div className="dashboard-info-cards">
        <StatisticsCardHospitalAdmin
          loginUser={props.loginUser}
          userCreatedAt={props.userCreatedAt}
          totalHospitals={props.totalHospitals}
          totalCenters={props.totalCenters}
          totalDevices={props.totalDevices}
          totalDoctors={props.totalDoctors}
        />
      </div>

      <div className="dashboard-info-cards">
        <TopPerformingDoctorsCard
          data={props.topDoctors}
          hospitalAdminDropdownOptions={props.hospitalAdminDropdownOptions}
          hospitalAdminTopDoctorsFilterSelected={
            props.hospitalAdminTopDoctorsFilterSelected
          }
          onHospitalAdminTopDoctorsFilterChange={
            props.onHospitalAdminTopDoctorsFilterChange
          }
        />
        <EcgReportsCardHospitalAdmin
          data={props.topCenters}
          hospitalAdminDropdownOptions={props.hospitalAdminDropdownOptions}
          hospitalAdminEcgReportsFilterSelected={
            props.hospitalAdminEcgReportsFilterSelected
          }
          onHospitalAdminEcgReportsFilterChange={
            props.onHospitalAdminEcgReportsFilterChange
          }
        />
      </div>

      {/* <div className="dashboard-info-cards">
        <DevicesCardHospitalAdmin devicesData={props.devicesData} />
        <ErrorLogsCardHospitalAdmin errorLogsData={props.errorLogsData} />
      </div> */}
    </div>
  );
};

export default HospitalAdminView;
