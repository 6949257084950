import React from "react";
import BarChartView from "../Other/BarChartView";
import {
  CardView,
  DateRangeSelector,
  TogglebuttonGroup,
} from "../../../../controls";
import "./CardStyles.scss";

const TopPerformingHospitals = (props) => {
  //-----------------------Bar-chart-Data-----------------

  const label_yaxis = "No. of ECG Reports";
  const label_xaxis = "Name of Hospitals";

  //---------------------Top Hospital List-----------------
  const renderTopHospitalList = () => {
    const children = [];
    props.topHospitalsData.map((item, index) => {
      children.push(
        <div className="hospital-list-wrapper">
          <div className="hospital-list-index">
            <span className="list-index">{index + 1}</span>
          </div>
          <div className="hospital-list-name">{item.label}</div>
          <div className="hospital-list-count-numbers">{item.count}</div>
        </div>
      );
    });
    return children;
  };

  //---------------------Bar Chart List-----------------
  const getHospitalListWithTrimmedLabel = () => {
    var newList = [];

    props.topHospitalsData.map((item, index) => {
      var newItem = { ...item };

      var label = newItem?.label?.trim() ?? "";
      var words = label.length > 0 ? label.split(" ") : [];

      var newLabel =
        words.length == 0
          ? ""
          : words.length == 1
          ? words[0]
          : words.length == 2
          ? words[0] + " " + words[1]
          : // Else if words length is more than 2
            words[0] + " " + words[1] + "...";

      newItem.label = newLabel;
      newList.push(newItem);
    });

    // console.log("topHospitalsData : ", JSON.stringify(props.topHospitalsData));
    // console.log("topHospitalsData : NEW", JSON.stringify(newList));

    if (newList.length === 0) {
      newList.push({ label: "", count: 0 });
    }
    return newList;
  };

  return (
    <div className="super-admin-top-hospitals-card">
      <CardView
        extraStyle="top-hospital-card-control"
        // bodyStyle={{ height: "350px" }}
      >
        <div className="card-header">
          <div className="title">
            <text>Top Performing Hospitals</text>
          </div>
          <div className="filter-and-buttons">
            <TogglebuttonGroup
              noOutline
              selectedItemId={props.toggleValue}
              onItemSelected={props.onToggleChange}
            />
            <DateRangeSelector
              startDate={props.dateRangeStartDate}
              endDate={props.dateRangeEndDate}
              onDatesChange={props.onDatesChange}
            />
          </div>
        </div>

        <div className="info-wrapper">
          <div className="bar-chart-wrapper">
            <BarChartView
              dataSet={getHospitalListWithTrimmedLabel()} //props.topHospitalsData
              yaxisLabel={label_yaxis}
              xaxisLabel={label_xaxis}
            />
          </div>

          <div className="top-hospital-list-section">
            {renderTopHospitalList()}
          </div>
        </div>
      </CardView>
    </div>
  );
};

export default TopPerformingHospitals;
