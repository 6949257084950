import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import "./InputStyles.scss";

const inputRef = React.createRef();

const Input = ({
  error,
  tabIndex = 0,
  type,
  className = "",
  label,
  disabled,
  value,
  size = "",
  setFocus = 0,
  isrequired = false,
  isvalnum = "",
  min = "",
  extraStyle = "",
  halfsize = false,
  charLimit,
  showCharLimit = false,
  showInputUnitText = null,
  showMobilePrefix = false,
  mobilePrefix = "+91",
  ...props
}) => {
  useEffect(() => {
    if (setFocus) {
      inputRef.current.focus();
    }
    if (value) {
      setCharCount(value.length);
    }
  }, [setFocus, value]);

  let [newType, setNewType] = useState(type === "password" ? "password" : type);
  let [hideViewIcon, setHideViewIcon] = useState(false);
  let [charCount, setCharCount] = useState(0);

  const changeType = () => {
    if (newType === "password") {
      setNewType("text");
    } else {
      setNewType("password");
    }
  };

  const handleKeyPress = (e) => {
    if (isvalnum === "isNum") {
      let charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
    if (isvalnum === "alphanumeric") {
      // var , i, len;
      let code = e.which ? e.which : e.keyCode;
      // for (i = 0, len = str.length; i < len; i++) {
      //   code = str.charCodeAt(i);
      // }
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        e.preventDefault();
      }
    }
  };

  // handle view icons
  const handleKeyUp = (e) => {
    setCharCount(e.target.value.length > 0 ? e.target.value.length : 0);
    // console.log("BROWSER : ", navigator.userAgent);
    if (
      navigator.userAgent.indexOf("Edg") !== -1 ||
      navigator.userAgent.indexOf("Edge") !== -1
    ) {
      if (e.target.value.length > 0) {
        setHideViewIcon(true);
      } else {
        setHideViewIcon(false);
      }
    }
  };

  return (
    <div className={`wrapper ${halfsize ? "half" : ""}`}>
      <span className={`label`}>
        {label} {isrequired && <span className="text-danger">*</span>}
      </span>

      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        {showMobilePrefix && <div className="showMobilePrefix">+91</div>}

        <input
          ref={inputRef}
          type={newType}
          tabIndex={tabIndex}
          disabled={disabled}
          value={value}
          onKeyPress={handleKeyPress}
          onKeyUp={handleKeyUp}
          min={min}
          maxLength={charLimit}
          className={`
          input ${className}
          ${error && !disabled ? "input_error" : ""}
          ${disabled ? "disabled" : ""}
          ${size}
          ${extraStyle}
          ${showCharLimit ? "extraPadding" : ""}
          ${showInputUnitText ? "extraPadding" : ""}
          ${type === "password" && !hideViewIcon ? "extraPadding" : ""}
          ${showMobilePrefix ? "withMobilePrefix" : ""}
          `}
          {...props}
        />
      </div>

      {type === "password" && !hideViewIcon && (
        <div
          onMouseDown={(e) => e.preventDefault()}
          className={`isShowWrapper ${
            size === "sm" ? "changeTopPosition" : ""
          }`}
          onClick={changeType}
        >
          {newType === "password" ? (
            <PiEyeSlash className="isShowIcon" />
          ) : (
            <PiEyeLight className="isShowIcon" />
          )}
        </div>
      )}

      {showCharLimit && (
        <div className={`showCharLimit ${label ? "withLabel" : ""}`}>
          <div />
          {charCount}/{charLimit}
        </div>
      )}

      {/* To identify user which type of data need to enter */}
      {showInputUnitText && (
        <div className="showUnitText">{showInputUnitText}</div>
      )}

      <div className={`input-field-error`}>
        {error && !disabled ? error : ""}
      </div>
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
};

export default Input;
