import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./centerReducer";

export function getCenterListWithFilter(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CENTER_LIST_WITH_FILTER_PENDING,
      payload: {
        getCenterListWithFilterPending: false,
        getCenterListWithFilterSuccess: false,
        getCenterListWithFilerError: null,
      },
    });
    dispatch(showLoader());

    Api.post("center/list", data)
      .then((response) => {
        // console.log(
        //   "getCenterListWithFilter : ",
        //   JSON.stringify(response.data.rows)
        // );

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? []);
        }

        dispatch(hideLoader());
        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_CENTER_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getCenterListWithFilterPending: false,
              getCenterListWithFilterSuccess: true,
              getCenterListWithFilerError: null,
              centerList: response?.data?.rows ?? [],
              filteredCenterList: response?.data?.rows ?? [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_CENTER_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getCenterListWithFilterPending: false,
              getCenterListWithFilterSuccess: true,
              getCenterListWithFilerError: null,
              centerList: response?.data?.rows ?? [],
              filteredCenterList: response?.data?.rows ?? [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_CENTER_LIST_WITH_FILTER_ERROR,
          payload: {
            getCenterListWithFilterPending: false,
            getCenterListWithFilterSuccess: true,
            getCenterListWithFilerError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
