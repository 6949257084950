import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./technicianReducer";

export function getTechnicianListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = "",
  order = ""
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_PENDING,
      payload: {
        getTechnicianListWithFilterPending: true,
        getTechnicianListWithFilterSuccess: false,
        getTechnicianListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `technician/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);

        if (typeof callback === "function") {
          callback(
            response?.data?.rows ?? [],
            response?.data?.count ?? 0,
            response?.data?.totalPages ?? 0
          );
        }

        dispatch(hideLoader());
        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getTechnicianListWithFilterPending: false,
              getTechnicianListWithFilterSuccess: true,
              getTechnicianListWithFilterError: null,
              technicianList: response?.data?.rows ?? [],
              filteredTechnicianList: response?.data?.rows ?? [],
              filteredTechnicianCount: response?.data?.count ?? 0,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getTechnicianListWithFilterPending: false,
              getTechnicianListWithFilterSuccess: true,
              getTechnicianListWithFilterError: null,
              filteredTechnicianList: response?.data?.rows ?? [],
              filteredTechnicianCount: response?.data?.count ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0);
        }

        dispatch({
          type: actionTypes.GET_TECHNICIAN_LIST_WITH_FILTER_ERROR,
          payload: {
            getTechnicianListWithFilterPending: false,
            getTechnicianListWithFilterSuccess: false,
            getTechnicianListWithFilterError: err,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getAssignedDeviceOfTechnician(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_PENDING,
      payload: {
        getAssignedDeviceOfTechnicianPending: true,
        getAssignedDeviceOfTechnicianSuccess: false,
        getAssignedDeviceOfTechnicianError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`technician/assigned/device`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.serialNo ?? null);
        }

        dispatch({
          type: actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_SUCCESS,
          payload: {
            getAssignedDeviceOfTechnicianPending: false,
            getAssignedDeviceOfTechnicianSuccess: true,
            getAssignedDeviceOfTechnicianError: null,
            assignedDevice: response?.data?.serialNo ?? null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.GET_ASSIGNED_DEVICE_OF_TECHNICIAN_ERROR,
          payload: {
            getAssignedDeviceOfTechnicianPending: false,
            getAssignedDeviceOfTechnicianSuccess: false,
            getAssignedDeviceOfTechnicianError: err,
            assignedDevice: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------- ge Technician Details By Id --------------------

export function getTechnicianDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_PENDING,
      payload: {
        getTechnicianDetailsByIdPending: false,
        getTechnicianDetailsByIdSuccess: false,
        getTechnicianDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`technician/${id}`)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_SUCCESS,
          payload: {
            getTechnicianDetailsByIdPending: false,
            getTechnicianDetailsByIdSuccess: true,
            getTechnicianDetailsByIdError: null,
            technicianDetails: response?.data ?? {},
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }
        dispatch({
          type: actionTypes.GET_TECHNICIAN_DETAILS_BY_ID_ERROR,
          payload: {
            getTechnicianDetailsByIdPending: false,
            getTechnicianDetailsByIdSuccess: true,
            getTechnicianDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- add Technician

export function addNewTechnician(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_NEW_TECHNICIAN_PENDING,
      payload: {
        addNewTechnicianPending: false,
        addNewTechnicianSuccess: false,
        addNewTechnicianError: null,
      },
    });
    dispatch(showLoader());

    Api.post("technician/add", data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_NEW_TECHNICIAN_SUCCESS,
          payload: {
            addNewTechnicianPending: false,
            addNewTechnicianSuccess: true,
            addNewTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_NEW_TECHNICIAN_ERROR,
          payload: {
            addNewTechnicianPending: false,
            addNewTechnicianSuccess: true,
            addNewTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- Edit Technician

export function editTechnician(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_TECHNICIAN_PENDING,
      payload: {
        editTechnicianPending: false,
        editTechnicianSuccess: false,
        editTechnicianError: null,
      },
    });
    dispatch(showLoader());

    Api.put(`technician/update/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_TECHNICIAN_SUCCESS,
          payload: {
            editTechnicianPending: false,
            editTechnicianSuccess: true,
            editTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.EDIT_TECHNICIAN_ERROR,
          payload: {
            editTechnicianPending: false,
            editTechnicianSuccess: true,
            editTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//---------------------------------- delete Technician

export function deleteTechnician(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TECHNICIAN_PENDING,
      payload: {
        deleteTechnicianPending: false,
        deleteTechnicianSuccess: false,
        deleteTechnicianError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`technician/delete/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.DELETE_TECHNICIAN_SUCCESS,
          payload: {
            deleteTechnicianPending: false,
            deleteTechnicianSuccess: true,
            deleteTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.DELETE_TECHNICIAN_ERROR,
          payload: {
            deleteTechnicianPending: false,
            deleteTechnicianSuccess: true,
            deleteTechnicianError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------------------------- delete device In Bilk ------------------------------

export function deleteTechnicianInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TECHNICIAN_IN_BULK_PENDING,
      payload: {
        deleteTechnicianInBulkPending: false,
        deleteTechnicianInBulkSuccess: false,
        deleteTechnicianInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`technician/delete/multiple/technicians`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_TECHNICIAN_IN_BULK_SUCCESS,
          payload: {
            deleteTechnicianInBulkPending: false,
            deleteTechnicianInBulkSuccess: true,
            deleteTechnicianInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_TECHNICIAN_IN_BULK_ERROR,
          payload: {
            deleteTechnicianInBulkPending: false,
            deleteTechnicianInBulkSuccess: true,
            deleteTechnicianInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
