import React from "react";
import { DataTable } from "../../../../common";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import { roles } from "../../../../../constants/roles";
import { FaSort } from "react-icons/fa6";
import moment from "moment/moment";
import "./ListStyles.scss";
import { AiFillFilter } from "react-icons/ai";

const AmbulanceList = (props) => {
  // passing props to data variable
  const data = props.ambulanceList;
  const userRole = props.userRole;

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onAmbulanceListSortClick !== null
                  ? () => props.onAmbulanceListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const Active = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const Inactive = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const Options = [
      { label: "Active", icon: <div style={Active} /> },
      { label: "Inactive", icon: <div style={Inactive} /> },
    ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              columnName={label}
              showSelectedItem={true}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onAmbulanceListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {/*  No sort icon  */}
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderEditDeleteAction = (row) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="Edit"
          styles="link"
          onClick={
            props.onEditAmbulanceClick !== null
              ? () => props.onEditAmbulanceClick(row)
              : () => {}
          }
        />
        <div className="partition-block" />
        <ThemedButton
          title="Delete"
          styles="link"
          disabled={props.disableSingleDeleteButton}
          onClick={
            props.onDeleteAmbulanceClick !== null
              ? () => props.onDeleteAmbulanceClick(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const renderUnlockButton = (row) => {
    return (
      <div>
        <ThemedButton
          title="Unlock"
          styles="link "
          onClick={
            props.onUnlockAmbulanceAccountClick !== null
              ? () => props.onUnlockAmbulanceAccountClick(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const renderStatus = (status) => {
    const onlineColor = { backgroundColor: "#52C41A" };
    const offlineColor = { backgroundColor: "#D9D9D9" };

    let statusStyle;
    if (status === "Active") {
      statusStyle = onlineColor;
    }
    if (status === "Inactive") {
      statusStyle = offlineColor;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text>{status}</text>
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // ---------------------  All Column Objects ------------------------

  const ambulanceName = {
    name: renderHeader("Ambulance Name", false, true),
    id: "ambulance_name",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.ambulanceName !== undefined && row?.ambulanceName !== null
        ? `${row.ambulanceName
            .charAt(0)
            .toUpperCase()}${row.ambulanceName.slice(1)}`
        : "-",
  };

  const ambulanceNo = {
    name: renderHeader("Ambulance Number", false, true),
    id: "ambulance_no",
    center: false,
    sortable: false,
    selector: (row) => row?.ambulanceRegistrationNumber ?? "-",
  };

  const ambulanceType = {
    name: renderHeader("Ambulance Type", false, true),
    id: "ambulance_type",
    center: false,
    sortable: false,
    selector: (row) => row?.ambulanceTypeInfo?.type ?? "-",
  };

  const status = {
    name: renderStatusHeader("Status"),
    id: "status",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.status == 1
        ? renderStatus("Active")
        : row?.status == 2
        ? renderStatus("Inactive")
        : "-",
  };

  const editDeleteAction = {
    name: renderActionHeader("Action"),
    id: "action",
    center: false,
    selector: (row) =>
      row?.user?.isLocked
        ? renderUnlockButton(row)
        : renderEditDeleteAction(row),
  };

  // ------------------  Add Columns According to Role -------------------

  // We will add needed columns to this array according to role.
  var columns = [];

  //
  if (userRole === roles.hospitalAdmin) {
    columns.push(ambulanceName);
    columns.push(ambulanceNo);
    columns.push(ambulanceType);
    columns.push(status);
    columns.push(editDeleteAction);
  }

  return (
    <div className="ambulance-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          className="data-table"
          columns={columns}
          data={data}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalAmbulances}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default AmbulanceList;
