import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getSecurityQuestionsList,
  uploadImage,
  deleteImage,
} from "../../../../store/master/masterActions";
import {
  getMyProfile,
  updateMyProfile,
  changePassword,
} from "../../../../store/auth/authActions";
import { roles } from "../../../../constants/roles";
import { history } from "../../../../store/configure/configureStore";
import {
  validateMobile,
  validatePassword,
  validatePasswordWithoutSpaces,
} from "../../../../utils/validator";
import MyProfileView from "./MyProfileView";
import { globalConstants } from "../../../../constants/globalConstants";
import { trimFormData } from "../../../../utils/common";
import * as validator from "../../../../utils/validator";
import { strings } from "../../../../constants/stringConstants";
class MyProfileContainer extends Component {
  validatorFieldsForPassword = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForPassword["required"] = [
      "oldPassword",
      "newPassword",
      "confirmNewPassword",
    ];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,

      firstName: "",
      lastName: "",
      email: "",
      securityQuestionsList: [],
      selectedSecurityQuestion: null,
      selectedSecurityQuestionId: null,
      answer: "",
      mobile: "",
      msciNumber: "",
      qualification: "",
      //
      firstNameError: "",
      lastNameError: "",
      mobileError: "",
      answerError: "",
      msciError: "",
      isValidationsOn: false,

      // Change Password
      showChangePasswordPopup: false,
      fields: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: {},

      // Image Uploader
      profilePicture: "",
      signPicture: "",
      hospitalFranchiseLogo: "",
      viewProfilePicFlag: false,
      viewSignIatureFlag: false,
      viewHospitalFranchiseImageFlag: false,
    };

    this.getSecurityQuestionsList = this.getSecurityQuestionsList.bind(this);
    this.getMyProfileDetails = this.getMyProfileDetails.bind(this);
    this.updateSecurityQuestionDropdown =
      this.updateSecurityQuestionDropdown.bind(this);

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onSecurityQuestionSelected =
      this.onSecurityQuestionSelected.bind(this);
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onMobileChange = this.onMobileChange.bind(this);
    this.onMsciChange = this.onMsciChange.bind(this);
    this.onQualificationChange = this.onQualificationChange.bind(this);

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.validateData = this.validateData.bind(this);

    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onPasswordhandleChange = this.onPasswordhandleChange.bind(this);
    this.onChangePasswodCancel = this.onChangePasswodCancel.bind(this);
    this.onChangePasswodConfirm = this.onChangePasswodConfirm.bind(this);

    this.viewHospitalFranchiseImageClicked =
      this.viewHospitalFranchiseImageClicked.bind(this);
    this.deleteHospitalFranchiseImageClicked =
      this.deleteHospitalFranchiseImageClicked.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    // this.getSecurityQuestionsList(); // Not Needed Anymore!

    this.getMyProfileDetails();
  }

  componentWillUnmount() {}

  //------------------------------------------------------------

  getSecurityQuestionsList = () => {
    this.props.getSecurityQuestionsList((list) => {
      var questionList = Array.isArray(list)
        ? list.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [];

      this.setState({ securityQuestionsList: questionList });
    });
  };

  getMyProfileDetails = () => {
    this.props.getMyProfile((data) => {
      // General Data independant of user role
      this.setState({
        email: data?.userName ?? "",
        selectedSecurityQuestionId: data?.securityQuestionId ?? null,
        answer: data?.answer ?? "",
        // Errors reset
        firstNameError: "",
        lastNameError: "",
        mobileError: "",
        answerError: "",
        msciError: "",
        profilePicture: data?.profilePicture ?? "",
        signPicture: data?.doctor_profile?.signature ?? "",
        hospitalFranchiseLogo:
          data?.hospital_admin_profile?.franchiseLogo ?? "",
      });

      // Role specific data
      if (this.state.userRole == roles.superAdmin) {
        this.setState({
          firstName: data?.super_admin_profile?.firstName ?? "",
          lastName: data?.super_admin_profile?.lastName ?? "",
          mobile: data?.super_admin_profile?.contactNumber ?? "",
        });
      } else if (this.state.userRole == roles.hospitalAdmin) {
        this.setState({
          firstName: data?.hospital_admin_profile?.firstName ?? "",
          lastName: data?.hospital_admin_profile?.lastName ?? "",
          mobile: data?.hospital_admin_profile?.contactNumber ?? "",
        });
      } else if (this.state.userRole == roles.reportingHospitalAdmin) {
        this.setState({
          firstName: data?.hospital_admin_profile?.firstName ?? "",
          lastName: data?.hospital_admin_profile?.lastName ?? "",
          mobile: data?.hospital_admin_profile?.contactNumber ?? "",
        });
      } else if (this.state.userRole == roles.centerAdmin) {
        this.setState({
          firstName: data?.center_admin_profile?.firstName ?? "",
          lastName: data?.center_admin_profile?.lastName ?? "",
          mobile: data?.center_admin_profile?.contactNumber ?? "",
        });
      } else if (this.state.userRole == roles.technician) {
        this.setState({
          firstName: data?.technician_profile?.firstName ?? "",
          lastName: data?.technician_profile?.lastName ?? "",
          mobile: data?.technician_profile?.contactNumber ?? "",
        });
      } else if (this.state.userRole == roles.doctor) {
        this.setState({
          firstName: data?.doctor_profile?.firstName ?? "",
          lastName: data?.doctor_profile?.lastName ?? "",
          mobile: data?.doctor_profile?.contactNumber ?? "",
          msciNumber: data?.doctor_profile?.registrationId ?? "",
          qualification: data?.doctor_profile?.qualification ?? "",
        });
      } else if (this.state.userRole == roles.supervisor) {
        this.setState({
          firstName: data?.supervisor_profile?.firstName ?? "",
          lastName: data?.supervisor_profile?.lastName ?? "",
          mobile: data?.supervisor_profile?.contactNumber ?? "",
          msciNumber: data?.supervisor_profile?.registrationId ?? "",
          qualification: data?.supervisor_profile?.qualification ?? "",
        });
      }

      setTimeout(() => {
        this.updateSecurityQuestionDropdown(data?.securityQuestionId ?? null);
      }, 1000);
    });
  };

  updateSecurityQuestionDropdown = (selectedId) => {
    // Selected Question (dropdown) related data

    if (selectedId !== null) {
      var securityQuestions = this.props.securityQuestions;

      securityQuestions.map((item) => {
        if (item.id == selectedId) {
          this.setState({
            selectedSecurityQuestion: { value: item.id, label: item.name },
          });
        }
      });
    } else {
      this.setState({ selectedSecurityQuestion: null });
    }
  };

  //------------------------------------------------------------

  onFirstNameChange = (value) => {
    this.setState({ firstName: value, firstNameError: "" });
  };

  onLastNameChange = (value) => {
    this.setState({ lastName: value, lastNameError: "" });
  };

  onSecurityQuestionSelected = (selectedItem) => {
    this.setState({
      selectedSecurityQuestion: selectedItem,
      selectedSecurityQuestionId: selectedItem.value,
      answer: "",
      answerError: "",
    });
  };

  onAnswerChange = (value) => {
    if (this.state.selectedSecurityQuestion === null) {
      this.setState({
        answer: "",
        answerError: "Please select a security question first",
      });
    } else {
      if (value.length > 120) {
        this.setState({
          answer: value.substr(0, 120),
          answerError: "Maximum 120 characters allowed",
        });
      } else {
        this.setState({ answer: value, answerError: "" });
      }
    }
  };

  onMobileChange = (value) => {
    this.setState({ mobile: value, mobileError: "" });
  };

  onMsciChange = (value) => {
    if (value.length > 120) {
      this.setState({
        msciNumber: value.substr(0, 120),
        msciError: "Maximum 120 characters allowed",
      });
    } else {
      this.setState({ msciNumber: value, msciError: "" });
    }
  };

  onQualificationChange = (value) => {
    this.setState({ qualification: value });
  };

  //------------------------------------------------------------

  onCancelClick = () => {
    history.push("/dashboard_new");
  };

  onResetClick = () => {
    this.getMyProfileDetails();
  };

  onSaveClick = () => {
    if (this.validateData()) {
      var data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        contactNumber: this.state.mobile,
        securityQuestionId: this.state.selectedSecurityQuestionId,
        answer: this.state.answer,
      };

      if (
        this.state.userRole === roles.doctor ||
        this.state.userRole === roles.supervisor
      ) {
        data.registrationId = this.state.msciNumber;
        data.qualification = this.state.qualification;
      }

      this.props.updateMyProfile(data);
    }
  };

  validateData = () => {
    var isValidData = true;

    if (this.state.firstName.trim() === "") {
      this.setState({ firstNameError: "This field is required!" });
      isValidData = false;
    }

    if (this.state.lastName.trim() === "") {
      this.setState({ lastNameError: "This field is required!" });
      isValidData = false;
    }

    if (this.state.mobile.trim() === "") {
      this.setState({ mobileError: "This field is required!" });
      isValidData = false;
    } else {
      if (!validateMobile(this.state.mobile)) {
        this.setState({ mobileError: "Please enter a valid mobile number" });
        isValidData = false;
      }
    }

    if (
      this.state.selectedSecurityQuestion !== null &&
      this.state.answer.trim() === ""
    ) {
      this.setState({ answerError: "Please enter an answer" });
      isValidData = false;
    }

    if (
      (this.state.userRole === roles.doctor ||
        this.state.userRole === roles.supervisor) &&
      String(this.state.msciNumber).trim() == ""
    ) {
      this.setState({ msciError: "This field is required!" });
      isValidData = false;
    }

    return isValidData;
  };

  //------------------------------------------------------------

  onChangePasswordClick = () => {
    this.setState({ showChangePasswordPopup: true });
  };

  onPasswordhandleChange = (field) => (e) => {
    let value;
    const { fields } = this.state;

    const isDate = e instanceof Date;
    const isNull = e === null;
    const isTextField = e?.target;

    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e.value;
    }

    var isRequired = this.validatorFieldsForPassword.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }

    if (e) {
      this.setState({
        ...this.state,
        fields: { ...fields, [field]: value },
      });
    }
  };

  // onOldPasswordChange = (value) => {
  //   this.setState({
  //     oldPassword: value,
  //     // oldPasswordError: ""
  //   });
  // };

  // onNewPasswordChange = (value) => {
  //   this.setState({
  //     newPassword: value,
  //     //  newPasswordError: ""
  //   });
  // };

  // onConfirmNewPasswordChange = (value) => {
  //   this.setState({
  //     confirmNewPassword: value,
  //     // confirmNewPasswordError: ""
  //   });
  // };

  onChangePasswodCancel = () => {
    const { fields } = this.state;
    this.setState({
      ...fields,
      fields: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: {},
      showChangePasswordPopup: false,
    });
  };

  onChangePasswodConfirm = () => {
    const { fields } = this.state;

    var custom = [];
    var required = ["oldPassword", "newPassword", "confirmNewPassword"];

    if (fields.newPassword) {
      custom.push(validator.StrongPassword(["newPassword"]));
      custom.push(validator.PasswordWithoutSpaces(["newPassword"]));
      custom.push(validator.password(["newPassword"]));
    }
    // if (fields.confirmNewPassword)
    //   custom.push(validator.password(["confirmNewPassword"]));

    const validatorFieldsForPassword = {
      required: required,
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFieldsForPassword,
      trimFormData(fields)
    );
    if (
      fields?.confirmNewPassword?.length >= 0 &&
      fields.newPassword !== fields.confirmNewPassword
    ) {
      errors["confirmNewPassword"] = strings.PASSWORD_MATCH_ERROR;
    }

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      this.props.changePassword(
        this.state.fields?.oldPassword,
        this.state.fields?.newPassword,
        (isError, error) => {
          if (isError) {
            this.setState({ showChangePasswordPopup: true }); //keep showing the popup
          } else {
            this.setState({
              ...fields,
              fields: {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
              },
              isValidationsOn: false,
              showChangePasswordPopup: false,
            });
          }
        }
      );
    }
    // }
  };

  /*
  validateChangePasswordData = () => {
    var isValidData = true;

    if (String(this.state.oldPassword) === "") {
      this.setState({ oldPasswordError: "This field is required" });
      isValidData = false;
    }

    if (String(this.state.newPassword) === "") {
      this.setState({ newPasswordError: "This field is required" });
      isValidData = false;
    } else if (!validatePassword(String(this.state.newPassword))) {
      this.setState({
        newPasswordError: "Passwords must contain at least 8 characters",
      });

      isValidData = false;
    }

    if (String(this.state.confirmNewPassword) === "") {
      this.setState({ confirmNewPasswordError: "This field is required" });
      isValidData = false;
    } else if (!validatePassword(String(this.state.confirmNewPassword))) {
      this.setState({
        confirmNewPasswordError: "Passwords must contain at least 8 characters",
      });
      isValidData = false;
    } else if (
      !validatePasswordWithoutSpaces(
        String(this.state.confirmNewPassword).trim()
      )
    ) {
      this.setState({
        confirmNewPasswordError: "Passwords should not contain spaces",
      });
      isValidData = false;
    }

    if (
      validatePassword(String(this.state.newPassword)) &&
      validatePassword(String(this.state.confirmNewPassword)) &&
      String(this.state.confirmNewPassword) !== String(this.state.newPassword)
    ) {
      this.setState({
        confirmNewPasswordError: "Password does not match with New Password",
      });
      isValidData = false;
    }

    return isValidData;
  };

  */

  //-------------------- Profile pic ---------------------------

  onHandleUploadProfileImage = (data) => {
    this.props.uploadImage(
      data,
      globalConstants.USER_PROFILE_IMAGE,
      (imageUrl) => {
        if (imageUrl !== null) {
          this.setState({ profilePicture: imageUrl });
        }
      },
      null,
      true
    );
  };

  viewProfilePicClicked = () => {
    this.setState({ viewProfilePicFlag: true });
  };

  deleteProfileClicked = (data) => {
    this.props.deleteImage(
      this.props.loginUser?.id,
      globalConstants.USER_PROFILE_IMAGE,
      (isSuccess) => {
        if (isSuccess) {
          this.setState({ profilePicture: null });
        }
      }
    );
  };

  closeViewProfilePicPopup = () => {
    this.setState({ viewProfilePicFlag: false });
  };

  //-------------------- Signature pic ---------------------------

  onHandleUploadSignImage = (data) => {
    this.props.uploadImage(
      data,
      globalConstants.DOCTOR_SIGNATURE_IMAGE,
      (imageUrl) => {
        if (imageUrl !== null) {
          this.setState({ signPicture: imageUrl });
        }
      },
      null,
      true
    );
  };

  viewSignatureClicked = () => {
    this.setState({ viewSignIatureFlag: true });
  };

  deleteSignatureClicked = (data) => {
    this.props.deleteImage(
      this.props.loginUser?.id,
      globalConstants.DOCTOR_SIGNATURE_IMAGE,
      (isSuccess) => {
        if (isSuccess) {
          this.setState({ signPicture: null });
        }
      }
    );
  };

  closeViewSignaturePopup = () => {
    this.setState({ viewSignIatureFlag: false });
  };

  //-------------------- Hospital Franchise Logo ---------------------------

  onHandleUploadHospitalFranchiseImage = (data) => {
    this.props.uploadImage(
      data,
      globalConstants.HOSPITAL_FRANCHISE_IMAGE,
      (imageUrl) => {
        if (imageUrl !== null) {
          this.setState({ hospitalFranchiseLogo: imageUrl });
        }
      },
      null,
      true
    );
  };

  viewHospitalFranchiseImageClicked = () => {
    this.setState({ viewHospitalFranchiseImageFlag: true });
  };

  deleteHospitalFranchiseImageClicked = (data) => {
    this.props.deleteImage(
      this.props.loginUser?.id,
      globalConstants.HOSPITAL_FRANCHISE_IMAGE,
      (isSuccess) => {
        if (isSuccess) {
          this.setState({ hospitalFranchiseLogo: null });
        }
      }
    );
  };

  closeViewHospitalFranchiseImagePopup = () => {
    this.setState({ viewHospitalFranchiseImageFlag: false });
  };

  render() {
    return (
      <MyProfileView
        userRole={this.state.userRole}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        email={this.state.email}
        securityQuestionsList={this.state.securityQuestionsList}
        selectedSecurityQuestion={this.state.selectedSecurityQuestion}
        answer={this.state.answer}
        mobile={this.state.mobile}
        msciNumber={this.state.msciNumber}
        qualification={this.state.qualification}
        profilePicture={this.state.profilePicture}
        signPicture={this.state.signPicture}
        hospitalFranchiseLogo={this.state.hospitalFranchiseLogo}
        viewProfilePicFlag={this.state.viewProfilePicFlag}
        viewSignIatureFlag={this.state.viewSignIatureFlag}
        viewHospitalFranchiseImageFlag={
          this.state.viewHospitalFranchiseImageFlag
        }
        //
        firstNameError={this.state.firstNameError}
        lastNameError={this.state.lastNameError}
        mobileError={this.state.mobileError}
        answerError={this.state.answerError}
        msciError={this.state.msciError}
        //
        onFirstNameChange={this.onFirstNameChange}
        onLastNameChange={this.onLastNameChange}
        onSecurityQuestionSelected={this.onSecurityQuestionSelected}
        onAnswerChange={this.onAnswerChange}
        onMobileChange={this.onMobileChange}
        onMsciChange={this.onMsciChange}
        onQualificationChange={this.onQualificationChange}
        //
        onCancelClick={this.onCancelClick}
        onResetClick={this.onResetClick}
        onSaveClick={this.onSaveClick}
        // Change Password
        onChangePasswordClick={this.onChangePasswordClick}
        showChangePasswordPopup={this.state.showChangePasswordPopup}
        fields={this.state.fields}
        errors={this.state.errors}
        onPasswordhandleChange={this.onPasswordhandleChange}
        onChangePasswodConfirm={this.onChangePasswodConfirm}
        onChangePasswodCancel={this.onChangePasswodCancel}
        // Profile Image
        onHandleUploadProfileImage={this.onHandleUploadProfileImage}
        viewProfilePicClicked={this.viewProfilePicClicked}
        deleteProfileClicked={this.deleteProfileClicked}
        closeViewProfilePicPopup={this.closeViewProfilePicPopup}
        // Signature Image
        onHandleUploadSignImage={this.onHandleUploadSignImage}
        viewSignatureClicked={this.viewSignatureClicked}
        deleteSignatureClicked={this.deleteSignatureClicked}
        closeViewSignaturePopup={this.closeViewSignaturePopup}
        // Hospital Franchise Logo
        onHandleUploadHospitalFranchiseImage={
          this.onHandleUploadHospitalFranchiseImage
        }
        viewHospitalFranchiseImageClicked={
          this.viewHospitalFranchiseImageClicked
        }
        deleteHospitalFranchiseImageClicked={
          this.deleteHospitalFranchiseImageClicked
        }
        closeViewHospitalFranchiseImagePopup={
          this.closeViewHospitalFranchiseImagePopup
        }
      />
    );
  }
}

MyProfileContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    securityQuestions: state.master.securityQuestions,
    uploadImageData: state.master.uploadImageData,
    UploadImageSuccess: state.master.UploadImageSuccess,
    deleteImageSuccess: state.master.deleteImageSuccess,
  };
};

export default connect(mapStateToProps, {
  getSecurityQuestionsList,
  getMyProfile,
  updateMyProfile,
  changePassword,
  showAlert,
  uploadImage,
  deleteImage,
})(MyProfileContainer);
