import React from "react";
import { DataTable } from "../../../../common";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import { AiFillFilter } from "react-icons/ai";
import { roles } from "../../../../../constants/roles";
import { FaSort } from "react-icons/fa6";
import moment from "moment/moment";
import "./ListStyles.scss";

const DeviceList = (props) => {
  // passing props to data variable
  const data = props.deviceList;
  const userRole = props.userRole;

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          <FaSort
            style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
            size={12}
            onClick={
              props.onDeviceListSortClick !== null
                ? () => props.onDeviceListSortClick(label)
                : () => {}
            }
          />
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const onlineColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const offlineColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const errorColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#FF4D4F",
    };
    const Options = [
      { label: "Online", icon: <div style={onlineColor} /> },
      { label: "Offline", icon: <div style={offlineColor} /> },
      { label: "Error", icon: <div style={errorColor} /> },
    ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              showSelectedItem={true}
              columnName={label}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onDeviceListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {/*  No sort icon  */}
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderEditDeleteAction = (row) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="Edit"
          styles="link"
          onClick={
            props.onEditDeviceClick !== null
              ? () => props.onEditDeviceClick(row)
              : () => {}
          }
        />
        <div className="partition-block" />
        <ThemedButton
          title="Delete"
          styles="link"
          onClick={
            props.onDeleteDeviceClick !== null
              ? () => props.onDeleteDeviceClick(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const renderReportDeviceAction = (row) => {
    return (
      <div className="action-wrapper">
        {row?.status !== 3 ? (
          row?.serialNo == props?.assignedDevice ? (
            <ThemedButton
              title="Report Device"
              styles="link"
              onClick={
                props.onReportDeviceClick !== null
                  ? () => props.onReportDeviceClick(row)
                  : () => {}
              }
            />
          ) : (
            <text style={{ color: "#d9d9d9" }} className="plain-text">
              Report Device
            </text>
          )
        ) : (
          <text className="plain-text">Reported</text>
        )}
      </div>
    );
  };

  const renderDisableDeviceAction = (rowId) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="Disable Device"
          styles="link"
          onClick={
            props.onDisableDeviceClick !== null
              ? () => props.onDisableDeviceClick(rowId)
              : () => {}
          }
        />
      </div>
    );
  };

  const renderAssignAndDisableActions = (row) => {
    return (
      <div className="action-wrapper">
        {row?.status == 3 ? null : (
          <>
            <ThemedButton
              title="Assign Technician"
              styles="link"
              onClick={
                props.onAssignTechnicianClick !== null
                  ? () => props.onAssignTechnicianClick(row)
                  : () => {}
              }
            />

            <div className="partition-block" />

            <ThemedButton
              title="Disable Device"
              styles="link"
              onClick={
                props.onDisableDeviceClick !== null
                  ? () => props.onDisableDeviceClick(row)
                  : () => {}
              }
            />
          </>
        )}
      </div>
    );
  };

  const renderViewReportsAction = (row) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="View Reports"
          styles="link"
          onClick={
            props.onViewReportsClick !== null
              ? () => {
                  var deviceId = row?.id ?? null;
                  var deviceSerialNumber = row?.serialNo ?? "";
                  props.onViewReportsClick(deviceId, deviceSerialNumber);
                }
              : () => {}
          }
        />
      </div>
    );
  };

  //
  const renderStatus = (status) => {
    const onlineColor = { backgroundColor: "#52C41A" };
    const offlineColor = { backgroundColor: "#D9D9D9" };
    const runningColor = { backgroundColor: "#1890FF" };
    const errorColor = { backgroundColor: "#FF4D4F" };

    let statusStyle;
    if (status === "Online") {
      statusStyle = onlineColor;
    }
    if (status === "Offline") {
      statusStyle = offlineColor;
    }
    if (status === "Running") {
      statusStyle = runningColor;
    }
    if (status === "Error") {
      statusStyle = errorColor;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text>{status}</text>
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const renderAddressDetails = (row) => {
    const location = row?.centerDeviceInfo?.center?.addressInfo?.locationName
      ? row.centerDeviceInfo.center.addressInfo.locationName
      : "";

    const district = row?.centerDeviceInfo?.center?.addressInfo?.districtInfo
      ?.name
      ? ", " + row.centerDeviceInfo.center.addressInfo.districtInfo.name
      : "";

    const state = row?.centerDeviceInfo?.center?.addressInfo?.stateInfo?.name
      ? ", " + row.centerDeviceInfo.center.addressInfo.stateInfo.name
      : "";

    return `${location}${district}${state}`;
  };

  // ---------------------  All Column Objects ------------------------

  const deviceId = {
    name: renderHeader("Device ID"),
    id: "device_id",
    center: false,
    sortable: false,
    selector: (row) => row?.serialNo ?? "-",
  };

  const deviceType = {
    name: renderHeader("Device Type"),
    id: "device_type",
    center: false,
    sortable: false,
    selector: (row) => row.deviceType?.type ?? "-",
  };

  const deviceLocation = {
    name: renderHeader("Device Location"),
    id: "device_location",
    center: false,
    sortable: false,
    selector: (row) => renderAddressDetails(row),
  };

  const hospital = {
    name: renderHeader("Hospital"),
    id: "hospital",
    center: false,
    sortable: false,
    selector: (row) => row?.hospital?.hospitalInfo?.hospitalName ?? "-",
  };

  const numberOfEcgRecords = {
    name: renderHeader("No. of ECG Records"),
    id: "ecg_record_count",
    center: false,
    sortable: false,
    selector: (row) => "-",
  };

  const addedOn = {
    name: renderHeader("Added On"),
    id: "added_on",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.createdAt
        ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "-",
  };

  const status = {
    name: renderStatusHeader("Status"),
    id: "status",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.status == 1
        ? renderStatus("Online")
        : row?.status == 2
        ? renderStatus("Offline")
        : row?.status == 3
        ? renderStatus("Error")
        : "-",
  };

  const technician = {
    name: renderHeader("Technician"),
    id: "technician",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.technicianList
        ? Array.isArray(row.technicianList) && row.technicianList.length > 0
          ? row.technicianList[0]?.technician_profile
            ? `${
                (row.technicianList[0].technician_profile?.firstName !== null &&
                row.technicianList[0].technician_profile?.firstName !==
                  undefined
                  ? `${row.technicianList[0].technician_profile?.firstName

                      .charAt(0)

                      .toUpperCase()}${row.technicianList[0].technician_profile?.firstName.slice(
                      1
                    )}`
                  : "") +
                " " +
                (row.technicianList[0].technician_profile?.lastName !== null &&
                row.technicianList[0].technician_profile?.lastName !== undefined
                  ? `${row.technicianList[0].technician_profile?.lastName

                      .charAt(0)

                      .toUpperCase()}${row.technicianList[0].technician_profile?.lastName.slice(
                      1
                    )}`
                  : "")
              }`
            : "-"
          : "-"
        : "-",
  };

  const editDeleteAction = {
    name: renderActionHeader("Action"),
    id: "action",
    center: false,
    selector: (row) => renderEditDeleteAction(row),
  };

  const reportDeviceAction = {
    name: renderActionHeader("Action"),
    id: "action",
    center: false,
    selector: (row) => renderReportDeviceAction(row),
  };

  const disableDeviceAction = {
    name: renderActionHeader("Action"),
    id: "action",
    center: false,
    selector: (row) => renderDisableDeviceAction(row),
  };

  const assignAndDisableActions = {
    name: renderActionHeader("Action"),
    id: "action",
    center: false,
    sortable: false,
    width: "350px",
    selector: (row) => renderAssignAndDisableActions(row),
  };

  const viewReportsAction = {
    name: renderActionHeader("ECG Reports", false),
    id: "action",
    center: false,
    selector: (row) => renderViewReportsAction(row),
  };

  // ------------------  Add Columns According to Role -------------------

  // We will add needed columns to this array according to role.
  var columns = [];

  if (userRole === roles.superAdmin) {
    columns.push(deviceId);
    columns.push(deviceType);
    columns.push(hospital);
    columns.push(addedOn);
    columns.push(status);
    columns.push(editDeleteAction);
  }
  //
  else if (userRole === roles.technician) {
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(deviceLocation);
    columns.push(status);
    columns.push(reportDeviceAction);
  }
  //
  else if (userRole === roles.hospitalAdmin) {
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(numberOfEcgRecords);
    columns.push(status);
    columns.push(viewReportsAction);
    columns.push(disableDeviceAction);
  }
  //
  else if (userRole === roles.centerAdmin) {
    columns.push(deviceId);
    columns.push(deviceType);
    columns.push(status);
    columns.push(technician);
    columns.push(assignAndDisableActions);
  }
  //
  else {
    columns.push(deviceId);
    columns.push(deviceType);
    columns.push(addedOn);
  }

  return (
    <div className="device-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          className="data-table"
          columns={columns}
          data={data}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRows={roles.superAdmin === props.userRole}
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalDevices}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default DeviceList;
