export const actionTypes = {
  GET_PATIENT_INSURANCE_BY_ID_PENDING: "GET_PATIENT_INSURANCE_BY_ID_PENDING",
  GET_PATIENT_INSURANCE_BY_ID_SUCCESS: "GET_PATIENT_INSURANCE_BY_ID_SUCCESS",
  GET_PATIENT_INSURANCE_BY_ID_ERROR: "GET_PATIENT_INSURANCE_BY_ID_ERROR",

  ADMIT_NEW_PATIENT_PENDING: "ADMIT_NEW_PATIENT_PENDING",
  ADMIT_NEW_PATIENT_SUCCESS: "ADMIT_NEW_PATIENT_SUCCESS",
  ADMIT_NEW_PATIENT_ERROR: "ADMIT_NEW_PATIENT_ERROR",

  ADD_PATIENT_INSURANCE_PENDING: "ADD_PATIENT_INSURANCE_PENDING",
  ADD_PATIENT_INSURANCE_SUCCESS: "ADD_PATIENT_INSURANCE_SUCCESS",
  ADD_PATIENT_INSURANCE_ERROR: "ADD_PATIENT_INSURANCE_ERROR",

  EDIT_PATIENT_INSURANCE_PENDING: "EDIT_PATIENT_INSURANCE_PENDING",
  EDIT_PATIENT_INSURANCE_SUCCESS: "EDIT_PATIENT_INSURANCE_SUCCESS",
  EDIT_PATIENT_INSURANCE_ERROR: "EDIT_PATIENT_INSURANCE_ERROR",

  DELETE_PATIENT_INSURANCE_PENDING: "DELETE_PATIENT_INSURANCE_PENDING",
  DELETE_PATIENT_INSURANCE_SUCCESS: "DELETE_PATIENT_INSURANCE_SUCCESS",
  DELETE_PATIENT_INSURANCE_ERROR: "DELETE_PATIENT_INSURANCE_ERROR",

  EDIT_PATIENT_PENDING: "EDIT_PATIENT_PENDING",
  EDIT_PATIENT_SUCCESS: "EDIT_PATIENT_SUCCESS",
  EDIT_PATIENT_ERROR: "EDIT_PATIENT_ERROR",

  TRANSFER_PATIENT_UPDATE_VITALS_PENDING:
    "TRANSFER_PATIENT_UPDATE_VITALS_PENDING",
  TRANSFER_PATIENT_UPDATE_VITALS_SUCCESS:
    "TRANSFER_PATIENT_UPDATE_VITALS_SUCCESS",
  TRANSFER_PATIENT_UPDATE_VITALS_ERROR: "TRANSFER_PATIENT_UPDATE_VITALS_ERROR",

  TRANSFER_PATIENT_ASSIGNED_HOSPITAL_PENDING:
    "TRANSFER_PATIENT_ASSIGNED_HOSPITAL_PENDING",
  TRANSFER_PATIENT_ASSIGNED_HOSPITAL_SUCCESS:
    "TRANSFER_PATIENT_ASSIGNED_HOSPITAL_SUCCESS",
  TRANSFER_PATIENT_ASSIGNED_HOSPITAL_ERROR:
    "TRANSFER_PATIENT_ASSIGNED_HOSPITAL_ERROR",

  TRANSFER_PATIENT_ASSIGNED_AMBULANCE_PENDING:
    "TRANSFER_PATIENT_ASSIGNED_AMBULANCE_PENDING",
  TRANSFER_PATIENT_ASSIGNED_AMBULANCE_SUCCESS:
    "TRANSFER_PATIENT_ASSIGNED_AMBULANCE_SUCCESS",
  TRANSFER_PATIENT_ASSIGNED_AMBULANCE_ERROR:
    "TRANSFER_PATIENT_ASSIGNED_AMBULANCE_ERROR",

  TRANSFER_PATIENT_ADD_VEHICLE_PENDING: "TRANSFER_PATIENT_ADD_VEHICLE_PENDING",
  TRANSFER_PATIENT_ADD_VEHICLE_SUCCESS: "TRANSFER_PATIENT_ADD_VEHICLE_SUCCESS",
  TRANSFER_PATIENT_ADD_VEHICLE_ERROR: "TRANSFER_PATIENT_ADD_VEHICLE_ERROR",

  TRANSFER_PATIENT_DETAILS_PENDING: "TRANSFER_PATIENT_DETAILS_PENDING",
  TRANSFER_PATIENT_DETAILS_SUCCESS: "TRANSFER_PATIENT_DETAILS_SUCCESS",
  TRANSFER_PATIENT_DETAILS_ERROR: "TRANSFER_PATIENT_DETAILS_ERROR",

  INITIATE_TRANSFER_PROCESS_PENDING: "INITIATE_TRANSFER_PROCESS_PENDING",
  INITIATE_TRANSFER_PROCESS_SUCCESS: "INITIATE_TRANSFER_PROCESS_SUCCESS",
  INITIATE_TRANSFER_PROCESS_ERROR: "INITIATE_TRANSFER_PROCESS_ERROR",
};

const initialState = {
  getPatientInsuranceDetailsByIdPending: false,
  getPatientInsuranceDetailsByIdSuccess: false,
  getPatientInsuranceDetailsByIdError: null,

  admitPatientPending: false,
  admitPatientSuccess: false,
  admitPatientError: null,

  addPatientInsurancePending: false,
  addPatientInsuranceSuccess: false,
  addPatientInsuranceError: null,

  editPatientInsurancePending: false,
  editPatientInsuranceSuccess: false,
  editPatientInsuranceError: null,

  editPatientPending: false,
  editPatientSuccess: false,
  editPatientError: null,

  deletePatientInsurancePending: false,
  deletePatientInsuranceSuccess: false,
  deletePatientInsuranceError: null,

  transferPatientUpdateVitalsPending: false,
  transferPatientUpdateVitalsSuccess: false,
  transferPatientUpdateVitalsError: null,

  transferPatientAssignedHospitalPending: false,
  transferPatientAssignedHospitalSuccess: false,
  transferPatientAssignedHospitalError: null,

  transferPatientAssignedAmbulancePending: false,
  transferPatientAssignedAmbulanceSuccess: false,
  transferPatientAssignedAmbulanceError: null,

  transferPatientAddVehiclePending: false,
  transferPatientAddVehicleSuccess: false,
  transferPatientAddVehicleError: null,

  transferPatientPending: false,
  transferPatientSuccess: false,
  transferPatientError: null,

  initiateTransferProcessPending: false,
  initiateTransferProcessSuccess: false,
  initiateTransferProcessError: null,

  patientDetails: {},
  patientInsuranceDetails: {},
};

export default function patient(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_PATIENT_INSURANCE_BY_ID_PENDING:
    case actionTypes.GET_PATIENT_INSURANCE_BY_ID_SUCCESS:
    case actionTypes.GET_PATIENT_INSURANCE_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADMIT_NEW_PATIENT_PENDING:
    case actionTypes.ADMIT_NEW_PATIENT_SUCCESS:
    case actionTypes.ADMIT_NEW_PATIENT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_PATIENT_PENDING:
    case actionTypes.EDIT_PATIENT_SUCCESS:
    case actionTypes.EDIT_PATIENT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_PATIENT_INSURANCE_PENDING:
    case actionTypes.ADD_PATIENT_INSURANCE_SUCCESS:
    case actionTypes.ADD_PATIENT_INSURANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_PATIENT_INSURANCE_PENDING:
    case actionTypes.EDIT_PATIENT_INSURANCE_SUCCESS:
    case actionTypes.EDIT_PATIENT_INSURANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_PATIENT_INSURANCE_PENDING:
    case actionTypes.DELETE_PATIENT_INSURANCE_SUCCESS:
    case actionTypes.DELETE_PATIENT_INSURANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.TRANSFER_PATIENT_DETAILS_PENDING:
    case actionTypes.TRANSFER_PATIENT_DETAILS_SUCCESS:
    case actionTypes.TRANSFER_PATIENT_DETAILS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_PENDING:
    case actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_SUCCESS:
    case actionTypes.TRANSFER_PATIENT_UPDATE_VITALS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_PENDING:
    case actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_SUCCESS:
    case actionTypes.TRANSFER_PATIENT_ASSIGNED_AMBULANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_PENDING:
    case actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_SUCCESS:
    case actionTypes.TRANSFER_PATIENT_ASSIGNED_HOSPITAL_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_PENDING:
    case actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_SUCCESS:
    case actionTypes.TRANSFER_PATIENT_ADD_VEHICLE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.INITIATE_TRANSFER_PROCESS_PENDING:
    case actionTypes.INITIATE_TRANSFER_PROCESS_SUCCESS:
    case actionTypes.INITIATE_TRANSFER_PROCESS_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
