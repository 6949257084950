import Api from "../../service/reportapi";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./dashboardReducer";
import moment from "moment";

export function getDashboardData() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DASHBOARD_DATA_PENDING,
      payload: {
        getDashboardDataPending: true,
        getDashboardDataSuccess: false,
        getDashboardDataError: null,
      },
    });
    dispatch(showLoader());

    Api.post("dashboard/data")
      .then((response) => {
        const lastUpdateDiv = document.getElementById("updated-time-info-text");
        if (lastUpdateDiv) {
          lastUpdateDiv.innerHTML = `Last Updated: ${moment().calendar()}`;
        }

        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
          payload: {
            getDashboardDataPending: false,
            getDashboardDataSuccess: true,
            getDashboardDataError: null,
            dashboardData: response?.data ?? {},
            lastUpdated: moment().calendar(),
            lastUpdatedTimestamp: moment().toString(),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_DASHBOARD_DATA_ERROR,
          payload: {
            getDashboardDataPending: false,
            getDashboardDataSuccess: true,
            getDashboardDataError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getTopPerformers(data, callback) {
  /*   The Data for gettign top performers should be sent in below format :

        {
          "entity": "hospital/center/doctor/technician",
          "duration": "week/month/year/custom (for today or any other date range)",
          "startTime": 1223456,
          "endTime": 123456789,
          "limit": 10
        }
  
  */

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TOP_PERFORMERS_PENDING,
      payload: {
        getTopPerformersPending: true,
        getTopPerformersSuccess: false,
        getTopPerformersError: null,
      },
    });
    dispatch(showLoader());

    Api.post("dashboard/topPerformers", data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        dispatch({
          type: actionTypes.GET_TOP_PERFORMERS_SUCCESS,
          payload: {
            getTopPerformersPending: false,
            getTopPerformersSuccess: true,
            getTopPerformersError: null,
            topPerformersData: response?.data ?? {},
            // lastUpdated: moment().calendar(),
            // lastUpdatedTimestamp: moment().toString(),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.GET_TOP_PERFORMERS_ERROR,
          payload: {
            getTopPerformersPending: false,
            getTopPerformersSuccess: true,
            getTopPerformersError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function resetDashboardData() {
  console.log("Dashboard Data reset..");
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_DASHBOARD_DATA,
      payload: {
        dashboardData: {},
        topPerformersData: {},
        lastUpdated: moment().calendar(),
        lastUpdatedTimestamp: moment().toString(),
      },
    });
  };
}
