import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  CardView,
  Imageuploader,
  RadioButtonGroup,
  TextArea,
  ThemedButton,
  Input,
  DatePicker,
  FormSearchView,
  FileUploader,
} from "../../../../controls";
import InfoTextBlock from "../Components/InfoTextBlock";
import InfoTextInline from "../Components/InfoTextInline";
import { FaAngleLeft, FaPlus } from "react-icons/fa";
import { PopupDialog } from "../../Components";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdPeople } from "react-icons/md";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { AiOutlineComment, AiOutlineFileExcel } from "react-icons/ai";
import { globalConstants } from "../../../../../constants/globalConstants";
import { LuEye } from "react-icons/lu";
import patient_details from "../../../../../assets/images/icons/patient_details_card.png";
import patient_vitals from "../../../../../assets/images/icons/patient_vitals_card.png";
import patient_pulse from "../../../../../assets/images/icons/patient_pulse.png";
import patient_rr from "../../../../../assets/images/icons/patient_rr.png";
import patient_bp from "../../../../../assets/images/icons/patient_bp.png";
import patient_spo2 from "../../../../../assets/images/icons/patient_spo2.png";
import patient_weight from "../../../../../assets/images/icons/patient_weight.png";
import patient_bs from "../../../../../assets/images/icons/patient_blood_sugar.png";
import technician_device from "../../../../../assets/images/icons/devices_card.png";
import ambulance_details from "../../../../../assets/images/icons/ambulance_details_card.png";
import admission_details from "../../../../../assets/images/icons/admission_details_card.png";
import StepperView from "../../../../controls/Stepper/StepperView";
import moment from "moment";
import "./CaseDetailsStyles.scss";

const CaseDetailsView = (props) => {
  const [UIViewSwitch, setUIViewSwitch] = useState(props.caseUIType);

  useEffect(() => {
    console.log("UI TYPE ::: ", props.caseUIType);
    setUIViewSwitch(props.caseUIType);
  }, [props.caseUIType]);

  const transferDataArray =
    props.caseDetailsInfo?.transferredCase?.length > 0
      ? props.caseDetailsInfo?.transferredCase
      : [];

  const admissionDetails =
    props.caseDetailsInfo?.hospitalCaseActivity?.length > 0
      ? props.caseDetailsInfo?.hospitalCaseActivity.find(
          (item) => item?.hospitalId == props.hospitalId
        )
      : null;

  const doctorName =
    admissionDetails != null && admissionDetails != undefined
      ? `Dr. ${
          (admissionDetails?.doctorInfo?.firstName !== null &&
          admissionDetails?.doctorInfo?.firstName !== undefined
            ? `${admissionDetails?.doctorInfo?.firstName
                .charAt(0)
                .toUpperCase()}${admissionDetails?.doctorInfo?.firstName.slice(
                1
              )}`
            : "") +
          " " +
          (admissionDetails?.doctorInfo?.lastName !== null &&
          admissionDetails?.doctorInfo?.lastName !== undefined
            ? `${admissionDetails?.doctorInfo?.lastName
                .charAt(0)
                .toUpperCase()}${admissionDetails?.doctorInfo?.lastName.slice(
                1
              )}`
            : "")
        }`
      : "-";

  //------------------- Render Function ---------------------------

  const renderAddPatientPopupContents = () => {
    // here if insurance data is present then getting dates in DD-MM-YYYY format
    // minDate only accept date object not a string
    const dateLimit = props.insuranceInfo
      ? new Date(props.addPatientFields.policyStartDate)
      : props.addPatientFields.policyStartDate
      ? props.addPatientFields.policyStartDate
      : null;

    const insuranceDetailsForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group vertical-middle to-error">
            <label className="form-label">
              <span className="required">* </span> Patient Insurance :
            </label>
            <div className="value-item ">
              <RadioButtonGroup
                data={props.patientInsuranceData}
                size="lg"
                name="insurance-flag-group"
                value={
                  props.addPatientFields.isInsurance
                    ? props.patientInsuranceData[0]
                    : props.patientInsuranceData[1]
                }
                onItemSelected={props.onPatientInsuranceButtonClicked}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Company Name :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Company Name"
                value={props.addPatientFields.companyName}
                onChange={props.onAddPatientFieldChange("companyName")}
                error={props.errors["companyName"]}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Insurance Type :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Insurance Type"
                value={props.addPatientFields.incuranceType}
                onChange={props.onAddPatientFieldChange("incuranceType")}
                error={props.errors["incuranceType"]}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Insurance Name :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Insurance Number"
                value={props.addPatientFields.incuranceName}
                onChange={props.onAddPatientFieldChange("incuranceName")}
                error={props.errors["incuranceName"]}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Policy Number :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Policy Number"
                value={props.addPatientFields.policyNumber}
                onChange={props.onAddPatientFieldChange("policyNumber")}
                error={props.errors["policyNumber"]}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Policy Start Date :
            </label>
            <div className="value-item">
              <DatePicker
                value={props.addPatientFields.policyStartDate}
                onChange={props.onAddPatientFieldChange("policyStartDate")}
                error={props.errors["policyStartDate"]}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Policy End Date :
            </label>
            <div className="value-item">
              <DatePicker
                value={props.addPatientFields.policyEndDate}
                onChange={props.onAddPatientFieldChange("policyEndDate")}
                error={props.errors["policyEndDate"]}
                disabled={props.disableInsuranceFormField}
                minDate={dateLimit}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group vertical-middle to-error">
            <label className="form-label inc-label-width">
              <span className="required">* </span> Patient Relationship with
              Insured :
            </label>
            <div className="value-item ">
              <RadioButtonGroup
                data={props.patientRelWithInsuredData}
                error={props.errors["relationWithInsured"]}
                size="lg"
                name="relation-group"
                value={props.addPatientFields.relationWithInsured}
                onItemSelected={props.onAddPatientFieldChange(
                  "relationWithInsured"
                )}
                disabled={props.disableInsuranceFormField}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group vertical-middle">
            <label className="form-label">
              <span className="required">* </span>Insurance Card :
            </label>
            <div className="value-item">
              <Imageuploader
                imageUrl={props.addPatientFields.insuranceCardImage}
                uploadFileHandle={props.onHandleUploadInsuranceCardImage}
                viewIconHandle={props.viewInsuranceCardImageClicked}
                deleteIconHandle={props.deleteInsuranceCardImageClicked}
                type={globalConstants.INSURANCE_CARD_IMAGE}
                disabled={props.disableInsuranceFormField}
                error={props.errors["insuranceCardImage"]}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
      );
    };
    const assignmentDetailsForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Bed Number :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Bed Number"
                value={props.addPatientFields.bedNumber}
                onChange={props.onAddPatientFieldChange("bedNumber")}
                error={props.errors["bedNumber"]}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Assigned Doctor :
            </label>
            <div className="value-item extra">
              <FormSearchView
                Options={props.doctorList}
                placeholder="Search Doctor Name"
                previousValue={props.addPatientFields.assignedDoctor}
                onInputChange={props.onDoctorSearchChange}
                onChange={props.onAssignedDoctorChange}
                onScroll={props.onScrollDoctorList}
                searchText={props.searchDoctorText}
                error={props.errors["assignedDoctor"]}
                lazyLoading={true}
                customInputSearch={true}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group top-placement">
            <label className="form-label">Notes :</label>
            <div className="value-item">
              <TextArea
                label={null}
                onChange={props.onAddPatientFieldChange("admitNotes")}
                value={props.addPatientFields.admitNotes}
                placeholder="Please Enter Your Notes"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
      );
    };
    const steps = ["Insurance Details", "Assignment Details"];
    const multiSteps = [insuranceDetailsForm(), assignmentDetailsForm()];

    return (
      <div style={{ paddingTop: "10px" }}>
        <StepperView
          steps={steps}
          bodySteps={multiSteps}
          parentStep={props.activeStep}
        />
      </div>
    );
  };

  const renderTransferPatientPopupContents = () => {
    const vitalsForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Pulse :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Pulse"
                value={props.transferPatientFields.pulse}
                onChange={props.onTransferPatientFieldChange("pulse")}
                error={props.errors["pulse"]}
                showInputUnitText={"bpm"}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>RR :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter RR"
                value={props.transferPatientFields.rr}
                onChange={props.onTransferPatientFieldChange("rr")}
                error={props.errors["rr"]}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>BP :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter BP"
                value={props.transferPatientFields.bloodPressure}
                onChange={props.onTransferPatientFieldChange("bloodPressure")}
                error={props.errors["bloodPressure"]}
                showInputUnitText={"mmHg"}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>SpO2 :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter SpO2"
                value={props.transferPatientFields.spo2}
                onChange={props.onTransferPatientFieldChange("spo2")}
                error={props.errors["spo2"]}
                showInputUnitText={"%"}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Weight :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Weight"
                value={props.transferPatientFields.weight}
                onChange={props.onTransferPatientFieldChange("weight")}
                error={props.errors["weight"]}
                showInputUnitText={"kgs"}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Blood Sugar :
            </label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Blood Sugar"
                value={props.transferPatientFields.bloodSugar}
                onChange={props.onTransferPatientFieldChange("bloodSugar")}
                error={props.errors["bloodSugar"]}
                showInputUnitText={"mg/dL"}
                isvalnum="isNum"
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
      );
    };
    const assignHospitalForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group">
            <label className="form-label">
              <span className="required">* </span>Assign Hospital :
            </label>
            <div className="value-item extra">
              <FormSearchView
                Options={props.hospitalList}
                placeholder="Search Hospital Name"
                onChange={props.onAssignedHospitalChange}
                lazyLoading={true}
                // onInputChange={props.onHospitalSearchChange}
                // customInputSearch={true}
                previousValue={props.transferPatientFields.assignedHospital}
                // onScroll={props.onScrollHospitalList}
                error={props.errors["assignedHospital"]}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
      );
    };
    const assignAmbulanceForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group">
            <label className="form-label">
              {props.showAddVehicleManuallyForm ? null : (
                <span className="required">* </span>
              )}
              Assign Ambulance :
            </label>
            <div className="value-item extra">
              <FormSearchView
                Options={props.ambulanceList}
                placeholder="Search Ambulance Name"
                onChange={props.onAssignedAmbulanceChange}
                lazyLoading={true}
                onInputChange={props.onAmbulanceSearchChange}
                customInputSearch={true}
                previousValue={props.transferPatientFields.assignedAmbulance}
                onScroll={props.onScrollAmbulanceList}
                error={props.errors["assignedAmbulance"]}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group">
            <label className="form-label"></label>
            <div className="value-item">
              <div className="optional-seperator">
                <div className="hz-form-seperator" />
                <text className="text-view">OR</text>
                <div className="hz-form-seperator" />
              </div>
            </div>
            <div className="last-dummy-item" />
          </div>
          {props.showAddVehicleManuallyForm ? (
            <>
              <div className="block-level-group">
                <label className="form-label">
                  <span className="required">* </span>Vehicle Name :
                </label>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Vehicle Name"
                    value={props.transferPatientFields.vehicleName}
                    onChange={props.onTransferPatientFieldChange("vehicleName")}
                    error={props.errors["vehicleName"]}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
              <div className="block-level-group">
                <label className="form-label">
                  <span className="required">* </span>Vehicle Number :
                </label>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Vehicle Number"
                    value={props.transferPatientFields.vehicleNumber}
                    onChange={props.onTransferPatientFieldChange(
                      "vehicleNumber"
                    )}
                    error={props.errors["vehicleNumber"]}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
              <div className="block-level-group vertical-middle to-error">
                <label className="form-label">
                  <span className="required">* </span> Vehicle Type :
                </label>
                <div className="value-item ">
                  <RadioButtonGroup
                    data={props.vehicleTypeData}
                    name="vehicle-type-group"
                    error={props.errors["vehicleType"]}
                    size="lg"
                    value={props.transferPatientFields.vehicleType}
                    onItemSelected={props.onTransferPatientFieldChange(
                      "vehicleType"
                    )}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
              <div className="block-level-group vertical-middle">
                <label className="form-label">Vehicle Image :</label>
                <div className="value-item">
                  <Imageuploader
                    imageUrl={props.transferPatientFields.vehicleImage}
                    uploadFileHandle={props.onHandleUploadManualVehicleImage}
                    viewIconHandle={props.viewAddManuallyVehicleImageClicked}
                    deleteIconHandle={props.deleteManualVehicleImageClicked}
                    type={globalConstants.VEHICLE_IMAGE}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
              <div className="block-level-group">
                <label className="form-label">
                  <span className="required">* </span>Attendant Name :
                </label>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder="Enter First Name"
                    value={props?.transferPatientFields?.attendantFirstName}
                    error={props.errors["attendantFirstName"]}
                    onChange={props.onTransferPatientFieldChange(
                      "attendantFirstName"
                    )}
                    halfsize={true}
                    charLimit={50}
                    showCharLimit={true}
                  />
                  <div style={{ width: "20px" }} />
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Last Name"
                    value={props?.transferPatientFields?.attendantLastName}
                    error={props.errors["attendantLastName"]}
                    onChange={props.onTransferPatientFieldChange(
                      "attendantLastName"
                    )}
                    halfsize={true}
                    charLimit={50}
                    showCharLimit={true}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
              <div className="block-level-group">
                <label className="form-label">
                  <span className="required">* </span>Attendant Number :
                </label>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder="Enter Attendant Number"
                    value={props.transferPatientFields.attendantContact}
                    onChange={props.onTransferPatientFieldChange(
                      "attendantContact"
                    )}
                    charLimit={10}
                    error={props.errors["attendantContact"]}
                    showMobilePrefix={true}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
            </>
          ) : (
            <div className="block-level-group">
              <label className="form-label"></label>
              <div className="value-item">
                <div className="optional-seperator">
                  <ThemedButton
                    title="Add Vehicle Details Manually"
                    size={"large"}
                    icon={<FaPlus size={15} color="#fff" />}
                    onClick={() => {
                      props.onAddVehicleManuallyClick();
                    }}
                    styles="primary huge"
                  />
                </div>
              </div>
              <div className="last-dummy-item" />
            </div>
          )}
        </div>
      );
    };

    const addDetailsForm = () => {
      return (
        <div className="add-new-patient-form">
          <div className="block-level-group">
            <label className="form-label">Doctor name :</label>
            <div className="value-item">
              <Input
                className="input-wrapper"
                placeholder="Enter Doctor name"
                readonly
                value={props.transferPatientFields.doctorName}
                // onChange={props.onTransferPatientFieldChange("doctorName")}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group top-placement">
            <label className="form-label">
              <span className="required">* </span>Transfer Notes :
            </label>
            <div className="value-item ">
              <TextArea
                onChange={props.onTransferPatientFieldChange("transferNotes")}
                value={props.transferPatientFields.transferNotes}
                placeholder="Enter Your Notes"
                row={3}
                error={props.errors["transferNotes"]}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
          <div className="block-level-group vertical-middle">
            <label className="form-label">Documents :</label>
            <div className="value-item">
              <FileUploader
                documents={props.transferPatientFields.documents}
                uploadFilesHandle={props.onUploadTransferPatientDocuments}
              />
            </div>
            <div className="last-dummy-item" />
          </div>
        </div>
      );
    };
    const steps = [
      "Add Vitals",
      "Assign Hospital",
      "Assign Ambulance",
      "Add Details",
    ];
    const multiSteps = [
      vitalsForm(),
      assignHospitalForm(),
      assignAmbulanceForm(),
      addDetailsForm(),
    ];

    return (
      <div style={{ paddingTop: "10px" }}>
        <StepperView
          steps={steps}
          bodySteps={multiSteps}
          parentStep={props.activeStep}
        />
      </div>
    );
  };

  const renderCloseCasePopupContents = () => {
    return (
      <div className="add-new-patient-form">
        <div className="block-level-group">
          <label className="form-label">Doctor name :</label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Doctor name"
              // disabled={true}
              readonly
              value={props.closeCaseFields.caseAssignedDoctor}
              // onChange={props.onCloseCaseFieldChange("caseAssignedDoctor")}
              error={props.errors["caseAssignedDoctor"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group top-placement">
          <label className="form-label">
            <span className="required">* </span>Closing Notes :
          </label>
          <div className="value-item">
            <TextArea
              label={null}
              onChange={props.onCloseCaseFieldChange("caseClosedReason")}
              value={props.closeCaseFields.caseClosedReason}
              placeholder="Please Enter Your Notes"
              error={props.errors["caseClosedReason"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group vertical-middle">
          <label className="form-label">Documents :</label>
          <div className="value-item">
            <FileUploader
              uploadFilesHandle={props.onUploadCloseCaseDocuments}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
      </div>
    );
  };

  const renderViewMorePopupContents = () => {
    // passing some extra style to download links
    // console.log("viewMoreData", props.viewMoreData);

    return (
      <div className="" style={{ padding: "10px" }}>
        {props.viewMoreData?.id == "docs"
          ? renderDocumentDetails(props.viewMoreData?.data, {
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "4px",
            })
          : props.viewMoreData?.data}
      </div>
    );
  };

  const renderTransferDetailsPopupContents = () => {
    const noteObject =
      props.trasnferCaseInfo?.transferNote?.length > 0
        ? props.trasnferCaseInfo?.transferNote[0]
        : null;

    let hospitalName =
      props.caseUIType == globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER
        ? props?.trasnferCaseInfo?.transferredFromHospital?.hospitalName ?? "-"
        : props?.trasnferCaseInfo?.transferredToHospital?.hospitalName ?? "-";

    let Note = noteObject?.note ?? "-";
    return (
      <div className="closing-transfer-details-view">
        <div className="block-level-group">
          <div className="field-label">Hospital Name :</div>
          <div className="field-value">{hospitalName}</div>
        </div>
        <div className="block-level-group">
          <div className="field-label">Transfer Notes :</div>
          <div className="field-value">{Note}</div>
        </div>
      </div>
    );
  };

  const renderClosingNotesPopupContents = () => {
    let closeReason = props.caseDetailsInfo?.caseClosedReason ?? "-";

    return (
      <div className="closing-transfer-details-view">
        {/* <div className="block-level-group">
          <div className="field-label">Doctor Name :</div>
          <div className="field-value">{doctorName}</div>
        </div> */}
        <div className="block-level-group">
          <div className="field-label">Closing Notes :</div>
          <div className="field-value">{closeReason}</div>
        </div>
      </div>
    );
  };

  const renderAdmissionDetailsViewContents = () => {
    const hospitalActivityObject =
      props.caseDetailsInfo?.hospitalCaseActivity?.length > 0
        ? props.caseDetailsInfo?.hospitalCaseActivity.find(
            (item) => item?.hospitalId == props.hospitalId
          )
        : null;

    let doctorName = `Dr. ${
      (hospitalActivityObject?.doctorInfo?.firstName !== null &&
      hospitalActivityObject?.doctorInfo?.firstName !== undefined
        ? `${hospitalActivityObject?.doctorInfo?.firstName
            .charAt(0)
            .toUpperCase()}${hospitalActivityObject?.doctorInfo?.firstName.slice(
            1
          )}`
        : "") +
      " " +
      (hospitalActivityObject?.doctorInfo?.lastName !== null &&
      hospitalActivityObject?.doctorInfo?.lastName !== undefined
        ? `${hospitalActivityObject?.doctorInfo?.lastName
            .charAt(0)
            .toUpperCase()}${hospitalActivityObject?.doctorInfo?.lastName.slice(
            1
          )}`
        : "")
    }`;

    const admissionNote =
      props.caseDetailsInfo?.caseNotes?.length > 0
        ? props.caseDetailsInfo?.caseNotes.find(
            (item) =>
              item?.entityId == props.hospitalId &&
              item?.noteType == globalConstants.ADMISSION_NOTE
          )
        : null;

    return (
      <div className="closing-transfer-details-view">
        <div className="block-level-group">
          <div className="field-label">Bed Number :</div>
          <div className="field-value">
            {hospitalActivityObject?.bedNumber ?? "-"}
          </div>
        </div>
        <div className="block-level-group">
          <div className="field-label">Assigned Doctor :</div>
          <div className="field-value">{doctorName}</div>
        </div>
        <div className="block-level-group">
          <div className="field-label">Notes :</div>
          <div className="field-value">{admissionNote?.note ?? "-"}</div>
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    var UIType = UIViewSwitch;

    const Closed = { backgroundColor: "#D9D9D9" };
    const Admit = { backgroundColor: "#52C41A" };
    const Transferred = { backgroundColor: "rgba(255, 122, 69, 1)" };
    const InTransit = { backgroundColor: "#1890FF" };
    const Arrived = { backgroundColor: "#87D068" };

    var status = "-";
    var statusStyle = null;

    if (UIType === globalConstants.CASE_UI_FOR_CLOSED) {
      status = "Closed";
      statusStyle = Closed;
    }
    if (UIType === globalConstants.CASE_UI_FOR_ADMIT) {
      status = "Admit";
      statusStyle = Admit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_TRANSFERRED) {
      status = "Transferred";
      statusStyle = Transferred;
    }
    if (UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT) {
      status = "In Transit";
      statusStyle = InTransit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER) {
      status = "In Transit (Transfer)";
      statusStyle = InTransit;
    }
    if (UIType === globalConstants.CASE_UI_FOR_ARRIVED) {
      status = "Arrived";
      statusStyle = Arrived;
    }

    return (
      <div className="status-wrapper" style={statusStyle}>
        <text>{status}</text>
      </div>
    );
  };

  const renderVitalAsPerStyles = (unit, data1, data2 = null) => {
    const value1 = data1 !== null && data1 !== undefined ? data1 : "-";
    const value2 = data2 !== null && data2 !== undefined ? data2 : "-";
    const valueUnit =
      unit == "Pulse"
        ? "bpm"
        : unit == "Bp"
        ? "mmHg"
        : unit == "Weight"
        ? "kgs"
        : unit == "spO2"
        ? "%"
        : unit == "bloodSugar"
        ? "mg/dL"
        : "";
    return !props.isTransferredCase ? (
      <div className="patient-vital-in">
        <div className="in-values">
          <text className="lg-text">{` ${value1}`}</text>
          <text className="unit">
            {value1 !== "-" ? ` ${valueUnit}` : null}
          </text>
        </div>
      </div>
    ) : (
      <div className="patient-vital-in-out">
        <div className="in-values">
          In:
          <text className="lg-text">{` ${value1}`}</text>
          <text className="unit">
            {value1 !== "-" ? ` ${valueUnit}` : null}
          </text>
        </div>
        <div className="in-values">
          Out:
          <text className="lg-text">{` ${value2}`}</text>
          <text className="unit">
            {value1 !== "-" ? ` ${valueUnit}` : null}
          </text>
        </div>
      </div>
    );
  };

  const renderDocumentDetails = (docArray, extraStyle = null) => {
    const handleLinkClick = (key) => {
      console.log(docArray, "on click event", key);
      if (docArray) {
        const selectedFile = docArray.find((item) => item.id == key);
        const filename = selectedFile?.fileName ?? "";

        const link = document.createElement("a");

        // link.download = filename;
        // link.target = "_blank";
        link.href = selectedFile?.documentPath;
        link.click();
        link.remove();
      }
    };

    return (
      <text style={extraStyle} className="dt-link ">
        {docArray?.length > 0
          ? docArray.map((item, i) => {
              return (
                <text
                  id="docc-links"
                  key={item?.id ?? null}
                  values={item?.fileName ?? null}
                  onClick={() => handleLinkClick(item?.id)}
                >
                  {item?.fileName?.split("/").pop() +
                    (extraStyle == null
                      ? docArray?.length == 1 || i == docArray?.length
                        ? "."
                        : ","
                      : "")}
                </text>
              );
            })
          : "-"}
      </text>
    );
  };

  const renderPatientDetails = () => {
    const showInsuranceDetailsCard =
      UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ||
      UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ||
      UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED ||
      UIViewSwitch == globalConstants.CASE_UI_FOR_TRANSFERRED;
    const historyReadMore =
      props.caseDetailsInfo?.patientHistory?.length > 50 ? true : false;

    const caseDocuments = props.caseDetailsInfo?.caseDocuments ?? [];
    let totalDocNames = "";
    caseDocuments.map((item) => (totalDocNames += item?.fileName + ", "));
    // console.log("totalDocNames", totalDocNames.length);
    const docsReadMore = totalDocNames?.length > 50 ? true : false;
    const gender =
      props.caseDetailsInfo?.gender == 1
        ? "Male"
        : props.caseDetailsInfo?.gender == 2
        ? "Female"
        : "-";
    const patientName =
      props.caseDetailsInfo !== null && props.caseDetailsInfo !== undefined
        ? props.caseDetailsInfo?.firstName !== null &&
          props.caseDetailsInfo?.firstName !== undefined &&
          props.caseDetailsInfo?.lastName !== null &&
          props.caseDetailsInfo?.lastName !== undefined
          ? `${
              (props.caseDetailsInfo?.firstName !== null &&
              props.caseDetailsInfo?.firstName !== undefined
                ? `${props.caseDetailsInfo?.firstName
                    .charAt(0)
                    .toUpperCase()}${props.caseDetailsInfo?.firstName.slice(1)}`
                : "") +
              " " +
              (props.caseDetailsInfo?.lastName !== null &&
              props.caseDetailsInfo?.lastName !== undefined
                ? `${props.caseDetailsInfo?.lastName
                    .charAt(0)
                    .toUpperCase()}${props.caseDetailsInfo?.lastName.slice(1)}`
                : "")
            }`
          : "-"
        : "-";

    return (
      <div
        className={`patient-card ${
          UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ||
          UIViewSwitch == globalConstants.CASE_UI_FOR_TRANSFERRED ||
          UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ||
          UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED
            ? "inc-height"
            : ""
        }`}
      >
        <div className="card-icon-section">
          <img className="card-icon" src={patient_details} alt="" />
        </div>
        <div className="card-Info">
          <div className="card-name-buttons">
            <text className="title-tag">Patient Details</text>
            <text className="status-tag">{renderStatus()}</text>
          </div>
          <div
            className={`info-parent ${
              showInsuranceDetailsCard ? "re-size-height" : ""
            }`}
          >
            <InfoTextBlock
              label="Name"
              value={patientName}
              style={{ maxWidth: "46%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Age"
              value={
                props.caseDetailsInfo?.age
                  ? `${props.caseDetailsInfo.age} yrs`
                  : "-"
              }
              style={{ maxWidth: "22%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Gender"
              value={gender}
              style={{ maxWidth: "22%" }}
            />
          </div>
          <div className="hz-seperator" />
          <div className="info-parent smaller">
            <InfoTextInline
              label="Number"
              value={
                props.caseDetailsInfo?.mobileNumber
                  ? `+91 ${props.caseDetailsInfo?.mobileNumber}`
                  : "-"
              }
              style={{ maxWidth: "35%" }}
            />
            <div className="vh-seperator" />
            <InfoTextInline
              label="Address"
              value={
                props.caseDetailsInfo?.patientAddressInfo?.address?.length > 2
                  ? props.caseDetailsInfo.patientAddressInfo.address
                  : "-"
              }
              style={{ width: "55%" }}
            />
          </div>
          <div className="hz-seperator" />
          <div
            className={`info-parent small ${
              showInsuranceDetailsCard ? "re-size-height" : ""
            }`}
          >
            <div className="info-2">
              <text className="dt-label">History: </text>
              <div className="read-more-value">
                <text className="dt-value ">
                  {props.caseDetailsInfo?.patientHistory?.length
                    ? props.caseDetailsInfo.patientHistory
                    : "-"}
                </text>
                {historyReadMore && (
                  <text
                    onClick={() =>
                      props.onViewMoreButtonClicked({
                        id: "history",
                        title: "Patient History",
                        data: props.caseDetailsInfo.patientHistory,
                      })
                    }
                    className="read-more"
                  >
                    view more
                  </text>
                )}
              </div>
            </div>
            <div className="vh-seperator" />
            <div className="info-2">
              <text className="dt-label">Documents: </text>
              <div className="read-more-value">
                {renderDocumentDetails(caseDocuments)}
                {docsReadMore && (
                  <text
                    onClick={() =>
                      props.onViewMoreButtonClicked({
                        id: "docs",
                        title: "Patient Documents",
                        data: caseDocuments,
                      })
                    }
                    className="read-more"
                  >
                    view more
                  </text>
                )}
              </div>
            </div>
          </div>
          {showInsuranceDetailsCard ? (
            <>
              <div className="hz-seperator" />
              <div className="insurance-details">
                <text className="dt-title">Insurance Details</text>
                <div className="dt-image-list">
                  <div className="list-view">
                    <div></div>
                    <text className="dt-text">
                      Company Name:
                      <span className="dt-value">
                        {" " + (props.insuranceInfo?.companyName ?? "-")}
                      </span>
                    </text>
                    <text className="dt-text">
                      Insurance Type:
                      <span className="dt-value">
                        {" " + (props.insuranceInfo?.insuranceType ?? "-")}
                      </span>
                    </text>
                    <text className="dt-text">
                      Insurance Name:
                      <span className="dt-value">
                        {" " + (props.insuranceInfo?.insuranceName ?? "-")}
                      </span>
                    </text>
                    <text className="dt-text">
                      Insurance Policy Number:
                      <span className="dt-value">
                        {" " + (props.insuranceInfo?.policyNumber ?? "-")}
                      </span>
                    </text>
                    <text className="dt-text">
                      Policy Start Date:
                      <text className="dt-value">
                        {props.insuranceInfo?.policyStartDate
                          ? " " +
                            moment(props.insuranceInfo.policyStartDate).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </text>
                    </text>
                    <text className="dt-text">
                      Policy End Date:
                      <text className="dt-value">
                        {props.insuranceInfo?.policyEndDate
                          ? " " +
                            moment(props.insuranceInfo.policyEndDate).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </text>
                    </text>
                    <text className="dt-text">
                      Relationship with Insured:
                      <text className="dt-value">
                        {" " +
                          (props.insuranceInfo?.patientToInsuredInfo
                            ?.relation ?? "-")}
                      </text>
                    </text>
                  </div>
                  <div className="images">
                    <div
                      className={`img_container_main ${
                        props.insuranceInfo?.insuranceCard ? "plain-border" : ""
                      }`}
                    >
                      {props.insuranceInfo?.insuranceCard ? (
                        <img
                          src={props.insuranceInfo.insuranceCard}
                          alt="image_preview"
                          className="img_preview"
                        />
                      ) : (
                        <text className="img-text">Insurance Card</text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const renderTechnicianAndDevice = () => {
    const techCommentReadMore =
      props.caseDetailsInfo?.technicianComments?.length > 150 ? true : false;

    const technicianInfo =
      props.caseDetailsInfo?.reportInfo?.technicianInfo ?? null;
    const technicianName =
      technicianInfo !== null && technicianInfo !== undefined
        ? `${
            (technicianInfo?.firstName !== null &&
            technicianInfo?.firstName !== undefined
              ? `${technicianInfo?.firstName
                  .charAt(0)
                  .toUpperCase()}${technicianInfo?.firstName.slice(1)}`
              : "") +
            " " +
            (technicianInfo?.lastName !== null &&
            technicianInfo?.lastName !== undefined
              ? `${technicianInfo?.lastName
                  .charAt(0)
                  .toUpperCase()}${technicianInfo?.lastName.slice(1)}`
              : "")
          }`
        : "-";
    let address =
      props.caseDetailsInfo?.deviceInfo?.centerDeviceInfo?.center?.addressInfo;
    const deviceLocation =
      address !== null && address !== undefined
        ? `${address?.addressLineOne ? address.addressLineOne + ", " : ""} ${
            address?.areaInfo?.name ? address.areaInfo.name + ", " : ""
          } ${address?.districtInfo?.name ? address.districtInfo.name : ""} `
        : "-";
    return (
      <div className="patient-card">
        <div className="card-icon-section">
          <img className="card-icon" src={technician_device} alt="" />
        </div>
        <div className="card-Info">
          <div className="card-name-buttons">
            <text className="title-tag">Technician & Device</text>
            <text className="status-tag">
              <ThemedButton
                title="View ECG"
                size={"small"}
                icon={<LuEye size={15} color="#fff" />}
                onClick={
                  props.onViewECGReportClick !== null
                    ? () => props.onViewECGReportClick()
                    : () => {}
                }
                styles="primary medium"
              />
            </text>
          </div>
          <div className="info-parent small">
            <InfoTextBlock
              label="Name"
              value={technicianName}
              style={{ maxWidth: "30%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Device ID"
              value={
                props.caseDetailsInfo?.reportInfo?.deviceInfo?.serialNo ?? "-"
              }
              style={{ maxWidth: "30%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="ECG Record ID"
              value={props.caseDetailsInfo?.reportInfo?.id ?? "-"}
              style={{ maxWidth: "30%" }}
            />
          </div>
          <div className="hz-seperator reduce-margin" />
          <div className="info-parent smaller">
            <InfoTextInline
              label="Device Location"
              value={deviceLocation}
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="hz-seperator reduce-margin" />
          <div className="info-parent small">
            <div className="info-1">
              <AiOutlineComment
                className="comment-section"
                size={16}
                color="#00B48A"
              />
              <div className="technician-comment">
                <text className="dt-label">Technician Comments: </text>
                <div className="read-more-value">
                  <text className="dt-value">
                    {props.caseDetailsInfo?.technicianComments ?? "-"}
                  </text>
                  {techCommentReadMore && (
                    <text
                      onClick={() =>
                        props.onViewMoreButtonClicked({
                          id: "technician comment",
                          title: "Technician Comments",
                          data:
                            props.caseDetailsInfo?.technicianComments ?? "-",
                        })
                      }
                      className="read-more"
                    >
                      view more
                    </text>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAmbulanceDetails = () => {
    let attendandComment = false;
    let doctorComment = false;
    let ambulanceDetails = null;
    let attendantName = "-";
    let ambulanceType = "-";
    let ambulanceName = "-";
    let attendantContactNumber = "-";
    let ambulanceRegistrationNumber = "-";
    let attendantComments = "-";
    let onboardDoctorComments = "-";
    let estimatedDropTime = null;
    let isCommingWithVehicle = false; // patient is comming with manually added vehicle
    let onboardDoctorName = "";
    let isDoctorOnborded = false;

    if (props.isTransferredCase > 0) {
      // console.log("props.trasnferCaseInfo", props.trasnferCaseInfo);

      const assignAmbulance = props.trasnferCaseInfo?.assignAmbulance
        ? true
        : false;
      isCommingWithVehicle = props.trasnferCaseInfo?.assignedVehicle
        ? true
        : false;

      attendandComment =
        props.trasnferCaseInfo?.rideInfo?.attendantComments?.length > 75
          ? true
          : false;
      doctorComment =
        props.trasnferCaseInfo?.rideInfo?.onboardDoctorComments?.length > 75
          ? true
          : false;
      ambulanceDetails = assignAmbulance
        ? props.trasnferCaseInfo?.assignAmbulance ?? null
        : props.trasnferCaseInfo?.assignedVehicle;

      isDoctorOnborded =
        props.trasnferCaseInfo?.assignAmbulance?.isDoctorOnboard ?? false;
      attendantName =
        ambulanceDetails !== null && ambulanceDetails !== undefined
          ? `${
              (ambulanceDetails?.attendantFirstName !== null &&
              ambulanceDetails?.attendantFirstName !== undefined
                ? `${ambulanceDetails?.attendantFirstName
                    .charAt(0)
                    .toUpperCase()}${ambulanceDetails?.attendantFirstName.slice(
                    1
                  )}`
                : "") +
              " " +
              (ambulanceDetails?.attendantLastName !== null &&
              ambulanceDetails?.attendantLastName !== undefined
                ? `${ambulanceDetails?.attendantLastName
                    .charAt(0)
                    .toUpperCase()}${ambulanceDetails?.attendantLastName.slice(
                    1
                  )}`
                : "")
            }`
          : "-";
      ambulanceName = ambulanceDetails?.ambulanceName;
      attendantContactNumber = ambulanceDetails?.attendantContactNumber
        ? `+91 ${ambulanceDetails.attendantContactNumber}`
        : "-";
      ambulanceRegistrationNumber = assignAmbulance
        ? ambulanceDetails?.ambulanceRegistrationNumber ?? "-"
        : ambulanceDetails?.vehicleRegistrationNumber ?? "-";

      attendantComments =
        props.trasnferCaseInfo?.rideInfo?.attendantComments?.length > 0
          ? props.trasnferCaseInfo.rideInfo.attendantComments
          : "-";

      onboardDoctorComments =
        props.trasnferCaseInfo?.rideInfo?.onboardDoctorComments?.length > 0
          ? props.trasnferCaseInfo.rideInfo.onboardDoctorComments
          : "-";

      estimatedDropTime = props.trasnferCaseInfo?.rideInfo?.estimatedDropTime
        ? moment(props.trasnferCaseInfo?.rideInfo?.startedAt)
            .add(props.trasnferCaseInfo?.rideInfo?.estimatedDropTime, "minutes")
            .format("hh:mm A")
        : "-";
      ambulanceType = assignAmbulance
        ? ambulanceDetails?.ambulanceType == 1
          ? "Cardiac"
          : ambulanceDetails?.ambulanceType == 2
          ? "ICU"
          : ambulanceDetails?.ambulanceType == 3
          ? "General"
          : "-"
        : ambulanceDetails?.vehicleType == 1
        ? "Ambulance"
        : ambulanceDetails?.vehicleType == 2
        ? "Other"
        : "-";

      let rideInfo = props.trasnferCaseInfo?.rideInfo;
      onboardDoctorName =
        rideInfo !== null && rideInfo !== undefined
          ? `${
              (rideInfo?.onboardDoctorFirstName !== null &&
              rideInfo?.onboardDoctorFirstName !== undefined
                ? `${rideInfo?.onboardDoctorFirstName
                    .charAt(0)
                    .toUpperCase()}${rideInfo?.onboardDoctorFirstName.slice(1)}`
                : "") +
              " " +
              (rideInfo?.onboardDoctorLastName !== null &&
              rideInfo?.onboardDoctorLastName !== undefined
                ? `${rideInfo?.onboardDoctorLastName
                    .charAt(0)
                    .toUpperCase()}${rideInfo?.onboardDoctorLastName.slice(1)}`
                : "")
            }`
          : "-";
    } //
    //
    else {
      const assignAmbulance = props.caseDetailsInfo?.assignAmbulance
        ? true
        : false;
      isCommingWithVehicle = props.caseDetailsInfo?.assignVehicle
        ? true
        : false;
      attendandComment =
        props.caseDetailsInfo?.rideInfo?.attendantComments?.length > 75
          ? true
          : false;
      doctorComment =
        props.caseDetailsInfo?.rideInfo?.onboardDoctorComments?.length > 75
          ? true
          : false;
      ambulanceDetails = assignAmbulance
        ? props.caseDetailsInfo?.assignAmbulance ?? null
        : props.caseDetailsInfo?.assignVehicle;

      isDoctorOnborded =
        props.caseDetailsInfo?.assignAmbulance?.isDoctorOnboard ?? false;
      attendantName =
        ambulanceDetails !== null && ambulanceDetails !== undefined
          ? `${
              (ambulanceDetails?.attendantFirstName !== null &&
              ambulanceDetails?.attendantFirstName !== undefined
                ? `${ambulanceDetails?.attendantFirstName
                    .charAt(0)
                    .toUpperCase()}${ambulanceDetails?.attendantFirstName.slice(
                    1
                  )}`
                : "") +
              " " +
              (ambulanceDetails?.attendantLastName !== null &&
              ambulanceDetails?.attendantLastName !== undefined
                ? `${ambulanceDetails?.attendantLastName
                    .charAt(0)
                    .toUpperCase()}${ambulanceDetails?.attendantLastName.slice(
                    1
                  )}`
                : "")
            }`
          : "-";
      ambulanceName = ambulanceDetails?.ambulanceName;
      attendantContactNumber = ambulanceDetails?.attendantContactNumber
        ? `+91 ${ambulanceDetails.attendantContactNumber}`
        : "-";
      ambulanceRegistrationNumber = assignAmbulance
        ? ambulanceDetails?.ambulanceRegistrationNumber ?? "-"
        : ambulanceDetails?.vehicleRegistrationNumber ?? "-";

      attendantComments =
        props.caseDetailsInfo?.rideInfo?.attendantComments?.length > 0
          ? props.caseDetailsInfo.rideInfo.attendantComments
          : "-";

      onboardDoctorComments =
        props.caseDetailsInfo?.rideInfo?.onboardDoctorComments?.length > 0
          ? props.caseDetailsInfo.rideInfo.onboardDoctorComments
          : "-";

      estimatedDropTime = props.caseDetailsInfo?.rideInfo?.estimatedDropTime
        ? moment(props.caseDetailsInfo?.rideInfo?.startedAt)
            .add(props.caseDetailsInfo?.rideInfo?.estimatedDropTime, "minutes")
            .format("hh:mm A")
        : "-";

      let rideInfo = props.caseDetailsInfo?.rideInfo;
      onboardDoctorName =
        rideInfo !== null && rideInfo !== undefined
          ? `${
              (rideInfo?.onboardDoctorFirstName !== null &&
              rideInfo?.onboardDoctorFirstName !== undefined
                ? `${rideInfo?.onboardDoctorFirstName
                    .charAt(0)
                    .toUpperCase()}${rideInfo?.onboardDoctorFirstName.slice(1)}`
                : "") +
              " " +
              (rideInfo?.onboardDoctorLastName !== null &&
              rideInfo?.onboardDoctorLastName !== undefined
                ? `${rideInfo?.onboardDoctorLastName
                    .charAt(0)
                    .toUpperCase()}${rideInfo?.onboardDoctorLastName.slice(1)}`
                : "")
            }`
          : "-";

      ambulanceType = assignAmbulance
        ? ambulanceDetails?.ambulanceType == 1
          ? "Cardiac"
          : ambulanceDetails?.ambulanceType == 2
          ? "ICU"
          : ambulanceDetails?.ambulanceType == 3
          ? "General"
          : "-"
        : ambulanceDetails?.vehicleType == 1
        ? "Ambulance"
        : ambulanceDetails?.vehicleType == 2
        ? "Other"
        : "-";
    }

    return (
      <div className="patient-card">
        <div className="card-icon-section">
          <img className="card-icon" src={ambulance_details} alt="" />
        </div>
        <div className="card-Info">
          <div className="card-name-buttons">
            <text className="title-tag">
              {isCommingWithVehicle ? `Manually Added Vehicle` : `Ambulance`}
            </text>
          </div>
          <div className="info-parent small">
            {!isCommingWithVehicle && (
              <>
                <InfoTextBlock
                  label="Name"
                  value={ambulanceName}
                  style={{ maxWidth: "36%" }}
                />

                <div className="vh-seperator" />
              </>
            )}
            <InfoTextBlock
              label="Type"
              value={ambulanceType}
              style={
                !isCommingWithVehicle
                  ? { maxWidth: "18%" }
                  : { maxWidth: "30%" }
              }
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Attendant"
              value={attendantName}
              style={{ maxWidth: "36%" }}
            />
          </div>
          <div className="hz-seperator reduce-margin" />
          <div className="info-parent smaller ">
            <InfoTextInline
              label="Attendant Number"
              value={attendantContactNumber}
              style={{ maxWidth: "45%", width: "auto" }}
            />
            <div className="vh-seperator" />
            <InfoTextInline
              label={
                isCommingWithVehicle ? "Vehicle Number" : "Ambulance Number"
              }
              value={ambulanceRegistrationNumber}
              style={{ maxWidth: "45%" }}
            />
          </div>
          <div className="hz-seperator reduce-margin" />
          <div className="info-parent small">
            {UIViewSwitch == globalConstants.CASE_UI_FOR_ARRIVED ? (
              <div className="info-5">
                <div className="attendant-dr-cmts">
                  <AiOutlineComment size={16} color="#00B48A" />
                  <div className="comment">
                    <text className="dt-label">Attendant Comments: </text>
                    <div className="read-more-value">
                      <text className="dt-value">{attendantComments}</text>
                      {attendandComment && (
                        <text
                          onClick={() =>
                            props.onViewMoreButtonClicked({
                              id: "attendant-comment",
                              title: "Attendant Comment",
                              data: attendantComments,
                            })
                          }
                          className="read-more"
                        >
                          view more
                        </text>
                      )}
                    </div>
                  </div>
                </div>
                {isDoctorOnborded && (
                  <div className="attendant-dr-cmts">
                    <AiOutlineComment size={16} color="#00B48A" />
                    <div className="comment">
                      <text className="dt-label">
                        {`Dr. ${onboardDoctorName} Comments:`}
                      </text>
                      <div className="read-more-value">
                        <text className="dt-value">
                          {onboardDoctorComments}
                        </text>
                        {doctorComment && (
                          <text
                            onClick={() =>
                              props.onViewMoreButtonClicked({
                                id: "doctor-comment",
                                title: "Doctor Comment",
                                data: onboardDoctorComments,
                              })
                            }
                            className="read-more"
                          >
                            view more
                          </text>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : UIViewSwitch ==
              globalConstants.CASE_UI_FOR_TRANSFERRED ? null : (
              <div className="info-4">
                {isCommingWithVehicle ? (
                  <text className="dt-label"> Tracking Unavailable</text>
                ) : (
                  <>
                    <text className="dt-label"> Estimated Arrival</text>
                    <text className="dt-value">
                      {estimatedDropTime !== null ? estimatedDropTime : "-"}
                    </text>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPatientVitals = () => {
    let inVitals = [];
    let outVitals = [];

    const vitalsArray = props.caseDetailsInfo?.caseVitals?.length
      ? props.caseDetailsInfo.caseVitals
      : [];

    if (transferDataArray?.length > 0) {
      let latestVital = vitalsArray.find(
        (item) => item.transferCaseId == props.transferId
      );
      let latestStage = parseInt(latestVital?.stage ?? 0);

      let preVitals = vitalsArray.find(
        (item) => item?.stage == latestStage - 1
      );
      inVitals = preVitals;
      outVitals = latestVital;
    } else {
      inVitals = vitalsArray[0];
    }

    return (
      <div className="patient-card inner-space ">
        <div className="card-icon-section">
          <img className="card-icon" src={patient_vitals} alt="" />
        </div>
        <div className="card-Info no-gap">
          <div className="card-name-buttons">
            <text className="title-tag">Patient Vitals</text>
          </div>
          <div className="info-parent large">
            <div className="info-3">
              <img className="data-img" src={patient_pulse} alt="" />
              <div className="data-label-value">
                <text>Pulse</text>
                <div>
                  {renderVitalAsPerStyles(
                    "Pulse",
                    inVitals?.pulse,
                    outVitals?.pulse
                  )}
                </div>
              </div>
            </div>
            <div className="vh-seperator t-m" />
            <div className="info-3">
              <img className="data-img" src={patient_rr} alt="" />
              <div className="data-label-value">
                <text>RR</text>
                <div>
                  {renderVitalAsPerStyles("Rr", inVitals?.rr, outVitals?.rr)}
                </div>
              </div>
            </div>
            <div className="vh-seperator t-m" />
            <div className="info-3">
              <img className="data-img" src={patient_bp} alt="" />
              <div className="data-label-value">
                <text>BP</text>
                <div>
                  {renderVitalAsPerStyles("Bp", inVitals?.bp, outVitals?.bp)}
                </div>
              </div>
            </div>
          </div>
          <div className="hz-seperator no-margin" />
          <div className="info-parent large">
            <div className="info-3 t-p">
              <img className="data-img" src={patient_spo2} alt="" />
              <div className="data-label-value">
                <text className="">SpO2</text>
                <div>
                  {renderVitalAsPerStyles(
                    "spO2",
                    inVitals?.spo2,
                    outVitals?.spo2
                  )}
                </div>
              </div>
            </div>
            <div className="vh-seperator b-m" />
            <div className="info-3 t-p">
              <img className="data-img" src={patient_weight} alt="" />
              <div className="data-label-value">
                <text>Weight</text>
                <div>
                  {renderVitalAsPerStyles(
                    "Weight",
                    inVitals?.weight,
                    outVitals?.weight
                  )}
                </div>
              </div>
            </div>
            <div className="vh-seperator b-m" />
            <div className="info-3 t-p">
              <img className="data-img" src={patient_bs} alt="" />
              <div className="data-label-value">
                <text>Blood Sugar</text>
                <div>
                  {renderVitalAsPerStyles(
                    "bloodSugar",
                    inVitals?.bloodSugar,
                    outVitals?.bloodSugar
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAdmissionDetails = () => {
    const NotesArray =
      props.caseDetailsInfo?.caseNotes?.length > 0
        ? props.caseDetailsInfo.caseNotes.filter((item) => {
            return item.userId == props.loginUserId;
          })
        : [];
    return (
      <div
        className={`patient-card ${
          UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ||
          UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED
            ? "height-auto"
            : ""
        }`}
      >
        <div className="card-icon-section">
          <img className="card-icon" src={admission_details} alt="" />
        </div>
        <div className="card-Info">
          <div className="card-name-buttons">
            <text className="title-tag">Admission Details</text>
          </div>
          <div className="info-parent smaller">
            <InfoTextBlock
              label="Doctor"
              value={doctorName}
              style={{ maxWidth: "45%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Bed"
              value={admissionDetails?.bedNumber ?? "-"}
              style={{ maxWidth: "20%" }}
            />
            <div className="vh-seperator" />
            <InfoTextBlock
              label="Admit Date"
              value={
                admissionDetails?.admittedAt
                  ? moment(admissionDetails.admittedAt).format("DD/MM/YY")
                  : "-"
              }
              style={{ maxWidth: "25%" }}
            />
          </div>
          <div className="hz-seperator" />
          <div className="text-notes">
            {NotesArray.length > 0
              ? NotesArray.map((item) => {
                  const date = moment(item.createdAt).format("DD-MM-YY");
                  const time = moment(item.createdAt).format("h:mm a");
                  const noteType =
                    item?.noteType == globalConstants.GENERIC_NOTE
                      ? "Notes"
                      : item?.noteType == globalConstants.ADMISSION_NOTE
                      ? "Admission Notes"
                      : item?.noteType == globalConstants.TRANSFER_NOTE
                      ? "Transfer Notes"
                      : item?.noteType == globalConstants.CLOSING_NOTE
                      ? "Closeing Notes"
                      : "";
                  return (
                    <div className="cmt-view">
                      <AiOutlineComment
                        className="cmt-icon"
                        size={16}
                        color="#00B48A"
                      />
                      <div className="comment">
                        <text className="dt-label">{noteType}: </text>
                        <text className="dt-value">
                          {item.note?.length ? item.note : "-"}
                        </text>
                        <text className="admission-cmt-date-time">
                          Admin | {date} | {time}
                        </text>
                      </div>
                    </div>
                  );
                })
              : null}
            {UIViewSwitch ==
            globalConstants.CASE_UI_FOR_CLOSED ? null : props.showAddNotesView ? (
              <div className="text-input-note">
                <label>Add Notes</label>
                <TextArea
                  value={props.genericNotes}
                  onChange={props.onChangeGenericNotes("genericNotes")}
                  row={2}
                  placeholder="Enter Your Notes"
                  error={props.errors["genericNotes"]}
                  smallPlaceholder={true}
                />
                <div className="action-buttons">
                  <ThemedButton
                    title="Cancel"
                    size={"small"}
                    onClick={() => {
                      props.onCloseAddNoteUI();
                    }}
                    styles="grey-outline small"
                  />
                  <ThemedButton
                    title="Save"
                    size={"small"}
                    onClick={() => {
                      props.onAddNoteConfirmClick();
                    }}
                    styles="primary small"
                  />
                </div>
              </div>
            ) : (
              <div className="add-note-button">
                <ThemedButton
                  title="Add Note"
                  size={"small"}
                  icon={<FaPlus size={10} color="#fff" />}
                  onClick={() => {
                    props.onAddNoteClick();
                  }}
                  styles="primary small"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderViewInsuranceCardImageContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.addPatientFields.insuranceCardImage}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  const renderViewManuallyAddedImageContents = (isInsuranceImage) => {
    return (
      <div className="img_container_main">
        <img
          src={props.transferPatientFields.vehicleImage}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  return (
    <div className="stemi-case-details-container">
      <div className="card-header-wrapper">
        <div className="title">
          <ThemedButton
            icon={<FaAngleLeft size={15} color="rgba(0,0,0,0.45)" />}
            onClick={() => {
              props.navigateBack();
            }}
            styles="link "
          />
          Case ID {props.caseDetailsInfo?.id}
        </div>

        <div className="search-and-buttons">
          {UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ? (
            <>
              <ThemedButton
                title="Transfer Details"
                size={"large"}
                icon={<LuEye size={15} color="rgba(0, 0, 0, 0.85)" />}
                onClick={() => {
                  props.onTransferDetailsButtonClicked();
                }}
                styles="grey-outline-white-bg smaller"
                // styles="primary smaller"
              />
              <ThemedButton
                title="Admit Patient"
                size={"large"}
                icon={<FaPlus size={12} color="#fff" />}
                onClick={() => {
                  props.onAddPatientClick();
                }}
                styles="primary smaller"
              />
            </>
          ) : UIViewSwitch == globalConstants.CASE_UI_FOR_TRANSFERRED ||
            UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED ? (
            <ThemedButton
              title={
                UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED
                  ? "Closing Notes"
                  : "Transfer Details"
              }
              size={"large"}
              icon={<LuEye size={15} color="#fff" />}
              onClick={() => {
                UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED
                  ? props.onClosingNotesButtonClicked()
                  : props.onTransferDetailsButtonClicked();
              }}
              styles="primary smaller"
            />
          ) : UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ? (
            <>
              {/* <ThemedButton
                title="Admission Details"
                size={"large"}
                icon={<LuEye size={16} color="#fff" />}
                onClick={() => {
                  props.onViewAdmissionDetailsClick();
                }}
                styles="primary smaller"
              /> */}
              <ThemedButton
                title="Close Case"
                size={"large"}
                icon={
                  <AiOutlineFileExcel size={16} color="rgba(0, 0, 0, 0.85)" />
                }
                onClick={() => {
                  props.onCloseCaseClick();
                }}
                style={{ backgroundColor: "white" }}
                styles="grey-outline-white-bg smaller"
              />
              <ThemedButton
                title="Transfer Case"
                size={"large"}
                icon={<RiLogoutBoxRLine size={16} color="#fff" />}
                onClick={() => {
                  props.onTransferCaseClick();
                }}
                styles="primary smaller"
              />
            </>
          ) : (
            <div className="button-text">
              <ThemedButton
                title="Admit Patient"
                size={"large"}
                icon={<FaPlus size={12} color="#fff" />}
                onClick={() => {
                  props.onAddPatientClick();
                }}
                styles="primary smaller"
              />
            </div>
          )}
        </div>
      </div>
      <CardView extraStyle="card-container" bodyStyle={{ padding: "0px 8px" }}>
        <div className="case-details-card-container">
          <div className="case-details-cards">
            {UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT ||
            UIViewSwitch == globalConstants.CASE_UI_FOR_ARRIVED ? (
              <>
                {renderPatientDetails()}
                {renderTechnicianAndDevice()}
              </>
            ) : UIViewSwitch ==
              globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ? (
              renderPatientDetails()
            ) : UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ||
              UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED ||
              UIViewSwitch == globalConstants.CASE_UI_FOR_TRANSFERRED ? (
              renderPatientDetails()
            ) : null}
          </div>
          <div className="case-details-cards">
            {UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT ||
            UIViewSwitch == globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER ||
            UIViewSwitch == globalConstants.CASE_UI_FOR_TRANSFERRED ||
            UIViewSwitch == globalConstants.CASE_UI_FOR_ARRIVED ? (
              <>
                {renderPatientVitals()}
                {renderAmbulanceDetails()}
              </>
            ) : UIViewSwitch == globalConstants.CASE_UI_FOR_ADMIT ||
              UIViewSwitch == globalConstants.CASE_UI_FOR_CLOSED ? (
              renderAdmissionDetails()
            ) : null}
          </div>
        </div>
      </CardView>

      <PopupDialog
        show={props.showAddPatientPopup}
        title={"Admit Patient"}
        showCloseIcon={true}
        renderBodyContent={renderAddPatientPopupContents}
        confirmButtonTitle={props.activeStep == 0 ? "Next" : "Admit Patient"}
        cancelButtonTitle={"Cancel"}
        hideCancelButton={props.activeStep == 1}
        onclickConfirm={
          props.activeStep == 0
            ? props.onAddPatientInsuranceConfirmClick
            : props.onAddPatientConfirmClick
        }
        extraButtonStyle="grey-outline"
        extraButtonTitle="Previous"
        showExtraButton={props.activeStep == 1 ? true : false}
        onClickExtra={props.handleBack}
        onclickCancel={props.onCloseAddPatientPopup}
        // popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />
      <PopupDialog
        show={props.showTransferCasePopup}
        title={"Transfer Patient"}
        showCloseIcon={true}
        renderBodyContent={renderTransferPatientPopupContents}
        confirmButtonTitle={props.activeStep != 3 ? "Next" : "Transfer Patient"}
        cancelButtonTitle={"Cancel"}
        hideCancelButton={props.activeStep != 0}
        onclickConfirm={
          props.activeStep == 0
            ? props.onPatientTransferUpdateVitals
            : props.activeStep == 1
            ? props.onPatientTransferAssignHospital
            : props.activeStep == 2 && props.showAddVehicleManuallyForm
            ? props.onPatientTransferAddVehicle
            : props.activeStep == 2
            ? props.onPatientTransferAssignAmbulance
            : props.activeStep == 3
            ? props.onPatientTransferConfirmDetails
            : () => {}
        }
        extraButtonStyle="grey-outline"
        extraButtonTitle="Previous"
        showExtraButton={props.activeStep != 0 ? true : false}
        onClickExtra={props.handleBack}
        onclickCancel={props.onCloseTransferCasePopup}
        {...(((props.showAddVehicleManuallyForm && props.activeStep == 2) ||
          props.activeStep == 3) && {
          extraStyle: { maxHeight: "60vh", overflowY: "auto" },
        })}
      />
      <PopupDialog
        show={props.showCloseCasePopup}
        title={"Close Case"}
        showCloseIcon={true}
        renderBodyContent={renderCloseCasePopupContents}
        confirmButtonTitle={"Close Case"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onCloseCaseConfirmClick}
        onclickCancel={props.onCloseCloseCasePopup}
        // popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showViewMorePopup}
        title={props.viewMoreData?.title ?? "View More"}
        showCloseIcon={true}
        renderBodyContent={renderViewMorePopupContents}
        hideConfirmButton={true}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.hideViewMorePopup}
        popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showTransferDetailsPopup}
        title={"Transfer Details"}
        showCloseIcon={true}
        renderBodyContent={renderTransferDetailsPopupContents}
        hideConfirmButton={true}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.hideTransferDetailsPopup}
        popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showClosingNotesPopup}
        title={"Closing Notes"}
        showCloseIcon={true}
        renderBodyContent={renderClosingNotesPopupContents}
        hideConfirmButton={true}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.hideClosingNotesPopup}
        popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showAdmissionDetailsPopup}
        title={"Transfer Details"}
        showCloseIcon={true}
        renderBodyContent={renderAdmissionDetailsViewContents}
        hideConfirmButton={true}
        cancelButtonTitle={"Cancel"}
        onclickCancel={props.onCloseAdmissionDetailsPopup}
        popupSize="md"
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showInsuranceCardImagePopup}
        title={"View Insurance Card Image"}
        showCloseIcon={true}
        renderBodyContent={renderViewInsuranceCardImageContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewImagePopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showAddVehicleImagePopup}
        title={"View Vehicle Image"}
        showCloseIcon={true}
        renderBodyContent={renderViewManuallyAddedImageContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewImagePopup}
        popupSize={"md"}
      />
    </div>
  );
};

export default CaseDetailsView;
