import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./errorLogsReducer";

export function getErrorLogsListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_PENDING,
      payload: {
        getErrorLogsListWithFilterPending: false,
        getErrorLogsListWithFilterSuccess: false,
        getErrorLogsListWithFilterError: null,
      },
    });
    dispatch(showLoader());
    Api.post(
      `device/errorLog/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log("error Log list : ", response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? []);
        }

        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getErrorLogsListWithFilterPending: false,
              getErrorLogsListWithFilterSuccess: true,
              getErrorLogsListWithFilterError: null,
              errorlogList: response.data.rows,
              filteredErrorLogsList: response?.data?.rows ?? [],
              filteredErrorLogsCount: response?.data?.count ?? 0,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getErrorLogsListWithFilterPending: false,
              getErrorLogsListWithFilterSuccess: true,
              getErrorLogsListWithFilterError: null,
              filteredErrorLogsList: response?.data?.rows ?? [],
              filteredErrorLogsCount: response?.data?.count ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_ERROR_LOGS_LIST_WITH_FILTER_ERROR,
          payload: {
            getErrorLogsListWithFilterPending: false,
            getErrorLogsListWithFilterSuccess: true,
            getErrorLogsListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function updateErrorLogs(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_ERROR_LOG_PENDING,
      payload: {
        updateErrorLogPending: false,
        updateErrorLogSuccess: false,
        updateErrorLogError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`device/update/errorLog/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.UPDATE_ERROR_LOG_SUCCESS,
          payload: {
            updateErrorLogPending: false,
            updateErrorLogSuccess: true,
            updateErrorLogError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.UPDATE_ERROR_LOG_ERROR,
          payload: {
            updateErrorLogPending: false,
            updateErrorLogSuccess: true,
            updateErrorLogError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function toggleDataForErrorLogs(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_PENDING,
      payload: {
        getToggleDataForErrorLogsPending: false,
        getToggleDataForErrorLogsSuccess: false,
        getToggleDataForErrorLogsError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`device/errorLog/status/list`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? []);
        }
        dispatch({
          type: actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_SUCCESS,
          payload: {
            getToggleDataForErrorLogsPending: false,
            getToggleDataForErrorLogsSuccess: true,
            getToggleDataForErrorLogsError: null,
            toggleDataforErrorLogs: response?.data ?? [],
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_TOGGLE_DATA_FOR_ERROR_LOGS_ERROR,
          payload: {
            getToggleDataForErrorLogsPending: false,
            getToggleDataForErrorLogsSuccess: true,
            getToggleDataForErrorLogsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function viewErrorLogs(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.VIEW_ERROR_LOG_PENDING,
      payload: {
        viewErrorLogPending: false,
        viewErrorLogSuccess: false,
        viewErrorLogError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`device/errorLog/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data ?? {});
        }

        if (!response?.data) {
          dispatch({
            type: actionTypes.VIEW_ERROR_LOG_SUCCESS,
            payload: {
              viewErrorLogPending: false,
              viewErrorLogSuccess: true,
              viewErrorLogError: null,
              viewErrorLogData: response?.data ?? {},
            },
          });
        } else {
          dispatch({
            type: actionTypes.VIEW_ERROR_LOG_SUCCESS,
            payload: {
              viewErrorLogPending: false,
              viewErrorLogSuccess: true,
              viewErrorLogError: null,
              viewErrorLogData: response?.data ?? {},
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.VIEW_ERROR_LOG_ERROR,
          payload: {
            viewErrorLogPending: false,
            viewErrorLogSuccess: true,
            viewErrorLogError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
