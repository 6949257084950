export const actionTypes = {
  GET_CASE_LIST_WITH_FILTER_PENDING: "GET_CASE_LIST_WITH_FILTER_PENDING",
  GET_CASE_LIST_WITH_FILTER_SUCCESS: "GET_CASE_LIST_WITH_FILTER_SUCCESS",
  GET_CASE_LIST_WITH_FILTER_ERROR: "GET_CASE_LIST_WITH_FILTER_ERROR",

  GET_CASE_DETAILS_BY_ID_PENDING: "GET_CASE_DETAILS_BY_ID_PENDING",
  GET_CASE_DETAILS_BY_ID_SUCCESS: "GET_CASE_DETAILS_BY_ID_SUCCESS",
  GET_CASE_DETAILS_BY_ID_ERROR: "GET_CASE_DETAILS_BY_ID_ERROR",

  DELETE_CASES_IN_BULK_PENDING: "DELETE_CASES_IN_BULK_PENDING",
  DELETE_CASES_IN_BULK_SUCCESS: "DELETE_CASES_IN_BULK_SUCCESS",
  DELETE_CASES_IN_BULK_ERROR: "DELETE_CASES_IN_BULK_ERROR",

  CLOSE_PATIENT_CASE_PENDING: "CLOSE_PATIENT_CASE_PENDING",
  CLOSE_PATIENT_CASE_SUCCESS: "CLOSE_PATIENT_CASE_SUCCESS",
  CLOSE_PATIENT_CASE_ERROR: "CLOSE_PATIENT_CASE_ERROR",

  REJECT_PATIENT_CASE_PENDING: "REJECT_PATIENT_CASE_PENDING",
  REJECT_PATIENT_CASE_SUCCESS: "REJECT_PATIENT_CASE_SUCCESS",
  REJECT_PATIENT_CASE_ERROR: "REJECT_PATIENT_CASE_ERROR",

  ADD_CASE_NOTE_PENDING: "ADD_CASE_NOTE_PENDING",
  ADD_CASE_NOTE_SUCCESS: "ADD_CASE_NOTE_SUCCESS",
  ADD_CASE_NOTE_ERROR: "ADD_CASE_NOTE_ERROR",

  UPLOAD_CASE_DOCUMENTS_PENDING: "UPLOAD_CASE_DOCUMENTS_PENDING",
  UPLOAD_CASE_DOCUMENTS_SUCCESS: "UPLOAD_CASE_DOCUMENTS_SUCCESS",
  UPLOAD_CASE_DOCUMENTS_ERROR: "UPLOAD_CASE_DOCUMENTS_ERROR",

  ACCEPT_CASE_BY_HOSPITAL_PENDING: "ACCEPT_CASE_BY_HOSPITAL_PENDING",
  ACCEPT_CASE_BY_HOSPITAL_SUCCESS: "ACCEPT_CASE_BY_HOSPITAL_SUCCESS",
  ACCEPT_CASE_BY_HOSPITAL_ERROR: "ACCEPT_CASE_BY_HOSPITAL_ERROR",

  GET_TRANSFER_DETAILS_BY_ID_PENDING: "GET_TRANSFER_DETAILS_BY_ID_PENDING",
  GET_TRANSFER_DETAILS_BY_ID_SUCCESS: "GET_TRANSFER_DETAILS_BY_ID_SUCCESS",
  GET_TRANSFER_DETAILS_BY_ID_ERROR: "GET_TRANSFER_DETAILS_BY_ID_ERROR",

  SET_SELECTED_CASE_ID: "SET_SELECTED_CASE_ID",
  RESET_SELECTED_CASE_ID: "RESET_SELECTED_CASE_ID",
};

const initialState = {
  getCaseListWithFilterPending: false,
  getCaseListWithFilterSuccess: false,
  getCaseListWithFilterError: null,

  getCaseDetailsByIdPending: false,
  getCaseDetailsByIdSuccess: false,
  getCaseDetailsByIdError: null,

  deleteCasesInBulkPending: false,
  deleteCasesInBulkSuccess: false,
  deleteCasesInBulkError: null,

  closePatientCasePending: false,
  closePatientCaseSuccess: false,
  closePatientCaseError: null,

  rejectPatientCasePending: false,
  rejectPatientCaseSuccess: false,
  rejectPatientCaseError: null,

  addCaseNotePending: false,
  addCaseNoteSuccess: false,
  addCaseNoteError: null,

  uploadCaseDocumentsPending: false,
  uploadCaseDocumentsSuccess: false,
  uploadCaseDocumentsError: null,

  acceptCaseByHospitalPending: false,
  acceptCaseByHospitalSucces: false,
  acceptCaseByHospitalError: null,

  getTransferDetailsByIdPending: false,
  getTransferDetailsByIdSuccess: false,
  getTransferDetailsByIdError: null,

  CaseList: [],
  filteredCaseList: [],
  filteredCaseCount: 0,

  selectedCaseId: null, // Selected case id while navigating to CaseDetails screen
};

export default function stemiCase(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_CASE_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_CASE_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_CASE_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_CASE_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_CASE_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_CASE_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.CLOSE_PATIENT_CASE_PENDING:
    case actionTypes.CLOSE_PATIENT_CASE_SUCCESS:
    case actionTypes.CLOSE_PATIENT_CASE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REJECT_PATIENT_CASE_PENDING:
    case actionTypes.REJECT_PATIENT_CASE_SUCCESS:
    case actionTypes.REJECT_PATIENT_CASE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_CASE_NOTE_PENDING:
    case actionTypes.ADD_CASE_NOTE_SUCCESS:
    case actionTypes.ADD_CASE_NOTE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.UPLOAD_CASE_DOCUMENTS_PENDING:
    case actionTypes.UPLOAD_CASE_DOCUMENTS_SUCCESS:
    case actionTypes.UPLOAD_CASE_DOCUMENTS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ACCEPT_CASE_BY_HOSPITAL_PENDING:
    case actionTypes.ACCEPT_CASE_BY_HOSPITAL_SUCCESS:
    case actionTypes.ACCEPT_CASE_BY_HOSPITAL_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_TRANSFER_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_TRANSFER_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_TRANSFER_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_CASE_ID:
    case actionTypes.RESET_SELECTED_CASE_ID:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
