import { roles } from "../../constants/roles";
import { getState } from "../configure/configureStore";
import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./caseReducer";

export function getCaseListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = "",
  order = ""
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CASE_LIST_WITH_FILTER_PENDING,
      payload: {
        getCaseListWithFilterPending: true,
        getCaseListWithFilterSuccess: false,
        getCaseListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `stemiCase/list/forHospitals?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(
            response?.data?.rows ?? [],
            response?.data?.count ?? 0,
            response?.data?.totalPages ?? 0
          );
        }
        dispatch({
          type: actionTypes.GET_CASE_LIST_WITH_FILTER_SUCCESS,
          payload: {
            getCaseListWithFilterPending: false,
            getCaseListWithFilterSuccess: true,
            getCaseListWithFilterError: null,
            filteredCaseList: response?.data?.rows ?? [],
            filteredCaseCount: response?.data?.count ?? 0,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([], 0, 0);
        }

        dispatch({
          type: actionTypes.GET_CASE_LIST_WITH_FILTER_ERROR,
          payload: {
            getCaseListWithFilterPending: false,
            getCaseListWithFilterSuccess: true,
            getCaseListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getCaseDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CASE_DETAILS_BY_ID_PENDING,
      payload: {
        getCaseDetailsByIdPending: false,
        getCaseDetailsByIdSuccess: false,
        getCaseDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`stemiCase/${id}`)
      .then((response) => {
        console.log("CASE BY ID ::: ", response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_CASE_DETAILS_BY_ID_SUCCESS,
          payload: {
            getCaseDetailsByIdPending: false,
            getCaseDetailsByIdSuccess: true,
            getCaseDetailsByIdError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.GET_CASE_DETAILS_BY_ID_ERROR,
          payload: {
            getCaseDetailsByIdPending: false,
            getCaseDetailsByIdSuccess: true,
            getCaseDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function acceptCase(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ACCEPT_CASE_BY_HOSPITAL_PENDING,
      payload: {
        acceptCaseByHospitalPending: true,
        acceptCaseByHospitalSucces: false,
        acceptCaseByHospitalError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/accept/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ACCEPT_CASE_BY_HOSPITAL_SUCCESS,
          payload: {
            acceptCaseByHospitalPending: false,
            acceptCaseByHospitalSucces: true,
            acceptCaseByHospitalError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ACCEPT_CASE_BY_HOSPITAL_ERROR,
          payload: {
            acceptCaseByHospitalPending: false,
            acceptCaseByHospitalSucces: true,
            acceptCaseByHospitalError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// =--------------------- close case -----------------------

export function closePatientCase(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLOSE_PATIENT_CASE_PENDING,
      payload: {
        closePatientCasePending: true,
        closePatientCaseSuccess: false,
        closePatientCaseError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/close/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(true);
        }

        dispatch({
          type: actionTypes.CLOSE_PATIENT_CASE_SUCCESS,
          payload: {
            closePatientCasePending: false,
            closePatientCaseSuccess: true,
            closePatientCaseError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(false);
        }

        dispatch({
          type: actionTypes.CLOSE_PATIENT_CASE_ERROR,
          payload: {
            closePatientCasePending: false,
            closePatientCaseSuccess: true,
            closePatientCaseError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// ---------------------- reject case ---------------

export function rejectPatientCase(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REJECT_PATIENT_CASE_PENDING,
      payload: {
        rejectPatientCasePending: true,
        rejectPatientCaseSuccess: false,
        rejectPatientCaseError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/reject/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REJECT_PATIENT_CASE_SUCCESS,
          payload: {
            rejectPatientCasePending: false,
            rejectPatientCaseSuccess: true,
            rejectPatientCaseError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REJECT_PATIENT_CASE_ERROR,
          payload: {
            rejectPatientCasePending: false,
            rejectPatientCaseSuccess: true,
            rejectPatientCaseError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//----------------------- add case note ----------------

export function addCaseNote(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_CASE_NOTE_PENDING,
      payload: {
        addCaseNotePending: true,
        addCaseNoteSuccess: false,
        addCaseNoteError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`stemiCase/add/note/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.ADD_CASE_NOTE_SUCCESS,
          payload: {
            addCaseNotePending: false,
            addCaseNoteSuccess: true,
            addCaseNoteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.ADD_CASE_NOTE_ERROR,
          payload: {
            addCaseNotePending: false,
            addCaseNoteSuccess: true,
            addCaseNoteError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//-------------------- upload case documents ----------------

export function uploadCaseDocuments(caseId, stage = null, data, callback) {
  return (dispatch) => {
    var formdata = new FormData();
    formdata.append("file", data);
    if (stage) {
      formdata.append("stage", stage);
    }
    dispatch({
      type: actionTypes.UPLOAD_CASE_DOCUMENTS_PENDING,
      payload: {
        uploadCaseDocumentsPending: true,
        uploadCaseDocumentsSuccess: false,
        uploadCaseDocumentsError: null,
      },
    });
    dispatch(showLoader());

    Api.uploadFile(`stemiCase/upload/document/${caseId}`, null, formdata)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response?.data);
        }

        dispatch({
          type: actionTypes.UPLOAD_CASE_DOCUMENTS_SUCCESS,
          payload: {
            uploadCaseDocumentsPending: false,
            uploadCaseDocumentsSuccess: true,
            uploadCaseDocumentsError: null,
          },
        });
        // dispatch(
        //   showAlert({
        //     isOpen: true,
        //     title: "Success",
        //     type: "Success",
        //     msg: response.message,
        //   })
        // );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.UPLOAD_CASE_DOCUMENTS_ERROR,
          payload: {
            uploadCaseDocumentsPending: false,
            uploadCaseDocumentsSuccess: true,
            uploadCaseDocumentsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// ---------------------- transfer details -----------------

export function getTransferredDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRANSFER_DETAILS_BY_ID_PENDING,
      payload: {
        getTransferDetailsByIdPending: true,
        getTransferDetailsByIdSuccess: false,
        getTransferDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`stemiCase/transferred/${id}`)
      .then((response) => {
        console.log("TRANSFER BY ID ::: ", response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response?.data ?? null);
        }

        dispatch({
          type: actionTypes.GET_TRANSFER_DETAILS_BY_ID_SUCCESS,
          payload: {
            getTransferDetailsByIdPending: false,
            getTransferDetailsByIdSuccess: true,
            getTransferDetailsByIdError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.GET_TRANSFER_DETAILS_BY_ID_ERROR,
          payload: {
            getTransferDetailsByIdPending: false,
            getTransferDetailsByIdSuccess: true,
            getTransferDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

// -------------  Case Clicked from list screen ----------------

export function setSelectedCaseId(caseId) {
  // console.log("setSelectedCaseId ----->", caseId);

  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_CASE_ID,
      payload: {
        selectedCaseId: caseId,
      },
    });
  };
}

export function getSelectedCaseId() {
  return getState().stemiCase.selectedCaseId;
}

export function resetSelectedCaseId() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SELECTED_CASE_ID,
      payload: {
        selectedCaseId: null,
      },
    });
  };
}
