import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";
import { history } from "../../../../../store/configure/configureStore";
import {
  createMQTTConnection,
  subscribeToTopic,
} from "../../../../../service/mqttApi";
import {
  getDashboardData,
  getTopPerformers,
} from "../../../../../store/dashboard/dashboardActions";
// import {
//   getDeviceListWithFilter,
//   checkInDevice,
// } from "../../../../../store/device/deviceActions";
import { getAssignedDeviceOfTechnician } from "../../../../../store/technician/technicianActions";
import { getReportListWithFilter } from "../../../../../store/report/reportActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import TechnicianView from "./TechnicianView";
import moment from "moment";

export class TechnicianDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",

      // ---- Statistics Card -------------

      totalReports: 0,
      pendingReports: 0,
      // showDeviceCheckInPopup: true,
      // isCheckedInOnDevice: false,
      // selectedDevice: null,
      serialNo: this.props.assignedDevice,
      graphDataSet: [],

      // ----- ECG Reports Bar Chart --------

      ecgReportsFilters: [
        { value: "year", label: "This Year" },
        { value: "month", label: "This Month" },
      ],
      ecgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },
      monthsBarChartData: [],
      yearsBarChartDat: [],
      ecgBarChartData: [],
      areaChartLabel: "",
    };

    this.populateTechnicianData = this.populateTechnicianData.bind(this);

    // this.onCheckInButtonClick = this.onCheckInButtonClick.bind(this);
    // this.onDeviceSelected = this.onDeviceSelected.bind(this);
    // this.onDeviceCheckInCofirmClick =
    //   this.onDeviceCheckInCofirmClick.bind(this);
    // this.onDeviceCheckInCancelClick =
    //   this.onDeviceCheckInCancelClick.bind(this);

    this.onReportDownloadButtonClick =
      this.onReportDownloadButtonClick.bind(this);
    this.onEcgReportsFilterChange = this.onEcgReportsFilterChange.bind(this);
  }

  componentDidMount() {
    // Connect to MQTT
    createMQTTConnection(() => {
      console.log("TECHNICIAN DASHBOARD : MQTT Connected!!!");
    });

    // Subscribe to MQTT topic for updating dashboard data, when any updates are made on backend
    var technicianId = this.props.loginUser?.userProfile?.id ?? "";
    subscribeToTopic(
      `device/reportRequest/reportData/technician:${technicianId}`,
      (msg) => {
        console.log("MQTT data recieved!");
        this.props.getDashboardData();
        setTimeout(() => {
          this.populateTechnicianData(this.props.dashboardData);
        }, 1500);
      }
    );

    // Set role, info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
    });

    // For Technician user : Fetch devices list
    // if (loginUserRole && loginUserRole === roles.technician) {
    //   this.props.getDeviceListWithFilter({}, null, 1, 100);

    this.props.getAssignedDeviceOfTechnician((serialNo) => {
      console.log("Technician assignedDevice ::: ", serialNo);
      this.setState({ serialNo: serialNo });
    });

    //   this.setState({
    //     isCheckedInOnDevice:
    //       this.props.selectedCheckInDevice === null ? false : true,
    //     showDeviceCheckInPopup:
    //       this.props.selectedCheckInDevice === null ? true : false,
    //     serialNo:
    //       this.props.selectedCheckInDevice === null
    //         ? null
    //         : this.props.selectedCheckInDevice.serialNo,
    //   });
    // }

    // Fetch Dashboard data for all users...
    if (loginUserRole) {
      this.props.showLoader();
      this.props.getDashboardData();

      setTimeout(() => {
        this.props.hideLoader();
        this.populateTechnicianData(this.props.dashboardData);
      }, 1500);
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.lastUpdatedTimestamp !== this.props.lastUpdatedTimestamp) {
      this.populateTechnicianData(newProps.dashboardData);
    }

    console.log("newProps.assignedDevice  ::: ", newProps.assignedDevice);
    this.setState({ serialNo: newProps.assignedDevice });

    // this.setState({
    //   isCheckedInOnDevice:
    //     newProps.selectedCheckInDevice === null ? false : true,
    //   showDeviceCheckInPopup:
    //     this.props.selectedCheckInDevice === null ? true : false,
    //   serialNo:
    //     this.props.selectedCheckInDevice === null
    //       ? null
    //       : this.props.selectedCheckInDevice.serialNo,
    // });
  }

  populateTechnicianData = (data) => {
    var dashboardData = data; //this.props.dashboardData; // Redux data
    console.log("DASHBOARD DATA Technician : ", JSON.stringify(dashboardData));

    // -------------- Reports Bar Chart calculation   ---------------

    // Logic to identify current month, and no. of days in it
    const allDayWise = dashboardData?.reports?.day_wise;
    console.log("ALL DAYWISE ----->", JSON.stringify(allDayWise));

    // First key is to identify month. so that we can extract no. of days to show in graph.
    var firstKey = "";
    if (allDayWise && Object.keys(allDayWise).length > 0) {
      firstKey = Object.keys(allDayWise)[0];
    } else {
      firstKey = moment().format("DD-MM-YYYY");
    }
    console.log("firstKey", firstKey);

    // Extract day, month and year from this key
    const [day, month, year] = firstKey
      ? firstKey.split("-").map(Number)
      : [1, 1, 2023];

    const firstDate = new Date(year, month - 1, day);
    const lastDay = new Date(year, month, 0).getDate();
    console.log("firstDate", firstDate);
    console.log("lastDay", lastDay);

    //------------------------------------------

    // Now we need a array of all days in month in format of {day, report_count}
    const fullDaysInMonth = [];

    // We also need to show totalReports generated today, we will calculate it simultaneously
    var currentDay = moment().format("DD");
    currentDay = `${String(parseInt(currentDay))}-${month}-${year}`;

    for (let i = 1; i <= lastDay; i++) {
      const formattedDay = `${i}`;
      const formattedMonth = `${month}`;
      const date = `${formattedDay}-${formattedMonth}-${year}`;
      fullDaysInMonth.push(date);
    }
    console.log("fullDaysInMonth", JSON.stringify(fullDaysInMonth));

    // use fullDaysInMonth array to create a new array for reports chart
    var monthlyBarChartData = [];

    fullDaysInMonth.map((day) => {
      // A day is like : "1-11-2023", "10-11-2023", etc.
      // For label in bar chart, we don't want "1-11-2023", "10-11-2023", instead we just want "1", "2", "10", etc.
      var dayLabel = day.substring(0, 2);
      if (dayLabel.charAt(1) === "-") {
        dayLabel = dayLabel.substring(0, 1);
      }

      monthlyBarChartData.push({
        name: dayLabel,
        hoverName: day,
        Value: parseInt(dashboardData?.reports?.day_wise?.[day] ?? 0),
      });
    });

    console.log("MONTH'S bar chart data", JSON.stringify(monthlyBarChartData));

    //------------------------------------------

    const allMonthWise = dashboardData?.reports?.month_wise;
    console.log("ALL MONTHWISE ----->", JSON.stringify(allMonthWise));

    var fullMonthsInYear = [];
    for (let i = 1; i <= 12; i++) {
      const formattedMonth = `${i}`;
      const monthKey = `${formattedMonth}-${year}`;
      fullMonthsInYear.push(monthKey);
    }
    console.log("fullMonthsInYear", JSON.stringify(fullMonthsInYear));

    var yearlyBarChartData = [];

    if (allMonthWise && Object.keys(allMonthWise).length > 0) {
      fullMonthsInYear.map((monthKey) => {
        // A month is like : "9-2023", "10-2023", etc.
        yearlyBarChartData.push({
          name: moment(monthKey, "MM-YYYY").format("MMM YY"),
          hoverName: moment(monthKey, "MM-YYYY").format("MMM YYYY"),
          Value: parseInt(
            allMonthWise.hasOwnProperty(monthKey) ? allMonthWise[monthKey] : 0
          ),
        });
      });
    }

    console.log("YEAR'S bar chart data", JSON.stringify(yearlyBarChartData));

    //------------------------------------------

    // We need to show totalReports and pending reports count

    var totalReports = parseInt(dashboardData?.reports?.today ?? 0);
    var pendingReports =
      parseInt(dashboardData?.reports?.arrived ?? 0) +
      parseInt(dashboardData?.reports?.assigned ?? 0);

    // -------------- Update state variables for all cards data  ---------------

    this.setState({
      // Statistics Card
      totalReports: totalReports,
      pendingReports: pendingReports,

      // ECG Reports Bar Chart
      monthsBarChartData: monthlyBarChartData,
      yearsBarChartDat: yearlyBarChartData,
      ecgBarChartData: monthlyBarChartData,
      areaChartLabel: moment().format("MMMMM YYYY"),

      // Reset Filter dropdown too
      ecgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },
    });
  };

  // ------------------------- Device Check In -------------------------
  /*

  onCheckInButtonClick = () => {
    this.setState({
      selectedDevice: null,
      isCheckedInOnDevice: false,
      showDeviceCheckInPopup: true,
    });
  };

  onDeviceSelected = (selectedItem) => {
    console.log("onDeviceSelected : ", selectedItem);
    this.setState({
      selectedDevice: selectedItem.value,
      serialNo: selectedItem.label,
    });
  };

  onDeviceCheckInCofirmClick = () => {
    if (this.state.selectedDevice === null) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select a device for Check In",
      });
    } else {
      this.props.checkInDevice(
        { deviceId: this.state.selectedDevice },
        // Upon recieving callback
        () => {
          this.setState({
            selectedDevice: null,
            isCheckedInOnDevice: true,
            showDeviceCheckInPopup: false,
          });
          this.props.getReportListWithFilter({});
          setTimeout(() => {
            this.forceUpdate();
          }, 1500);
        }
      );
    }
  };

  onDeviceCheckInCancelClick = () => {
    this.setState({
      selectedDevice: null,
      isCheckedInOnDevice: false,
      showDeviceCheckInPopup: false,
    });
  };

  */

  // ------------------------- Report Download -------------------------

  onReportDownloadButtonClick = (reportId) => {
    console.log("onReportDownloadButtonClick : reportId : ", reportId);
    // history.push(`/report_download/${reportId}`);
    this.props.ReportDownload(reportId);
  };

  // ------------------------- Area Chart filter -------------------------

  onEcgReportsFilterChange = (selectedOption) => {
    if (selectedOption.value === "year") {
      this.setState({
        ecgReportsFilterSelected: selectedOption,
        ecgBarChartData: this.state.yearsBarChartDat,
        areaChartLabel: moment().format("YYYY"),
      });
    } else if (selectedOption.value === "month") {
      this.setState({
        ecgReportsFilterSelected: selectedOption,
        ecgBarChartData: this.state.monthsBarChartData,
        areaChartLabel: moment().format("MMMMM YYYY"),
      });
    }
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.technician) {
      return (
        <TechnicianView
          loginUser={this.props.loginUser}
          userCreatedAt={this.state.userCreatedAt}
          //
          totalReports={this.state.totalReports}
          pendingReports={this.state.pendingReports}
          dataSet={this.state.graphDataSet}
          //
          // filteredDeviceList={this.props.filteredDeviceList}
          // onCheckInButtonClick={this.onCheckInButtonClick}
          // showDeviceCheckInPopup={this.state.showDeviceCheckInPopup}
          // isCheckedInOnDevice={this.state.isCheckedInOnDevice}
          //
          serialNo={this.state.serialNo}
          // assignedDevice={this.props.assignedDevice}
          // onDeviceSelected={this.onDeviceSelected}
          // onDeviceCheckInCofirmClick={this.onDeviceCheckInCofirmClick}
          // onDeviceCheckInCancelClick={this.onDeviceCheckInCancelClick}
          //
          ecgReportsFilters={this.state.ecgReportsFilters}
          ecgReportsFilterSelected={this.state.ecgReportsFilterSelected}
          ecgBarChartData={this.state.ecgBarChartData}
          onEcgReportsFilterChange={this.onEcgReportsFilterChange}
          areaChartLabel={this.state.areaChartLabel}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    profilePicture: state.auth.profilePicture,
    dashboardData: state.dashboard.dashboardData,
    filteredDeviceList: state.device.filteredDeviceList,
    assignedDevice: state.technician.assignedDevice,
    lastUpdatedTimestamp: state.dashboard.lastUpdatedTimestamp,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
  getTopPerformers,
  getReportListWithFilter,
  getAssignedDeviceOfTechnician,
  // getDeviceListWithFilter,
  // checkInDevice,
})(TechnicianDashboardContainer);
