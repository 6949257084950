export const actionTypes = {
  GET_HOSPITAL_ADMINS_LIST_PENDING: "GET_HOSPITAL_ADMINS_LIST_PENDING",
  GET_HOSPITAL_ADMINS_LIST_SUCCES: "GET_HOSPITAL_ADMINS_LIST_PSUCCES",
  GET_HOSPITAL_ADMINS_LIST_ERROR: "GET_HOSPITAL_ADMINS_LIST_ERROR",

  GET_ADMIN_INFO_BY_ID_PENDING: "GET_ADMIN_INFO_BY_ID_PENDING",
  GET_ADMIN_INFO_BY_ID_SUCCES: "GET_ADMIN_INFO_BY_ID_SUCCES",
  GET_ADMIN_INFO_BY_ID_ERROR: "GET_ADMIN_INFO_BY_ID_ERROR",

  SET_SELECTED_HOSPITAL_ADMIN: "SET_SELECTED_HOSPITAL_ADMIN",
  RESET_SELECTED_HOSPITAL_ADMIN: "RESET_SELECTED_HOSPITAL_ADMIN",

  ADD_HOSPITAL_ADMIN_PENDING: "ADD_HOSPITAL_ADMIN_PENDING",
  ADD_HOSPITAL_ADMIN_SUCCESS: "ADD_HOSPITAL_ADMIN_SUCCESS",
  ADD_HOSPITAL_ADMIN_ERROR: "ADD_HOSPITAL_ADMIN_ERROR",

  EDIT_HOSPITAL_ADMIN_PENDING: "EDIT_HOSPITAL_ADMIN_PENDING",
  EDIT_HOSPITAL_ADMIN_SUCCESS: "EDIT_HOSPITAL_ADMIN_SUCCESS",
  EDIT_HOSPITAL_ADMIN_ERROR: "EDIT_HOSPITAL_ADMIN_ERROR",

  DELETE_HOSPITAL_ADMIN_PENDING: "DELETE_HOSPITAL_ADMIN_PENDING",
  DELETE_HOSPITAL_ADMIN_SUCCESS: "DELETE_HOSPITAL_ADMIN_SUCCESS",
  DELETE_HOSPITAL_ADMIN_ERROR: "DELETE_HOSPITAL_ADMIN_ERROR",

  DELETE_ADMINISTRATORS_IN_BULK_PENDING:
    "DELETE_ADMINISTRATORS_IN_BULK_PENDING",
  DELETE_ADMINISTRATORS_IN_BULK_SUCCESS:
    "DELETE_ADMINISTRATORS_IN_BULK_SUCCESS",
  DELETE_ADMINISTRATORS_IN_BULK_ERROR: "DELETE_ADMINISTRATORS_IN_BULK_ERROR",
};

const initialState = {
  getHospitalAdminsListPending: false,
  getHospitalAdminsListSuccess: false,
  getHospitalAdminsListError: null,

  getAdminsInfoByIdPending: false,
  getAdminsInfoByIdSuccess: false,
  getAdminsInfoByIdError: null,

  addHospitalAdminPending: false,
  addHospitalAdminSuccess: false,
  addHospitalAdminError: null,

  editHospitalAdminPending: false,
  editHospitalAdminSuccess: false,
  editHospitalAdminError: null,

  deleteHospitalAdminPending: false,
  deleteHospitalAdminSuccess: false,
  deleteHospitalAdminError: null,

  deleteAdministratorInBulkPending: false,
  deleteAdministratorInBulkSuccess: false,
  deleteAdministratorInBulkError: null,

  hospitalAdminsList: [],
  hospitalAdminsHospitalIds: [],
  selectedHospitalAdmin: null,
};

export default function administrators(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_HOSPITAL_ADMINS_LIST_PENDING:
    case actionTypes.GET_HOSPITAL_ADMINS_LIST_SUCCES:
    case actionTypes.GET_HOSPITAL_ADMINS_LIST_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_ADMIN_INFO_BY_ID_PENDING:
    case actionTypes.GET_ADMIN_INFO_BY_ID_SUCCES:
    case actionTypes.GET_ADMIN_INFO_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_HOSPITAL_ADMIN:
    case actionTypes.RESET_SELECTED_HOSPITAL_ADMIN:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_HOSPITAL_ADMIN_PENDING:
    case actionTypes.ADD_HOSPITAL_ADMIN_SUCCESS:
    case actionTypes.ADD_HOSPITAL_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_HOSPITAL_ADMIN_PENDING:
    case actionTypes.EDIT_HOSPITAL_ADMIN_SUCCESS:
    case actionTypes.EDIT_HOSPITAL_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_HOSPITAL_ADMIN_PENDING:
    case actionTypes.DELETE_HOSPITAL_ADMIN_SUCCESS:
    case actionTypes.DELETE_HOSPITAL_ADMIN_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_ADMINISTRATORS_IN_BULK_PENDING:
    case actionTypes.DELETE_ADMINISTRATORS_IN_BULK_SUCCESS:
    case actionTypes.DELETE_ADMINISTRATORS_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
