import React, { Component, createRef, useEffect, useRef } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getDeviceListWithFilter,
  checkInDevice,
  removeTechnicianForDevice,
  getSeverityListForErrorLog,
  ReportDeviceSubmission,
  getDeviceDetailsById,
  getDeviceTypeList,
  deleteDeviceInBulk,
  addDeviceInBulk,
  deleteDevice,
  editDevice,
  addDevice,
} from "../../../../store/device/deviceActions";
import { getHospitalsListWithFilter } from "../../../../store/hospital/hospitalActions";
import {
  setSelectedDevice,
  setReportScreensState,
  getReportScreensState,
} from "../../../../store/report/reportActions";
import { getTechnicianListWithFilter } from "../../../../store/technician/technicianActions";
import { getCenterListWithFilter } from "../../../../store/center/centerActions";
import { history } from "../../../../store/configure/configureStore";
import * as validator from "../../../../utils/validator";
import { trimFormData } from "../../../../utils/common";
import DevicesView from "./DevicesView";
import axios from "axios";
import { roles } from "../../../../constants/roles";

class DevicesContainer extends Component {
  validatorFields = {};
  constructor(props) {
    super(props);

    this.validatorFields["required"] = ["deviceId", "deviceType"];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      deviceList: [],
      totalDevices: 0,
      //
      selectedDeviceOnActionClick: null,
      //
      showAddBulkDevicesPopup: false,
      showAddNewDevicesPopup: false,
      showEditDevicesPopup: false,
      showDeleteDevicePopup: false,
      showDeleteDeviceConfirmPopup: false,
      showReportDevicePopup: false,
      showDisableDevicePopup: false,

      // Assign Technician
      showTechnicianAssignPopup: false,
      techniciansList: [],
      selectedTechnician: null,

      // Search filters
      filters: {
        text: "",
        status: "",
      },
      searchStringParam: null,

      // error logs
      reportSeverityList: [],
      deviceInfo: {},
      fields: {
        email: "",
      },
      description: "",
      remark: "",
      deviceReportSeverity: "",
      status: "",
      //error logs error
      isValidationsOn: false,
      remarksError: "",
      emailError: "",
      severityError: "",
      descriptionError: "",

      //add new device
      pageCounttechnician: 2,
      pageCountHospital: 2,
      technicianListPage: 1,
      hospitalListPage: 1,
      addNewDevicefields: {
        deviceId: "",
        deviceType: "",
        assignHospital: "",
        assignCenter: "",
        assignTechnician: "",
      },
      searchHospitalText: "",
      searchTechnicianText: "",
      noScrollHospital: false,
      noScrollTechnician: false,
      hospitalList: [],
      detailhospitalList: [],
      deviceTypeList: [],
      pushedHospitalId: "",
      centerList: [],
      emptyData: false,
      errors: {},

      //Bulk Delete
      selectedIdsToDelete: [],
      clearSelectedRows: false,

      //stepper
      file: [],
      fileName: "",
      assignHospitalInBulk: "",
      detailsAssignHospitalInBulk: {},
      disableNextButton: true,
      pregressPer: 0,
      showProgress: false,
      fileInputRef: React.createRef(null),
      activeStep: 0,
    };

    this.callGetDeviceListAPI = this.callGetDeviceListAPI.bind(this);
    this.callGetCenterListAPI = this.callGetCenterListAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);
    this.callGetTechnicianListAPI = this.callGetTechnicianListAPI.bind(this);

    this.onAddBulkDevicesClick = this.onAddBulkDevicesClick.bind(this);
    this.onAddNewDevicesClick = this.onAddNewDevicesClick.bind(this);
    this.closeAllPopups = this.closeAllPopups.bind(this);
    this.resetDeviceFormField = this.resetDeviceFormField.bind(this);

    this.onAssignTechnicianChange = this.onAssignTechnicianChange.bind(this);
    this.onAssignCenterChange = this.onAssignCenterChange.bind(this);
    this.onSelectDeviceType = this.onSelectDeviceType.bind(this);
    this.onAddNewDevicesConfirmClick =
      this.onAddNewDevicesConfirmClick.bind(this);
    this.onAddDeviceFieldChange = this.onAddDeviceFieldChange.bind(this);
    this.onCloseAddDevicePopup = this.onCloseAddDevicePopup.bind(this);
    this.onAssignHospitalChange = this.onAssignHospitalChange.bind(this);
    this.onScrollTechnicianList = this.onScrollTechnicianList.bind(this);
    this.onScrollHospitalList = this.onScrollHospitalList.bind(this);

    this.handleFileInputClick = this.handleFileInputClick.bind(this);
    this.addBulkDevicesConfirmClick =
      this.addBulkDevicesConfirmClick.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.onCloseAddBulkDevicePopup = this.onCloseAddBulkDevicePopup.bind(this);

    this.onHospitalSearchChange = this.onHospitalSearchChange.bind(this);
    this.onTechnicianSearchChange = this.onTechnicianSearchChange.bind(this);

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkDeviceDeleteClick = this.onBulkDeviceDeleteClick.bind(this);
    this.onBulkDeviceDeleteConfirmClick =
      this.onBulkDeviceDeleteConfirmClick.bind(this);

    this.onEditDeviceConfirmClick = this.onEditDeviceConfirmClick.bind(this);
    this.onCloseEditDevicePopup = this.onCloseEditDevicePopup.bind(this);

    this.onDeleteDeviceConfirmClick =
      this.onDeleteDeviceConfirmClick.bind(this);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onReportDeviceClick = this.onReportDeviceClick.bind(this);
    this.onEditDeviceClick = this.onEditDeviceClick.bind(this);
    this.onDeleteDeviceClick = this.onDeleteDeviceClick.bind(this);
    this.onAssignTechnicianClick = this.onAssignTechnicianClick.bind(this);
    this.onDisableDeviceClick = this.onDisableDeviceClick.bind(this);
    this.onViewReportsClick = this.onViewReportsClick.bind(this);

    this.onTechnicianSelected = this.onTechnicianSelected.bind(this);
    this.onTechnicianAssignCofirmClick =
      this.onTechnicianAssignCofirmClick.bind(this);
    this.onTechnicianAssignCancelClick =
      this.onTechnicianAssignCancelClick.bind(this);

    //
    this.validateData = this.validateData.bind(this);
    this.onMailToChange = this.onMailToChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onRemarksChange = this.onRemarksChange.bind(this);
    this.onSeverityChange = this.onSeverityChange.bind(this);
    this.onSendEmailButtonClick = this.onSendEmailButtonClick.bind(this);
    this.onCloseReportDevicePopup = this.onCloseReportDevicePopup.bind(this);

    this.onAddBulkDeviceFieldChange =
      this.onAddBulkDeviceFieldChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);

    this.handleFileDropClick = this.handleFileDropClick.bind(this);

    this.onDeviceListFilterClick = this.onDeviceListFilterClick.bind(this);
    this.onDeviceListSortClick = this.onDeviceListSortClick.bind(this);

    this.onSkipSelectHospitalStageClick =
      this.onSkipSelectHospitalStageClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    this.props.getSeverityListForErrorLog((reportSeverityListData) => {
      this.setState({ reportSeverityList: reportSeverityListData });
    });

    this.props.getDeviceTypeList((list) => {
      const newTypeList = list.map((item) => {
        return { label: item.type, value: item.id };
      });
      this.setState({ deviceTypeList: newTypeList });
    });

    var previousState = getReportScreensState()?.deviceListScreen ?? {}; // = this.props.location?.state ?? {};
    console.log("Device Screen prevState ::: ", JSON.stringify(previousState));

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;
    var prevFilters = previousState?.filters ?? {
      text: "",
    };
    var searchString = prevFilters?.text !== "" ? prevFilters.text : null;

    this.setState(
      {
        page: prevPage,
        limit: prevLimit,
        filters: prevFilters,
        searchStringParam: searchString,
      },
      () => {
        this.callGetHospitalListAPI();
        this.callGetDeviceListAPI();
      }
    );
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {}

  handleFileInputClick = () => {
    this.state.fileInputRef.current.click();
  };

  handleFileDropClick = (e) => {
    const temp = document.getElementById("border-active");
    temp.style.border = "";
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    console.log(droppedFile);
    if (droppedFile === undefined) {
      this.state.errors["file"] = "Field can not be empty";
    } else if (droppedFile.type !== "text/csv") {
      this.setState({ file: [], disableNextButton: true });
      this.state.errors["file"] = "Only .CSV files are accepted.";
    } else {
      const maxSize = 6 * 1024 * 1024;
      const fileName =
        droppedFile.name.length > 28
          ? `${droppedFile.name.split(".")[0].substring(0, 28)}... .${
              droppedFile.name.split(".")[1]
            }`
          : droppedFile.name;
      if (droppedFile.size > maxSize) {
        this.state.errors["file"] = "file size exceeds 6 MB limit";
        this.setState({
          fileName: "",
          file: [],
          disableNextButton: true,
        });
      } else {
        this.setState({
          fileName: fileName,
          file: e.dataTransfer.files,
          disableNextButton: false,
        });
        this.state.errors["file"] = "";
      }
    }
  };

  onAddBulkDeviceFieldChange = (field) => (e) => {
    console.log(field, "handleChangeAddBulkDeviceFields", e);
    if (field === "file") {
      //
      console.log(e.target.files);
      const file = e.target.files[0];
      if (file === undefined) {
        this.state.errors[field] = "Field can not be empty";
      } else if (file.type !== "text/csv") {
        this.setState({ file: [], disableNextButton: true });
        this.state.errors[field] = "Only .CSV files are accepted.";
      } else {
        const maxSize = 6 * 1024 * 1024;
        const fileName =
          file.name.length > 28
            ? `${file.name.split(".")[0].substring(0, 28)}... .${
                file.name.split(".")[1]
              }`
            : file.name;
        if (file.size > maxSize) {
          this.state.errors[field] = "file size exceeds 6 MB limit";
          this.setState({
            fileName: "",
            file: [],
            disableNextButton: true,
          });
        } else {
          this.setState({
            fileName: fileName,
            file: e.target.files,
            disableNextButton: false,
          });
          this.state.errors[field] = "";
        }
      }
    } else if (field === "assignHospitalInBulk") {
      //
      if (e === null || e.value === "" || e === "") {
        this.setState({
          assignHospitalInBulk: "",
          disableNextButton: true,
          searchHospitalText: "",
          noScrollHospital: false,
          pageCountHospital: 2,
          detailsAssignHospitalInBulk: {},
        });
      } else {
        const detailsOfAssignHospitalInBulk =
          this.state.detailhospitalList.find((item) => item.id === e.value);
        console.log(
          "detailsOfAssignHospitalInBulk",
          detailsOfAssignHospitalInBulk
        );
        this.setState({
          assignHospitalInBulk: e,
          disableNextButton: false,
          searchHospitalText: "",
          noScrollHospital: false,
          pageCountHospital: 2,
          detailsAssignHospitalInBulk: detailsOfAssignHospitalInBulk,
        });
      }
    }
  };

  onSkipSelectHospitalStageClick = () => {
    this.setState(
      {
        assignHospitalInBulk: "",
        searchHospitalText: "",
        noScrollHospital: false,
        pageCountHospital: 2,
        detailsAssignHospitalInBulk: {},
      },
      () => {
        this.handleNext();
      }
    );
  };

  addBulkDevicesConfirmClick = () => {
    console.log("addBulkDevicesConfirmClick", this.state.file);
    this.props.addDeviceInBulk(
      this.state.file[0],
      this.state.assignHospitalInBulk.value,
      (response) => {
        if (response !== null) {
          this.setState({
            activeStep: 0,
            showAddBulkDevicesPopup: false,
            file: [],
            fileName: "",
            assignHospitalInBulk: "",
            disableNextButton: true,
            pregressPer: 0,
            searchHospitalText: "",
            errors: {},
            noScrollHospital: false,
          });
          this.callGetDeviceListAPI();
        }
      }
    );
  };

  onCloseAddBulkDevicePopup = () => {
    this.setState(
      {
        activeStep: 0,
        showAddBulkDevicesPopup: false,
        file: [],
        fileName: "",
        assignHospitalInBulk: "",
        disableNextButton: true,
        pregressPer: 0,
        errors: {},
        noScrollHospital: false,
        detailsAssignHospitalInBulk: {},
        searchHospitalText: "",
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  handleDeleteFile = () => {
    this.setState({ file: [], disableNextButton: true });
    this.state.fileInputRef.current.value = null;
    console.log("referance", this.state.fileInputRef);
  };

  //--------------------------- Get Devices List API Call ------------------------

  callGetDeviceListAPI = () => {
    this.props.getDeviceListWithFilter(
      this.state.filters,
      (listData, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(listData);
          this.forceUpdate();
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  callGetHospitalListAPI = () => {
    this.props.getHospitalsListWithFilter(
      {
        text: this.state.searchHospitalText,
      },
      (list, count, metaData) => {
        const newList = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({ hospitalList: newList, detailhospitalList: list });
      },
      this.state.hospitalListPage,
      25
    );
  };

  callGetCenterListAPI = (hospitalId) => {
    this.props.getCenterListWithFilter({ hospitalId: hospitalId }, (list) => {
      console.log("centerList", list);
      const newCenterList = list.map((item) => {
        return { label: item.centerName, value: item.id };
      });
      this.setState({ centerList: newCenterList });
    });
  };

  callGetTechnicianListAPI = (centerId) => {
    this.props.getTechnicianListWithFilter(
      roles.centerAdmin === this.state.userRole
        ? {
            text: this.state.searchTechnicianText,
          }
        : {
            centerId: centerId,
            text: this.state.searchTechnicianText,
          },
      (list, count) => {
        this.setState({ techniciansList: list });
      },
      this.state.technicianListPage,
      25
    );
  };

  resetDeviceFormField = () => {
    this.setState({
      showAddNewDevicesPopup: false,
      showEditDevicesPopup: false,
      isValidationsOn: false,
      noScrollHospital: false,
      noScrollTechnician: false,
      errors: {},
      centerList: [],
      techniciansList: [],
      pageCounttechnician: 2,
      pageCountHospital: 2,
      addNewDevicefields: {
        ...this.state.addNewDevicefields,
        deviceId: "",
        deviceType: "",
        assignHospital: "",
        assignCenter: "",
        assignTechnician: "",
      },
    });
  };

  //------------------------------ Add New Device popup field control ----------------------------------------

  onAssignTechnicianChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignTechnician: value,
        },
        searchTechnicianText: "",
        noScrollTechnician: false,
        pageCounttechnician: 2,
      });
    } else {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignTechnician: "",
        },
        noScrollTechnician: false,
        pageCounttechnician: 2,
      });
    }
  };

  onSelectDeviceType = (value) => {
    this.setState({
      addNewDevicefields: {
        ...this.state.addNewDevicefields,
        deviceType: value,
      },
    });
  };

  onScrollHospitalList = () => {
    console.log("noScrollHospital", this.state.noScrollHospital);
    if (!this.state.noScrollHospital) {
      this.props.getHospitalsListWithFilter(
        {
          text: this.state.searchHospitalText,
        },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              if (this.state.pushedHospitalId !== item.id) {
                return { label: item.hospitalName, value: item.id };
              }
            });
            const newListData = listData.filter(function (item) {
              return item !== undefined;
            });
            this.setState({
              hospitalList: [...this.state.hospitalList, ...newListData],
              detailhospitalList: [...this.state.detailhospitalList, ...list],
            });
            ++this.state.pageCountHospital;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCountHospital, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onScrollTechnicianList = () => {
    if (!this.state.noScrollTechnician) {
      this.props.getTechnicianListWithFilter(
        roles.centerAdmin === this.state.userRole
          ? {
              text: this.state.searchTechnicianText,
            }
          : {
              centerId: this.state.addNewDevicefields.assignCenter?.value ?? "",
              text: this.state.searchTechnicianText,
            },
        (listData, count) => {
          if (listData.length > 0) {
            this.setState({
              techniciansList: [...this.state.techniciansList, ...listData],
            });
            ++this.state.pageCounttechnician;
          } else {
            this.setState({ noScrollTechnician: true });
          }
        },
        this.state.pageCounttechnician, // direct call from 2nd page bcoz 1st page alredy added
        25
      );
    }
  };

  onAssignHospitalChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignHospital: value,
          assignCenter: "",
          assignTechnician: "",
        },
        searchHospitalText: "",
        noScrollHospital: false,
        pageCountHospital: 2,
        techniciansList: [],
      });
      this.callGetCenterListAPI(value.value);
    } else {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignHospital: value,
          assignCenter: "",
          assignTechnician: "",
        },
        searchHospitalText: "",
        noScrollHospital: false,
        pageCountHospital: 2,
        techniciansList: [],
        centerList: [],
      });
    }
  };

  onAssignCenterChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignCenter: value,
          assignTechnician: "",
        },
      });
      this.callGetTechnicianListAPI(value.value);
    } else {
      this.setState({
        addNewDevicefields: {
          ...this.state.addNewDevicefields,
          assignCenter: "",
          assignTechnician: "",
        },
        techniciansList: [],
      });
    }
  };

  onAddDeviceFieldChange = (field) => (e) => {
    let value;
    const { addNewDevicefields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;
    console.log(field, "&", e);

    var isRequired = this.validatorFields.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      if (field === "deviceId") {
        if (this.state.isValidationsOn && isRequired) {
          e.target.value.length > 14
            ? (this.state.errors[field] = "Only 14 character IDs are accepted")
            : e.target.value.length > 0
            ? (this.state.errors[field] = "")
            : (this.state.errors[field] = "Field can not be empty");
        }
        value = e.target.value;
      }
      value = e.target.value;
    } else if (field === "deviceType") {
      if (e === null || e.value === "" || e === "") {
        this.state.errors[field] = "Field can not be empty";
      } else {
        this.state.errors[field] = "";
        value = e;
      }
    }
    // else if (field === "deviceId") {
    //   if (this.state.isValidationsOn && isRequired) {
    //     e.target.value.length > 14
    //       ? (this.state.errors[field] = "Only 14 character IDs are accepted")
    //       : e.target.value.length > 0
    //       ? (this.state.errors[field] = "")
    //       : (this.state.errors[field] = "Field can not be empty");
    //   }
    //   value = e.target.value;
    // }
    // set data
    this.setState({
      ...this.state,
      addNewDevicefields: { ...addNewDevicefields, [field]: value },
    });
  };

  //---------------------report device pop up fields control------------------------------

  onHospitalSearchChange = (text) => {
    console.log("onHospitalSearchChange", text);
    this.setState(
      {
        searchHospitalText: text,
        pageCountHospital: 2,
        noScrollHospital: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  onTechnicianSearchChange = (text) => {
    this.setState(
      {
        searchTechnicianText: text,
        pageCounttechnician: 2,
        noScrollTechnician: false,
      },
      () => {
        this.callGetTechnicianListAPI(
          this.state.addNewDevicefields?.assignCenter?.value
        );
      }
    );
  };

  onMailToChange = (value, name) => {
    const field = name;
    if (JSON.stringify(value).length <= 2 && this.state.isValidationsOn) {
      this.setState({
        fields: { ...this.state.fields, [field]: value },
        emailError: "Field can not be empty",
      });
    } else {
      this.setState({
        fields: { ...this.state.fields, [field]: value },
        emailError: "",
      });
    }
  };

  onDescriptionChange = (value) => {
    console.log(JSON.stringify(value).length, "*", this.state.isValidationsOn);
    if (value.length > 120) {
      this.setState({
        description: value.substr(0, 120),
        descriptionError: "Maximum 120 characters allowed",
      });
    } else if (
      JSON.stringify(value).length <= 2 &&
      this.state.isValidationsOn
    ) {
      this.setState({
        description: value,
        descriptionError: "Please add description for send email",
      });
    } else {
      this.setState({ description: value, descriptionError: "" });
    }
  };

  onRemarksChange = (value) => {
    if (value.length > 500) {
      var croppedValue = value.slice(0, 500);
      console.log("VAL : ", croppedValue);

      this.setState({
        remark: croppedValue,
        remarksError: "Maximum 500 characters allowed",
      });
    } else {
      this.setState({ remark: value, remarksError: "" });
    }
  };

  onSeverityChange = (value) => {
    this.setState({ deviceReportSeverity: value, severityError: "" });
  };

  onSendEmailButtonClick = () => {
    this.setState({ isValidationsOn: true }); // on change validation on only after user click on send email button
    if (this.validateData()) {
      const data = {
        deviceId: this.state.deviceInfo.id,
        to: this.state.fields.email,
        status: this.state.deviceInfo.status,
        description: this.state.description,
        remark: this.state.remark,
        severity: this.state.deviceReportSeverity.value,
      };
      this.props.ReportDeviceSubmission(data, () => {
        this.callGetDeviceListAPI();
        this.setState({
          showReportDevicePopup: false,
          deviceReportSeverity: "",
          description: "",
          fields: { ...this.state.fields, email: "" },
          remark: "",
        });
      });
    }
  };

  validateData = () => {
    var isErrorInData = false;

    var custom = [validator.email(["email"])];
    var fields = this.state.fields;
    const validatorFields = {
      required: ["email"],
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );

    if (!Object.keys(errors).length) {
      this.setState({ emailError: "" });
      isErrorInData = false;
    } else {
      this.setState({ emailError: errors.email });
      isErrorInData = true;
    }
    if (
      this.state.deviceReportSeverity === null ||
      this.state.deviceReportSeverity === "" ||
      this.state.deviceReportSeverity.value === ""
    ) {
      this.setState({ severityError: "Please add severity for send email" });
      isErrorInData = true;
    }
    if (
      this.state.description === null ||
      this.state.description.trim() === ""
    ) {
      this.setState({
        descriptionError: "Please add description for send email",
      });
      isErrorInData = true;
    }

    if (isErrorInData) {
      return false;
    } else {
      return true;
    }
  };

  //--------------------- Pop up dialog controls ---------------------

  //-------------------------- ADD Device ------------------------------------
  onAddBulkDevicesClick = () => {
    this.setState({ showAddBulkDevicesPopup: true });
  };

  onAddNewDevicesClick = () => {
    this.setState({ showAddNewDevicesPopup: true });
  };
  onAddNewDevicesConfirmClick = () => {
    const custom = [];
    this.validatorFields["custom"] = custom;
    const { addNewDevicefields } = this.state;

    const { errors } = validator.validate(
      this.validatorFields,
      trimFormData(addNewDevicefields)
    );

    if (!addNewDevicefields.deviceId.length > 0) {
      errors["deviceId"] = "Field can not be empty";
    } else if (
      addNewDevicefields.deviceId.trim().length < 14 ||
      addNewDevicefields.deviceId.trim().length > 14
    ) {
      errors["deviceId"] = "Only 14 character IDs are accepted.";
    }

    console.log("length", addNewDevicefields.deviceId.length);
    console.log("errors", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        type: addNewDevicefields.deviceType.value,
        serialNo: addNewDevicefields.deviceId,
        hospitalId: addNewDevicefields.assignHospital.value,
        centerId: addNewDevicefields.assignCenter.value,
      };
      console.log("add data", data);
      this.props.addDevice(data, (response) => {
        console.log("response", response);
        const val = addNewDevicefields.assignTechnician.value;
        //
        if (response !== null) {
          if (val !== null && val !== "" && val !== undefined) {
            this.props.checkInDevice(
              {
                technicianId: addNewDevicefields.assignTechnician.value,
                deviceId: response.id,
              },
              () => {}
            );
          }
          this.resetDeviceFormField();
          this.callGetDeviceListAPI();
          this.callGetHospitalListAPI();
        }
      });
    }
  };

  onCloseAddDevicePopup = () => {
    this.resetDeviceFormField();
    this.callGetHospitalListAPI();
  };

  //-------------------------------- Update device -------------------------------------------------

  onEditDeviceConfirmClick = () => {
    const custom = [];
    this.validatorFields["custom"] = custom;
    const { addNewDevicefields } = this.state;

    const { errors } = validator.validate(
      this.validatorFields,
      trimFormData(addNewDevicefields)
    );
    if (!addNewDevicefields.deviceId?.length > 0) {
      errors["deviceId"] = "Field can not be empty";
    } else if (
      addNewDevicefields.deviceId?.trim().length < 14 ||
      addNewDevicefields.deviceId.trim().length > 14
    ) {
      errors["deviceId"] = "Only 14 character IDs are accepted";
    }
    console.log("errors", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        type: addNewDevicefields.deviceType.value,
        serialNo: addNewDevicefields.deviceId,
        hospitalId: addNewDevicefields.assignHospital?.value ?? null,
        centerId: addNewDevicefields.assignCenter?.value ?? null,
      };
      console.log("data", data);
      this.props.editDevice(this.state.deviceInfo.id, data, (response) => {
        const val = addNewDevicefields.assignTechnician?.value;
        if (response !== null) {
          if (val !== null && val !== "" && val !== undefined) {
            this.props.checkInDevice(
              {
                technicianId: addNewDevicefields.assignTechnician.value,
                deviceId: this.state.deviceInfo.id,
              },
              () => {
                this.resetDeviceFormField();
                this.callGetDeviceListAPI();
                this.callGetHospitalListAPI();
              }
            );
          } else {
            this.props.removeTechnicianForDevice(
              {
                deviceId: this.state.deviceInfo.id,
              },
              () => {
                this.resetDeviceFormField();
                this.callGetDeviceListAPI();
                this.callGetHospitalListAPI();
              }
            );
          }
        }
      });
    }
  };

  //---------------------------------- Deelete Device --------------------------------------

  onDeleteDeviceConfirmClick = () => {
    this.props.deleteDevice(this.state.deviceInfo.id, () => {
      this.setState({ showDeleteDevicePopup: false });
      this.callGetDeviceListAPI();
    });
  };

  onCloseEditDevicePopup = () => {
    this.resetDeviceFormField();
    this.callGetHospitalListAPI();
  };

  onCloseReportDevicePopup = () => {
    this.setState({
      isValidationsOn: false,
      showReportDevicePopup: false,

      severityError: "",
      deviceReportSeverity: "",
      descriptionError: "",
      description: "",
      emailError: "",
      fields: { ...this.state.fields, email: "" },
      remark: "",
    });
  };

  closeAllPopups = () => {
    this.setState({
      showAddBulkDevicesPopup: false,
      showAddNewDevicesPopup: false,
      showDisableDevicePopup: false,
      showTechnicianAssignPopup: false,
      showDeleteDevicePopup: false,
      showDeleteDeviceConfirmPopup: false,
      errors: {},
    });
  };

  // ------------------- Assign Technician --------------------------

  onAssignTechnicianClick = (deviceInfo) => {
    console.log("DEVICE INFO : ", deviceInfo.id);
    this.props.getTechnicianListWithFilter(
      {
        text: this.state.searchTechnicianText,
      },
      (list, count) => {
        this.setState({
          selectedDeviceOnActionClick: deviceInfo,
          selectedTechnician: null,
          techniciansList: list,
          showTechnicianAssignPopup: true,
        });
      },
      this.state.technicianListPage, // page
      25 // limt
      // TODO - Lazy loading on dropdown list end reached -> call API with page = 2
    );
  };

  onTechnicianSelected = (selectedItem) => {
    console.log("onTechnicianSelected : ", selectedItem);
    this.setState({
      selectedTechnician: selectedItem !== null ? selectedItem.value : null,
    });
  };

  onTechnicianAssignCofirmClick = () => {
    if (this.state.selectedTechnician === null) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select a technician!",
      });
    } else {
      this.props.checkInDevice(
        {
          technicianId: this.state.selectedTechnician,
          deviceId: this.state.selectedDeviceOnActionClick.id,
        },
        // Upon recieving callback
        () => {
          this.setState({
            selectedTechnician: null,
            selectedDeviceOnActionClick: null,
            techniciansList: [],
            pageCounttechnician: 2,
            showTechnicianAssignPopup: false,
            noScrollTechnician: false,
            searchTechnicianText: "",
          });
          this.callGetDeviceListAPI();
        }
      );
    }
  };

  onTechnicianAssignCancelClick = () => {
    this.setState({
      selectedTechnician: null,
      selectedDeviceOnActionClick: null,
      techniciansList: [],
      pageCounttechnician: 2,
      showTechnicianAssignPopup: false,
      noScrollTechnician: false,
      searchTechnicianText: "",
    });
  };

  //--------------------- List Action Button Clicks --------------------

  onReportDeviceClick = (row) => {
    console.log(row, "rowid");
    this.setState({ deviceInfo: row, showReportDevicePopup: true });
    const deviceDetails = row;
  };

  onEditDeviceClick = (row) => {
    this.props.getDeviceDetailsById(row.id, (details) => {
      console.log("device details", details);
      const hospitaldetails = details?.hospital?.hospitalInfo ?? null;
      const centerInfo = details?.centerDeviceInfo ?? null;
      var hospitalObject;
      const updateTechnician =
        details?.technicianList?.length > 0
          ? details?.technicianList[0].technician_profile
          : null;

      // if (technicianIndex !== -1) {
      //   technicianObject = this.state.techniciansList[technicianIndex];
      // } else if (updateTechnician !== null) {
      //   technicianObject = {
      //     value: updateTechnician.id,
      //     label: `${
      //       updateTechnician.firstName + " " + updateTechnician.lastName
      //     }`,
      //   };
      //   this.state.techniciansList.push(technicianObject);
      // }

      const hospitalIndex = this.state.hospitalList.findIndex(
        (item) => item.value === hospitaldetails?.id
      );
      if (hospitaldetails !== null) {
        if (hospitalIndex !== -1) {
          hospitalObject = this.state.hospitalList[hospitalIndex]; // if hospital is not present in first 25 then we manually create object and push
        } else {
          hospitalObject = {
            value: hospitaldetails?.id,
            label: hospitaldetails?.hospitalName,
          };
          this.state.hospitalList.push(hospitalObject);
        }
      }

      const typeIndex = this.state.deviceTypeList.findIndex(
        (item) => item.value === details.type
      );
      hospitaldetails !== null
        ? this.props.getCenterListWithFilter(
            { hospitalId: hospitaldetails?.id },
            (list) => {
              const centerIndex = list.findIndex(
                (item) => item.id === centerInfo?.centerId
              );
              const newCenterList = list.map((item) => {
                return { label: item.centerName, value: item.id };
              });

              centerInfo !== null
                ? this.props.getTechnicianListWithFilter(
                    {
                      centerId: centerInfo?.centerId,
                    },
                    (list, count) => {
                      const technicianIndex = list.findIndex(
                        (item) => item.id === updateTechnician?.id ?? ""
                      );

                      const newTechnicianList = list.map((item) => {
                        return {
                          label: `${
                            (item?.firstName !== null &&
                            item?.firstName !== undefined
                              ? `${item?.firstName
                                  .charAt(0)
                                  .toUpperCase()}${item?.firstName.slice(1)}`
                              : "") +
                            " " +
                            (item?.lastName !== null &&
                            item?.lastName !== undefined
                              ? `${item?.lastName
                                  .charAt(0)
                                  .toUpperCase()}${item?.lastName.slice(1)}`
                              : "")
                          }`,
                          value: item.id,
                        };
                      });
                      this.setState({
                        techniciansList: list,
                        showEditDevicesPopup: true,
                        isValidationsOn: true,
                        deviceInfo: row,
                        pushedHospitalId: hospitalObject?.value,
                        addNewDevicefields: {
                          ...this.state.addNewDevicefields,
                          deviceId: details.serialNo,
                          deviceType:
                            typeIndex !== -1
                              ? this.state.deviceTypeList[typeIndex]
                              : "",
                          assignHospital: hospitalObject,
                          assignCenter: newCenterList[centerIndex],
                          assignTechnician: newTechnicianList[technicianIndex],
                        },
                        searchHospitalText: "",
                        searchTechnicianText: "",
                        centerList: newCenterList,
                      });
                    },
                    this.state.technicianListPage,
                    25
                  )
                : this.setState({
                    techniciansList: [],
                    showEditDevicesPopup: true,
                    isValidationsOn: true,
                    deviceInfo: row,
                    pushedHospitalId: hospitalObject?.value,
                    addNewDevicefields: {
                      ...this.state.addNewDevicefields,
                      deviceId: details.serialNo,
                      deviceType:
                        typeIndex !== -1
                          ? this.state.deviceTypeList[typeIndex]
                          : "",
                      assignHospital: hospitalObject,
                      assignCenter: newCenterList[centerIndex],
                      assignTechnician: "",
                    },
                    searchHospitalText: "",
                    searchTechnicianText: "",
                    centerList: newCenterList,
                  });
            }
          )
        : this.setState({
            techniciansList: [],
            showEditDevicesPopup: true,
            isValidationsOn: true,
            deviceInfo: row,
            pushedHospitalId: hospitalObject?.value,
            addNewDevicefields: {
              ...this.state.addNewDevicefields,
              deviceId: details.serialNo,
              deviceType:
                typeIndex !== -1 ? this.state.deviceTypeList[typeIndex] : "",
              assignHospital: hospitalObject,
              assignCenter: "",
              assignTechnician: "",
            },
            searchHospitalText: "",
            searchTechnicianText: "",
            centerList: [],
          });
    });
  };

  onDeleteDeviceClick = (row) => {
    console.log("delete device");
    this.setState({ deviceInfo: row, showDeleteDevicePopup: true });
  };

  onDisableDeviceClick = () => {
    this.setState({ showDisableDevicePopup: true });
    console.log("onDisableDeviceClick");
  };

  //--------------------------------------------- delete bulk devices ------------------------------

  handleSelectedRowsChange = (selectedRowsArray) => {
    var devicesIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({ selectedIdsToDelete: devicesIds });
  };

  onBulkDeviceDeleteClick = () => {
    this.setState({ showDeleteDeviceConfirmPopup: true });
  };

  onBulkDeviceDeleteConfirmClick = () => {
    this.props.deleteDeviceInBulk(
      { deviceIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showDeleteDeviceConfirmPopup: false,
        });
        this.callGetDeviceListAPI();
      }
    );
  };

  onViewReportsClick = (deviceId, deviceSerialNumber) => {
    console.log("onViewReportsClick : ", deviceId, ",", deviceSerialNumber);

    this.props.setSelectedDevice({
      id: deviceId,
      serialNumber: deviceSerialNumber,
      //date-time filters not availableon this page, thus no need to add
    });

    var savedPageStates = getReportScreensState();
    savedPageStates.isComingFromDoctorsList = false;
    savedPageStates.isComingFromDeviceList = true;
    savedPageStates.deviceListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);

    setTimeout(() => {
      history.push({
        pathname: "/ecg_reports",
        // state: {
        //   page: this.state.page,
        //   limit: this.state.limit,
        //   toggleValue: this.state.toggleValue,
        //   dateRangeStartDate: this.state.dateRangeStartDate,
        //   dateRangeEndDate: this.state.dateRangeEndDate,
        //   filters: this.state.filters,
        // },
      });
    }, 500);
  };

  //--------------------------------------------- Stepper View functionality ------------------------------

  handleNext = () => {
    let disableButton = true;
    if (JSON.stringify(this.state.assignHospitalInBulk).length > 2) {
      disableButton = false;
    }
    this.setState({
      disableNextButton: disableButton,
      activeStep: this.state.activeStep + 1,
    });
  };

  handleBack = () => {
    if (
      this.state.activeStep === 2 &&
      !(JSON.stringify(this.state.assignHospitalInBulk).length > 2)
    ) {
      this.setState({
        disableNextButton: true,
        activeStep: this.state.activeStep - 1,
      });
    } else {
      this.setState({
        disableNextButton: false,
        activeStep: this.state.activeStep - 1,
      });
    }
  };
  //-------------------------- Data and Pagination ---------------------------------

  populateListData = (listData) => {
    var deviceListData = listData; // Redux data
    var numberOfDevicesAvailable =
      deviceListData && deviceListData.length ? deviceListData.length : 0;

    this.setState({
      deviceList: deviceListData,
      totalDevices: numberOfDevicesAvailable,
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalDevices;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetDeviceListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetDeviceListAPI();
      }
    );
  };

  //------------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetDeviceListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetDeviceListAPI();
      }
    );
  };

  //sorting filters

  onDeviceListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);

    let filter =
      filterOption === "Online"
        ? 1
        : filterOption === "Offline"
        ? 2
        : filterOption === "Error"
        ? 3
        : "";
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          status: parseInt(filter),
        },
      },
      () => {
        this.props.getDeviceListWithFilter(
          this.state.filters,
          (listData, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData);
              this.forceUpdate();
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  onDeviceListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Device ID") {
      columnName = "deviceId";
    } else if (column === "Device Type") {
      columnName = "deviceType";
    } else if (column === "Hospital") {
      columnName = "hospital";
    } else if (column === "Added On") {
      columnName = "addedOn";
    } else if (column === "Technician") {
      columnName = "technician";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getDeviceListWithFilter(
          this.state.filters,
          (listData, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData);
              this.forceUpdate();
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <DevicesView
        // For showing Report List Columns as per user role
        userRole={this.state.userRole}
        assignedDevice={this.props.assignedDevice}
        //
        hospitalList={this.state.hospitalList}
        deviceList={this.state.deviceList}
        totalDevices={this.props.filteredDeviceCount}
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        searchStringParam={this.state.searchStringParam}
        //
        showAddBulkDevicesPopup={this.state.showAddBulkDevicesPopup}
        onAddBulkDevicesClick={this.onAddBulkDevicesClick}
        //
        showAddNewDevicesPopup={this.state.showAddNewDevicesPopup}
        showEditDevicesPopup={this.state.showEditDevicesPopup}
        showDeleteDevicePopup={this.state.showDeleteDevicePopup}
        onAddNewDevicesClick={this.onAddNewDevicesClick}
        //
        onBulkDeviceDeleteClick={this.onBulkDeviceDeleteClick}
        onBulkDeviceDeleteConfirmClick={this.onBulkDeviceDeleteConfirmClick}
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        showDeleteDeviceConfirmPopup={this.state.showDeleteDeviceConfirmPopup}
        //
        onAddNewDevicesConfirmClick={this.onAddNewDevicesConfirmClick}
        onAddDeviceFieldChange={this.onAddDeviceFieldChange}
        onAssignHospitalChange={this.onAssignHospitalChange}
        onAssignCenterChange={this.onAssignCenterChange}
        onAssignTechnicianChange={this.onAssignTechnicianChange}
        onSelectDeviceType={this.onSelectDeviceType}
        onScrollTechnicianList={this.onScrollTechnicianList}
        onScrollHospitalList={this.onScrollHospitalList}
        //
        onHospitalSearchChange={this.onHospitalSearchChange}
        onTechnicianSearchChange={this.onTechnicianSearchChange}
        //
        addNewDevicefields={this.state.addNewDevicefields}
        deviceTypeList={this.state.deviceTypeList}
        centerList={this.state.centerList}
        errors={this.state.errors}
        //
        onCloseAddDevicePopup={this.onCloseAddDevicePopup}
        //
        showReportDevicePopup={this.state.showReportDevicePopup}
        onReportDeviceClick={this.onReportDeviceClick}
        //
        onEditDeviceClick={this.onEditDeviceClick}
        onDeleteDeviceClick={this.onDeleteDeviceClick}
        //
        onDeleteDeviceConfirmClick={this.onDeleteDeviceConfirmClick}
        //
        showDisableDevicePopup={this.state.showDisableDevicePopup}
        onDisableDeviceClick={this.onDisableDeviceClick}
        //
        onAssignTechnicianClick={this.onAssignTechnicianClick}
        showTechnicianAssignPopup={this.state.showTechnicianAssignPopup}
        techniciansList={this.state.techniciansList}
        onTechnicianSelected={this.onTechnicianSelected}
        selectedTechnician={this.state.selectedTechnician}
        onTechnicianAssignCofirmClick={this.onTechnicianAssignCofirmClick}
        onTechnicianAssignCancelClick={this.onTechnicianAssignCancelClick}
        searchTechnicianText={this.state.searchTechnicianText}
        //
        searchHospitalText={this.state.searchHospitalText}
        onEditDeviceConfirmClick={this.onEditDeviceConfirmClick}
        onCloseEditDevicePopup={this.onCloseEditDevicePopup}
        //
        selectedDeviceOnActionClick={this.state.selectedDeviceOnActionClick}
        closeAllPopups={this.closeAllPopups}
        //
        onViewReportsClick={this.onViewReportsClick}
        //
        onMailToChange={this.onMailToChange}
        emailError={this.state.emailError}
        fields={this.state.fields}
        //
        onDescriptionChange={this.onDescriptionChange}
        description={this.state.description}
        descriptionError={this.state.descriptionError}
        //
        onSeverityChange={this.onSeverityChange}
        severityError={this.state.severityError}
        //
        remark={this.state.remark}
        remarksError={this.state.remarksError}
        //
        onRemarksChange={this.onRemarksChange}
        onSendEmailButtonClick={this.onSendEmailButtonClick}
        deviceInfo={this.state.deviceInfo}
        reportSeverityList={this.state.reportSeverityList}
        onCloseReportDevicePopup={this.onCloseReportDevicePopup}
        //
        activeStep={this.state.activeStep}
        fileInputRef={this.state.fileInputRef}
        pregressPer={this.state.pregressPer}
        disableNextButton={this.state.disableNextButton}
        fileName={this.state.fileName}
        file={this.state.file}
        assignHospitalInBulk={this.state.assignHospitalInBulk}
        detailsAssignHospitalInBulk={this.state.detailsAssignHospitalInBulk}
        //
        onCloseAddBulkDevicePopup={this.onCloseAddBulkDevicePopup}
        handleFileInputClick={this.handleFileInputClick}
        onAddBulkDeviceFieldChange={this.onAddBulkDeviceFieldChange}
        addBulkDevicesConfirmClick={this.addBulkDevicesConfirmClick}
        handleDeleteFile={this.handleDeleteFile}
        //
        handleBack={this.handleBack}
        handleNext={this.handleNext}
        handleFileDropClick={this.handleFileDropClick}
        //
        onDeviceListFilterClick={this.onDeviceListFilterClick}
        onDeviceListSortClick={this.onDeviceListSortClick}
        //
        onSkipSelectHospitalStageClick={this.onSkipSelectHospitalStageClick}
      />
    );
  }
}

DevicesContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    assignedDevice: state.technician.assignedDevice,
    filteredDeviceList: state.device.filteredDeviceList,
    filteredDeviceCount: state.device.filteredDeviceCount,
    filteredHospitalList: state.hospital.filteredHospitalList,
  };
};

export default connect(mapStateToProps, {
  showAlert,
  getDeviceListWithFilter,
  checkInDevice,
  removeTechnicianForDevice,
  getSeverityListForErrorLog,
  ReportDeviceSubmission,
  getTechnicianListWithFilter,
  setSelectedDevice,
  setReportScreensState,
  getHospitalsListWithFilter,
  getDeviceDetailsById,
  getCenterListWithFilter,
  getDeviceTypeList,
  deleteDeviceInBulk,
  addDeviceInBulk,
  deleteDevice,
  editDevice,
  addDevice,
})(DevicesContainer);
