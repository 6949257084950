import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  getReportListWithFilter,
  getReportListOfDoctorWithFilter,
  getReportListOfDeviceWithFilter,
  ReportDownload,
  ReportDownloadInBulk,
  ReportExportToExcelInBulk,
  ReportPrint,
  setReportScreensState,
  getReportScreensState,
  getSelectedDoctor,
  getSelectedDevice,
} from "../../../../store/report/reportActions";
import {
  createMQTTConnection,
  subscribeToTopic,
} from "../../../../service/mqttApi";
import { showAlert } from "../../../../store/alert/alertActions";
import { roles } from "../../../../constants/roles";
import { history } from "../../../../store/configure/configureStore";
import EcgReportsView from "./EcgReportsView";
import moment from "moment";

class EcgReportsContainer extends Component {
  startDayForLastSevenDaysToggle = moment()
    .subtract(6, "days")
    .format("DD-MM-YYYY");

  constructor(props) {
    super(props);

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      isComingFromDeviceList: false,
      isComingFromDoctorsList: false,
      //
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      reportList: [],
      totalReports: 0,
      //
      toggleValue: "lastSevenDays",
      toggleData: [
        {
          id: "today",
          name: "Today",
        },
        {
          id: "lastSevenDays",
          name: "Last 7 Days",
        },
      ],
      dateRangeStartDate: null,
      dateRangeEndDate: null,
      filters: {
        startTime: moment(
          this.startDayForLastSevenDaysToggle,
          "DD-MM-YYYY"
        ).unix(), // moment().startOf("day").unix(),
        endTime: moment().endOf("day").unix(),
        text: "",
        status: "",
        severity: "",
      },
      // For SuperAdmin and HospitalAdmin, we need to show reports list for selected doctor (isComingFromDoctorsList)
      doctorId: null,
      doctorName: "",

      // For HospitalAdmin, we need to show reports list for selected device (isComingFromDeviceList)
      deviceId: null,
      deviceSerialNumber: "",

      // For Bulk Download of reports
      reportIdsToDownload: [],
      clearSelectedRows: false,
    };

    this.initializeAllData = this.initializeAllData.bind(this);
    this.saveThisScreensStateInRedux =
      this.saveThisScreensStateInRedux.bind(this);
    this.callGetReportListApiForUserRole =
      this.callGetReportListApiForUserRole.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);

    this.onToggleChange = this.onToggleChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);
    this.onBulkReportsDownloadClick =
      this.onBulkReportsDownloadClick.bind(this);
    this.onExcelExportClick = this.onExcelExportClick.bind(this);

    this.onViewReportClick = this.onViewReportClick.bind(this);
    this.onSupervisorViewReportClick =
      this.onSupervisorViewReportClick.bind(this);
    this.onPrintReportClick = this.onPrintReportClick.bind(this);
    this.onDownloadReportClick = this.onDownloadReportClick.bind(this);

    this.onDoctorNameBackClick = this.onDoctorNameBackClick.bind(this);
    this.onDeviceNameBackClick = this.onDeviceNameBackClick.bind(this);

    this.onECGReportListFilterClick =
      this.onECGReportListFilterClick.bind(this);
    this.onECGReportListSortClick = this.onECGReportListSortClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    this.initializeAllData();

    if (loginUserRole === roles.technician) {
      // Connect to MQTT
      createMQTTConnection(() => {
        console.log("TECHNICIAN ECG Report Screen : MQTT Connected!!!");
      });

      // Subscribe to MQTT topic for refreshing list, when any updates are made on backend
      var technicianId = this.props.loginUser?.userProfile?.id ?? "";
      subscribeToTopic(
        `device/reportRequest/reportData/technician:${technicianId}`,
        (msg) => {
          console.log("MQTT data recieved!");
          this.callGetReportListApiForUserRole();
        }
      );
    }

    /*
    // For SuperAdmin and HospitalAdmin, we need to show doctors name in card header
    if (
      loginUserRole === roles.superAdmin ||
      loginUserRole === roles.hospitalAdmin
    ) {
      var selectedDoctorData = getSelectedDoctor();
      console.log("selectedDoctorData : ", JSON.stringify(selectedDoctorData));

      this.setState(
        {
          doctorId: selectedDoctorData?.id ?? null,
          doctorName: selectedDoctorData?.name ?? "Dr. - ",
          toggleValue: selectedDoctorData.toggleValue,
          dateRangeStartDate: selectedDoctorData.dateRangeStartDate,
          dateRangeEndDate: selectedDoctorData.dateRangeEndDate,
          filters: {
            ...selectedDoctorData.filters,
            doctorProfileId: selectedDoctorData?.id ?? null,
          },
        },
        () => {
          this.props.getReportListOfDoctorWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit
          );
        }
      );
    } else {
      this.props.getReportListWithFilter(
        this.state.filters,
        (listData, count) => {
          this.populateListData(listData, count);
        },
        this.state.page,
        this.state.limit
      );
    }

    */
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {}

  // -------------- List parsing  ---------------

  initializeAllData = () => {
    var reportScreenStates = getReportScreensState(); // this.props.location?.state ?? {};
    console.log("ECGReports state ::: ", JSON.stringify(reportScreenStates));

    var isComingFromDeviceList =
      reportScreenStates?.isComingFromDeviceList ?? false;
    var isComingFromDoctorsList =
      reportScreenStates?.isComingFromDoctorsList ?? false;

    this.setState({
      isComingFromDeviceList: isComingFromDeviceList,
      isComingFromDoctorsList: isComingFromDoctorsList,
    });

    //--------------------------------------------------------
    if (!isComingFromDoctorsList && !isComingFromDeviceList) {
      // Check Report List screens's previous state (For doctor and supervisor, while coming back from Report Details Page)
      var previousState = reportScreenStates?.reportListScreen ?? {};

      console.log("previousState :: ", previousState);
      var prevPage = previousState?.page ?? 1;
      var prevLimit = previousState?.limit ?? 10;
      var prevToggle =
        previousState?.dateRangeStartDate && previousState?.dateRangeStartDate
          ? null
          : previousState?.toggleValue
          ? previousState?.toggleValue
          : "lastSevenDays";
      var prevDateRangeStart = previousState?.dateRangeStartDate ?? null;
      var prevDateRangeEnd = previousState?.dateRangeEndDate ?? null;
      var prevFilters = previousState?.filters ?? {
        startTime: moment(
          this.startDayForLastSevenDaysToggle,
          "DD-MM-YYYY"
        ).unix(), // moment().startOf("day").unix(),
        endTime: moment().endOf("day").unix(),
        text: "",
      };
      var searchString = previousState?.text !== "" ? prevFilters.text : null;

      this.setState(
        {
          page: prevPage,
          limit: prevLimit,
          toggleValue: prevToggle,
          dateRangeStartDate:
            prevDateRangeStart !== null ? moment(prevDateRangeStart) : null,
          dateRangeEndDate:
            prevDateRangeEnd !== null ? moment(prevDateRangeEnd) : null,
          filters: prevFilters,
          searchStringParam: searchString,
        },
        () => {
          this.props.getReportListWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }

    //--------------------------------------------------------
    else if (isComingFromDoctorsList) {
      // Check Selected doctors data in redux, this will also contain date related filters, which we need to apply to this screen. (For SuperAdmin and HospitalAdmin)
      var selectedDoctorData = getSelectedDoctor();
      console.log("selectedDoctorData : ", JSON.stringify(selectedDoctorData));

      this.setState(
        {
          doctorId: selectedDoctorData?.id ?? null,
          doctorName: selectedDoctorData?.name ?? "Dr. - ",
          hospitalId: selectedDoctorData?.hospitalId ?? null,
          //
          toggleValue: selectedDoctorData.toggleValue,
          dateRangeStartDate:
            selectedDoctorData.dateRangeStartDate !== null
              ? moment(selectedDoctorData.dateRangeStartDate)
              : null,
          dateRangeEndDate:
            selectedDoctorData.dateRangeEndDate !== null
              ? moment(selectedDoctorData.dateRangeEndDate)
              : null,
          filters: {
            ...selectedDoctorData.filters,
            doctorProfileId: selectedDoctorData?.id ?? null,
            reportingHospitalId: selectedDoctorData?.hospitalId ?? null,
          },
          searchStringParam: null, // We do not need search value applied in this screen
        },
        () => {
          this.props.getReportListOfDoctorWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }

    //--------------------------------------------------------
    else if (isComingFromDeviceList) {
      // Check Selected device data in redux, this will also contain date related filters, which we need to apply to this screen. (For HospitalAdmin)
      var selectedDeviceData = getSelectedDevice();
      console.log("selectedDeviceData : ", JSON.stringify(selectedDeviceData));

      this.setState(
        {
          deviceId: selectedDeviceData?.id ?? null,
          deviceSerialNumber: selectedDeviceData?.serialNumber ?? "-",
          //
          filters: {
            ...this.state.filters,
            deviceId: selectedDeviceData?.id ?? null,
          },
        },
        () => {
          this.props.getReportListOfDeviceWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
  };

  saveThisScreensStateInRedux = () => {
    var savedPageStates = getReportScreensState();
    savedPageStates.reportListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);
  };

  callGetReportListApiForUserRole = () => {
    if (
      !this.state.isComingFromDoctorsList &&
      !this.state.isComingFromDeviceList
    ) {
      this.props.getReportListWithFilter(
        this.state.filters,
        (listData, count) => {
          this.populateListData(listData, count);
        },
        this.state.page,
        this.state.limit
      );
    }

    //--------------------------------------------------------
    else if (this.state.isComingFromDoctorsList) {
      this.props.getReportListOfDoctorWithFilter(
        this.state.filters,
        (listData, count) => {
          this.populateListData(listData, count);
        },
        this.state.page,
        this.state.limit
      );
    }

    //--------------------------------------------------------
    else if (this.state.isComingFromDeviceList) {
      this.props.getReportListOfDeviceWithFilter(
        this.state.filters,
        (listData, count) => {
          this.populateListData(listData, count);
        },
        this.state.page,
        this.state.limit
      );
    }
  };

  populateListData = (listData, count) => {
    // Set data in state variables
    this.setState({
      reportList: listData,
      totalReports: count,
    });

    this.forceUpdate();
  };

  // -------------- Pagination  ---------------

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalReports;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        reportIdsToDownload: [],
        clearSelectedRows: !this.state.clearSelectedRows,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportListApiForUserRole();
      }
    );
  };

  handlePageChange = (page) => {
    console.log("handlePageChange");
    this.setState(
      {
        page: page,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportListApiForUserRole();
      }
    );
  };

  handleSelectedRowsChange = (selectedRowsArray) => {
    // console.log("selectedRowsArray ::: ", selectedRowsArray);
    var reportIds = selectedRowsArray.map((item) => {
      return {
        id: parseInt(item.id),
        isReportGenerated: item.dtRecReplied == null ? false : true,
      };
    });

    // console.log("selected reportIds ::: ", reportIds);

    this.setState({
      reportIdsToDownload: reportIds,
      clearSelectedRows: this.state.clearSelectedRows,
    });
  };

  // -------------- Column Button clicks ---------------

  onViewReportClick = (reportId) => {
    console.log("On VIEW Report Click", reportId);

    var savedPageStates = getReportScreensState();
    savedPageStates.isComingFromDoctorsList = false;
    savedPageStates.isComingFromDeviceList = false;
    savedPageStates.reportListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);

    if (this.state.userRole === roles.doctor) {
      //history.push(`ecg_report_details/preview/${reportId}`);
      history.push(`ecg_report_details?id=${reportId}&preview=${true}`);
    }
  };

  onSupervisorViewReportClick = (reportId) => {
    console.log("On supervisor_view Report Click", reportId);

    var savedPageStates = getReportScreensState();
    savedPageStates.isComingFromDoctorsList = false;
    savedPageStates.isComingFromDeviceList = false;
    savedPageStates.reportListScreen = this.state;
    this.props.setReportScreensState(savedPageStates);

    if (this.state.userRole === roles.supervisor) {
      //history.push(`ecg_report_details/preview/${reportId}`);
      history.push(`ecg_report_details?id=${reportId}`);
    }
  };

  onPrintReportClick = (reportId) => {
    console.log("On PRINT Report Click ", reportId);
    this.props.ReportPrint(reportId);
  };

  onDownloadReportClick = (reportId) => {
    console.log("On DOWNLOAD Report Click ", reportId);
    this.props.ReportDownload(reportId);
  };

  onBulkReportsDownloadClick = () => {
    var selectedIds = this.state.reportIdsToDownload;

    var reportGeneratedIds = [];
    selectedIds.map((item) => {
      if (item.isReportGenerated) {
        reportGeneratedIds.push(parseInt(item.id));
      }
    });
    console.log("selectedIds : ", JSON.stringify(selectedIds));
    console.log("reportGeneratedIds : ", JSON.stringify(reportGeneratedIds));

    if (selectedIds.length === 0) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select at least one record for downloading",
      });
    }
    //
    else if (selectedIds.length > 5) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "You can select maximum five records for downloading",
      });
    }
    //
    else if (reportGeneratedIds.length < selectedIds.length) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select only those records for which report is generated",
      });
    }

    //
    else {
      var data = {
        reportIds: reportGeneratedIds,
      };
      this.props.ReportDownloadInBulk(data);
    }
  };

  onExcelExportClick = () => {
    var selectedIds = this.state.reportIdsToDownload;
    var reportIdsToExport = [];

    selectedIds.map((item) => {
      reportIdsToExport.push(parseInt(item.id));
    });
    if (selectedIds.length === 0) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "danger",
        msg: "Please select at least one record for Exporting",
      });
    } else {
      var data = {
        reportIds: reportIdsToExport,
      };
      this.props.ReportExportToExcelInBulk(data);
    }
  };

  // -------------- Filters changed ---------------

  onToggleChange = (toggleVal) => {
    var startDate = null;
    var endDate = null;

    if (toggleVal === "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }

    if (toggleVal === "lastSevenDays") {
      var startDay = moment().subtract(6, "days").format("DD-MM-YYYY");
      startDate = moment(startDay, "DD-MM-YYYY").unix();
      endDate = moment().endOf("day").unix();

      console.log("before 7 days: ", startDay, " , ", startDate);
    }

    // if (toggleVal === "month") {
    //   startDate = moment().startOf("month").unix();
    //   endDate = moment().endOf("month").unix();
    // }
    // if (toggleVal === "year") {
    //   startDate = moment().startOf("year").unix();
    //   endDate = moment().endOf("year").unix();
    // }

    this.setState(
      {
        page: 1,
        toggleValue: toggleVal,
        dateRangeStartDate: null,
        dateRangeEndDate: null,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportListApiForUserRole();
      }
    );
  };

  onDatesChange = (start, end) => {
    console.log("onDatesChange, ", start, ", ", end);

    var startDate = moment(start).startOf("day").unix();
    var endDate = moment(end).endOf("day").unix();

    this.setState(
      {
        page: 1,
        toggleValue: null,
        dateRangeStartDate: start,
        dateRangeEndDate: end,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportListApiForUserRole();
      }
    );
  };

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        {
          page: 1,
          filters: { ...this.state.filters, text },
          searchStringParam: null,
        },
        () => {
          this.saveThisScreensStateInRedux();
          this.callGetReportListApiForUserRole();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      {
        page: 1,
        filters: { ...this.state.filters, text: "" },
        searchStringParam: null,
      },
      () => {
        this.saveThisScreensStateInRedux();
        this.callGetReportListApiForUserRole();
      }
    );
  };

  // -------------- Back Navigation ---------------

  onDoctorNameBackClick = () => {
    // Send previous page's state for updating pagination on that screen
    history.push({
      pathname: "/ecg_reports_by_doctor",
      // state: this.state.prevState,
    });
  };

  onDeviceNameBackClick = () => {
    // Send previous page's state for updating pagination on that screen
    history.push({
      pathname: "/devices",
      // state: this.state.prevState,
    });
  };

  //sorting filters

  onECGReportListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    //
    let statusFilter = this.state.filters.status;
    let severityFilter = this.state.filters.severity;
    console.log(statusFilter, "values", severityFilter);
    if (column === "Severity") {
      severityFilter =
        filterOption === "Normal"
          ? 1
          : filterOption === "Mild"
          ? 2
          : filterOption === "Critical"
          ? 3
          : filterOption === "STEMI"
          ? 4
          : null;
    } else {
      statusFilter =
        filterOption === "Reported"
          ? "REPORTED"
          : filterOption === "Sent"
          ? "SENT"
          : filterOption === "Assigned"
          ? "ASSIGNED"
          : null;
    }
    if (
      !this.state.isComingFromDoctorsList &&
      !this.state.isComingFromDeviceList
    ) {
      this.setState(
        {
          ...this.state,
          filters: {
            ...this.state.filters,
            status: statusFilter,
            severity: parseInt(severityFilter),
          },
        },
        () => {
          this.props.getReportListWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
    //--------------------------------------------------------
    else if (this.state.isComingFromDoctorsList) {
      this.setState(
        {
          ...this.state,
          filters: {
            ...this.state.filters,
            status: statusFilter,
            severity: parseInt(severityFilter),
          },
        },
        () => {
          this.props.getReportListOfDoctorWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    } else if (this.state.isComingFromDeviceList) {
      this.setState(
        {
          ...this.state,
          filters: {
            ...this.state.filters,
            status: statusFilter,
            severity: parseInt(severityFilter),
          },
        },
        () => {
          this.props.getReportListOfDeviceWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
  };

  onECGReportListSortClick = (column) => {
    console.log(" sorting column name :", column);

    let columnName = "";
    if (column === "Record ID") {
      columnName = "reportId";
    } else if (column === "Device ID") {
      columnName = "deviceId";
    } else if (column === "Data Recorded On") {
      columnName = "dataRecordedOn";
    } else if (column === "Doctor Name") {
      columnName = "doctorName";
    } else if (column === "Hospital") {
      columnName = "hospitalName";
    } else if (column === "Report Submitted On") {
      columnName = "reportSubmittedOn";
    }
    //
    if (
      !this.state.isComingFromDoctorsList &&
      !this.state.isComingFromDeviceList
    ) {
      this.setState(
        {
          sortBy: columnName,
          order: this.state.listOrder ? "asc" : "desc",
          listOrder: !this.state.listOrder,
        },
        () => {
          this.props.getReportListWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
    //--------------------------------------------------------
    else if (this.state.isComingFromDoctorsList) {
      this.setState(
        {
          sortBy: columnName,
          order: this.state.listOrder ? "asc" : "desc",
          listOrder: !this.state.listOrder,
        },
        () => {
          this.props.getReportListOfDoctorWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
    //
    else if (this.state.isComingFromDeviceList) {
      this.setState(
        {
          sortBy: columnName,
          order: this.state.listOrder ? "asc" : "desc",
          listOrder: !this.state.listOrder,
        },
        () => {
          this.props.getReportListOfDeviceWithFilter(
            this.state.filters,
            (listData, count) => {
              this.populateListData(listData, count);
            },
            this.state.page,
            this.state.limit,
            this.state.sortBy,
            this.state.order
          );
        }
      );
    }
  };

  render() {
    return (
      <EcgReportsView
        // For showing Report List Columns as per user role
        userRole={this.state.userRole}
        isComingFromDoctorsList={this.state.isComingFromDoctorsList}
        isComingFromDeviceList={this.state.isComingFromDeviceList}
        //
        doctorId={this.state.doctorId}
        doctorName={this.state.doctorName}
        deviceId={this.state.deviceId}
        deviceSerialNumber={this.state.deviceSerialNumber}
        //
        reportList={this.state.reportList}
        totalReports={this.state.totalReports}
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        //
        onViewReportClick={this.onViewReportClick}
        onSupervisorViewReportClick={this.onSupervisorViewReportClick}
        onPrintReportClick={this.onPrintReportClick}
        onDownloadReportClick={this.onDownloadReportClick}
        //
        toggleValue={this.state.toggleValue}
        toggleData={this.state.toggleData}
        onToggleChange={this.onToggleChange}
        //
        dateRangeStartDate={this.state.dateRangeStartDate}
        dateRangeEndDate={this.state.dateRangeEndDate}
        onDatesChange={this.onDatesChange}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        searchStringParam={this.state.searchStringParam}
        //
        reportIdsToDownload={this.state.reportIdsToDownload}
        clearSelectedRows={this.state.clearSelectedRows}
        onBulkReportsDownloadClick={this.onBulkReportsDownloadClick}
        onExcelExportClick={this.onExcelExportClick}
        //
        onDoctorNameBackClick={this.onDoctorNameBackClick}
        onDeviceNameBackClick={this.onDeviceNameBackClick}
        //
        onECGReportListFilterClick={this.onECGReportListFilterClick}
        onECGReportListSortClick={this.onECGReportListSortClick}
      />
    );
  }
}

EcgReportsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
  };
};

export default connect(mapStateToProps, {
  getReportListWithFilter,
  getReportListOfDoctorWithFilter,
  getReportListOfDeviceWithFilter,
  ReportDownload,
  ReportDownloadInBulk,
  ReportPrint,
  setReportScreensState,
  showAlert,
  ReportExportToExcelInBulk,
})(EcgReportsContainer);
