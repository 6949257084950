import React from "react";
import PropTypes from "prop-types";
import { BiRadioCircle } from "react-icons/bi";
import "./RadioButtonStyles.scss";

const RadioButton = ({
  onChange,
  className = "",
  text,
  name,
  size,
  color,
  checked = false,
  subText,
  error,
  ...props
}) => {
  return (
    <label className={`radio_label className`}>
      <input
        type="radio"
        hidden={true}
        checked={checked}
        onChange={onChange}
        value={text}
        name={name}
        className="checkbox"
        {...props}
      />

      <div
        className={`iconWrapper ${size == "lg" ? "lg-radio-button" : ""} ${
          error ? "error" : ""
        }`}
      >
        <BiRadioCircle className="icon" color="white" size={30} />
      </div>

      <div className="textBlock">
        <p
          className={`text text-muted ${size == "lg" ? "lg-label" : ""} ${
            color == "light" ? "light-grey" : ""
          }`}
        >
          {text}
        </p>
        <p className="subText">{subText}</p>
      </div>
    </label>
  );
};

RadioButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default RadioButton;
