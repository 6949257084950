import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import * as validator from "../../../../../utils/validator";
import {
  trimFormData,
  evaluateCaseSourceDestination,
  getOngoingOutwardTransferId,
} from "../../../../../utils/common";
import {
  getCaseDetailsById,
  getTransferredDetailsById,
} from "../../../../../store/case/caseActions";
import CaseDetailsView from "./CaseDetailsView";
import { globalConstants } from "../../../../../constants/globalConstants";
import {
  getHospitalsListWithFilter,
  getHospitalsNearAnotherHospitals,
} from "../../../../../store/hospital/hospitalActions";
import { getAmbulanceListWithFilter } from "../../../../../store/ambulance/ambulanceActions";
import {
  addPatientInsurance,
  admitPatient,
  editPatient,
  editPatientInsurance,
  getPatientInsuranceDetailsById,
  transferPatientConfirmDetails,
  transferPatientAddVehicle,
  transferPatientAssignAmbulance,
  transferPatientAssignHospital,
  transferPatientUpdateVitals,
  initiateTransferProcess,
  deletePatientInsurance,
} from "../../../../../store/patient/patientActions";
import {
  closePatientCase,
  rejectPatientCase,
  addCaseNote,
  uploadCaseDocuments,
} from "../../../../../store/case/caseActions";
import { getDoctorsListWithFilter } from "../../../../../store/doctor/doctorActions";
import {
  uploadImage,
  deleteImage,
} from "../../../../../store/master/masterActions";
import { strings } from "../../../../../constants/stringConstants";
import moment from "moment";
import { history } from "../../../../../store/configure/configureStore";

class CaseDetailsContainer extends Component {
  validatorFieldsForAddPatient = {};
  validatorFieldsForTransferPatient = {};
  validatorFieldsForCloseCase = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForAddPatient["required"] = [];
    this.validatorFieldsForTransferPatient["required"] = [];
    this.validatorFieldsForCloseCase["required"] = ["caseClosedReason"];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      order: "",
      sortBy: "",
      activeStep: 0,
      prevState: {},
      clearSelectedRows: false,

      // Case Related Global Data
      caseId: null,
      caseDetailsInfo: {}, // -----> GetCaseDetailsById
      hospitalId: null, // ------> Case currently Assigned to which hospital
      caseStatus: null, // -----> Case's Current Status
      //
      isTransferredCase: false, // ------> Initial Assumption is that case is coming from technician!
      transferId: null, // ------> If this is a transfer case (coming from hospital), refer to this ID for calling GetTransferDetailsById
      trasnferCaseInfo: {}, // ------> GetTransferDetailsById
      isSource: false,
      //
      ongoingOutwardTransferId: null, // ------> This is a transfer initiate by this admin which is INCOMPLETE!
      ongoingOutwardTransferInfo: {}, // ------> This is a transfer initiate by this admin which is INCOMPLETE!
      //
      adminsHospitalIds: this.props.hospitalAdminsHospitalId, // ------> All hospital Ids for this Admin
      //
      caseUIType: null, // ------> Switch UI based on this

      // Popups
      showAddPatientPopup: false,
      showEditAdmitPatientPopup: false,
      showCloseCasePopup: false,
      showTransferCasePopup: false,
      showAddVehicleManuallyForm: false,
      showAddNotesView: false,
      showViewMorePopup: false,
      showTransferDetailsPopup: false,
      checkOnEditInsuranceCardImgPresent: false,
      checkOnEditVehicleImgPresent: false,
      showClosingNotesPopup: false,
      showAdmissionDetailsPopup: false,

      // Admit patient
      insuranceInfo: null,
      addPatientFields: {
        isInsurance: false,
        companyName: "",
        incuranceName: "",
        incuranceType: "",
        policyNumber: "",
        insuranceCardImage: "",
        policyStartDate: null,
        policyEndDate: null,
        relationWithInsured: null,
        bedNumber: "",
        assignedDoctor: "",
        admitNotes: "",
        insuranceCardImage: "",
      },
      isValidationsOn: false,
      disableInsuranceFormField: false,
      showInsuranceCardImagePopup: false,
      insuranceCardImages: [],
      mainInsuranceCardImage: null,
      patientRelWithInsuredData: [
        { value: 1, label: "Self" },
        { value: 2, label: "Spouse" },
        { value: 3, label: "Child" },
        { value: 4, label: "Other" },
      ],
      patientInsuranceData: [
        { value: 1, label: "Yes" },
        { value: 2, label: "No" },
      ],

      // Transfer patient
      transferPatientFields: {
        // Step 1 : Patient Vitals
        pulse: "",
        bloodPressure: "",
        rr: "",
        spo2: "",
        weight: "",
        bloodSugar: "",
        // Step 2 : Hosp
        assignedHospital: "",
        // Step 3 : Amb
        assignedAmbulance: "",
        // Step 3 : Manually adding vehicle
        vehicleName: "",
        vehicleNumber: "",
        vehicleType: "",
        vehicleImage: "",
        attendantFirstName: "",
        attendantLastName: "",
        attendantContact: "",
        // Step 4 : Final API
        doctorName: "",
        transferNotes: "",
        documents: [],
      },
      mainManualVehicleImage: "",
      manuallyAddedVehicleInfo: null,
      showAddVehicleImagePopup: false,
      vehicleTypeData: [
        { value: 1, label: "Ambulance" },
        { value: 2, label: "Others" },
      ],

      // Closing notes
      closeCaseFields: {
        caseAssignedDoctor: "",
        caseClosedReason: "",
        documents: [],
      },

      // Hospital list
      hospitalList: [],
      detailhospitalList: [],
      hospitalListPage: 1,
      noScrollHospital: false,
      pageCountHospital: 2,
      searchHospitalText: "",

      // Doctor list
      doctorList: [],
      detailDoctorList: [],
      doctorListPage: 1,
      noScrollDoctor: false,
      pageCountDoctor: 2,
      searchDoctorText: "",

      // Ambulance list
      ambulanceList: [],
      detailAmbulanceList: [],
      ambulanceListPage: 1,
      noScrollAmbulance: false,
      pageCountAmbulance: 2,
      searchAmbulanceText: "",

      // Plain Notes
      genericNotes: "",
      viewMoreData: {},
      errors: {},
    };

    this.callGetCaseDetailsById = this.callGetCaseDetailsById.bind(this);
    this.updateCaseUITypeSimplified =
      this.updateCaseUITypeSimplified.bind(this);
    this.callGetInsuranceDetailsByID =
      this.callGetInsuranceDetailsByID.bind(this);

    this.onAddNoteClick = this.onAddNoteClick.bind(this);
    this.onAddNoteConfirmClick = this.onAddNoteConfirmClick.bind(this);
    this.onCloseAddNoteUI = this.onCloseAddNoteUI.bind(this);

    this.onChangeGenericNotes = this.onChangeGenericNotes.bind(this);
    this.onCloseCaseFieldChange = this.onCloseCaseFieldChange.bind(this);
    this.onCloseAddPatientPopup = this.onCloseAddPatientPopup.bind(this);
    this.onAddPatientClick = this.onAddPatientClick.bind(this);
    this.onAddPatientConfirmClick = this.onAddPatientConfirmClick.bind(this);

    this.onAddPatientInsuranceConfirmClick =
      this.onAddPatientInsuranceConfirmClick.bind(this);

    this.deleteInsuranceCardImageClicked =
      this.deleteInsuranceCardImageClicked.bind(this);
    this.onHandleUploadInsuranceCardImage =
      this.onHandleUploadInsuranceCardImage.bind(this);

    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);

    this.onCloseCaseClick = this.onCloseCaseClick.bind(this);
    this.onCloseCaseConfirmClick = this.onCloseCaseConfirmClick.bind(this);
    this.onCloseCloseCasePopup = this.onCloseCloseCasePopup.bind(this);

    this.onAssignedHospitalChange = this.onAssignedHospitalChange.bind(this);
    this.onAssignedAmbulanceChange = this.onAssignedAmbulanceChange.bind(this);
    this.onScrollAmbulanceList = this.onScrollAmbulanceList.bind(this);
    this.onAmbulanceSearchChange = this.onAmbulanceSearchChange.bind(this);
    this.onHospitalSearchChange = this.onHospitalSearchChange.bind(this);
    this.onScrollHospitalList = this.onScrollHospitalList.bind(this);

    this.onAddVehicleManuallyClick = this.onAddVehicleManuallyClick.bind(this);
    this.onCloseAddVehicleManuallyForm =
      this.onCloseAddVehicleManuallyForm.bind(this);

    this.onHandleUploadManualVehicleImage =
      this.onHandleUploadManualVehicleImage.bind(this);
    this.deleteManualVehicleImageClicked =
      this.deleteManualVehicleImageClicked.bind(this);

    this.onDoctorSearchChange = this.onDoctorSearchChange.bind(this);
    this.onScrollDoctorList = this.onScrollDoctorList.bind(this);
    this.callGetDoctorListAPI = this.callGetDoctorListAPI.bind(this);
    this.onAssignedDoctorChange = this.onAssignedDoctorChange.bind(this);

    this.onViewMoreButtonClicked = this.onViewMoreButtonClicked.bind(this);
    this.hideViewMorePopup = this.hideViewMorePopup.bind(this);

    this.onTransferCaseClick = this.onTransferCaseClick.bind(this);
    this.onCloseTransferCasePopup = this.onCloseTransferCasePopup.bind(this);
    this.onTransferPatientFieldChange =
      this.onTransferPatientFieldChange.bind(this);

    this.onPatientTransferUpdateVitals =
      this.onPatientTransferUpdateVitals.bind(this);
    this.onPatientTransferAssignHospital =
      this.onPatientTransferAssignHospital.bind(this);
    this.onPatientTransferAssignAmbulance =
      this.onPatientTransferAssignAmbulance.bind(this);
    this.onPatientInsuranceButtonClicked =
      this.onPatientInsuranceButtonClicked.bind(this);
    this.onPatientTransferAddVehicle =
      this.onPatientTransferAddVehicle.bind(this);
    this.onPatientTransferConfirmDetails =
      this.onPatientTransferConfirmDetails.bind(this);

    this.onViewECGReportClick = this.onViewECGReportClick.bind(this);

    this.onUploadTransferPatientDocuments =
      this.onUploadTransferPatientDocuments.bind(this);

    this.onUploadCloseCaseDocuments =
      this.onUploadCloseCaseDocuments.bind(this);
    this.resetPatientFormField = this.resetPatientFormField.bind(this);

    this.navigateBack = this.navigateBack.bind(this);
    this.fillOutgoingTransferData = this.fillOutgoingTransferData.bind(this);

    this.viewInsuranceCardImageClicked =
      this.viewInsuranceCardImageClicked.bind(this);
    this.viewAddManuallyVehicleImageClicked =
      this.viewAddManuallyVehicleImageClicked.bind(this);
    this.closeViewImagePopup = this.closeViewImagePopup.bind(this);

    this.onTransferDetailsButtonClicked =
      this.onTransferDetailsButtonClicked.bind(this);
    this.hideTransferDetailsPopup = this.hideTransferDetailsPopup.bind(this);

    this.onClosingNotesButtonClicked =
      this.onClosingNotesButtonClicked.bind(this);
    this.hideClosingNotesPopup = this.hideClosingNotesPopup.bind(this);

    this.onCloseAdmissionDetailsPopup =
      this.onCloseAdmissionDetailsPopup.bind(this);
    this.onViewAdmissionDetailsClick =
      this.onViewAdmissionDetailsClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var caseObject = this.props.selectedCaseData;
    var previousState = this.props.location?.state ?? {};

    this.setState(
      {
        prevState: previousState,
        adminsHospitalIds: this.props.hospitalAdminsHospitalIds ?? [],
        caseId: previousState.caseId,
      },
      () => {
        this.callGetCaseDetailsById(previousState.caseId);
      }
    );
  }

  componentWillUnmount() {}

  navigateBack = () => {
    history.push({
      pathname: `/cases`,
      statisSource: this.state.prevState,
    });
  };

  // ------------------------- Data Initialization ----------------------------------

  callGetCaseDetailsById = (id) => {
    this.props.getCaseDetailsById(id, (caseData) => {
      console.log("CASE DATA :", caseData);

      var isTransferredCase =
        caseData?.linkedTransferId === null ||
        caseData?.linkedTransferId === undefined
          ? false
          : true;

      var hospitalId = isTransferredCase
        ? caseData?.linkedHospitalId
        : caseData?.assignHospital?.id;

      this.setState(
        {
          caseDetailsInfo: caseData,
          hospitalId: hospitalId,
          caseStatus: caseData.caseStatus,
          isTransferredCase: isTransferredCase,
        },
        () => {
          if (isTransferredCase) {
            this.props.getTransferredDetailsById(
              caseData?.linkedTransferId,
              (response) => {
                if (response) {
                  this.setState(
                    {
                      transferId: caseData?.linkedTransferId,
                      trasnferCaseInfo: response,
                    },
                    () => {
                      this.updateCaseUITypeSimplified();
                    }
                  );
                }
              }
            );
          } else {
            this.updateCaseUITypeSimplified();
          }
        }
      );

      /*------------------------------------------------------------------------------------------------
      // By default let's assume case is coming from Technician, and not a transferred case!
      var isTransferredCase = false;
      var hospitalId = caseData?.assignHospital?.id;

      this.setState({
        caseDetailsInfo: caseData,
        hospitalId: hospitalId,
        caseStatus: caseData.caseStatus,
        isTransferredCase: isTransferredCase,
      });

      //------------------------------------------------------------------------------------------------
      // Check if case is coming from Hospital / already sent to a hospital i.e. It is a transfer case!!
      if (caseData?.transferredCase?.length > 0) {
        var transferId = null;
        var isSource = false;
        var ongoingOutwardTransferId = null;

        // There are  transfer records, So first check if any record refers to incomplete outgoing transfer...
        var transferEntriesArray = caseData?.transferredCase ?? [];

        ongoingOutwardTransferId = getOngoingOutwardTransferId(
          transferEntriesArray,
          this.state.adminsHospitalIds
        );

        console.log("ongoingOutwardTransferId", ongoingOutwardTransferId);

        if (ongoingOutwardTransferId !== null) {
          this.setState({ ongoingOutwardTransferId: ongoingOutwardTransferId });

          // We found one outgoing transfer id in transferEntriesArray,
          // So, remove that transfer entry from this array for further processing
          var itemIndex = transferEntriesArray.findIndex((item) => {
            if (item.id === ongoingOutwardTransferId) {
              return true;
            } else {
              return false;
            }
          });

          if (itemIndex !== -1) {
            transferEntriesArray.splice(itemIndex, 1);
          }
        }

        // If there was an entry for outgoing transfer, we have removed it
        // Now let's check again the transfer array........

        if (transferEntriesArray.length > 0) {
          // If there are still transfer entries remaining in array,
          // means this case is definitly a transferred case (Coming from Hospital / Sent to a Hospital)
          isTransferredCase = true;

          // Now let's parse remaining transfer array to find out which hospital case is assigned to and if that hosp is source or destination
          let responseData = evaluateCaseSourceDestination(
            transferEntriesArray,
            this.state.adminsHospitalIds
          );


          transferId = responseData?.transferId;
          hospitalId = responseData?.isSource
            ? responseData?.sourceId
            : responseData?.destinationId;
          isSource = responseData?.isSource;

          if (responseData !== null) {
            this.setState({
              hospitalId: hospitalId,
              isTransferredCase: isTransferredCase,
              transferId: transferId,
              isSource: isSource,
            });

            this.props.getTransferredDetailsById(transferId, (response) => {
              if (response) {
                this.setState({ trasnferCaseInfo: response }, () => {
                  this.updateCaseUIType(
                    caseData,
                    hospitalId,
                    isTransferredCase,
                    response,
                    isSource
                  );
                });
              }
            });
          } else {
            // If couldn't find any record, we already have set state variables assuming case coming from technician!
            this.updateCaseUIType(caseData, hospitalId, isTransferredCase);
          }
        } else {
          // Do nothing, we already have set state variables assuming case coming from technician!
          this.updateCaseUIType(caseData, hospitalId, isTransferredCase);
        }
      } else {
        // Update UI for case coming from technician!
        this.updateCaseUIType(caseData, hospitalId, isTransferredCase);
      }

      */

      //---------------------------------------------------------------
      // Now we have case details all set, so fetch extra details needed...
      this.setState(
        {
          addPatientFields: {
            ...this.state.addPatientFields,
            isInsurance: caseData?.insuranceId ? true : false,
          },
        },
        () => {
          if (caseData?.insuranceId) {
            this.callGetInsuranceDetailsByID(caseData?.id);
          } else {
            this.setState({
              insuranceInfo: null,
            });
          }
        }
      );
    });
  };

  /* updateCaseUIType - Not used anymore!
 
 updateCaseUIType = (
    caseData,
    caseAssignedToHospitalId,
    isTransferredCase,
    transferInfo = null,
    isSourceInTransfer = false
  ) => {
    console.log(
      "updateCaseUIType : 1 : ",
      isTransferredCase,
      "--",
      caseAssignedToHospitalId,
      "--",
      isSourceInTransfer
    );

    console.log("STATE : ", this.state);

    const isComingFromTechnician = !isTransferredCase;
    var caseStatus = caseData.caseStatus;
    var transferStatus = null;
    var isSource = isSourceInTransfer;
    var isDestination = !isSourceInTransfer;
    var uiType = null;

    if (isTransferredCase && transferInfo != null) {
      transferStatus = transferInfo.status;
    }
    console.log(isDestination, "transferInfo", transferInfo);

    console.log(
      "updateCaseUIType : 2 : ",
      isComingFromTechnician,
      "--",
      caseStatus,
      "--",
      transferStatus
    );

    //----------------------------------------------------------
    if (caseStatus === globalConstants.CASE_STATUS_CLOSED) {
      uiType = globalConstants.CASE_UI_FOR_CLOSED;
    }

    //----------------------------------------------------------
    else if (
      isComingFromTechnician &&
      (caseStatus == globalConstants.CASE_STATUS_FORWARDED ||
        caseStatus == globalConstants.CASE_STATUS_HOSPITAL_ASSIGNED ||
        caseStatus == globalConstants.CASE_STATUS_AMBULANCE_ASSIGNED)
    ) {
      uiType = globalConstants.CASE_UI_FOR_IN_TRANSIT;

      // Check if ride is arrived
      if (caseData?.rideInfo?.status == globalConstants.RIDE_ENDED) {
        uiType = globalConstants.CASE_UI_FOR_ARRIVED;
      }

      // Check if patient is admitted to this hospital or is again transferred ahead.
      const hospitalActivityObject =
        caseData?.hospitalCaseActivity?.length > 0
          ? caseData?.hospitalCaseActivity.find(
              (item) => item?.hospitalId == caseAssignedToHospitalId
            )
          : null;

      if (hospitalActivityObject !== null) {
        console.log("Hospital Activity Object ::: ", hospitalActivityObject);
        let hospitalStatus = hospitalActivityObject?.hospitalActivityStatus;

        if (hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_ADMITTED) {
          uiType = globalConstants.CASE_UI_FOR_ADMIT;
        } else if (
          hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_TRANSFERRED
        ) {
          uiType = globalConstants.CASE_UI_FOR_TRANSFERRED;
        }
      }
    }

    //----------------------------------------------------------
    else if (
      isComingFromTechnician &&
      (caseStatus === globalConstants.CASE_STATUS_ADMITTED ||
        caseStatus === globalConstants.CASE_STATUS_AWAITING_TRANSFER)
    ) {
      uiType = globalConstants.CASE_UI_FOR_ADMIT;
    }

    //----------------------------------------------------------
    else if (
      isTransferredCase &&
      isSource &&
      transferStatus >= globalConstants.TRANSFER_DETAILS_CONFIRM
    ) {
      uiType = globalConstants.CASE_UI_FOR_TRANSFERRED;
    }

    //----------------------------------------------------------
    else if (
      isTransferredCase &&
      isDestination &&
      transferStatus <= globalConstants.TRANSFER_DETAILS_CONFIRM
    ) {
      uiType = globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER;
    }

    //----------------------------------------------------------
    else if (
      isTransferredCase &&
      isDestination &&
      transferStatus === globalConstants.TRANSFER_ACCEPTED_BY_DEST
    ) {
      // Case Accepted by hospital and afte this transfer entries status is never updated any further
      // Byh default let's show the case as 'IN TRANSIT TRANSFER'
      uiType = globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER;

      // Here check case Arrived or not
      if (transferInfo?.rideInfo?.status == globalConstants.RIDE_ENDED) {
        uiType = globalConstants.CASE_UI_FOR_ARRIVED;
      }

      // Now check if patient is admitted to this destination hospital or not.
      console.log("Hospital Activity Arr ::: ", caseData?.hospitalCaseActivity);

      const hospitalActivityObject =
        caseData?.hospitalCaseActivity?.length > 0
          ? caseData?.hospitalCaseActivity.find(
              (item) => item?.hospitalId == caseAssignedToHospitalId
            )
          : null;

      if (hospitalActivityObject !== null) {
        console.log("Hospital Activity Object ::: ", hospitalActivityObject);
        let hospitalStatus = hospitalActivityObject?.hospitalActivityStatus;

        if (hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_ADMITTED) {
          uiType = globalConstants.CASE_UI_FOR_ADMIT;
          console.log("11111");
        } else if (
          hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_TRANSFERRED
        ) {
          uiType = globalConstants.CASE_UI_FOR_TRANSFERRED;
        }
      }
    }

    this.setState({ caseUIType: uiType });
  };

  */

  updateCaseUITypeSimplified = () => {
    console.log("updateCaseUITypeSimplified :: ");
    console.log("STATE : ", this.state);

    const caseData = this.state.caseDetailsInfo;
    const transferInfo = this.state.trasnferCaseInfo;

    var displayStatus = caseData.displayStatus;
    var uiType = null;

    //----------------------------------------------------------
    if (displayStatus === "Closed") {
      uiType = globalConstants.CASE_UI_FOR_CLOSED;
    }

    //----------------------------------------------------------
    else if (displayStatus == "In Transit") {
      uiType = globalConstants.CASE_UI_FOR_IN_TRANSIT;

      // Check if ride is arrived
      if (caseData?.rideInfo?.status == globalConstants.RIDE_ENDED) {
        uiType = globalConstants.CASE_UI_FOR_ARRIVED;
      }
    }

    //----------------------------------------------------------
    if (displayStatus === "In Transit (Transfer)") {
      uiType = globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER;

      // Here check case Arrived or not
      if (transferInfo?.rideInfo?.status == globalConstants.RIDE_ENDED) {
        uiType = globalConstants.CASE_UI_FOR_ARRIVED;
      }
    }

    //----------------------------------------------------------
    if (displayStatus === "Admit") {
      uiType = globalConstants.CASE_UI_FOR_ADMIT;
    }

    //----------------------------------------------------------
    else if (displayStatus === "Transferred") {
      uiType = globalConstants.CASE_UI_FOR_TRANSFERRED;
    }

    //----------------------------------------------------------

    console.log("caseUIType :: ", uiType);
    this.setState({ caseUIType: uiType });
  };

  callGetInsuranceDetailsByID = (incurenceID) => {
    this.props.getPatientInsuranceDetailsById(incurenceID, (insuranceData) => {
      console.log("insuranceDetails", insuranceData);
      if (insuranceData) {
        const relationWithInsuredObj =
          this.state.patientRelWithInsuredData.find(
            (item) => item.value == insuranceData?.patientToInsured
          );
        this.setState({
          insuranceInfo: insuranceData !== null ? insuranceData : null,
          checkOnEditInsuranceCardImgPresent: insuranceData?.insuranceCard
            ? true
            : false,
          // mainInsuranceCardImage: insuranceData?.insuranceCard
          //   ? insuranceData?.insuranceCard
          //   : null,
          disableInsuranceFormField: insuranceData !== null ? false : true,
          addPatientFields: {
            ...this.state.addPatientFields,
            isInsurance: insuranceData !== null ? true : false,
            companyName: insuranceData?.companyName ?? "",
            incuranceName: insuranceData?.insuranceName ?? "",
            incuranceType: insuranceData?.insuranceType ?? "",
            policyNumber: insuranceData?.policyNumber ?? "",
            insuranceCardImage: insuranceData?.insuranceCard,
            policyStartDate: insuranceData?.policyStartDate ?? null,
            policyEndDate: insuranceData?.policyEndDate ?? null,
            relationWithInsured: relationWithInsuredObj
              ? relationWithInsuredObj
              : null,
            bedNumber: "",
            assignedDoctor: "",
            admitNotes: "",
          },
        });
      }
    });
  };

  fillOutgoingTransferData = (responseData) => {
    console.log("fillOutgoingTransferData : responseData", responseData);

    const hospitalName = responseData?.transferredToHospital
      ? {
          label: responseData?.transferredToHospital?.hospitalName,
          value: responseData?.transferredToHospital?.id,
        }
      : "";

    const ambulanceName = responseData?.assignAmbulance
      ? {
          label: responseData?.assignAmbulance?.ambulanceName,
          value: responseData?.assignAmbulance?.id,
        }
      : "";

    const vtype =
      responseData?.assignedVehicle?.vehicleType == 1
        ? this.state.vehicleTypeData[0]
        : this.state.vehicleTypeData[1];

    this.setState({
      transferPatientFields: {
        ...this.state.transferPatientFields,
        pulse: responseData?.transferCaseVitals?.pulse ?? "",
        bloodPressure: responseData?.transferCaseVitals?.bp ?? "",
        rr: responseData?.transferCaseVitals?.rr ?? "",
        spo2: responseData?.transferCaseVitals?.spo2 ?? "",
        weight: responseData?.transferCaseVitals?.weight ?? "",
        bloodSugar: responseData?.transferCaseVitals?.bloodSugar ?? "",
        //
        assignedHospital: hospitalName,
        assignedAmbulance: ambulanceName,
        //

        vehicleName: responseData?.assignedVehicle?.vehicleName ?? "",
        vehicleNumber:
          responseData?.assignedVehicle?.vehicleRegistrationNumber ?? "",
        vehicleType: vtype,
        vehicleImage: responseData?.assignedVehicle?.vehicleImage ?? null,
        attendantFirstName:
          responseData?.assignedVehicle?.attendantFirstName ?? "",
        attendantLastName:
          responseData?.assignedVehicle?.attendantLastName ?? "",
        attendantContact:
          responseData?.assignedVehicle?.attendantContactNumber ?? "",
      },
      manuallyAddedVehicleInfo: responseData?.assignedVehicle ?? null,
    });

    //----------------------------------------------------------

    var activeStep = 0;
    if (responseData?.transferCaseVitals) {
      activeStep = 1;
    }
    //
    if (responseData?.transferredToHospital !== null) {
      activeStep = 2;
    }
    //
    if (responseData?.assignAmbulance !== null) {
      activeStep = 3;
      this.setState({ showAddVehicleManuallyForm: false });
    }
    //
    if (responseData?.assignedVehicle != null) {
      activeStep = 3;
      this.setState({ showAddVehicleManuallyForm: true });
    }
    this.setState({ activeStep: activeStep });
  };

  // ----------------- hospital ---------------

  onHospitalSearchChange = (text) => {
    this.setState(
      {
        searchHospitalText: text,
        pageCountHospital: 2,
        noScrollHospital: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  callGetHospitalListAPI = () => {
    this.props.getHospitalsListWithFilter(
      {
        text: this.state.searchHospitalText,
      },
      (list, count, metaData) => {
        const newList = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({ hospitalList: newList, detailhospitalList: list });
      },
      this.state.hospitalListPage,
      25
    );
  };

  callGetNearByHospitalListAPI = () => {
    this.props.getHospitalsNearAnotherHospitals(
      {
        hospitalId: this.state.hospitalId,
      },
      (list, count) => {
        const newList = list.map((item) => {
          return { label: item.hospital_name, value: item.id };
        });
        this.setState({ hospitalList: newList, detailhospitalList: list });
      }
    );
  };

  onScrollHospitalList = () => {
    if (!this.state.noScrollHospital) {
      this.props.getHospitalsListWithFilter(
        {
          text: this.state.searchHospitalText,
        },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              return { label: item.hospitalName, value: item.id };
            });
            this.setState({
              hospitalList: [...this.state.hospitalList, ...listData],
              detailhospitalList: [...this.state.detailhospitalList, ...list],
            });
            ++this.state.pageCountHospital;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCountHospital, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onAssignedHospitalChange = (value) => {
    if (value !== null && value !== undefined && value !== "") {
      this.setState({
        transferPatientFields: {
          ...this.state.transferPatientFields,
          assignedHospital: value,
        },
        searchHospitalText: "",
      });
      if (this.state.isValidationsOn) {
        this.state.errors["assignedHospital"] = "";
      }
    } else {
      this.setState(
        {
          transferPatientFields: {
            ...this.state.transferPatientFields,
            assignedHospital: "",
          },
          searchHospitalText: "",
          noScrollHospital: false,
        },
        () => {
          this.callGetNearByHospitalListAPI();
          if (this.state.isValidationsOn) {
            this.state.errors["assignedHospital"] = "Field can not be empty";
          }
        }
      );
    }
  };

  // ------------------------- doctor --------------------

  onDoctorSearchChange = (text) => {
    this.setState(
      {
        searchDoctorText: text,
        pageCountDoctor: 2,
        noScrollDoctor: false,
      },
      () => {
        this.callGetDoctorListAPI();
      }
    );
  };

  callGetDoctorListAPI = () => {
    this.props.getDoctorsListWithFilter(
      {
        text: this.state.searchDoctorText,
        hospitalId: this.state.hospitalId,
      },
      (list, count) => {
        const newList = list.map((item) => {
          return {
            label: `${
              (item?.firstName !== null && item?.firstName !== undefined
                ? `${item?.firstName
                    .charAt(0)
                    .toUpperCase()}${item?.firstName.slice(1)}`
                : "") +
              " " +
              (item?.lastName !== null && item?.lastName !== undefined
                ? `${item?.lastName
                    .charAt(0)
                    .toUpperCase()}${item?.lastName.slice(1)}`
                : "")
            }`,
            value: item.id,
          };
        });
        this.setState({
          doctorList: newList,
          detailDoctorList: list,
          pageCountDoctor: 2,
        });
      },
      this.state.doctorListPage,
      25
    );
  };

  onScrollDoctorList = () => {
    if (!this.state.noScrollDoctor) {
      this.props.getDoctorsListWithFilter(
        {
          text: this.state.searchDoctorText,
        },
        (list, count) => {
          if (list.length > 0) {
            const newList = list.map((item) => {
              return {
                label: `${
                  (item?.firstName !== null && item?.firstName !== undefined
                    ? `${item?.firstName
                        .charAt(0)
                        .toUpperCase()}${item?.firstName.slice(1)}`
                    : "") +
                  " " +
                  (item?.lastName !== null && item?.lastName !== undefined
                    ? `${item?.lastName
                        .charAt(0)
                        .toUpperCase()}${item?.lastName.slice(1)}`
                    : "")
                }`,
                value: item.id,
              };
            });
            this.setState({
              doctorList: [...this.state.doctorList, ...newList],
              detailDoctorList: [...this.state.detailDoctorList, ...list],
            });
            ++this.state.pageCountDoctor;
          } else {
            this.setState({ noScrollDoctor: true });
          }
        },
        this.state.pageCountDoctor, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onAssignedDoctorChange = (Object) => {
    if (Object !== null && Object !== undefined && Object !== "") {
      this.setState({
        addPatientFields: {
          ...this.state.addPatientFields,
          assignedDoctor: Object,
        },
        searchDoctorText: "",
      });
      if (this.state.isValidationsOn) {
        this.state.errors["assignedDoctor"] = "";
      }
    } else {
      this.setState(
        {
          addPatientFields: {
            ...this.state.addPatientFields,
            assignedDoctor: "",
          },
          searchDoctorText: "",
          noScrollDoctor: false,
        },
        () => {
          this.callGetDoctorListAPI();
          if (this.state.isValidationsOn) {
            this.state.errors["assignedDoctor"] = "Field can not be empty";
          }
        }
      );
    }
  };

  // ----------------- ambulance ---------------

  onAmbulanceSearchChange = (text) => {
    this.setState(
      {
        searchAmbulanceText: text,
        pageCountAmbulance: 2,
        noScrollAmbulance: false,
      },
      () => {
        this.callGetAmbulanceListAPI();
      }
    );
  };

  callGetAmbulanceListAPI = () => {
    this.props.getAmbulanceListWithFilter(
      {
        text: this.state.searchAmbulanceText,
      },
      (list, totalPages, count) => {
        const newList = list.map((item) => {
          return { label: item.ambulanceName, value: item.id };
        });
        this.setState({ ambulanceList: newList, detailAmbulanceList: list });
      },
      this.state.ambulanceListPage,
      25
    );
  };

  onScrollAmbulanceList = () => {
    if (!this.state.noScrollAmbulance) {
      this.props.getAmbulanceListWithFilter(
        {
          text: this.state.searchAmbulanceText,
        },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              if (this.state.pushedAmbulanceId !== item.id) {
                return { label: item.AmbulanceName, value: item.id };
              }
            });
            const newListData = listData.filter(function (item) {
              return item !== undefined;
            });
            this.setState({
              ambulanceList: [...this.state.ambulanceList, ...newListData],
              detailambulanceList: [...this.state.detailambulanceList, ...list],
            });
            ++this.state.pageCountAmbulance;
          } else {
            this.setState({ noScrollAmbulance: true });
          }
        },
        this.state.pageCountAmbulance, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onAssignedAmbulanceChange = (Object) => {
    if (Object !== null && Object !== undefined) {
      this.setState({
        transferPatientFields: {
          ...this.state.transferPatientFields,
          assignedAmbulance: Object,
          //
          vehicleName: "",
          vehicleNumber: "",
          vehicleType: "",
          vehicleImage: "",
          attendantContact: "",
          attendantFirstName: "",
          attendantLastName: "",
        },
        searchAmbulanceText: "",
        checkOnEditVehicleImgPresent: false,
        showAddVehicleManuallyForm: false,
      });
      if (this.state.isValidationsOn) {
        this.state.errors["assignedAmbulance"] = "";
      }
      this.validatorFieldsForTransferPatient["required"] = [];
      this.validatorFieldsForTransferPatient["custom"] = [];
    } else {
      this.setState(
        {
          transferPatientFields: {
            ...this.state.transferPatientFields,
            assignedAmbulance: "",
          },
          checkOnEditVehicleImgPresent: false,
          searchAmbulanceText: "",
          noScrollDoctor: false,
        },
        () => {
          this.callGetAmbulanceListAPI();
          if (this.state.isValidationsOn) {
            this.state.errors["assignedAmbulance"] = "Field can not be empty";
          }
        }
      );
    }
  };

  //---------------------------- patient section ------------------------

  resetPatientFormField = () => {
    this.setState({
      isValidationsOn: false,
      errors: {},

      activeStep: 0,
      clearSelectedRows: false,
      // caseDetailsInfo: {},
      //
      showAddPatientPopup: false,
      showEditAdmitPatientPopup: false,
      showCloseCasePopup: false,
      showTransferCasePopup: false,
      showAddNotesView: false,
      addPatientFields: {
        isInsurance: false,
        companyName: "",
        incuranceName: "",
        incuranceType: "",
        policyNumber: "",
        insuranceCardImage: "",
        policyStartDate: null,
        policyEndDate: null,
        relationWithInsured: null,
      },

      //hospital
      searchDoctorText: "",
      pageCountHospital: 2,
      pageCountDoctor: 2,
      noScrollHospital: false,
      noScrollDoctor: false,
    });
  };

  resetTransferPatientFormField = () => {
    this.setState({
      ...this.state,
      transferPatientFields: {
        ...this.state.transferPatientFields,
        pulse: "",
        bloodPressure: "",
        rr: "",
        spo2: "",
        weight: "",
        bloodSugar: "",
        assignedHospital: "",
        assignedAmbulance: "",
        doctorName: "",
        transferNotes: "",
        documents: [],

        //manually adding vehicle
        vehicleName: "",
        vehicleNumber: "",
        vehicleType: "",
        vehicleImage: "",
        attendantFirstName: "",
        attendantLastName: "",
        attendantContact: "",
      },
      showTransferCasePopup: false,
      errors: {},
      activeStep: 0,

      ambulanceListPage: 1,
      pageCountAmbulance: 2,
      searchAmbulanceText: "",
      noScrollAmbulance: false,

      hospitalListPage: 1,
      pageCountHospital: 2,
      searchHospitalText: "",
      noScrollHospital: false,
      //
    });
  };

  //-------------------------- Admit Patient  ------------------------

  onAddPatientClick = () => {
    if (this.state.caseDetailsInfo?.insuranceId) {
      this.callGetInsuranceDetailsByID(this.state.caseId);
      this.setState(
        {
          showEditAdmitPatientPopup: true,
          showAddPatientPopup: true,
        },
        () => {
          this.callGetDoctorListAPI();
        }
      );
    } else {
      this.setState(
        {
          showAddPatientPopup: true,
          disableInsuranceFormField: true,
        },
        () => {
          this.callGetDoctorListAPI();
        }
      );
    }
  };

  onAddPatientFieldChange = (field) => (e) => {
    let value;
    const { addPatientFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired = this.validatorFieldsForAddPatient.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    } else if (
      field === "relationWithInsured" ||
      field === "policyStartDate" ||
      field === "policyEndDate"
    ) {
      if (e === null || e.value === "" || e === "") {
        this.state.errors[field] = "Field can not be empty";
      } else {
        this.state.errors[field] = "";
        value = e;
      }
      if (e?.label == "No") {
        this.state.errors = {};
      }
    }
    this.setState({
      ...this.state,
      addPatientFields: { ...addPatientFields, [field]: value },
    });
  };

  onPatientInsuranceButtonClicked = (e) => {
    console.log("onPatientInsuranceButtonClicked", e);
    if (e?.value == 1) {
      this.setState({
        addPatientFields: {
          ...this.state.addPatientFields,
          isInsurance: true,
        },
        disableInsuranceFormField: false,
      });
    } else {
      this.setState({
        addPatientFields: {
          ...this.state.addPatientFields,
          isInsurance: false,
          companyName: "",
          incuranceName: "",
          incuranceType: "",
          policyNumber: "",
          insuranceCardImage: "",
          policyStartDate: null,
          policyEndDate: null,
          relationWithInsured: null,
        },
        disableInsuranceFormField: true,
        errors: {},
      });
    }
  };

  onAddPatientInsuranceConfirmClick = () => {
    const custom = [];
    this.validatorFieldsForAddPatient["custom"] = custom;
    const { addPatientFields } = this.state;

    if (this.validatorFieldsForAddPatient.required.includes("bedNumber")) {
      this.validatorFieldsForAddPatient["required"] =
        this.validatorFieldsForAddPatient["required"].filter(
          (item) => item !== "bedNumber" && item !== "assignedDoctor"
        );
    }
    if (this.state.addPatientFields.isInsurance) {
      this.validatorFieldsForAddPatient["required"] = [
        ...this.validatorFieldsForAddPatient["required"],
        "isInsurance",
        "companyName",
        "incuranceName",
        "incuranceType",
        "policyNumber",
        "policyStartDate",
        "policyEndDate",
        "relationWithInsured",
        "insuranceCardImage",
      ];

      const { errors } = validator.validate(
        this.validatorFieldsForAddPatient,
        trimFormData(addPatientFields)
      );

      console.log("addPatientInsurance :: errors", errors);
      this.setState({ ...this.state, errors, isValidationsOn: true });

      if (!Object.keys(errors).length) {
        const data = {
          stemiCaseId: this.state.caseDetailsInfo.id,
          companyName: addPatientFields.companyName?.trim() ?? "",
          insuranceType: addPatientFields.incuranceType,
          insuranceName: addPatientFields.incuranceName?.trim() ?? "",
          policyNumber: addPatientFields.policyNumber,
          policyStartDate:
            addPatientFields.policyStartDate !== null
              ? moment(addPatientFields.policyStartDate).format("YYYY-MM-DD")
              : null,
          policyEndDate:
            addPatientFields.policyEndDate !== null
              ? moment(addPatientFields.policyEndDate).format("YYYY-MM-DD")
              : null,
          patientToInsured: addPatientFields.relationWithInsured?.value ?? null,
        };
        // API call
        // check Insurance selection on yes/no type

        this.props.addPatientInsurance(data, (response) => {
          if (response) {
            if (this.state.mainInsuranceCardImage) {
              this.props.uploadImage(
                this.state.mainInsuranceCardImage,
                globalConstants.INSURANCE_CARD_IMAGE,
                (imageUrl) => {
                  if (imageUrl !== null) {
                    this.handleNext();
                    this.callGetCaseDetailsById(this.state.caseDetailsInfo.id);
                  }
                },
                response.id,
                false
              );
            } else {
              this.handleNext();
              this.callGetCaseDetailsById(this.state.caseDetailsInfo.id);
            }
          }
        });
      }
    } else {
      if (this.state.caseDetailsInfo?.insuranceId) {
        this.props.deletePatientInsurance(
          this.state.caseDetailsInfo?.id ?? null,
          (response) => {
            if (response) {
              this.validatorFieldsForAddPatient["required"] = [];
              this.handleNext();
              this.callGetCaseDetailsById(this.state.caseDetailsInfo.id);
            }
          }
        );
      } else {
        this.validatorFieldsForAddPatient["required"] = [];
        this.handleNext();
        this.callGetCaseDetailsById(this.state.caseDetailsInfo.id);
      }
    }
  };

  onAddPatientConfirmClick = () => {
    if (this.validatorFieldsForAddPatient.required.includes("companyName")) {
      const reqFields = ["bedNumber", "assignedDoctor"];
      this.validatorFieldsForAddPatient["required"] = reqFields;
    } else {
      this.validatorFieldsForAddPatient["required"] = [
        ...this.validatorFieldsForAddPatient["required"],
        "bedNumber",
        "assignedDoctor",
      ];
    }

    const { addPatientFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForAddPatient,
      trimFormData(addPatientFields)
    );

    console.log("errors", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        hospitalId: this.state.hospitalId,
        assignedDoctorId: addPatientFields.assignedDoctor?.value,
        bedNumber: addPatientFields.bedNumber,
        note: addPatientFields.admitNotes,
      };

      // API call
      this.props.admitPatient(this.state.caseId, data, (response) => {
        this.resetPatientFormField();
        this.callGetDoctorListAPI();
        this.callGetCaseDetailsById(this.state.caseId);
      });
    }
  };

  onCloseAddPatientPopup = () => {
    this.setState(
      {
        showAddPatientPopup: false,
        disableInsuranceFormField: false,
        activeStep: 0,
      },
      () => {
        this.resetPatientFormField();
        this.callGetDoctorListAPI();
      }
    );
  };

  //------------------- close case --------------------

  onCloseCaseFieldChange = (field) => (e) => {
    let value;
    const { closeCaseFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired = this.validatorFieldsForCloseCase.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    } else if (field === "documents") {
      if (e?.length > 0) {
        value = e;
      } else {
        value = [];
      }
    }
    this.setState({
      ...this.state,
      closeCaseFields: { ...closeCaseFields, [field]: value },
    });
  };

  onCloseCaseClick = () => {
    const admissionDetails =
      this.state.caseDetailsInfo?.hospitalCaseActivity?.length > 0
        ? this.state.caseDetailsInfo?.hospitalCaseActivity.find(
            (item) => item?.hospitalId == this.state.hospitalId
          )
        : null;

    const doctorInfo = admissionDetails?.doctorInfo ?? null;

    const assignedDoctorName =
      doctorInfo != null && doctorInfo != undefined
        ? `Dr. ${
            (doctorInfo?.firstName !== null &&
            doctorInfo?.firstName !== undefined
              ? `${doctorInfo?.firstName
                  .charAt(0)
                  .toUpperCase()}${doctorInfo?.firstName.slice(1)}`
              : "") +
            " " +
            (doctorInfo?.lastName !== null && doctorInfo?.lastName !== undefined
              ? `${doctorInfo?.lastName
                  .charAt(0)
                  .toUpperCase()}${doctorInfo?.lastName.slice(1)}`
              : "")
          }`
        : "-";
    this.setState({
      showCloseCasePopup: true,
      isValidationsOn: true,
      closeCaseFields: {
        ...this.state.closeCaseFields,
        caseAssignedDoctor: assignedDoctorName,
      },
    });
  };

  onCloseCaseConfirmClick = () => {
    const custom = [];
    this.validatorFieldsForCloseCase["custom"] = custom;
    const { closeCaseFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForCloseCase,
      trimFormData(closeCaseFields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        caseClosedReason: this.state.closeCaseFields.caseClosedReason,
      };
      console.log("this.state.caseDetailsInfo", this.state.caseDetailsInfo);
      this.props.closePatientCase(
        this.state.caseDetailsInfo.id,
        data,
        (response) => {
          if (response) {
            if (this.state.closeCaseFields.documents?.length > 0) {
              let flag = false;
              let docs = this.state.closeCaseFields.documents;
              for (let i = 0; i < docs.length; i++) {
                this.props.uploadCaseDocuments(
                  this.state.caseId,
                  3,
                  docs[i],
                  (response) => {
                    if (response == null) {
                      flag = true;
                    }
                  }
                );
                if (flag) {
                  break;
                }
              }
            }
            this.setState(
              {
                showCloseCasePopup: false,
              },
              () => {
                history.push("/cases");
              }
            );
          }
        }
      );
    }
  };

  onCloseCloseCasePopup = () => {
    this.resetPatientFormField();
    this.setState({ showCloseCasePopup: false, activeStep: 0 });
  };

  //------------------- Transfer case --------------------

  onTransferPatientFieldChange = (field) => (e) => {
    let value;
    const { transferPatientFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired =
      this.validatorFieldsForTransferPatient.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    } else if (field === "vehicleType") {
      if (e === null || e.value === "" || e === "") {
        this.state.errors[field] = "Field can not be empty";
      } else {
        this.state.errors[field] = "";
        value = e;
      }
    }
    this.setState({
      ...this.state,
      transferPatientFields: { ...transferPatientFields, [field]: value },
    });
  };

  onTransferCaseClick = () => {
    const hospitalActivityObject =
      this.state.caseDetailsInfo?.hospitalCaseActivity?.length > 0
        ? this.state.caseDetailsInfo?.hospitalCaseActivity.find(
            (item) => item?.hospitalId == this.state.hospitalId
          )
        : null;

    var doctorName = "-";

    if (
      hospitalActivityObject !== null &&
      hospitalActivityObject != undefined
    ) {
      doctorName = `Dr. ${
        (hospitalActivityObject?.doctorInfo?.firstName !== null &&
        hospitalActivityObject?.doctorInfo?.firstName !== undefined
          ? `${hospitalActivityObject?.doctorInfo?.firstName
              .charAt(0)
              .toUpperCase()}${hospitalActivityObject?.doctorInfo?.firstName.slice(
              1
            )}`
          : "") +
        " " +
        (hospitalActivityObject?.doctorInfo?.lastName !== null &&
        hospitalActivityObject?.doctorInfo?.lastName !== undefined
          ? `${hospitalActivityObject?.doctorInfo?.lastName
              .charAt(0)
              .toUpperCase()}${hospitalActivityObject?.doctorInfo?.lastName.slice(
              1
            )}`
          : "")
      }`;
    }

    const data = {
      transferredFromHospitalId: this.state.hospitalId,
    };

    this.callGetNearByHospitalListAPI();
    this.callGetAmbulanceListAPI();
    this.callGetDoctorListAPI();

    this.props.initiateTransferProcess(
      this.state.caseId,
      data,
      (processData) => {
        if (processData) {
          this.props.getTransferredDetailsById(processData?.id, (response) => {
            if (response) {
              console.log("ongoingOutwardTransferId :: ", processData?.id);
              this.setState(
                {
                  ongoingOutwardTransferId: processData?.id ?? null,
                  ongoingOutwardTransferInfo: response,
                  showTransferCasePopup: true,
                  transferPatientFields: {
                    ...this.state.transferPatientFields,
                    doctorName: doctorName,
                  },
                },
                () => {
                  this.fillOutgoingTransferData(response);
                }
              );
            }
          });
        }
      }
    );
  };

  onPatientTransferUpdateVitals = () => {
    this.validatorFieldsForTransferPatient["required"] = [
      ...this.validatorFieldsForTransferPatient["required"],
      "pulse",
      "bloodPressure",
      "rr",
      "spo2",
      "weight",
      "bloodSugar",
    ];

    const { transferPatientFields } = this.state;
    const { errors } = validator.validate(
      this.validatorFieldsForTransferPatient,
      trimFormData(transferPatientFields)
    );
    console.log("errors", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        pulse: this.state.transferPatientFields.pulse ?? null,
        rr: this.state.transferPatientFields.rr ?? null,
        bp: this.state.transferPatientFields.bloodPressure ?? null,
        spo2: this.state.transferPatientFields.spo2 ?? null,
        weight: this.state.transferPatientFields.weight ?? null,
        bloodSugar: this.state.transferPatientFields.bloodSugar ?? null,
        transferCaseId: this.state.ongoingOutwardTransferId,
      };
      this.props.transferPatientUpdateVitals(
        this.state.caseId,
        data,
        (response) => {
          if (response) {
            this.validatorFieldsForTransferPatient["required"] = [];
            this.setState({
              activeStep: this.state.activeStep + 1,
            });
          }
        }
      );
    }
  };

  onPatientTransferAssignHospital = () => {
    this.validatorFieldsForTransferPatient["required"] = [
      ...this.validatorFieldsForTransferPatient["required"],
      "assignedHospital",
    ];

    const { transferPatientFields } = this.state;
    const { errors } = validator.validate(
      this.validatorFieldsForTransferPatient,
      trimFormData(transferPatientFields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      const data = {
        transferredToHospitalId:
          this.state.transferPatientFields.assignedHospital?.value,
      };
      this.props.transferPatientAssignHospital(
        this.state.ongoingOutwardTransferId,
        data,
        (response) => {
          if (response) {
            this.validatorFieldsForTransferPatient["required"] = [];
            this.setState({
              activeStep: this.state.activeStep + 1,
            });
          }
        }
      );
    }
  };

  onPatientTransferAssignAmbulance = () => {
    if (
      this.validatorFieldsForTransferPatient.required.includes("vehicleName")
    ) {
      const reqFields = ["assignedAmbulance"];
      this.validatorFieldsForTransferPatient["required"] = reqFields;
    } else {
      this.validatorFieldsForTransferPatient["required"] = [
        ...this.validatorFieldsForTransferPatient["required"],
        "assignedAmbulance",
      ];
    }

    const { transferPatientFields } = this.state;
    const { errors } = validator.validate(
      this.validatorFieldsForTransferPatient,
      trimFormData(transferPatientFields)
    );

    console.log("onPatientTransferAssignAmbulance :: errors ", errors);

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      const data = {
        ambulanceId: this.state.transferPatientFields.assignedAmbulance?.value,
      };
      this.props.transferPatientAssignAmbulance(
        this.state.ongoingOutwardTransferId,
        data,
        (response) => {
          if (response) {
            this.validatorFieldsForTransferPatient["required"] = [];
            this.setState({
              activeStep: this.state.activeStep + 1,
            });
          }
        }
      );
    }
  };

  onPatientTransferAddVehicle = () => {
    var custom = [validator.phone(["attendantContact"])];
    this.validatorFieldsForTransferPatient["custom"] = custom;
    if (
      this.validatorFieldsForTransferPatient.required.includes(
        "assignedAmbulance"
      )
    ) {
      this.validatorFieldsForTransferPatient["required"] =
        this.validatorFieldsForTransferPatient["required"].filter(
          (item) => item !== "assignedAmbulance"
        );
    }
    this.validatorFieldsForTransferPatient["required"] = [
      ...this.validatorFieldsForTransferPatient["required"],
      "vehicleName",
      "vehicleNumber",
      "vehicleType",
      "attendantFirstName",
      "attendantLastName",
      "attendantContact",
    ];

    const { transferPatientFields } = this.state;
    const { errors } = validator.validate(
      this.validatorFieldsForTransferPatient,
      trimFormData(transferPatientFields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      const data = {
        caseId: this.state.caseId,
        transferId: this.state.ongoingOutwardTransferId,
        vehicleName: transferPatientFields.vehicleName,
        vehicleType: transferPatientFields.vehicleType?.value,
        attendantFirstName: transferPatientFields.attendantFirstName,
        attendantLastName: transferPatientFields.attendantLastName,
        attendantContactNumber: transferPatientFields.attendantContact,
        vehicleRegistrationNumber: transferPatientFields.vehicleNumber,
      };

      this.props.transferPatientAddVehicle(data, (response) => {
        if (response) {
          if (this.state.mainManualVehicleImage) {
            this.props.uploadImage(
              this.state.mainManualVehicleImage,
              globalConstants.VEHICLE_IMAGE,
              (imageUrl) => {
                if (imageUrl !== null) {
                  this.validatorFieldsForTransferPatient["required"] = [];
                  this.setState({
                    ...this.state,
                    transferPatientFields: {
                      ...transferPatientFields,
                      vehicleImage: imageUrl,
                    },
                    manuallyAddedVehicleInfo: response,
                    activeStep: this.state.activeStep + 1,
                  });
                }
              },
              response?.id,
              false
            );
          } else {
            this.validatorFieldsForTransferPatient["required"] = [];
            this.setState({
              activeStep: this.state.activeStep + 1,
              checkOnEditVehicleImgPresent: false,
              manuallyAddedVehicleInfo: response,
            });
          }
        }
      });
    }
  };

  onPatientTransferConfirmDetails = () => {
    this.validatorFieldsForTransferPatient["required"] = [
      ...this.validatorFieldsForTransferPatient["required"],
      "doctorName",
      "transferNotes",
    ];

    const { transferPatientFields } = this.state;
    const { errors } = validator.validate(
      this.validatorFieldsForTransferPatient,
      trimFormData(transferPatientFields)
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      this.validatorFieldsForTransferPatient["required"] = [];

      const data = {
        note: transferPatientFields.transferNotes?.trim(),
      };

      this.props.transferPatientConfirmDetails(
        this.state.ongoingOutwardTransferId,
        data,
        (response) => {
          if (response) {
            if (this.state.transferPatientFields.documents?.length > 0) {
              let flag = false;
              let docs = this.state.transferPatientFields.documents;
              for (let i = 0; i < docs.length; i++) {
                //TODO
                this.props.uploadCaseDocuments(
                  this.state.caseId,
                  2,
                  docs[i],
                  (response) => {
                    if (response === null) {
                      flag = true;
                    }
                  }
                );
                if (flag) {
                  break;
                }
              }
            }
            this.resetTransferPatientFormField();
            // this.callGetCaseDetailsById(this.state.caseId);
            this.navigateBack();
          }
        }
      );
    }
  };

  onCloseTransferCasePopup = () => {
    this.validatorFieldsForTransferPatient["required"] = [];
    this.setState(
      {
        showTransferCasePopup: false,
        activeStep: 0,
        errors: {},
      },
      () => {
        if (this.state.activeStep == 3) this.resetTransferPatientFormField();
      }
    );
  };

  //----------------- Manual added vehicle -----------------

  onAddVehicleManuallyClick = () => {
    this.setState({
      showAddVehicleManuallyForm: true,
      transferPatientFields: {
        ...this.state.transferPatientFields,
        assignedAmbulance: "",
      },
      errors: {},
    });
  };

  onCloseAddVehicleManuallyForm = () => {
    this.setState({ showAddVehicleManuallyForm: false });
  };

  onHandleUploadManualVehicleImage = (data) => {
    const { transferPatientFields } = this.state;
    const localUrl = URL.createObjectURL(data);
    if (this.state.checkOnEditVehicleImgPresent) {
      this.props.uploadImage(
        data,
        globalConstants.VEHICLE_IMAGE,
        (imageUrl) => {
          if (imageUrl !== null) {
            this.setState({
              ...this.state,
              transferPatientFields: {
                ...transferPatientFields,
                vehicleImage: imageUrl,
              },
              mainManualVehicleImage: data,
            });
          }
        },
        this.state.manuallyAddedVehicleInfo?.id,
        true
      );
    } else {
      this.setState({
        ...this.state,
        transferPatientFields: {
          ...transferPatientFields,
          vehicleImage: localUrl,
        },
        mainManualVehicleImage: data,
      });
    }
  };

  deleteManualVehicleImageClicked = (data) => {
    const { transferPatientFields } = this.state;
    if (!this.state.checkOnEditVehicleImgPresent) {
      this.setState({
        ...this.state,
        transferPatientFields: {
          ...transferPatientFields,
          vehicleImage: null,
        },
        mainManualVehicleImage: null,
      });
    } else {
      this.props.deleteImage(
        this.state.manuallyAddedVehicleInfo?.id ?? null,
        globalConstants.VEHICLE_IMAGE,
        (isSuccess) => {
          if (isSuccess) {
            this.setState({
              ...this.state,
              transferPatientFields: {
                ...transferPatientFields,
                vehicleImage: null,
              },
              checkOnEditVehicleImgPresent: true,
              mainManualVehicleImage: null,
            });
          }
        }
      );
    }
  };

  //----------------- Popups Show / Hide ---------------------

  onViewMoreButtonClicked = (data) => {
    console.log("data", data);

    this.setState({ showViewMorePopup: true, viewMoreData: data });
  };

  hideViewMorePopup = () => {
    this.setState({ showViewMorePopup: false });
  };

  onTransferDetailsButtonClicked = () => {
    this.setState({ showTransferDetailsPopup: true });
  };

  hideTransferDetailsPopup = () => {
    this.setState({ showTransferDetailsPopup: false });
  };

  onClosingNotesButtonClicked = () => {
    this.setState({ showClosingNotesPopup: true });
  };

  hideClosingNotesPopup = () => {
    this.setState({ showClosingNotesPopup: false });
  };

  //

  onViewAdmissionDetailsClick = () => {
    this.setState({
      showAdmissionDetailsPopup: true,
    });
  };

  onCloseAdmissionDetailsPopup = () => {
    this.setState({
      showAdmissionDetailsPopup: false,
    });
  };

  // ----------------- Insurance card upload image -------------

  onHandleUploadInsuranceCardImage = (data) => {
    const { addPatientFields } = this.state;
    const localUrl = URL.createObjectURL(data);

    if (data) {
      this.state.errors["insuranceCardImage"] = "";
    }

    if (this.state.showEditAdmitPatientPopup) {
      this.props.uploadImage(
        data,
        globalConstants.INSURANCE_CARD_IMAGE,
        (imageUrl) => {
          if (imageUrl !== null) {
            this.setState({
              ...this.state,
              addPatientFields: {
                ...addPatientFields,
                insuranceCardImage: imageUrl,
              },
              // mainInsuranceCardImage: data,
            });
          }
        },
        this.state.insuranceInfo?.id,
        true
      );
    } else {
      this.setState({
        ...this.state,
        addPatientFields: {
          ...addPatientFields,
          insuranceCardImage: localUrl,
        },
        mainInsuranceCardImage: data,
      });
    }
  };

  deleteInsuranceCardImageClicked = (data) => {
    const { addPatientFields } = this.state;

    if (!this.state.showEditAdmitPatientPopup) {
      this.setState({
        ...this.state,
        addPatientFields: {
          ...addPatientFields,
          insuranceCardImage: null,
        },
        mainInsuranceCardImage: null,
      });
    } else {
      this.props.deleteImage(
        this.state.insuranceInfo?.id,
        globalConstants.INSURANCE_CARD_IMAGE,
        (isSuccess) => {
          if (isSuccess) {
            this.setState({
              ...this.state,
              addPatientFields: {
                ...addPatientFields,
                insuranceCardImage: null,
              },
              checkOnEditInsuranceCardImgPresent: true,
              mainInsuranceCardImage: null,
            });
          }
        }
      );
    }
  };

  closeViewImagePopup = () => {
    this.setState({
      ...this.state,
      showInsuranceCardImagePopup: false,
      showAddVehicleImagePopup: false,
    });
  };

  viewInsuranceCardImageClicked = () => {
    this.setState({
      ...this.state,
      showInsuranceCardImagePopup: true,
    });
  };

  viewAddManuallyVehicleImageClicked = () => {
    this.setState({
      ...this.state,
      showAddVehicleImagePopup: true,
    });
  };

  // ------------------------ View ECG Report -----------------------------

  onViewECGReportClick = () => {
    const reportId = this.state.caseDetailsInfo?.reportId;
    const caseId = this.state.caseId;
    if (reportId) {
      history.push(
        `ecg_report_details?id=${reportId}&preview=${false}&case_id=${caseId}&case_view=${true}`
      );
    }
  };

  // ----------------------- Notes Data Handling --------------------

  onAddNoteClick = () => {
    this.setState({ showAddNotesView: true });
  };

  onChangeGenericNotes = (field) => (e) => {
    if (e?.target) {
      e.target.value.length > 0
        ? (this.state.errors = {})
        : (this.state.errors[field] = "Field can not be empty");
      this.setState({
        genericNotes: e.target.value,
      });
    }
  };

  onAddNoteConfirmClick = () => {
    const { errors } = this.state;
    if (!this.state.genericNotes?.trim().length > 0) {
      this.state.errors["genericNotes"] = "Field can not be empty";
    }
    this.setState({ ...this.state, errors });
    if (!Object.keys(errors).length) {
      const data = {
        note: this.state.genericNotes,
      };

      this.props.addCaseNote(this.state.caseId, data, () => {
        this.setState(
          {
            showAddNotesView: false,
            genericNotes: "",
            errors: {},
          },
          () => {
            this.callGetCaseDetailsById(this.state.caseId);
          }
        );
      });
    }
  };

  onCloseAddNoteUI = () => {
    this.setState({ showAddNotesView: false, errors: {}, genericNotes: "" });
  };

  //------------------------------ Stepper ---------------------------

  handleNext = () => {
    this.setState({
      disableNextButton: true,
      activeStep: this.state.activeStep + 1,
      isValidationsOn: false,
    });
  };

  handleBack = () => {
    this.validatorFieldsForTransferPatient["required"] = [];
    this.validatorFieldsForTransferPatient["custom"] = [];
    this.setState({
      isValidationsOn: true,
      disableNextButton: false,
      activeStep: this.state.activeStep - 1,
      checkOnEditVehicleImgPresent: this.state.manuallyAddedVehicleInfo
        ? true
        : false,
      showEditAdmitPatientPopup: this.state.insuranceInfo?.insuranceCard
        ? true
        : false,
    });
  };

  // --------------------------- Documents upload ---------------------

  onUploadTransferPatientDocuments = (docs) => {
    this.setState({
      transferPatientFields: {
        ...this.state.transferPatientFields,
        documents: docs?.length > 0 ? docs : [],
      },
    });
  };

  onUploadCloseCaseDocuments = (docs) => {
    console.log("docs", docs);

    this.setState({
      closeCaseFields: {
        ...this.state.closeCaseFields,
        documents: docs?.length > 0 ? docs : [],
      },
    });
  };

  //----------------------------- RENDER ------------------------------

  render() {
    return (
      <CaseDetailsView
        loginUserId={this.props.loginUser?.id}
        caseDetailsInfo={this.state.caseDetailsInfo}
        hospitalId={this.state.hospitalId}
        caseStatus={this.state.caseStatus}
        //
        isTransferredCase={this.state.isTransferredCase}
        transferId={this.state.transferId}
        trasnferCaseInfo={this.state.trasnferCaseInfo}
        isSource={this.state.isSource}
        //
        ongoingOutwardTransferId={this.state.ongoingOutwardTransferId}
        //
        caseUIType={this.state.caseUIType} //TODO
        //
        navigateBack={this.navigateBack}
        //
        onAddPatientConfirmClick={this.onAddPatientConfirmClick}
        onAddPatientClick={this.onAddPatientClick}
        onCloseAddPatientPopup={this.onCloseAddPatientPopup}
        showAddPatientPopup={this.state.showAddPatientPopup}
        addPatientFields={this.state.addPatientFields}
        onAddPatientFieldChange={this.onAddPatientFieldChange}
        onAddPatientInsuranceConfirmClick={
          this.onAddPatientInsuranceConfirmClick
        }
        //
        doctorList={this.state.doctorList}
        searchDoctorText={this.state.searchDoctorText}
        onScrollDoctorList={this.onScrollDoctorList}
        onDoctorSearchChange={this.onDoctorSearchChange}
        onAssignedDoctorChange={this.onAssignedDoctorChange}
        //
        patientInsuranceData={this.state.patientInsuranceData}
        patientRelWithInsuredData={this.state.patientRelWithInsuredData}
        activeStep={this.state.activeStep}
        showAddNotesView={this.state.showAddNotesView}
        onAddNoteClick={this.onAddNoteClick}
        onAddNoteConfirmClick={this.onAddNoteConfirmClick}
        onCloseAddNoteUI={this.onCloseAddNoteUI}
        onPatientInsuranceButtonClicked={this.onPatientInsuranceButtonClicked}
        disableInsuranceFormField={this.state.disableInsuranceFormField}
        //
        showCloseCasePopup={this.state.showCloseCasePopup}
        onCloseCaseClick={this.onCloseCaseClick}
        onCloseCaseConfirmClick={this.onCloseCaseConfirmClick}
        onCloseCloseCasePopup={this.onCloseCloseCasePopup}
        //
        onPatientTransferUpdateVitals={this.onPatientTransferUpdateVitals}
        onPatientTransferAssignHospital={this.onPatientTransferAssignHospital}
        onPatientTransferAddVehicle={this.onPatientTransferAddVehicle}
        onPatientTransferAssignAmbulance={this.onPatientTransferAssignAmbulance}
        onPatientTransferConfirmDetails={this.onPatientTransferConfirmDetails}
        //
        onTransferPatientFieldChange={this.onTransferPatientFieldChange}
        showTransferCasePopup={this.state.showTransferCasePopup}
        onTransferCaseClick={this.onTransferCaseClick}
        onCloseTransferCasePopup={this.onCloseTransferCasePopup}
        //
        transferPatientFields={this.state.transferPatientFields}
        //
        onHandleUploadInsuranceCardImage={this.onHandleUploadInsuranceCardImage}
        deleteInsuranceCardImageClicked={this.deleteInsuranceCardImageClicked}
        //
        handleNext={this.handleNext}
        handleBack={this.handleBack}
        //
        onScrollHospitalList={this.onScrollHospitalList}
        onHospitalSearchChange={this.onHospitalSearchChange}
        hospitalList={this.state.hospitalList}
        searchHospitalText={this.state.searchHospitalText}
        onAssignedHospitalChange={this.onAssignedHospitalChange}
        //
        onAssignedAmbulanceChange={this.onAssignedAmbulanceChange}
        ambulanceList={this.state.ambulanceList}
        onScrollAmbulanceList={this.onScrollAmbulanceList}
        onAmbulanceSearchChange={this.onAmbulanceSearchChange}
        searchAmbulanceText={this.state.searchAmbulanceText}
        //
        onHandleUploadManualVehicleImage={this.onHandleUploadManualVehicleImage}
        deleteManualVehicleImageClicked={this.deleteManualVehicleImageClicked}
        vehicleTypeData={this.state.vehicleTypeData}
        onAddVehicleManuallyClick={this.onAddVehicleManuallyClick}
        onCloseAddVehicleManuallyForm={this.onCloseAddVehicleManuallyForm}
        showAddVehicleManuallyForm={this.state.showAddVehicleManuallyForm}
        //
        onCloseCaseFieldChange={this.onCloseCaseFieldChange}
        closeCaseFields={this.state.closeCaseFields}
        //
        onChangeGenericNotes={this.onChangeGenericNotes}
        genericNotes={this.state.genericNotes}
        //
        showViewMorePopup={this.state.showViewMorePopup}
        viewMoreData={this.state.viewMoreData}
        onViewMoreButtonClicked={this.onViewMoreButtonClicked}
        hideViewMorePopup={this.hideViewMorePopup}
        //
        insuranceInfo={this.state.insuranceInfo}
        onViewECGReportClick={this.onViewECGReportClick}
        errors={this.state.errors}
        //
        onUploadTransferPatientDocuments={this.onUploadTransferPatientDocuments}
        onUploadCloseCaseDocuments={this.onUploadCloseCaseDocuments}
        //
        showInsuranceCardImagePopup={this.state.showInsuranceCardImagePopup}
        closeViewImagePopup={this.closeViewImagePopup}
        viewAddManuallyVehicleImageClicked={
          this.viewAddManuallyVehicleImageClicked
        }
        showAddVehicleImagePopup={this.state.showAddVehicleImagePopup}
        viewInsuranceCardImageClicked={this.viewInsuranceCardImageClicked}
        //
        showTransferDetailsPopup={this.state.showTransferDetailsPopup}
        onTransferDetailsButtonClicked={this.onTransferDetailsButtonClicked}
        hideTransferDetailsPopup={this.hideTransferDetailsPopup}
        //
        showClosingNotesPopup={this.state.showClosingNotesPopup}
        onClosingNotesButtonClicked={this.onClosingNotesButtonClicked}
        hideClosingNotesPopup={this.hideClosingNotesPopup}
        //
        showAdmissionDetailsPopup={this.state.showAdmissionDetailsPopup}
        onViewAdmissionDetailsClick={this.onViewAdmissionDetailsClick}
        onCloseAdmissionDetailsPopup={this.onCloseAdmissionDetailsPopup}
      />
    );
  }
}

CaseDetailsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    hospitalAdminsHospitalIds: state.administrators.hospitalAdminsHospitalIds,
  };
};
export default connect(mapStateToProps, {
  getCaseDetailsById,
  getHospitalsListWithFilter,
  getAmbulanceListWithFilter,
  addPatientInsurance,
  admitPatient,
  editPatient,
  editPatientInsurance,
  getPatientInsuranceDetailsById,
  uploadImage,
  deleteImage,
  getDoctorsListWithFilter,
  closePatientCase,
  rejectPatientCase,
  addCaseNote,
  transferPatientConfirmDetails,
  transferPatientAddVehicle,
  transferPatientAssignAmbulance,
  transferPatientAssignHospital,
  transferPatientUpdateVitals,
  initiateTransferProcess,
  uploadCaseDocuments,
  getHospitalsNearAnotherHospitals,
  getTransferredDetailsById,
  deletePatientInsurance,
})(CaseDetailsContainer);
