import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import "./FormSearchStyles.scss";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { styled } from "@mui/system";
import { showAlert } from "../../../store/alert/alertActions";
import { getStore } from "../../../store/configure/configureStore";

const Label = styled("label")({
  display: "block",
});

const Input = styled("input")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  color: theme.palette.mode === "light" ? "#000" : "#fff",
  border: "none",
  outline: "none",
  fontSize: "14px",

  "&::placeholder": {
    color: "rgba(0, 0, 0, 0.85)",
    opacity: "0.7",
    fontWeight: "300",
  },
}));

const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  top: "100%",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 190,
  borderRadius: "0 0 2px 2px",
  border: "1px solid rgba(0,0,0,.25)",
  "& li": {
    fontSize: "14px",
    padding: "5px 10px",
  },
  "& li.Mui-focused": {
    cursor: "pointer",
  },
  // "& li[aria-selected='true']": {
  //   backgroundColor: "#c0e5dc",
  // },
  "& li:hover ": {
    backgroundColor: "rgb(242, 242, 242) ",
    color: "#070707",
    cursor: "pointer",
  },
  "& li.Mui-focused": {
    backgroundColor: "rgb(242, 242, 242)",
    color: "#070707",
    cursor: "pointer",
  },
}));

const FormSearchView = ({
  Options,
  placeholder,
  onChange,
  onInputChange,
  onScroll = null,
  previousArrayValue, // in multiple select
  previousValue = null, // value is equal to previous selected index
  disable = false,
  searchText,
  multiSelect = false,
  lazyLoading = false,
  customInputSearch = false,
  error,
}) => {
  useEffect(() => {
    if (previousValue !== null) {
      setPreviousSelectedValue(previousValue);
    }
  }, [previousValue]);
  // above it will help get latest value in Field

  const [previousSelectedValue, setPreviousSelectedValue] =
    useState(previousValue);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: Options,
    ...(!multiSelect &&
      (disable ? { value: "" } : { value: previousSelectedValue })),
    disabled: disable, // disable when user pass true
    getOptionLabel: (option) => option.label || "",
    onChange: (event, newInputValue) => {
      if (multiSelect) {
        const alredyPresent = previousArrayValue.some(
          (option) =>
            option.value ==
            (newInputValue !== null && newInputValue !== undefined
              ? newInputValue.value
              : "") // avoid dublicate entry in multiple select
        );
        if (!alredyPresent) {
          onChange(newInputValue);
        } else {
          onChange(null);
          getStore().dispatch(
            showAlert({
              isOpen: true,
              title: "Attention",
              type: "warning",
              msg: "This item already exists",
            })
          );
        }
      } else {
        onChange(newInputValue);
        setPreviousSelectedValue(newInputValue);
      }
    },
    onInputChange: (event, value) => {
      if (customInputSearch && !previousSelectedValue) {
        onInputChange(value); // when user want to search Data custom form serverside (from api/backend)
      }
    },
  });

  return (
    <>
      <div
        className={`form-search-wrapper ${error ? "input-error" : ""} ${
          disable ? "disable" : ""
        }`}
        {...getRootProps()}
      >
        <IoIosSearch size={20} color="rgba(0,0,0,0.45)" />
        <Input
          style={{ width: "100%" }}
          className={` ${error ? "input-field" : ""} ${
            disable ? "disable" : ""
          } `}
          placeholder={placeholder}
          {...getInputProps()}
          {...(multiSelect && { value: searchText })}
        />
        <div className={`search-error`}>{error ? error : ""}</div>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox
          {...getListboxProps()}
          onScroll={(event) => {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (scrollTop + clientHeight >= scrollHeight) {
              if (lazyLoading) {
                onScroll(); // when user scroll dropdown-list upto bottom this event will fire
              }
            }
          }}
        >
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.label}</li>
          ))}
        </Listbox>
      ) : null}
    </>
  );
};

FormSearchView.propTypes = {};

export default FormSearchView;
