import { globalConstants } from "../../constants/globalConstants";
import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import {
  updateHospitalFranchiseLogo,
  updateUserProfileImageUrl,
} from "../auth/authActions";
import { getHospitalDetailsById } from "../hospital/hospitalActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./masterReducer";

export function getState(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_STATE_PENDING,
      payload: {
        getStatePending: false,
        getStateSuccess: false,
        getStateError: null,
      },
    });
    // dispatch(showLoader());

    Api.get("master/state")
      .then((response) => {
        // dispatch(hideLoader());

        // if (typeof callback === "function") {
        //   const Maharashtra = response.data.find(
        //     (state) => state.name === "Maharashtra"
        //   );
        //   callback({ stateId: Maharashtra.id });
        // }

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_STATE_SUCCESS,
          payload: {
            getStatePending: false,
            getStateSuccess: true,
            getStateError: null,
            stateList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_STATE_ERROR,
          payload: {
            getStatePending: false,
            getStateSuccess: true,
            getStateError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getStatesList(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_STATE_PENDING,
      payload: {
        getStatePending: false,
        getStateSuccess: false,
        getStateError: null,
      },
    });
    dispatch(showLoader());

    Api.get("master/state")
      .then((response) => {
        // console.log("getStatesList response");
        dispatch(hideLoader());

        // if (typeof callback === "function") {
        //   const Maharashtra = response.data.find(
        //     (state) => state.name === "Maharashtra"
        //   );
        //   callback({ stateId: Maharashtra.id });
        // }

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_STATE_SUCCESS,
          payload: {
            getStatePending: false,
            getStateSuccess: true,
            getStateError: null,
            stateList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_STATE_ERROR,
          payload: {
            getStatePending: false,
            getStateSuccess: true,
            getStateError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getDistrict(stateDetails, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DISTRICT_PENDING,
      payload: {
        getDistrictPending: false,
        getDistrictSuccess: false,
        getDistrictError: null,
      },
    });
    // dispatch(showLoader());

    Api.post("master/district", stateDetails)
      .then((response) => {
        // console.log("getDistrict response");
        // dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_DISTRICT_SUCCESS,
          payload: {
            getDistrictPending: false,
            getDistrictSuccess: true,
            getDistrictError: null,
            districtList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_DISTRICT_ERROR,
          payload: {
            getDistrictPending: false,
            getDistrictSuccess: true,
            getDistrictError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getCity(cityData, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CITY_PENDING,
      payload: {
        getCityPending: false,
        getCitySuccess: false,
        getCityError: null,
      },
    });
    // dispatch(showLoader());

    Api.post("master/area", cityData)
      .then((response) => {
        // dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }
        dispatch({
          type: actionTypes.GET_CITY_SUCCESS,
          payload: {
            getCityPending: false,
            getCitySuccess: true,
            getCityError: null,

            cityList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_CITY_ERROR,
          payload: {
            getCityPending: false,
            getCitySuccess: true,
            getCityError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getPincode(pincodeData, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PINCODE_PENDING,
      payload: {
        getPincodePending: false,
        getPincodeSuccess: false,
        getPincodeError: null,
      },
    });
    // dispatch(showLoader());

    Api.post("master/pincode", pincodeData)
      .then((response) => {
        // console.log(response);
        // dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_PINCODE_SUCCESS,
          payload: {
            getPincodePending: false,
            getPincodeSuccess: true,
            getPincodeError: null,

            pincodeList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_PINCODE_ERROR,
          payload: {
            getPincodePending: false,
            getPincodeSuccess: true,
            getPincodeError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getSecurityQuestionsList(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SECURITY_QUESTIONS_LIST_PENDING,
      payload: {
        getSecurityQuestionsPending: true,
        getSecurityQuestionsSuccess: false,
        getSecurityQuestionsError: null,
      },
    });
    dispatch(showLoader());

    Api.get("master/security/questions/list")
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_SECURITY_QUESTIONS_LIST_SUCCESS,
          payload: {
            getSecurityQuestionsPending: false,
            getSecurityQuestionsSuccess: true,
            getSecurityQuestionsError: null,
            securityQuestions: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_SECURITY_QUESTIONS_LIST_ERROR,
          payload: {
            getSecurityQuestionsPending: false,
            getSecurityQuestionsSuccess: true,
            getSecurityQuestionsError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getStatus() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_STATUS_PENDING,
      payload: {
        getStatusPending: false,
        getStatusSuccess: false,
        getStatusError: null,
      },
    });
    dispatch(showLoader());

    Api.get("master/statusList")
      .then((response) => {
        // console.log(response);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_STATUS_SUCCESS,
          payload: {
            getStatusPending: false,
            getStatusSuccess: true,
            getStatusError: null,

            statusList: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.GET_STATUS_ERROR,
          payload: {
            getStatusPending: false,
            getStatusSuccess: true,
            getStatusError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export const uploadImage = (
  data,
  type,
  callback = null,
  entityId = null,
  showSuccessMsg = false
) => {
  var successMessage =
    (type === globalConstants.USER_PROFILE_IMAGE
      ? "Profile picture"
      : type === globalConstants.DOCTOR_SIGNATURE_IMAGE
      ? "Signature"
      : type === globalConstants.HOSPITAL_LOGO_IMAGE ||
        type === globalConstants.HOSPITAL_FRANCHISE_IMAGE
      ? "Logo"
      : type === globalConstants.AMBULANCE_IMAGE ||
        type === globalConstants.INSURANCE_CARD_IMAGE
      ? "Image"
      : "Picture") +
    " " +
    "uploaded successfully!";

  var entityName =
    type === globalConstants.HOSPITAL_LOGO_IMAGE
      ? "hospitalId"
      : type === globalConstants.AMBULANCE_IMAGE
      ? "ambulanceId"
      : type === globalConstants.INSURANCE_CARD_IMAGE
      ? "insuranceId"
      : type === globalConstants.VEHICLE_IMAGE
      ? "vehicleId"
      : null;

  return (dispatch) => {
    var formdata = new FormData();
    formdata.append("file", data);

    if (entityId) {
      formdata.append(entityName, entityId);
    }

    dispatch(showLoader());

    dispatch({
      type: actionTypes.UPLOAD_IMAGE_PENDING,
      payload: {
        UploadImagePending: true,
        UploadImageSuccess: false,
        UploadImageError: null,
        uploadImageData: null,
      },
    });

    // dispatch(showBigLoader());

    Api.uploadFile(`auth/uploadDocument/my/profile/${type}`, null, formdata)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        if (type === globalConstants.USER_PROFILE_IMAGE) {
          dispatch(updateUserProfileImageUrl(response.data));
        }

        if (type === globalConstants.HOSPITAL_FRANCHISE_IMAGE) {
          dispatch(updateHospitalFranchiseLogo(response.data));
        }

        // hide success msg for ambulance ogo
        if (showSuccessMsg) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: successMessage,
            })
          );
        }

        dispatch({
          type: actionTypes.UPLOAD_IMAGE_SUCCESS,
          payload: {
            UploadImagePending: false,
            UploadImageSuccess: true,
            UploadImageError: null,
            uploadImageData: response,
          },
        });
      })

      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );

        dispatch({
          type: actionTypes.UPLOAD_IMAGE_ERROR,
          payload: {
            UploadImagePending: false,
            UploadImageSuccess: true,
            UploadImageError: null,
            uploadImageData: null,
          },
        });
      });
  };
};

export const deleteImage = (data, type, callback = null) => {
  var successMessage =
    (type === globalConstants.USER_PROFILE_IMAGE
      ? "Profile picture"
      : type === globalConstants.DOCTOR_SIGNATURE_IMAGE
      ? "Signature"
      : type === globalConstants.HOSPITAL_LOGO_IMAGE ||
        type === globalConstants.HOSPITAL_FRANCHISE_IMAGE
      ? "Logo"
      : type === globalConstants.AMBULANCE_IMAGE ||
        type === globalConstants.VEHICLE_IMAGE
      ? "Image"
      : "Picture") +
    " " +
    "deleted successfully!";

  return (dispatch) => {
    const newData = {
      fileRequestedId: data,
    };

    dispatch({
      type: actionTypes.DELETE_IMAGE_PENDING,
      payload: {
        deleteImagePending: true,
        deleteImageSuccess: false,
        deleteImageError: null,
      },
    });

    dispatch(showLoader());

    Api.post(`auth/deleteUploadedMyProfile/${type}`, newData)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(true);
        }

        if (type === globalConstants.USER_PROFILE_IMAGE) {
          dispatch(updateUserProfileImageUrl(null));
        }

        if (type === globalConstants.HOSPITAL_FRANCHISE_IMAGE) {
          dispatch(updateHospitalFranchiseLogo(null));
        }

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: successMessage,
          })
        );

        dispatch({
          type: actionTypes.DELETE_IMAGE_SUCCESS,
          payload: {
            deleteImagePending: false,
            deleteImageSuccess: true,
            deleteImageError: null,
          },
        });

        if (type === globalConstants.USER_PROFILE_IMAGE) {
          dispatch(updateUserProfileImageUrl(null));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(false);
        }

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );

        dispatch({
          type: actionTypes.DELETE_IMAGE_ERROR,
          payload: {
            deleteImagePending: false,
            deleteImageSuccess: true,
            deleteImageError: null,
          },
        });
      });
  };
};

export const unlockUser = (uid, callback) => {
  const data = { userId: uid };
  return (dispatch) => {
    dispatch({
      type: actionTypes.UNLOCK_USER_ACCOUNT_PENDING,
      payload: {
        unlockUserAccountPending: true,
        unlockUserAccountSuccess: false,
        unlockUserAccountError: null,
      },
    });

    dispatch(showLoader());

    Api.post(`auth/unlockUser`, data)
      .then((response) => {
        dispatch(hideLoader());

        dispatch({
          type: actionTypes.UNLOCK_USER_ACCOUNT_SUCCESS,
          payload: {
            unlockUserAccountPending: false,
            unlockUserAccountSuccess: true,
            unlockUserAccountError: null,
          },
        });

        if (typeof callback === "function") {
          callback();
        }
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );

        dispatch({
          type: actionTypes.UNLOCK_USER_ACCOUNT_ERROR,
          payload: {
            unlockUserAccountPending: false,
            unlockUserAccountSuccess: true,
            unlockUserAccountError: null,
          },
        });
      });
  };
};
