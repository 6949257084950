import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";

import {
  getDashboardData,
  getTopPerformers,
} from "../../../../../store/dashboard/dashboardActions";
import { getReportListWithFilter } from "../../../../../store/report/reportActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import SuperAdminView from "./SuperAdminView";
import moment from "moment";

export class SuperAdminDashboardContainer extends Component {
  // an interval object to clear ids after 1 minutes.
  // clearNewReportIdsForDoctor = null;

  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",
      firstName: "",
      lastName: "",

      // ---------------- SuperAdimin ----------------

      // Hospitals Card
      totalHospitals: 0,
      totalCenters: 0,
      newlyAddedHospitals: 0,
      // ECG Reports Card
      totalReportsThisYear: 0,
      reportsData: [],
      //
      reportsGeneratedToday: 0,
      reportsCompletedToday: 0,
      reportsPendingForToday: 0,
      // Devices Card
      totalDevices: 0,
      devicesData: [
        { name: "Online", value: 0 },
        { name: "Error", value: 0 },
        { name: "Offline", value: 0 },
      ],
      // Errors Card
      totalErrorLogs: 0,
      errorManagementData: [
        { name: "Open", value: 0 },
        { name: "In Progress", value: 0 },
        { name: "Closed", value: 0 },
      ],
      // Top Hospitals Bar Chart
      topHospitalsData: [{ label: "", count: 0 }],
      toggleValue: "month",
      dateRangeStartDate: null,
      dateRangeEndDate: null,
      filters: {
        startTime: moment().startOf("month").unix(),
        endTime: moment().endOf("month").unix(),
      },
    };

    this.populateSuperAdminData = this.populateSuperAdminData.bind(this);

    this.onToggleChangeForTopHospitals =
      this.onToggleChangeForTopHospitals.bind(this);
    this.onDateRangeChangeForTopHospitals =
      this.onDateRangeChangeForTopHospitals.bind(this);
  }

  componentDidMount() {
    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var firstName = this.props.loginUser?.userProfile?.firstName ?? "";
    var lastName = this.props.loginUser?.userProfile?.lastName ?? "";
    firstName = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
    lastName = `${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;

    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
      firstName: firstName,
      lastName: lastName,
    });

    // Fetch Dashboard data for all users...
    if (loginUserRole) {
      this.props.showLoader();
      this.props.getDashboardData();

      setTimeout(() => {
        this.props.hideLoader();
        this.populateSuperAdminData(this.props.dashboardData);
      }, 1500);
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.lastUpdatedTimestamp !== this.props.lastUpdatedTimestamp) {
      this.populateSuperAdminData(newProps.dashboardData);
    }
  }

  // ---------------- Dashbaord Data Parsing -----------------------

  populateSuperAdminData = (data) => {
    var dashboardData = data; //this.props.dashboardData; // Redux data

    // -------------- Reports Card calculation   ---------------

    const pastMonthData = dashboardData.reports?.past_months ?? {};
    const currentMonthData = dashboardData.reports?.current_month_daily ?? {};

    var totalReportCountForYear = 0;
    var reportsChartData = [];

    // "arrived":0,
    // "assigned":1,
    // "today":1,

    Object.values(pastMonthData).forEach((valueObject) => {
      var monthlyCountObject = valueObject;
      var monthsCount = monthlyCountObject?.all ?? 0;
      totalReportCountForYear =
        parseInt(totalReportCountForYear) + parseInt(monthsCount);
      reportsChartData.push({
        label: "past_month",
        count: parseInt(monthsCount),
      });
    });

    console.log("totalReportCount : For Year: ", totalReportCountForYear);

    var totalReportCountForCurrentMonth = 0;
    Object.values(currentMonthData).forEach((value) => {
      totalReportCountForCurrentMonth =
        parseInt(totalReportCountForCurrentMonth) + parseInt(value);
    });

    reportsChartData.push({
      label: "current_month",
      count: parseInt(totalReportCountForCurrentMonth),
    });

    console.log(
      "totalReportCount: For Current Month : ",
      totalReportCountForCurrentMonth
    );

    var completedReportsCountToday =
      parseInt(dashboardData?.reports?.today ?? 0) -
      (parseInt(dashboardData?.reports?.arrived ?? 0) +
        parseInt(dashboardData?.reports?.assigned ?? 0));

    var pendingReportsCountToday =
      parseInt(dashboardData?.reports?.arrived ?? 0) +
      parseInt(dashboardData?.reports?.assigned ?? 0);

    // -------------- Hospitals Dropdown (Old UI item but few values needed here) ---------------

    var hospitalIdWithNames = dashboardData?.hospitals ?? {};
    var hospitalIds = [];

    if (pastMonthData) {
      Object.keys(pastMonthData).forEach((month) => {
        const monthData = pastMonthData[month];
        if (monthData) {
          hospitalIds.push(...Object.keys(monthData));
        }
      });
    }

    const uniqueHospitalIds = Array.from(new Set(hospitalIds))
      .filter((id) => !isNaN(id))
      .map((id) => parseInt(id, 10));

    const hospitalDropdownData = uniqueHospitalIds.map((id) => ({
      label: hospitalIdWithNames?.[id],
      value: id,
    }));

    // ------------ Top Hospitals Bar Chart Data ---------------

    var topHospitals = dashboardData?.topHospitals ?? []; //TODO
    // console.log("topHospitals: ", JSON.stringify(topHospitals));

    if (topHospitals.length > 0) {
      // Sort the array based on 'total' property in descending order
      topHospitals.sort((a, b) => parseInt(b.total) - parseInt(a.total));
    }

    var topHospitalsDataForBarChart = [];
    topHospitals.map((item) => {
      topHospitalsDataForBarChart.push({
        label: item.name,
        count: item.total,
      });
    });

    // console.log("topHospitalsDataForBarChart: ", JSON.stringify(topHospitalsDataForBarChart));

    // ------------ Devices Card Data ---------------

    var activeDevices = dashboardData?.devices?.status?.Active ?? 0;
    var errorDevices = dashboardData?.devices?.status?.Error ?? 0;
    var offlineDevices = dashboardData?.devices?.status?.Offline ?? 0;

    var openErrors = dashboardData?.errorLogs?.Open ?? 0;
    var inProgressErrors = dashboardData?.errorLogs?.["In Progress"] ?? 0;
    var closedErrors = dashboardData?.errorLogs?.Closed ?? 0;

    // -------------- Update state variables for all cards data  ---------------

    this.setState({
      // Hospitals
      totalHospitals: dashboardData?.hospitals?.total ?? 0,
      totalCenters: dashboardData?.centers?.total ?? 0,
      newlyAddedHospitals: dashboardData?.newHospitalCount ?? 0,
      // ECG Reports
      totalReportsThisYear: totalReportCountForYear,
      reportsData: reportsChartData,
      //
      reportsGeneratedToday: dashboardData?.reports?.today ?? 0,
      reportsCompletedToday: completedReportsCountToday,
      reportsPendingForToday: pendingReportsCountToday,
      // Devices
      totalDevices: dashboardData?.devices?.total ?? 0,
      devicesData: [
        { name: "Online", value: parseInt(activeDevices) },
        { name: "Error", value: parseInt(errorDevices) },
        { name: "Offline", value: parseInt(offlineDevices) },
      ],
      // Errors
      totalErrorLogs:
        parseInt(openErrors) +
        parseInt(inProgressErrors) +
        parseInt(closedErrors),
      errorManagementData: [
        { name: "Open", value: parseInt(openErrors) },
        { name: "In Progress", value: parseInt(inProgressErrors) },
        { name: "Closed", value: parseInt(closedErrors) },
      ],
      // Top Hospitals (Reset toggle and date filters too as we get 'month' filters data here)
      topHospitalsData: topHospitalsDataForBarChart,
      toggleValue: "month",
      dateRangeStartDate: null,
      dateRangeEndDate: null,
      filters: {
        startTime: moment().startOf("month").unix(),
        endTime: moment().endOf("month").unix(),
      },
    });
  };

  onToggleChangeForTopHospitals = (toggleVal) => {
    var startDate = null;
    var endDate = null;
    if (toggleVal == "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }
    if (toggleVal == "week") {
      startDate = moment().startOf("week").unix();
      endDate = moment().endOf("week").unix();
    }
    if (toggleVal == "month") {
      startDate = moment().startOf("month").unix();
      endDate = moment().endOf("month").unix();
    }
    if (toggleVal == "year") {
      startDate = moment().startOf("year").unix();
      endDate = moment().endOf("year").unix();
    }
    this.setState(
      {
        toggleValue: toggleVal,
        dateRangeStartDate: null,
        dateRangeEndDate: null,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        var apiData = {
          entity: "hospital",
          duration: toggleVal === "today" ? "custom" : toggleVal,
          startTime: startDate,
          endTime: endDate,
          limit: 10,
        };

        console.log("ON TOGGLE CHANGE : ", JSON.stringify(apiData));

        // Call API and Update top hospitals list

        this.props.getTopPerformers(apiData, (responseData) => {
          var topHospitals = responseData.topHospitals;

          if (topHospitals.length > 0) {
            // Sort the array based on 'total' property in descending order
            topHospitals.sort((a, b) => parseInt(b.total) - parseInt(a.total));
          }

          var topHospitalsDataForBarChart = [];
          topHospitals.map((item) => {
            topHospitalsDataForBarChart.push({
              label: item.name,
              count: item.total,
            });
          });

          this.setState({ topHospitalsData: topHospitalsDataForBarChart });
        });
      }
    );
  };

  onDateRangeChangeForTopHospitals = (start, end) => {
    console.log("onDateRangeChangeForTopHospitals, ", start, ", ", end);

    var startDate = moment(start).startOf("day").unix();
    var endDate = moment(end).endOf("day").unix();

    this.setState(
      {
        toggleValue: null,
        dateRangeStartDate: start,
        dateRangeEndDate: end,
        filters: {
          ...this.state.filters,
          startTime: startDate,
          endTime: endDate,
        },
      },
      () => {
        var apiData = {
          entity: "hospital",
          duration: "custom",
          startTime: startDate,
          endTime: endDate,
          limit: 10,
        };

        console.log("ON DATE RANGE CHANGE : ", JSON.stringify(apiData));

        // Call API and Update top hospitals list

        this.props.getTopPerformers(apiData, (responseData) => {
          var topHospitals = responseData.topHospitals;

          if (topHospitals.length > 0) {
            // Sort the array based on 'total' property in descending order
            topHospitals.sort((a, b) => parseInt(b.total) - parseInt(a.total));
          }

          var topHospitalsDataForBarChart = [];
          topHospitals.map((item) => {
            topHospitalsDataForBarChart.push({
              label: item.name,
              count: item.total,
            });
          });

          this.setState({ topHospitalsData: topHospitalsDataForBarChart });
        });
      }
    );
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.superAdmin) {
      return (
        <SuperAdminView
          totalHospitals={this.state.totalHospitals}
          totalCenters={this.state.totalCenters}
          newlyAddedHospitals={this.state.newlyAddedHospitals}
          //
          totalReportsThisYear={this.state.totalReportsThisYear}
          reportsData={this.state.reportsData}
          reportsGeneratedToday={this.state.reportsGeneratedToday}
          reportsCompletedToday={this.state.reportsCompletedToday}
          reportsPendingForToday={this.state.reportsPendingForToday}
          //
          totalDevices={this.state.totalDevices}
          devicesData={this.state.devicesData}
          //
          totalErrorLogs={this.state.totalErrorLogs}
          errorManagementData={this.state.errorManagementData}
          //
          topHospitalsData={this.state.topHospitalsData}
          //
          toggleValue={this.state.toggleValue}
          onToggleChange={this.onToggleChangeForTopHospitals}
          //
          dateRangeStartDate={this.state.dateRangeStartDate}
          dateRangeEndDate={this.state.dateRangeEndDate}
          onDatesChange={this.onDateRangeChangeForTopHospitals}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    dashboardData: state.dashboard.dashboardData,
    lastUpdatedTimestamp: state.dashboard.lastUpdatedTimestamp,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
  getTopPerformers,
  getReportListWithFilter,
})(SuperAdminDashboardContainer);
