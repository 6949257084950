// import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import Api from "./../../service/api";
import { history, getState } from "../configure/configureStore";
import { actionTypes } from "./authReducer";
import { roles } from "../../constants/roles";
import {
  createMQTTConnection,
  disconnect,
  unSubscribeToTopic,
} from "../../service/mqttApi";
import { resetDashboardData } from "../dashboard/dashboardActions";
import { resetDeviceData } from "../device/deviceActions";
import { resetReprtsData } from "../report/reportActions";
import { LOGIN } from "../../navigation/routes";

export function login(email, password) {
  return (dispatch) => {
    let data = {
      email: email.trim(),
      password: password,
    };
    if (email === "" || password === "") {
      dispatch(
        showAlert({
          isOpen: true,
          title: "error",
          type: "danger",
          msg: "Fill all the fields.",
        })
      );
    } else {
      dispatch({
        type: actionTypes.AUTH_PENDING,
        payload: {
          authPending: true,
          authSuccess: false,
          authError: null,
          accessToken: null,
          refreshToken: null,
          loginUser: null,
        },
      });
      dispatch(showLoader());

      Api.post("auth/signin", data)
        .then((response) => {
          localStorage.setItem("Token", response.data.accessToken);
          dispatch(hideLoader());

          createMQTTConnection(() => {
            console.log("LOGIN : MQTT Connected!!!");
          });
          dispatch({
            type: actionTypes.AUTH_SUCCESS,
            payload: {
              authPending: false,
              authSuccess: true,
              authError: null,
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              loginUser: response.data,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(hideLoader());
          dispatch({
            type: actionTypes.AUTH_ERROR,
            payload: {
              authPending: false,
              authSuccess: true,
              authError: false,
              accessToken: null,
              refreshToken: null,
              loginUser: null,
            },
          });
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: err,
            })
          );
        });
    }
  };
}

export function logout() {
  return (dispatch) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getState().auth.accessToken,
    };

    dispatch({
      type: actionTypes.LOGOUT_PENDING,
      payload: {
        logoutPending: true,
        logoutSuccess: false,
        logoutError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`auth/logout`, {}, headers)
      .then((response) => {
        // console.log("logout response", response);

        dispatch(hideLoader());

        dispatch({
          type: actionTypes.LOGOUT_SUCCESS,
          payload: {
            logoutPending: false,
            logoutSuccess: true,
            logoutError: null,
          },
        });

        // Reset User Login Details and all other data...
        dispatch(resetUserLoginDetails());

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message
              ? response.message
              : "You are successfully logged out!",
          })
        );
      })
      .catch((err) => {
        dispatch(hideLoader());

        dispatch({
          type: actionTypes.LOGOUT_SUCCESS,
          payload: {
            logoutPending: false,
            logoutSuccess: true,
            logoutError: null,
          },
        });

        // Reset User Login Details and all other data...
        dispatch(resetUserLoginDetails());

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "You are successfully logged out!",
          })
        );
      });
  };
}

export function resetUserLoginDetails() {
  console.log("User Login Details reset...");

  var isDoctorUser =
    getState().auth.loginUser?.data?.userRoles[0]?.name === roles.doctor
      ? true
      : false;

  var hospitalId =
    getState().auth.loginUser?.userProfile?.doctorHospitalInfo?.hospitalId ??
    "";

  // Unsubscribe from mqtt for doctor users.
  // if (isDoctorUser) {
  // unSubscribeToTopic(`device/reportRequest/reportData/${hospitalId}`);
  // }

  disconnect();

  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_USER_LOGIN_DETAILS,
      payload: {
        accessToken: null,
        refreshToken: null,
        loginUser: null,
      },
    });

    dispatch(resetDashboardData());
    dispatch(resetDeviceData());
    dispatch(resetReprtsData());

    setTimeout(() => {
      history.push("/login");
    }, 500);
  };
}

export function changePassword(oldPassword, newPassword, callback) {
  return (dispatch) => {
    let data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: newPassword,
    };

    dispatch(showLoader());
    dispatch({
      type: actionTypes.CHANGE_PASSWORD_PENDING,
      payload: {
        changePasswordPending: true,
        changePasswordSuccess: false,
        changePasswordError: null,
      },
    });

    Api.post("auth/password/change", data)

      .then((response) => {
        // console.log(response);
        if (response.data) {
          dispatch(hideLoader());

          if (typeof callback === "function") {
            callback(false, null); // callback (isError, errorMessage)
          }

          dispatch({
            type: actionTypes.CHANGE_PASSWORD_SUCCESS,
            payload: {
              changePasswordPending: false,
              changePasswordSuccess: true,
              changePasswordError: null,
            },
          });

          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "success",
              msg: response.message,
            })
          );
        } else {
          dispatch(hideLoader());

          dispatch({
            type: actionTypes.CHANGE_PASSWORD_ERROR,
            payload: {
              changePasswordPending: false,
              changePasswordSuccess: false,
              changePasswordError: response.message,
            },
          });

          if (typeof callback === "function") {
            callback(true, response.message); // callback (isError, errorMessage)
          }

          dispatch(
            showAlert({
              isOpen: true,
              title: "error",
              type: "danger",
              msg: response.message,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(true, err); // callback (isError, errorMessage)
        }

        dispatch({
          type: actionTypes.CHANGE_PASSWORD_ERROR,
          payload: {
            changePasswordPending: false,
            changePasswordSuccess: false,
            changePasswordError: err,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function updateToken(token, refreshToken) {
  // console.log("test", token, refreshToken);
  return (dispatch) => {
    // console.log(" Update token,refresh ::", token, refreshToken);
    dispatch({
      type: actionTypes.UPDATE_TOKEN_SUCCESS,
      payload: {
        accessToken: token,
        refreshToken: refreshToken,
      },
    });
  };
}

export function getMyProfile(callback) {
  var userRole = getState().auth.loginUser?.data?.userRoles[0]?.name ?? null;

  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_MY_PROFILE_PENDING,
      payload: {
        getMyProfilePending: true,
        getMyProfileSuccess: false,
        getMyProfileError: null,
      },
    });
    dispatch(showLoader());

    Api.get("auth/my/profile")
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        //  Update First Name, Last Name and Profile Pic in redux > auth > loginUser object
        var firstName = "";
        var lastName = "";
        var profilePicUrl = response.data.profilePicture ?? null;
        var centerList = []; // only for center admin user : we get user's center name in this list

        // Role specific data
        if (userRole == roles.superAdmin) {
          firstName = response.data?.super_admin_profile?.firstName ?? "";
          lastName = response.data?.super_admin_profile?.lastName ?? "";
        } //
        else if (userRole == roles.hospitalAdmin) {
          firstName = response.data?.hospital_admin_profile?.firstName ?? "";
          lastName = response.data?.hospital_admin_profile?.lastName ?? "";
        } //
        else if (userRole == roles.reportingHospitalAdmin) {
          firstName = response.data?.hospital_admin_profile?.firstName ?? "";
          lastName = response.data?.hospital_admin_profile?.lastName ?? "";
        } //
        else if (userRole == roles.centerAdmin) {
          firstName = response.data?.center_admin_profile?.firstName ?? "";
          lastName = response.data?.center_admin_profile?.lastName ?? "";
          centerList = response.data?.center_admin_profile?.centerList ?? [];
        } //
        else if (userRole == roles.technician) {
          firstName = response.data?.technician_profile?.firstName ?? "";
          lastName = response.data?.technician_profile?.lastName ?? "";
        } //
        else if (userRole == roles.doctor) {
          firstName = response.data?.doctor_profile?.firstName ?? "";
          lastName = response.data?.doctor_profile?.lastName ?? "";
        } //
        else if (userRole == roles.supervisor) {
          firstName = response.data?.supervisor_profile?.firstName ?? "";
          lastName = response.data?.supervisor_profile?.lastName ?? "";
        }

        dispatch(
          updateUserFirstNameAndLastName(firstName, lastName, centerList)
        );
        dispatch(updateUserProfileImageUrl(profilePicUrl));

        dispatch({
          type: actionTypes.GET_MY_PROFILE_SUCCESS,
          payload: {
            getMyProfilePending: false,
            getMyProfileSuccess: true,
            getMyProfileError: null,
            myProfileData: response.data,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.GET_MY_PROFILE_ERROR,
          payload: {
            getMyProfilePending: false,
            getMyProfileSuccess: false,
            getMyProfileError: true,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function updateMyProfile(data, callback) {
  console.log("updateMyProfile  :: ", data);

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_MY_PROFILE_PENDING,
      payload: {
        updateMyProfilePending: true,
        updateMyProfileSuccess: false,
        updateMyProfileError: null,
      },
    });
    dispatch(showLoader());

    Api.post("auth/update/my/profile", data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.UPDATE_MY_PROFILE_SUCCESS,
          payload: {
            updateMyProfilePending: false,
            updateMyProfileSuccess: true,
            updateMyProfileError: null,
            securityQuestions: response.data,
          },
        });

        dispatch(updateUserFirstNameAndLastName(data.firstName, data.lastName));

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.UPDATE_MY_PROFILE_ERROR,
          payload: {
            updateMyProfilePending: false,
            updateMyProfileSuccess: false,
            updateMyProfileError: true,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function updateUserProfileImageUrl(url) {
  var loginUserData = getState().auth.loginUser;
  loginUserData.data = { ...loginUserData.data, profilePicture: url };

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_PROFILE_IMAGE,
      payload: {
        loginUser: loginUserData,
        profilePicture: url,
      },
    });
  };
}

export function updateHospitalFranchiseLogo(url) {
  var loginUserData = getState().auth.loginUser;
  loginUserData.userProfile = {
    ...loginUserData.userProfile,
    franchiseLogo: url,
  };

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_HOSPITAL_FRANHISE_LOGO,
      payload: {
        loginUser: loginUserData,
        franchiseLogo: url,
      },
    });
  };
}

export function updateUserFirstNameAndLastName(
  firstName,
  lastName,
  centerList = null // Used only for center admin user
) {
  var loginUserData = getState().auth.loginUser;

  loginUserData.userProfile = {
    ...loginUserData.userProfile,
    firstName: firstName,
    lastName: lastName,
    centerList: centerList,
  };

  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_NAMES_DATA,
      payload: {
        loginUser: loginUserData,
      },
    });
  };
}

export function verifyEmailForForgotPassword(fields, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.VERIFY_EMAIL_FOR_OTP_PENDING,
      payload: {
        verifyEmailForOtpPending: true,
        verifyEmailForOtpSuccess: false,
        verifyEmailForOtpError: false,
      },
    });
    let data = {
      userName: fields?.email.trim(),
    };
    dispatch(showLoader());
    console.log("data", data);

    Api.post("auth/password/forgot", data)

      .then((response) => {
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.VERIFY_EMAIL_FOR_OTP_SUCCESS,
          payload: {
            verifyEmailForOtpPending: false,
            verifyEmailForOtpSuccess: true,
            verifyEmailForOtpError: false,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response.message,
          })
        );

        // history.push(
        //   "/reset_password?email=" + email + "&token=" + response.data.otp
        // );
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.VERIFY_EMAIL_FOR_OTP_ERROR,
          payload: {
            verifyEmailForOtpPending: false,
            verifyEmailForOtpSuccess: false,
            verifyEmailForOtpError: true,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function resendOtp(fields, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESEND_OTP_PENDING,
      payload: {
        resendOtpPending: true,
        resendOtpSuccess: false,
        resendOtpError: false,
      },
    });
    let data = {
      userName: fields?.email.trim(),
    };
    dispatch(showLoader());
    console.log("data", data);

    Api.post("auth/otp/resend", data)

      .then((response) => {
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.RESEND_OTP_SUCCESS,
          payload: {
            resendOtpPending: false,
            resendOtpSuccess: true,
            resendOtpError: false,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: response?.message,
          })
        );

        // history.push(
        //   "/reset_password?email=" + email + "&token=" + response.data.otp
        // );
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.RESEND_OTP_ERROR,
          payload: {
            resendOtpPending: false,
            resendOtpSuccess: false,
            resendOtpError: true,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function resetUserPasswordUsingOTP(fields, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_PASSWORD_BY_OTP_PENDING,
      payload: {
        resetPasswordByOTPPending: true,
        resetPasswordByOTPSuccess: false,
        resetPasswordByOTPError: false,
      },
    });
    let data = {
      userName: fields?.email.trim(),
      newPassword: fields?.password,
      otp: fields?.emailOtp.trim(),
    };
    dispatch(showLoader());
    console.log("data", data);

    Api.post("auth/password/reset", data)

      .then((response) => {
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response);
        }

        dispatch({
          type: actionTypes.RESET_PASSWORD_BY_OTP_SUCCESS,
          payload: {
            resetPasswordByOTPPending: false,
            resetPasswordByOTPSuccess: true,
            resetPasswordByOTPError: false,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "success",
            msg: "Password Reset Successfully",
          })
        );
        history.push(LOGIN);
      })
      .catch((err) => {
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.RESET_PASSWORD_BY_OTP_ERROR,
          payload: {
            resetPasswordByOTPPending: false,
            resetPasswordByOTPSuccess: false,
            resetPasswordByOTPError: true,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function resetUserPasswordByAdmin(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_USER_PASSWORD_BY_ADMIN_PENDING,
      payload: {
        resetUserPasswordByAdminPending: false,
        resetUserPasswordByAdminSuccess: false,
        resetUserPasswordByAdminError: null,
      },
    });

    dispatch(showLoader());
    Api.post("auth/password/reset/user", data)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.RESET_USER_PASSWORD_BY_ADMIN_SUCCESS,
          payload: {
            resetUserPasswordByAdminPending: false,
            resetUserPasswordByAdminSuccess: true,
            resetUserPasswordByAdminError: null,
          },
        });

        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }
        dispatch({
          type: actionTypes.RESET_USER_PASSWORD_BY_ADMIN_ERROR,
          payload: {
            resetUserPasswordByAdminPending: false,
            resetUserPasswordByAdminSuccess: true,
            resetUserPasswordByAdminError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
