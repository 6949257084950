export const actionTypes = {
  GET_DEVICE_PENDING: "GET_DEVICE_PENDING",
  GET_DEVICE_SUCCESS: "GET_DEVICE_SUCCESS",
  GET_DEVICE_ERROR: "GET_DEVICE_ERROR",

  GET_DEVICE_LIST_WITH_FILTER_PENDING: "GET_DEVICE_LIST_WITH_FILTER_PENDING",
  GET_DEVICE_LIST_WITH_FILTER_SUCCESS: "GET_DEVICE_LIST_WITH_FILTER_SUCCESS",
  GET_DEVICE_LIST_WITH_FILTER_ERROR: "GET_DEVICE_LIST_WITH_FILTER_ERROR",

  GET_DEVICE_DETAILS_BY_ID_PENDING: "GET_DEVICE_DETAILS_BY_ID_PENDING",
  GET_DEVICE_DETAILS_BY_ID_SUCCESS: "GET_DEVICE_DETAILS_BY_ID_SUCCESS",
  GET_DEVICE_DETAILS_BY_ID_ERROR: "GET_DEVICE_DETAILS_BY_ID_ERROR",

  GET_DEVICE_TYPE_LIST_PENDING: "GET_DEVICE_TYPE_LIST_PENDING",
  GET_DEVICE_TYPE_LIST_SUCCESS: "GET_DEVICE_TYPE_LIST_SUCCESS",
  GET_DEVICE_TYPE_LIST_ERROR: "GET_DEVICE_TYPE_LIST_ERROR",

  ADD_DEVICES_IN_BULK_PENDING: "ADD_DEVICES_IN_BULK_PENDING",
  ADD_DEVICES_IN_BULK_SUCCESS: "ADD_DEVICES_IN_BULK_SUCCESS",
  ADD_DEVICES_IN_BULK_ERROR: "ADD_DEVICES_IN_BULK_ERROR",

  ADD_DEVICE_PENDING: "ADD_DEVICE_PENDING",
  ADD_DEVICE_SUCCESS: "ADD_DEVICE_SUCCESS",
  ADD_DEVICE_ERROR: "ADD_DEVICE_ERROR",

  EDIT_DEVICE_PENDING: "EDIT_DEVICE_PENDING",
  EDIT_DEVICE_SUCCESS: "EDIT_DEVICE_SUCCESS",
  EDIT_DEVICE_ERROR: "EDIT_DEVICE_ERROR",

  DELETE_DEVICE_PENDING: "DELETE_DEVICE_PENDING",
  DELETE_DEVICE_SUCCESS: "DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_ERROR: "DELETE_DEVICE_ERROR",

  DELETE_DEVICES_IN_BULK_PENDING: "DELETE_DEVICES_IN_BULK_PENDING",
  DELETE_DEVICES_IN_BULK_SUCCESS: "DELETE_DEVICES_IN_BULK_SUCCESS",
  DELETE_DEVICES_IN_BULK_ERROR: "DELETE_DEVICES_IN_BULK_ERROR",

  CHECKIN_DEVICE_PENDING: "CHECKIN_DEVICE_PENDING",
  CHECKIN_DEVICE_SUCCESS: "CHECKIN_DEVICE_SUCCESS",
  CHECKIN_DEVICE_ERROR: "CHECKIN_DEVICE_ERROR",

  REPORT_DEVICE_PENDING: "REPORT_DEVICE_PENDING",
  REPORT_DEVICE_SUCCESS: "REPORT_DEVICE_SUCCESS",
  REPORT_DEVICE_ERROR: "REPORT_DEVICE_ERROR",

  REMOVE_TECHNICIAN_FOR_DEVICE_PENDING: "REMOVE_TECHNICIAN_FOR_DEVICE_PENDING",
  REMOVE_TECHNICIAN_FOR_DEVICE_SUCCESS: "REMOVE_TECHNICIAN_FOR_DEVICE_SUCCESS",
  REMOVE_TECHNICIAN_FOR_DEVICE_ERROR: "REMOVE_TECHNICIAN_FOR_DEVICE_ERROR",

  GET_SEVERITY_LIST_FOR_DEVICE_PENDING: "GET_SEVERITY_LIST_FOR_DEVICE_PENDING",
  GET_SEVERITY_LIST_FOR_DEVICE_SUCCESS: "GET_SEVERITY_LIST_FOR_DEVICE_SUCCESS",
  GET_SEVERITY_LIST_FOR_DEVICE_ERROR: "GET_SEVERITY_LIST_FOR_DEVICE_ERROR",

  RESET_DEVICE_DATA: "RESET_DEVICE_DATA",
};

const initialState = {
  getDevicePending: false,
  getDeviceSuccess: false,
  getDeviceError: null,

  getDeviceListWithFilterPending: false,
  getDeviceListWithFilterSuccess: false,
  getDeviceListWithFilterError: null,

  getSeverityListForErrorLogPending: false,
  getSeverityListForErrorLogSuccess: false,
  getSeverityListForErrorLogError: null,

  getDeviceDetailsByIdPending: false,
  getDeviceDetailsByIdSuccess: false,
  getDeviceDetailsByIdError: null,

  getDeviceTypeListPending: false,
  getDeviceTypeListSuccess: false,
  getDeviceTypeListError: null,

  reportDevicePending: false,
  reportDeviceSuccess: false,
  reportDeviceError: null,

  addDevicesInBulkPending: false,
  addDevicesInBulkSuccess: false,
  addDevicesInBulkError: null,

  addDevicePending: false,
  addDeviceSuccess: false,
  addDeviceError: null,

  editDevicePending: false,
  editDeviceSuccess: false,
  editDeviceError: null,

  deleteDevicePending: false,
  deleteDeviceSuccess: false,
  deleteDeviceError: null,

  deleteDevicesInBulkPending: false,
  deleteDevicesInBulkSuccess: false,
  deleteDevicesInBulkError: null,

  removeTechnicianForDevicePending: false,
  removeTechnicianForDeviceSuccess: false,
  removeTechnicianForDeviceError: null,

  checkInDevicePending: false,
  checkInDeviceSuccess: false,
  checkInDeviceError: null,

  deviceList: [],
  filteredDeviceList: [],
  reportSeverityList: [],
  selectedCheckInDevice: null,
  filteredDeviceCount: 0,
};

export default function device(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_DEVICE_PENDING:
    case actionTypes.GET_DEVICE_SUCCESS:
    case actionTypes.GET_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DEVICE_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_DEVICE_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_DEVICE_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DEVICE_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_DEVICE_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_DEVICE_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DEVICE_TYPE_LIST_PENDING:
    case actionTypes.GET_DEVICE_TYPE_LIST_SUCCESS:
    case actionTypes.GET_DEVICE_TYPE_LIST_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_DEVICES_IN_BULK_PENDING:
    case actionTypes.ADD_DEVICES_IN_BULK_SUCCESS:
    case actionTypes.ADD_DEVICES_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_DEVICE_PENDING:
    case actionTypes.ADD_DEVICE_SUCCESS:
    case actionTypes.ADD_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_DEVICE_PENDING:
    case actionTypes.EDIT_DEVICE_SUCCESS:
    case actionTypes.EDIT_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_DEVICE_PENDING:
    case actionTypes.DELETE_DEVICE_SUCCESS:
    case actionTypes.DELETE_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_DEVICES_IN_BULK_PENDING:
    case actionTypes.DELETE_DEVICES_IN_BULK_SUCCESS:
    case actionTypes.DELETE_DEVICES_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.CHECKIN_DEVICE_PENDING:
    case actionTypes.CHECKIN_DEVICE_SUCCESS:
    case actionTypes.CHECKIN_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_PENDING:
    case actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_SUCCESS:
    case actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_DEVICE_DATA:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
