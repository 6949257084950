import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../constants/roles";
import { showLoader, hideLoader } from "../../../../store/loader/loaderActions";
import { getMyProfile } from "../../../../store/auth/authActions";
import SuperAdminDashboardContainer from "./SuperAdmin/SuperAdminDashboardContainer";
import HospitalAdminDashboardContainer from "./HospitalAdmin/HospitalAdminDashboardContainer";
import CenterAdminDashboardContainer from "./CenterAdmin/CenterAdminDashboardContainer";
import DoctorDashboardContainer from "./Doctor/DoctorDashboardContainer";
import TechnicianDashboardContainer from "./Technician/TechnicianDashboardContainer";
import SupervisorContainer from "./Supervisor/SupervisorDashboardContainer";
import EcgReportsContainer from "../EcgReports/EcgReportsContainer";

export class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
    };
  }

  componentDidMount() {
    // Set role of user in state variable
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    this.setState({ userRole: loginUserRole });

    // Fetch latest user profile data every time dashboard screen is loaded / refreshed
    this.props.getMyProfile((data) => {});
  }

  componentWillUnmount() {}

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.superAdmin) {
      return <SuperAdminDashboardContainer />;
    } else if (
      this.state.userRole === roles.hospitalAdmin ||
      this.state.userRole === roles.reportingHospitalAdmin
    ) {
      return <HospitalAdminDashboardContainer />;
    } else if (this.state.userRole === roles.centerAdmin) {
      return <CenterAdminDashboardContainer />;
    } else if (this.state.userRole === roles.doctor) {
      return <DoctorDashboardContainer />;
    } else if (this.state.userRole === roles.technician) {
      return <TechnicianDashboardContainer />;
    } else if (this.state.userRole === roles.supervisor) {
      return <EcgReportsContainer />;
    }
    //  else if (this.state.userRole === roles.supervisor) {
    //   return <SupervisorContainer />;
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
  };
};

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
  getMyProfile,
})(DashboardContainer);
