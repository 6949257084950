import React from "react";
import CardView from "../../../controls/Card/CardView";
import logoutIcon from "../../../../assets/images/icons/logout.png";
import myProfile from "../../../../assets/images/icons/my_profile.png";
import hospital_line from "../../../../assets/images/icons/hospital_line.png";

import "./componentStyles.scss";
import { roles } from "../../../../constants/roles";

const DropdownCard = ({
  loginUser,
  onMyProfileClick,
  // onHospitalInfoClick,
  onHospitalInfoClicked,
  onLogoutClick,
}) => {
  const userRole = loginUser?.data?.userRoles[0].name;
  return (
    <div className="dropdown-option-wrapper">
      <CardView extraStyle="dropdown-card-control" cardstyle="card-style">
        <div className="dropdown-options">
          <div className="option" onClick={onMyProfileClick}>
            <img className="menu-icon" src={myProfile} alt="icon" /> My Profile
          </div>

          {userRole === roles.hospitalAdmin && (
            <div className="option" onClick={onHospitalInfoClicked}>
              <img className="menu-icon" src={hospital_line} alt="icon" />{" "}
              Hospital Info
            </div>
          )}

          <div className="option" onClick={onLogoutClick}>
            <img className="menu-icon" src={logoutIcon} alt="icon" /> Logout
          </div>
        </div>
      </CardView>
    </div>
  );
};

export default DropdownCard;
