import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { login } from "../../../store/auth/authActions";
import { DASHBOARD_NEW, FORGOTPASSWORD } from "../../../navigation/routes";
import { history } from "../../../store/configure/configureStore";
import { globalConstants } from "../../../constants/globalConstants";
import {
  trimFormData,
  extractLoginDetailsFromSecureStorage,
  saveLoginDetailsInSecureStorage,
  removeLoginDetailsFromSecureStorage,
} from "../../../utils/common";
import * as validator from "../../../utils/validator";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import auth from "../../restricted/auth";
import LoginView from "./LoginView";
import secureLocalStorage from "react-secure-storage";

export class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: "",
        password: "",
      },
      errors: {},
      rememberMeSelected: false,
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    var rememberMeSelected = secureLocalStorage.getItem(
      globalConstants.REMEMBER_ME_KEY
    );

    if (rememberMeSelected) {
      var decryptedData = extractLoginDetailsFromSecureStorage();

      // Update fields in state for auto filling of email and password
      this.setState({
        fields: {
          email: decryptedData.email,
          password: decryptedData.password,
        },
        errors: {},
        rememberMeSelected: true,
      });
    }
  }

  componentWillUnmount() {}

  onClickEnterButton = (e) => {
    if (e.keyCode === 13) {
      console.log("hello");
      this.onLogin();
    }
  };

  onForgotPasswordClick = () => {
    history.push(FORGOTPASSWORD);
  };

  handleFieldsChange = ({ target }) => {
    const field = target.name;
    this.setState({
      fields: { ...this.state.fields, [field]: target.value },
      errors: {},
    });
  };

  onLogin = () => {
    var fields = this.state.fields;
    const validatorFields = {
      required: ["email", "password"],
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFormData(fields)
    );
    this.setState({ ...this.state, errors });

    if (!Object.keys(errors).length) {
      // If 'Remember Me' is checked - store data in secure storage, then make API call
      if (this.state.rememberMeSelected) {
        saveLoginDetailsInSecureStorage(this.state.fields);
      } else {
        removeLoginDetailsFromSecureStorage();
      }

      // Call Login API
      var trimmedFields = trimFormData(fields);
      this.props.login(trimmedFields.email, fields.password);
    }
  };

  onRememberMeCheckBoxChange = () => {
    this.setState({ rememberMeSelected: !this.state.rememberMeSelected });
  };

  render() {
    if (auth.isAuthenticated()) {
      // if (this.props.authSuccess == true) {
      console.log("Authenticated!", auth, auth.isAuthenticated());
      history.push(DASHBOARD_NEW);
    }

    return (
      <LoginView
        isLoader={this.props.isLoader}
        fields={this.state.fields}
        errors={this.state.errors}
        onFieldsChange={this.handleFieldsChange}
        onKeyDown={this.onClickEnterButton}
        onLogin={this.onLogin}
        //
        onForgotPasswordClick={this.onForgotPasswordClick}
        //
        rememberMeSelected={this.state.rememberMeSelected}
        onRememberMeCheckBoxChange={this.onRememberMeCheckBoxChange}
      />
    );
  }
}

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authSuccess: state.auth.authSuccess,
  authData: state.auth,
  loginUser: state.auth.loginUser,
  isLoader: state.loader.isLoader,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: login,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
