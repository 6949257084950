import React from "react";
import CardView from "../../../controls/Card/CardView";
import DeviceList from "../../../pages/NewUIPages/Components/Lists/DeviceList";
import {
  TextArea,
  SearchView,
  ThemedButton,
  Select,
  Input,
  SelectOption,
  FormSearchView,
  Checkbox,
} from "../../../controls";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import { FaPlus } from "react-icons/fa6";
import {
  AiOutlineInbox,
  AiOutlineFileExcel,
  AiOutlineDelete,
} from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineDownload } from "react-icons/ai";
import PopupDialog from "../Components/Other/PopupDialog";
import "./DevicesStyles.scss";
import StepperView from "../../../controls/Stepper/StepperView";
import { roles } from "../../../../constants/roles";

const DevicesView = (props) => {
  const technicianListForSelect = Array.isArray(props.techniciansList)
    ? props.techniciansList.map((item) => {
        var tecnicianName = `${
          (item?.firstName !== null && item?.firstName !== undefined
            ? `${item?.firstName
                .charAt(0)
                .toUpperCase()}${item?.firstName.slice(1)}`
            : "") +
          " " +
          (item?.lastName !== null && item?.lastName !== undefined
            ? `${item?.lastName.charAt(0).toUpperCase()}${item?.lastName.slice(
                1
              )}`
            : "")
        }`;

        return { value: item.id, label: tecnicianName };
      })
    : [];

  //--------------render popup content ------------------
  const renderAddBulkDevicesContents = () => {
    const selectHospital = () => {
      return (
        <div className="select-hospital-tab">
          <div className="block-level-group">
            <label className="form-label">Assign Hospital :</label>
            <div className="value-item extra">
              <FormSearchView
                Options={props.hospitalList}
                placeholder="Search Hospital Name"
                onChange={props.onAddBulkDeviceFieldChange(
                  "assignHospitalInBulk"
                )}
                lazyLoading={true}
                onInputChange={props.onHospitalSearchChange}
                customInputSearch={true}
                previousValue={props.assignHospitalInBulk}
                onScroll={props.onScrollHospitalList}
                error={props.errors["assignHospitalInBulk"]}
              />
            </div>
            <div style={{ width: "20%" }} />
          </div>
        </div>
      );
    };
    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const temp = document.getElementById("border-active");
      temp.style.border = "1px dashed #00B48A";
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const temp = document.getElementById("border-active");
      temp.style.border = "";
    };

    const uploadBox = () => {
      return (
        <div className="upload-box-file-details">
          <div className="upload-box">
            <form
              style={
                props.file.length > 0 ? { border: "1px solid #D9D9D9" } : {}
              }
              onClick={props.handleFileInputClick}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={props.handleFileDropClick}
              id="border-active"
            >
              <input
                className="file-input"
                type="file"
                name="file"
                ref={props.fileInputRef}
                hidden
                onChange={props.onAddBulkDeviceFieldChange("file")}
              />
              <AiOutlineInbox size={40} color="#00B48A" />
              <div className="text-head-sub">
                <text className="text-head">
                  <span>Click</span> or drag file to this area to upload
                </text>
                <text className="text-sub">only CSV (max 6MB)</text>
              </div>
            </form>
            <div className="file-error">{props.errors["file"]}</div>
          </div>
          {props.file.length > 0 ? (
            <div className="file-details">
              <div className="details">
                <div className="file-icon">
                  <AiOutlineFileExcel size={28} color="#00B48A" />
                </div>
                <div className="file-name">{props.fileName}</div>
                <div className="delete-icon">
                  <AiOutlineDelete
                    size={20}
                    cursor={"pointer"}
                    color="rgba(0, 0, 0, 0.45)"
                    onClick={props.handleDeleteFile}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    };
    const alert = () => {
      return (
        <div className="alert-modal-loading-area">
          <div className="alert-modal-content">
            <img className="delete-icon" src={logoutIcon} alt="icon" />
            <div className="alert-head-sub-text">
              <text className="alert-head">{`Are you sure you want to add devices?`}</text>
              <text className="alert-sub">
                Assigned Hospital :
                {props.assignHospitalInBulk.label
                  ? ` ${
                      props.assignHospitalInBulk?.label ??
                      "No Hospital Selected"
                    }, ${
                      props.detailsAssignHospitalInBulk?.addressInfo
                        ?.locationName ?? ""
                    }, ${
                      props.detailsAssignHospitalInBulk?.addressInfo
                        ?.districtInfo?.name ?? ""
                    }, ${
                      props.detailsAssignHospitalInBulk?.addressInfo?.stateInfo
                        ?.name ?? ""
                    }`
                  : " No Hospital Selected"}
              </text>
            </div>
          </div>
          <section className="loading-area">
            {/* <div className="loading-bar">
              <div
                className="loading"
                style={{ width: `${props.pregressPer}%` }}
              ></div>
            </div> */}
          </section>
        </div>
      );
    };
    const steps = ["Select File", "Assign Hospital", "Add Devices"];
    const multiSteps = [uploadBox(), selectHospital(), alert()];
    return (
      <div style={{ paddingTop: "10px" }}>
        <StepperView
          steps={steps}
          bodySteps={multiSteps}
          parentStep={props.activeStep}
        />
      </div>
    );
  };

  const renderDisableDevicePopupContents = () => {
    return (
      <div>
        <h3 className="popup_text title">
          <FiAlertTriangle size={20} style={{ marginRight: "10px" }} />
          Unauthorized
        </h3>
        <text className="popup_text">
          You are currently not authorized to disable device. Kindly reach out
          to Administrator
        </text>
        <div style={{ height: "30px" }} />
      </div>
    );
  };

  const renderDeleteDevicePopupContents = () => {
    const serialNo = props.deviceInfo?.serialNo ?? "";

    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>{`Are you sure you want to Delete Device ${serialNo} ?`}</text>
      </div>
    );
  };

  const renderDeleteBulkDevicePopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        {deleteCount > 1 ? (
          <text>{`Are you sure you want to Delete ${deleteCount} Devices ?`}</text>
        ) : (
          <text>{`Are you sure you want to Delete ${deleteCount} Device ?`}</text>
        )}
      </div>
    );
  };

  const renderAddNewDevicesContents = () => {
    return (
      <div className="add-new-device-form">
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span> Device ID :
          </label>
          <div className="value-item">
            <Input
              charLimit={14}
              showCharLimit={true}
              className="input-wrapper"
              placeholder="Enter Device ID"
              value={props.addNewDevicefields.deviceId}
              onChange={props.onAddDeviceFieldChange("deviceId")}
              error={props.errors["deviceId"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span> Device Type :
          </label>
          <div className="value-item">
            <Select
              label={null}
              placeholder="Select Device Type"
              className="select-device-type"
              isSearchable={
                Object.keys(props.deviceTypeList).length > 0 ? true : false
              }
              options={props.deviceTypeList}
              value={props.addNewDevicefields.deviceType}
              onChange={props.onAddDeviceFieldChange("deviceType")}
              error={props.errors["deviceType"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">Assign Hospital :</label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.hospitalList}
              placeholder="Search Hospital Name"
              onChange={props.onAssignHospitalChange}
              lazyLoading={true}
              previousValue={props.addNewDevicefields.assignHospital}
              onInputChange={props.onHospitalSearchChange}
              customInputSearch={true}
              searchText={props.searchHospitalText}
              onScroll={props.onScrollHospitalList}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">Assign Center :</label>
          <div className="value-item extra">
            <FormSearchView
              Options={props.centerList}
              placeholder="Search Center Name"
              disable={!props.centerList.length > 0}
              onChange={props.onAssignCenterChange}
              lazyLoading={false}
              previousValue={props.addNewDevicefields.assignCenter}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">Assign Technician :</label>
          <div className="value-item extra">
            <FormSearchView
              Options={technicianListForSelect}
              placeholder="Search Technician Name"
              onChange={props.onAssignTechnicianChange}
              disable={
                !props.techniciansList.length > 0 &&
                !props.searchTechnicianText.length > 0
              }
              onInputChange={props.onTechnicianSearchChange}
              previousValue={props.addNewDevicefields.assignTechnician}
              lazyLoading={true}
              onScroll={props.onScrollTechnicianList}
              customInputSearch={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
      </div>
    );
  };

  const renderReportDevicePopupContents = () => {
    const location = props.deviceInfo?.centerDeviceInfo?.center?.addressInfo
      ?.locationName
      ? props.deviceInfo.centerDeviceInfo.center.addressInfo.locationName
      : "-";

    const district = props.deviceInfo?.centerDeviceInfo?.center?.addressInfo
      ?.districtInfo?.name
      ? ", " +
        props.deviceInfo.centerDeviceInfo.center.addressInfo.districtInfo.name
      : "";

    const state = props.deviceInfo?.centerDeviceInfo?.center?.addressInfo
      ?.stateInfo?.name
      ? ", " +
        props.deviceInfo.centerDeviceInfo.center.addressInfo.stateInfo.name
      : "";
    const addressDetails = `${location}${district}${state}`;
    return (
      <>
        <div className="form-wrapper-container">
          <div className="form-contents">
            <div className="fields">
              <text className="text">
                <span className="required-star">* </span> To :
              </text>
              <Input
                className="select-option"
                placeholder={"Enter Email"}
                type="email"
                size="sm"
                onChange={(e) => {
                  props.onMailToChange(e.target.value, e.target.name);
                }}
                value={props.fields["email"]}
                name={"email"}
                error={props.emailError}
              />
            </div>
            <div className="fields">
              <text className="text">
                <span className="required-star">* </span> Severity :
              </text>
              <Select
                label={null}
                placeholder="Select Severity"
                className="select-option"
                isSearchable={
                  Object.keys(props.reportSeverityList).length > 0
                    ? true
                    : false
                }
                options={props.reportSeverityList}
                value={props.deviceReportSeverity}
                onChange={(val) => {
                  console.log("SELECT : ", val);
                  props.onSeverityChange(val);
                }}
                error={props.severityError}
              />
            </div>
            <div className="fields">
              <text className="text">
                <span className="required-star">* </span> Description :
              </text>
              <Input
                className="select-option"
                placeholder={"Enter Description"}
                type="text"
                onChange={(e) => {
                  props.onDescriptionChange(e.target.value);
                }}
                size="sm"
                value={props.description}
                name={"description"}
                error={props.descriptionError}
              />
            </div>
            <div className="fields upper">
              <text className="text">Remarks :</text>
              <TextArea
                label={null}
                className="wrapper-option"
                placeholder={"Please enter your remarks if any"}
                value={props.remark}
                onChange={(e) => {
                  props.onRemarksChange(e.target.value);
                }}
                error={props.remarksError}
              />
            </div>
          </div>
          <div className="form-info">
            <div className="info-1">
              <text className="label">Device ID</text>
              <text className="value">{props.deviceInfo?.serialNo ?? "-"}</text>
            </div>
            <div className="separator" />
            <div className="info-1">
              <text className="label">Device Type</text>
              <text className="value">
                {props.deviceInfo?.deviceType?.type ?? "-"}
              </text>
            </div>
            <div className="separator" />
            <div className="info-2">
              <text className="label">Device Location</text>
              <text className="value">{addressDetails}</text>
            </div>
          </div>
          <div className="form-info">
            <div className="info-2">
              <text className="label">Hospital</text>
              <text className="value">
                {props.deviceInfo.hospital?.hospitalInfo?.hospitalName ?? "-"}
              </text>
            </div>
            <div className="separator" />
            <div className="info-1">
              <text className="label">Status</text>
              <text className="value">
                {props.deviceInfo?.status == 1
                  ? "Online"
                  : props.deviceInfo?.status == 2
                  ? "Offline"
                  : props.deviceInfo?.status == 3
                  ? "Error"
                  : "-"}
              </text>
            </div>
          </div>
        </div>
      </>
    );
  };

  function renderAssignTechnicianPopupContents() {
    return (
      <div className="report-popup-container">
        {/* <Select
          label={
            "Device ID : " + props.selectedDeviceOnActionClick?.serialNo ?? "-"
          }
          placeholder="Select Technician"
          isSearchable={
            Object.keys(technicianListForSelect).length > 0 ? true : false
          }
          onChange={(val) => {
            props.onTechnicianSelected(val);
            console.log(val, "seleted");
          }}
          options={technicianListForSelect}
        /> */}
        <div className="search-view">
          <text className="search-label">
            {`Device ID : ${
              props.selectedDeviceOnActionClick?.serialNo ?? "-"
            }`}
          </text>
          <FormSearchView
            Options={technicianListForSelect}
            placeholder="Select Technician"
            onChange={props.onTechnicianSelected}
            onInputChange={props.onTechnicianSearchChange}
            customInputSearch={true}
            previousValue={
              props.userRole === roles.centerAdmin
                ? ""
                : props.addNewDevicefields.assignTechnician
            }
            lazyLoading={true}
            onScroll={props.onScrollTechnicianList}
          />
        </div>
      </div>
    );
  }

  //---------------------------------------------------------------

  return (
    <div className="devices-container-new">
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wraper">
          <div className="title">Total Devices : {props.totalDevices}</div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Devices"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                  updatedCurrentValue={props.searchStringParam}
                />

                {props.userRole === roles.superAdmin ? (
                  <>
                    <ThemedButton
                      title="Add Bulk Devices"
                      size={"large"}
                      icon={<FaPlus size={15} color="#000" />}
                      onClick={() => {
                        props.onAddBulkDevicesClick();
                      }}
                      styles="grey-outline large"
                    />

                    <ThemedButton
                      title="Add New Device"
                      size={"large"}
                      icon={<FaPlus size={15} color="#fff" />}
                      onClick={() => {
                        props.onAddNewDevicesClick();
                      }}
                      styles="primary medium"
                    />
                  </>
                ) : // props.userRole === roles.hospitalAdmin ? (
                //   <ThemedButton
                //     title="Download"
                //     size={"medium"}
                //     icon={<AiOutlineDownload size={15} color="#fff" />}
                //     onClick={() => {}}
                //     styles="primary medium"
                //   />
                null}
              </>
            )}

            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkDeviceDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <DeviceList
          userRole={props.userRole}
          assignedDevice={props.assignedDevice}
          //
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          totalDevices={props.totalDevices}
          deviceList={props.deviceList}
          //
          onReportDeviceClick={props.onReportDeviceClick}
          onEditDeviceClick={props.onEditDeviceClick}
          onDeleteDeviceClick={props.onDeleteDeviceClick}
          onAssignTechnicianClick={props.onAssignTechnicianClick}
          onDisableDeviceClick={props.onDisableDeviceClick}
          onViewReportsClick={props.onViewReportsClick}
          //
          onRowClick={props.onRowClick}
          //
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          onDeviceListFilterClick={props.onDeviceListFilterClick}
          onDeviceListSortClick={props.onDeviceListSortClick}
        />
      </CardView>

      <PopupDialog
        show={props.showAddBulkDevicesPopup}
        title={"Add Bulk Devices"}
        showCloseIcon={true}
        renderBodyContent={renderAddBulkDevicesContents}
        cancelButtonTitle={props.activeStep !== 0 ? "Back" : "Cancel"}
        onclickCancel={props.onCloseAddBulkDevicePopup}
        // hideCancelButton={props.activeStep !== 0}
        showExtraButton={props.activeStep == 1}
        extraButtonStyle="primary-outline"
        extraButtonTitle={"Skip"}
        onClickExtra={props.onSkipSelectHospitalStageClick}
        onclickConfirm={
          props.activeStep === 2
            ? props.addBulkDevicesConfirmClick
            : props.handleNext
        }
        confirmButtonTitle={
          props.activeStep === 1
            ? "Assign"
            : props.activeStep === 2
            ? "Add"
            : "Next"
        }
        // disabledExtraButton={!props.disableNextButton}
        disabledConfirmButton={
          props.activeStep === 2 ? false : props.disableNextButton
        }
        //
        overrideFunction={props.handleBack}
        overrideFunctionEnable={props.activeStep != 0}
        popupSize={"md"}
      />

      <PopupDialog
        show={
          props.showEditDevicesPopup
            ? props.showEditDevicesPopup
            : props.showAddNewDevicesPopup
        }
        title={props.showEditDevicesPopup ? "Edit Device" : "Add New Device"}
        showCloseIcon={true}
        renderBodyContent={renderAddNewDevicesContents}
        confirmButtonTitle={props.showEditDevicesPopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditDevicesPopup
            ? props.onEditDeviceConfirmClick
            : props.onAddNewDevicesConfirmClick
        }
        onclickCancel={
          props.showEditDevicesPopup
            ? props.onCloseEditDevicePopup
            : props.onCloseAddDevicePopup
        }
        // extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
      />

      <PopupDialog
        show={props.showReportDevicePopup}
        title={`Report Device ${props.deviceInfo?.serialNo ?? "-"}`}
        showCloseIcon={true}
        renderBodyContent={renderReportDevicePopupContents}
        confirmButtonTitle={"Send Email"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onSendEmailButtonClick}
        onclickCancel={props.onCloseReportDevicePopup}
      />

      <PopupDialog
        show={props.showDeleteDevicePopup}
        title={`Delete Device`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteDevicePopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteDeviceConfirmClick}
        onclickCancel={props.closeAllPopups}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDeleteDeviceConfirmPopup}
        title={`Delete Devices`}
        showCloseIcon={true}
        renderBodyContent={renderDeleteBulkDevicePopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkDeviceDeleteConfirmClick}
        onclickCancel={props.closeAllPopups}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showDisableDevicePopup}
        title={"Disable Device"}
        showCloseIcon={true}
        renderBodyContent={renderDisableDevicePopupContents}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.closeAllPopups}
        onclickCancel={props.closeAllPopups}
        hideButtons={true}
      />

      <PopupDialog
        show={props.showTechnicianAssignPopup}
        title={"Assign Technician"}
        showCloseIcon={true}
        renderBodyContent={renderAssignTechnicianPopupContents}
        confirmButtonTitle={"Assign"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onTechnicianAssignCofirmClick}
        onclickCancel={props.onTechnicianAssignCancelClick}
        popupSize={"md"}
      />
    </div>
  );
};

export default DevicesView;
