import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { showLoader, hideLoader } from "../../store/loader/loaderActions";
import { Header, Footer, Sidebar, TopNavBar } from "../common";
import { PopupDialog } from "../pages/NewUIPages/Components";
import { logout } from "../../store/auth/authActions";
import { roles } from "../../constants/roles";
import { history } from "../../store/configure/configureStore";
import {
  MY_PROFILE,
  HOSPITAL_INFO,
  ECG_REPORT_DETAILS,
} from "../../navigation/routes";
import logoutIcon from "../../assets/images/icons/logout-confirmation.png";
import SideBarMobile from "../common/SideBarMobile/SideBarMobile";
import TopNavBarNew from "../common/TopNavBarNew/TopNavBarNew";
import "./MainLayoutStyles.scss";

class MainLayout extends React.Component {
  closeDropDownTimeout = null;

  constructor(props) {
    super(props);

    this.state = {
      showDropdownMenuInHeader: false,
      showLogoutConfirmationPopup: false,
    };

    this.renderNewUI = this.renderNewUI.bind(this);
    this.renderOldUI = this.renderOldUI.bind(this);
    this.updateDropdownMenuVisibility =
      this.updateDropdownMenuVisibility.bind(this);

    this.onLogoClick = this.onLogoClick.bind(this);
    this.onMyProfileClick = this.onMyProfileClick.bind(this);
    this.onHospitalInfoClicked = this.onHospitalInfoClicked.bind(this);

    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onLogoutConfirmClick = this.onLogoutConfirmClick.bind(this);
    this.onLogoutCancelClick = this.onLogoutCancelClick.bind(this);
  }

  componentDidMount() {}

  renderNewUI = () => {
    return (
      <div className="new-ui-full-container">
        <Header
          loginUser={this.props.loginUser}
          profilePicture={this.props.profilePicture}
          franchiseLogo={this.props.franchiseLogo}
          //
          onLogoClick={this.onLogoClick}
          showDropdownMenu={this.state.showDropdownMenuInHeader}
          updateDropdownMenuVisibility={this.updateDropdownMenuVisibility}
          onMyProfileClick={this.onMyProfileClick}
          onHospitalInfoClicked={this.onHospitalInfoClicked}
          onLogoutClick={this.onLogoutClick}
        />

        {window.location.pathname === MY_PROFILE ||
        window.location.pathname === HOSPITAL_INFO ||
        window.location.pathname === ECG_REPORT_DETAILS ||
        this.props.loginUser?.data?.userRoles[0]?.name === roles.supervisor ? (
          <div className="navbar_placeholder_view " />
        ) : (
          <TopNavBarNew loginUser={this.props.loginUser} />
        )}

        <main
          className={`content_wrapper_scrollable 
        ${
          window.location.pathname === MY_PROFILE ||
          window.location.pathname === HOSPITAL_INFO ||
          window.location.pathname === ECG_REPORT_DETAILS
            ? "fullscreen"
            : ""
        }`}
        >
          {this.props.children}
        </main>

        <div className="footer-right">
          ©2023 Rhythms 24x7. All Rights Reserved.
        </div>
      </div>
    );
  };

  renderOldUI = (loginUser, logout) => {
    return (
      <div
        className={
          loginUser !== null &&
          loginUser.data.userRoles[0].name !== roles.technician &&
          loginUser.data.userRoles[0].name !== roles.doctor
            ? "page_content_wrapper"
            : "page_content_wrapper_set_full_width"
        }
      >
        <TopNavBar loginUser={loginUser} logout={logout} />

        <main className="padding">{this.props.children}</main>

        <footer>
          <div className="footer">©2023 Rhythms 24x7. All Rights Reserved.</div>
        </footer>
      </div>
    );
  };

  onLogoClick = () => {
    history.push("/dashboard_new");
  };

  updateDropdownMenuVisibility = () => {
    // On any click of dropdown icon, clear previous timeouts!!!
    clearTimeout(this.closeDropDownTimeout);
    this.closeDropDownTimeout = null;

    this.setState(
      {
        showDropdownMenuInHeader: !this.state.showDropdownMenuInHeader,
      },
      () => {
        if (this.state.showDropdownMenuInHeader) {
          // If upon state update dropdown menu is opened then auto close it in 4 secs using timer.
          this.closeDropDownTimeout = setTimeout(() => {
            this.setState({ showDropdownMenuInHeader: false });
          }, 4000);
        }
      }
    );
  };

  onMyProfileClick = () => {
    this.setState({ showDropdownMenuInHeader: false });
    history.push("/my_profile");
  };

  onHospitalInfoClicked = () => {
    this.setState({ showDropdownMenuInHeader: false });
    history.push("/hospital_info");
  };

  onLogoutClick = () => {
    this.setState({ showLogoutConfirmationPopup: true });
  };

  renderLogoutMessage = () => {
    return (
      <div className="logout-modal-content ">
        <img className="logout-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Logout?</text>
      </div>
    );
  };

  onLogoutConfirmClick = () => {
    this.setState({
      showLogoutConfirmationPopup: false,
      showDropdownMenuInHeader: false,
    });
    this.props.showLoader();

    setTimeout(() => {
      this.props.hideLoader();
      this.props.logout();
    }, 500);
  };

  onLogoutCancelClick = () => {
    this.setState({
      showLogoutConfirmationPopup: false,
      showDropdownMenuInHeader: false,
    });
  };

  render() {
    return (
      <>
        {this.renderNewUI()}

        <PopupDialog
          show={this.state.showLogoutConfirmationPopup}
          title={"Logout"}
          showCloseIcon={true}
          renderBodyContent={this.renderLogoutMessage}
          confirmButtonTitle={"Logout"}
          cancelButtonTitle={"Cancel"}
          onclickConfirm={this.onLogoutConfirmClick}
          onclickCancel={this.onLogoutCancelClick}
          hideButtons={false}
          popupSize={"md"}
        />
      </>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.object.isRequired,
  getState: PropTypes.func.isRequired,
  stateLoaded: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logout,
      showLoader: showLoader,
      hideLoader: hideLoader,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  loginUser: state.auth.loginUser,
  profilePicture: state.auth.profilePicture,
  franchiseLogo: state.auth.franchiseLogo,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
