import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { HiOutlineCheck } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./IconMenuOptionStyles.scss";
import ThemedButton from "../ThemedButton/ThemedButtonView";

export default function IconMenuOption({
  row,
  options,
  menuIcon,
  columnName,
  onclickEdit,
  onClickFilter,
  onclickDelete,
  onclickResetPassword,
  showSelectedItem = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // based on key execute function
  const handleActionOptionClick = (label) => {
    if (label === "Edit") {
      onclickEdit(row);
    } else if (label === "Delete") {
      onclickDelete(row);
    } else if (label === "Reset Password") {
      onclickResetPassword(row);
    }
    setSelectedOption(label);
    handleClose(); // Close the menu after handling the click
  };

  // common function for all filters
  const handleFilterOptionClick = (label) => {
    if (label) {
      onClickFilter(columnName, label);
    }
    setSelectedOption(label);
    handleClose();
  };

  const handleClearButtonCilck = (e) => {
    e.preventDefault();
    setSelectedOption(null);
    onClickFilter(columnName, "cleared"); //Todo pass when user cleared filter
    handleClose();
  };

  return (
    <div className="icon-menu-options">
      <div className="vertical-three-dots" onClick={handleClick}>
        {menuIcon}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {options?.map(({ label, icon }) => {
          return (
            <MenuItem
              onClick={
                showSelectedItem
                  ? () => handleFilterOptionClick(label)
                  : () => handleActionOptionClick(label)
              }
            >
              <div className="list">
                <div className="list-item">
                  {icon}
                  {columnName === "Severity" ? null : <text>{label}</text>}
                </div>
                {selectedOption === label && showSelectedItem && (
                  <HiOutlineCheck
                    style={{
                      transition: "opacity 0.2s ease-in-out",
                      margin: "0px 0px 0px 10px",
                    }}
                    color="rgba(0,0,0,0.25)"
                    size={20}
                  />
                )}
              </div>
            </MenuItem>
          );
        }) ?? null}
        {showSelectedItem && (
          <div>
            <hr />
            <MenuItem>
              <div className="buttons" onClick={handleClearButtonCilck}>
                <button className="clear-button">Clear</button>
              </div>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}
