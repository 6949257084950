import { connect } from "mqtt/dist/mqtt";
import { MQTT_BROKER_URL, MQTT_CHANNEL } from "../constants/env";
import auth from "../../src/components/restricted/auth";

// const brokerUrl = "mqtt://devrhythm.smartsight.in:8004";
// const client = connect("ws://test.mosquitto.org:8080");

var client = null;
var cb = {};

export const createMQTTConnection = (callback) => {
  if (!client) {
    client = connect(MQTT_BROKER_URL);
    client.on("connect", () => {
      console.log("connected mqtt");
      callback();
    });
    client.on("error", (err) => {
      console.error("Error connecting to MQTT broker:", err);
    });
    client.on("message", function (t, m) {
      if (auth.isAuthenticated()) {
        if (cb[t]) {
          cb[t](m.toString());
        }
        // cb(m.toString());
      } else if (!auth.isAuthenticated()) {
        unSubscribeToTopic(t.replace(MQTT_CHANNEL, ""));
      }
    });
  }
};

export const subscribeToTopic = (topic, callback) => {
  if (client) {
    console.log("Client Found");
    unSubscribeToTopic(topic, () => {
      if (client) {
        client.subscribe(MQTT_CHANNEL + topic, {}, (error) => {
          if (error) {
            console.log("Subscribe to topics error", error);
            return;
          } else {
            cb[MQTT_CHANNEL + topic] = callback;
            console.log(`Subscribed to ${topic}`);
          }
        });
      }
      // }, 500);
    });
  } else {
    alert("Client not Found");
  }
};

export const unSubscribeToTopic = (topic, callback = null) => {
  if (client) {
    client.unsubscribe(MQTT_CHANNEL + topic, {}, (error) => {
      if (callback) {
        callback();
      }
      if (error) {
        console.log("Unsubscribe to topics error", error);
        return;
      } else {
        console.log(`Unsubscribed to ${topic}`);
      }
    });
  }
};

export const disconnect = () => {
  if (client) {
    client.end(true);
    client = null;
  }
};
