import React from "react";
import { CardView, ThemedButton } from "../../../../controls";
import { NestedAccordionList } from "../../Components";
import { FaAngleLeft } from "react-icons/fa6";
import "../AdministratorsStyles.scss";

const AdminsHospitalsView = (props) => {
  //-------------- Hospitals > Devices/Doctors View ------------------

  const renderHospitalsAccordian = () => {
    return (
      <CardView
        extraStyle="card-container accordian-bg "
        cardstyle="card-style"
      >
        <div className="card-header-wrapper">
          <div className="title">
            <ThemedButton
              icon={<FaAngleLeft size={15} color="rgba(0,0,0,0.45)" />}
              onClick={() => {
                props.onAdminNameBackClick();
              }}
              styles="link "
            />

            {(props.selectedAdmin?.firstName !== undefined &&
            props.selectedAdmin?.firstName !== null
              ? `${props.selectedAdmin.firstName
                  .charAt(0)
                  .toUpperCase()}${props.selectedAdmin.firstName.slice(1)}`
              : "") +
              " " +
              (props.selectedAdmin?.lastName !== undefined &&
              props.selectedAdmin?.lastName !== null
                ? `${props.selectedAdmin.lastName
                    .charAt(0)
                    .toUpperCase()}${props.selectedAdmin.lastName.slice(1)}`
                : "")}
          </div>

          <div className="search-and-buttons">
            {/* 
              <SearchView placeholder="Search Hospitals" />   // TODO
            */}
          </div>
        </div>

        {props.hospitalList && props.hospitalList.length > 0 ? (
          <div style={{ paddingTop: "20px" }}>
            <NestedAccordionList dataList={props.hospitalList} />
          </div>
        ) : (
          <div className="no-centers-placeholder">
            <text>No Hospitals available</text>
          </div>
        )}
      </CardView>
    );
  };

  return (
    <div className="administrators-container-new">
      {renderHospitalsAccordian()}
    </div>
  );
};

export default AdminsHospitalsView;
