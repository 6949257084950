import React, { useState } from "react";
import {
  CardView,
  Input,
  SearchView,
  ThemedButton,
} from "../../../../controls";
import { FiRotateCw } from "react-icons/fi";
import { CiRedo } from "react-icons/ci";
import {
  EcgReportsDynamicList,
  PopupDialog,
  StatisticsCardDoctor,
} from "../../Components";
import "../DashboardStyles.scss";

const DoctorsView = (props) => {
  // console.log("DOCTORS VIEW : filteredReportList", JSON.stringify(props.filteredReportList));
  // console.log("props", props);

  function renderNewReportPopup() {
    return (
      <div className="report-popup-container">
        <div className="report-popup-inputs">
          <Input
            label={"Patient Name:"}
            value={props.newReportDetails?.patientName ?? "-"}
          />
        </div>

        <div className="report-popup-inputs">
          <Input
            className="report-popup-inputs"
            label={"Age:"}
            value={props.newReportDetails?.age ?? "-"}
          />
        </div>

        <div className="report-popup-inputs">
          <Input
            className="report-popup-inputs"
            label={"Location:"}
            value={props.newReportDetails?.location ?? "-"}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="new-dashboard-container">
      <CardView
        extraStyle="dashboard-bar-chart"
        // bodyStyle={{ width: "calc(100vw - 20px)", paddingTop: "30px" }}
      >
        <StatisticsCardDoctor
          loginUser={props.loginUser}
          userCreatedAt={props.userCreatedAt}
          previousMonthsData={props.previousMonthsData}
        />
      </CardView>

      <CardView
        extraStyle="dashboard-bar-chart"
        // bodyStyle={{ width: "calc(100vw - 20px)", paddingTop: "30px" }}
      >
        <div className="dashboard-title-container">
          <text className="title-for-reports">ECG Reports</text>

          <div className="search-and-buttons">
            <SearchView
              placeholder={"Search ECG Reports"}
              onSearchClick={props.onSearchClick}
              onSearchTextCleared={props.onSearchTextCleared}
              updatedCurrentValue={props.searchStringParam}
            />
            <ThemedButton
              title={"Refresh"}
              icon={<CiRedo color="#fff" size={15} />}
              styles="primary small"
              onClick={props.onReportsRefreshClick}
            />
          </div>
        </div>

        <div style={{ height: "10px", backgroundColor: "transparent" }} />

        <EcgReportsDynamicList
          reportList={props.reportList}
          newRecords={props.newRecords}
          onReportAcceptClick={props.onReportAcceptClick}
          onReportRejectClick={props.onReportRejectClick}
          //
          page={props.page}
          limit={props.limit}
          totalReports={props.totalReports}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          onECGReportDoctorDashboardListSortClick={
            props.onECGReportDoctorDashboardListSortClick
          }
        />
      </CardView>

      <PopupDialog
        show={props.showNewReportPopup}
        title={"Report Alert"}
        showCloseIcon={true}
        renderBodyContent={renderNewReportPopup}
        confirmButtonTitle={"Accept"}
        cancelButtonTitle={"Reject"}
        onclickConfirm={props.onReportAcceptClick}
        onclickCancel={props.onReportRejectClick}
        popupSize={"md"}
      />
    </div>
  );
};

export default DoctorsView;
