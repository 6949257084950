import React from "react";
import { DataTable } from "../../../../common";
import {
  IconMenuOption,
  PaginationNew,
  ThemedButton,
} from "../../../../controls";
import { roles } from "../../../../../constants/roles";
import { FaDownload, FaSort } from "react-icons/fa6";
import moment from "moment";
import "./ListStyles.scss";
import { AiFillFilter } from "react-icons/ai";

const EcgReportsList = (props) => {
  const data = props.reportList;
  const userRole = props.userRole;
  var isReportListByDoctors = props.isReportListByDoctors ?? false; // ECGReports list on ECGReportsByDoctorContainer
  var isReportListByDevice = props.isReportListByDevice ?? false; // ECGReports list on ECGReportsContainer, BUT COMING FROM DEVICE CLICK!

  // console.log("REPORT DATA ", JSON.stringify(props.reportList));

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onECGReportListSortClick !== null
                  ? isReportListByDoctors
                    ? () => props.onECGReportByDoctorListSortClick(label)
                    : () => props.onECGReportListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const reported = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const sent = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const assigned = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#1890FF",
    };
    const Low = {
      color: "#52C41A",
      border: "1px solid  #B7EB8F",
      background: "#F6FFED",
      padding: "2px 8px",
    };
    const Medium = {
      color: "#FA8C16",
      border: "1px solid #FFD591",
      background: "#FFF7E6",
      padding: "2px 8px",
    };
    const High = {
      color: "#F5222D",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
      padding: "2px 8px",
    };
    const STEMI = {
      color: "#CD000B",
      border: "1px solid #CD000B",
      background: "rgba(205, 0, 11,0.25)",
      padding: "2px 8px",
    };
    const Options =
      label === "Severity"
        ? [
            { label: "Normal", icon: <text style={Low}>Normal</text> },
            { label: "Mild", icon: <text style={Medium}>Mild</text> },
            { label: "Critical", icon: <text style={High}>Critical</text> },
            { label: "STEMI", icon: <text style={STEMI}>STEMI</text> },
          ]
        : [
            { label: "Reported", icon: <div style={reported} /> },
            { label: "Sent", icon: <div style={sent} /> },
            { label: "Assigned", icon: <div style={assigned} /> },
          ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              showSelectedItem={true}
              columnName={label}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onECGReportListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {/*  No sort icon  */}
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderReportStatus = (status) => {
    const reported = { backgroundColor: "#52C41A" };
    const sent = { backgroundColor: "#D9D9D9" };
    const assigned = { backgroundColor: "#1890FF" };

    let statusStyle;
    if (status === "Reported") {
      statusStyle = reported;
    }
    if (status === "Sent") {
      statusStyle = sent;
    }
    if (status === "Assigned") {
      statusStyle = assigned;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text>{status}</text>
      </div>
    );
  };

  const renderSeverity = (severity) => {
    const normal = {
      color: "#52C41A",
      border: "1px solid  #B7EB8F",
      background: "#F6FFED",
    };
    const mild = {
      color: "#FA8C16",
      border: "1px solid #FFD591",
      background: "#FFF7E6",
    };
    const critical = {
      color: "#F5222D",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
    };
    const STEMI = {
      color: "#CD000B",
      border: "1px solid #CD000B",
      background: "rgba(205, 0, 11,0.25)",
    };
    const none = {
      color: "#000",
      border: "none",
      background: "transparent",
    };

    let severityStyle;
    if (severity === "Normal") {
      severityStyle = normal;
    }
    if (severity === "Mild") {
      severityStyle = mild;
    }
    if (severity === "Critical") {
      severityStyle = critical;
    }
    if (severity === "STEMI") {
      severityStyle = STEMI;
    }
    if (severity === "None") {
      severity = "-";
      severityStyle = none;
    }

    return (
      <div className="severity-wrapper">
        <div style={severityStyle} className="severity-box">
          <text>{severity}</text>
        </div>
      </div>
    );
  };

  const calculateResponseTime = (start, end) => {
    var value = "-";

    if (end) {
      var secs = moment(end, "YYYY-MM-DD HH-mm-ss").diff(
        moment(start, "YYYY-MM-DD HH-mm-ss"),
        "seconds"
      );
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;

      value = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
        "0" + secs
      ).slice(-2)}`;
    }

    return value;
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // const handleSelectedRowsChange = (state) => {
  //   console.log("handleSelectedRowsChange : ", state.selectedRows);
  //   props.onSelectedRowsChange(state.selectedRows);
  // };

  // ---------------------  All Column Objects ------------------------

  const recordId = {
    name: renderHeader("Record ID", false, true),
    id: "report_id",
    center: false,
    sortable: false,
    selector: (row) => row?.id ?? "-",
  };

  const recordIdForSupervisor = {
    name: renderHeader("Record ID", false, true),
    id: "report_id_supervisor",
    center: false,
    sortable: false,
    selector: (row) => (
      <text
        style={{
          color: "#00B48A",
          fontSize: "14px",
          fontWeight: "400",
          cursor: "pointer",
        }}
        onClick={
          props.onSupervisorViewReportClick !== null
            ? () => props.onSupervisorViewReportClick(row.id)
            : () => {}
        }
      >
        {row?.id ?? "-"}
      </text>
    ),
  };

  const centerName = {
    name: renderHeader("Center Name"),
    id: "center_name",
    center: false,
    sortable: false,
    selector: (row) => row?.centerInfo?.centerName ?? "-",
  };

  const patientName = {
    name: renderHeader("Patient Name"),
    id: "patient_name",
    center: false,
    sortable: false,
    selector: (row) =>
      row.patientInfo !== null && row.patientInfo !== undefined
        ? (row.patientInfo?.firstName !== undefined &&
          row.patientInfo?.firstName !== null
            ? `${row.patientInfo.firstName
                .charAt(0)
                .toUpperCase()}${row.patientInfo.firstName.slice(1)}`
            : "") +
          " " +
          (row.patientInfo?.lastName !== undefined &&
          row.patientInfo?.lastName !== null
            ? `${row.patientInfo.lastName
                .charAt(0)
                .toUpperCase()}${row.patientInfo.lastName.slice(1)}`
            : "")
        : "-",
  };

  const doctorName = {
    name: renderHeader("Doctor Name", false, true),
    id: "doctor_name",
    center: false,
    sortable: false,
    selector: (row) =>
      row.doctorInfo !== null && row.doctorInfo !== undefined
        ? "Dr. " +
          (row.doctorInfo?.firstName !== undefined &&
          row.doctorInfo?.firstName !== null
            ? `${row.doctorInfo.firstName
                .charAt(0)
                .toUpperCase()}${row.doctorInfo.firstName.slice(1)}`
            : "") +
          " " +
          (row.doctorInfo?.lastName !== undefined &&
          row.doctorInfo?.lastName !== null
            ? `${row.doctorInfo.lastName
                .charAt(0)
                .toUpperCase()}${row.doctorInfo.lastName.slice(1)}`
            : "")
        : "-",
  };

  const uniqueDoctorName = {
    name: renderHeader(
      "Doctor Name",
      false,
      props.userRole !== roles.supervisor
    ),
    id: "doctor_name",
    center: false,
    sortable: false,
    selector: (row) =>
      row.doctorInfo !== null && row.doctorInfo !== undefined
        ? "Dr. " +
          (row.doctorInfo?.firstName !== undefined &&
          row.doctorInfo?.firstName !== null
            ? `${row.doctorInfo.firstName
                .charAt(0)
                .toUpperCase()}${row.doctorInfo.firstName.slice(1)}`
            : "") +
          " " +
          (row.doctorInfo?.lastName !== undefined &&
          row.doctorInfo?.lastName !== null
            ? `${row.doctorInfo.lastName
                .charAt(0)
                .toUpperCase()}${row.doctorInfo.lastName.slice(1)}`
            : "")
        : "-",
  };

  const doctorsHospitalName = {
    name: renderHeader("Hospital", false, true),
    id: "doctors_hospital_name",
    center: false,
    sortable: false,
    selector: (row) => row?.hospitalName ?? "-",
  };

  const hospitalName = {
    name: renderHeader("Hospital", false, false),
    id: "doctors_hospital_name",
    center: false,
    sortable: false,
    selector: (row) => row?.hospitalInfo?.hospitalName ?? "-",
  };

  const doctorsReportCount = {
    name: renderHeader("No. of ECG Records", false, false),
    id: "doctors_report_count",
    center: false,
    sortable: false,
    selector: (row) => row?.reportCount ?? "-",
  };

  const deviceId = {
    name: renderHeader("Device ID", false, true),
    id: "device_id",
    center: false,
    sortable: false,
    selector: (row) => row?.deviceInfo?.serialNo ?? "-",
  };

  const dataRecordedDate = {
    name: renderHeader("Data Recorded Date"),
    id: "data_recorded_date",
    center: false,
    sortable: false,
    selector: (row) =>
      moment(row?.dtRecReceivedOnServer).format("YYYY-MM-DD") ?? "-",
  };

  const dataRecordedTime = {
    name: renderHeader("Data Recorded Time"),
    id: "data_recorded_time",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.dtRecReceivedOnServer
        ? moment(row?.dtRecReceivedOnServer).format("h:mm A") ?? "-"
        : "-",
  };

  const dataRecordedDateAndTime = {
    name: renderHeader("Data Recorded On", false, true),
    id: "data_recorded_date",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.dtRecReceivedOnServer
        ? moment(row?.dtRecReceivedOnServer).format("YYYY-MM-DD, h:mm A") ?? "-"
        : "-",
  };

  const reportAcceptedDateAndTime = {
    name: renderHeader("Report Accepted On"),
    id: "data_recorded_date",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.doctorAcceptedAt
        ? moment(row?.doctorAcceptedAt).format("YYYY-MM-DD, h:mm A") ?? "-"
        : "-",
  };

  const reportSubmittedDateAndTime = {
    name: renderHeader("Report Submitted On", false, true),
    id: "data_recorded_date",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.dtRecReplied
        ? moment(row?.dtRecReplied).format("YYYY-MM-DD, h:mm A") ?? "-"
        : "-",
  };

  const responseTime = {
    name: renderHeader("Response Time"),
    id: "response_time",
    center: false,
    sortable: false,
    selector: (row) =>
      calculateResponseTime(row?.dtRecReceivedOnServer, row?.dtRecReplied),
  };

  const status = {
    name: renderStatusHeader("Status"),
    id: "status",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.dtRecReplied
        ? renderReportStatus("Reported") //("Generated")
        : row?.doctorAcceptedAt
        ? renderReportStatus("Assigned")
        : row?.dtRecReceivedOnServer
        ? renderReportStatus("Sent")
        : "-",
  };

  const severity = {
    name: renderStatusHeader("Severity", false),
    id: "severity",
    center: false,
    sortable: false,
    selector: (row) =>
      userRole === roles.supervisor
        ? row?.reviewedSeverity == 1
          ? renderSeverity("Normal")
          : row?.reviewedSeverity == 2
          ? renderSeverity("Mild")
          : row?.reviewedSeverity == 3
          ? renderSeverity("Critical")
          : row?.reviewedSeverity == 4
          ? renderSeverity("STEMI")
          : renderSeverity("None")
        : row?.severity == 1
        ? renderSeverity("Normal")
        : row?.severity == 2
        ? renderSeverity("Mild")
        : row?.severity == 3
        ? renderSeverity("Critical")
        : row?.severity == 4
        ? renderSeverity("STEMI")
        : renderSeverity("None"),
  };

  const ReportRreviewStatus = {
    name: renderHeader("Status", true),
    id: "report_preview_status",
    center: false,
    sortable: false,
    selector: (row) => (row?.status === true ? "Reviewed" : "Pending Review"),
  };

  const viewAction = {
    name: renderActionHeader("Action", true),
    id: "view_action",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.dtRecReplied ? (
        <ThemedButton
          title="View Report"
          styles="link"
          onClick={
            props.onViewReportClick !== null
              ? () => props.onViewReportClick(row.id)
              : () => {}
          }
        />
      ) : null,
  };

  const printAction = {
    name: renderActionHeader("Action", true),
    id: "print_action",
    center: false,
    sortable: false,
    selector: (row) => (
      <div className="action-wrapper">
        {row?.dtRecReplied ? (
          <ThemedButton
            title="Print"
            styles="link"
            onClick={
              props.onPrintReportClick !== null
                ? () => props.onPrintReportClick(row.id)
                : () => {}
            }
          />
        ) : null}
      </div>
    ),
  };

  const downloadAction = {
    name: renderActionHeader("Action", true),
    id: "download_action",
    // right: true,
    sortable: false,
    // omit: props.userRole !== roles.technician ? true : false,
    selector: (row) => (
      <div className="action-wrapper">
        {row.dtRecReplied ? (
          <ThemedButton
            // icon={<FaDownload color="#D9D9D9" />}
            title={"Download"}
            styles={"link"}
            onClick={
              props.onDownloadReportClick !== null
                ? () => props.onDownloadReportClick(row.id)
                : () => {}
            }
          />
        ) : null}
      </div>
    ),
  };

  const viewDoctorsReportsAction = {
    name: renderActionHeader("Action", true),
    id: "view_reports_action",
    center: false,
    sortable: false,
    selector: (row) => (
      <div className="action-wrapper">
        <ThemedButton
          title="View Reports"
          styles="link"
          onClick={
            props.onViewDoctorsReportsClick !== null
              ? () => {
                  var doctorName =
                    row.doctorInfo !== null && row.doctorInfo !== undefined
                      ? "Dr. " +
                        (row.doctorInfo?.firstName !== undefined &&
                        row.doctorInfo?.firstName !== null
                          ? `${row.doctorInfo.firstName
                              .charAt(0)
                              .toUpperCase()}${row.doctorInfo.firstName.slice(
                              1
                            )}`
                          : "") +
                        " " +
                        (row.doctorInfo?.lastName !== undefined &&
                        row.doctorInfo?.lastName !== null
                          ? `${row.doctorInfo.lastName
                              .charAt(0)
                              .toUpperCase()}${row.doctorInfo.lastName.slice(
                              1
                            )}`
                          : "")
                      : "-";

                  props.onViewDoctorsReportsClick(
                    doctorName,
                    row.doctorId,
                    row.hospitalId
                  );
                }
              : () => {}
          }
        />
      </div>
    ),
  };

  // ------------------  Add Columns According to Role -------------------

  // We will add needed columns to this array according to role.
  var columns = [];

  if (userRole === roles.superAdmin && isReportListByDoctors) {
    // Show list with doctors name and reports count (This is for list on ECGReportsByDoctor screen)
    columns.push(uniqueDoctorName);
    columns.push(doctorsHospitalName);
    columns.push(doctorsReportCount);
    columns.push(viewDoctorsReportsAction);
  } else if (userRole === roles.superAdmin && isReportListByDevice) {
    // Show reports list of selected device with below columns
    columns.push(recordId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  } else if (userRole === roles.superAdmin) {
    // Show reports list of selected doctor with below columns
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  }

  //
  else if (userRole === roles.hospitalAdmin && isReportListByDoctors) {
    // Show list with doctors name and reports count (This is for list on ECGReportsByDoctor screen)
    columns.push(uniqueDoctorName);
    columns.push(doctorsHospitalName);
    columns.push(doctorsReportCount);
    columns.push(viewDoctorsReportsAction);
  } else if (userRole === roles.hospitalAdmin && isReportListByDevice) {
    // Show reports list of selected device with below columns
    columns.push(recordId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  } else if (userRole === roles.hospitalAdmin) {
    // Show reports list of selected doctor with below columns
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  }

  //
  else if (userRole === roles.reportingHospitalAdmin && isReportListByDoctors) {
    // Show list with doctors name and reports count (This is for list on ECGReportsByDoctor screen)
    columns.push(uniqueDoctorName);
    columns.push(doctorsHospitalName);
    columns.push(doctorsReportCount);
    columns.push(viewDoctorsReportsAction);
  } else if (
    userRole === roles.reportingHospitalAdmin &&
    isReportListByDevice
  ) {
    // Show reports list of selected device with below columns
    columns.push(recordId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  } else if (userRole === roles.reportingHospitalAdmin) {
    // Show reports list of selected doctor with below columns
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(downloadAction);
  }

  //
  else if (userRole === roles.centerAdmin) {
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(severity);
    columns.push(status);
    columns.push(printAction);
  }

  //
  else if (userRole === roles.technician) {
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(severity);
    columns.push(status);
    columns.push(printAction);
  }

  //
  else if (userRole === roles.doctor) {
    columns.push(recordId);
    columns.push(deviceId);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(viewAction);
  }

  //
  else if (userRole === roles.supervisor) {
    columns.push(recordIdForSupervisor);
    columns.push(hospitalName);
    columns.push(uniqueDoctorName);
    columns.push(dataRecordedDateAndTime);
    columns.push(reportSubmittedDateAndTime);
    columns.push(severity);
    columns.push(ReportRreviewStatus);
  }

  return (
    <div className="ecg-reports-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          selectableRows={props.hideCheckboxes ? false : true}
          selectableRowsComponent={BootyCheckbox}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalReports}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default EcgReportsList;

/* ------------------------  Old Columns Array  --------------------------

  const columnsOld = [
    {
      name: "Report#",
      id: "Report#",
      center: false,
      sortable: true,
      selector: (row) => row?.id,
      style: {
        color: "#00B48A",
      },
    },
    {
      name: "Center Name",
      id: "Center_Name",
      center: false,
      sortable: true,
      selector: (row) => row?.centerInfo?.centerName,
      style: {
        color: "#00B48A",
      },
    },
    {
      name: "Arrived",
      id: "Arrived",
      center: false,
      selector: (row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={row.id}>
              {row.dtRecReceivedOnServer
                ? moment(row.dtRecReceivedOnServer).format("hh:mm A")
                : "N/A"}
            </Tooltip>
          }
        >
          <div>
            <FaCircleCheck
              color={row.dtRecReceivedOnServer ? "#00AAD6" : "#D9D9D9"}
            />
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: "Assigned",
      id: "Assigned",
      center: false,
      selector: (row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={row.id}>
              {row.doctorAcceptedAt
                ? moment(row.doctorAcceptedAt).format("hh:mm A")
                : "N/A"}
            </Tooltip>
          }
        >
          <div>
            <FaCircleCheck
              color={row.doctorAcceptedAt ? "#00AAD6" : "#D9D9D9"}
            />
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: "Generated",
      id: "Generated",
      center: false,
      selector: (row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={row.id}>
              {row.dtRecReplied
                ? moment(row.dtRecReplied).format("hh:mm A")
                : "N/A"}
            </Tooltip>
          }
        >
          <div>
            <FaCircleCheck color={row.dtRecReplied ? "#00AAD6" : "#D9D9D9"} />
          </div>
        </OverlayTrigger>
      ),
    },
    // {
    //   name: "Doctor Name",
    //   id: "Doctor_Name",
    //   center: false,
    //   sortable: true,
    //   selector: (row) => row.doctorName,
    //   style: {
    //     color: "#00B48A",
    //   },
    // },
    // {
    //   name: "Hospital",
    //   id: "Hospital",
    //   center: false,
    //   sortable: true,
    //   selector: (row) => row.hospital,
    // },
    // {
    //   name: "No. of ECG Records",
    //   id: "No_of_ECG_Records",
    //   center: false,
    //   sortable: true,
    //   selector: (row) => row.noOfEcgRecords,
    // },
  ];

  */
