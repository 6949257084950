import React, { useEffect, useRef, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import imageView from "../../../assets/images/imageView.png";
import imageDelete from "../../../assets/images/imageDelete.png";
import infoIcon from "../../../assets/images/icons/logout-confirmation.png";
import { showAlert } from "../../../store/alert/alertActions";
import { getStore } from "../../../store/configure/configureStore";
import { globalConstants } from "../../../constants/globalConstants";
import { PopupDialog } from "../../pages/NewUIPages/Components";
import "./ImageUploaderStyles.scss";

const Imageuploader = ({
  imageUrl,
  uploadFileHandle,
  viewIconHandle,
  deleteIconHandle,
  accept = "image/*",
  type = null,
  disabled = false,
  error,
}) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showDeleteConfirmationMsg, setShowDeleteConfirmationMsg] =
    useState(false);

  useEffect(() => {
    if (file?.size) {
      uploadFileHandle(file);
    }
  }, [file]);

  useEffect(() => {
    if (imageUrl === null || imageUrl === "") {
      console.log("image not found :: ", imageUrl);
      setFile(null);
    } else {
      console.log("image found :: ", imageUrl);
    }
  }, [imageUrl]);

  const handleImageChange = (e) => {
    const uploadedFile = e.target.files[0];
    const filetagSeparater = uploadedFile?.type.split("/");

    if (filetagSeparater?.length > 0) {
    }

    if (
      filetagSeparater?.length > 0 &&
      filetagSeparater[0] == "image" &&
      (filetagSeparater[1] == "jpg" ||
        filetagSeparater[1] == "jpeg" ||
        filetagSeparater[1] == "png")
    ) {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const img = new Image();

        img.src = event.target.result;
        var imageSizeInKb = img.src.length / 1024;
        var imageSizeInMb = imageSizeInKb / 1024;

        img.onload = () => {
          console.log("IMG width ::: ", img.width);
          console.log("IMG height ::: ", img.height);
          console.log("IMG sizeInMb ::: ", imageSizeInMb);

          if (
            parseInt(img.width) <= 500 &&
            parseInt(img.height) <= 500 &&
            imageSizeInMb <= 2
          ) {
            console.log("Image dimensions are valid");
            setFile(uploadedFile);
          }
          //
          else if (imageSizeInMb > 2) {
            setFile(null);
            getStore().dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Error uploading file. File size exceeds maximum allowed size of 2 Mb.",
                showError: true,
              })
            );
          }
          //
          else if (img.width > 500 || img.height > 500) {
            setFile(null);
            getStore().dispatch(
              showAlert({
                isOpen: true,
                title: "Error",
                type: "danger",
                msg: "Error uploading file. File dimensions exceeds maximum allowed dimensions of 500 x 500 pixels.",
                showError: true,
              })
            );
          }
        };
      };
      if (uploadedFile) {
        reader.readAsDataURL(uploadedFile);
      }
    } else {
      setFile(null);
      getStore().dispatch(
        showAlert({
          isOpen: true,
          title: "Error",
          type: "danger",
          msg: "Invalid file format. Kindly upload JPG, JPEG, JFIF and PNG images only.",
          showError: true,
        })
      );
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const renderDeletePopupContent = () => {
    return (
      <div className="logout-modal-content">
        <img className="logout-icon" src={infoIcon} alt="icon" />
        <text>
          Are you sure you want to delete the{" "}
          {type === globalConstants.USER_PROFILE_IMAGE
            ? "profile picture"
            : type === globalConstants.DOCTOR_SIGNATURE_IMAGE
            ? "signature"
            : type === globalConstants.HOSPITAL_LOGO_IMAGE ||
              type === globalConstants.HOSPITAL_FRANCHISE_IMAGE
            ? "logo"
            : type === globalConstants.AMBULANCE_IMAGE
            ? "Image"
            : "picture"}
          {"?"}
        </text>
      </div>
    );
  };

  return (
    <div className="image_uploader_outer_container">
      {imageUrl !== null && imageUrl !== "" ? (
        <div className="image_viewer">
          <img src={imageUrl} alt="img_preview" className="img_preview" />

          <div className="img_actions">
            <img
              className="logo-style"
              src={imageView}
              alt="imageView"
              onClick={() => viewIconHandle()}
            />
            <img
              className="logo-style"
              src={imageDelete}
              alt="imageDelete"
              onClick={() => setShowDeleteConfirmationMsg(true)}
            />
          </div>
        </div>
      ) : (
        <div className="image_uploader" onClick={handleButtonClick}>
          <input
            type="file"
            disabled={disabled}
            accept={accept}
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />

          <div
            className={`add_view ${disabled ? "disable-bg" : ""}`}
            onChange={handleImageChange}
          >
            {<BsPlusLg size={15} color="#222" />}
            <text className="mt-2">Upload</text>
          </div>
        </div>
      )}

      {imageUrl !== null && imageUrl !== "" ? null : (
        <div className="info_view">
          <text>
            <b>File Upload Guidelines : </b>
          </text>
          <text>
            {/* <span className="asterics">*</span> */}
            Only JPG, JPEG, JFIF and PNG file formats allowed.
          </text>
          <text>
            {/* <span className="asterics">*</span> */}
            Maximum 2MB size and 500 x 500 pixels resolution allowed.
          </text>
        </div>
      )}
      <div className="image-req-error">{error}</div>
      <PopupDialog
        show={showDeleteConfirmationMsg}
        title={"Confirm Delete"}
        showCloseIcon={true}
        renderBodyContent={renderDeletePopupContent}
        onclickConfirm={() => {
          setShowDeleteConfirmationMsg(false);
          deleteIconHandle();
        }}
        onclickCancel={() => setShowDeleteConfirmationMsg(false)}
        popupSize={"md"}
      />
    </div>
  );
};

Imageuploader.propTypes = {};

export default Imageuploader;
