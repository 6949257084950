import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showLoader, hideLoader } from "../../../../store/loader/loaderActions";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getHospitalsListWithFilter,
  setSelectedHospital,
  getReportingHospitalsListWithFilter,
  getHospitalDetailsById,
  setReportingStatus,
  addNewHospital,
  editHospital,
  deleteHospital,
  deleteHospitalInBulk,
} from "../../../../store/hospital/hospitalActions";
import {
  getState,
  getDistrict,
  getCity,
  getPincode,
} from "../../../../store/master/masterActions";
import { getHospitalAdminsList } from "../../../../store/administrators/adminActions";
import { history } from "../../../../store/configure/configureStore";
import HospitalsView from "./HospitalsView";
import * as validator from "../../../../utils/validator";
import { trimFormData } from "../../../../utils/common";

class HospitalsContainer extends Component {
  validatorFields = {};
  constructor(props) {
    super(props);

    this.validatorFields["required"] = [
      "name",
      "reportingName",
      "addressLine1",
      "contact",
      "contact",
      "district",
      "state",
      "pincode",
    ];
    this.state = {
      page: 1,
      sortBy: "",
      order: "",
      listOrder: false,
      adminListPage: 1,
      pageCountAdministrator: 2,
      reportingHospitalListPage: 1,
      pageCountReportingHospital: 2,
      limit: 10,
      hospitalList: [],
      reportingHospitalList: [],
      administratorList: [],
      totalHospitals: 0,

      stateList: [],
      districtList: [],
      pincodeList: [],
      selectedVillage: {},

      addHospitalFields: {
        name: "",
        reportingName: "",
        addressLine1: "",
        latitude: "",
        longitude: "",
        latitude: "",
        longitude: "",
        landmark: "",
        contact: "",
        contact: "",
        district: "",
        state: "",
        village: "",
        pincode: "",
      },
      administrator: [],
      noScrollHospital: false,
      noScrollAdministrator: false,
      searchReportHospitalText: "",
      reportService: false, // for it is reporting hospital or not
      isValidationsOn: false,
      searchAdministratorText: "",
      hospitalInfo: {},
      editStateChange: false,
      pushedReportingHospitalId: "",

      // Popup Dialog
      showAddHospitalPopup: false,
      showEditHospitalPopup: false,
      showDeleteHospitalPopup: false,
      showDeleteHospitalConfirmPopup: false,
      errors: {},

      // Search filters
      filters: {
        text: "",
      },

      // This is needed only when we are coming back from View Centers page (sending a search string as param to searchView). BY DEFAULT THIS SHOULD BE 'null'.
      searchStringParam: null,

      //Bulk Delete
      selectedIdsToDelete: [],
      clearSelectedRows: false,
      //
      hospitalTypeData: [
        { value: 1, label: "Privately Owned" },
        { value: 2, label: "Government" },
      ],
      HospitalHubTypeToggleData: [
        { id: 1, name: "Hub A" },
        { id: 2, name: "Hub B" },
      ],
      hospitalType: "",
      hubType: "",
      isHub: false,
      isSpoke: false,
      isGovernment: false,
      showHospitalHubTypeToggle: false,
      //
    };

    this.callGetHospitalListAPI = this.callGetHospitalListAPI.bind(this);
    this.callGetOnlyReportingHospitalListAPI =
      this.callGetOnlyReportingHospitalListAPI.bind(this);
    this.callGetHospitalAdminListAPI =
      this.callGetHospitalAdminListAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);
    this.callGetDistrictListAPI = this.callGetDistrictListAPI.bind(this);
    this.callGetPincodeListAPI = this.callGetPincodeListAPI.bind(this);

    this.onScrollReportingHospitalList =
      this.onScrollReportingHospitalList.bind(this);
    this.onReportingHospitalSearchChange =
      this.onReportingHospitalSearchChange.bind(this);
    this.onReportingHospitalChange = this.onReportingHospitalChange.bind(this);

    this.onStateChange = this.onStateChange.bind(this);
    this.onPincodeChange = this.onPincodeChange.bind(this);
    this.onDistrictChange = this.onDistrictChange.bind(this);

    this.onAdministratorChange = this.onAdministratorChange.bind(this);
    this.onAdministratorSearchChange =
      this.onAdministratorSearchChange.bind(this);
    this.handleHospitalAdminChips = this.handleHospitalAdminChips.bind(this);
    this.onScrollAdministratorList = this.onScrollAdministratorList.bind(this);

    this.onAddHospitalClick = this.onAddHospitalClick.bind(this);
    this.onAddHospitalConfirmClick = this.onAddHospitalConfirmClick.bind(this);
    this.onAddHospitalFieldChange = this.onAddHospitalFieldChange.bind(this);
    this.onCloseAddHospitalPopup = this.onCloseAddHospitalPopup.bind(this);
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);

    this.onEditHospitalClick = this.onEditHospitalClick.bind(this);
    this.onEditHospitalConfirmClick =
      this.onEditHospitalConfirmClick.bind(this);
    this.onCloseEditPopup = this.onCloseEditPopup.bind(this);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onDeleteAdminConfirmClick = this.onDeleteAdminConfirmClick.bind(this);
    this.onCloseDeletePopup = this.onCloseDeletePopup.bind(this);

    this.onDeleteHospitalAction = this.onDeleteHospitalAction.bind(this);
    this.onHospitalSelected = this.onHospitalSelected.bind(this);

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkHospitalDeleteClick = this.onBulkHospitalDeleteClick.bind(this);
    this.onBulkHospitalDeleteConfirmClick =
      this.onBulkHospitalDeleteConfirmClick.bind(this);

    this.resetFormFields = this.resetFormFields.bind(this);
    this.onHubCheckBoxChange = this.onHubCheckBoxChange.bind(this);
    this.onSpokeCheckBoxChange = this.onSpokeCheckBoxChange.bind(this);
    this.onGovermentOrPrivateChange =
      this.onGovermentOrPrivateChange.bind(this);

    this.onChangeHubHospitalType = this.onChangeHubHospitalType.bind(this);
    this.onHospitalListSortClick = this.onHospitalListSortClick.bind(this);
  }
  componentDidUpdate() {}

  componentDidMount() {
    var previousState = this.props.location?.state ?? {};
    console.log("HOSPITALS previousState ::: ", JSON.stringify(previousState));

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;
    var prevFilters = previousState?.filters ?? {
      text: "",
    };
    var searchString = prevFilters?.text !== "" ? prevFilters.text : null;

    this.props.getState((list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });
      console.log("list", newList);
      this.setState({ stateList: newList });
    });

    this.setState(
      {
        page: prevPage,
        limit: prevLimit,
        filters: prevFilters,
        searchStringParam: searchString,
        hubType: this.state.HospitalHubTypeToggleData[0],
        hospitalType: this.state.hospitalTypeData[0],
      },
      () => {
        window.history.replaceState({}, "");
        this.callGetHospitalListAPI();
        this.callGetOnlyReportingHospitalListAPI();
        this.callGetHospitalAdminListAPI();
      }
    );
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    this.populateListData(
      newProps.filteredHospitalList,
      newProps.filteredHospitalCount,
      {}
    );
  }

  //--------------------------- Get Hospital List API Call ------------------------

  callGetHospitalListAPI = () => {
    this.props.getHospitalsListWithFilter(
      this.state.filters,
      (list, count, metaData, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(list, count, metaData);
          this.forceUpdate();
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  callGetOnlyReportingHospitalListAPI = () => {
    this.props.getReportingHospitalsListWithFilter(
      {
        text: this.state.searchReportHospitalText,
      },
      (list) => {
        const newList = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({
          reportingHospitalList: newList,
        });
      },
      this.state.reportingHospitalListPage,
      25
    );
  };

  callGetHospitalAdminListAPI = () => {
    this.props.getHospitalAdminsList(
      {
        text: this.state.searchAdministratorText,
      },
      (listData, count, metadata) => {
        const newList = listData.map((item) => {
          return {
            label: `${
              (item?.firstName !== null && item?.firstName !== undefined
                ? `${item?.firstName
                    .charAt(0)
                    .toUpperCase()}${item?.firstName.slice(1)}`
                : "") +
              " " +
              (item?.lastName !== null && item?.lastName !== undefined
                ? `${item?.lastName
                    .charAt(0)
                    .toUpperCase()}${item?.lastName.slice(1)}`
                : "")
            }`,
            value: item.id,
          };
        });
        this.setState({
          administratorList: newList,
        });
      },
      this.state.adminListPage,
      25
    );
  };

  callGetDistrictListAPI = (stateId) => {
    this.props.getDistrict({ stateId: stateId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: item.name };
      });
      this.setState({ districtList: newList });
    });
  };

  callGetPincodeListAPI = (districtId) => {
    this.props.getPincode({ districtId: districtId }, (list) => {
      const newList = list.map((item) => {
        return { value: item.id, label: `${item.pincode}` };
      });
      this.setState({ pincodeList: newList });
    });
  };

  resetFormFields = () => {
    this.setState({
      addHospitalFields: {
        ...this.state.addHospitalFields,
        name: "",
        reportingName: "",
        addressLine1: "",
        latitude: "",
        longitude: "",
        landmark: "",
        contact: "",
        district: "",
        state: "",
        village: "",
        pincode: "",
      },
      pageCountReportingHospital: 2,
      pageCountAdministrator: 2,
      showAddHospitalPopup: false,
      showEditHospitalPopup: false,
      isValidationsOn: false,
      reportService: false,
      noScrollHospital: false,
      noScrollAdministrator: false,
      errors: {},
      administrator: [],
      districtList: [],
      pincodeList: [],
      selectedVillage: {},
      isHub: false,
      isSpoke: false,
      isGovernment: false,
      hubType: this.state.HospitalHubTypeToggleData[0],
      hospitalType: this.state.hospitalTypeData[0],
      showHospitalHubTypeToggle: false,
    });
  };

  //--------------------------- Populate List -------------------------------

  populateListData = (listData, count = 0, metadata = {}) => {
    var hospitalListData = listData; // Redux data

    var hospitalsMetaData = null;
    if (metadata.hasOwnProperty("hospitals")) {
      hospitalsMetaData = metadata.hospitals;
    }

    // Add centers. doctors and devices count to hospitalList items - from metadata
    hospitalListData.map((item) => {
      var hospitalId = item.id;
      if (
        hospitalsMetaData !== null &&
        hospitalsMetaData.hasOwnProperty(hospitalId)
      ) {
        // console.log("METADATA FOUND!! hospitalId : ", hospitalId);
        var currentHospitalMetaData = hospitalsMetaData[hospitalId];
        item.centersCount = currentHospitalMetaData.centers?.total ?? 0;
        item.devicesCount = currentHospitalMetaData.devices?.total ?? 0;
        item.doctorsCount = currentHospitalMetaData.doctors?.total ?? 0;
      }
    });

    this.setState({
      hospitalList: hospitalListData,
      totalHospitals: count,
    });
  };

  //-------------------- Add / Edit Popup Data Handling  -----------------------

  onHubCheckBoxChange = (e) => {
    const target = e.target;
    if (target.checked) {
      this.setState({ isHub: true, showHospitalHubTypeToggle: true });
    } else {
      this.setState({
        isHub: false,
        showHospitalHubTypeToggle: false,
      });
    }
  };

  onChangeHubHospitalType = (index) => {
    console.log("onChangeHubHospitalType", index);
    this.setState({
      hubType: this.state.HospitalHubTypeToggleData.find(
        (item) => item.id === index
      ),
    });
  };

  onSpokeCheckBoxChange = (e) => {
    const target = e.target;
    if (target.checked) {
      this.setState({ isSpoke: true });
    } else {
      this.setState({ isSpoke: false });
    }
  };

  onGovermentOrPrivateChange = (value) => {
    this.setState({ isGovernment: value.label === "Government" });
  };

  onCheckBoxChange = (event) => {
    const target = event.target;
    if (target.checked) {
      this.validatorFields["required"] = this.validatorFields[
        "required"
      ].filter(function (item) {
        return item !== "reportingName";
      });
      this.state.errors["reportingName"] = "";
      // When hospital itself reporting then remove *reportingName* required field
      this.setState({
        reportService: true,
        addHospitalFields: {
          ...this.state.addHospitalFields,
          reportingName: "",
        },
        searchReportHospitalText: "",
      });
    } else {
      this.validatorFields["required"].push("reportingName");
      console.log("showEditHospitalPopup", this.state.showEditHospitalPopup);
      if (this.state.showEditHospitalPopup) {
        this.setState(
          {
            addHospitalFields: {
              ...this.state.addHospitalFields,
              reportingName: "",
            },
            searchReportHospitalText: "",
            noScrollHospital: false,
          },
          () => {
            this.callGetOnlyReportingHospitalListAPI();
          }
        );
      }

      // hospital itself is not reporting then user must have to add reporting hospital Name also
      this.setState({ reportService: false });
    }
  };

  onReportingHospitalChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState(
        {
          addHospitalFields: {
            ...this.state.addHospitalFields,
            reportingName: value ?? "",
          },
          searchReportHospitalText: "",
          noScrollHospital: false,
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );
      this.validatorFields["required"] = this.validatorFields[
        "required"
      ].filter(function (item) {
        return item !== "reportingName";
      });
      this.state.errors["reportingName"] = "";
      if (this.state.showEditHospitalPopup) {
        if (value.value === this.state.hospitalInfo.id) {
          this.setState({
            reportService: true,
          });
        }
      }
    } else {
      this.setState(
        {
          addHospitalFields: {
            ...this.state.addHospitalFields,
            reportingName: "",
          },
          searchReportHospitalText: "",
          noScrollHospital: false,
        },
        () => {
          this.callGetOnlyReportingHospitalListAPI();
        }
      );
      this.validatorFields["required"].push("reportingName");
    }
  };

  onReportingHospitalSearchChange = (text) => {
    console.log("calling after edit");
    this.setState(
      {
        searchReportHospitalText: text,
        noScrollHospital: false,
      },
      () => {
        this.callGetOnlyReportingHospitalListAPI();
      }
    );
  };

  onScrollReportingHospitalList = () => {
    console.log(
      "this.state.pageCountReportingHospital",
      this.state.pageCountReportingHospital
    );
    if (!this.state.noScrollHospital) {
      this.props.getReportingHospitalsListWithFilter(
        {
          text: this.state.searchReportHospitalText,
        },
        (list) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              if (this.state.pushedReportingHospitalId !== item.id) {
                return { label: item.hospitalName, value: item.id };
              }
            });
            const newListData = listData.filter(function (item) {
              return item !== undefined;
            });
            this.setState({
              reportingHospitalList: [
                ...this.state.reportingHospitalList,
                ...newListData,
              ],
            });
            ++this.state.pageCountReportingHospital;
          } else {
            this.setState({ noScrollHospital: true });
          }
        },
        this.state.pageCountReportingHospital, // direct call from 2nd page bcoz 1st page alredy added
        25
      );
    }
  };

  onStateChange = (value) => {
    console.log("value", value);
    if (value !== null && value !== undefined) {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          state: value,
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
      });
      // this.validatorFields["required"] = this.validatorFields[
      //   "required"
      // ].filter(function (item) {
      //   return item !== "state";
      // });
      this.state.errors["state"] = "";

      // this.props.getDistrict({ stateId: value.value }, (list) => {
      //   const newList = list.map((item) => {
      //     return { value: item.id, label: item.name };
      //   });
      //   this.setState({ districtList: newList });
      // });
      this.callGetDistrictListAPI(value.value);
    } else {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          state: "",
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
        districtList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["state"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("state");
    }
  };

  onDistrictChange = (value) => {
    console.log("onDistrictChange", value);
    if (value !== null && value !== undefined) {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          district: value,
          village: "",
          pincode: "",
        },
      });
      // this.validatorFields["required"] = this.validatorFields[
      //   "required"
      // ].filter(function (item) {
      //   return item !== "district";
      // });
      this.state.errors["district"] = "";

      // this.props.getPincode({ districtId: value.value }, (list) => {
      //   const newList = list.map((item) => {
      //     return { value: item.id, label: `${item.pincode}` };
      //   });
      //   this.setState({ pincodeList: newList });
      // });
      this.callGetPincodeListAPI(value.value);
    } else {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          district: "",
          village: "",
          pincode: "",
        },
        pincodeList: [],
      });
      if (this.state.isValidationsOn) {
        this.state.errors["district"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("district");
    }
  };

  onPincodeChange = (value) => {
    if (value !== null && value !== undefined) {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          pincode: value,
        },
      });
      // this.validatorFields["required"] = this.validatorFields[
      //   "required"
      // ].filter(function (item) {
      //   return item !== "pincode";
      // });
      this.state.errors["pincode"] = "";

      this.props.getCity({ pincodeId: value.value }, (areaData) => {
        console.log("by district village list", areaData);
        this.setState({
          selectedVillage: areaData,
          addHospitalFields: {
            ...this.state.addHospitalFields,
            village: areaData.name,
          },
        });
      });
    } else {
      this.setState({
        addHospitalFields: {
          ...this.state.addHospitalFields,
          pincode: "",
          village: "",
        },
        selectedVillage: {},
      });
      if (this.state.isValidationsOn) {
        this.state.errors["pincode"] = "Field can not be empty";
      }
      // this.validatorFields["required"].push("pincode");
    }
  };

  onAdministratorSearchChange = (text) => {
    this.state.searchAdministratorText = text;
    this.setState(
      {
        searchAdministratorText: text,
        noScrollAdministrator: false,
      },
      () => {
        this.callGetHospitalAdminListAPI();
      }
    );
  };

  onScrollAdministratorList = () => {
    if (!this.state.noScrollAdministrator) {
      this.props.getHospitalAdminsList(
        {
          text: this.state.searchAdministratorText,
        },
        (listData, count, metadata) => {
          if (listData.length > 0) {
            const list = listData.map((item) => {
              return {
                label: `${
                  (item?.firstName !== null && item?.firstName !== undefined
                    ? `${item?.firstName
                        .charAt(0)
                        .toUpperCase()}${item?.firstName.slice(1)}`
                    : "") +
                  " " +
                  (item?.lastName !== null && item?.lastName !== undefined
                    ? `${item?.lastName
                        .charAt(0)
                        .toUpperCase()}${item?.lastName.slice(1)}`
                    : "")
                }`,
                value: item.id,
              };
            });
            this.setState({
              administratorList: [...this.state.administratorList, ...list],
            });
            ++this.state.pageCountAdministrator;
          } else {
            this.setState({ noScrollAdministrator: true });
          }
        },
        this.state.pageCountAdministrator, // direct call from 2nd page bcoz 1st page alredy added
        25
      );
    }
  };

  onAddHospitalFieldChange = (field) => (e) => {
    let value;
    const { addHospitalFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired = this.validatorFields.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      addHospitalFields: { ...addHospitalFields, [field]: value },
    });
  };

  handleHospitalAdminChips = (chipToDelete) => {
    console.log("chipToDelete", chipToDelete);
    const updatedList = this.state.administrator.filter(
      (chip) => chip.value !== chipToDelete.value
    );
    this.setState({
      administrator: [...updatedList],
    });
  };

  onAdministratorChange = (Object) => {
    if (Object !== null && Object !== undefined) {
      this.state.administrator.push(Object);
      this.setState(
        {
          searchAdministratorText: "",
          noScrollAdministrator: false,
        },
        () => {
          this.callGetHospitalAdminListAPI();
        }
      );
    } else {
      this.setState(
        {
          searchAdministratorText: "",
          noScrollAdministrator: false,
          pageCountAdministrator: 2,
        },
        () => {
          this.callGetHospitalAdminListAPI();
        }
      );
    }
  };

  //------------------------ Add hospital -------------------------

  onAddHospitalClick = () => {
    this.setState({ showAddHospitalPopup: true });
  };

  onAddHospitalConfirmClick = () => {
    const custom = [validator.phone(["contact"])];
    this.validatorFields["custom"] = custom;
    const { addHospitalFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFields,
      trimFormData(addHospitalFields)
    );
    this.setState({ ...this.state, errors, isValidationsOn: true });
    console.log("error", errors);
    if (!Object.keys(errors).length) {
      let newAdministratorArray = [];
      this.state.administrator.map(
        (item) => newAdministratorArray.push(item.value) // store only administrator id's in array pass to api
      );
      const data = {
        addressData: {
          addressLineOne: addHospitalFields.addressLine1,
          latitude:
            addHospitalFields.latitude?.trim().length > 0
              ? addHospitalFields.latitude?.trim()
              : null,
          longitude:
            addHospitalFields.longitude?.trim().length > 0
              ? addHospitalFields.longitude?.trim()
              : null,
          landmark: addHospitalFields.landmark,
          mobile: addHospitalFields.contact,
          districtId: addHospitalFields.district.value,
          stateId: addHospitalFields.state.value,
          pincodeId: addHospitalFields.pincode.value,
          areaId: this.state.selectedVillage.id,
        },
        hospitalName: addHospitalFields.name,
        reportService: this.state.reportService,
        isHub: this.state.isHub,
        hubType:
          this.state.isHub == true
            ? this.state.hubType.name == "Hub A"
              ? "A"
              : "B"
            : null,
        isSpoke: this.state.isSpoke,
        isGovernment: this.state.isGovernment,
        medicalService: true,
        reportingHospitalId: this.state.reportService
          ? null
          : addHospitalFields.reportingName.value,
        hospitalAdminIds: newAdministratorArray,
        // parentId: null,
        // registrationId: null,
        // isParent: null,
      };
      console.log("data", data);
      this.props.addNewHospital(data, (response) => {
        if (response !== null) {
          this.setState(
            {
              searchReportHospitalText: "",
              searchAdministratorText: "",
            },
            () => {
              this.resetFormFields();
              this.callGetHospitalListAPI();
              this.callGetOnlyReportingHospitalListAPI();
              this.callGetHospitalAdminListAPI();
            }
          );
        }
      });
    }
  };

  onCloseAddHospitalPopup = () => {
    this.setState(
      {
        showAddHospitalPopup: false,
        administrator: [],
        reportingHospitalList: [],
        searchReportHospitalText: "",
        searchAdministratorText: "",
      },
      () => {
        this.resetFormFields();
        this.callGetOnlyReportingHospitalListAPI();
        this.callGetHospitalAdminListAPI();
      }
    );
  };

  //------------------------ Edit hospital -------------------------

  onEditHospitalClick = (row) => {
    //
    //api call hospital details get by ID
    this.props.getHospitalDetailsById(row.id, (details) => {
      console.log("HospitalDetailsById", details);
      const data = details.hospital;
      var reportingHospitalObject;
      if (data.reportService) {
        console.log("removed");
        this.validatorFields["required"] = this.validatorFields[
          "required"
        ].filter(function (item) {
          return item !== "reportingName";
        });
      }

      const stateId = data?.addressInfo?.stateId;
      const districtId = data?.addressInfo?.districtId;
      const repoHospitalId = data?.reportingHospitalId ?? "";
      const pincodeId = data?.addressInfo?.pincodeId;
      let hospitalAdmins = [];
      data.hospitalInfo.map((item) =>
        hospitalAdmins.push(item.hospitalAdminInfo)
      );
      hospitalAdmins = hospitalAdmins.map((item) => {
        return {
          label: `${
            (item?.firstName !== null && item?.firstName !== undefined
              ? `${item?.firstName
                  .charAt(0)
                  .toUpperCase()}${item?.firstName.slice(1)}`
              : "") +
            " " +
            (item?.lastName !== null && item?.lastName !== undefined
              ? `${item?.lastName
                  .charAt(0)
                  .toUpperCase()}${item?.lastName.slice(1)}`
              : "")
          }`,
          value: item.id,
        };
      });

      const reportingHospitalIndex = this.state.reportingHospitalList.findIndex(
        (item) => item.value == repoHospitalId
      );
      console.log("reportingHospitalIndex", reportingHospitalIndex);
      if (reportingHospitalIndex !== -1) {
        reportingHospitalObject =
          this.state.reportingHospitalList[reportingHospitalIndex];
      } else if (
        details.reportingHospitalData !== undefined &&
        details.reportingHospitalData !== null
      ) {
        reportingHospitalObject = {
          value: details.reportingHospitalData.id,
          label: details.reportingHospitalData.hospitalName,
        };
        this.state.reportingHospitalList.push(reportingHospitalObject);
      }
      console.log("reportingHospitalObject", reportingHospitalObject);
      const stateIndex = this.state.stateList.findIndex(
        (item) => item.value == stateId
      );
      //
      // api call for get District list by stateId
      this.props.getDistrict({ stateId: stateId }, (destrictList) => {
        const districtIndex = destrictList.findIndex(
          (item) => item.id == districtId
        );
        const newDestrictList = destrictList.map((item) => {
          return { value: item.id, label: item.name };
        });
        //
        // api call for get pincode list by districtId
        this.props.getPincode({ districtId: districtId }, (pincodeList) => {
          const pincodeIndex = pincodeList.findIndex(
            (item) => item.id == pincodeId
          );
          const newPincodeList = pincodeList.map((item) => {
            return { value: item.id, label: `${item.pincode}` };
          });
          const hubHospitalType = this.state.HospitalHubTypeToggleData.find(
            (item) =>
              item.name ==
              (data?.hubType == "A"
                ? "Hub A"
                : data?.hubType == "B"
                ? "Hub B"
                : "Hub A")
          );
          console.log("hubHospitalType", hubHospitalType);
          this.setState({
            pincodeList: newPincodeList,
            districtList: newDestrictList,
            hospitalInfo: data,
            showEditHospitalPopup: true,
            pushedReportingHospitalId: reportingHospitalObject?.value ?? "",
            isValidationsOn: true,
            reportService: data.reportService,
            isHub: data.isHub,
            showHospitalHubTypeToggle: data.isHub == true ? true : false,
            hubType: hubHospitalType,
            isSpoke: data.isSpoke,
            isGovernment: data.isGovernment,
            hospitalType:
              data.isGovernment === true
                ? this.state.hospitalTypeData[1]
                : this.state.hospitalTypeData[0],
            administrator: [...hospitalAdmins],
            addHospitalFields: {
              ...this.state.addHospitalFields,
              name: data?.hospitalName ?? "",
              reportingName: reportingHospitalObject,
              addressLine1: data?.addressInfo?.addressLineOne ?? "",
              latitude: data?.addressInfo?.latitude?.toString() ?? "",
              longitude: data?.addressInfo?.longitude?.toString() ?? "",
              landmark: data?.addressInfo?.landmark ?? "",
              contact: data?.addressInfo?.mobile ?? "",
              district:
                districtIndex !== -1 ? newDestrictList[districtIndex] : "",
              state: stateIndex !== -1 ? this.state.stateList[stateIndex] : "",
              village: data?.addressInfo.areaInfo?.name ?? "",
              pincode: pincodeIndex !== -1 ? newPincodeList[pincodeIndex] : "",
            },
            searchReportHospitalText: "",
            searchAdministratorText: "",
          });
        });
      });
    });
  };

  onEditHospitalConfirmClick = () => {
    const custom = [validator.phone(["contact"])];
    this.validatorFields["custom"] = custom;
    const { addHospitalFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFields,
      trimFormData(addHospitalFields)
    );

    console.log("onEditHospitalConfirmClick : ", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });
    if (!Object.keys(errors).length) {
      let updatedAdministratorArray = [];
      this.state.administrator.map(
        (item) => updatedAdministratorArray.push(item.value) // store only administrator id's in array pass to api
      );
      const data = {
        addressData: {
          addressLineOne: addHospitalFields.addressLine1,
          latitude:
            addHospitalFields.latitude?.trim().length > 0
              ? addHospitalFields.latitude?.trim()
              : null,
          longitude:
            addHospitalFields.longitude?.trim().length > 0
              ? addHospitalFields.longitude?.trim()
              : null,
          landmark: addHospitalFields.landmark,
          mobile: addHospitalFields.contact,
          mobile: addHospitalFields.contact,
          districtId: addHospitalFields.district.value,
          stateId: addHospitalFields.state.value,
          pincodeId: addHospitalFields.pincode.value,
          areaId: this.state.selectedVillage.id,
        },
        hospitalName: addHospitalFields.name,
        reportService: this.state.reportService,
        isHub: this.state.isHub,
        hubType:
          this.state.isHub == true
            ? this.state.hubType.name == "Hub A"
              ? "A"
              : "B"
            : null,
        isSpoke: this.state.isSpoke,
        isGovernment: this.state.isGovernment,
        medicalService: true,
        reportingHospitalId: this.state.reportService
          ? null
          : addHospitalFields.reportingName.value,
        hospitalAdminIds: updatedAdministratorArray,
        // parentId: null,
        // registrationId: null,
        // isParent: null,
      };
      console.log(errors, "data", data);
      this.props.editHospital(this.state.hospitalInfo.id, data, (response) => {
        if (response !== null) {
          this.setState(
            {
              searchReportHospitalText: "",
              searchAdministratorText: "",
            },
            () => {
              this.resetFormFields();
              this.callGetHospitalListAPI();
              this.callGetOnlyReportingHospitalListAPI();
              this.callGetHospitalAdminListAPI();
            }
          );
          this.validatorFields["required"].push("reportingName");
        }
      });
    }
  };

  onCloseEditPopup = () => {
    const check = this.validatorFields["required"].includes("reportingName");
    if (!check) {
      this.validatorFields["required"].push("reportingName");
    }
    this.setState(
      {
        showEditHospitalPopup: false,
        administrator: [],
        reportingHospitalList: [],
        searchReportHospitalText: "",
        searchAdministratorText: "",
      },
      () => {
        this.resetFormFields();
        this.callGetOnlyReportingHospitalListAPI();
        this.callGetHospitalAdminListAPI();
      }
    );
  };

  //----------------------------- Delete hospital -------------------------

  onDeleteHospitalAction = (row) => {
    console.log(row);
    this.setState({ hospitalInfo: row, showDeleteHospitalPopup: true });
  };

  onDeleteAdminConfirmClick = () => {
    this.props.deleteHospital(this.state.hospitalInfo.id, () => {
      this.setState({ showDeleteHospitalPopup: false });
      this.callGetHospitalListAPI();
    });
  };

  onCloseDeletePopup = () => {
    this.setState({
      showDeleteHospitalPopup: false,
      showDeleteHospitalConfirmPopup: false,
    });
  };

  //----------------------- Delete bulk hospitals -----------------------------

  handleSelectedRowsChange = (selectedRowsArray) => {
    var hospitalIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({ selectedIdsToDelete: hospitalIds });
  };

  onBulkHospitalDeleteClick = () => {
    this.setState({ showDeleteHospitalConfirmPopup: true });
  };

  onBulkHospitalDeleteConfirmClick = () => {
    console.log("selectedIdsToDelete", this.state.selectedIdsToDelete);
    this.props.deleteHospitalInBulk(
      { hospitalIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showDeleteHospitalConfirmPopup: false,
        });
        this.callGetHospitalListAPI();
      }
    );
  };

  //------------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        {
          page: 1,
          filters: { ...this.state.filters, text },
          searchStringParam: null,
        },
        () => {
          this.callGetHospitalListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      {
        page: 1,
        filters: { ...this.state.filters, text: "", searchStringParam: null },
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  //----------------------------- Hospital Selection ---------------------------

  onHospitalSelected = (hospitalObject) => {
    // Save selected Hospital in state vaiable and redux
    this.props.setSelectedHospital(hospitalObject);

    setTimeout(() => {
      history.push({
        pathname: "/hospitals/centers",
        state: {
          page: this.state.page,
          limit: this.state.limit,
          filters: this.state.filters,
        },
      });
    }, 500);
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalHospitals;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    console.log("#1 : ", newRowAndPageCount);
    console.log("#2 : ", parseInt(total));

    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
      console.log("#3 : ", newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  //sorting filters

  onHospitalListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Location") {
      columnName = "location";
    }
    if (column === "Hospital Name") {
      columnName = "hospitalName";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getHospitalsListWithFilter(
          this.state.filters,
          (list, count, metaData, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(list, count, metaData);
              this.forceUpdate();
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <HospitalsView
        hospitalList={this.state.hospitalList}
        totalHospitals={this.state.totalHospitals}
        //
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        //----------------- Search -------------------
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        searchStringParam={this.state.searchStringParam}
        //------------------ Hospital click --------------
        onHospitalSelected={this.onHospitalSelected}
        //------------------ Add Hospital --------------
        onAddHospitalClick={this.onAddHospitalClick}
        onAddHospitalConfirmClick={this.onAddHospitalConfirmClick}
        onCloseAddHospitalPopup={this.onCloseAddHospitalPopup}
        //------------------ Edit Hospital --------------
        showEditHospitalPopup={this.state.showEditHospitalPopup}
        onEditHospitalClick={this.onEditHospitalClick}
        onEditHospitalConfirmClick={this.onEditHospitalConfirmClick}
        onCloseEditPopup={this.onCloseEditPopup}
        //------------------ Add Hospital --------------
        showAddHospitalPopup={this.state.showAddHospitalPopup}
        addHospitalFields={this.state.addHospitalFields}
        onCheckBoxChange={this.onCheckBoxChange}
        reportService={this.state.reportService}
        isHub={this.state.isHub}
        hubType={this.state.hubType}
        isSpoke={this.state.isSpoke}
        isGovernment={this.state.isGovernment}
        onChangeHubHospitalType={this.onChangeHubHospitalType}
        HospitalHubTypeToggleData={this.state.HospitalHubTypeToggleData}
        onGovermentOrPrivateChange={this.onGovermentOrPrivateChange}
        onSpokeCheckBoxChange={this.onSpokeCheckBoxChange}
        onHubCheckBoxChange={this.onHubCheckBoxChange}
        showHospitalHubTypeToggle={this.state.showHospitalHubTypeToggle}
        searchReportHospitalText={this.state.searchReportHospitalText}
        reportingHospitalList={this.state.reportingHospitalList}
        onAddHospitalFieldChange={this.onAddHospitalFieldChange}
        administratorList={this.state.administratorList}
        administrator={this.state.administrator}
        handleHospitalAdminChips={this.handleHospitalAdminChips}
        onAdministratorChange={this.onAdministratorChange}
        onReportingHospitalChange={this.onReportingHospitalChange}
        onScrollReportingHospitalList={this.onScrollReportingHospitalList}
        onReportingHospitalSearchChange={this.onReportingHospitalSearchChange}
        onDeleteHospitalAction={this.onDeleteHospitalAction}
        //
        searchAdministratorText={this.state.searchAdministratorText}
        onAdministratorSearchChange={this.onAdministratorSearchChange}
        onScrollAdministratorList={this.onScrollAdministratorList}
        //
        onStateChange={this.onStateChange}
        stateList={this.state.stateList}
        onDistrictChange={this.onDistrictChange}
        districtList={this.state.districtList}
        onPincodeChange={this.onPincodeChange}
        pincodeList={this.state.pincodeList}
        selectedVillage={this.state.selectedVillage}
        //------------------ Delete Hospital --------------
        showDeleteHospitalPopup={this.state.showDeleteHospitalPopup}
        onCloseDeletePopup={this.onCloseDeletePopup}
        onDeleteAdminConfirmClick={this.onDeleteAdminConfirmClick}
        HospitalInfo={this.state.hospitalInfo}
        //------------------ Delete Bulk Hospital --------------
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onBulkHospitalDeleteClick={this.onBulkHospitalDeleteClick}
        onBulkHospitalDeleteConfirmClick={this.onBulkHospitalDeleteConfirmClick}
        showDeleteHospitalConfirmPopup={
          this.state.showDeleteHospitalConfirmPopup
        }
        hospitalTypeData={this.state.hospitalTypeData}
        hospitalType={this.state.hospitalType}
        //
        onHospitalListSortClick={this.onHospitalListSortClick}
        //
        errors={this.state.errors}
      />
    );
  }
}

HospitalsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    filteredHospitalList: state.hospital.filteredHospitalList,
    filteredHospitalCount: state.hospital.filteredHospitalCount,
    selectedHospital: state.hospital.selectedHospital,
  };
};

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
  showAlert,
  getHospitalsListWithFilter,
  getReportingHospitalsListWithFilter,
  setSelectedHospital,
  getHospitalAdminsList,
  getHospitalDetailsById,
  addNewHospital,
  editHospital,
  deleteHospital,
  deleteHospitalInBulk,
  setReportingStatus,
  //
  getState,
  getDistrict,
  getCity,
  getPincode,
})(HospitalsContainer);
