import React from "react";
import CardView from "../../../../controls/Card/CardView";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import error_card from "../../../../../assets/images/icons/error_card.png";
import "./CardStyles.scss";

const COLORS = ["#00474F", "#08979C", "#B5F5EC"];

const ErrorManagementCard = (props) => {
  return (
    <div className="super-admin-card-wrapper">
      <CardView
        extraStyle="error-management-card-control" // Since it has same styling as device card, we are reusing the style here!
        bodyStyle={{ height: "180px" }}
      >
        <div className="card-title">
          <img className="card-icon" src={error_card} alt="icon" />
          <span>Error Management</span>
        </div>

        {parseInt(props.totalErrorLogs) === 0 ? (
          <div className="info-wrapper centered">
            <text>No Error Logs found.</text>
          </div>
        ) : (
          <div className="info-wrapper">
            <ResponsiveContainer width="100%" height={100}>
              <PieChart>
                <Pie
                  data={props.errorManagementData}
                  cx="20%"
                  cy="50%"
                  innerRadius={35}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="value"
                  legendType="circle"
                >
                  {props.errorManagementData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                  <Label
                    content={<CustomLabel total={props.totalErrorLogs} />}
                  />
                </Pie>
                <Legend
                  content={<CustomLegend data={props.errorManagementData} />}
                  width={"55%"}
                  layout="vetical"
                  verticalAlign="middle"
                  align="right"
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </CardView>
    </div>
  );
};

const CustomLegend = (data) => {
  var legendsData = Array.isArray(data.data) ? data.data : [];
  // console.log("legendsData : ", JSON.stringify(legendsData));

  //---------------------------------------------------------
  // calculate percentage
  var totalValue = 0;
  // sum the values
  for (var i = 0; i < legendsData.length; i++) {
    totalValue += legendsData[i].value; //TODO
  }
  //---------------------------------------------------------

  return (
    <ul style={{ listStyle: "none" }} className="legends-list">
      {legendsData.map((entry, index) => (
        <li key={`legend-${index}`} className="legends-list-item">
          <span
            className="legend-dot"
            style={{ backgroundColor: COLORS[index] }}
          ></span>
          <span className="legend-title-wide">{entry.name}</span>
          <div className="legend-separator" />
          <span className="legend-value">{parseInt(entry.value)}</span>
        </li>
      ))}
    </ul>
  );
};

const CustomLabel = ({ viewBox, total }) => {
  const { cx, cy } = viewBox;

  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      fill="#333"
      fontSize={16}
      fontWeight="bold"
    >
      <tspan
        x={cx}
        dy="-1em"
        style={{ fill: "#00000085", fontSize: "12px", fontWeight: "300" }}
      >
        Total
      </tspan>
      <tspan
        x={cx}
        dy="1.2em"
        style={{ fill: "#00000085", fontSize: "22px", fontWeight: "400" }}
      >
        {total}
      </tspan>
    </text>
  );
};

export default ErrorManagementCard;
