import React, { useEffect } from "react";
import {
  Input,
  Select,
  TextArea,
  CardView,
  ThemedButton,
  Imageuploader,
} from "../../../controls";
import PopupDialog from "../Components/Other/PopupDialog";
import { roles } from "../../../../constants/roles";
import { FiAlertTriangle, FiArrowLeft } from "react-icons/fi";
import { globalConstants } from "../../../../constants/globalConstants";
import { strings } from "../../../../constants/stringConstants";
import "./MyProfileStyles.scss";

const MyProfileView = (props) => {
  // useEffect(() => {
  // if (props.UploadImageSuccess || props.deleteImageSuccess) {
  //   props.getMyProfileDetails();
  // }
  //   console.log("props.profilePicture :: ", props.profilePicture);
  //   console.log("props.signPicture :: ", props.signPicture);
  // }, [props.UploadImageSuccess, props.deleteImageSuccess]);

  const renderViewProfileImageContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.profilePicture}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  const renderViewSignImageContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.signPicture}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  const renderViewHospitalfranchiseImageContents = () => {
    return (
      <div className="img_container_main">
        <img
          src={props.hospitalFranchiseLogo}
          alt="image_preview"
          className="img_preview"
        />
      </div>
    );
  };

  const renderChangePasswordContent = () => {
    return (
      <div className="change-password-contents">
        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Old Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Old Password"
              size="sm"
              onChange={props.onPasswordhandleChange("oldPassword")}
              type="password"
              value={props.fields.oldPassword}
              // error={props.oldPasswordError}
              error={props.errors["oldPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div
          className="fields"
          style={{
            marginBottom:
              props.errors["newPassword"] === strings.WEAK_PASSWORD_ERROR
                ? "15px"
                : "0px",
          }}
        >
          <text className="label-item">
            <span className="required-star">* </span> New Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter New Password"
              size="sm"
              onChange={props.onPasswordhandleChange("newPassword")}
              type="password"
              value={props.fields.newPassword}
              // error={props.newPasswordError}
              error={props.errors["newPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Confirm Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Confirm New Password"
              size="sm"
              onChange={props.onPasswordhandleChange("confirmNewPassword")}
              type="password"
              value={props.fields.confirmNewPassword}
              // error={props.confirmNewPasswordError}
              error={props.errors["confirmNewPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="my_profile_container">
      <CardView
        extraStyle="profile-card"
        // bodyStyle={{ minHeight: "calc(100vh - 130px)" }}
      >
        <div style={{ paddingBottom: "50px" }}>
          <text className="profile-title">My Profile</text>
          <div className="separator" />

          <div className="form-wrapper-container">
            <div className="form-contents">
              {/* --- */}

              <div className="fields">
                <text className="label-item">
                  <span className="required-star">* </span>Email :
                </text>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder=""
                    size="sm"
                    onChange={(e) => {}}
                    value={props.email}
                    disabled={true}
                  />
                </div>

                <div className="last-dummy-item" />
              </div>

              <div className="fields">
                <text className="label-item">
                  <span className="required-star">* </span>Password :
                </text>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder=""
                    size="sm"
                    onChange={(e) => {}}
                    value={"*******************************"}
                    disabled={true}
                  />
                </div>
                <div className="last-dummy-item">
                  <ThemedButton
                    title={"Change Password"}
                    size={"small"}
                    styles="link underline"
                    onClick={() => props.onChangePasswordClick()}
                  />
                </div>
              </div>

              {/* <div className="fields">
                <text className="label-item">Security Question :</text>
                <div className="value-item">
                  <Select
                    label-item={null}
                    placeholder="Select your security question"
                    className="select-wrapper"
                    options={props.securityQuestionsList}
                    value={props.selectedSecurityQuestion}
                    onChange={(val) => {
                      props.onSecurityQuestionSelected(val);
                    }}
                    error=""
                  />
                </div>
                <div className="last-dummy-item" />
              </div>

              <div className="fields">
                <text className="label-item">Answer :</text>
                <div className="value-item">
                  <Input
                    className="input-wrapper"
                    placeholder="Enter answer to your security question"
                    size="sm"
                    onChange={(e) => {
                      props.onAnswerChange(e.target.value);
                    }}
                    value={props.answer}
                    error={props.answerError}
                    disabled={
                      props.selectedSecurityQuestion === null ? true : false
                    }
                  />
                </div>
                <div className="last-dummy-item" />
              </div> */}

              <div className="fields">
                <text className="label-item">
                  <span className="required-star">* </span> Name :
                </text>
                <div className="value-item">
                  <Input
                    className="input-wrapper half"
                    placeholder="First Name"
                    size="sm"
                    onChange={(e) => {
                      props.onFirstNameChange(e.target.value);
                    }}
                    value={props.firstName}
                    halfsize={true}
                    charLimit={50}
                    showCharLimit={true}
                    error={props.firstNameError}
                  />

                  <div style={{ width: "5px" }} />

                  <Input
                    className="input-wrapper half"
                    placeholder="Last Name"
                    size="sm"
                    onChange={(e) => {
                      props.onLastNameChange(e.target.value);
                    }}
                    value={props.lastName}
                    halfsize={true}
                    charLimit={50}
                    showCharLimit={true}
                    error={props.lastNameError}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>

              <div className="fields">
                <text className="label-item">
                  {" "}
                  <span className="required-star">* </span>Mobile :
                </text>
                <div className="value-item">
                  {/* <text className="mobile-code">+91</text> */}
                  <Input
                    className="input-wrapper"
                    placeholder="Enter your mobile"
                    size="sm"
                    showMobilePrefix={true}
                    value={props.mobile}
                    onChange={(e) => {
                      props.onMobileChange(e.target.value);
                    }}
                    maxLength={10}
                    error={props.mobileError}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>

              {props.userRole === roles.doctor ||
              props.userRole === roles.supervisor ? (
                <div className="fields">
                  <text className="label-item">Qualification :</text>
                  <div className="value-item">
                    <Input
                      className="input-wrapper"
                      placeholder="Enter your qualification"
                      size="sm"
                      value={props.qualification}
                      onChange={(e) => {
                        props.onQualificationChange(e.target.value);
                      }}
                      error=""
                    />
                  </div>
                  <div className="last-dummy-item" />
                </div>
              ) : null}

              {props.userRole === roles.doctor ||
              props.userRole === roles.supervisor ? (
                <div className="fields">
                  <text className="label-item">
                    <span className="required-star">* </span>MSCI ID :
                  </text>
                  <div className="value-item">
                    <Input
                      className="input-wrapper"
                      placeholder="Enter your MSCI ID"
                      size="sm"
                      value={props.msciNumber}
                      onChange={(e) => {
                        props.onMsciChange(e.target.value);
                      }}
                      error={props.msciError}
                    />
                  </div>
                  <div className="last-dummy-item" />
                </div>
              ) : null}

              {/* <div className="fields top-placement">
              <text className="label-item top-placement">Dummy :</text>
              <div className="value-item">
                <TextArea
                  label-item={null}
                  className="textatrea-wrapper"
                  placeholder=""
                  value={props.msciNumber}
                  onChange={props.onMsciChange}
                  error=""
                />
              </div>
            </div> */}

              <div className="fields">
                <text className="label-item">Profile Picture : </text>
                <div className="value-item">
                  <Imageuploader
                    imageUrl={props.profilePicture}
                    uploadFileHandle={props.onHandleUploadProfileImage}
                    viewIconHandle={props.viewProfilePicClicked}
                    deleteIconHandle={props.deleteProfileClicked}
                    type={globalConstants.USER_PROFILE_IMAGE}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>

              {props.userRole === roles.hospitalAdmin ? (
                <div className="fields">
                  <text className="label-item">Hospital Franchise Logo : </text>
                  <div className="value-item">
                    <Imageuploader
                      imageUrl={props.hospitalFranchiseLogo}
                      uploadFileHandle={
                        props.onHandleUploadHospitalFranchiseImage
                      }
                      viewIconHandle={props.viewHospitalFranchiseImageClicked}
                      deleteIconHandle={
                        props.deleteHospitalFranchiseImageClicked
                      }
                      type={globalConstants.HOSPITAL_FRANCHISE_IMAGE}
                    />
                  </div>
                  <div className="last-dummy-item" />
                </div>
              ) : null}

              {props.userRole === roles.doctor ? (
                <div className="fields">
                  <text className="label-item">Signature : </text>
                  <div className="value-item">
                    <Imageuploader
                      imageUrl={props.signPicture}
                      uploadFileHandle={props.onHandleUploadSignImage}
                      viewIconHandle={props.viewSignatureClicked}
                      deleteIconHandle={props.deleteSignatureClicked}
                      type={globalConstants.DOCTOR_SIGNATURE_IMAGE}
                    />
                  </div>
                  <div className="last-dummy-item" />
                </div>
              ) : null}

              <div className="fields">
                <div className="label-item" />
                <div className="value-item">
                  <ThemedButton
                    title={"Cancel"}
                    size={"small"}
                    styles="grey-outline"
                    onClick={() => {
                      props.onCancelClick();
                    }}
                  />

                  <ThemedButton
                    title={"Reset"}
                    size={"small"}
                    styles="primary-outline"
                    onClick={() => {
                      props.onResetClick();
                    }}
                  />

                  <ThemedButton
                    title={"Save"}
                    size={"small"}
                    onClick={() => {
                      props.onSaveClick();
                    }}
                  />
                </div>
                <div className="last-dummy-item" />
              </div>
            </div>
          </div>
        </div>
      </CardView>

      <PopupDialog
        show={props.viewProfilePicFlag}
        title={"View Profile Image"}
        showCloseIcon={true}
        renderBodyContent={renderViewProfileImageContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewProfilePicPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.viewSignIatureFlag}
        title={"View Signature Image"}
        showCloseIcon={true}
        renderBodyContent={renderViewSignImageContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewSignaturePopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.viewHospitalFranchiseImageFlag}
        title={"View Hospital Franchise Logo"}
        showCloseIcon={true}
        renderBodyContent={renderViewHospitalfranchiseImageContents}
        hideButtons={true}
        onclickConfirm={() => {}}
        onclickCancel={props.closeViewHospitalFranchiseImagePopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showChangePasswordPopup}
        title={"Change Password"}
        showCloseIcon={true}
        renderBodyContent={renderChangePasswordContent}
        hideButtons={false}
        confirmButtonTitle={"Save"}
        onclickConfirm={props.onChangePasswodConfirm}
        onclickCancel={props.onChangePasswodCancel}
        popupSize={"md"}
      />
    </div>
  );
};

export default MyProfileView;
