import React, { useEffect } from "react";
import { DataTable } from "../../../../common";
import { FaSort } from "react-icons/fa6";
import { ThemedButton, PaginationNew } from "../../../../controls";
import "./ListStyles.scss";

const HospitalList = (props) => {
  // passing props to data variable
  const data = props.hospitalList;

  useEffect(() => {
    console.log("HOSPITAL LIST LIMIT: ", props.limit);
  }, [props.limit]);

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onHospitalListSortClick !== null
                  ? () => props.onHospitalListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderAction = (row) => {
    return (
      <div className="action-wrapper">
        <ThemedButton
          title="Edit"
          styles="link "
          onClick={
            props.onEditHospitalClicked !== null
              ? () => props.onEditHospitalClicked(row)
              : () => {}
          }
        />
        <div className="partition-block" />
        <ThemedButton
          title="Delete"
          styles="link "
          onClick={
            props.onDeleteHospitalClicked !== null
              ? () => props.onDeleteHospitalClicked(row)
              : () => {}
          }
        />
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // Columns info with row value to use (row : single object in data array)
  const columns = [
    {
      name: renderHeader("Hospital Name", false, true),
      id: "Hospital",
      center: false,
      sortable: false,
      selector: (row) => (
        <text
          style={{
            color: "#00B48A",
            fontSize: "14px",
            fontWeight: "400",
            cursor: "pointer",
          }}
          onClick={() => {
            props.onHospitalSelected(row);
          }}
        >
          {row?.hospitalName ?? "-"}
        </text>
      ),
      style: {
        color: "#00B48A",
        fontSize: "14px",
        fontWeight: "400",
        cursor: "pointer",
      },
    },
    {
      name: renderHeader("Location", false, false),
      id: "Location",
      center: false,
      sortable: false,
      selector: (row) =>
        `${row?.addressInfo?.addressLineOne ?? ""}, ${
          row?.addressInfo?.districtInfo?.name ?? ""
        }, ${row?.addressInfo?.stateInfo?.name ?? ""}`,
    },
    {
      name: renderHeader("No.of Centres"),
      id: "No_of_Centres",
      center: false,
      sortable: false,
      selector: (row) => row?.centersCount ?? "0",
    },
    {
      name: renderHeader("No. of Doctors"),
      id: "No_of_Doctors",
      center: false,
      sortable: false,
      selector: (row) => row?.doctorsCount ?? "0",
    },
    {
      name: renderHeader("No. of Devices"),
      id: "No_of_Devices",
      center: false,
      sortable: false,
      selector: (row) => row?.devicesCount ?? "0",
    },
    {
      name: renderActionHeader("Action"),
      id: "Action",
      center: false,
      selector: (row) => renderAction(row),
    },
  ];

  return (
    <div className="hospital-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew} // Not needed anymore!
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalHospitals}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default HospitalList;

// var dummyData = [
//   {
//     hospital: "Global Hospital ",
//     location: "Parel, Mumbai, Maharashtra",
//     noOfCenters: 3,
//     noOfDoctors: 100,
//     noOfDevices: 100,
//   },
// ];
