export const actionTypes = {
  SAVE_NOTIFICATION: "SAVE_NOTIFICATION",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",

  REFRESH_HOME_SCREEN: "REFRESH_HOME_SCREEN",
  RESET_REFRESH_HOME_SCREEN: "RESET_REFRESH_HOME_SCREEN",
};

const initialState = {
  // MQTT Notifications Data
  newNotifications: [],
  notificationDeletedTimestamp: null,
  homeScreenRefreshed: false,
};

export default function mqtt(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SAVE_NOTIFICATION:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_NOTIFICATION:
      return Object.assign({}, state, payload);

    case actionTypes.REFRESH_HOME_SCREEN:
    case actionTypes.RESET_REFRESH_HOME_SCREEN:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
