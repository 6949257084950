import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  TextArea,
  CardView,
  ThemedButton,
  Imageuploader,
  ButtonView,
  Checkbox,
  FormSearchView,
} from "../../../../controls";
import PopupDialog from "../../Components/Other/PopupDialog";
import logoutIcon from "../../../../../assets/images/icons/logout-confirmation.png";
import { strings } from "../../../../../constants/stringConstants";
import "../HospitalCenter/HospitalCenterStyles.scss";

const AddEditCenterModalForm = ({
  addEditModalFlag,
  editModalFlag,
  handleChange,
  onCheckChange,
  fields,
  errors,
  onSave,
  onHandleEditCenterCloseModal,
  onHandleAddCenterCloseModal,
  deleteAdminFlag,
  onHandleDeleteAdminCloseModal,
  onHandleDeleteAdminOpenModal,
  onSaveDeleteAdminBtn,
  centerAdminId,
  onStateChange,
  onDistrictChange,
  onPincodeChange,
  stateList,
  districtList,
  pincodeList,
}) => {
  const renderDeleteAdminPopupContents = () => {
    const adminName = `${
      (fields?.firstName !== null && fields?.firstName !== undefined
        ? `${fields?.firstName
            .charAt(0)
            .toUpperCase()}${fields?.firstName.slice(1)}`
        : "") +
      " " +
      (fields?.lastName !== null && fields?.lastName !== undefined
        ? `${fields?.lastName.charAt(0).toUpperCase()}${fields?.lastName.slice(
            1
          )}`
        : "")
    }`; //
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Delete Center Admin
      ${adminName}
          ?`}
        </text>
      </div>
    );
  };

  const renderAddNewCenterModalContents = () => {
    return (
      <div>
        <div className="form-wrapper-container">
          <div className="form-contents">
            <div className="fields">
              <text className="label-item">
                <span className="asterisk">*</span>Center Name :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Center Name"
                  size="sm"
                  onChange={handleChange("centerName")}
                  value={fields.centerName}
                  error={errors["centerName"]}
                  // disabled={
                  //   props.selectedSecurityQuestion === null ? true : false
                  // }
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="fields">
              <text className="label-item">
                <span className="asterisk">*</span>Address Line 1 :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Address Line 1"
                  size="sm"
                  onChange={handleChange("addressOne")}
                  value={fields.addressOne}
                  error={errors["addressOne"]}
                  // disabled={
                  //   props.selectedSecurityQuestion === null ? true : false
                  // }
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="fields">
              <text className="label-item">
                {/* <span className="asterisk">*</span> */}
                Address Line 2 :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Address Line 2"
                  size="sm"
                  onChange={handleChange("address2")}
                  value={fields.address2}
                  // error={errors["addressTwo"]}
                  // disabled={
                  //   props.selectedSecurityQuestion === null ? true : false
                  // }
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            {/* <div className="fields">
              <text className="label-item">
                <span className="asterisk">*</span>
                Location :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Location"
                  size="sm"
                  onChange={handleChange("location")}
                  value={fields.location}
                  error={errors["location"]}
                  // disabled={
                  //   props.selectedSecurityQuestion === null ? true : false
                  // }
                />
              </div>
              <div className="last-dummy-item" />
            </div> */}

            <div className="block-level-group">
              <label className="form-label">
                <span className="required">* </span>State :
              </label>
              <div className="value-item extra">
                <FormSearchView
                  Options={stateList}
                  placeholder="Search State"
                  onChange={onStateChange}
                  error={errors["state"]}
                  // lazyLoading={false}
                  previousValue={fields?.state}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <label className="form-label">
                <span className="required">* </span>District :
              </label>
              <div className="value-item extra">
                <FormSearchView
                  Options={districtList}
                  disable={fields?.state?.value ? false : true}
                  placeholder="Search District"
                  onChange={onDistrictChange}
                  error={errors["district"]}
                  // lazyLoading={false}
                  // multiSelect={false}
                  previousValue={fields?.district}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <label className="form-label">
                <span className="required">* </span>Pincode :
              </label>
              <div className="value-item extra">
                <FormSearchView
                  Options={pincodeList}
                  disable={fields?.district?.value ? false : true}
                  placeholder="Search Pincode"
                  onChange={onPincodeChange}
                  error={errors["pincode"]}
                  // lazyLoading={false}
                  // multiSelect={false}
                  previousValue={fields?.pincode}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <label className="form-label">Area/Village :</label>
              <div className="value-item">
                <Input
                  extraStyle={"display-text-view"}
                  className="input-wrapper"
                  placeholder="Area/Village"
                  readOnly={true}
                  value={
                    fields?.village == null || fields?.village == ""
                      ? ""
                      : fields?.village?.name
                  }
                  // disable={props.pincodeList.length > 0?false:true}
                  // onChange={handleChange("village")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="block-level-group">
              <label className="form-label">Landmark :</label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Landmark"
                  value={fields?.landmark}
                  onChange={handleChange("landmark")}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="fields">
              <text className="label-item">
                <span className="asterisk">*</span>Mobile :
              </text>
              <div className="value-item">
                {/* <text className="mobile-code">+91</text> */}
                <Input
                  type="tel"
                  className="input-wrapper"
                  placeholder="Enter Mobile Number"
                  size="sm"
                  isvalnum="isNum"
                  value={fields.contact}
                  onChange={handleChange("contact")}
                  maxLength={10}
                  showMobilePrefix={true}
                  error={errors["contact"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="fields1 ">
              <text className="label-item1"></text>
              <div className="value-item1">
                <div className="checkbox">
                  <Checkbox
                    onChange={
                      centerAdminId == null
                        ? onCheckChange("CenterAdminFlag")
                        : centerAdminId != null && editModalFlag
                        ? () => {}
                        : onCheckChange("CenterAdminFlag")
                    }
                    checked={fields.CenterAdminFlag}
                  />
                  <label className="boxLabel">Add Center Admin Details</label>
                </div>

                {centerAdminId == null ? null : (
                  <div className="delete_Center_Admin_btn">
                    <ThemedButton
                      title="Delete Admin"
                      size={"medium"}
                      onClick={() => {
                        onHandleDeleteAdminOpenModal();
                      }}
                      styles={`medium ${
                        centerAdminId == null ? "disabled" : "primary"
                      }`}
                    />
                  </div>
                )}
              </div>
              <div className="last-dummy-item1" />
            </div>
            <div className="fields ">
              <text className="label-item">
                <span className="asterisk">*</span>Email :
              </text>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  value={fields?.email}
                  name={"email"}
                  disabled={
                    centerAdminId
                      ? true
                      : (fields.CenterAdminFlag == true && addEditModalFlag) ||
                        (fields.CenterAdminFlag == true && editModalFlag)
                      ? false
                      : true
                  }
                  placeholder="Enter Email"
                  type="text"
                  onChange={handleChange("email")}
                  size="sm"
                  error={errors["email"]}
                  // style={{ backgroundColor: "white" }}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="fields ">
              <text className="label-item">
                <span className="asterisk">*</span>Mobile :
              </text>
              <div className="value-item">
                {/* <text className="mobile-code">+91</text> */}
                <Input
                  className="input-wrapper"
                  placeholder="Enter Mobile Number"
                  size="sm"
                  disabled={fields.CenterAdminFlag == false ? true : false}
                  isvalnum="isNum"
                  value={fields?.CenterAdmincontact}
                  onChange={handleChange("CenterAdmincontact")}
                  maxLength={10}
                  showMobilePrefix={true}
                  error={errors["CenterAdmincontact"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="block-level-group">
              <label className="form-label">
                <span className="required-star">* </span> Name :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter First Name"
                  value={fields?.firstName}
                  disabled={fields.CenterAdminFlag == false ? true : false}
                  error={errors["firstName"]}
                  onChange={handleChange("firstName")}
                  halfsize={true}
                  charLimit={50}
                  showCharLimit={true}
                />
                <div style={{ width: "20px" }} />
                <Input
                  className="input-wrapper"
                  placeholder="Enter Last Name"
                  value={fields?.lastName}
                  disabled={fields.CenterAdminFlag == false ? true : false}
                  error={errors["lastName"]}
                  onChange={handleChange("lastName")}
                  halfsize={true}
                  charLimit={50}
                  showCharLimit={true}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div
              className="fields"
              style={{
                marginBottom:
                  errors["password"] === strings.WEAK_PASSWORD_ERROR
                    ? "15px"
                    : "0px",
              }}
            >
              <text className="label-item">
                <span className="asterisk">*</span>Password :
              </text>
              <div className="value-item">
                <Input
                  value={fields?.password}
                  className="input-wrapper"
                  name={"password"}
                  placeholder="Enter Password"
                  disabled={
                    fields.CenterAdminFlag == false
                      ? true
                      : fields?.passwordShowFlag && editModalFlag
                      ? true
                      : false
                  }
                  // type="password"
                  onChange={handleChange("password")}
                  // disabled={fields?.email && editModalFlag ? true :false}
                  size="lg"
                  error={errors["password"]}
                  // style={{ backgroundColor: "white" }}
                />
              </div>
              <div className="last-dummy-item" />
            </div>

            <div className="fields mb-3">
              <text className="label-item">
                <span className="asterisk">*</span>Confirm Password :
              </text>
              <div className="value-item">
                <Input
                  value={fields?.confirmPassword}
                  className="input-wrapper"
                  name={"password"}
                  placeholder="Enter Confirm Password"
                  // disabled={fields.CenterAdminFlag == false ? true : false}
                  disabled={
                    fields.CenterAdminFlag == false
                      ? true
                      : fields?.passwordShowFlag && editModalFlag
                      ? true
                      : false
                  }
                  // type="password"
                  onChange={handleChange("confirmPassword")}
                  // disabled={loading}
                  size="lg"
                  error={errors["confirmPassword"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <PopupDialog
        show={addEditModalFlag == true ? addEditModalFlag : editModalFlag}
        title={`${addEditModalFlag == true ? "Add New Center" : "Edit Center"}`}
        showCloseIcon={true}
        renderBodyContent={renderAddNewCenterModalContents}
        hideButtons={false}
        onclickConfirm={() => onSave()}
        // onclickCancel={onHandleAddEditCenterCloseModal}
        onclickCancel={
          addEditModalFlag == true
            ? onHandleAddCenterCloseModal
            : onHandleEditCenterCloseModal
        }
        extraStyle={{ maxHeight: "60vh", overflowY: "auto" }}
        popupSize={"lg"}
      />

      <PopupDialog
        show={deleteAdminFlag}
        title={"Delete Admin"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteAdminPopupContents}
        hideButtons={false}
        onclickConfirm={() => onSaveDeleteAdminBtn()}
        onclickCancel={onHandleDeleteAdminCloseModal}
        popupSize={"md"}
      />
    </div>
  );
};

export default AddEditCenterModalForm;
