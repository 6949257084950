import React from "react";
import CardView from "../../../controls/Card/CardView";
import TechniciansList from "../../../pages/NewUIPages/Components/Lists/TechnicianList";
import { Input, SearchView, ThemedButton } from "../../../controls";
import { FaPlus } from "react-icons/fa6";
import logoutIcon from "../../../../assets/images/icons/logout-confirmation.png";
import { IoIosSearch } from "react-icons/io";
import { FiAlertTriangle } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";
import { AiOutlineDelete } from "react-icons/ai";
import { strings } from "../../../../constants/stringConstants";
import PopupDialog from "../Components/Other/PopupDialog";
import "./TechniciansStyles.scss";

const TechniciansView = (props) => {
  const selectedTechnician = props.technicianInfo;

  const selectedTechnicianName = `${
    (selectedTechnician?.firstName !== null &&
    selectedTechnician?.firstName !== undefined
      ? `${selectedTechnician?.firstName
          .charAt(0)
          .toUpperCase()}${selectedTechnician?.firstName.slice(1)}`
      : "") +
    " " +
    (selectedTechnician?.lastName !== null &&
    selectedTechnician?.lastName !== undefined
      ? `${selectedTechnician?.lastName
          .charAt(0)
          .toUpperCase()}${selectedTechnician?.lastName.slice(1)}`
      : "")
  }`;

  const renderAddNewTechniciansContents = () => {
    return (
      <div className="add-new-technician-form">
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span> Technician Name :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter First Name"
              value={props.addTechnicianFields.firstName}
              error={props.errors["firstName"]}
              onChange={props.onAddTechnicianFieldChange("firstName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
            <div style={{ width: "20px" }} />
            <Input
              className="input-wrapper"
              placeholder="Enter Last Name"
              value={props.addTechnicianFields.lastName}
              error={props.errors["lastName"]}
              onChange={props.onAddTechnicianFieldChange("lastName")}
              halfsize={true}
              charLimit={50}
              showCharLimit={true}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span> Email ID :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Email ID"
              disabled={props.showEditTechnicianPopup}
              value={props.addTechnicianFields.email}
              onChange={props.onAddTechnicianFieldChange("email")}
              error={props.errors["email"]}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        <div className="block-level-group">
          <label className="form-label">
            <span className="required">* </span> Mobile :
          </label>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Mobile Number"
              value={props.addTechnicianFields.phoneNo}
              onChange={props.onAddTechnicianFieldChange("phoneNo")}
              error={props.errors["phoneNo"]}
              maxLength={10}
            />
          </div>
          <div className="last-dummy-item" />
        </div>
        {props.showEditTechnicianPopup ? null : (
          <>
            <div
              className="block-level-group"
              style={{
                marginBottom:
                  props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                    ? "15px"
                    : "0px",
              }}
            >
              <label className="form-label">
                <span className="required">* </span> Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Password"
                  value={props.addTechnicianFields.password}
                  onChange={props.onAddTechnicianFieldChange("password")}
                  error={props.errors["password"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
            <div className="block-level-group">
              <label className="form-label">
                <span className="required">* </span> Confirm Password :
              </label>
              <div className="value-item">
                <Input
                  className="input-wrapper"
                  placeholder="Enter Confirm Password"
                  value={props.addTechnicianFields.confirmPassword}
                  onChange={props.onAddTechnicianFieldChange("confirmPassword")}
                  error={props.errors["confirmPassword"]}
                />
              </div>
              <div className="last-dummy-item" />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderDeleteTechnicianPopupContents = () => {
    // const data = props.technicianInfo;
    // const selectedTechnicianName = `${
    //   (data?.firstName !== null && data?.firstName !== undefined
    //     ? `${data?.firstName.charAt(0).toUpperCase()}${data?.firstName.slice(
    //         1
    //       )}`
    //     : "") +
    //   " " +
    //   (data?.lastName !== null && data?.lastName !== undefined
    //     ? `${data?.lastName.charAt(0).toUpperCase()}${data?.lastName.slice(1)}`
    //     : "")
    // }`;
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>{`Are you sure you want to delete ${selectedTechnicianName} ?`}</text>
      </div>
    );
  };

  const renderBulkDeleteTechnicianPopupContents = () => {
    const deleteCount = props.selectedIdsToDelete.length ?? "";
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        {deleteCount > 1 ? (
          <text>{`Are you sure you want to Delete ${deleteCount} Technicians ?`}</text>
        ) : (
          <text>{`Are you sure you want to Delete ${deleteCount} Technician ?`}</text>
        )}
      </div>
    );
  };

  const renderUnlockMessage = () => {
    return (
      <div className="unlock-modal-content">
        <img className="unlock-icon" src={logoutIcon} alt="icon" />
        <text>Are you sure you want to Unlock {selectedTechnicianName}?</text>
      </div>
    );
  };

  const renderResetPasswordContent = () => {
    return (
      <div className="reset-password-technician-form">
        <div
          className="fields"
          style={{
            marginBottom:
              props.errors["password"] === strings.WEAK_PASSWORD_ERROR
                ? "15px"
                : "0px",
          }}
        >
          <text className="label-item">
            <span className="required-star">* </span> New Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Password"
              type={"password"}
              size="sm"
              value={props.resetPasswordTechnicianFields.password}
              onChange={props.onResetPasswordTechnicianFieldChange("password")}
              error={props.errors["password"]}
            />
            <div className="dummy-item" />
          </div>
        </div>

        <div className="fields">
          <text className="label-item">
            <span className="required-star">* </span> Confirm Password :
          </text>
          <div className="value-item">
            <Input
              className="input-wrapper"
              placeholder="Enter Confirm Password"
              value={props.resetPasswordTechnicianFields.confirmPassword}
              onChange={props.onResetPasswordTechnicianFieldChange(
                "confirmPassword"
              )}
              type={"password"}
              size="sm"
              error={props.errors["confirmPassword"]}
            />
            <div className="dummy-item" />
          </div>
        </div>
      </div>
    );
  };

  //---------------------------------------------------------------

  return (
    <div className="technicians-container">
      <CardView extraStyle="card-container" cardstyle="card-style">
        <div className="card-header-wraper">
          <div className="title">
            Total Technicians : {props.totalTechnicians}
          </div>

          <div className="search-and-buttons">
            {props.selectedIdsToDelete.length > 0 ? null : (
              <>
                <SearchView
                  placeholder="Search Technicians"
                  onSearchClick={props.onSearchClick}
                  onSearchTextCleared={props.onSearchTextCleared}
                />

                <ThemedButton
                  title="Add New Technician"
                  size={"large"}
                  icon={<FaPlus size={15} color="#fff" />}
                  onClick={() => {
                    props.onAddNewTechniciansClick();
                  }}
                  styles="primary medium"
                />
              </>
            )}

            <CSSTransition
              in={
                props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0
              }
              timeout={500}
              classNames="zoom"
            >
              <div className="button-text">
                {props.selectedIdsToDelete &&
                props.selectedIdsToDelete.length > 0 ? (
                  <>
                    <text>{`Total ${props.selectedIdsToDelete.length} items in this page are selected`}</text>
                    <ThemedButton
                      title="Delete"
                      size={"medium"}
                      icon={<AiOutlineDelete size={15} color="#fff" />}
                      onClick={props.onBulkTechnicianDeleteClick}
                      styles="primary medium"
                    />
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </div>
        </div>

        <TechniciansList
          userRole={props.userRole}
          //
          page={props.page}
          limit={props.limit}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
          //
          totalTechnicians={props.totalTechnicians}
          TechniciansList={props.technicianList}
          //
          onSelectedRowsChange={props.onSelectedRowsChange}
          clearSelectedRows={props.clearSelectedRows}
          //
          onEditTechnicianClick={props.onEditTechnicianClick}
          onDeleteTechnicianClick={props.onDeleteTechnicianClick}
          onResetPasswordTechnicianClick={props.onResetPasswordTechnicianClick}
          //
          onUnlockTechnicianAccountClick={props.onUnlockTechnicianAccountClick}
          //
          onTechnicianListFilterClick={props.onTechnicianListFilterClick}
          onTechnicianListSortClick={props.onTechnicianListSortClick}
        />
      </CardView>

      <PopupDialog
        show={
          props.showEditTechnicianPopup
            ? props.showEditTechnicianPopup
            : props.showAddNewTechniciansPopup
        }
        title={
          props.showEditTechnicianPopup
            ? "Edit Technician"
            : "Add New Technician"
        }
        showCloseIcon={true}
        renderBodyContent={renderAddNewTechniciansContents}
        confirmButtonTitle={props.showEditTechnicianPopup ? "Save" : "Add"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={
          props.showEditTechnicianPopup
            ? props.onEditTechnicianConfirmClick
            : props.onAddNewTechnicianConfirmClick
        }
        onclickCancel={
          props.showEditTechnicianPopup
            ? props.onCloseEditTechnicianPopup
            : props.onCloseAddTechnicianPopup
        }
      />

      <PopupDialog
        show={props.showDeleteTechnicianPopup}
        title={"Delete Technician"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteTechnicianPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onDeleteTechnicianConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseDeleteTechnicianPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showBulkDeleteTechnicianPopup}
        title={"Delete Technicians"}
        showCloseIcon={true}
        renderBodyContent={renderBulkDeleteTechnicianPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onBulkTechnicianDeleteConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseDeleteTechnicianPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showResetPasswordTechnicianPopup}
        title={`Reset Password For ${selectedTechnicianName}`}
        showCloseIcon={true}
        renderBodyContent={renderResetPasswordContent}
        confirmButtonTitle={"Confirm"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onResetPasswordTechnicianConfirmClick} //temp delete functionality it is disable
        onclickCancel={props.onCloseResetPasswordTechnicianPopup}
        popupSize={"md"}
      />

      <PopupDialog
        show={props.showUnlockTechnicianConfirmationPopup}
        title={"Unlock"}
        showCloseIcon={true}
        renderBodyContent={renderUnlockMessage}
        confirmButtonTitle={"Unlock"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={props.onUnlockTechnicianAccountConfirmClick}
        onclickCancel={props.closeUnlockTechnicianPopup}
        hideButtons={false}
        popupSize={"md"}
      />
    </div>
  );
};

export default TechniciansView;
