import React from "react";
import {
  CardView,
  DateRangeSelector,
  SearchView,
  ThemedButton,
  TogglebuttonGroup,
} from "../../../controls";
import EcgReportsList from "../Components/Lists/EcgReportsList";
import { AiOutlineDownload } from "react-icons/ai";
import "./EcgReportsStyles.scss";

const EcgReportsView = (props) => {
  return (
    <div className="ecg-reports-container-new">
      <CardView extraStyle="card-container">
        <div className="card-header-wraper">
          <div className="title">
            Reports by Doctors
            {/* Total Reports: {props.totalReports} */}
          </div>

          <div className="search-and-buttons">
            <TogglebuttonGroup
              selectedItemId={props.toggleValue}
              onItemSelected={props.onToggleChange}
              customToggleData={props.toggleData}
            />

            <DateRangeSelector
              startDate={props.dateRangeStartDate}
              endDate={props.dateRangeEndDate}
              onDatesChange={props.onDatesChange}
            />

            <SearchView
              placeholder={"Search ECG Reports"}
              onSearchClick={props.onSearchClick}
              onSearchTextCleared={props.onSearchTextCleared}
              updatedCurrentValue={props.searchStringParam}
            />

            {/* <ThemedButton
              title="Download"
              size={"medium"}
              icon={<AiOutlineDownload size={15} color="#fff" />}
              onClick={() => {}}
              styles="primary medium"
            /> */}
          </div>
        </div>

        <div className="ps-2 pe-2">
          <EcgReportsList
            userRole={props.userRole}
            isReportListByDoctors={true} // For rendering columns
            //
            page={props.page}
            limit={props.limit}
            totalReports={props.totalReports}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            onChangePage={props.onChangePage}
            onSelectedRowsChange={() => {}}
            hideCheckboxes={true}
            //
            reportList={props.reportList}
            onViewReportClick={null}
            onSupervisorViewReportClick={null}
            onPrintReportClick={null}
            onDownloadReportClick={null}
            onViewDoctorsReportsClick={props.onViewDoctorsReportsClick}
            onECGReportByDoctorListSortClick={
              props.onECGReportByDoctorListSortClick
            }
          />
        </div>
      </CardView>
    </div>
  );
};

export default EcgReportsView;
