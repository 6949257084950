import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import Modal from "react-bootstrap/Modal";
import { BiErrorCircle } from "react-icons/bi";
import { PiCheckCircle } from "react-icons/pi";
import { Store } from "react-notifications-component";
import { hideAlert } from "../../../store/alert/alertActions";
import { ThemedButton } from "../../controls";

class AlertView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { popupTitle: "", popupMessage: "", showPopupAlert: false };
  }
  onHandleKeyDown = (e) => {
    e.preventDefault();
    if (e.keyCode === 13 && this.state.showPopupAlert) {
      this.setState({
        popupTitle: "",
        popupMessage: "",
        showPopupAlert: false,
      });
      this.props.hideAlert();
    }
  };

  render() {
    const { isOpen, title, msg, hideAlert } = this.props;
    const { type } = this.props;
    console.log("Alert type : ", type);

    if (
      isOpen &&
      (String(type).toLowerCase() === "success" ||
        String(type).toLowerCase() === "danger")
    ) {
      // Show Alert Popup for success and danger types
      this.setState({
        popupTitle: title,
        popupMessage: msg,
        showPopupAlert: true,
      });
    } else if (isOpen) {
      // Show top-right notification for info and warning types
      Store.addNotification({
        title: title,
        message: msg,
        type: type,
        container: "top-right",
        dismiss: {
          duration: 3000,
        },
      });
      hideAlert();
    }

    return this.state.showPopupAlert === false ? null : (
      <div tabIndex={0} onKeyDown={this.onHandleKeyDown}>
        <Modal
          show={true}
          onHide={() => {
            this.setState({
              popupTitle: "",
              popupMessage: "",
              showPopupAlert: false,
            });
            hideAlert();
          }}
          // size="sm"
          backdrop="static"
          style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
          centered
          // Modal Styles are applied from global App.scss file !!
        >
          {String(type).toLowerCase() === "danger" ? (
            <Modal.Header closeButton={true}>
              <Modal.Title
                style={{
                  color: "#FF4D4F",
                  fontWeight: 500,
                }}
              >
                {this.state.popupTitle}
              </Modal.Title>
            </Modal.Header>
          ) : null}

          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                margin:
                  String(type).toLowerCase() === "success" ? "20px" : "0px",
              }}
            >
              {String(type).toLowerCase() === "success" ? (
                <PiCheckCircle
                  size={20}
                  color={"#00b48a"}
                  style={{ minWidth: "30px" }}
                />
              ) : (
                <BiErrorCircle
                  size={20}
                  color={"#FF4D4F"}
                  style={{ minWidth: "30px" }}
                />
              )}

              <text style={{ fontWeight: 500 }}>{this.state.popupMessage}</text>
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{
              border: "none",
              paddingTop: "0px",
              paddingBottom: "15px",
              marginTop: "-10px",
              marginRight: "10px",
            }}
          >
            <ThemedButton
              title={String(type).toLowerCase() === "danger" ? "OK" : "Done"}
              size={"small"}
              styles="primary"
              onClick={() => {
                this.setState({
                  popupTitle: "",
                  popupMessage: "",
                  showPopupAlert: false,
                });
                hideAlert();
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AlertView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  msg: PropTypes.string,
  type: PropTypes.string,
  hideAlert: PropTypes.func.isRequired,
};

const mapStateToProps = ({ alert: { isOpen, title, msg, type } }) => ({
  isOpen,
  title,
  msg,
  type,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideAlert,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AlertView);
