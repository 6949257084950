import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";
import { history } from "../../../../../store/configure/configureStore";
import { getDashboardData } from "../../../../../store/dashboard/dashboardActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import SupervisorView from "./SupervisorView";
import moment from "moment";

export class SupervisorDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",
      firstName: "",
      lastName: "",
    };

    this.populateSupervisorData = this.populateSupervisorData.bind(this);
  }

  componentDidMount() {
    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var firstName = this.props.loginUser?.userProfile?.firstName ?? "";
    var lastName = this.props.loginUser?.userProfile?.lastName ?? "";
    firstName = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)}`;
    lastName = `${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;

    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
      firstName: firstName,
      lastName: lastName,
    });

    // Fetch Dashboard data for all users...
    if (loginUserRole) {
      this.props.showLoader();
      this.props.getDashboardData();

      setTimeout(() => {
        this.props.hideLoader();
        this.populateSupervisorData();
      }, 1500);
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    this.populateSupervisorData();
  }

  populateSupervisorData = () => {
    var dashboardData = this.props.dashboardData; // Redux data
    console.log("DASHBOARD DATA Supervisor : ", JSON.stringify(dashboardData));
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.supervisor) {
      return (
        <SupervisorView
          userRole={this.state.userRole}
          userCreatedAt={this.state.userCreatedAt}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    dashboardData: state.dashboard.dashboardData,
    filteredReportList: state.report.filteredReportList,
    filteredDeviceList: state.device.filteredDeviceList,
    selectedCheckInDevice: state.device.selectedCheckInDevice,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
})(SupervisorDashboardContainer);
