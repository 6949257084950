export const actionTypes = {
  GET_STATE_PENDING: "GET_STATE_PENDING",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_ERROR: "GET_STATE_ERROR",

  GET_DISTRICT_PENDING: "GET_DISTRICT_PENDING",
  GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
  GET_DISTRICT_ERROR: "GET_DISTRICT_ERROR",

  GET_CITY_PENDING: "GET_CITY_PENDING",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_ERROR: "GET_CITY_ERROR",

  GET_PINCODE_PENDING: "GET_PINCODE_PENDING",
  GET_PINCODE_SUCCESS: "GET_PINCODE_SUCCESS",
  GET_PINCODE_ERROR: "GET_PINCODE_ERROR",

  GET_SECURITY_QUESTIONS_LIST_PENDING: "GET_SECURITY_QUESTIONS_LIST_PENDING",
  GET_SECURITY_QUESTIONS_LIST_SUCCESS: "GET_SECURITY_QUESTIONS_LIST_SUCCESS",
  GET_SECURITY_QUESTIONS_LIST_ERROR: "GET_SECURITY_QUESTIONS_LIST_ERROR",

  GET_STATUS_PENDING: "GET_STATUS_PENDING",
  GET_STATUS_SUCCESS: "GET_STATUS_SUCCESS",
  GET_STATUS_ERROR: "GET_STATUS_ERROR",

  UPLOAD_IMAGE_PENDING: "UPLOAD_IMAGE_PENDING",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_ERROR: "UPLOAD_IMAGE_ERROR",

  UNLOCK_USER_ACCOUNT_PENDING: "UNLOCK_USER_ACCOUNT_PENDING",
  UNLOCK_USER_ACCOUNT_SUCCESS: "UNLOCK_USER_ACCOUNT_SUCCESS",
  UNLOCK_USER_ACCOUNT_ERROR: "UNLOCK_USER_ACCOUNT_ERROR",

  DELETE_IMAGE_PENDING: "DELETE_IMAGE_PENDING",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_ERROR: "DELETE_IMAGE_ERROR",
  RESET_DISTRICT_LIST: "RESET_DISTRICT_LIST",
};

const initialState = {
  getStatePending: false,
  getStateSuccess: false,
  getStateError: null,

  getDistrictPending: false,
  getDistrictSuccess: false,
  getDistrictError: null,

  getCityPending: false,
  getCitySuccess: false,
  getCityError: null,

  getPincodePending: false,
  getPincodeSuccess: false,
  getPincodeError: null,

  getSecurityQuestionsPending: false,
  getSecurityQuestionsSuccess: false,
  getSecurityQuestionsError: null,

  getStatusPending: false,
  getStatusSuccess: false,
  getStatusError: null,

  UploadImagePending: false,
  UploadImageSuccess: false,
  UploadImageError: null,

  deleteImagePending: false,
  deleteImageSuccess: false,
  deleteImageError: null,

  unlockUserAccountPending: false,
  unlockUserAccountSuccess: false,
  unlockUserAccountError: null,

  uploadImageData: null,

  stateList: [],
  districtList: [],
  pincodeList: [],
  cityList: [],
  statusList: [],
  securityQuestions: [],
};

export default function master(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_STATE_PENDING:
    case actionTypes.GET_STATE_SUCCESS:
    case actionTypes.GET_STATE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_DISTRICT_PENDING:
    case actionTypes.GET_DISTRICT_SUCCESS:
    case actionTypes.GET_DISTRICT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_CITY_PENDING:
    case actionTypes.GET_CITY_SUCCESS:
    case actionTypes.GET_CITY_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_PINCODE_PENDING:
    case actionTypes.GET_PINCODE_SUCCESS:
    case actionTypes.GET_PINCODE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_SECURITY_QUESTIONS_LIST_PENDING:
    case actionTypes.GET_SECURITY_QUESTIONS_LIST_SUCCESS:
    case actionTypes.GET_SECURITY_QUESTIONS_LIST_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_STATUS_PENDING:
    case actionTypes.GET_STATUS_SUCCESS:
    case actionTypes.GET_STATUS_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.UNLOCK_USER_ACCOUNT_PENDING:
    case actionTypes.UNLOCK_USER_ACCOUNT_SUCCESS:
    case actionTypes.UNLOCK_USER_ACCOUNT_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.UPLOAD_IMAGE_PENDING:
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
    case actionTypes.UPLOAD_IMAGE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_IMAGE_PENDING:
    case actionTypes.DELETE_IMAGE_SUCCESS:
    case actionTypes.DELETE_IMAGE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.RESET_DISTRICT_LIST:
      return {
        ...state,
        districtList: [],
      };

    default:
      return state;
  }
}
