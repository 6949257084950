export const actionTypes = {
  GET_SUPERVISOR_LIST_PENDING: "GET_SUPERVISOR_LIST_PENDING",
  GET_SUPERVISOR_LIST_SUCCESS: "GET_SUPERVISOR_LIST_SUCCESS",
  GET_SUPERVISOR_LIST_ERROR: "GET_SUPERVISOR_LIST_ERROR",

  GET_SUPERVISOR_INFO_BY_ID_PENDING: "GET_SUPERVISOR_INFO_BY_ID_PENDING",
  GET_SUPERVISOR_INFO_BY_ID_SUCCESS: "GET_SUPERVISOR_INFO_BY_ID_SUCCESS",
  GET_SUPERVISOR_INFO_BY_ID_ERROR: "GET_SUPERVISOR_INFO_BY_ID_ERROR",

  SET_SELECTED_SUPERVISOR: "SET_SELECTED_SUPERVISOR",
  RESET_SELECTED_SUPERVISOR: "RESET_SELECTED_SUPERVISOR",

  ADD_SUPERVISOR_PENDING: "ADD_SUPERVISOR_PENDING",
  ADD_SUPERVISOR_SUCCESS: "ADD_SUPERVISOR_SUCCESS",
  ADD_SUPERVISOR_ERROR: "ADD_SUPERVISOR_ERROR",

  EDIT_SUPERVISOR_PENDING: "EDIT_SUPERVISOR_PENDING",
  EDIT_SUPERVISOR_SUCCESS: "EDIT_SUPERVISOR_SUCCESS",
  EDIT_SUPERVISOR_ERROR: "EDIT_SUPERVISOR_ERROR",

  DELETE_SUPERVISOR_PENDING: "DELETE_SUPERVISOR_PENDING",
  DELETE_SUPERVISOR_SUCCESS: "DELETE_SUPERVISOR_SUCCESS",
  DELETE_SUPERVISOR_ERROR: "DELETE_SUPERVISOR_ERROR",

  DELETE_SUPERVISOR_IN_BULK_PENDING: "DELETE_SUPERVISOR_IN_BULK_PENDING",
  DELETE_SUPERVISOR_IN_BULK_SUCCESS: "DELETE_SUPERVISOR_IN_BULK_SUCCESS",
  DELETE_SUPERVISOR_IN_BULK_ERROR: "DELETE_SUPERVISOR_IN_BULK_ERROR",
};

const initialState = {
  getSupervisorListPending: false,
  getSupervisorListSuccess: false,
  getSupervisorListError: null,

  getSupervisorInfoByIdPending: false,
  getSupervisorInfoByIdSuccess: false,
  getSupervisorInfoByIdError: null,

  addSupervisorPending: false,
  addSupervisorSuccess: false,
  addSupervisorError: null,

  editSupervisorPending: false,
  editSupervisorSuccess: false,
  editSupervisorError: null,

  deleteSupervisorPending: false,
  deletesSupervisorSuccess: false,
  deleteSupervisorError: null,

  deleteSupervisorInBulkPending: false,
  deleteSupervisorInBulkSuccess: false,
  deleteSupervisorInBulkError: null,

  supervisorsList: [],
  filteredSupervisorCount: 0,
  selectedSupervisor: null,
};

export default function supervisors(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_SUPERVISOR_LIST_PENDING:
    case actionTypes.GET_SUPERVISOR_LIST_SUCCESS:
    case actionTypes.GET_SUPERVISOR_LIST_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_SUPERVISOR_INFO_BY_ID_PENDING:
    case actionTypes.GET_SUPERVISOR_INFO_BY_ID_SUCCESS:
    case actionTypes.GET_SUPERVISOR_INFO_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.SET_SELECTED_SUPERVISOR:
    case actionTypes.RESET_SELECTED_SUPERVISOR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_SUPERVISOR_PENDING:
    case actionTypes.ADD_SUPERVISOR_SUCCESS:
    case actionTypes.ADD_SUPERVISOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_SUPERVISOR_PENDING:
    case actionTypes.EDIT_SUPERVISOR_SUCCESS:
    case actionTypes.EDIT_SUPERVISOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_SUPERVISOR_PENDING:
    case actionTypes.DELETE_SUPERVISOR_SUCCESS:
    case actionTypes.DELETE_SUPERVISOR_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_SUPERVISOR_IN_BULK_PENDING:
    case actionTypes.DELETE_SUPERVISOR_IN_BULK_SUCCESS:
    case actionTypes.DELETE_SUPERVISOR_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
