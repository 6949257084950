import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getHospitalAdminsList,
  getHospitalAdminsDataFromId,
  setSelectedHospitalAdmin,
  resetSelectedHospitalAdmin,
  addNewHospitalAdmin,
  editHospitalAdmin,
  deleteHospitalAdmin,
  deleteAdministratorInBulk,
} from "../../../../store/administrators/adminActions";
import { unlockUser } from "../../../../store/master/masterActions";
import { resetUserPasswordByAdmin } from "../../../../store/auth/authActions";
import { getHospitalsListWithFilter } from "../../../../store/hospital/hospitalActions";
import { history } from "../../../../store/configure/configureStore";
import AdministratorsView from "./AdministratorsView";
import * as validator from "../../../../utils/validator";
import {
  trimFormData,
  ignoreOrderArrayComparison,
} from "../../../../utils/common";
import { strings } from "../../../../constants/stringConstants";

class AdministratorsContainer extends Component {
  validatorFieldsForHospitalAdmin = {};
  validatorFieldsForResetPassword = {};
  constructor(props) {
    super(props);

    this.validatorFieldsForHospitalAdmin["required"] = [
      "email",
      "password",
      "confirmPassword",
      "firstName",
      "lastName",
      "contactNumber",
    ];

    this.validatorFieldsForResetPassword["required"] = [
      "password",
      "confirmPassword",
    ];

    this.state = {
      page: 1,
      hospitalListPage: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      adminsList: [],
      totalAdmins: 0,

      // Popup Dialog
      showAddAdminPopup: false,
      showEditAdminPopup: false,
      showDeleteAdminPopup: false,
      showDeleteAdminConfirmPopup: false,
      showResetPasswordHospitalAdminPopup: false,
      showUnlockAdministratorConfirmationPopup: false,
      //
      addAdminFields: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
      },
      //reset password
      resetPasswordHospitalAdminFields: {
        password: "",
        confirmPassword: "",
      },
      searchHospitalText: "",
      hospitals: [],
      previousHospitals: [],
      errors: {},
      searchHospitalList: [],
      isValidationsOn: false,
      hospitalAdminInfo: {},
      noScroll: false,

      // Search filters
      pageCount: 2,
      filters: {
        text: "",
      },

      // Bulk delete
      selectedIdsToDelete: [],
      clearSelectedRows: false,
    };

    this.callGetAdminsListAPI = this.callGetAdminsListAPI.bind(this);
    this.populateListData = this.populateListData.bind(this);

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);

    this.onAddAdministratorClick = this.onAddAdministratorClick.bind(this);
    this.onCloseAddAdministratorPopup =
      this.onCloseAddAdministratorPopup.bind(this);

    this.onAdminSelected = this.onAdminSelected.bind(this);
    this.onAddAdministratorConfirmClick =
      this.onAddAdministratorConfirmClick.bind(this);
    this.onHospitalSearchChange = this.onHospitalSearchChange.bind(this);
    this.onAddAdminFieldChange = this.onAddAdminFieldChange.bind(this);
    this.onAssignHospitalChange = this.onAssignHospitalChange.bind(this);
    this.onScrollHospitalListDropdown =
      this.onScrollHospitalListDropdown.bind(this);
    this.handleDeleteHospital = this.handleDeleteHospital.bind(this);

    this.onEditHospitalAdminClick = this.onEditHospitalAdminClick.bind(this);
    this.onEditAdminConfirmClick = this.onEditAdminConfirmClick.bind(this);
    this.onCloseEditAdminPopup = this.onCloseEditAdminPopup.bind(this);

    this.onCloseDeleteAdminPopup = this.onCloseDeleteAdminPopup.bind(this);
    this.onDeleteAdminConfirmClick = this.onDeleteAdminConfirmClick.bind(this);

    this.handleSelectedRowsChange = this.handleSelectedRowsChange.bind(this);
    this.onBulkAdministratorDeleteClick =
      this.onBulkAdministratorDeleteClick.bind(this);
    this.onBulkAdministratorDeleteConfirmClick =
      this.onBulkAdministratorDeleteConfirmClick.bind(this);
    this.onDeleteHospitalAdminClick =
      this.onDeleteHospitalAdminClick.bind(this);

    this.onResetPasswordHospitalAdminFieldChange =
      this.onResetPasswordHospitalAdminFieldChange.bind(this);
    this.onResetPasswordHospitalAdminClick =
      this.onResetPasswordHospitalAdminClick.bind(this);
    this.onResetPasswordHospitalAdminConfirmClick =
      this.onResetPasswordHospitalAdminConfirmClick.bind(this);
    this.onCloseResetPasswordHospitalAdminPopup =
      this.onCloseResetPasswordHospitalAdminPopup.bind(this);

    this.resetFormFields = this.resetFormFields.bind(this);

    this.onUnlockHospitalAdminAccountClick =
      this.onUnlockHospitalAdminAccountClick.bind(this);

    this.onUnlockHospitalAdminAccountConfirmClick =
      this.onUnlockHospitalAdminAccountConfirmClick.bind(this);

    this.closeUnlockHospitalAdminPopup =
      this.closeUnlockHospitalAdminPopup.bind(this);
    this.onHospitalAdminListSortClick =
      this.onHospitalAdminListSortClick.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var previousState = this.props.location?.state ?? {};
    console.log("ADMINS previousState ::: ", JSON.stringify(previousState));

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;

    this.setState({ page: prevPage, limit: prevLimit }, () => {
      window.history.replaceState({}, "");
      this.callGetAdminsListAPI();
      this.callGetHospitalListAPI();
    });
  }

  componentWillUnmount() {}

  //--------------------------- Get Admin List API Call ------------------------

  callGetAdminsListAPI = () => {
    this.props.getHospitalAdminsList(
      this.state.filters,
      (listData, count, metadata, totalPages) => {
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({
            page: totalPages,
          });
        } else {
          this.populateListData(listData, count, metadata);
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  callGetHospitalListAPI = () => {
    console.log("api", this.state.searchHospitalText);
    this.props.getHospitalsListWithFilter(
      { text: this.state.searchHospitalText },
      (list, count, metaData) => {
        const listData = list.map((item) => {
          return { label: item.hospitalName, value: item.id };
        });
        this.setState({
          pageCount: 2,
          searchHospitalList: listData,
        });
      },
      this.state.hospitalListPage,
      25
    );
  };

  resetFormFields = () => {
    console.log("FUN rESET");
    this.setState({
      addAdminFields: {
        ...this.state.addAdminFields,
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
      },
      showAddAdminPopup: false,
      showEditAdminPopup: false,
      searchHospitalText: "",
      hospitals: [],
      noScroll: false,
      pageCount: 2,
      isValidationsOn: false,
      errors: {},
    });
  };

  //-------------------------  Populate List -------------------------------

  populateListData = (listData, count, metadata = {}) => {
    var adminListData = listData; // Redux data

    var hospitalsMetaData = null;
    if (metadata.hasOwnProperty("hospitals")) {
      hospitalsMetaData = metadata.hospitals;
    }

    adminListData.map((item) => {
      var currentAdminsHospitalIds = []; // e.g. [34,38]
      var currentAdminsMetaData = {
        hospitalsCount: 0,
        centersCount: 0,
        devicesCount: 0,
        doctorsCount: 0,
      };

      var adminHospitals = item.hospitalList ?? [];
      currentAdminsMetaData.hospitalsCount = adminHospitals.length;

      // console.log(
      //   "adminHospitals : ",
      //   item.firstName,
      //   " : ",
      //   JSON.stringify(adminHospitals)
      // );

      for (let index = 0; index < adminHospitals.length; index++) {
        const hospital = adminHospitals[index];
        var hospitalId = hospital?.hospitalInfo?.id ?? null;

        if (hospitalId !== null) {
          currentAdminsHospitalIds.push(hospitalId);
        }
      }

      // console.log(
      //   "currentAdminsHospitalIds : ",
      //   item.firstName,
      //   " : ",
      //   JSON.stringify(currentAdminsHospitalIds)
      // );

      currentAdminsHospitalIds.map((hospitalId) => {
        if (
          hospitalsMetaData !== null &&
          hospitalsMetaData.hasOwnProperty(hospitalId)
        ) {
          // console.log("METADATA FOUND!! hospitalId : ", hospitalId);
          var currentHospitalMetaData = hospitalsMetaData[hospitalId];
          currentAdminsMetaData.centersCount += parseInt(
            currentHospitalMetaData.centers?.total ?? 0
          );
          currentAdminsMetaData.devicesCount += parseInt(
            currentHospitalMetaData.devices?.total ?? 0
          );
          currentAdminsMetaData.doctorsCount += parseInt(
            currentHospitalMetaData.doctors?.total ?? 0
          );
        }
      });

      // console.log(
      //   "currentAdminsMetaData : ",
      //   item.firstName,
      //   " : ",
      //   JSON.stringify(currentAdminsMetaData)
      // );

      // Finally add these two values in admin list item :::
      item["currentAdminsHospitalIds"] = currentAdminsHospitalIds;
      item["currentAdminsMetaData"] = currentAdminsMetaData;
    });

    //---------------------------
    this.setState({
      adminsList: adminListData,
      totalAdmins: count,
    });
  };

  //---------------------------  Search List -------------------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetAdminsListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetAdminsListAPI();
      }
    );
  };

  //--------------------- Add / Edit Admin Popup Data Handling ------------------------

  onAddAdministratorClick = () => {
    this.setState({ showAddAdminPopup: true, showEditAdminPopup: false });
  };

  onAddAdminFieldChange = (field) => (e) => {
    let value;
    const { addAdminFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;
    console.log("e", e);
    console.log("field", field);
    var isRequired =
      this.validatorFieldsForHospitalAdmin.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      addAdminFields: { ...addAdminFields, [field]: value },
    });
  };

  onAssignHospitalChange = (Object) => {
    console.log("onAssignHospitalChange", Object);
    if (Object !== null && Object !== undefined) {
      this.state.hospitals.push(Object);
      this.setState(
        {
          filters: { ...this.state.filters, text: "" },
          searchHospitalText: "",
        }
        // () => {
        //   this.callGetHospitalListAPI();
        // }
      );
    } else {
      this.setState(
        {
          searchHospitalText: "",
          noScroll: false,
        },
        () => {
          this.callGetHospitalListAPI();
        }
      );
    }
  };

  handleDeleteHospital = (chipToDelete) => {
    const updatedList = this.state.hospitals.filter(
      (chip) => chip.value !== chipToDelete.value
    );
    this.setState({
      hospitals: [...updatedList],
    });
  };

  onAddAdministratorConfirmClick = () => {
    var custom = [
      validator.email(["email"]),
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
      validator.phone(["contactNumber"]),
    ];
    this.validatorFieldsForHospitalAdmin["custom"] = custom;
    const { addAdminFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForHospitalAdmin,
      trimFormData(addAdminFields)
    );

    if (
      addAdminFields.confirmPassword.length > 0 &&
      addAdminFields.password !== addAdminFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }

    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      let listArray = [];
      this.state.hospitals.map((item) => listArray.push(item.value));
      const data = {
        email: addAdminFields.email,
        password: addAdminFields.password,
        firstName: addAdminFields.firstName,
        lastName: addAdminFields.lastName,
        contactNumber: addAdminFields.contactNumber,
        hospitalIds: listArray,
      };
      this.props.addNewHospitalAdmin(data, (response) => {
        if (response !== null) {
          this.callGetAdminsListAPI();
          this.resetFormFields();
        }
      });
    }
  };

  onHospitalSearchChange = (text) => {
    this.state.searchHospitalText = text;
    this.setState(
      {
        searchHospitalText: text,
        noScroll: false,
      },
      () => {
        this.callGetHospitalListAPI();
      }
    );
  };

  onScrollHospitalListDropdown = () => {
    if (!this.state.noScroll) {
      this.props.getHospitalsListWithFilter(
        { text: this.state.searchHospitalText },
        (list, count, metaData) => {
          if (list.length > 0) {
            const listData = list.map((item) => {
              return { label: item.hospitalName, value: item.id };
            });
            this.setState({
              searchHospitalList: [
                ...this.state.searchHospitalList,
                ...listData,
              ],
            });
            ++this.state.pageCount;
          } else {
            this.setState({ noScroll: true });
          }
        },
        this.state.pageCount, // // direct call from 2nd page because 1st page alredy added
        25
      );
    }
  };

  onCloseAddAdministratorPopup = () => {
    this.setState(
      {
        showAddAdminPopup: false,
        searchHospitalText: "",
        hospitals: [],
      },
      () => {
        this.resetFormFields();
        this.callGetHospitalListAPI();
      }
    );
  };

  //------------------------- Edit Admin  ---------------------------

  onEditHospitalAdminClick = (row) => {
    this.validatorFieldsForHospitalAdmin["required"] =
      this.validatorFieldsForHospitalAdmin["required"].filter(function (item) {
        return item !== "password" && item !== "confirmPassword";
      });
    let updatedArray = [];
    if (row.hospitalList.length > 0) {
      row.hospitalList.map((item) => updatedArray.push(item.hospitalInfo));
      updatedArray = updatedArray.map((item) => {
        return { label: item.hospitalName, value: item.id };
      });
    }
    console.log("updatedArray", updatedArray);
    this.setState({
      addAdminFields: {
        ...this.state.addAdminFields,
        firstName: row?.firstName ?? "",
        lastName: row?.lastName ?? "",
        contactNumber: row?.contactNumber ?? "",
        email: row?.user?.user_name ?? "",
      },
      hospitals: [...updatedArray],
      previousHospitals: [...updatedArray],
      isValidationsOn: true,
      hospitalAdminInfo: row,
      showEditAdminPopup: true, // this flage made hanges in UI
      showAddAdminPopup: true, // bcoz we are reusing add adminstrator UI
    });
    console.log(this.state.hospitals.length);
  };

  onEditAdminConfirmClick = () => {
    var custom = [validator.phone(["contactNumber"])];
    this.validatorFieldsForHospitalAdmin["custom"] = custom;
    const { addAdminFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForHospitalAdmin,
      trimFormData(addAdminFields)
    );
    console.log("before");

    let preUpdatedArrayIds = [];
    let postUpdatedArrayIds = [];
    this.state.previousHospitals.map((item) =>
      preUpdatedArrayIds.push(item.value)
    );
    this.state.hospitals.map((item) => postUpdatedArrayIds.push(item.value));

    let isEqual = ignoreOrderArrayComparison(
      preUpdatedArrayIds,
      postUpdatedArrayIds
    );

    this.setState({ ...this.state, errors, isValidationsOn: true });
    const adminId = this.state.hospitalAdminInfo.id;
    console.log("errors edit", errors);
    if (!Object.keys(errors).length) {
      const data = {
        firstName: this.state.addAdminFields.firstName,
        lastName: this.state.addAdminFields.lastName,
        contactNumber: this.state.addAdminFields.contactNumber,
        hospitalIds: postUpdatedArrayIds,
        isUpdatedHospitals: !isEqual,
      };
      console.log("editdata", data);
      this.props.editHospitalAdmin(adminId, data, (response) => {
        if (response !== null) {
          this.resetFormFields();
          this.callGetAdminsListAPI();
          this.onCloseEditAdminPopup();
        }
      });
    }
  };

  onCloseEditAdminPopup = () => {
    this.setState(
      {
        showEditAdminPopup: false,
        showAddAdminPopup: false,
        isUpdatedHospitals: false,
        searchHospitalText: "",
      },
      () => {
        this.resetFormFields();
        this.callGetHospitalListAPI();
      }
    );
    this.validatorFieldsForHospitalAdmin["required"].push("password");
    this.validatorFieldsForHospitalAdmin["required"].push("confirmPassword");
  };

  //----------------------- Delete Admin  ---------------------------

  onDeleteHospitalAdminClick = (row) => {
    console.log(row);
    this.setState({ hospitalAdminInfo: row, showDeleteAdminPopup: true });
  };

  onDeleteAdminConfirmClick = () => {
    this.props.deleteHospitalAdmin(this.state.hospitalAdminInfo.id, () => {
      this.callGetAdminsListAPI(); // refresh admin list
    });
    this.setState({ showDeleteAdminPopup: false });
  };

  onCloseDeleteAdminPopup = () => {
    this.setState({
      showDeleteAdminPopup: false,
      showDeleteAdminConfirmPopup: false,
    });
  };

  // reset password

  onResetPasswordHospitalAdminClick = (row) => {
    this.setState({
      hospitalAdminInfo: row,
      showResetPasswordHospitalAdminPopup: true,
    });
  };

  onResetPasswordHospitalAdminFieldChange = (field) => (e) => {
    let value;
    const { resetPasswordHospitalAdminFields } = this.state;
    const isNull = e === null;
    const isTextField = e?.target;

    var isRequired =
      this.validatorFieldsForResetPassword.required.includes(field);
    if (isNull) {
      if (this.state.isValidationsOn && isRequired)
        this.state.errors[field] = "Field can not be empty";
      value = e;
    } else if (isTextField) {
      if (this.state.isValidationsOn && isRequired) {
        e.target.value.length > 0
          ? (this.state.errors[field] = "")
          : (this.state.errors[field] = "Field can not be empty");
      }
      value = e.target.value;
    }
    // set data
    this.setState({
      ...this.state,
      resetPasswordHospitalAdminFields: {
        ...resetPasswordHospitalAdminFields,
        [field]: value,
      },
    });
  };

  onResetPasswordHospitalAdminConfirmClick = () => {
    var custom = [
      validator.StrongPassword(["password"]),
      validator.PasswordWithoutSpaces(["password"]),
      validator.password(["password"]),
    ];

    this.validatorFieldsForResetPassword["custom"] = custom;
    const { resetPasswordHospitalAdminFields } = this.state;

    const { errors } = validator.validate(
      this.validatorFieldsForResetPassword,
      trimFormData(resetPasswordHospitalAdminFields)
    );
    console.log("confirm", resetPasswordHospitalAdminFields);
    if (
      resetPasswordHospitalAdminFields.confirmPassword.length > 0 &&
      resetPasswordHospitalAdminFields.password !==
        resetPasswordHospitalAdminFields.confirmPassword
    ) {
      errors["confirmPassword"] = `${strings.PASSWORD_MATCH_ERROR}`;
    }
    console.log("error", errors);
    this.setState({ ...this.state, errors, isValidationsOn: true });

    if (!Object.keys(errors).length) {
      const data = {
        userId: this.state.hospitalAdminInfo?.userId,
        newPassword: resetPasswordHospitalAdminFields.password.trim(),
      };
      console.log(this.state.hospitalAdminInfo);
      this.props.resetUserPasswordByAdmin(data, () => {
        this.setState({
          resetPasswordHospitalAdminFields: {
            ...this.state.resetPasswordHospitalAdminFields,
            password: "",
            confirmPassword: "",
          },
          showResetPasswordHospitalAdminPopup: false,
          isValidationsOn: false,
        });
      });
    }
  };

  onCloseResetPasswordHospitalAdminPopup = () => {
    this.setState({
      showResetPasswordHospitalAdminPopup: false,
      resetPasswordHospitalAdminFields: {
        ...this.state.resetPasswordHospitalAdminFields,
        password: "",
        confirmPassword: "",
      },
      isValidationsOn: false,
      errors: {},
    });
  };

  //

  onUnlockHospitalAdminAccountClick = (row) => {
    console.log("onUnlockHospitalAdminAccountClick");
    this.setState({
      hospitalAdminInfo: row,
      showUnlockAdministratorConfirmationPopup: true,
    });
  };

  onUnlockHospitalAdminAccountConfirmClick = () => {
    console.log("onUnlockHospitalAdminAccountConfirmClick");
    this.props.unlockUser(this.state.hospitalAdminInfo.userId, () => {
      this.setState(
        {
          hospitalAdminInfo: {},
          showUnlockAdministratorConfirmationPopup: false,
        },
        () => {
          this.callGetAdminsListAPI();
        }
      );
    });
  };

  closeUnlockHospitalAdminPopup = () => {
    this.setState({
      hospitalAdminInfo: {},
      showUnlockAdministratorConfirmationPopup: false,
    });
  };

  //-------------------- Delete Bulk Administrators ---------------------------

  handleSelectedRowsChange = (selectedRowsArray, state) => {
    console.log("state", state);
    var adminIds = selectedRowsArray.map((item) => {
      return item.id;
    });
    this.setState({ selectedIdsToDelete: adminIds });
  };

  onBulkAdministratorDeleteClick = () => {
    this.setState({ showDeleteAdminConfirmPopup: true });
  };

  onBulkAdministratorDeleteConfirmClick = () => {
    this.props.deleteAdministratorInBulk(
      { hospitalAdminIds: this.state.selectedIdsToDelete },
      () => {
        this.setState({
          selectedIdsToDelete: [],
          clearSelectedRows: !this.state.clearSelectedRows,
          showDeleteAdminConfirmPopup: false,
        });
        this.callGetAdminsListAPI();
      }
    );
  };

  //----------------------------- Admin Selection ---------------------------

  onAdminSelected = (adminObject) => {
    // Save selected admin in state vaiable and redux
    this.props.setSelectedHospitalAdmin(adminObject);

    setTimeout(() => {
      history.push({
        pathname: "/administrators/hospitals",
        state: {
          page: this.state.page,
          limit: this.state.limit,
        },
      });
    }, 500);
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalAdmins;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetAdminsListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetAdminsListAPI();
      }
    );
  };

  //sorting filters

  onHospitalAdminListSortClick = (column) => {
    console.log(" sorting column name :", column);
    let columnName = "";
    if (column === "Administrator Name") {
      columnName = "firstName";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "asc" : "desc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.props.getHospitalAdminsList(
          this.state.filters,
          (listData, count, metadata, totalPages) => {
            if (
              this.state.page > totalPages &&
              totalPages != 0 &&
              totalPages !== null
            ) {
              this.setState({
                page: totalPages,
              });
            } else {
              this.populateListData(listData, count, metadata);
            }
          },
          this.state.page,
          this.state.limit,
          this.state.sortBy,
          this.state.order
        );
      }
    );
  };

  render() {
    return (
      <AdministratorsView
        adminsList={this.state.adminsList}
        totalAdmins={this.state.totalAdmins}
        //
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        //----------------- Search -------------------
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        //------------------ Add Admin  --------------
        addAdminFields={this.state.addAdminFields}
        showAddAdminPopup={this.state.showAddAdminPopup}
        hospitals={this.state.hospitals}
        searchHospitalList={this.state.searchHospitalList}
        searchHospitalText={this.state.searchHospitalText}
        onAdminSelected={this.onAdminSelected}
        onAddAdministratorClick={this.onAddAdministratorClick}
        onCloseAddAdministratorPopup={this.onCloseAddAdministratorPopup}
        onAddAdminFieldChange={this.onAddAdminFieldChange}
        onAddAdministratorConfirmClick={this.onAddAdministratorConfirmClick}
        onAssignHospitalChange={this.onAssignHospitalChange}
        onHospitalSearchChange={this.onHospitalSearchChange}
        onScrollHospitalListDropdown={this.onScrollHospitalListDropdown}
        handleDeleteHospital={this.handleDeleteHospital}
        //------------------ Edit Admin  --------------
        onEditHospitalAdminClick={this.onEditHospitalAdminClick}
        onEditAdminConfirmClick={this.onEditAdminConfirmClick}
        showEditAdminPopup={this.state.showEditAdminPopup}
        onCloseEditAdminPopup={this.onCloseEditAdminPopup}
        //------------------ Delete Admin  --------------
        onDeleteHospitalAdminClick={this.onDeleteHospitalAdminClick}
        showDeleteAdminPopup={this.state.showDeleteAdminPopup}
        onDeleteAdminConfirmClick={this.onDeleteAdminConfirmClick}
        hospitalAdminInfo={this.state.hospitalAdminInfo}
        onCloseDeleteAdminPopup={this.onCloseDeleteAdminPopup}
        //------------------ Bulk Delete Admin  --------------
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        onBulkAdministratorDeleteClick={this.onBulkAdministratorDeleteClick}
        onBulkAdministratorDeleteConfirmClick={
          this.onBulkAdministratorDeleteConfirmClick
        }
        //
        showResetPasswordHospitalAdminPopup={
          this.state.showResetPasswordHospitalAdminPopup
        }
        resetPasswordHospitalAdminFields={
          this.state.resetPasswordHospitalAdminFields
        }
        onResetPasswordHospitalAdminFieldChange={
          this.onResetPasswordHospitalAdminFieldChange
        }
        onResetPasswordHospitalAdminClick={
          this.onResetPasswordHospitalAdminClick
        }
        onResetPasswordHospitalAdminConfirmClick={
          this.onResetPasswordHospitalAdminConfirmClick
        }
        onCloseResetPasswordHospitalAdminPopup={
          this.onCloseResetPasswordHospitalAdminPopup
        }
        //
        showDeleteAdminConfirmPopup={this.state.showDeleteAdminConfirmPopup}
        //
        onHospitalAdminListSortClick={this.onHospitalAdminListSortClick}
        //
        errors={this.state.errors}
        //
        showUnlockAdministratorConfirmationPopup={
          this.state.showUnlockAdministratorConfirmationPopup
        }
        onUnlockHospitalAdminAccountClick={
          this.onUnlockHospitalAdminAccountClick
        }
        onUnlockHospitalAdminAccountConfirmClick={
          this.onUnlockHospitalAdminAccountConfirmClick
        }
        closeUnlockHospitalAdminPopup={this.closeUnlockHospitalAdminPopup}
      />
    );
  }
}

AdministratorsContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  showAlert,
  getHospitalAdminsList,
  getHospitalAdminsDataFromId,
  setSelectedHospitalAdmin,
  resetSelectedHospitalAdmin,
  getHospitalsListWithFilter,
  addNewHospitalAdmin,
  editHospitalAdmin,
  deleteHospitalAdmin,
  deleteAdministratorInBulk,
  resetUserPasswordByAdmin,
  unlockUser,
})(AdministratorsContainer);
