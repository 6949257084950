import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import * as validator from "../../../../utils/validator";
import {
  evaluateCaseSourceDestination,
  getOngoingOutwardTransferId,
  trimFormData,
} from "../../../../utils/common";
import { showAlert } from "../../../../store/alert/alertActions";
import {
  getCaseListWithFilter,
  getCaseDetailsById,
  rejectPatientCase,
  acceptCase,
  getTransferredDetailsById,
  setSelectedCaseId,
} from "../../../../store/case/caseActions";
import { showLoader, hideLoader } from "../../../../store/loader/loaderActions";
import {
  createMQTTConnection,
  subscribeToTopic,
  unSubscribeToTopic,
} from "../../../../service/mqttApi";
import {
  saveNotification,
  refreshHomeScreen,
  resetRefreshHomeScreen,
} from "../../../../store/mqtt/mqttActions";
import { getHospitalAdminsDataFromId } from "../../../../store/administrators/adminActions";
import CasesView from "./CasesView";
import { history } from "../../../../store/configure/configureStore";
import { globalConstants } from "../../../../constants/globalConstants";

class CasesContainer extends Component {
  validatorFieldsForCases = {};
  newRecords = [];
  constructor(props) {
    super(props);

    this.validatorFieldsForCases["required"] = [];

    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      page: 1,
      limit: 10,
      sortBy: "",
      order: "",
      listOrder: false,
      totalCases: 0,
      casesList: [],
      //
      selectedIdsToDelete: [],
      caseInfo: {},
      trasnferCaseInfo: {},
      clearSelectedRows: false,
      //
      showViewCasePopup: false,
      showDeleteCasePopup: false,
      showDeleteCaseConfirmPopup: false,
      showResetPasswordHospitalCasePopup: false,
      showRejectCasePopup: false,

      filters: {
        text: "",
      },

      //
      adminsHospitalIds: [],
      errors: {},

      // mqtt
      hospitalAdminId: null,
    };

    this.callGetCaseListAPI = this.callGetCaseListAPI.bind(this);

    this.HospitalAdminEnvSetupAndMqttSubscribe =
      this.HospitalAdminEnvSetupAndMqttSubscribe.bind(this);
    this.unSubscribeFromMqttAlerts = this.unSubscribeFromMqttAlerts.bind(this);

    this.onViewCaseClick = this.onViewCaseClick.bind(this);
    this.onAcceptCaseClick = this.onAcceptCaseClick.bind(this);
    this.onCloseViewCasePopup = this.onCloseViewCasePopup.bind(this);

    this.onRejectCaseClick = this.onRejectCaseClick.bind(this);
    this.onCloseRejectPopup = this.onCloseRejectPopup.bind(this);
    this.callRejectCaseAPI = this.callRejectCaseAPI.bind(this);

    this.callAcceptCaseAPI = this.callAcceptCaseAPI.bind(this);
    this.navigateToCaseDetails = this.navigateToCaseDetails.bind(this);

    this.onSearchTextCleared = this.onSearchTextCleared.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);

    this.onCaseListSortClick = this.onCaseListSortClick.bind(this);
    this.onCaseListFilterClick = this.onCaseListFilterClick.bind(this);
  }

  componentDidMount() {
    var previousState = this.props.location?.statisSource ?? {};
    console.log("ADMINS previousState ::: ", JSON.stringify(previousState));

    createMQTTConnection(() => {
      console.log("DOCTOR DASHBOARD : MQTT Connected!!!");
    });

    // this.HospitalAdminEnvSetupAndMqttSubscribe();
    // this.props.resetRefreshHomeScreen();

    var prevPage = previousState?.page ?? 1;
    var prevLimit = previousState?.limit ?? 10;
    var prevFilter = previousState?.filters ?? { text: "" };

    this.props.getHospitalAdminsDataFromId(
      this.props.loginUser?.userProfile?.id,
      (data) => {
        const hospitalIds = data?.hospitalList.map((item) => {
          return item.hospitalId;
        });
        this.setState({
          adminsHospitalIds: hospitalIds,
        });
      }
    );
    //
    this.setState(
      { page: prevPage, limit: prevLimit, filters: prevFilter },
      () => {
        window.history.replaceState({}, "");
        this.HospitalAdminEnvSetupAndMqttSubscribe();
        this.props.resetRefreshHomeScreen();
      }
    );
  }

  componentWillUnmount() {
    this.unSubscribeFromMqttAlerts();
  }

  componentWillReceiveProps(newProps) {
    console.log("-----componentWillReceiveProps---");
    console.log("-----------------------------------------------------");
    console.log("homeScreenRefreshed :  ", newProps.homeScreenRefreshed);
    //-----------------------------------------------------------------
    // Set to true when a new notification is added (New MQTT recieved)
    if (newProps.homeScreenRefreshed == true) {
      this.page = 1;
      this.lastPage = 0;
      this.setState({ totalCases: 0, casesList: [] });
      this.callGetCaseListAPI();
      this.props.resetRefreshHomeScreen();
    }

    //-----------------------------------------------------------------
    // Updated when a notification is deleted (Post 1 min of adding)
    if (
      newProps.notificationDeletedTimestamp !==
      this.props.notificationDeletedTimestamp
    ) {
      this.newRecords = this.props.notifications
        .filter(
          (item) =>
            item.status === "new_case" &&
            (new Date() - new Date(item.timestamp)) / 60000 <= 1
        )
        .map((item) => item.id);
    }
  }

  // --------------------------- Mqtt connection and API --------

  HospitalAdminEnvSetupAndMqttSubscribe = () => {
    // Set hospital Admin Id in state variable
    var hospitalAdminId = this.props.loginUser?.userProfile?.id ?? null;
    this.setState({ hospitalAdminId: hospitalAdminId });

    // Ask for browser notifications permission
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    this.callGetCaseListAPI();

    // Subscribe to MQTT topic for recieving new case alerts and dynamic list updating
    subscribeToTopic(`hospital/newCaseRequest/${hospitalAdminId}`, (msg) => {
      const data = JSON.parse(msg);

      console.log("mqtt data : ");
      console.log(JSON.stringify(data));

      new Notification("New Case Request", {
        body: "New case request received, kindly accept and proceed for admission.",
      });

      this.props.saveNotification(
        data?.caseId,
        "new_case",
        new Date().getTime()
      );

      this.props.refreshHomeScreen();
    });

    // Subscribe to MQTT topic for recieving case removal alerts
    subscribeToTopic(
      `hospital/cancelledCaseRequest/${hospitalAdminId}`,
      (msg) => {
        const data = JSON.parse(msg);
        console.log("mqtt data : ");
        console.log(JSON.stringify(data));

        var caseId = data.caseId;

        new Notification("Case Reassigned", {
          body: `Case ID ${caseId} has been reassigned to another hospital.`,
        });

        this.props.refreshHomeScreen();
      }
    );
  };

  unSubscribeFromMqttAlerts = () => {
    unSubscribeToTopic(`hospital/newCaseRequest/${this.state.hospitalAdminId}`);
    unSubscribeToTopic(
      `hospital/cancelledCaseRequest/${this.state.hospitalAdminId}`
    );
  };

  //---------------------------- Common API call's ---------------------------

  callGetCaseListAPI = () => {
    this.props.getCaseListWithFilter(
      this.state.filters,
      (listData, count, totalPages) => {
        //checking latest records id's
        this.newRecords = this.props.notifications
          .filter(
            (item) =>
              item.status === "new_case" &&
              (new Date() - new Date(item.timestamp)) / 60000 <= 1
          )
          .map((item) => item.id);
        console.log("newRecords", this.newRecords);
        //
        if (
          this.state.page > totalPages &&
          totalPages != 0 &&
          totalPages !== null
        ) {
          this.setState({ page: totalPages });
        } else {
          this.populateListData(listData, count);
        }
      },
      this.state.page,
      this.state.limit,
      this.state.sortBy,
      this.state.order
    );
  };

  populateListData = (listData, count) => {
    var caseListNew = [];

    for (let index = 0; index < listData.length; index++) {
      var row = listData[index];

      // Basic data manipulation
      var isTransferredCase = row?.linkedTransferId === null ? false : true;
      var linkedHospitalId = isTransferredCase
        ? row?.linkedHospitalId
        : row?.hospitalId;
      var linkedTransferId = row?.linkedTransferId;

      // Now check case accepted status of linkedHospitalId from hospitalCaseActivity array
      const hospitalActivityObject =
        row?.hospitalCaseActivity?.length > 0
          ? row?.hospitalCaseActivity.find(
              (item) => item?.hospitalId == linkedHospitalId
            )
          : null;

      var acceptedStatus = false;
      if (hospitalActivityObject !== null) {
        let hospitalStatus = hospitalActivityObject?.hospitalActivityStatus;
        if (
          hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_ASSIGNED ||
          hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_REJECTED
        ) {
          acceptedStatus = false;
        } else {
          acceptedStatus = true;
        }
      }

      row["isTransferredCase"] = isTransferredCase;
      row["acceptedStatus"] = acceptedStatus;

      console.log("----------------------------------------");
      console.log("DATA ::: CASE ID : ", row.id);
      console.log("DATA ::: isTransferredCase : ", isTransferredCase);
      console.log("DATA ::: linkedTransferId : ", linkedTransferId);
      console.log("DATA ::: linkedHospitalId : ", linkedHospitalId);
      console.log("DATA ::: acceptedStatus : ", acceptedStatus);
      console.log("DATA ::: Hos Activity Obj : ", hospitalActivityObject);

      caseListNew.push(row);

      /*
      //------------------------------------------------------------------------------------------------
      //------------------------------------------------------------------------------------------------
      // Let's first Assume case coming from technician
      var isTransferredCase = false;
      var hospitalId = row?.hospitalId ?? null;
      var responseData = null;

      //------------------------------------------------------------------------------------------------
      // Check if case is coming from Hospital / already sent to a hospital i.e. It is a transfer case!!
      if (row?.transferredCase?.length > 0) {
        var transferId = null;
        var isSource = false;
        var ongoingOutwardTransferId = null;

        // There are  transfer records, So first check if any record refers to incomplete outgoing transfer...
        var transferEntriesArray = row?.transferredCase ?? [];

        ongoingOutwardTransferId = getOngoingOutwardTransferId(
          transferEntriesArray ?? [],
          this.state.adminsHospitalIds ?? []
        );

        if (ongoingOutwardTransferId !== null) {
          // We found one outgoing transfer id in transferEntriesArray,
          // So, remove that transfer entry from this array for further processing
          var itemIndex = transferEntriesArray.findIndex((item) => {
            if (item.id === ongoingOutwardTransferId) {
              return true;
            } else {
              return false;
            }
          });

          if (itemIndex !== -1) {
            transferEntriesArray.splice(itemIndex, 1);
          }
        }

        // If there was an entry for outgoing transfer, we have removed it
        // Now let's check again the transfer array........

        if (transferEntriesArray.length > 0) {
          // If there are still transfer entries remaining in array,
          // means this case is definitly a transferred case (Coming from Hospital / Sent to a Hospital)
          isTransferredCase = true;

          // Now let's parse remaining transfer array to find out which hospital case is assigned to and if that hosp is source or destination
          responseData = evaluateCaseSourceDestination(
            transferEntriesArray ?? [],
            this.state.adminsHospitalIds ?? []
          );

          transferId = responseData?.transferId;
          hospitalId = responseData?.isSource
            ? responseData?.sourceId
            : responseData?.destinationId;
          isSource = responseData?.isSource;
        }
      }

      //------------------------------------------------------------------------------------------------
      // Now Update UI Type based on all data extracted, let's simplify data in below variables.
      let isComingFromTechnician = !isTransferredCase;
      let rowCaseStatus = row?.caseStatus ?? null;
      let rowTransferCaseStatus = responseData?.status ?? null;
      var isSource = responseData?.isSource ?? false;
      var isDestination = !responseData?.isSource ?? false;
      let caseUIType = null;

      console.log("----------------------------------------");
      console.log("DATA ::: CASE ID : ", row.id);
      console.log("DATA ::: isComingFromTechnician : ", isComingFromTechnician);
      console.log("DATA ::: transferId : ", transferId);
      console.log("DATA ::: responseData : ", responseData);
      console.log("DATA ::: rowTransferCaseStatus : ", rowTransferCaseStatus);

      //----------------------------------------------------------
      if (rowCaseStatus === globalConstants.CASE_STATUS_CLOSED) {
        caseUIType = globalConstants.CASE_UI_FOR_CLOSED;
      }

      //----------------------------------------------------------
      else if (
        isComingFromTechnician &&
        (rowCaseStatus == globalConstants.CASE_STATUS_FORWARDED ||
          rowCaseStatus == globalConstants.CASE_STATUS_HOSPITAL_ASSIGNED ||
          rowCaseStatus == globalConstants.CASE_STATUS_AMBULANCE_ASSIGNED)
      ) {
        caseUIType = globalConstants.CASE_UI_FOR_IN_TRANSIT;

        // Check if ride is arrived
        // if (row?.rideInfo?.status == globalConstants.RIDE_ENDED) {
        //   caseUIType = globalConstants.CASE_UI_FOR_ARRIVED;
        // }

        // Check if patient is admitted to this hospital or is again transferred ahead.
        const hospitalActivityObject =
          row?.hospitalCaseActivity?.length > 0
            ? row?.hospitalCaseActivity.find(
                (item) => item?.hospitalId == hospitalId
              )
            : null;

        if (hospitalActivityObject !== null) {
          console.log("Hospital Activity Object ::: ", hospitalActivityObject);
          let hospitalStatus = hospitalActivityObject?.hospitalActivityStatus;

          if (hospitalStatus >= globalConstants.HOSP_ACTIVITY_STATUS_ACCEPTED) {
            row["acceptedStatus"] = true;
          }
          if (
            hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_ADMITTED
          ) {
            caseUIType = globalConstants.CASE_UI_FOR_ADMIT;
          } else if (
            hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_TRANSFERRED
          ) {
            caseUIType = globalConstants.CASE_UI_FOR_TRANSFERRED;
          }
        }
      }

      //----------------------------------------------------------
      else if (
        isComingFromTechnician &&
        (rowCaseStatus === globalConstants.CASE_STATUS_ADMITTED ||
          rowCaseStatus === globalConstants.CASE_STATUS_AWAITING_TRANSFER)
      ) {
        caseUIType = globalConstants.CASE_UI_FOR_ADMIT;
      }

      //----------------------------------------------------------
      else if (
        isTransferredCase &&
        isSource &&
        rowTransferCaseStatus >= globalConstants.TRANSFER_DETAILS_CONFIRM
      ) {
        caseUIType = globalConstants.CASE_UI_FOR_TRANSFERRED;
      }

      //----------------------------------------------------------
      else if (
        isTransferredCase &&
        isDestination &&
        rowTransferCaseStatus <= globalConstants.TRANSFER_DETAILS_CONFIRM
      ) {
        caseUIType = globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER;
      }

      //----------------------------------------------------------
      else if (
        isTransferredCase &&
        isDestination &&
        rowTransferCaseStatus == globalConstants.TRANSFER_ACCEPTED_BY_DEST
      ) {
        // Case Accepted by hospital and afte this transfer entries status is never updated any further
        // Byh default let's show the case as 'IN TRANSIT TRANSFER'
        caseUIType = globalConstants.CASE_UI_FOR_IN_TRANSIT_TRANSFER;
        row["acceptedStatus"] = true;

        // Now check if patient is admitted to this destination hospital or is again transferred ahead.
        const hospitalActivityObject =
          row?.hospitalCaseActivity?.length > 0
            ? row?.hospitalCaseActivity.find(
                (item) => item?.hospitalId == hospitalId
              )
            : null;

        console.log("----------------------------------------");
        console.log(
          "Hospital Activity Object ::: ",
          hospitalId,
          "--->",
          hospitalActivityObject
        );

        if (hospitalActivityObject !== null) {
          let hospitalStatus = hospitalActivityObject?.hospitalActivityStatus;

          if (
            hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_ADMITTED
          ) {
            caseUIType = globalConstants.CASE_UI_FOR_ADMIT;
          } else if (
            hospitalStatus === globalConstants.HOSP_ACTIVITY_STATUS_TRANSFERRED
          ) {
            caseUIType = globalConstants.CASE_UI_FOR_TRANSFERRED;
          }
        }
      }

      // Adding new values in row to show correct UI in list and to help fetch data on 'View' click.
      row["caseUIType"] = caseUIType;
      row["isTransferredCase"] = isTransferredCase;
      row["currentTransferId"] = transferId;
      row["currentHospitalId"] = hospitalId;

      console.log("----------------------------------------");
      console.log("ROW UI Type:::", row.id, "-->", caseUIType);
      console.log("ROW ::: ", row.id, "-->", row);
      console.log("----------------------------------------");
       caseListNew.push(row);
    */
    }

    this.setState({ casesList: caseListNew, totalCases: count });
  };

  //------------------- View Case Before Accepting -------------------

  onViewCaseClick = (rowData) => {
    this.props.getCaseDetailsById(rowData?.id, (response) => {
      var caseData = response ?? {};

      // These values are calculated while populating list, and won't be available in getCaseById
      caseData["isTransferredCase"] = rowData?.isTransferredCase;
      caseData["acceptedStatus"] = rowData?.acceptedStatus;

      // if transferred case then fetch transfer details
      if (rowData?.isTransferredCase) {
        let transferId = rowData?.linkedTransferId ?? null;
        this.props.getTransferredDetailsById(transferId, (response) => {
          this.setState({
            caseInfo: caseData,
            showViewCasePopup: true,
            trasnferCaseInfo: response,
            transferId: transferId,
          });
        });
      } else {
        this.setState({
          caseInfo: caseData,
          showViewCasePopup: true,
          trasnferCaseInfo: {},
          transferId: null,
        });
      }
    });
  };

  onCloseViewCasePopup = () => {
    this.setState({ showViewCasePopup: false });
  };

  //------------------- View Case After Accepting -------------------

  navigateToCaseDetails = (caseID) => {
    this.unSubscribeFromMqttAlerts();
    this.props.setSelectedCaseId(caseID);

    history.push({
      pathname: `/case_details`,
      state: {
        caseId: caseID,
        page: this.state.page,
        limit: this.state.limit,
        filters: this.state.filters,
        adminsHospitalIds: this.state.adminsHospitalIds,
      },
    });
  };

  //------------------- Accept Case -------------------

  onAcceptCaseClick = (caseData, fromModal) => {
    this.setState({ caseInfo: caseData }, () => {
      this.callAcceptCaseAPI();
    });

    if (fromModal) {
      this.setState({ showViewCasePopup: false });
    }
  };

  callAcceptCaseAPI = () => {
    let caseData = this.state.caseInfo;
    let caseID = caseData?.id ?? null;

    let data = null;
    if (caseData?.isTransferredCase) {
      data = {
        transferId: caseData?.linkedTransferId,
        transferredToHospitalId: caseData?.linkedHospitalId,
      };
    } else {
      data = {
        hospitalId: caseData?.linkedHospitalId,
      };
    }

    this.props.acceptCase(caseID, data, (response) => {
      if (response) {
        this.unSubscribeFromMqttAlerts();
        this.props.setSelectedCaseId(caseID);

        setTimeout(() => {
          history.push({
            pathname: `/case_details`,
            state: {
              caseId: caseID,
              page: this.state.page,
              limit: this.state.limit,
              filters: this.state.filters,
              adminsHospitalIds: this.state.adminsHospitalIds,
            },
          });
        }, 300);
      }
    });
  };

  //------------------- Reject Case -------------------

  onRejectCaseClick = (caseData, fromModal) => {
    this.setState({ caseInfo: caseData, showRejectCasePopup: true });

    if (fromModal) {
      this.setState({ showViewCasePopup: false });
    }
  };

  callRejectCaseAPI = () => {
    let caseData = this.state.caseInfo;

    let data = null;
    if (caseData?.isTransferredCase) {
      data = {
        transferId: caseData?.linkedTransferId,
        transferredToHospitalId: caseData?.linkedHospitalId,
      };
    } else {
      data = {
        hospitalId: caseData?.linkedHospitalId,
      };
    }

    this.props.rejectPatientCase(caseData?.id, data, () => {
      this.setState({ showRejectCasePopup: false }, () => {
        this.callGetCaseListAPI();
      });
    });
  };

  onCloseRejectPopup = () => {
    this.setState({ showRejectCasePopup: false });
  };

  //------------------- Search / Pagination -------------------

  onSearchClick = (text) => {
    if (String(text).trim().length < 3) {
      this.props.showAlert({
        isOpen: true,
        title: "Attention",
        type: "warning",
        msg: "Mimimum 3 characters needed for search!",
      });
    } else {
      this.setState(
        { page: 1, filters: { ...this.state.filters, text } },
        () => {
          this.callGetCaseListAPI();
        }
      );
    }
  };

  onSearchTextCleared = () => {
    console.log("Search Cleared.....");
    this.setState(
      { page: 1, filters: { ...this.state.filters, text: "" } },
      () => {
        this.callGetCaseListAPI();
      }
    );
  };

  handlePerRowsChange = (newPerPage, page) => {
    var newRowsPerPage = newPerPage;
    var newPage = page;
    var total = this.state.totalAdmins;

    // Check if new (rowsPerPage * currentPageNumber) exceeds total count
    // If this happens we need to update currentPageNumber to LAST PAGE depending on newRowsPerPage
    var newRowAndPageCount = parseInt(newRowsPerPage) * parseInt(page);
    if (newRowAndPageCount > total) {
      newPage = parseInt(total) / parseInt(newRowsPerPage);
      newPage = Math.ceil(newPage);
    }

    this.setState(
      {
        limit: newPerPage,
        page: newPage,
        // clearSelectedRows: !this.state.clearSelectedRows,
        selectedIdsToDelete: [],
      },
      () => {
        this.callGetCaseListAPI();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        this.callGetCaseListAPI();
      }
    );
  };

  //---------------- filter and sorting --------------------

  onCaseListFilterClick = (column, filterOption) => {
    console.log("columnName", column, "filterOption", filterOption);
    let filter =
      filterOption === "Active" ? 1 : filterOption === "Closed" ? 2 : null;
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          caseStatus: parseInt(filter),
        },
      },
      () => {
        this.callGetCaseListAPI();
      }
    );
  };

  onCaseListSortClick = (column) => {
    let columnName = "";
    if (column === "Patient Name") {
      columnName = "firstName";
    } else if (column === "Case ID") {
      columnName = "caseId";
    } else if (column === "Technician Name") {
      columnName = "technicianName";
    } else if (column === "Device ID") {
      columnName = "deviceId";
    }
    this.setState(
      {
        sortBy: columnName,
        order: this.state.listOrder ? "desc" : "asc",
        listOrder: !this.state.listOrder,
      },
      () => {
        this.callGetCaseListAPI();
      }
    );
  };

  render() {
    return (
      <CasesView
        casesList={this.state.casesList}
        totalCases={this.state.totalCases}
        //
        page={this.state.page}
        limit={this.state.limit}
        onChangeRowsPerPage={this.handlePerRowsChange}
        onChangePage={this.handlePageChange}
        onSelectedRowsChange={this.handleSelectedRowsChange}
        clearSelectedRows={this.state.clearSelectedRows}
        selectedIdsToDelete={this.state.selectedIdsToDelete}
        //
        onAcceptCaseClick={this.onAcceptCaseClick}
        onCloseViewCasePopup={this.onCloseViewCasePopup}
        showViewCasePopup={this.state.showViewCasePopup}
        navigateToCaseDetails={this.navigateToCaseDetails}
        //
        caseInfo={this.state.caseInfo}
        trasnferCaseInfo={this.state.trasnferCaseInfo}
        transferId={this.state.transferId}
        onViewCaseClick={this.onViewCaseClick}
        //
        onCloseRejectPopup={this.onCloseRejectPopup}
        onRejectCaseClick={this.onRejectCaseClick}
        callRejectCaseAPI={this.callRejectCaseAPI}
        showRejectCasePopup={this.state.showRejectCasePopup}
        //
        onSearchClick={this.onSearchClick}
        onSearchTextCleared={this.onSearchTextCleared}
        //
        newRecords={this.newRecords}
        //
        onCaseListFilterClick={this.onCaseListFilterClick}
        onCaseListSortClick={this.onCaseListSortClick}
        //
        filters={this.state.filters}
      />
    );
  }
}

CasesContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    CaseList: state.stemiCase.CaseList,
    filteredCaseList: state.stemiCase.filteredCaseList,

    notifications: state.mqtt.newNotifications,
    notificationDeletedTimestamp: state.mqtt.notificationDeletedTimestamp,
    homeScreenRefreshed: state.mqtt.homeScreenRefreshed,
  };
};
export default connect(mapStateToProps, {
  getCaseListWithFilter,
  getCaseDetailsById,
  rejectPatientCase,
  getHospitalAdminsDataFromId,
  showAlert,
  acceptCase,
  getTransferredDetailsById,
  saveNotification,
  refreshHomeScreen,
  resetRefreshHomeScreen,
  showLoader,
  hideLoader,
  setSelectedCaseId,
})(CasesContainer);
