import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import {
  resetSelectedHospital,
  getDoctorsListbyId,
} from "../../../../../store/hospital/hospitalActions";
import { getCenterListWithFilter } from "../../../../../store/center/centerActions";
import { history } from "../../../../../store/configure/configureStore";
import CentersView from "./CentersView";

class CentersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      selectedHospital: this.props.selectedHospital,

      // Accordian Data
      centersList: [],
      devicesList: [],
      doctorsList: [],

      // Search filters
      filters: {
        text: "",
      },
      toggleValue: "centers",
      toggleData: [
        {
          id: "centers",
          name: "Centers",
        },
        {
          id: "doctors",
          name: "Doctors",
        },
      ],

      // previous page's state
      prevState: {},
    };

    this.onHospitalNameBackClick = this.onHospitalNameBackClick.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    var hospitalObject = this.props.selectedHospital;
    var previousState = this.props.location?.state ?? {};
    console.log("Centers previousState ::: ", JSON.stringify(previousState));

    this.setState({
      selectedHospital: hospitalObject,
      prevState: previousState,
    });
    this.props.getDoctorsListbyId({ hospitalId: hospitalObject.id }, (list) => {
      this.setState({
        doctorsList: list,
      });
    });

    // Fetch centers list and save it in state variable
    this.props.getCenterListWithFilter(
      { hospitalId: hospitalObject.id },

      (listData) => {
        // console.log("CENTERS LIST : ", JSON.stringify(listData));

        var newList = [];
        listData.map((item) => {
          var deviceList = Array.isArray(item.deviceList)
            ? item.deviceList
            : [];

          // console.log("CENTERS > DEVICES : ", JSON.stringify(deviceList));

          newList.push({
            id: item.id,
            title: item.centerName ?? "",
            devicesList: deviceList,
            deviceCount: parseInt(deviceList.length),
          });
        });

        this.setState({
          centersList: newList,
        });
      }
    );
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {}

  //----------------------------- Hospital Selection ---------------------------

  onHospitalNameBackClick = () => {
    // Reset selected Hospital in state vaiable and redux
    this.props.resetSelectedHospital();

    // Send previous page's state for updating pagination on that screen
    history.push({
      pathname: "/hospitals",
      state: this.state.prevState,
    });
  };

  onToggleChange = (toggleVal) => {
    console.log(toggleVal);
    this.setState({ toggleValue: toggleVal });
  };

  onCenterSelected = (index) => {
    /* ------ This function isn NOT Used anymore..-------

    var selectedCentersData = null;

    var centerList = this.state.centersList;
    for (let i = 0; i < centerList.length; i++) {
      if (i == index) {
        selectedCentersData = centerList[i];
      }
    }

    // console.log(
    //   "HOSPITAL : Selected Center : ",
    //   JSON.stringify(selectedCentersData)
    // );

    // Find devices and doctors from center data
    var devices = [];
    var doctors = [];

    if (selectedCentersData !== null) {
      devices = Array.isArray(selectedCentersData.deviceList)
        ? selectedCentersData.deviceList
        : [];
      doctors = Array.isArray(selectedCentersData.doctorsList)
        ? selectedCentersData.doctorsList
        : [];

      // console.log("HOSPITAL : Devicelist : ", JSON.stringify(devices));

      // Now update devices and doctors list
      this.setState({
        devicesList: devices,
        doctorsList: doctors,
      });
    }
    */
  };

  render() {
    return (
      <CentersView
        onHospitalNameBackClick={this.onHospitalNameBackClick}
        selectedHospital={this.state.selectedHospital}
        //------------------ Accordian -----------------
        centersList={this.state.centersList}
        doctorsList={this.state.doctorsList}
        //
        toggleData={this.state.toggleData}
        toggleValue={this.state.toggleValue}
        onToggleChange={this.onToggleChange}
      />
    );
  }
}

CentersContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    selectedHospital: state.hospital.selectedHospital,
  };
};

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
  resetSelectedHospital,
  getCenterListWithFilter,
  getDoctorsListbyId,
})(CentersContainer);
