import React from "react";

import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import {
  FaHospital,
  FaUserTie,
  FaUserDoctor,
  FaBuilding,
} from "react-icons/fa6";
import { TbDeviceHeartMonitorFilled } from "react-icons/tb";
import { AiFillDashboard } from "react-icons/ai";
import { BiSolidReport } from "react-icons/bi";
import { FaSignOutAlt } from "react-icons/fa";
import logo from "../../../assets/images/Logo.png";

// BsClipboard
import "./SidebarStyles.scss";
import {
  HOME,
  CHANGE_PASSWORD,
  HOSPITALS_LIST,
  DEVICES_LIST,
  TECHNICIANS_LIST,
  DOCTORS_LIST,
  REPORTS_LIST,
  CENTER_LIST,
} from "../../../navigation/routes";
import { MdClose } from "react-icons/md";
import { logout } from "../../../store/auth/authActions";

const Sidebar = ({ logout, isOpen, setIsOpen, loginUser }) => {
  let userRoles = [];
  if (loginUser && loginUser.data) {
    userRoles = loginUser.data.userRoles || [];
  }

  const isTechnician = userRoles.some((role) => role.name === "Technician");
  const isDoctor = userRoles.some((role) => role.name === "Doctor");

  const onLogOut = () => {
    logout();
  };

  const superAdminNavLinks = (
    <span>
      <NavLink className="nav-link mt-1" exact to={HOME}>
        <span className="side_nav_content">
          <AiFillDashboard className="me-1" /> Dashboard
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={HOSPITALS_LIST}>
        <span className="side_nav_content">
          <FaHospital className="me-1" /> Hospitals
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={DEVICES_LIST}>
        <span className="side_nav_content">
          <TbDeviceHeartMonitorFilled className="me-1" /> Devices
        </span>
      </NavLink>
      {/* < NavLink className="nav-link mt-1" exact to={CENTER_LIST}>
        <span className="side_nav_content"><FaBuilding className="me-1" /> Centers</span>
      </NavLink> */}
    </span>
  );

  const hospitalAdminNavLinks = (
    <span>
      <NavLink className="nav-link mt-1" exact to={HOME}>
        <span className="side_nav_content">
          <AiFillDashboard className="me-1" /> Dashboard
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={HOSPITALS_LIST}>
        <span className="side_nav_content">
          <FaHospital className="me-1" /> Hospitals
        </span>
      </NavLink>
      {/* <NavLink className="nav-link mt-1" exact to={DOCTORS_LIST}>
        <span className="side_nav_content">
          <FaUserDoctor className="me-1" /> Doctors
        </span>
      </NavLink> */}
      <NavLink className="nav-link mt-1" exact to={REPORTS_LIST}>
        <span className="side_nav_content">
          <BiSolidReport className="me-1" /> Reports
        </span>
      </NavLink>
      <NavLink className="nav-link mt-1" exact to={CENTER_LIST}>
        <span className="side_nav_content">
          <FaBuilding className="me-1" /> Centers
        </span>
      </NavLink>
    </span>
  );

  const reportingHospitalAdminNavLinks = (
    <span>
      <NavLink className="nav-link mt-1" exact to={HOME}>
        <span className="side_nav_content">
          <AiFillDashboard className="me-1" /> Dashboard
        </span>
      </NavLink>

      <NavLink className="nav-link mt-1" exact to={DOCTORS_LIST}>
        <span className="side_nav_content">
          <FaUserDoctor className="me-1" /> Doctors
        </span>
      </NavLink>

      <NavLink className="nav-link mt-1" exact to={REPORTS_LIST}>
        <span className="side_nav_content">
          <BiSolidReport className="me-1" /> Reports
        </span>
      </NavLink>
    </span>
  );

  const centerAdminNavLinks = (
    <span>
      <NavLink className="nav-link mt-1" exact to={HOME}>
        <span className="side_nav_content">
          <AiFillDashboard className="me-1" /> Dashboard
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={DEVICES_LIST}>
        <span className="side_nav_content">
          <TbDeviceHeartMonitorFilled className="me-1" /> Devices
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={TECHNICIANS_LIST}>
        <span className="side_nav_content">
          <FaUserTie className="me-1" /> Technicians
        </span>
      </NavLink>
      <NavLink className="nav-link" exact to={REPORTS_LIST}>
        <span className="side_nav_content">
          <BiSolidReport className="me-1" /> Reports
        </span>
      </NavLink>
    </span>
  );

  const technicianNavLinks = (
    <span>
      <NavLink className="nav-link" exact to={REPORTS_LIST}>
        <span className="side_nav_content">
          <BiSolidReport className="me-1" /> Reports
        </span>
      </NavLink>
    </span>
  );

  return (
    <>
      {!isTechnician && !isDoctor && (
        <div className="sidenav_wrapper_box">
          <Container>
            <Navbar className="sidenav_wrapper_box_navbar">
              <Nav className="flex-column">
                <div className="text-center">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      height: "50px",
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                {/* <span>
                < NavLink className="nav-link mt-1" exact to={HOME}>
                  <span className="side_nav_content"><AiFillDashboard className="me-1" /> Dashboard</span>
                </NavLink>
                <NavLink className="nav-link" exact to={HOSPITALS_LIST}>
                  <span className="side_nav_content"><FaHospital className="me-1" /> Hospitals</span>
                </NavLink>
                <NavLink className="nav-link" exact to={DEVICES_LIST}>
                  <span className="side_nav_content"><TbDeviceHeartMonitorFilled className="me-1" /> Devices</span>
                </NavLink>
                <NavLink className="nav-link" exact to={TECHNICIANS_LIST}>
                  <span className="side_nav_content"><FaUserTie className="me-1" /> Technicians</span>
                </NavLink> </span> */}

                {userRoles.some((role) => role.name === "Super Admin")
                  ? superAdminNavLinks
                  : null}

                {userRoles.some((role) => role.name === "Hospital Admin")
                  ? loginUser?.data?.id == "363"
                    ? reportingHospitalAdminNavLinks
                    : hospitalAdminNavLinks
                  : null}

                {userRoles.some((role) => role.name === "Center Admin")
                  ? centerAdminNavLinks
                  : null}

                {userRoles.some((role) => role.name === "Technician")
                  ? technicianNavLinks
                  : null}

                {/* <NavLink className="nav-link" exact to={CHANGE_PASSWORD}>
                <span className="side_nav_content">Change Password</span>
              </NavLink> */}
                <p className="nav-link mt-1" onClick={onLogOut}>
                  <span
                    className="side_nav_content"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    <FaSignOutAlt className="me-1" />
                    Logout
                  </span>
                </p>
                <div className="other-nav">
                  <NavLink className="nav-link mt-3" exact to={HOME}>
                    <span className="side_nav_content">Features</span>
                  </NavLink>
                  <NavLink className="nav-link mt-3" exact to={HOME}>
                    <span className="side_nav_content">Pricing</span>
                  </NavLink>
                  <NavLink className="nav-link mt-3" exact to={HOME}>
                    <span className="side_nav_content">About</span>
                  </NavLink>
                </div>
              </Nav>
            </Navbar>
          </Container>
        </div>
      )}
    </>
  );
};

Sidebar.propTypes = {
  //logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loginUser: state.auth.loginUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout: logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
