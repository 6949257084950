import { roles } from "../../constants/roles";
import { getState } from "../configure/configureStore";
import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./deviceReducer";

export function getDeviceListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DEVICE_LIST_WITH_FILTER_PENDING,
      payload: {
        getDeviceListWithFilterPending: false,
        getDeviceListWithFilterSuccess: false,
        getDeviceListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `device/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.totalPages ?? 0);
        }

        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_DEVICE_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getDeviceListWithFilterPending: false,
              getDeviceListWithFilterSuccess: true,
              getDeviceListWithFilterError: null,
              deviceList: response.data.rows,
              filteredDeviceList: response?.data?.rows ?? [],
              filteredDeviceCount: response?.data?.count ?? 0,
              // filterdDeviceList: [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_DEVICE_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getDeviceListWithFilterPending: false,
              getDeviceListWithFilterSuccess: true,
              getDeviceListWithFilterError: null,
              filteredDeviceList: response?.data?.rows ?? [],
              filteredDeviceCount: response?.data?.count ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_DEVICE_LIST_WITH_FILTER_ERROR,
          payload: {
            getDeviceListWithFilterPending: false,
            getDeviceListWithFilterSuccess: true,
            getDeviceListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getSeverityListForErrorLog(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SEVERITY_LIST_FOR_DEVICE_PENDING,
      payload: {
        getSeverityListForErrorLogPending: false,
        getSeverityListForErrorLogSuccess: false,
        getSeverityListForErrorLogError: null,
      },
    });
    dispatch(showLoader());

    Api.get("device/errorLog/severity/list")
      .then((response) => {
        console.log(response, "severity");
        dispatch(hideLoader());

        if (typeof callback === "function") {
          const reportSeverityListData = Array.isArray(response?.data)
            ? response.data.map((item) => {
                return { value: item.id, label: item.name };
              })
            : [];

          callback(reportSeverityListData);
        }

        if (!response?.data) {
          dispatch({
            type: actionTypes.GET_SEVERITY_LIST_FOR_DEVICE_ERROR,
            payload: {
              getSeverityListForErrorLogPending: false,
              getSeverityListForErrorLogSuccess: false,
              getSeverityListForErrorLogError: "",
              reportSeverityList: [],
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_SEVERITY_LIST_FOR_DEVICE_SUCCESS,
            payload: {
              getSeverityListForErrorLogPending: false,
              getSeverityListForErrorLogSuccess: true,
              getSeverityListForErrorLogError: null,
              reportSeverityList: response?.data ?? [],
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_SEVERITY_LIST_FOR_DEVICE_ERROR,
          payload: {
            getSeverityListForErrorLogPending: false,
            getSeverityListForErrorLogSuccess: true,
            getSeverityListForErrorLogError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getDeviceDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DEVICE_DETAILS_BY_ID_PENDING,
      payload: {
        getDeviceDetailsByIdPending: false,
        getDeviceDetailsByIdSuccess: false,
        getDeviceDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`device/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_DEVICE_DETAILS_BY_ID_SUCCESS,
          payload: {
            getDeviceDetailsByIdPending: false,
            getDeviceDetailsByIdSuccess: true,
            getDeviceDetailsByIdError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.GET_DEVICE_DETAILS_BY_ID_ERROR,
          payload: {
            getDeviceDetailsByIdPending: false,
            getDeviceDetailsByIdSuccess: true,
            getDeviceDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getDeviceTypeList(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DEVICE_TYPE_LIST_PENDING,
      payload: {
        getDeviceTypeListPending: false,
        getDeviceTypeListSuccess: false,
        getDeviceTypeListError: null,
      },
    });
    dispatch(showLoader());

    Api.get("device/type/list")
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_DEVICE_TYPE_LIST_SUCCESS,
          payload: {
            getDeviceTypeListPending: false,
            getDeviceTypeListSuccess: true,
            getDeviceTypeListError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_DEVICE_TYPE_LIST_ERROR,
          payload: {
            getDeviceTypeListPending: false,
            getDeviceTypeListSuccess: true,
            getDeviceTypeListError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function addDevice(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_DEVICE_PENDING,
      payload: {
        addDevicePending: false,
        addDeviceSuccess: false,
        addDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.post("device/add", data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_DEVICE_SUCCESS,
          payload: {
            addDevicePending: false,
            addDeviceSuccess: true,
            addDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_DEVICE_ERROR,
          payload: {
            addDevicePending: false,
            addDeviceSuccess: true,
            addDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//--------------------------------------------------- add device in Bulk ---------------------------------------

export function addDeviceInBulk(data, hospitalId, callback) {
  return (dispatch) => {
    var formdata = new FormData();
    console.log("file", data);
    formdata.append("file", data);
    if (hospitalId) {
      formdata.append("hospitalId", hospitalId);
    }
    dispatch({
      type: actionTypes.ADD_DEVICES_IN_BULK_PENDING,
      payload: {
        addDevicesInBulkPending: false,
        addDevicesInBulkSuccess: false,
        addDevicesInBulkError: null,
      },
    });
    dispatch(showLoader());
    Api.uploadFile("device/bulkInsert", null, formdata)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_DEVICES_IN_BULK_SUCCESS,
          payload: {
            addDevicesInBulkPending: false,
            addDevicesInBulkSuccess: true,
            addDevicesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_DEVICES_IN_BULK_ERROR,
          payload: {
            addDevicesInBulkPending: false,
            addDevicesInBulkSuccess: false,
            addDevicesInBulkError: err,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//----------------------- update Device -------------------------

export function editDevice(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_DEVICE_PENDING,
      payload: {
        editDevicePending: false,
        editDeviceSuccess: false,
        editDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.put(`device/update/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_DEVICE_SUCCESS,
          payload: {
            editDevicePending: false,
            editDeviceSuccess: true,
            editDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.EDIT_DEVICE_ERROR,
          payload: {
            editDevicePending: false,
            editDeviceSuccess: true,
            editDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------------------------- delete device ------------------------------

export function deleteDevice(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DEVICE_PENDING,
      payload: {
        deleteDevicePending: false,
        deleteDeviceSuccess: false,
        deleteDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`device/delete/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DEVICE_SUCCESS,
          payload: {
            deleteDevicePending: false,
            deleteDeviceSuccess: true,
            deleteDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DEVICE_ERROR,
          payload: {
            deleteDevicePending: false,
            deleteDeviceSuccess: true,
            deleteDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------------------------- delete device In Bilk ------------------------------

export function deleteDeviceInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DEVICES_IN_BULK_PENDING,
      payload: {
        deleteDevicesInBulkPending: false,
        deleteDevicesInBulkSuccess: false,
        deleteDevicesInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`device/delete/multiple/devices`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DEVICES_IN_BULK_SUCCESS,
          payload: {
            deleteDevicesInBulkPending: false,
            deleteDevicesInBulkSuccess: true,
            deleteDevicesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_DEVICES_IN_BULK_ERROR,
          payload: {
            deleteDevicesInBulkPending: false,
            deleteDevicesInBulkSuccess: true,
            deleteDevicesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function checkInDevice(data, callback) {
  var userRole = getState().auth.loginUser?.data?.userRoles[0]?.name ?? null;
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHECKIN_DEVICE_PENDING,
      payload: {
        checkInDevicePending: false,
        checkInDeviceSuccess: false,
        checkInDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.post("device/checkin", data)
      .then((response) => {
        if (typeof callback === "function") {
          callback(response.data);
        }
        console.log(response);
        dispatch(hideLoader());

        dispatch({
          type: actionTypes.CHECKIN_DEVICE_SUCCESS,
          payload: {
            checkInDevicePending: false,
            checkInDeviceSuccess: true,
            checkInDeviceError: null,
            selectedCheckInDevice: response.data,
          },
        });
        if (roles.superAdmin !== userRole) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Success",
              type: "Success",
              msg: response.message,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (typeof callback === "function") {
          callback(null);
        }
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.CHECKIN_DEVICE_ERROR,
          payload: {
            checkInDevicePending: false,
            checkInDeviceSuccess: true,
            checkInDeviceError: null,
          },
        });
        if (roles.superAdmin !== userRole) {
          dispatch(
            showAlert({
              isOpen: true,
              title: "Error",
              type: "danger",
              msg: err,
            })
          );
        }
      });
  };
}

//------------------------------ remove technician  -------------------

export function removeTechnicianForDevice(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_PENDING,
      payload: {
        removeTechnicianForDevicePending: false,
        removeTechnicianForDeviceSuccess: false,
        removeTechnicianForDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.delete("device/remove/technician", data)
      .then((response) => {
        if (typeof callback === "function") {
          callback();
        }
        console.log(response);
        dispatch(hideLoader());

        dispatch({
          type: actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_SUCCESS,
          payload: {
            removeTechnicianForDevicePending: false,
            removeTechnicianForDeviceSuccess: true,
            removeTechnicianForDeviceError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        dispatch({
          type: actionTypes.REMOVE_TECHNICIAN_FOR_DEVICE_ERROR,
          payload: {
            removeTechnicianForDevicePending: false,
            removeTechnicianForDeviceSuccess: true,
            removeTechnicianForDeviceError: null,
          },
        });
      });
  };
}

export function resetDeviceData() {
  console.log("Device Data reset..");
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_DEVICE_DATA,
      payload: {
        deviceList: [],
        filteredDeviceList: [],
        selectedCheckInDevice: null,
        filteredDeviceCount: 0,
      },
    });
  };
}
export function ReportDeviceSubmission(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REPORT_DEVICE_PENDING,
      payload: {
        reportDevicePending: false,
        reportDeviceSuccess: false,
        reportDeviceError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`device/reportDevice`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REPORT_DEVICE_SUCCESS,
          payload: {
            reportDevicePending: false,
            reportDeviceSuccess: true,
            reportDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.REPORT_DEVICE_ERROR,
          payload: {
            reportDevicePending: false,
            reportDeviceSuccess: true,
            reportDeviceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
