import { getState, getStore } from "../../store/configure/configureStore";
import { resetUserLoginDetails } from "../../store/auth/authActions";
import { showAlert } from "../../store/alert/alertActions";
import { globals } from "../../store/globals";
import moment from "moment";

// import decode from "jwt-decode";

// class Auth {
//   constructor() {
//     this.authenticated = true;
//   }

//   isAuthenticated() {
//   if (!globals.store.getState().auth.token) {
//   this.authenticated = false;
// } else {
//   let decodedData = decode(
//     JSON.parse(window.localStorage.getItem("token"))
//   );
//   let current_time = Date.now() / 1000;
//   if (decodedData.exp - 3600 < current_time) {
//     this.authenticated = false;
//   } else {
//     this.authenticated = true;
//   }
// }

//   }
// }

// export default new Auth();

class Auth {
  constructor() {
    this.authenticated = true;
  }

  isAuthenticated() {
    // console.log("IS AUTHENTICATED ::: ", getState().auth.loginUser);

    if (getState().auth.accessToken) {
      var isTokenExpired = false;

      var tokenExpiryDateTime =
        getState().auth.loginUser?.accessTokenExpiryTime ?? null;

      var tokenExpiryString =
        tokenExpiryDateTime !== null
          ? moment
              .unix(parseInt(tokenExpiryDateTime))
              .format("DD MMM YYYY hh:mm a")
          : "";

      if (
        tokenExpiryString !== "" &&
        moment(tokenExpiryString, "DD MMM YYYY hh:mm a").isSameOrBefore(
          moment().format("DD MMM YYYY hh:mm a")
        )
      ) {
        isTokenExpired = true;
      }

      // console.log("IS AUTHENTICATED :: tokenExpiry : ", tokenExpiryString);
      // console.log("IS AUTHENTICATED :: isTokenExpired : ", isTokenExpired);

      // Check if token is there but it is expired...
      if (isTokenExpired) {
       // getStore().dispatch(
       //   showAlert({
       //     isOpen: true,
       //     title: "Session Expired",
       //     type: "danger",
       //     msg: "Your session has expired.",
       //   })
       // );

       // getStore().dispatch(resetUserLoginDetails());
        this.authenticated = false;
      } else {
        this.authenticated = true;
      }
    } else {
      // If NO token is there....
      this.authenticated = false;
    }

    return this.authenticated;
  }
}

export default new Auth();
