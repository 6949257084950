export const actionTypes = {
  GET_AMBULANCE_LIST_WITH_FILTER_PENDING:
    "GET_AMBULANCE_LIST_WITH_FILTER_PENDING",
  GET_AMBULANCE_LIST_WITH_FILTER_SUCCESS:
    "GET_AMBULANCE_LIST_WITH_FILTER_SUCCESS",
  GET_AMBULANCE_LIST_WITH_FILTER_ERROR: "GET_AMBULANCE_LIST_WITH_FILTER_ERROR",

  GET_AMBULANCE_DETAILS_BY_ID_PENDING: "GET_AMBULANCE_DETAILS_BY_ID_PENDING",
  GET_AMBULANCE_DETAILS_BY_ID_SUCCESS: "GET_AMBULANCE_DETAILS_BY_ID_SUCCESS",
  GET_AMBULANCE_DETAILS_BY_ID_ERROR: "GET_AMBULANCE_DETAILS_BY_ID_ERROR",

  ADD_AMBULANCES_IN_BULK_PENDING: "ADD_AMBULANCES_IN_BULK_PENDING",
  ADD_AMBULANCES_IN_BULK_SUCCESS: "ADD_AMBULANCES_IN_BULK_SUCCESS",
  ADD_AMBULANCES_IN_BULK_ERROR: "ADD_AMBULANCES_IN_BULK_ERROR",

  ADD_AMBULANCE_PENDING: "ADD_AMBULANCE_PENDING",
  ADD_AMBULANCE_SUCCESS: "ADD_AMBULANCE_SUCCESS",
  ADD_AMBULANCE_ERROR: "ADD_AMBULANCE_ERROR",

  EDIT_AMBULANCE_PENDING: "EDIT_AMBULANCE_PENDING",
  EDIT_AMBULANCE_SUCCESS: "EDIT_AMBULANCE_SUCCESS",
  EDIT_AMBULANCE_ERROR: "EDIT_AMBULANCE_ERROR",

  DELETE_AMBULANCE_PENDING: "DELETE_AMBULANCE_PENDING",
  DELETE_AMBULANCE_SUCCESS: "DELETE_AMBULANCE_SUCCESS",
  DELETE_AMBULANCE_ERROR: "DELETE_AMBULANCE_ERROR",

  DELETE_AMBULANCES_IN_BULK_PENDING: "DELETE_AMBULANCES_IN_BULK_PENDING",
  DELETE_AMBULANCES_IN_BULK_SUCCESS: "DELETE_AMBULANCES_IN_BULK_SUCCESS",
  DELETE_AMBULANCES_IN_BULK_ERROR: "DELETE_AMBULANCES_IN_BULK_ERROR",
};

const initialState = {
  getAmbulanceListWithFilterPending: false,
  getAmbulanceListWithFilterSuccess: false,
  getAmbulanceListWithFilterError: null,

  getAmbulanceDetailsByIdPending: false,
  getAmbulanceDetailsByIdSuccess: false,
  getAmbulanceDetailsByIdError: null,

  addAmbulancesInBulkPending: false,
  addAmbulancesInBulkSuccess: false,
  addAmbulancesInBulkError: null,

  addAmbulancePending: false,
  addAmbulanceSuccess: false,
  addAmbulanceError: null,

  editAmbulancePending: false,
  editAmbulanceSuccess: false,
  editAmbulanceError: null,

  deleteAmbulancePending: false,
  deleteAmbulanceSuccess: false,
  deleteAmbulanceError: null,

  deleteAmbulancesInBulkPending: false,
  deleteAmbulancesInBulkSuccess: false,
  deleteAmbulancesInBulkError: null,

  ambulanceList: [],
  filteredAmbulanceList: [],
  reportSeverityList: [],
  selectedCheckInAmbulance: null,
  filteredAmbulanceCount: 0,
};

export default function ambulance(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_PENDING:
    case actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_SUCCESS:
    case actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.GET_AMBULANCE_DETAILS_BY_ID_PENDING:
    case actionTypes.GET_AMBULANCE_DETAILS_BY_ID_SUCCESS:
    case actionTypes.GET_AMBULANCE_DETAILS_BY_ID_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_AMBULANCES_IN_BULK_PENDING:
    case actionTypes.ADD_AMBULANCES_IN_BULK_SUCCESS:
    case actionTypes.ADD_AMBULANCES_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.ADD_AMBULANCE_PENDING:
    case actionTypes.ADD_AMBULANCE_SUCCESS:
    case actionTypes.ADD_AMBULANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.EDIT_AMBULANCE_PENDING:
    case actionTypes.EDIT_AMBULANCE_SUCCESS:
    case actionTypes.EDIT_AMBULANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_AMBULANCE_PENDING:
    case actionTypes.DELETE_AMBULANCE_SUCCESS:
    case actionTypes.DELETE_AMBULANCE_ERROR:
      return Object.assign({}, state, payload);

    case actionTypes.DELETE_AMBULANCES_IN_BULK_PENDING:
    case actionTypes.DELETE_AMBULANCES_IN_BULK_SUCCESS:
    case actionTypes.DELETE_AMBULANCES_IN_BULK_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
