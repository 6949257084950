import React, { useEffect } from "react";
import "./TogglebuttonGroupStyles.scss";

const TogglebuttonGroup = ({
  selectedItemId,
  onItemSelected,
  noOutline = false,
  customToggleData = null,
  smallSize = false,
}) => {
  var defaultDateFilters = [
    {
      id: "today",
      name: "Today",
    },
    {
      id: "week",
      name: "This Week",
    },
    {
      id: "month",
      name: "This Month",
    },
    {
      id: "year",
      name: "This Year",
    },
  ];

  // If customToggleData is not passed, then use default date filters.
  const toggleData =
    customToggleData === null ? defaultDateFilters : customToggleData;

  // Handle selected toggle option via this internal state function
  const [selectedId, setSelectedId] = React.useState(selectedItemId ?? null);

  // Keep checking if selectedItemId === null is passed.
  // This is passed when some other filter is in use, in which case none of toggle options should be selected.
  // Also, Inherently we handle selcted Id in this component, but if it is passed from parent, then that should take preference.
  useEffect(() => {
    // console.log("Toggle Button UseEffect : selectedItemId :: ", selectedItemId);
    if (selectedItemId === null) {
      setSelectedId(null);
    } else if (selectedItemId !== selectedId) {
      setSelectedId(selectedItemId);
    }
  }, [selectedItemId]);

  // Function to update state
  const handleChange = (event, selectedId) => {
    // console.log("event " + event.target.value);
    console.log("Toggle Button selectedId " + selectedId);
    setSelectedId(selectedId);
    onItemSelected(selectedId);
  };

  return (
    <div
      className={`toggle-button-group ${smallSize ? "small-size" : ""} ${
        noOutline ? "plain" : ""
      } `}
    >
      {toggleData.map((item, i) => (
        <div
          className={`toggle-button-item ${smallSize ? "small-size" : ""} ${
            noOutline ? "plain-item" : ""
          } ${toggleData.length - 1 === i ? "last" : ""}
          ${i === 0 ? "first" : ""} ${
            selectedId === toggleData[i].id
              ? noOutline
                ? "selected plain"
                : "selected"
              : ""
          }`}
          onClick={(e) => handleChange(e, toggleData[i].id)}
        >
          <text>{toggleData[i].name}</text>
        </div>
      ))}
    </div>
  );
};

//   <div className={`toggle-button-group ${noOutline ? "plain" : ""} `}>
//     <div
//       className={`toggle-button-item first ${noOutline ? "plain-item" : ""} ${
//         selectedId === toggleData[0].id
//           ? noOutline
//             ? "selected plain"
//             : "selected"
//           : ""
//       }`}
//       onClick={(e) => handleChange(e, toggleData[0].id)}
//     >
//       <text>{toggleData[0].name}</text>
//     </div>

//     <div
//       className={`toggle-button-item  ${noOutline ? "plain-item" : ""} ${
//         selectedId === toggleData[1].id
//           ? noOutline
//             ? "selected plain"
//             : "selected"
//           : ""
//       }`}
//       onClick={(e) => handleChange(e, toggleData[1].id)}
//     >
//       <text>{toggleData[1].name}</text>
//     </div>

//     <div
//       className={`toggle-button-item  ${noOutline ? "plain-item" : ""} ${
//         selectedId === toggleData[2].id
//           ? noOutline
//             ? "selected plain"
//             : "selected"
//           : ""
//       }`}
//       onClick={(e) => handleChange(e, toggleData[2].id)}
//     >
//       <text>{toggleData[2].name}</text>
//     </div>

//     <div
//       className={`toggle-button-item last ${noOutline ? "plain-item" : ""} ${
//         selectedId === toggleData[3].id
//           ? noOutline
//             ? "selected plain"
//             : "selected"
//           : ""
//       }`}
//       onClick={(e) => handleChange(e, toggleData[3].id)}
//     >
//       <text>{toggleData[3].name}</text>
//     </div>
//   </div>

TogglebuttonGroup.propTypes = {};

export default TogglebuttonGroup;
