import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./OtherComponentStyles.scss";

const AreaChartView = ({ xAxisLabel, yAxisLabel, dataSet }) => {
  // console.log("data", dataSet);

  const CustomTooltip = ({ active, payload, label, test }) => {
    if (active && payload && payload.length) {
      //TODO - Set x axis
      return (
        <div className="area-chart-tooltip">
          {dataSet.length == 12 ? (
            <text>{`Month : ${payload[0].payload.hoverName}`}</text>
          ) : (
            <text>{`Date : ${payload[0].payload.hoverName}`}</text>
          )}
          <text>{`Count : ${payload[0].value}`}</text>
          {/* <text>{`${label} : ${payload[0].value}`}</text> */}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={280}>
      <AreaChart
        data={dataSet}
        margin={{ top: 10, right: 0, left: 0, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="40%" stopColor="rgba(255, 133, 192, 0.6)" />
            <stop offset="95%" stopColor="rgba(250, 250, 250, 0.6)" />
          </linearGradient>
        </defs>
        <CartesianGrid
          vertical={false}
          stroke="rgba(0,0,0,0.1)"
          strokeDasharray="1 1"
          // strokeDasharray="0"
        />
        <XAxis
          dataKey="name"
          label={{
            value: xAxisLabel,
            offset: -10,
            position: "insideBottom",
          }}
        />
        <YAxis
          label={{
            value: yAxisLabel,
            angle: -90,
            offset: 10,
            position: "insideLeft",
            style: { textAnchor: "middle" },
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="linear"
          dataKey="Value"
          stroke="none"
          fill="url(#colorUv)"
          dot={{
            fill: "#FFADD2",
            stroke: "#FFADD2",
            strokeWidth: 1,
            radius: 1,
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartView;
