import React from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "../../../../common";
import { FaSlash, FaSort } from "react-icons/fa6";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import "./ListStyles.scss";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  AiFillFilter,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineRedo,
} from "react-icons/ai";

const SupervisorList = (props) => {
  var unLockFlag = true;
  const data = props.data;

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onSupervisorListSortClick !== null
                  ? () => props.onSupervisorListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = true, center = false) => {
    return (
      <div className={`custom-column-header ${center ? "header-text" : ""}`}>
        <text>{label}</text>
        <div className="sortIcon">
          <div className={`partition ${islastColumn === true ? "none" : ""}`} />
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label) => {
    const Active = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const Inactive = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#D9D9D9",
    };
    const Occupied = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#1890FF",
    };
    const Options = [
      { label: "Active", icon: <div style={Active} /> },
      { label: "Inactive", icon: <div style={Inactive} /> },
      { label: "Occupied", icon: <div style={Occupied} /> },
    ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              columnName={label}
              showSelectedItem={true}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onSupervisorListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderEditDeleteResetPasswordAction = (row) => {
    const Options = [
      { label: "Edit", icon: <AiOutlineEdit size={18} /> },
      { label: "Delete", icon: <AiOutlineDelete size={18} /> },
      { label: "Reset Password", icon: <AiOutlineRedo size={18} /> },
    ];
    return (
      <IconMenuOption
        row={row}
        options={Options}
        menuIcon={<BsThreeDotsVertical size={22} />}
        onclickEdit={props.onEditSupervisorClick}
        onclickDelete={props.onDeleteSupervisorClick}
        onclickResetPassword={props.onResetPasswordSupervisorClick}
      />
    );
  };

  const renderSupervisorStatus = (status) => {
    const Active = { backgroundColor: "#00B48A" };
    const Inactive = { backgroundColor: "#D9D9D9" };
    const Occupied = { backgroundColor: "#1890FF" };
    const Locked = { backgroundColor: "#FF4D4F" };

    let statusStyle;
    if (status === "Active") {
      statusStyle = Active;
    }
    if (status === "Inactive") {
      statusStyle = Inactive;
    }
    if (status === "Occupied") {
      statusStyle = Occupied;
    }
    if (status === "Locked") {
      statusStyle = Locked;
    }

    return (
      <div className="status-wrapper">
        <div className="status-circle me-2" style={statusStyle}></div>
        <text>{status}</text>
      </div>
    );
  };

  const renderUnlockButton = (row) => {
    return (
      <div>
        <ThemedButton
          title="Unlock"
          styles="link "
          onClick={
            props.onUnlockSupervisorAccountClick !== null
              ? () => props.onUnlockSupervisorAccountClick(row)
              : () => {}
          }
        />
      </div>
    );
  };
  const renderSupervisorName = (row, isNew) => {
    return (
      <div className="supervisor-name-wrapper">
        <text className="me-3">
          {(row?.firstName !== undefined && row?.firstName !== null
            ? `Dr. ${row.firstName
                .charAt(0)
                .toUpperCase()}${row.firstName.slice(1)}`
            : "") +
            " " +
            (row?.lastName !== undefined && row?.lastName !== null
              ? `${row.lastName.charAt(0).toUpperCase()}${row.lastName.slice(
                  1
                )}`
              : "")}
        </text>
        {/* {isNew ? <div className="new-tag">New</div> : null} */}
      </div>
    );
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // Columns info with row value to use (row : single object in data array)
  const columns = [
    {
      name: renderHeader("Supervisor Name", false, true),
      id: "supervisor_name",
      center: false,
      sortable: true,
      selector: (row) => renderSupervisorName(row, true),
    },
    {
      name: renderHeader("Qualification", false, false),
      id: "qualification",
      center: false,
      sortable: true,
      selector: (row) => row?.qualification ?? "-",
    },
    {
      name: renderHeader("MSCI ID", false, true),
      id: "mci_id",
      center: false,
      sortable: true,
      selector: (row) => row?.registrationId ?? "-",
    },
    {
      name: renderHeader("Added On", false, false),
      id: "added_on",
      center: false,
      sortable: true,
      selector: (row) =>
        row?.createdAt
          ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
          : "-",
    },

    {
      name: renderStatusHeader("Status"),
      id: "status",
      center: false,
      sortable: true,
      selector: (row) =>
        row?.userInfo?.isLocked
          ? renderSupervisorStatus("Locked")
          : row?.status == 1
          ? renderSupervisorStatus("Active")
          : row?.status == 2
          ? renderSupervisorStatus("Occupied")
          : row?.status == 3
          ? renderSupervisorStatus("Inactive")
          : "-",
    },
    {
      name: renderActionHeader("Action", true, true),
      id: "action",
      center: true,
      selector: (row) =>
        row?.userInfo?.isLocked
          ? renderUnlockButton(row)
          : renderEditDeleteResetPasswordAction(row),
    },
  ];
  return (
    <div className="supervisor-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          columns={columns}
          data={data}
          onSelectedRowsChange={(state) =>
            props.onSelectedRowsChange(state.selectedRows, state)
          }
          clearSelectedRows={props.clearSelectedRows}
          selectableRows={false}
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalSupervisor}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default SupervisorList;
