import React, { useEffect, useState } from "react";
import { ThemedButton } from "../../../../controls";
import logoutIcon from "../../../../../assets/images/icons/logout-confirmation.png";
import { FaPlus } from "react-icons/fa";
import PopupDialog from "../../Components/Other/PopupDialog";
import AddEditCenterModalForm from "../Components/AddEditCenterModalForm";
import CenterCard from "../Components/CenterCard";
import "./HospitalCenterStyles.scss";

const HospitalCenterView = ({
  addEditModalFlag,
  onHandleAddNewCenterOpenModal,
  tabData,
  onHandleAddEditCenterCloseModal,
  editModalFlag,
  getCenterData,
  onHandleDeleteCenter,
  onHandleEditCenterOpenModal,
  handleChange,
  onCheckChange,
  fields,
  errors,
  onSave,
  onHandleEditCenterCloseModal,
  onHandleAddCenterCloseModal,
  onHandleDeleteAdminCloseModal,
  onDeleteCenterCardClick,
  closeModalinitData,
  showDeleteCenterPopup,
  centerInfo,
  deleteAdminFlag,
  onHandleDeleteAdminOpenModal,
  onSaveDeleteAdminBtn,
  centerAdminId,
  onStateChange,
  onDistrictChange,
  onPincodeChange,
  stateList,
  districtList,
  pincodeList,
}) => {
  const renderDeleteCenterPopupContents = () => {
    return (
      <div className="delete-modal-content">
        <img className="delete-icon" src={logoutIcon} alt="icon" />
        <text>
          {`Are you sure you want to Delete Center
     ${centerInfo?.center?.centerName ? centerInfo?.center?.centerName : ""}
           ?`}
        </text>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          paddingBottom: "50px",
        }}
      >
        <div className="hospital_center_header">
          <text className="center_title">Centers</text>
          <div className="addnew_center_button">
            <ThemedButton
              title="Add New Center"
              size={"large"}
              icon={<FaPlus size={15} color="#fff" />}
              onClick={onHandleAddNewCenterOpenModal}
              styles="primary medium"
            />
          </div>
        </div>

        <div className="separator" />

        {tabData?.centerList.length > 0 ? (
          <div className="hospital_center_card_body">
            {tabData?.centerList?.map((item) => {
              return (
                <CenterCard
                  name={
                    item?.center?.centerName ? item?.center?.centerName : "NA"
                  }
                  addressLine1={
                    item?.center?.addressInfo?.addressLineOne
                      ? item?.center?.addressInfo?.addressLineOne
                      : "NA"
                  }
                  location={
                    item?.center?.addressInfo?.locationName
                      ? item?.center?.addressInfo?.locationName
                      : "NA"
                  }
                  mobile={
                    item?.center?.addressInfo?.mobile
                      ? item?.center?.addressInfo?.mobile
                      : "NA"
                  }
                  onHandleEditCenterOpenModal={() =>
                    onHandleEditCenterOpenModal(item)
                  }
                  onDeleteCenterCardClick={() => onDeleteCenterCardClick(item)}
                />
              );
            })}
          </div>
        ) : (
          <div className="plainText">No centers found.</div>
        )}
      </div>

      <AddEditCenterModalForm
        addEditModalFlag={addEditModalFlag}
        onHandleAddCenterCloseModal={onHandleAddCenterCloseModal}
        onHandleDeleteAdminCloseModal={onHandleDeleteAdminCloseModal}
        hospitalId={tabData?.id}
        editModalFlag={editModalFlag}
        onHandleAddEditCenterCloseModal={onHandleAddEditCenterCloseModal}
        getCenterData={getCenterData}
        onCheckChange={onCheckChange}
        handleChange={handleChange}
        fields={fields}
        errors={errors}
        onSave={onSave}
        onHandleEditCenterCloseModal={onHandleEditCenterCloseModal}
        deleteAdminFlag={deleteAdminFlag}
        onHandleDeleteAdminOpenModal={onHandleDeleteAdminOpenModal}
        onSaveDeleteAdminBtn={onSaveDeleteAdminBtn}
        centerAdminId={centerAdminId}
        onStateChange={onStateChange}
        onDistrictChange={onDistrictChange}
        onPincodeChange={onPincodeChange}
        stateList={stateList}
        districtList={districtList}
        pincodeList={pincodeList}
      />

      <PopupDialog
        show={showDeleteCenterPopup}
        title={"Delete Center"}
        showCloseIcon={true}
        renderBodyContent={renderDeleteCenterPopupContents}
        confirmButtonTitle={"Delete"}
        cancelButtonTitle={"Cancel"}
        onclickConfirm={onHandleDeleteCenter}
        onclickCancel={closeModalinitData}
        popupSize={"md"}
      />
    </div>
  );
};

export default HospitalCenterView;
