import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { roles } from "../../../../../constants/roles";
import { showAlert } from "../../../../../store/alert/alertActions";
import { logout } from "../../../../../store/auth/authActions";
import { history } from "../../../../../store/configure/configureStore";
import {
  getDashboardData,
  getTopPerformers,
} from "../../../../../store/dashboard/dashboardActions";
import {
  getDeviceListWithFilter,
  checkInDevice,
} from "../../../../../store/device/deviceActions";
import { getReportListWithFilter } from "../../../../../store/report/reportActions";
import {
  showLoader,
  hideLoader,
} from "../../../../../store/loader/loaderActions";
import {
  ReportAccepance,
  ReportDownload,
} from "../../../../../store/report/reportActions";
import CenterAdminView from "./CenterAdminView";
import moment from "moment";

export class CenterAdminDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: this.props.loginUser?.data?.userRoles[0]?.name ?? null,
      userCreatedAt: "",
      centerName: "",

      // ------- Statistics Cards  -----------

      totalReports: 0,
      pendingReports: 0,
      totalDevices: 0,
      totalTechnicians: 0,

      // ----- Top Technicians Bar Chart --------

      topTechniciansFilters: [
        { value: "year", label: "This Year" },
        { value: "month", label: "This Month" },
        { value: "week", label: "This Week" },
        { value: "today", label: "Today" },
      ],

      topTechniciansFilterSelected: {
        value: "month",
        label: "This Month",
      },
      topTechnicians: [],

      // ----- Devices Bar Chart --------

      devicesData: [
        { name: "Active", value: 0 },
        { name: "Error", value: 0 },
        { name: "Offline", value: 0 },
      ],

      // ----- ECG Reports Bar Chart --------

      ecgReportsFilters: [
        { value: "year", label: "This Year" },
        { value: "month", label: "This Month" },
      ],
      ecgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },
      monthsBarChartData: [],
      yearsBarChartDat: [],
      ecgBarChartData: [],
      areaChartLabel: "",
    };

    this.populateCenterAdminData = this.populateCenterAdminData.bind(this);
    this.onTopTechniciansFilterChange =
      this.onTopTechniciansFilterChange.bind(this);
    this.onEcgReportsFilterChange = this.onEcgReportsFilterChange.bind(this);
  }

  componentDidMount() {
    // Set roles, first name, last name info of user in state variables
    var loginUserRole = this.props.loginUser?.data?.userRoles[0]?.name ?? null;
    var userCreatedAt = this.props.loginUser?.userProfile?.createdAt
      ? moment(this.props.loginUser?.userProfile?.createdAt).format(
          "DD/MM/YYYY"
        )
      : "";

    var centerList = this.props.loginUser?.userProfile?.centerList;
    var centerItem = Array.isArray(centerList) ? centerList[0] : {};
    var centerName = centerItem?.centerInfo?.centerName ?? "";
    console.log("centerName  :::: ", centerName);

    this.setState({
      userRole: loginUserRole,
      userCreatedAt: userCreatedAt,
      centerName: centerName,
    });

    // Fetch Dashboard data for all users...
    if (loginUserRole) {
      this.props.showLoader();
      this.props.getDashboardData();

      setTimeout(() => {
        this.props.hideLoader();
        this.populateCenterAdminData(this.props.dashboardData);
      }, 1500);
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.lastUpdatedTimestamp !== this.props.lastUpdatedTimestamp) {
      this.populateCenterAdminData(newProps.dashboardData);
    }

    var centerList = newProps.loginUser?.userProfile?.centerList;
    var centerItem = Array.isArray(centerList) ? centerList[0] : {};
    var centerName = centerItem?.centerInfo?.centerName ?? "";
    this.setState({ centerName: centerName });
  }

  // ---------------- Dashbaord Data Parsing -----------------------

  populateCenterAdminData = (data) => {
    var dashboardData = data; //this.props.dashboardData; // Redux data

    // -------------- Reports Bar Chart calculation   ---------------

    // Logic to identify current month, and no. of days in it
    const allDayWise = dashboardData["0"]?.reports?.day_wise;
    console.log("ALL DAYWISE ----->", JSON.stringify(allDayWise));

    // First key is to identify month. so that we can extract no. of days to show in graph.
    var firstKey = "";
    if (allDayWise && Object.keys(allDayWise).length > 0) {
      firstKey = Object.keys(allDayWise)[0];
    } else {
      firstKey = moment().format("DD-MM-YYYY");
    }
    console.log("firstKey", firstKey);

    // Extract day, month and year from this key
    const [day, month, year] = firstKey
      ? firstKey.split("-").map(Number)
      : [1, 1, 2023];

    const firstDate = new Date(year, month - 1, day);
    const lastDay = new Date(year, month, 0).getDate();
    console.log("firstDate", firstDate);
    console.log("lastDay", lastDay);

    //------------------------------------------

    // Now we need a array of all days in month in format of {day, report_count}
    const fullDaysInMonth = [];

    // We also need to show totalReports generated today, we will calculate it simultaneously
    var currentDay = moment().format("DD");
    currentDay = `${String(parseInt(currentDay))}-${month}-${year}`;

    for (let i = 1; i <= lastDay; i++) {
      const formattedDay = `${i}`;
      const formattedMonth = `${month}`;
      const date = `${formattedDay}-${formattedMonth}-${year}`;
      fullDaysInMonth.push(date);
    }
    console.log("fullDaysInMonth", JSON.stringify(fullDaysInMonth));

    // use fullDaysInMonth array to create a new array for reports chart
    var monthlyBarChartData = [];

    fullDaysInMonth.map((day) => {
      // A day is like : "1-11-2023", "10-11-2023", etc.
      // For label in bar chart, we don't want "1-11-2023", "10-11-2023", instead we just want "1", "2", "10", etc.
      var dayLabel = day.substring(0, 2);
      if (dayLabel.charAt(1) === "-") {
        dayLabel = dayLabel.substring(0, 1);
      }

      monthlyBarChartData.push({
        name: dayLabel,
        hoverName: day,
        Value: parseInt(dashboardData["0"]?.reports?.day_wise?.[day] ?? 0),
      });
    });

    console.log("MONTH'S bar chart data", JSON.stringify(monthlyBarChartData));

    //------------------------------------------

    const allMonthWise = dashboardData["0"]?.reports?.month_wise;
    console.log("ALL MONTHWISE ----->", JSON.stringify(allMonthWise));

    var fullMonthsInYear = [];
    for (let i = 1; i <= 12; i++) {
      const formattedMonth = `${i}`;
      const monthKey = `${formattedMonth}-${year}`;
      fullMonthsInYear.push(monthKey);
    }
    console.log("fullMonthsInYear", JSON.stringify(fullMonthsInYear));

    var yearlyBarChartData = [];

    if (allMonthWise && Object.keys(allMonthWise).length > 0) {
      fullMonthsInYear.map((monthKey) => {
        // A month is like : "9-2023", "10-2023", etc.
        yearlyBarChartData.push({
          name: moment(monthKey, "MM-YYYY").format("MMM YY"),
          hoverName: moment(monthKey, "MM-YYYY").format("MMM YYYY"),
          Value: parseInt(
            allMonthWise.hasOwnProperty(monthKey) ? allMonthWise[monthKey] : 0
          ),
        });
      });
    } else {
      fullMonthsInYear.map((monthKey) => {
        // A month is like : "9-2023", "10-2023", etc.
        yearlyBarChartData.push({
          name: moment(monthKey, "MM-YYYY").format("MMM YY"),
          hoverName: moment(monthKey, "MM-YYYY").format("MMM YYYY"),
          Value: 0,
        });
      });
    }

    console.log("YEAR'S bar chart data", JSON.stringify(yearlyBarChartData));

    //------------------------------------------

    // We need to show totalReports and pending reports count

    var totalReports = dashboardData["0"]?.reports?.today ?? 0;
    // var pendingReports = dashboardData["0"]?.reports?.arrived ?? 0;

    var pendingReports =
      parseInt(dashboardData["0"]?.reports?.arrived ?? 0) +
      parseInt(dashboardData["0"]?.reports?.assigned ?? 0);

    //------------------------------------------

    // We need to show TopTechnicians bar chart (by default month data is shown)

    var topTechniciansData = dashboardData["topTechnicians"] ?? [];
    var topTechniciansBarChartData = [];

    topTechniciansData.map((item) => {
      topTechniciansBarChartData.push({
        label: item.name,
        count: parseInt(item.total ?? 0),
      });
    });

    //------------------------------------------

    // We need to show Devices Bar chart

    var devicesDataFromAPI = dashboardData?.devices?.status ?? {};

    // -------------- Update state variables for all cards data  ---------------

    this.setState({
      // Statistics Card
      totalDevices: dashboardData["0"]?.devices?.total ?? 0,
      totalTechnicians: dashboardData["0"]?.technicians?.active ?? 0,
      totalReports: totalReports,
      pendingReports: pendingReports,

      // Top Technicians Bar Chart
      topTechnicians: topTechniciansBarChartData,

      // Devices Bar Chart
      devicesData: [
        { label: "Active", count: parseInt(devicesDataFromAPI?.Active ?? 0) },
        { label: "Error", count: parseInt(devicesDataFromAPI?.Error ?? 0) },
        { label: "Offline", count: parseInt(devicesDataFromAPI?.Offline ?? 0) },
      ],

      // ECG Reports Bar Chart
      monthsBarChartData: monthlyBarChartData,
      yearsBarChartDat: yearlyBarChartData,
      ecgBarChartData: monthlyBarChartData,
      areaChartLabel: moment().format("MMMMM YYYY"),

      // Reset filters dropdown values
      topTechniciansFilterSelected: {
        value: "month",
        label: "This Month",
      },
      ecgReportsFilterSelected: {
        value: "month",
        label: "This Month",
      },
    });
  };

  onTopTechniciansFilterChange = (selectedOption) => {
    this.setState({ topTechniciansFilterSelected: selectedOption });

    var toggleVal = selectedOption.value;
    var startDate = null;
    var endDate = null;

    if (toggleVal == "today") {
      startDate = moment().startOf("day").unix();
      endDate = moment().endOf("day").unix();
    }
    if (toggleVal == "week") {
      startDate = moment().startOf("week").unix();
      endDate = moment().endOf("week").unix();
    }
    if (toggleVal == "month") {
      startDate = moment().startOf("month").unix();
      endDate = moment().endOf("month").unix();
    }
    if (toggleVal == "year") {
      startDate = moment().startOf("year").unix();
      endDate = moment().endOf("year").unix();
    }

    var apiData = {
      entity: "technician",
      duration: toggleVal === "today" ? "custom" : toggleVal,
      startTime: startDate,
      endTime: endDate,
      limit: 5,
    };

    // Call API and Update top hospitals list
    this.props.getTopPerformers(apiData, (responseData) => {
      var topTechnicians = responseData.topTechnicians;
      console.log(
        "ON SELECT : getTopPerformers: ",
        JSON.stringify(topTechnicians)
      );

      if (topTechnicians.length > 0) {
        // Sort the array based on 'total' property in descending order
        topTechnicians.sort((a, b) => parseInt(b.total) - parseInt(a.total));
      }

      var topTechniciansForBarChart = [];
      topTechnicians.map((item) => {
        topTechniciansForBarChart.push({
          label: item.name,
          count: item.total,
        });
      });

      this.setState({ topTechnicians: topTechniciansForBarChart });
    });
  };

  onEcgReportsFilterChange = (selectedOption) => {
    if (selectedOption.value === "year") {
      this.setState({
        ecgReportsFilterSelected: selectedOption,
        ecgBarChartData: this.state.yearsBarChartDat,
        areaChartLabel: moment().format("YYYY"),
      });
    } else if (selectedOption.value === "month") {
      this.setState({
        ecgReportsFilterSelected: selectedOption,
        ecgBarChartData: this.state.monthsBarChartData,
        areaChartLabel: moment().format("MMMMM YYYY"),
      });
    }
  };

  render() {
    if (!this.state.userRole) {
      return null;
    } else if (this.state.userRole === roles.centerAdmin) {
      return (
        <CenterAdminView
          loginUser={this.props.loginUser}
          userCreatedAt={this.state.userCreatedAt}
          centerName={this.state.centerName}
          //
          totalReports={this.state.totalReports}
          pendingReports={this.state.pendingReports}
          totalDevices={this.state.totalDevices}
          totalTechnicians={this.state.totalTechnicians}
          //
          topTechniciansFilters={this.state.topTechniciansFilters}
          topTechniciansFilterSelected={this.state.topTechniciansFilterSelected}
          topTechnicians={this.state.topTechnicians}
          onTopTechniciansFilterChange={this.onTopTechniciansFilterChange}
          //
          devicesData={this.state.devicesData}
          //
          ecgReportsFilters={this.state.ecgReportsFilters}
          ecgReportsFilterSelected={this.state.ecgReportsFilterSelected}
          ecgBarChartData={this.state.ecgBarChartData}
          onEcgReportsFilterChange={this.onEcgReportsFilterChange}
          areaChartLabel={this.state.areaChartLabel}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.auth.loginUser,
    dashboardData: state.dashboard.dashboardData,
    lastUpdatedTimestamp: state.dashboard.lastUpdatedTimestamp,
  };
};

export default connect(mapStateToProps, {
  logout,
  showAlert,
  showLoader,
  hideLoader,
  getDashboardData,
  getTopPerformers,
  getReportListWithFilter,
  getDeviceListWithFilter,
  checkInDevice,
  ReportAccepance,
  ReportDownload,
})(CenterAdminDashboardContainer);
