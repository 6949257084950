import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import Nav from "react-bootstrap/Nav";

import "./OtherComponentStyles.scss";

const NestedAccordionList = ({
  userRole,
  dataList,
  centerData,
  deviceList,
  onClickHospital,
  onClickCenter,
}) => {
  // console.log("NESTED ACCORDIAN : dataList : ", JSON.stringify(dataList));

  //-------------------------------------------------------------------------------------

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const [activeChildAccordionKey, setActiveChildAccordionKey] = useState(null);

  //child list naviagation items for center
  const childNavigationOfCenter = ["Devices"];

  //--------------------- Accordian Customization --------------------------------------

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      // console.log("totally custom!")
    });

    return (
      <button
        type="button"
        className="custom-accordion-button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  const handleAccordionSelect = (index) => {
    setActiveAccordionKey(index); // Update active accordion key
    setActiveChildAccordionKey(null);
  };

  const handleChildAccordionSelect = (index) => {
    setActiveChildAccordionKey(index); // Update active child accordion key
  };

  //---------------------------- Accordion render ----------------------------------

  const renderAccordion = () => {
    return dataList.map((item, index) => (
      <Accordion.Item
        className={`${
          activeAccordionKey === index
            ? "custom-accordion-item active"
            : "custom-accordion-item"
        }`}
        eventKey={index}
      >
        {renderAccordionHeader(item, index)}
        <Accordion.Body>
          <Accordion
            bsPrefix="custom-child-accordion"
            defaultActiveKey="0"
            activeKey={activeChildAccordionKey}
            onSelect={handleChildAccordionSelect}
          >
            {renderChildAccordion(item, index)}
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    ));
  };

  const renderAccordionHeader = (item, index) => {
    const isExpanded = activeAccordionKey === index;
    const Icon = isExpanded ? FaAngleDown : FaAngleRight;

    var centerList =
      item.hospitalInfo?.centerList &&
      Array.isArray(item.hospitalInfo?.centerList)
        ? item.hospitalInfo.centerList
        : [];

    return (
      <h2 className="accordion-header">
        <CustomToggle eventKey={index}>
          <div className="title-section">
            <span className="arrow-icon">
              <Icon />
            </span>
            <text>
              {item.hospitalInfo?.hospitalName ?? "-"}{" "}
              {item.hospitalInfo?.reportService ? "(Reporting Hospital)" : ""}
            </text>
          </div>
          <div className="count-section">
            <text className="text-count">Centers: {centerList.length}</text>
          </div>
        </CustomToggle>
      </h2>
    );
  };

  //------------------------- Child Accordion render ---------------------------------

  const renderChildAccordion = (item, index) => {
    var centerList =
      item.hospitalInfo?.centerList &&
      Array.isArray(item.hospitalInfo?.centerList)
        ? item.hospitalInfo.centerList
        : [];

    if (centerList.length > 0) {
      return centerList.map((item, index) => (
        <Accordion.Item
          // className="child-accordion-item"
          className={`${
            activeChildAccordionKey === index
              ? "custom-child-accordion-item active"
              : "custom-child-accordion-item"
          }`}
          eventKey={index}
          onClick={() => {
            console.log(item.id);
          }}
        >
          {renderChildAccordionHeader(item, index)}
          <Accordion.Body bsPrefix="custom-child-accordion-body">
            {renderDeviceListHeader()}
            {renderDeviceList(item, index)}
          </Accordion.Body>
        </Accordion.Item>
      ));
    } else {
      return (
        <>
          <div style={{ height: "20px" }} />
          <text className="no-data-placeholder">No centers available.</text>
          <div style={{ height: "20px" }} />
        </>
      );
    }
  };

  const renderChildAccordionHeader = (item, index) => {
    const isExpanded = activeChildAccordionKey === index;
    const Icon = isExpanded ? FaAngleDown : FaAngleRight;

    return (
      <h2 className="child-accordion-header">
        <CustomToggle eventKey={index}>
          <div className="title-section">
            <span className="arrow-icon">
              <Icon />
            </span>
            <text className="title">{item.center?.centerName ?? "-"}</text>
          </div>
        </CustomToggle>
      </h2>
    );
  };

  //---------------------------- Child List render ------------------------------------

  const renderChildListNavigation = () => {
    return (
      <div className="navigaion">
        <Nav className="custom-nav">
          {childNavigationOfCenter.map((item, index) => (
            <Nav.Item key={index} className="custom-nav-item">
              <Nav.Link className={"custom-nav-link"}>{item}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
    );
  };

  const renderDeviceListHeader = () => {
    return (
      <div className="list-header">
        <text>Devices</text>
      </div>
    );
  };

  const renderDeviceList = (item, index) => {
    var deviceList = item.center?.deviceList ?? [];

    if (deviceList.length > 0) {
      const nameLabel = deviceList.map((item, deviceListIndex) => (
        <Col key={item.id} xs={3} sm={3} md={3} lg={3} className="border">
          <div className="list-wrapper">
            <div className="list-index"> {deviceListIndex + 1}</div>
            <div className="list-item"> {item?.dI?.seria ?? "-"}</div>
          </div>
        </Col>
      ));
      return (
        <div className="name-details-card-wrapper">
          <Container>
            <Row>{nameLabel}</Row>
          </Container>
        </div>
      );
    } else {
      return <text className="no-data-placeholder">No devices available.</text>;
    }
  };

  return (
    <div className="nested-accordion-list">
      <Accordion
        bsPrefix="custom-accordion"
        defaultActiveKey="0"
        activeKey={activeAccordionKey}
        onSelect={handleAccordionSelect}
      >
        {renderAccordion()}
      </Accordion>
    </div>
  );
};

export default NestedAccordionList;
