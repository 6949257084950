import React from "react";
import { CardView, SelectOption } from "../../../../controls";
import AreaChartView from "../Other/AreaChartView";
import { FaAngleDown } from "react-icons/fa6";
import "./CardStyles.scss";

const EcgReportsCardCenterAdmin = (props) => {
  return (
    <CardView
      extraStyle="center-admin-area-chart-wrapper"
      bodyStyle={{ height: "350px" }}
    >
      <div className="card-header">
        <div className="title">ECG Reports Sent</div>
        <div className="dropdown-option-wrapper">
          <SelectOption
            options={props.filtersData}
            placeholder={"Select"}
            isSearchable={false}
            value={props.filterOptionSelected}
            onChange={(val) => {
              console.log("SELECT : ", val);
              props.onFilterOptionChange(val);
            }}
          />
        </div>
      </div>

      <div className="info-wrapper">
        <div className="area-chart-wrapper">
          <AreaChartView
            yAxisLabel={"No. of ECG Reports"}
            xAxisLabel={props.areaChartLabel}
            dataSet={props.data ?? []}
          />
        </div>
      </div>
    </CardView>
  );
};

export default EcgReportsCardCenterAdmin;
