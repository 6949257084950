import React from "react";
import {
  DateRangeSelector,
  SelectOption,
  ThemedButton,
  TogglebuttonGroup,
  Timeline,
  NewDateRangePicker,
} from "../../../../components/controls";
import { PopupDialog } from "../../../pages/NewUIPages/Components";
import { IoCloudUploadOutline } from "react-icons/io5";
import "./TestScreenStyles.scss";

const renderButtons = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ThemedButton
          title="Primary"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary small"
        />

        <ThemedButton
          title="Primary"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary medium"
        />

        <ThemedButton
          title="Primary"
          icon={<IoCloudUploadOutline size={15} color="#fff" />}
          onClick={() => {}}
          styles="primary large"
        />

        <ThemedButton
          title="Primary"
          icon={<IoCloudUploadOutline size={15} color="#fff" />}
          onClick={() => {}}
          styles="primary huge"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <ThemedButton
          title="Primary Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary-outline small"
        />

        <ThemedButton
          title="Primary Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary-outline large"
        />

        <ThemedButton
          title="Primary Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary-outline large"
        />

        <ThemedButton
          title="Primary Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="primary-outline huge"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <ThemedButton
          title="Grey  Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="grey-outline small"
        />

        <ThemedButton
          title="Grey  Outline"
          // icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="grey-outline large"
        />

        <ThemedButton
          title="Grey  Outline"
          icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="grey-outline large"
        />

        <ThemedButton
          title="Grey  Outline"
          icon={<IoCloudUploadOutline size={15} color="#000" />}
          onClick={() => {}}
          styles="grey-outline huge"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }} className="mt-3">
        <ThemedButton
          title="Disabled Button"
          onClick={() => {}}
          styles="disabled large"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }} className="mt-3">
        <ThemedButton
          title="Link Button"
          onClick={() => {}}
          styles="link large"
        />
      </div>

      <NewDateRangePicker />
    </div>
  );
};

const renderNewDatePicker = () => {
  return <NewDateRangePicker />;
};

const renderDateRange = (props) => {
  return (
    <div className="test-view mt-4">
      <DateRangeSelector
        startDate={null}
        endDate={null}
        onDatesChange={({ startDate, endDate }) =>
          props.onChangeDate(startDate, endDate)
        }
      />
    </div>
  );
};

const renderToggleButton = (props) => {
  return (
    <div className="test-view mt-4">
      <div style={{ width: "400px" }}>
        <TogglebuttonGroup onItemSelected={() => {}} />
      </div>
    </div>
  );
};

const renderSelct = (props) => {
  const options = [
    { value: "Year", label: "This Year" },
    { value: "Month", label: "This Month" },
    { value: "Week", label: "This Week" },
    { value: "Today", label: "Today" },
  ];
  const defaultValue = { value: "Year", label: "This Year" };

  return (
    <div className="test-view mt-4">
      <SelectOption
        options={options}
        placeholder={"Select"}
        value={defaultValue}
        isSearchable={false}
        onChange={(val) => {
          console.log("SELECT : ", val);
        }}
        {...props}
      />
    </div>
  );
};

const renderTimelineContent = () => {
  return <Timeline />;
};

const renderTimelinePopup = () => {
  return (
    <PopupDialog
      show={true}
      title={"Error Log History"}
      showCloseIcon={true}
      renderBodyContent={renderTimelineContent}
      confirmButtonTitle={"Confirm"}
      cancelButtonTitle={"Cancel"}
      onclickConfirm={() => {}}
      onclickCancel={() => {}}
      hideButtons
    />
  );
};

//-------------------------------------------------------------------------------------

const TestScreenView = (props) => {
  return (
    <div className="test_screen_view">
      {/* {renderButtons()} */}
      {renderNewDatePicker()}
      {/* {renderDateRange(props)}
      {renderToggleButton(props)}
      {renderSelct(props)} */}
      {/* {renderTimelinePopup()} */}
    </div>
  );
};

export default TestScreenView;
