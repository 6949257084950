import { roles } from "../../constants/roles";
import { getState } from "../configure/configureStore";
import Api from "../../service/api";
import { showAlert } from "../alert/alertActions";
import { showLoader, hideLoader } from "../loader/loaderActions";
import { actionTypes } from "./ambulanceReducer";

export function getAmbulanceListWithFilter(
  data,
  callback,
  page = 1,
  limit = 10,
  sortBy = null,
  order = null
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_PENDING,
      payload: {
        getAmbulanceListWithFilterPending: true,
        getAmbulanceListWithFilterSuccess: false,
        getAmbulanceListWithFilterError: null,
      },
    });
    dispatch(showLoader());

    Api.post(
      `ambulance/list?page=${page}&limit=${limit}&sortby=${sortBy}&order=${order}`,
      data
    )
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response?.data?.rows ?? [], response?.data?.totalPages ?? 0);
        }

        if (!data || Object.keys(data).length === 0) {
          dispatch({
            type: actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getAmbulanceListWithFilterPending: false,
              getAmbulanceListWithFilterSuccess: true,
              getAmbulanceListWithFilterError: null,
              ambulanceList: response.data.rows,
              filteredAmbulanceList: response?.data?.rows ?? [],
              filteredAmbulanceCount: response?.data?.count ?? 0,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_SUCCESS,
            payload: {
              getAmbulanceListWithFilterPending: false,
              getAmbulanceListWithFilterSuccess: true,
              getAmbulanceListWithFilterError: null,
              ambulanceList: response.data.rows,
              filteredAmbulanceList: response?.data?.rows ?? [],
              filteredAmbulanceCount: response?.data?.count ?? 0,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback([]);
        }

        dispatch({
          type: actionTypes.GET_AMBULANCE_LIST_WITH_FILTER_ERROR,
          payload: {
            getAmbulanceListWithFilterPending: false,
            getAmbulanceListWithFilterSuccess: true,
            getAmbulanceListWithFilterError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function getAmbulanceDetailsById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_AMBULANCE_DETAILS_BY_ID_PENDING,
      payload: {
        getAmbulanceDetailsByIdPending: false,
        getAmbulanceDetailsByIdSuccess: false,
        getAmbulanceDetailsByIdError: null,
      },
    });
    dispatch(showLoader());

    Api.get(`ambulance/by/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.GET_AMBULANCE_DETAILS_BY_ID_SUCCESS,
          payload: {
            getAmbulanceDetailsByIdPending: false,
            getAmbulanceDetailsByIdSuccess: true,
            getAmbulanceDetailsByIdError: null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback({});
        }

        dispatch({
          type: actionTypes.GET_AMBULANCE_DETAILS_BY_ID_ERROR,
          payload: {
            getAmbulanceDetailsByIdPending: false,
            getAmbulanceDetailsByIdSuccess: true,
            getAmbulanceDetailsByIdError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

export function addAmbulance(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_AMBULANCE_PENDING,
      payload: {
        addAmbulancePending: false,
        addAmbulanceSuccess: false,
        addAmbulanceError: null,
      },
    });
    dispatch(showLoader());

    Api.post("ambulance/add", data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_AMBULANCE_SUCCESS,
          payload: {
            addAmbulancePending: false,
            addAmbulanceSuccess: true,
            addAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_AMBULANCE_ERROR,
          payload: {
            addAmbulancePending: false,
            addAmbulanceSuccess: true,
            addAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//--------------------------------------------------- add device in Bulk ---------------------------------------

export function addAmbulanceInBulk(data, hospitalId, callback) {
  return (dispatch) => {
    var formdata = new FormData();
    console.log("file", data);
    formdata.append("file", data);
    if (hospitalId) {
      formdata.append("hospitalId", hospitalId);
    }
    dispatch({
      type: actionTypes.ADD_AMBULANCES_IN_BULK_PENDING,
      payload: {
        addAmbulancesInBulkPending: false,
        addAmbulancesInBulkSuccess: false,
        addAmbulancesInBulkError: null,
      },
    });
    dispatch(showLoader());
    Api.uploadFile("ambulance/bulkInsert", null, formdata)
      .then((response) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.ADD_AMBULANCES_IN_BULK_SUCCESS,
          payload: {
            addAmbulancesInBulkPending: false,
            addAmbulancesInBulkSuccess: true,
            addAmbulancesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        dispatch(hideLoader());

        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.ADD_AMBULANCES_IN_BULK_ERROR,
          payload: {
            addAmbulancesInBulkPending: false,
            addAmbulancesInBulkSuccess: false,
            addAmbulancesInBulkError: err,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//----------------------- update Device -------------------------

export function editAmbulance(id, data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_AMBULANCE_PENDING,
      payload: {
        editAmbulancePending: false,
        editAmbulanceSuccess: false,
        editAmbulanceError: null,
      },
    });
    dispatch(showLoader());

    Api.post(`ambulance/update/details/${id}`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(response.data);
        }

        dispatch({
          type: actionTypes.EDIT_AMBULANCE_SUCCESS,
          payload: {
            editAmbulancePending: false,
            editAmbulanceSuccess: true,
            editAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback(null);
        }

        dispatch({
          type: actionTypes.EDIT_AMBULANCE_ERROR,
          payload: {
            editAmbulancePending: false,
            editAmbulanceSuccess: true,
            editAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------------------------- delete Ambulance ------------------------------

export function deleteAmbulance(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_AMBULANCE_PENDING,
      payload: {
        deleteAmbulancePending: false,
        deleteAmbulanceSuccess: false,
        deleteAmbulanceError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`ambulance/delete/${id}`)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_AMBULANCE_SUCCESS,
          payload: {
            deleteAmbulancePending: false,
            deleteAmbulanceSuccess: true,
            deleteAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_AMBULANCE_ERROR,
          payload: {
            deleteAmbulancePending: false,
            deleteAmbulanceSuccess: true,
            deleteAmbulanceError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}

//------------------------------------------- delete Ambulance In Bilk ------------------------------

export function deleteAmbulanceInBulk(data, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_AMBULANCES_IN_BULK_PENDING,
      payload: {
        deleteAmbulancesInBulkPending: true,
        deleteAmbulancesInBulkSuccess: false,
        deleteAmbulancesInBulkError: null,
      },
    });
    dispatch(showLoader());

    Api.delete(`ambulance/delete/multiple/ambulance`, data)
      .then((response) => {
        console.log(response);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_AMBULANCES_IN_BULK_SUCCESS,
          payload: {
            deleteAmbulancesInBulkPending: false,
            deleteAmbulancesInBulkSuccess: true,
            deleteAmbulancesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Success",
            type: "Success",
            msg: response.message,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideLoader());
        if (typeof callback === "function") {
          callback();
        }

        dispatch({
          type: actionTypes.DELETE_AMBULANCES_IN_BULK_ERROR,
          payload: {
            deleteAmbulancesInBulkPending: false,
            deleteAmbulancesInBulkSuccess: true,
            deleteAmbulancesInBulkError: null,
          },
        });
        dispatch(
          showAlert({
            isOpen: true,
            title: "Error",
            type: "danger",
            msg: err,
          })
        );
      });
  };
}
