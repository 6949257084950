import React from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "../../../../common";
import { FaSort } from "react-icons/fa6";
import {
  ThemedButton,
  PaginationNew,
  IconMenuOption,
} from "../../../../controls";
import "./ListStyles.scss";
import { roles } from "../../../../../constants/roles";
import moment from "moment";
import { AiFillFilter } from "react-icons/ai";

const ErrorLogsList = (props) => {
  const data = props.errorLogList;
  const userRole = props.userRole;
  // Columns info with row value to use (row : single object in data array)

  //-------------------- Render Column Elements --------------------

  const renderHeader = (label, islastColumn = false, sortable = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>

        <div className="sortIcon">
          {sortable ? (
            <FaSort
              style={{ color: "rgba(0,0,0,0.25)", cursor: "pointer" }}
              size={12}
              onClick={
                props.onErrorLogListSortClick !== null
                  ? () => props.onErrorLogListSortClick(label)
                  : () => {}
              }
            />
          ) : null}
          {islastColumn ? (
            <div className="partition none" />
          ) : (
            <div className="partition" />
          )}
        </div>
      </div>
    );
  };

  const renderStatusHeader = (label, islastColumn = false) => {
    const openColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#FF4D4F",
    };
    const inProgressColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#1890FF",
    };
    const closedColor = {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#52C41A",
    };
    const Low = {
      color: "#52C41A",
      border: "1px solid  #B7EB8F",
      background: "#F6FFED",
      padding: "2px 8px",
    };
    const Medium = {
      color: "#FA8C16",
      border: "1px solid #FFD591",
      background: "#FFF7E6",
      padding: "2px 8px",
    };
    const High = {
      color: "#F5222D",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
      padding: "2px 8px",
    };
    const Options =
      label === "Severity"
        ? [
            { label: "Low", icon: <text style={Low}>Low</text> },
            { label: "Medium", icon: <text style={Medium}>Medium</text> },
            { label: "High", icon: <text style={High}>High</text> },
          ]
        : [
            { label: "Open", icon: <div style={openColor} /> },
            { label: "In Progress", icon: <div style={inProgressColor} /> },
            { label: "Closed", icon: <div style={closedColor} /> },
          ];
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          {
            <IconMenuOption
              options={Options}
              columnName={label}
              showSelectedItem={true}
              menuIcon={<AiFillFilter size={12} color="rgba(0,0,0,0.25)" />}
              onClickFilter={props.onErrorLogListFilterClick}
            />
          }
          <div className="partition" />
        </div>
      </div>
    );
  };

  const renderActionHeader = (label, islastColumn = false) => {
    return (
      <div className="custom-column-header">
        <text>{label}</text>
        <div className="sortIcon">
          <div className="partition none" />
        </div>
      </div>
    );
  };

  const renderSeverity = (severity) => {
    const Low = {
      color: "#52C41A",
      border: "1px solid  #B7EB8F",
      background: "#F6FFED",
    };
    const Medium = {
      color: "#FA8C16",
      border: "1px solid #FFD591",
      background: "#FFF7E6",
    };
    const High = {
      color: "#F5222D",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
    };

    let severityStyle;
    if (severity === "Low") {
      severityStyle = Low;
    }
    if (severity === "Medium") {
      severityStyle = Medium;
    }
    if (severity === "High") {
      severityStyle = High;
    }

    return (
      <div className="severity-wrapper">
        <div style={severityStyle} className="severity-box">
          <text>{severity}</text>
        </div>
      </div>
    );
  };

  const renderDeviceStatus = (status) => {
    const onlineColor = { backgroundColor: "#52C41A" };
    const offlineColor = { backgroundColor: "#D9D9D9" };
    const runningColor = { backgroundColor: "#1890FF" };
    const errorColor = { backgroundColor: "#FF4D4F" };

    let statusStyle;
    if (status === "Online") {
      statusStyle = onlineColor;
    }
    if (status === "Offline") {
      statusStyle = offlineColor;
    }
    if (status === "Running") {
      statusStyle = runningColor;
    }
    if (status === "Error") {
      statusStyle = errorColor;
    }

    return (
      <div className="status-wrapper">
        <span className="status-circle me-2" style={statusStyle}></span>
        <span>{status}</span>
      </div>
    );
  };

  const renderErrorLogStatus = (status) => {
    var toggleData = props.toggleData ?? [];
    var statusName = "";

    // console.log("renderErrorLogStatus : status : ", status);
    // console.log("renderErrorLogStatus : toggleData : ", toggleData);

    toggleData.map((item) => {
      if (item.id == status) {
        statusName = item.name;
      }
    });

    const openColor = { backgroundColor: "#FF4D4F" };
    const inProgressColor = { backgroundColor: "#1890FF" };
    const closedColor = { backgroundColor: "#52C41A" };

    let statusStyle;
    if (statusName == "Open") {
      statusStyle = openColor;
    }
    if (statusName == "In Progress" || statusName == "In-Progress") {
      statusStyle = inProgressColor;
    }
    if (statusName === "Closed") {
      statusStyle = closedColor;
    }

    return (
      <div className="status-wrapper">
        <span className="status-circle me-2" style={statusStyle}></span>
        <span>{statusName}</span>
      </div>
    );
  };

  const renderAssignedTechnicianName = (row) => {
    if (row.deviceInfo.technicianList.length !== 0) {
      let name = row.deviceInfo.technicianList[0].technician_profile;
      return `${
        (name?.firstName !== null && name?.firstName !== undefined
          ? `${name?.firstName.charAt(0).toUpperCase()}${name?.firstName.slice(
              1
            )}`
          : "") +
        " " +
        (name?.lastName !== null && name?.lastName !== undefined
          ? `${name?.lastName.charAt(0).toUpperCase()}${name?.lastName.slice(
              1
            )}`
          : "")
      }`;
    } else {
      return "-";
    }
  };

  const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // -------------------- All Column Object ---------------------

  const errorLogId = {
    name: renderHeader("Error Log ID", false, true),
    id: "error_log_id",
    center: false,
    sortable: false,
    selector: (row) => parseInt(row?.id),
  };

  const deviceId = {
    name: renderHeader("Device ID", false, false),
    id: "device_id",
    center: false,
    sortable: false,
    selector: (row) => row.deviceInfo?.serialNo ?? "-",
  };

  const deviceType = {
    name: renderHeader("Device Type", false, false),
    id: "device_type",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.deviceInfo ? row?.deviceInfo?.deviceType?.type ?? "-" : "-",
  };

  const center = {
    name: renderHeader("Center", false, true),
    id: "center",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.deviceInfo?.centerDeviceInfo?.center?.centerName ?? "-",
  };

  const hospitalName = {
    name: renderHeader("Hospital", false, true),
    id: "doctors_hospital_name",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.deviceInfo?.hospital?.hospitalInfo?.hospitalName ?? "-",
  };

  const deviceAssignTo = {
    name: renderHeader("Assigned To", false, false),
    id: "assign_to",
    center: false,
    sortable: false,
    selector: (row) => renderAssignedTechnicianName(row),
  };

  const deviceStatus = {
    name: renderStatusHeader(
      props.userRole === roles.technician ? "Device Status" : "Status"
    ),
    id: "status",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.deviceInfo?.status == 1
        ? renderDeviceStatus("Online")
        : row?.deviceInfo?.status == 2
        ? renderDeviceStatus("Offline")
        : row?.deviceInfo?.status == 3
        ? renderDeviceStatus("Error")
        : "-",
  };

  const deviceLocation = {
    name: renderHeader("Device Location"),
    id: "device_location",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.deviceInfo?.centerDeviceInfo
        ? `${
            row?.deviceInfo?.centerDeviceInfo?.center?.addressInfo
              ?.locationName ?? ""
          }, ${
            row?.deviceInfo?.centerDeviceInfo?.center?.addressInfo?.districtInfo
              ?.name ??
            row?.deviceInfo?.centerDeviceInfo?.center?.addressInfo?.districtInfo
              ?.nam ??
            ""
          }, ${
            row?.deviceInfo?.centerDeviceInfo?.center?.addressInfo?.stateInfo
              ?.name ?? ""
          }`
        : "-",
  };

  const deviceReportedDate = {
    name: renderHeader("Reported On", false, true),
    id: "reported_on",
    center: false,
    sortable: false,
    selector: (row) => moment(row?.createdAt ?? "").format("YYYY-MM-DD") ?? "-",
  };

  const errorLogSeverity = {
    name: renderStatusHeader("Severity", false),
    id: "error_log_severity",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.severity == 1
        ? renderSeverity("Low")
        : row?.severity == 2
        ? renderSeverity("Medium")
        : row?.severity == 3
        ? renderSeverity("High")
        : "-",
  };

  const errorLogStatus = {
    name: renderHeader("Status", false, false),
    id: "error_log_status",
    center: false,
    sortable: false,
    selector: (row) => (row?.status ? renderErrorLogStatus(row?.status) : "-"),
  };

  const errorDescription = {
    name: renderHeader("Error Description", false, false),
    id: "error_description",
    center: false,
    sortable: false,
    selector: (row) => row?.description ?? "-",
  };

  const viewAction = {
    name: renderActionHeader("Action", true),
    id: "view_action",
    center: false,
    sortable: false,
    selector: (row) => (
      <ThemedButton
        title="View"
        styles="link"
        onClick={
          props.onViewClick !== null
            ? () => props.onViewClick(row.id)
            : () => {}
        }
      />
    ),
  };

  const escalateAction = {
    name: renderActionHeader("Action", true),
    id: "view_action",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.status !== 3 ? (
        <ThemedButton
          title="Escalate"
          styles="link"
          onClick={
            props.onEscalateActionClick !== null
              ? () => props.onEscalateActionClick(row.id)
              : () => {}
          }
        />
      ) : null,
  };

  const investigateAction = {
    name: renderActionHeader("Action", true),
    id: "investigate",
    center: false,
    sortable: false,
    selector: (row) =>
      row?.status !== 3 ? (
        <ThemedButton
          title="Investigate"
          styles="link"
          onClick={
            props.onInvestigateActionClick !== null
              ? () => props.onInvestigateActionClick(row.id)
              : () => {}
          }
        />
      ) : null,
  };

  // ------------------  Add Columns According to Role -------------------

  // We will add needed columns to this array according to role.

  var columns = [];
  if (userRole === roles.superAdmin) {
    columns.push(errorLogId);
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(deviceLocation);
    // columns.push(deviceStatus);
    columns.push(hospitalName);
    columns.push(errorLogSeverity);
    columns.push(errorDescription);
    if (props.toggleHeader == "All") {
      columns.push(errorLogStatus);
    }
    columns.push(escalateAction);
  }
  //
  else if (userRole === roles.hospitalAdmin) {
    columns.push(errorLogId);
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(deviceLocation);
    // columns.push(deviceStatus);
    columns.push(center);
    columns.push(errorLogSeverity);
    columns.push(errorDescription);
    if (props.toggleHeader == "All") {
      columns.push(errorLogStatus);
    }
    columns.push(investigateAction);
  }
  //
  else if (userRole === roles.centerAdmin) {
    columns.push(errorLogId);
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(deviceLocation);
    // columns.push(deviceStatus);
    columns.push(deviceAssignTo);
    columns.push(errorLogSeverity);
    columns.push(errorDescription);
    if (props.toggleHeader == "All") {
      columns.push(errorLogStatus);
    }
    columns.push(investigateAction);
  }
  //
  else if (userRole === roles.technician) {
    columns.push(errorLogId);
    columns.push(deviceId);
    columns.push(deviceType);
    // columns.push(deviceLocation);
    // columns.push(deviceStatus);
    columns.push(deviceReportedDate);
    columns.push(errorLogSeverity);
    columns.push(errorDescription);
    if (props.toggleHeader == "All") {
      columns.push(errorLogStatus);
    }
    columns.push(viewAction);
  }
  return (
    <div className="error-logs-list-info-wrapper">
      <div className="table-list-wrapper">
        <DataTable
          className="data-table"
          columns={columns}
          data={data}
          selectableRows={false}
          selectableRowsComponent={BootyCheckbox}
          selectableRowsHighlight={true}
          pagination
          // paginationComponent={PaginationNew}
          paginationRowsPerPageOptions={[10, 20]}
          paginationPerPage={props.limit}
          paginationDefaultPage={props.page}
          paginationServer
          paginationTotalRows={props.totalErrors}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          onChangePage={props.onChangePage}
        />
      </div>
    </div>
  );
};

export default ErrorLogsList;
