import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import "./ThemedButtonStyles.scss";

const ThemedButton = ({
  title,
  icon,
  onClick,
  disabled,
  styles,
  size,
  tooltip,
  fullWidth,
  loginButton,
}) => {
  return (
    <div className={`themed-button_control ${fullWidth && "full-width"}`}>
      <div className="d-grid gap-2">
        <Button
          data-tip={tooltip}
          data-for="main"
          onClick={onClick}
          disabled={disabled}
          className={`${styles} ${size}  ${loginButton && "login-button"}`}
        >
          <div className="icon_title_container">
            {icon}
            {icon && title ? <div className="icon_title_separator" /> : null}
            {title}
          </div>
        </Button>
      </div>
      <ReactTooltip
        className="button_tooltip"
        id="main"
        place="top"
        type="light"
        effect="solid"
        backgroundColor="#d7e3f5"
      />
    </div>
  );
};

ThemedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  title: PropTypes.string,
  style: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

ThemedButton.defaultProps = {
  disabled: false,
  styles: "primary",
  size: "",
  tooltip: "",
};

export default ThemedButton;
