import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  DASHBOARD_NEW,
  ADMINISTRATORS,
  ADMINISTRATORS_HOSPITALS,
  HOSPITALS,
  HOSPITALS_CENTERS,
  DOCTORS,
  TECHNICIANS,
  DEVICES,
  AMBULANCES,
  ECG_REPORTS,
  ECG_REPORT_DETAILS,
  ECG_REPORTS_BY_DOCTOR,
  ERROR_LOGS,
  SUPERVISOR,
  CASES,
  CASES_DETAILS,
} from "../../../navigation/routes";

import { NavLink, Link } from "react-router-dom";
import { roles } from "../../../constants/roles";
import admin_filled from "../../../assets/images/icons/admin_filled.png";
import admin_line from "../../../assets/images/icons/admin_line.png";
import dashboard_filled from "../../../assets/images/icons/dashboard_filled.png";
import dashboard_line from "../../../assets/images/icons/dashboard_line.png";
import hospital_filled from "../../../assets/images/icons/hospital_filled.png";
import hospital_line from "../../../assets/images/icons/hospital_line.png";
import device_filled from "../../../assets/images/icons/device_filled.png";
import device_line from "../../../assets/images/icons/device_line.png";
import error_log_filled from "../../../assets/images/icons/error_log_filled.png";
import error_log_line from "../../../assets/images/icons/error_log_line.png";
import ecg_reports_filled from "../../../assets/images/icons/ecg_reports_filled.png";
import ecg_reports_line from "../../../assets/images/icons/ecg_reports_line.png";
import ambulance_filled from "../../../assets/images/icons/ambulance_filled_small.png";
import ambulance_line from "../../../assets/images/icons/ambulance-line-small.png";
import cases_filled from "../../../assets/images/icons/cases_filled.png";
import cases_line from "../../../assets/images/icons/cases_line.png";

import {
  getStore,
  getState,
  history,
} from "../../../store/configure/configureStore";
import { getSelectedDoctor } from "../../../store/report/reportActions";
import { getDashboardData } from "../../../store/dashboard/dashboardActions";
import { getAssignedDeviceOfTechnician } from "../../../store/technician/technicianActions";
import { getSelectedCaseId } from "../../../store/case/caseActions";
import { getMyProfile } from "../../../store/auth/authActions";
import { ThemedButton } from "../../controls";
import { MdRefresh } from "react-icons/md";
import { IoMdRefreshCircle } from "react-icons/io";
import "./TopNavBarNewStyles.scss";

const TopNavBarNew = ({ loginUser }) => {
  //----------------------- Path Handling -------------------------

  const location = useLocation();
  const currentPath = location.pathname;
  // console.log("current path", currentPath);

  const isActive = (path) => {
    if (currentPath === path) {
      return true;
    }
    return false;
  };

  const isEcgReportViewPage = () => {
    var pathValue = "ecg_report_details";

    const urlParams = new URLSearchParams(location.search);
    var preview = urlParams.get("preview") ?? false;
    var id = urlParams.get("id");

    if (currentPath.includes(pathValue) && preview) {
      console.log("isEcgReportViewPage ::::: YES ");
      return true;
    }
    return false;
  };

  const isEcgReportSubmitPage = () => {
    var pathValue = "ecg_report_details";

    const urlParams = new URLSearchParams(location.search);
    var preview = urlParams.get("preview") ?? false;
    var id = urlParams.get("id");

    if (currentPath.includes(pathValue) && !preview) {
      console.log("isEcgReportSubmitPage ::::: YES ");
      return true;
    }
    return false;
  };

  const getReportIdFromPath = () => {
    const urlParams = new URLSearchParams(location.search);
    var id = urlParams.get("id") ?? null;
    return id;
  };

  const onRefreshClick = () => {
    getStore().dispatch(getDashboardData());
    getStore().dispatch(getMyProfile());

    if (loginUser?.data?.userRoles[0].name === roles.technician) {
      getStore().dispatch(
        getAssignedDeviceOfTechnician((deviceId) => {
          console.log("Nav bar - technician device id : ", deviceId);
        })
      );
    }
  };

  //------------------------- Nav Icons as per Active screen  -------------------

  const dashboardIcon =
    isActive(DASHBOARD_NEW) || isEcgReportSubmitPage()
      ? dashboard_filled
      : dashboard_line;

  const adminIcon =
    isActive(ADMINISTRATORS) || isActive(ADMINISTRATORS_HOSPITALS)
      ? admin_filled
      : admin_line;

  const supervisorIcon = isActive(SUPERVISOR) ? admin_filled : admin_line;

  const hospitalIcon =
    isActive(HOSPITALS) || isActive(HOSPITALS_CENTERS)
      ? hospital_filled
      : hospital_line;

  const deviceIcon = isActive(DEVICES) ? device_filled : device_line;

  const ambulanceIcon = isActive(AMBULANCES)
    ? ambulance_filled
    : ambulance_line;

  const ecgReportsIcon =
    isActive(ECG_REPORTS) ||
    isActive(ECG_REPORTS_BY_DOCTOR) ||
    isEcgReportViewPage()
      ? ecg_reports_filled
      : ecg_reports_line;

  const errorLogIcon = isActive(ERROR_LOGS) ? error_log_filled : error_log_line;

  const doctorIcon = isActive(DOCTORS) ? admin_filled : admin_line;

  const technicianIcon = isActive(TECHNICIANS) ? admin_filled : admin_line;

  const casesIcon =
    isActive(CASES) || isActive(CASES_DETAILS) ? cases_filled : cases_line;

  //--------------- Clickable Breadcrumbs as per Active screen -------------------

  const homeActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Home"}
      onClick={() => history.push("/dashboard_new")}
    />
  );

  const homeAndEcgReportsActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Home - ECG Reports"}
      onClick={() => history.push("/dashboard_new")}
    />
  );

  const administratorsActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Administrators"}
      onClick={() => history.push("/administrators")}
    />
  );

  const hospitalsActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Hospitals"}
      onClick={() => history.push("/hospitals")}
    />
  );

  const casesActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Cases"}
      onClick={() => history.push("/cases")}
    />
  );

  const ecgReportsByDoctorActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"ECG Reports"}
      onClick={() => history.push("/ecg_reports_by_doctor")}
    />
  );

  const reviewedReportsActiveButton = (
    <ThemedButton
      styles={"link compact small"}
      title={"Reviewed Reports"}
      onClick={() => history.push("/ecg_reports")}
    />
  );

  //------------------ Breadcrumbs as per Active screen  --------------------------

  const renderBreadCrumbs = () => {
    //TODO - Make path breadcrumbs active/inactive and clicakble
    return (
      <div className="breadcrumbs-container">
        {isActive(DASHBOARD_NEW) ? (
          <text>Home</text>
        ) : //

        isActive(ADMINISTRATORS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Administrators</text>
          </div>
        ) : //

        isActive(ADMINISTRATORS_HOSPITALS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            {administratorsActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">
              {(getState().administrators.selectedHospitalAdmin?.firstName !==
                undefined &&
              getState().administrators.selectedHospitalAdmin?.firstName !==
                null
                ? `${getState()
                    .administrators.selectedHospitalAdmin?.firstName.charAt(0)
                    .toUpperCase()}${getState().administrators.selectedHospitalAdmin?.firstName.slice(
                    1
                  )}`
                : "") +
                " " +
                (getState().administrators.selectedHospitalAdmin?.lastName !==
                  undefined &&
                getState().administrators.selectedHospitalAdmin?.lastName !==
                  null
                  ? `${getState()
                      .administrators.selectedHospitalAdmin?.lastName.charAt(0)
                      .toUpperCase()}${getState().administrators.selectedHospitalAdmin?.lastName.slice(
                      1
                    )}`
                  : "")}
            </text>
          </div>
        ) : //

        isActive(HOSPITALS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Hospitals</text>
          </div>
        ) : //

        isActive(HOSPITALS_CENTERS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            {hospitalsActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">
              {getState().hospital.selectedHospital?.hospitalName ?? "Centers"}
            </text>
          </div>
        ) : //

        isActive(DEVICES) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Devices</text>
          </div>
        ) : //

        isActive(AMBULANCES) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Ambulances</text>
          </div>
        ) : //

        isActive(ECG_REPORTS) &&
          loginUser?.data?.userRoles[0].name === roles.doctor ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Reviewed Reports</text>
          </div>
        ) : //

        isActive(ECG_REPORTS) &&
          (loginUser?.data?.userRoles[0].name === roles.superAdmin ||
            loginUser?.data?.userRoles[0].name === roles.hospitalAdmin) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            {ecgReportsByDoctorActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">
              {getSelectedDoctor()?.name ?? "Reports By Doctor"}
            </text>
          </div>
        ) : //

        isActive(ECG_REPORTS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> ECG Reports</text>
          </div>
        ) : //

        isActive(ECG_REPORTS_BY_DOCTOR) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> ECG Reports</text>
          </div>
        ) : //

        isEcgReportViewPage() ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            {reviewedReportsActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Record Id : {getReportIdFromPath()}</text>
          </div>
        ) : //

        isEcgReportSubmitPage() ? (
          <div className="items-container">
            {homeAndEcgReportsActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Record Id :{getReportIdFromPath()}</text>
          </div>
        ) : //

        isActive(ERROR_LOGS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Error Logs</text>
          </div>
        ) : //

        isActive(DOCTORS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Doctors</text>
          </div>
        ) : //

        isActive(TECHNICIANS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Technicians</text>
          </div>
        ) : //

        isActive(SUPERVISOR) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Supervisors</text>
          </div>
        ) : //

        isActive(CASES) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            <text className="spacer"> Cases</text>
          </div>
        ) : //

        isActive(CASES_DETAILS) ? (
          <div className="items-container">
            {homeActiveButton}
            <text className="spacer">/</text>
            {casesActiveButton}
            <text className="spacer">/</text>
            <text className="spacer">Case ID : {getSelectedCaseId()}</text>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  //---------------------- Nav Links For All User Roles ---------------------------

  const superAdminNavLinks = (
    <>
      <NavLink
        className="nav-options"
        exact
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink
        className="nav-options"
        to={ADMINISTRATORS}
        isActive={() =>
          isActive(ADMINISTRATORS) || isActive(ADMINISTRATORS_HOSPITALS)
        }
      >
        <img className="navbar-tab-icon" src={adminIcon} alt="" />
        Administrators
      </NavLink>

      <NavLink
        className="nav-options"
        to={SUPERVISOR}
        isActive={() => isActive(SUPERVISOR)}
      >
        <img className="navbar-tab-icon" src={supervisorIcon} alt="" />
        Supervisors
      </NavLink>

      <NavLink
        className="nav-options"
        to={HOSPITALS}
        isActive={() => isActive(HOSPITALS) || isActive(HOSPITALS_CENTERS)}
      >
        <img className="navbar-tab-icon" src={hospitalIcon} alt="" />
        Hospitals
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        to={ECG_REPORTS_BY_DOCTOR}
        isActive={() =>
          isActive(ECG_REPORTS_BY_DOCTOR) || isActive(ECG_REPORTS)
        }
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const newSupervisorNavLinks = (
    <>
      <NavLink
        className="nav-options"
        exact
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink
        className="nav-options"
        to={ADMINISTRATORS}
        isActive={() =>
          isActive(ADMINISTRATORS) || isActive(ADMINISTRATORS_HOSPITALS)
        }
      >
        <img className="navbar-tab-icon" src={adminIcon} alt="" />
        Administrators
      </NavLink>

      {/* <NavLink
        className="nav-options"
        to={SUPERVISOR}
        isActive={() => isActive(SUPERVISOR)}
      >
        <img className="navbar-tab-icon" src={supervisorIcon} alt="" />
        Supervisors
      </NavLink> */}

      <NavLink
        className="nav-options"
        to={HOSPITALS}
        isActive={() => isActive(HOSPITALS) || isActive(HOSPITALS_CENTERS)}
      >
        <img className="navbar-tab-icon" src={hospitalIcon} alt="" />
        Hospitals
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        to={ECG_REPORTS_BY_DOCTOR}
        isActive={() =>
          isActive(ECG_REPORTS_BY_DOCTOR) || isActive(ECG_REPORTS)
        }
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const hospitalAdminNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink className="nav-options" exact to={DOCTORS}>
        <img className="navbar-tab-icon" src={doctorIcon} alt="" />
        Doctors
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        to={AMBULANCES}
        isActive={() => isActive(AMBULANCES)}
      >
        <img className="navbar-tab-icon inc-size" src={ambulanceIcon} alt="" />
        Ambulances
      </NavLink>

      <NavLink
        className="nav-options"
        exact
        to={ECG_REPORTS_BY_DOCTOR}
        isActive={() =>
          isActive(ECG_REPORTS_BY_DOCTOR) || isActive(ECG_REPORTS)
        }
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={CASES}
        isActive={() =>
          isActive(CASES) || isActive(CASES_DETAILS) || isEcgReportSubmitPage()
        }
      >
        <img className="navbar-tab-icon" src={casesIcon} alt="" />
        Cases
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const reportingHospitalAdminNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink className="nav-options" exact to={DOCTORS}>
        <img className="navbar-tab-icon" src={doctorIcon} alt="" />
        Doctors
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        exact
        to={ECG_REPORTS_BY_DOCTOR}
        isActive={() =>
          isActive(ECG_REPORTS_BY_DOCTOR) || isActive(ECG_REPORTS)
        }
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const centerAdminNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink className="nav-options" exact to={TECHNICIANS}>
        <img className="navbar-tab-icon" src={technicianIcon} alt="" />
        Technicians
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        exact
        to={ECG_REPORTS}
        isActive={() => isActive(ECG_REPORTS)}
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const doctorNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW) || isEcgReportSubmitPage()}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink
        className="nav-options"
        to={ECG_REPORTS}
        isActive={() =>
          isActive(ECG_REPORTS) || isEcgReportViewPage(ECG_REPORT_DETAILS)
        }
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        Reviewed Reports
      </NavLink>
    </>
  );

  const technicianNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW)}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>

      <NavLink
        className="nav-options"
        to={DEVICES}
        isActive={() => isActive(DEVICES)}
      >
        <img className="navbar-tab-icon" src={deviceIcon} alt="" />
        Devices
      </NavLink>

      <NavLink
        className="nav-options"
        to={ECG_REPORTS}
        isActive={() => isActive(ECG_REPORTS)}
      >
        <img className="navbar-tab-icon" src={ecgReportsIcon} alt="" />
        ECG Reports
      </NavLink>

      <NavLink
        className="nav-options"
        to={ERROR_LOGS}
        isActive={() => isActive(ERROR_LOGS)}
      >
        <img className="navbar-tab-icon" src={errorLogIcon} alt="" />
        Error Logs
      </NavLink>
    </>
  );

  const supervisorNavLinks = (
    <>
      <NavLink
        className="nav-options"
        to={DASHBOARD_NEW}
        isActive={() => isActive(DASHBOARD_NEW) || isEcgReportSubmitPage()}
      >
        <img className="navbar-tab-icon" src={dashboardIcon} alt="" />
        Dashboard
      </NavLink>
    </>
  );

  // console.log("LOGIN DATA :::: ", JSON.stringify(loginUser?.data));
  // console.log("loginUser?.data?.userRoles[0]", loginUser?.data?.userRoles[0]);

  return (
    <>
      <Navbar className="top-navbar-new-full-width">
        {/* // Instead of shopwing page path as text, now we have to show clickable buttons as breadcrumbs
        <div className="page-path-info">{pagePathNew}</div> */}

        {renderBreadCrumbs()}

        <Container className="navigation-container">
          <Nav className="navigation-option-wrapper">
            {loginUser?.data?.userRoles[0].name === roles.superAdmin
              ? superAdminNavLinks
              : null}

            {loginUser?.data?.userRoles[0].name === roles.hospitalAdmin &&
            loginUser?.data?.id !== "363"
              ? hospitalAdminNavLinks
              : null}
            {loginUser?.data?.userRoles[0].name === roles.hospitalAdmin &&
            loginUser?.data?.id === "363"
              ? reportingHospitalAdminNavLinks
              : null}
            {loginUser?.data?.userRoles[0].name === roles.centerAdmin
              ? centerAdminNavLinks
              : null}
            {loginUser?.data?.userRoles[0].name === roles.doctor
              ? doctorNavLinks
              : null}
            {loginUser?.data?.userRoles[0].name === roles.technician
              ? technicianNavLinks
              : null}
            {loginUser?.data?.userRoles[0].name === roles.supervisor
              ? supervisorNavLinks
              : null}
          </Nav>
          {loginUser?.data?.userRoles[0].name === roles.supervisor ||
          !isActive(DASHBOARD_NEW) ? null : (
            // Show last updated time for Dashboard screen only. Also it is not to be shown to supervisor
            <div className="updated-time-info">
              <div id="updated-time-info-text">
                Last Updated: {getState().dashboard.dashboardData.lastUpdated}
              </div>
              <ThemedButton
                icon={
                  <MdRefresh
                    size={18}
                    color="#00b48a"
                    style={{ marginLeft: "10px" }}
                  />
                }
                styles={"link"}
                onClick={() => onRefreshClick()}
              />
            </div>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default TopNavBarNew;
